import React, { Component } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import fetchIntercept from "fetch-intercept";
//import Login from "./pages/login/index";
import NotFound from "./pages/not-found/index";
import Register from "./pages/register/index";
import User from "./pages/user/index";
import checkAuth from "./functions/checkAuth";
import { tokenRegister } from "./services/tokenRegister";
import { userRegister } from "./services/userRegister";
//import ForgotPassword from "./pages/forgot-password/index";
//import ResetPassword from "./pages/reset-password/index";
import Faq from "./pages/faq";
import TermsOfService from "./pages/terms-of-service";
import PrivacyPolicy from "./pages/privacy-policy";
import SetPassword from "./pageFIles/setPassword";
import Preview from "./pages/preview";
import PremiumReportSharedByLink from "./pages/premium-report/reports-shared";
import PreviewPdf from "./pages/preview-pdf";
import Callback from "./pages/callback";
import { LinkedInPopUp } from "react-linkedin-login-oauth2";
import StandardReportPublic from "./pageFIles/standardReportPublic";
import AdvancedReportPublic from "./pageFIles/advancedReportPublic";
import Report from "./pages/old/report";

//PAGE FILES
import Login from "./pageFIles/login";
import LoginToken from "./pageFIles/loginToken";
import ForgotPassword from "./pageFIles/forgotPassword";
import ResetPassword from "./pageFIles/resetPassword";
import LoggedInAdmin from "./pageFIles/loggedInAdmin";
import LoggedInAgent from "./pageFIles/loggedInAgent";
import LoginOnboarding from "./pageFIles/loginOnboarding";

//PAGE MODULES
import ProtectedRoute from "./moduleFiles/protectedRoute";
import ExternalPreview from "./pageFIles/externalPreview";
import VerificationCode from "./pageFIles/verificationCode";
import OnboardingVerificationCode from "./pageFIles/onboardingVerificationCode";
import { clientRegister } from "./services/clientRegister";
import ExternalClientPerformanceList from "./pageFIles/externalClientPerformanceList";
import LoggedInClient from "./pageFIles/loggedInClient";
import ClientReportList from "./pageFIles/clientReportList";
import ToolboxHero from "./pageFIles/toolbox";

let isRefreshing = false;
let refreshSubscribers = [];

function onRrefreshed(token) {
	refreshSubscribers.forEach((callback) => callback(token));
	refreshSubscribers = [];
}

function addSubscriber(callback) {
	refreshSubscribers.push(callback);
}

class Main extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	componentWillMount() {
		this._setInterceptor();
	}

	_setInterceptor() {
		const unregister = fetchIntercept.register({
			request: function (url, config) {
				if (config && config.headers && config.headers['x-auth-token'] && tokenRegister.isTokenExpired(config.headers['x-auth-token'])) {
					if (!isRefreshing) {
						isRefreshing = true;
						try {
							tokenRegister.refreshToken().then((response) => {
								const newToken = response.headers['x-auth-token'];
								tokenRegister.setToken(newToken);
								config.headers['x-auth-token'] = newToken;
								isRefreshing = false;
								onRrefreshed(newToken);
							});
						} catch (error) {
							isRefreshing = false;
							return Promise.reject(error);
						}
					}
					return new Promise((resolve) => {
						addSubscriber((token) => {
							config.headers['x-auth-token'] = token;
							resolve([url, config]);
						});
					});
				}
				return [url, config];
			},
			requestError: function (error) {
				return error;
			},
			response: function (response) {
				if (response.status === 401) {
					tokenRegister.remove();
					userRegister.remove();
					clientRegister.remove();
					try {
						if (window.location.pathname) {
							localStorage.setItem("redirectUrl", window.location.pathname + window.location.search);
						}
					} catch (error) {
						console.log(error)
					}

					try {
						// if (window.location.hostname === 'localhost') {
						// 	window.location.replace("http://localhost:5001/login");
						// } else if (window.location.hostname === 'app.adcredo.io') {
						// 	window.location.replace("https://app.adcredo.io/login");
						// } else if (window.location.hostname === 'dev.adcredo.io') {
						// 	window.location.replace("https://dev.adcredo.io/login");
						// } else if (window.location.hostname === 'docker-test.adcredo.io') {
						// 	window.location.replace("https://docker-test.adcredo.io/login");
						// }
					} catch (error) {
						console.log(error);
					}
				}
				if (response.headers.get('x-auth-token')) {
					try {
						tokenRegister.set(response.headers.get("x-auth-token"), userRegister.isAdmin());
					} catch (error) {
						console.log(error);
					}
				}
				return response;
			},
			responseError: function (error) {
				return error;
			},
		});
	}

	render() {
		return (
			<BrowserRouter>
				<Switch>

					{/*TOOLBOX*/}
					{
						false &&
						<Route exact path="/toolbox" component={ToolboxHero} />
					}

					{/*REPORT DOMAIN (ONLY PROFESSIONAL)*/}
					{
						window && window.location && (window.location.origin == "https://report.adcredo.io" || window.location.origin == "https://report-dev.adcredo.io") &&
						<Route exact path="/:id" component={AdvancedReportPublic} />
					}

					{/*SUB DOMAIN*/}
					{
						window && window.location && (window.location.origin !== "https://app.adcredo.io" && window.location.origin !== "https://dev.adcredo.io") &&
						<Route exact path="/professional/:id" component={AdvancedReportPublic} />
					}
					{
						window && window.location && (window.location.origin !== "https://app.adcredo.io" && window.location.origin !== "https://dev.adcredo.io") &&
						<Route exact path="/standard/:id" component={StandardReportPublic} />
					}
					{
						window && window.location && (window.location.origin !== "https://app.adcredo.io" && window.location.origin !== "https://dev.adcredo.io") &&
						<Route exact path="/previews/:id" component={ExternalPreview} />
					}

					{/*MAIN DOMAIN*/}
					{
						window && window.location && (window.location.hostname == "localhost"
							|| window.location.origin == "https://app.adcredo.io"
							|| window.location.origin == "https://dev.adcredo.io"
							|| window.location.origin == "https://docker-test.adcredo.io"
						)
						&&
						<Route exact path="/advanced/report/:id" component={AdvancedReportPublic} />
					}
					{
						window && window.location && (window.location.hostname == "localhost" || window.location.origin == "https://app.adcredo.io" || window.location.origin == "https://dev.adcredo.io" || window.location.origin == "https://docker-test.adcredo.io") &&
						<Route exact path="/standard/report/:id" component={StandardReportPublic} />
					}
					{
						window && window.location && (window.location.hostname == "localhost" || window.location.origin == "https://app.adcredo.io" || window.location.origin == "https://dev.adcredo.io" || window.location.origin == "https://docker-test.adcredo.io") &&
						<Route exact path="/preview/v2/:id" component={ExternalPreview} />
					}
					{
						window && window.location && (window.location.hostname == "localhost" || window.location.origin == "https://app.adcredo.io" || window.location.origin == "https://dev.adcredo.io" || window.location.origin == "https://docker-test.adcredo.io") &&
						<Route exact path="/premium/report/:id" component={AdvancedReportPublic} />
					}
					{
						window && window.location && (window.location.hostname == "localhost" || window.location.origin == "https://app.adcredo.io" || window.location.origin == "https://dev.adcredo.io" || window.location.origin == "https://docker-test.adcredo.io") &&
						<Route exact path="/freemium/report/:id" component={StandardReportPublic} />
					}
					{
						window && window.location && (window.location.hostname == "localhost" || window.location.origin == "https://app.adcredo.io" || window.location.origin == "https://dev.adcredo.io" || window.location.origin == "https://docker-test.adcredo.io") &&
						<Route exact path="/performance/:agency" component={ExternalClientPerformanceList} />
					}
					{
						window && window.location && (window.location.hostname == "localhost" || window.location.origin == "https://app.adcredo.io" || window.location.origin == "https://dev.adcredo.io" || window.location.origin == "https://docker-test.adcredo.io") &&
						<Route exact path="/report/:id" component={Report} />
					}
					{
						window && window.location && (window.location.hostname == "localhost" || window.location.origin == "https://app.adcredo.io" || window.location.origin == "https://dev.adcredo.io" || window.location.origin == "https://docker-test.adcredo.io") &&
						<Route exact path="/preview/:id" component={Preview} />
					}
					{
						window && window.location && (window.location.hostname == "localhost" || window.location.origin == "https://app.adcredo.io" || window.location.origin == "https://dev.adcredo.io" || window.location.origin == "https://docker-test.adcredo.io") &&
						<Route exact path="/terms-of-service" component={TermsOfService} />
					}
					{
						window && window.location && (window.location.hostname == "localhost" || window.location.origin == "https://app.adcredo.io" || window.location.origin == "https://dev.adcredo.io" || window.location.origin == "https://docker-test.adcredo.io") &&
						<Route exact path="/privacy-policy" component={PrivacyPolicy} />
					}
					{
						window && window.location && (window.location.hostname == "localhost" || window.location.origin == "https://app.adcredo.io" || window.location.origin == "https://dev.adcredo.io" || window.location.origin == "https://docker-test.adcredo.io") &&
						<Route exact path="/login" component={Login} />
					}
					{
						window && window.location && (window.location.hostname == "localhost" || window.location.origin == "https://app.adcredo.io" || window.location.origin == "https://dev.adcredo.io" || window.location.origin == "https://docker-test.adcredo.io") &&
						<Route exact path="/login/:agent" component={LoginToken} />
					}
					{
						window && window.location && (window.location.hostname == "localhost" || window.location.origin == "https://app.adcredo.io" || window.location.origin == "https://dev.adcredo.io" || window.location.origin == "https://docker-test.adcredo.io") &&
						<Route exact path="/forgot-password" component={ForgotPassword} />
					}
					{
						window && window.location && (window.location.hostname == "localhost" || window.location.origin == "https://app.adcredo.io" || window.location.origin == "https://dev.adcredo.io" || window.location.origin == "https://docker-test.adcredo.io") &&
						<Route exact path="/verification" component={VerificationCode} />
					}
					{
						window && window.location && (window.location.hostname == "localhost" || window.location.origin == "https://app.adcredo.io" || window.location.origin == "https://dev.adcredo.io" || window.location.origin == "https://docker-test.adcredo.io") &&
						<Route exact path="/reset-password/:token" component={ResetPassword} />
					}
					{
						window && window.location && (window.location.hostname == "localhost" || window.location.origin == "https://app.adcredo.io" || window.location.origin == "https://dev.adcredo.io" || window.location.origin == "https://docker-test.adcredo.io") &&
						<Route exact path="/set-password/:token" component={SetPassword} />
					}
					{
						window && window.location && (window.location.hostname == "localhost" || window.location.origin == "https://app.adcredo.io" || window.location.origin == "https://dev.adcredo.io" || window.location.origin == "https://docker-test.adcredo.io") &&
						<Route exact path="/callback/adform" component={Callback} />
					}

					<Route exact path="/callback/linkedin" component={LinkedInPopUp} />
					<Route exact path="/callback/snapchat" component={Callback} />
					<Route exact path="/callback/:platform" component={Callback} />

					{
						window && window.location && (window.location.hostname == "localhost" || window.location.origin == "https://app.adcredo.io" || window.location.origin == "https://dev.adcredo.io" || window.location.origin == "https://docker-test.adcredo.io") &&
						<Route path='/admin' component={LoggedInAdmin} />
					}
					{
						window && window.location && (window.location.hostname == "localhost" || window.location.origin == "https://app.adcredo.io" || window.location.origin == "https://dev.adcredo.io" || window.location.origin == "https://docker-test.adcredo.io") &&
						<Route path="/user" component={checkAuth(User)} />
					}
					{
						window && window.location && (window.location.hostname == "localhost" || window.location.origin == "https://app.adcredo.io" || window.location.origin == "https://dev.adcredo.io" || window.location.origin == "https://docker-test.adcredo.io") &&
						<Route path="/v2" component={checkAuth(LoggedInAgent)} />
					}
					{
						false &&
						window && window.location && (window.location.hostname == "localhost" || window.location.origin == "https://app.adcredo.io" || window.location.origin == "https://dev.adcredo.io" || window.location.origin == "https://docker-test.adcredo.io") &&
						<Route path="/client" component={LoggedInClient} />
					}
					{
						false &&
						window && window.location && (window.location.hostname == "localhost" || window.location.origin == "https://app.adcredo.io" || window.location.origin == "https://dev.adcredo.io" || window.location.origin == "https://docker-test.adcredo.io") &&
						<Route path="/test-onboarding" component={LoginOnboarding} />
					}
					{
						window && window.location && (window.location.hostname == "localhost" || window.location.origin == "https://app.adcredo.io" || window.location.origin == "https://dev.adcredo.io" || window.location.origin == "https://docker-test.adcredo.io") &&
						<Redirect to="/login" />
					}

				</Switch>
			</BrowserRouter>
		);
	}
}

export default Main;
