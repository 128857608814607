import React, { Component, Fragment } from 'react';
import { Popover } from '@headlessui/react';
import {
    ArrowDownIcon, ArrowLeftIcon, ArrowRightIcon,
    ArrowUpIcon,
    PhotographIcon,
    PlusIcon,
    TrashIcon,
    ViewBoardsIcon
} from "@heroicons/react/outline";
import { arrayMove, SortableContainer, SortableElement } from "react-sortable-hoc";
import FeatherIcon from "feather-icons-react";
import SlideDown from "react-slidedown";
import InputTailwind from "../inputTailwind";
import DropdownTailwind from "../dropdownTailwind";
import IconPicker from "../iconPicker";
import SwitchTailwind from "../switchTailwind";
import { CirclePicker } from "react-color";
import TextArea from "../../components/text-area";

class SelectCellSettings extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tab: {},
            selected_channels_internal: {},
            selected_accounts_internal: {},
            selected_campaigns_internal: {},
            selected_adgroups_internal: {},
            selected_ads_internal: {},
            table_default: [
                {
                    title: "preview",
                    description: "Show preview of ad",
                    value: "remove_preview"
                },
                {
                    title: "channel",
                    description: "Shows channel of datasource",
                    value: "remove_channel"
                },
                {
                    title: "breakdown",
                    description: "Show data breakdown",
                    value: "remove_breakdown"
                },
                {
                    title: "breakdown value",
                    description: "Show data breakdown value",
                    value: "remove_breakdown_value"
                },
                {
                    title: "level",
                    description: "Shows if datasource is campaign, adgroup or ad",
                    value: "remove_level"
                },
                {
                    title: "id",
                    description: "Shows id of datasource",
                    value: "remove_id"
                },
                {
                    title: "name",
                    description: "Shows name of datasource",
                    value: "remove_name"
                }
            ],
            placements: [
                { id: 0, name: "Feed", description: "Ads that appear in a user's scrollable content", value: "feed", icon: <ViewBoardsIcon style={{ width: 14, height: 14, transform: 'rotate(-90deg)' }} />, allowed: { facebook: true, linkedin: true, tiktok: true, twitter: true } },
                { id: 3, name: "Feed (Instagram)", description: "Ads that appear in a users scrollable content on Instagram", icon: <ViewBoardsIcon style={{ width: 14, height: 14, transform: 'rotate(-90deg)' }} />, value: "feed_instagram", allowed: { facebook: true } },
                { id: 1, name: "Story", description: "Ads that appear in a user's swipable content", value: "story", icon: <ViewBoardsIcon style={{ width: 14, height: 14 }} />, allowed: { facebook: true, snapchat: true } },
                { id: 4, name: "Story (Instagram)", description: "Ads that appear in a users swipable content on Instagram", icon: <ViewBoardsIcon style={{ width: 14, height: 14 }} />, value: "story_instagram", allowed: { facebook: true } },
                { id: 2, name: "Remove frame", description: "Remove ad frame and only show media", value: "no_frame", icon: <PhotographIcon style={{ width: 14, height: 14 }} />, allowed: { facebook: true, linkedin: true, tiktok: true, twitter: true, snapchat: true } },
                { id: 5, name: "Square", description: "Display ad in square format", value: "square", icon: <PhotographIcon style={{ width: 14, height: 14 }} />, allowed: { google: true } },
                { id: 6, name: "Billboard", description: "Display ad in rectangle format", value: "billboard", icon: <PhotographIcon style={{ width: 14, height: 14 }} />, allowed: { google: true } },
                { id: 6, name: "Skyscraper", description: "Display ad in vertical format", value: "skyscraper", icon: <PhotographIcon style={{ width: 14, height: 14 }} />, allowed: { google: true } },
            ],
            custom_color_string: "",
            selectedId: ""
        };
    };

    async componentDidMount() {
        await this.promisedSetState({
            tab: this.props.tab ? this.props.tab : {},
            open: this.props.open,
            master_template: this.props.masterTemplate,
            template: this.props.template,
            selected_channels_internal: this.props.selectedChannelsInternal ? this.props.selectedChannelsInternal : {},
            selected_accounts_internal: this.props.selectedAccountsInternal ? this.props.selectedAccountsInternal : {},
            selected_campaigns_internal: this.props.selectedCampaignsInternal ? this.props.selectedCampaignsInternal : {},
            selected_adgroups_internal: this.props.selectedAdgroupsInternal ? this.props.selectedAdgroupsInternal : {},
            selected_ads_internal: this.props.selectedAdsInternal ? this.props.selectedAdsInternal : {},
        });
        if (!this.state.tab.settings) {
            this.state.tab.settings = {};
            await this.promisedSetState({
                tab: this.state.tab
            });
        }
    }

    async componentWillReceiveProps(nextProps, nextContext) {
        await this.promisedSetState({
            tab: nextProps.tab ? nextProps.tab : {},
            open: nextProps.open,
            master_template: nextProps.masterTemplate,
            template: nextProps.template,
            selected_channels_internal: nextProps.selectedChannelsInternal ? nextProps.selectedChannelsInternal : {},
            selected_accounts_internal: nextProps.selectedAccountsInternal ? nextProps.selectedAccountsInternal : {},
            selected_campaigns_internal: nextProps.selectedCampaignsInternal ? nextProps.selectedCampaignsInternal : {},
            selected_adgroups_internal: nextProps.selectedAdgroupsInternal ? nextProps.selectedAdgroupsInternal : {},
            selected_ads_internal: nextProps.selectedAdsInternal ? nextProps.selectedAdsInternal : {},
        });
        if (!this.state.tab.settings) {
            this.state.tab.settings = {};
            await this.promisedSetState({
                tab: this.state.tab
            });
        }
    }

    functions = {
        valid: () => {
            return true;
        },
        selectedDataSources: () => {
            let sources = [];
            if (this.state.selected_channels_internal) {
                for (let key in this.state.selected_channels_internal) {
                    sources.push(this.state.selected_channels_internal[key]);
                }
            }
            if (this.state.selected_accounts_internal) {
                for (let key in this.state.selected_accounts_internal) {
                    sources.push(this.state.selected_accounts_internal[key]);
                }
            }
            if (this.state.selected_campaigns_internal) {
                for (let key in this.state.selected_campaigns_internal) {
                    sources.push(this.state.selected_campaigns_internal[key]);
                }
            }
            if (this.state.selected_adgroups_internal) {
                for (let key in this.state.selected_adgroups_internal) {
                    sources.push(this.state.selected_adgroups_internal[key]);
                }
            }
            if (this.state.selected_ads_internal) {
                for (let key in this.state.selected_ads_internal) {
                    sources.push(this.state.selected_ads_internal[key]);
                }
            }
            return sources;
        },
        selectedMetrics: () => {
            let metrics = [];
            if (this.state.tab && this.state.tab.metrics) {
                for (let metric in this.state.tab.metrics) {
                    metrics.push(this.state.tab.metrics[metric]);
                }
            }
            return metrics;
        }
    };

    renders = {
        groupWithSameFields: (group) => {
            try {
                let same = [];
                if (Array.isArray(group.items)) {
                    group.items.map((item) => {
                        if (!same.includes(item.field.toLowerCase())) {
                            same.push(item.field.toLowerCase());
                        }
                    });
                    return same.length !== group.items.length;
                }
            } catch (error) { }
            return false;
        }
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {

        {/*TABLE METRICS SORT*/ }
        const SortableItem = SortableElement(({ item, index }) => {
            return (
                <div style={{ zIndex: 99999999 }}
                    className="w-full flex flex-row justify-center cursor-pointer align-middle items-center mb-2">
                    <div
                        className="text-purple-500 text-sm font-medium bg-purple-100 h-10 w-10 flex rounded justify-center align-middle items-center">
                        {item.index + 1}
                    </div>
                    <div className="text-xs flex flex-1 ml-2">
                        <div className="flex flex-1 flex-col">
                            <div className="font-medium text-sm">
                                {item.alias ? item.alias : item.name}
                            </div>
                            <div className="text-xxs capitalize">
                                {item.calculated ? "Calculated metric" : "Custom metric"}
                            </div>
                        </div>
                    </div>
                    <div
                        className="text-purple-500 text-xs cursor-pointer bg-purple-100 h-10 w-10 flex rounded font-bold justify-center align-middle items-center">
                        <FeatherIcon className="stroke-current transform rotate-90"
                            size={16}
                            icon={'code'}
                        />
                    </div>
                </div>
            )
        });
        const SortableList = SortableContainer(({ items }) => (
            <div className="">
                {items.map((item, index) => {
                    item.index = index;
                    if (item) {
                        return (
                            <SortableItem
                                index={index}
                                item={item}
                            />
                        )
                    }
                })}
            </div>
        ));
        const onSortEnd = ({ oldIndex, newIndex }) => {
            if (!Array.isArray(this.state.tab.table_metrics_index)) {
                this.state.tab.table_metrics_index = [];
            }
            this.state.tab.table_metrics_index = arrayMove(this.state.tab.table_metrics_index, oldIndex, newIndex);
            this.props.onTab(this.state.tab);
        };

        return (
            <>
                <div
                    className={(this.functions.valid() ? " " : "opacity-50 ") + "px-4 sm:px-6 pb-6 pt-6"}>
                    <div className="flex flex-1 flex-row justify-center items-center">
                        <div onClick={() => {
                            this.props.onToggle(this.state.open);
                        }} className="flex flex-col flex-1 cursor-pointer">
                            <h3 className="mb-0 font-semibold text-sm">
                                Settings
                            </h3>
                            <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                {this.state.tab.celltype && this.state.tab.celltype.value === "preview" ? "Select ad placement" : "Select color of each data source and edit data"}
                            </p>
                        </div>
                        {
                            this.functions.valid() &&
                            <div className="flex flex-row">
                                {
                                    this.functions.valid() &&
                                    <div
                                        className="mr-2 text-purple-500 border-purple-500 border-2 bg-purple-100 cursor-pointer h-10 w-10 flex rounded font-bold justify-center align-middle items-center">
                                        <FeatherIcon className={'stroke-current'} size={16}
                                            icon={"check"} />
                                    </div>
                                }
                                <div onClick={() => {
                                    this.props.onToggle(this.state.open);
                                }}
                                    className="text-purple-500 bg-purple-100 cursor-pointer h-10 w-10 flex rounded font-bold justify-center align-middle items-center">
                                    {
                                        !this.state.open &&
                                        <FeatherIcon className={'stroke-current'} size={16}
                                            icon={"chevron-down"} />
                                    }
                                    {
                                        this.state.open &&
                                        <FeatherIcon className={'stroke-current'} size={16}
                                            icon={"chevron-up"} />
                                    }
                                </div>
                            </div>
                        }
                    </div>
                    <SlideDown closed={!this.state.open}>
                        <div className="w-full h-6" />
                        {
                            this.state.tab.celltype && (this.state.tab.celltype.value === "chart" || this.state.tab.celltype.value === "number" || this.state.tab.celltype.value === "table") &&
                            <div className="grid grid-cols-12 gap-4">
                                <div className={`col-span-${(this.state.tab.celltype.value === "number" && !(this.state.tab.celltype && this.state.tab.celltype.id === "performance")) ? '6' : '12'}`}>
                                    <div className="flex flex-1 flex-col mb-4 justify-center">
                                        <InputTailwind
                                            label={"Cell title"}
                                            value={this.state.tab.settings ? this.state.tab.settings.title : ""}
                                            onChange={(value) => {
                                                if (!this.state.tab.settings) {
                                                    this.state.tab.settings = { title: "" };
                                                }
                                                this.state.tab.settings.title = value;
                                                this.props.onTab(this.state.tab);
                                            }}
                                        />
                                    </div>
                                </div>
                                {
                                    this.state.tab.celltype.value === "number" &&
                                    !(this.state.tab.celltype && this.state.tab.celltype.id === "performance") &&
                                    <div className="col-span-6">
                                        <DropdownTailwind
                                            label={"Text align"}
                                            selected={this.state.tab.settings && this.state.tab.settings.align && this.state.tab.settings.align.value ? this.state.tab.settings.align : {
                                                id: 1,
                                                title: "Center",
                                                name: "Center",
                                                value: "center"
                                            }}
                                            options={[
                                                { id: 1, title: "Center", name: "Center", value: "center" },
                                                { id: 2, title: "Right", name: "Right", value: "end" },
                                                { id: 3, title: "Left", name: "Left", value: "left" }
                                            ]}
                                            onChange={(value) => {
                                                this.state.tab.settings.align = value;
                                                this.props.onTab(this.state.tab);
                                            }}
                                        />
                                    </div>
                                }
                            </div>
                        }
                        {
                            this.state.tab.celltype && this.state.tab.celltype.value === "number" &&
                            !(this.state.tab.celltype && this.state.tab.celltype.id === "performance") &&
                            <div>
                                <div className="grid grid-cols-12 gap-4 relative">
                                    <div className="col-span-6">
                                        <Popover>
                                            {({ open, close }) => {
                                                return (
                                                    <div className="flex flex-1 flex-col mb-4 justify-center">
                                                        <div className="text-xs font-medium mb-1">
                                                            Icon
                                                        </div>
                                                        <div className="flex truncate">
                                                            <Popover.Button
                                                                className={`w-full border-1.5 flex items-center truncate focus:outline-none px-2 py-3 text-sm pl-3 font-medium rounded-md bg-gray-50 cursor-pointer`}>
                                                                {this.state.tab.settings && this.state.tab.settings.icon ? this.state.tab.settings.icon.name : "Click to select"}
                                                            </Popover.Button>
                                                            {
                                                                this.state.tab.settings && this.state.tab.settings.icon &&
                                                                <button
                                                                    onClick={() => {
                                                                        delete this.state.tab.settings.icon;
                                                                        this.props.onTab(this.state.tab);
                                                                    }}
                                                                    className="ml-2 rounded-md border bg-red-500 text-white flex flex-row items-center justify-center font-bold text-xs cursor-pointer min-w-12 min-h-12 h-12 w-12">
                                                                    <FeatherIcon
                                                                        size={16}
                                                                        icon="trash" />
                                                                </button>
                                                            }
                                                        </div>
                                                        <Popover.Panel
                                                            transition
                                                            style={{ top: "70px" }}
                                                            className="origin-top-right absolute w-full flex overflow-hidden z-50 border-1.5 right-0 mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                            <div
                                                                className="flex-auto overflow-hidden rounded-lg bg-white text-sm leading-6 shadow-lg ring-1 ring-gray-900/5">
                                                                <IconPicker
                                                                    filter={this.state.tab.settings && this.state.tab.settings.icon ? this.state.tab.settings.icon.name : null}
                                                                    onSelect={(val) => {
                                                                        if (!this.state.tab.settings) {
                                                                            this.state.tab.settings = { title: "" }
                                                                        }
                                                                        if (this.state.tab.settings.icon) {
                                                                            this.state.tab.settings.icon.name = val.name;
                                                                            this.state.tab.settings.icon.path = val.path;
                                                                        } else {
                                                                            this.state.tab.settings.icon = {
                                                                                name: val.name,
                                                                                path: val.path,
                                                                                size: {
                                                                                    id: 2,
                                                                                    title: "Medium",
                                                                                    name: "Medium",
                                                                                    value: "medium"
                                                                                }
                                                                            };
                                                                        }
                                                                        this.props.onTab(this.state.tab);
                                                                        close();
                                                                    }}>
                                                                </IconPicker>
                                                            </div>
                                                        </Popover.Panel>
                                                    </div>
                                                )
                                            }}
                                        </Popover>
                                    </div>
                                    <div className="col-span-6 relative">
                                        <DropdownTailwind
                                            label={"Icon size"}
                                            selected={this.state.tab.settings && this.state.tab.settings.icon ? this.state.tab.settings.icon.size : { id: 0, name: "Select size" }}
                                            options={[
                                                { id: 1, title: "Small", name: "Small", value: "small" },
                                                { id: 2, title: "Medium", name: "Medium", value: "medium" },
                                                { id: 3, title: "Large", name: "Large", value: "large" }
                                            ]}
                                            onChange={(value) => {
                                                this.state.tab.settings.icon.size = value;
                                                this.props.onTab(this.state.tab);
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            this.state.tab.celltype && this.state.tab.celltype.value === "number" &&
                            this.state.tab.celltype && this.state.tab.celltype.id !== "performance" &&
                            <div className="mb-4 w-full grid grid-cols-12 gap-4">
                                <div
                                    className={this.state.tab.settings && this.state.tab.settings.customNumber ? "col-span-6" : "col-span-12"}>
                                    <div className="text-xs font-medium mb-1">
                                        Custom value
                                    </div>
                                    <div className='flex flex-col w-full'>
                                        <div className='flex items-center justify-center border-gray-300 w-full bg-custom-input border-1.5 rounded-md' style={{ paddingTop: "0.5rem", paddingBottom: "0.6rem" }}>
                                            <div className="flex flex-1 ml-5 text-sm">
                                                {this.state.tab.settings && this.state.tab.settings.customNumber ? "Enabled" : "Disabled"}
                                            </div>
                                            <div className="relative overflow-hidden mr-5">
                                                <SwitchTailwind
                                                    value={this.state.tab.settings && this.state.tab.settings.customNumber ? true : false}
                                                    onSwitch={async () => {
                                                        if (!this.state.tab.settings) {
                                                            this.state.tab.settings = { title: "" };
                                                        }
                                                        this.state.tab.settings.customNumberValue = "";
                                                        this.state.tab.settings.customNumber = !this.state.tab.settings.customNumber;
                                                        this.props.onTab(this.state.tab);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {
                                    this.state.tab.settings && this.state.tab.settings.customNumber &&
                                    <div className="col-span-6">
                                        <div className="text-xs text-opacity-0 font-medium">
                                            Value
                                        </div>
                                        <div className='flex flex-col w-full'>
                                            <InputTailwind
                                                disabled={!(this.state.tab.settings && this.state.tab.settings.customNumber)}
                                                value={this.state.tab.settings.customNumberValue}
                                                label=''
                                                placeholder='number'
                                                onChange={(value) => {
                                                    this.state.tab.settings.customNumberValue = value;
                                                    this.props.onTab(this.state.tab);
                                                }}
                                            />
                                        </div>
                                    </div>
                                }
                            </div>
                        }
                        {
                            this.state.tab.celltype && this.state.tab.celltype.value === "chart" &&
                            <div className="grid grid-cols-12 gap-4 mb-4">
                                <div className={this.state.tab.chart && this.state.tab.chart.value == "donut" && !(this.state.tab.settings && this.state.tab.settings.show_datasources && this.state.tab.settings.show_datasources.value == "disabled") ? "col-span-4" : "col-span-6"}>
                                    <DropdownTailwind
                                        label={"Show inline values"}
                                        selected={this.state.tab.settings && this.state.tab.settings.chart_numbers && this.state.tab.settings.chart_numbers.value ? this.state.tab.settings.chart_numbers : { id: 1, title: "Disabled", name: "Disabled", subtitle: "Inline values will only show on hover", value: "disabled", default: true }}
                                        options={[
                                            { id: 1, title: "Disabled", name: "Disabled", subtitle: "Inline values will only show on hover", value: "disabled", default: true },
                                            { id: 2, title: "Enabled", name: "Enabled", subtitle: "Inline values will be visible", value: "enabled" }
                                        ]}
                                        onChange={(value) => {
                                            if (!this.state.tab.settings) {
                                                this.state.tab.settings = { title: "" };
                                            }
                                            this.state.tab.settings.chart_numbers = value;
                                            this.props.onTab(this.state.tab);
                                        }}
                                    />
                                </div>
                                <div className={this.state.tab.chart && this.state.tab.chart.value == "donut" && !(this.state.tab.settings && this.state.tab.settings.show_datasources && this.state.tab.settings.show_datasources.value == "disabled") ? "col-span-4" : "col-span-6"}>
                                    <DropdownTailwind
                                        label={"Show datasource labels"}
                                        selected={this.state.tab.settings && this.state.tab.settings.show_datasources && this.state.tab.settings.show_datasources.value ? this.state.tab.settings.show_datasources : { id: 2, title: "Enabled", name: "Enabled", subtitle: "Datasources values will be visible", value: "enabled", default: true }}
                                        options={[
                                            { id: 1, title: "Disabled", name: "Disabled", subtitle: "Datasource labels wont show", value: "disabled" },
                                            { id: 2, title: "Enabled", name: "Enabled", subtitle: "Datasource labels will be visible", value: "enabled", default: true }
                                        ]}
                                        onChange={(value) => {
                                            if (!this.state.tab.settings) {
                                                this.state.tab.settings = { title: "" };
                                            }
                                            this.state.tab.settings.show_datasources = value;
                                            this.props.onTab(this.state.tab);
                                        }}
                                    />
                                </div>
                                {
                                    this.state.tab.chart && this.state.tab.chart.value == "donut" &&
                                    !(this.state.tab.settings && this.state.tab.settings.show_datasources && this.state.tab.settings.show_datasources.value == "disabled") &&
                                    <div className="col-span-4">
                                        <DropdownTailwind
                                            label={"Datasource label position"}
                                            selected={this.state.tab.settings && this.state.tab.settings.position_datasources && this.state.tab.settings.position_datasources.value ? this.state.tab.settings.position_datasources : { id: 1, title: "Left", name: "Left", subtitle: "Datasource labels will show on left side", value: "left", default: true }}
                                            options={[
                                                { id: 1, title: "Left", name: "Left", subtitle: "Datasource labels will show on left side", value: "left", default: true },
                                                { id: 2, title: "Top", name: "Top", subtitle: "Datasource labels will show on top", value: "top" },
                                                { id: 3, title: "Right", name: "Right", subtitle: "Datasource labels will show on right side", value: "right" },
                                                { id: 4, title: "Bottom", name: "Bottom", subtitle: "Datasource labels will show on bottom", value: "bottom" },
                                                { id: 5, title: "Sections", name: "Sections", subtitle: "Datasource labels will show outside each section", value: "sections" }
                                            ]}
                                            onChange={(value) => {
                                                if (!this.state.tab.settings) {
                                                    this.state.tab.settings = { title: "" };
                                                }
                                                this.state.tab.settings.position_datasources = value;
                                                this.props.onTab(this.state.tab);
                                            }}
                                        />
                                    </div>
                                }
                                {
                                    (this.state.tab.chart && (this.state.tab.chart.value === "line" || this.state.tab.chart.value === "bar") && ((this.functions.selectedDataSources().length === 2 && this.functions.selectedMetrics().length === 1) || (this.functions.selectedDataSources().length === 1 && this.functions.selectedMetrics().length === 2))) &&
                                    <div className="col-span-6">
                                        <DropdownTailwind
                                            label={"Multi Y axis"}
                                            selected={this.state.tab.settings && this.state.tab.settings.multi_y_axis && this.state.tab.settings.multi_y_axis.value ? this.state.tab.settings.multi_y_axis : { id: 1, title: "Disabled", name: "Disabled", value: "disabled", default: true }}
                                            options={[
                                                { id: 1, title: "Disabled", name: "Disabled", value: "disabled" },
                                                { id: 2, title: "Enabled", name: "Enabled", value: "enabled", default: true }
                                            ]}
                                            onChange={(value) => {
                                                if (!this.state.tab.settings) {
                                                    this.state.tab.settings = { title: "" };
                                                }
                                                this.state.tab.settings.multi_y_axis = value;
                                                this.props.onTab(this.state.tab);
                                            }}
                                        />
                                    </div>
                                }
                            </div>
                        }
                        {
                            this.state.tab.celltype && (this.state.tab.celltype.value === "chart") &&
                            <div className="grid grid-cols-12 gap-4 mb-4">
                                {
                                    ((this.state.tab.chart && this.state.tab.chart.value === "bar" && this.functions.selectedDataSources().length < 2) || (this.state.tab.chart && this.state.tab.chart.value == "donut")) &&
                                    <div className="col-span-4">
                                        <DropdownTailwind
                                            label={"Sort"}
                                            selected={this.state.tab.settings && this.state.tab.settings.chart_sort && this.state.tab.settings.chart_sort.value ? this.state.tab.settings.chart_sort : { id: 1, title: "No sort", name: "No sort", subtitle: "Dont sort chart", value: "none", default: true }}
                                            options={[
                                                { id: 1, title: "No sort", name: "No sort", subtitle: "Dont sort chart", value: "none", default: true },
                                                { id: 2, title: "Descending", name: "Descending", subtitle: "Sort values by highest to lowest", value: "descending" },
                                                { id: 3, title: "Ascending", name: "Ascending", subtitle: "Sort values by lowest to highest", value: "ascending" }
                                            ]}
                                            onChange={(value) => {
                                                if (!this.state.tab.settings) {
                                                    this.state.tab.settings = { title: "" };
                                                }
                                                this.state.tab.settings.chart_sort = value;
                                                this.props.onTab(this.state.tab);
                                            }}
                                        />
                                    </div>
                                }
                                <div className={((this.state.tab.chart && this.state.tab.chart.value === "bar" && this.functions.selectedDataSources().length < 2) || (this.state.tab.chart && this.state.tab.chart.value == "donut")) ? "col-span-4" : "col-span-6"}>
                                    <InputTailwind
                                        label={"Labels limit (number)"}
                                        value={this.state.tab.settings ? this.state.tab.settings.label_limit : ""}
                                        onChange={(value) => {
                                            if (!this.state.tab.settings) {
                                                this.state.tab.settings = { title: "" };
                                            }
                                            this.state.tab.settings.label_limit = value;
                                            this.props.onTab(this.state.tab);
                                        }}
                                    />
                                </div>
                                <div className={((this.state.tab.chart && this.state.tab.chart.value === "bar" && this.functions.selectedDataSources().length < 2) || (this.state.tab.chart && this.state.tab.chart.value == "donut")) ? "col-span-4" : "col-span-6"}>
                                    <InputTailwind
                                        label={"Label character limit (number)"}
                                        value={this.state.tab.settings ? this.state.tab.settings.limit_characters : ""}
                                        onChange={(value) => {
                                            if (!this.state.tab.settings) {
                                                this.state.tab.settings = { title: "" };
                                            }
                                            this.state.tab.settings.limit_characters = value;
                                            this.props.onTab(this.state.tab);
                                        }}
                                    />
                                </div>
                            </div>
                        }
                        {
                            this.state.tab.celltype && this.state.tab.celltype.value === "chart" &&
                            <div className="w-full mb-4">
                                <div className="text-xs font-medium mb-1">
                                    Single default chart color
                                </div>
                                <div className={"w-full border-1.5 items-center justify-center flex flex-row rounded-md"}>
                                    <div className="ml-4">
                                        <SwitchTailwind
                                            value={this.state.tab.settings && this.state.tab.settings.use_default_color ? true : false}
                                            onSwitch={async () => {
                                                if (!this.state.tab.settings) {
                                                    this.state.tab.settings = { title: "" };
                                                }
                                                this.state.tab.settings.default_color = "453FF1";
                                                this.state.tab.settings.use_default_color = !this.state.tab.settings.use_default_color;
                                                this.props.onTab(this.state.tab);
                                            }}
                                        />
                                    </div>
                                    <div className='overflow-hidden flex flex-1 rounded-md relative'>
                                        {
                                            !(this.state.tab.settings && this.state.tab.settings.use_default_color) &&
                                            <div className="absolute right-0 left-0 top-0 bottom-0 bg-white bg-opacity-75 cursor-not-allowed"></div>
                                        }
                                        <div
                                            style={{ backgroundColor: "#fcfcfc" }}
                                            className="flex items-center flex-1 p-1">
                                            <span className={`pl-3`}>#</span>
                                            <input
                                                id="default_color"
                                                className="w-full border-none p-2 bg-transparent"
                                                type="text"
                                                placeholder=''
                                                value={this.state.tab.settings && this.state.tab.settings.default_color ? this.state.tab.settings.default_color : ""}
                                                onClick={(e) => {
                                                    this.setState({ selectedId: e.target.id })
                                                }}
                                                onChange={async (e) => {
                                                    if (!this.state.tab.settings) {
                                                        this.state.tab.settings = { title: "" };
                                                    }
                                                    e.target.value = e.target.value.replace(/#/g, "")
                                                    this.state.tab.settings.default_color = e.target.value;
                                                    this.props.onTab(this.state.tab);
                                                }}
                                            />
                                        </div>
                                        <div className="relative overflow-hidden w-16 border-l-1.5">
                                            {
                                                (this.state.tab.settings && this.state.tab.settings.use_default_color) &&
                                                <input
                                                    style={{ width: "100%", height: 65, bottom: -10 }}
                                                    className="absolute cursor-pointer"
                                                    type="color"
                                                    value={"#" + (this.state.tab.settings && this.state.tab.settings.default_color ? this.state.tab.settings.default_color : "ffffff")}
                                                    onChange={async (e) => {
                                                        if (!this.state.tab.settings) {
                                                            this.state.tab.settings = { title: "" };
                                                        }
                                                        let color_string = e.target.value.slice(1)
                                                        this.state.tab.settings.default_color = color_string;
                                                        this.props.onTab(this.state.tab);
                                                    }}
                                                />
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            !(this.state.tab.settings && this.state.tab.settings.use_default_color) &&
                            this.state.tab.celltype && this.state.tab.celltype.value === "chart" &&
                            <div className="w-full">
                                <DropdownTailwind
                                    label={"Multiple chart colors"}
                                    selected={this.state.tab.settings && this.state.tab.settings.chart_colors && this.state.tab.settings.chart_colors.value ? this.state.tab.settings.chart_colors : { id: 1, title: "Datasource/Metric", name: "Set color by Datasource/Metric", value: "datasource_metric", default: true }}
                                    options={[
                                        { id: 1, title: "Datasource/Metric", name: "Set color by Datasource/Metric", value: "datasource_metric", default: true },
                                        { id: 2, title: "Metric", name: "Set color by Metric", value: "metric" }
                                    ]}
                                    onChange={(value) => {
                                        if (!this.state.tab.settings) {
                                            this.state.tab.settings = { title: "" };
                                        }
                                        this.state.tab.settings.chart_colors = value;
                                        this.props.onTab(this.state.tab);
                                    }}
                                />
                            </div>
                        }
                        {
                            this.state.tab.chart && this.state.tab.chart.value == "donut" &&
                            <div className="w-full mt-4">
                                <div
                                    className={`-mb-4 text-xs font-medium text-gray-700 flex flex-1`}>
                                    Use additional color scale
                                </div>
                                <div
                                    className='flex items-center justify-center mt-5 border-gray-300 w-full bg-custom-input border-1.5 rounded-md'
                                    style={{
                                        paddingTop: "0.5rem",
                                        paddingBottom: "0.6rem"
                                    }}>
                                    <div
                                        className={`flex flex-1 font-medium text-xs ml-4`}>
                                        {this.state.tab.settings && this.state.tab.settings.use_additional_color_scale ? "Enabled" : "Disabled"}
                                    </div>
                                    <div
                                        className="relative overflow-hidden mr-5">
                                        <SwitchTailwind
                                            value={this.state.tab.settings && this.state.tab.settings.use_additional_color_scale ? true : false}
                                            onSwitch={async () => {
                                                if (!this.state.tab.settings) {
                                                    this.state.tab.settings = { title: "" };
                                                }
                                                this.state.tab.settings.use_additional_color_scale = !this.state.tab.settings.use_additional_color_scale;
                                                this.props.onTab(this.state.tab);
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            !(this.state.tab.settings && this.state.tab.settings.use_default_color) &&
                            this.state.tab.celltype && this.state.tab.celltype.value === "chart" &&
                            <div className="mt-4 mb-4">
                                <div className="text-xs font-medium mb-1">
                                    {this.state.tab.settings && this.state.tab.settings.chart_colors ? this.state.tab.settings && this.state.tab.settings.chart_colors.name : "Datasource/Metric"}
                                </div>
                                {
                                    ((this.state.tab.settings && this.state.tab.settings.chart_colors && this.state.tab.settings.chart_colors.value == "metric") ? [{
                                        type: "metric",
                                        id: "metric",
                                        hex: "#453FF1"
                                    }] : this.functions.selectedDataSources()).map((item, i) => {
                                        return (
                                            <Fragment key={i}>
                                                {
                                                    this.functions.selectedMetrics().map((metric, index) => {
                                                        return (
                                                            <Fragment key={i + index}>
                                                                <div
                                                                    className="flex flex-1 flex-row justify-center mb-2">
                                                                    <div onClick={() => {
                                                                        if (metric.setColor) {
                                                                            delete metric.setColor;
                                                                        } else {
                                                                            metric.setColor = i + "_" + index;
                                                                        }
                                                                        this.props.onSelected({
                                                                            selected_channels_internal: this.state.selected_channels_internal,
                                                                            selected_accounts_internal: this.state.selected_accounts_internal,
                                                                            selected_campaigns_internal: this.state.selected_campaigns_internal,
                                                                            selected_adgroups_internal: this.state.selected_adgroups_internal,
                                                                            selected_ads_internal: this.state.selected_ads_internal
                                                                        });
                                                                    }}
                                                                        style={{ backgroundColor: (this.state.tab.settings && this.state.tab.settings.metric_color && this.state.tab.settings.metric_color[item.id] && this.state.tab.settings.metric_color[item.id][metric.id] ? this.state.tab.settings.metric_color[item.id][metric.id].hex : (typeof item.hex === "string" ? "#" + item.hex.replace("#", "") : "#ffffff")) }}
                                                                        className="cursor-pointer mr-2 h-10 w-10 rounded-full text-purple-500 flex justify-center align-middle items-center">
                                                                    </div>
                                                                    <div
                                                                        className="flex flex-1 truncate flex-col">
                                                                        {
                                                                            item.type !== "metric" &&
                                                                            <div
                                                                                className="font-medium truncate text-sm">
                                                                                <span className="text-purple-500 mr-2">{metric.name}:</span>{item.name}
                                                                            </div>
                                                                        }
                                                                        {
                                                                            item.type == "metric" &&
                                                                            <div
                                                                                className="font-medium text-sm">
                                                                                <span className="text-purple-500 mr-2">{metric.name}</span>
                                                                            </div>
                                                                        }
                                                                        <div className="text-xxs">
                                                                            {this.state.tab.settings && this.state.tab.settings.metric_color && this.state.tab.settings.metric_color[item.id] && this.state.tab.settings.metric_color[item.id][metric.id] ? this.state.tab.settings.metric_color[item.id][metric.id].hex : item.hex}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {
                                                                    metric.setColor == (i + "_" + index) &&
                                                                    <div className="mt-3">
                                                                        <div className="flex w-full mb-4">
                                                                            <div className="flex flex-col w-full">
                                                                                <div
                                                                                    className={`w-full border-1.5 rounded-md ${this.state.selectedId === "custom_color_string" ? "border-purple-500" : ""}`}>
                                                                                    <div
                                                                                        className='grid grid-cols-4 overflow-hidden rounded-md'>
                                                                                        <div
                                                                                            style={{ backgroundColor: "#fcfcfc" }}
                                                                                            className="flex items-center col-span-3 p-1">
                                                                                            <span
                                                                                                className={`pl-3`}>#</span>
                                                                                            <input
                                                                                                id="custom_color_string"
                                                                                                className={`w-full border-none p-2 bg-transparent`}
                                                                                                type="text"
                                                                                                placeholder='5a67d8'
                                                                                                value={this.state.tab.settings && this.state.tab.settings.metric_color && this.state.tab.settings.metric_color[item.id] && this.state.tab.settings.metric_color[item.id][metric.id] ? this.state.tab.settings.metric_color[item.id][metric.id].hex.replace(/#/g, "") : item.hex ? item.hex.replace(/#/g, "") : ""}
                                                                                                onClick={(e) => {
                                                                                                    this.setState({ selectedId: e.target.id })
                                                                                                }}
                                                                                                onChange={async (e) => {
                                                                                                    if (!this.state.tab.settings) {
                                                                                                        this.state.tab.settings = { title: '' }
                                                                                                    }
                                                                                                    if (!("metric_color" in this.state.tab.settings)) {
                                                                                                        this.state.tab.settings.metric_color = {};
                                                                                                    }
                                                                                                    if (!(item.id in this.state.tab.settings.metric_color)) {
                                                                                                        this.state.tab.settings.metric_color[item.id] = {};
                                                                                                    }
                                                                                                    this.state.tab.settings.metric_color[item.id][metric.id] = { hex: "#" + e.target.value, rgb: "" };
                                                                                                    this.props.onTab(this.state.tab);
                                                                                                }}
                                                                                            />
                                                                                        </div>
                                                                                        <div className="relative overflow-hidden col-span-1 border-l-1.5">
                                                                                            <input
                                                                                                style={{ width: 300, height: 65, bottom: -10 }}
                                                                                                className="absolute cursor-pointer"
                                                                                                type="color"
                                                                                                value={this.state.tab.settings && this.state.tab.settings.metric_color && this.state.tab.settings.metric_color[item.id] && this.state.tab.settings.metric_color[item.id][metric.id] ? this.state.tab.settings.metric_color[item.id][metric.id].hex : item.hex}
                                                                                                onChange={async (e) => {
                                                                                                    if (!this.state.tab.settings) {
                                                                                                        this.state.tab.settings = { title: '' }
                                                                                                    }
                                                                                                    if (!("metric_color" in this.state.tab.settings)) {
                                                                                                        this.state.tab.settings.metric_color = {};
                                                                                                    }
                                                                                                    if (!(item.id in this.state.tab.settings.metric_color)) {
                                                                                                        this.state.tab.settings.metric_color[item.id] = {};
                                                                                                    }
                                                                                                    this.state.tab.settings.metric_color[item.id][metric.id] = { hex: e.target.value, rgb: "" };
                                                                                                    this.props.onTab(this.state.tab);
                                                                                                }}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <CirclePicker
                                                                            width={"100%"}
                                                                            colors={["#3DEDB0", "#FF5900", "#3A5998", "#4384F4", "#0D65A1", "#50ABF1", "#e8710a", "#34A853", "#60C1A4", "#f44336", "#e91e63", "#9c27b0", "#673ab7", "#3f51b5", "#2196f3", "#03a9f4", "#00bcd4", "#009688", "#4caf50", "#8bc34a", "#cddc39", "#ffeb3b", "#ffc107", "#ff9800", "#ff5722", "#795548", "#607d8b", "#000000"]}
                                                                            circleSize={41}
                                                                            onChange={async (value) => {
                                                                                metric.setColor = false;
                                                                                if (!this.state.tab.settings) {
                                                                                    this.state.tab.settings = { title: '' }
                                                                                }
                                                                                if (!("metric_color" in this.state.tab.settings)) {
                                                                                    this.state.tab.settings.metric_color = {};
                                                                                }
                                                                                if (!(item.id in this.state.tab.settings.metric_color)) {
                                                                                    this.state.tab.settings.metric_color[item.id] = {};
                                                                                }
                                                                                this.state.tab.settings.metric_color[item.id][metric.id] = { hex: value.hex, rgb: value.rgb };
                                                                                this.props.onTab(this.state.tab);
                                                                            }}
                                                                        />
                                                                    </div>

                                                                }
                                                            </Fragment>
                                                        )
                                                    })
                                                }

                                            </Fragment>
                                        )
                                    })
                                }
                            </div>
                        }
                        {
                            this.state.tab.celltype && this.state.tab.celltype.value === "table" &&
                            <div className="flex flex-1 flex-col justify-center mb-4">
                                <div className="text-xs font-medium mb-2">
                                    Table columns
                                </div>
                                <div>
                                    {
                                        this.state.table_default.map((item) => {
                                            return (
                                                <Fragment>
                                                    <div
                                                        className="w-full flex flex-row justify-center cursor-pointer align-middle items-center">
                                                        <div
                                                            className="text-purple-500 text-xs font-bold bg-purple-100 h-10 w-10 flex rounded  justify-center align-middle items-center">
                                                            <FeatherIcon
                                                                className={'stroke-current'}
                                                                size={16}
                                                                icon="lock" />
                                                        </div>
                                                        <div onClick={() => {
                                                            if (!this.state.tab.settings) {
                                                                this.state.tab.settings = {};
                                                            }
                                                            item.open = !item.open;
                                                            if (item.open) {
                                                                this.state.table_default = this.state.table_default.map((inner_item) => {
                                                                    if (inner_item.title !== item.title) {
                                                                        inner_item.open = false;
                                                                    }
                                                                    return inner_item;
                                                                });
                                                            }
                                                            this.setState({
                                                                tab: this.state.tab,
                                                                table_default: this.state.table_default
                                                            });
                                                            this.props.onTab(this.state.tab);
                                                        }}
                                                            className="text-purple-500 ml-2 text-xs font-bold bg-purple-100 h-10 w-10 flex rounded cursor-pointer justify-center align-middle items-center">
                                                            {
                                                                !item.open &&
                                                                <FeatherIcon
                                                                    className={'stroke-current'}
                                                                    size={16}
                                                                    icon="plus" />
                                                            }
                                                            {
                                                                item.open &&
                                                                <FeatherIcon
                                                                    className={'stroke-current'}
                                                                    size={16}
                                                                    icon="minus" />
                                                            }
                                                        </div>
                                                        <div className="text-xs flex flex-1 ml-2">
                                                            <div
                                                                className="flex flex-1 flex-col truncate">
                                                                <div
                                                                    className="font-medium text-sm truncate capitalize">
                                                                    {this.state.tab.settings && this.state.tab.settings[item.value + "_title"] && this.state.tab.settings[item.value + "_title"] !== "" ? this.state.tab.settings[item.value + "_title"] : item.title}
                                                                </div>
                                                                <div className="text-xxs">
                                                                    {item.description}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div onClick={() => {
                                                            this.state.tab[item.value] = !this.state.tab[item.value];
                                                            this.props.onTab(this.state.tab);
                                                        }}
                                                            className={((!this.state.tab[item.value]) ? "border-purple-500 bg-purple-100" : "") + " cursor-pointer h-10 w-10 rounded text-purple-500 border-2 flex justify-center align-middle items-center"}>
                                                            {
                                                                !this.state.tab[item.value] &&
                                                                <FeatherIcon
                                                                    className={'stroke-current'}
                                                                    size={16}
                                                                    icon="check" />
                                                            }
                                                        </div>
                                                    </div>
                                                    {
                                                        item.open &&
                                                        this.state.tab.settings &&
                                                        <div className="w-full mt-2">
                                                            <InputTailwind
                                                                value={this.state.tab.settings[item.value + "_title"]}
                                                                placeholder={"Change column name"}
                                                                onChange={(value) => {
                                                                    if (!this.state.tab.settings) {
                                                                        this.state.tab.settings = {};
                                                                    }
                                                                    this.state.tab.settings[item.value + "_title"] = value;
                                                                    this.props.onTab(this.state.tab);
                                                                }}
                                                            />
                                                            {
                                                                false &&
                                                                <input
                                                                    onChange={(event) => {
                                                                        if (!this.state.tab.settings) {
                                                                            this.state.tab.settings = {};
                                                                        }
                                                                        this.state.tab.settings[item.value + "_title"] = event.target.value;
                                                                        this.props.onTab(this.state.tab);
                                                                    }}
                                                                    placeholder={"Change column name"}
                                                                    value={this.state.tab.settings[item.value + "_title"]}
                                                                    className="h-10 w-full rounded text-purple-500 border-2 flex pl-4 pr-4 box-border"
                                                                />
                                                            }
                                                        </div>
                                                    }
                                                    <div className="w-full h-2"></div>
                                                </Fragment>

                                            )
                                        })
                                    }
                                </div>
                                <div>
                                    <SortableList
                                        items={Array.isArray(this.state.tab.table_metrics_index) ? this.state.tab.table_metrics_index : []}
                                        onSortEnd={onSortEnd}
                                        axis="y"
                                        distance={1}
                                    />
                                </div>
                            </div>
                        }

                        {
                            this.state.tab.celltype && this.state.tab.celltype.value === "preview" &&
                            (((this.state.selected_channels_internal && Object.keys(this.state.selected_channels_internal).length < 1) &&
                                (this.state.selected_accounts_internal && Object.keys(this.state.selected_accounts_internal).length < 1)) || this.state.template) &&
                            <>
                                {
                                    this.state.placements.filter((placement) => {
                                        if (this.state.template) {
                                            return placement.id == 0 || placement.id == 2;
                                        } else {
                                            return placement.allowed[this.state.selected_ads_internal && this.state.selected_ads_internal[Object.keys(this.state.selected_ads_internal)[0]].channel]
                                        }
                                    }).map((item) => {
                                        return (
                                            <div className='cursor-pointer mb-2 flex flex-1'
                                                key={item.id}
                                            >
                                                <div className='text-purple-500 bg-purple-100 h-8 w-8 flex rounded font-bold mr-2 justify-center align-middle items-center'>
                                                    {item.icon}
                                                </div>
                                                <div
                                                    onClick={() => {
                                                        if (!this.state.tab.settings) {
                                                            this.state.tab.settings = { title: "" };
                                                        }
                                                        this.state.tab.settings.selected_placement = item;
                                                        this.props.onTab(this.state.tab);
                                                    }}
                                                    className={`h-8 w-8 p-1 rounded-full border-2 mr-2 flex justify-center align-middle items-center ${this.state.tab.settings && this.state.tab.settings.selected_placement && this.state.tab.settings.selected_placement.value === item.value && "border-purple-500"}`}>
                                                    {
                                                        this.state.tab.settings && this.state.tab.settings.selected_placement && this.state.tab.settings.selected_placement.value === item.value &&
                                                        <div className="w-full h-full rounded-full bg-purple-500" />
                                                    }
                                                </div>
                                                <div className='flex flex-col'>
                                                    <div className='font-bold text-xs'>
                                                        {item.name}
                                                    </div>
                                                    <div className='text-xxs'>
                                                        {item.description}
                                                    </div>
                                                </div>
                                            </div>

                                        )
                                    })
                                }
                            </>
                        }

                        {
                            this.state.tab.celltype && this.state.tab.celltype.value === "table" &&
                            <div className="grid grid-cols-12 gap-4 mb-4">
                                <div className="col-span-6">
                                    <div className="text-xs font-medium mb-1">
                                        Table header background
                                    </div>
                                    <div className={`w-full border-1.5 rounded-md ${this.state.selectedId === "table_header_background_color" ? "border-purple-500" : ""}`}>
                                        <div className='grid grid-cols-4 overflow-hidden rounded-md'>
                                            <div
                                                style={{ backgroundColor: "#fcfcfc" }}
                                                className="flex items-center col-span-3 p-1">
                                                <span className={`pl-3`}>#</span>
                                                <input
                                                    id="table_header_background_color"
                                                    className="w-full border-none p-2 bg-transparent"
                                                    type="text"
                                                    placeholder='FFFFFF'
                                                    value={this.state.tab.settings && this.state.tab.settings.table_header_background_color ? this.state.tab.settings.table_header_background_color : "fcfcfc"}
                                                    onClick={(e) => {
                                                        this.setState({ selectedId: e.target.id })
                                                    }}
                                                    onChange={async (e) => {
                                                        if (!this.state.tab.settings) {
                                                            this.state.tab.settings = { title: "" };
                                                        }
                                                        e.target.value = e.target.value.replace(/#/g, "")
                                                        this.state.tab.settings.table_header_background_color = e.target.value;
                                                        this.props.onTab(this.state.tab);
                                                    }}
                                                />
                                            </div>
                                            <div className="relative overflow-hidden col-span-1 border-l-1.5">
                                                <input
                                                    style={{ width: 110, height: 65, bottom: -10 }}
                                                    className="absolute cursor-pointer"
                                                    type="color"
                                                    value={"#" + (this.state.tab.settings && this.state.tab.settings.table_header_background_color ? this.state.tab.settings.table_header_background_color : "fcfcfc")}
                                                    onChange={async (e) => {
                                                        if (!this.state.tab.settings) {
                                                            this.state.tab.settings = { title: "" };
                                                        }
                                                        let color_string = e.target.value.slice(1)
                                                        this.state.tab.settings.table_header_background_color = color_string;
                                                        this.props.onTab(this.state.tab);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-span-6">
                                    <div className="text-xs font-medium mb-1">
                                        Table header font
                                    </div>
                                    <div className={`w-full border-1.5 rounded-md ${this.state.selectedId === "table_header_font_color" ? "border-purple-500" : ""}`}>
                                        <div className='grid grid-cols-4 overflow-hidden rounded-md'>
                                            <div
                                                style={{ backgroundColor: "#33333" }}
                                                className="flex items-center col-span-3 p-1">
                                                <span className={`pl-3`}>#</span>
                                                <input
                                                    id="table_header_font_color"
                                                    className="w-full border-none p-2 bg-transparent"
                                                    type="text"
                                                    placeholder='FFFFFF'
                                                    value={this.state.tab.settings && this.state.tab.settings.table_header_font_color ? this.state.tab.settings.table_header_font_color : "333333"}
                                                    onClick={(e) => {
                                                        this.setState({ selectedId: e.target.id })
                                                    }}
                                                    onChange={async (e) => {
                                                        if (!this.state.tab.settings) {
                                                            this.state.tab.settings = { title: "" };
                                                        }
                                                        e.target.value = e.target.value.replace(/#/g, "")
                                                        this.state.tab.settings.table_header_font_color = e.target.value;
                                                        this.props.onTab(this.state.tab);
                                                    }}
                                                />
                                            </div>
                                            <div className="relative overflow-hidden col-span-1 border-l-1.5">
                                                <input
                                                    style={{ width: 110, height: 65, bottom: -10 }}
                                                    className="absolute cursor-pointer"
                                                    type="color"
                                                    value={"#" + (this.state.tab.settings && this.state.tab.settings.table_header_font_color ? this.state.tab.settings.table_header_font_color : "333333")}
                                                    onChange={async (e) => {
                                                        if (!this.state.tab.settings) {
                                                            this.state.tab.settings = { title: "" };
                                                        }
                                                        let color_string = e.target.value.slice(1)
                                                        this.state.tab.settings.table_header_font_color = color_string;
                                                        this.props.onTab(this.state.tab);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            this.state.tab.celltype && this.state.tab.celltype.value === "table" &&
                            <div className="grid grid-cols-12 gap-4 mb-4">
                                <div className="col-span-6">
                                    <div className="text-xs font-medium mb-1">
                                        Table rows background
                                    </div>
                                    <div className={`w-full border-1.5 rounded-md ${this.state.selectedId === "table_row_background_color" ? "border-purple-500" : ""}`}>
                                        <div className='grid grid-cols-4 overflow-hidden rounded-md'>
                                            <div
                                                style={{ backgroundColor: "#ffffff" }}
                                                className="flex items-center col-span-3 p-1">
                                                <span className={`pl-3`}>#</span>
                                                <input
                                                    id="table_row_background_color"
                                                    className="w-full border-none p-2 bg-transparent"
                                                    type="text"
                                                    placeholder='FFFFFF'
                                                    value={this.state.tab.settings && this.state.tab.settings.table_row_background_color ? this.state.tab.settings.table_row_background_color : "ffffff"}
                                                    onClick={(e) => {
                                                        this.setState({ selectedId: e.target.id })
                                                    }}
                                                    onChange={async (e) => {
                                                        if (!this.state.tab.settings) {
                                                            this.state.tab.settings = { title: "" };
                                                        }
                                                        e.target.value = e.target.value.replace(/#/g, "")
                                                        this.state.tab.settings.table_row_background_color = e.target.value;
                                                        this.props.onTab(this.state.tab);
                                                    }}
                                                />
                                            </div>
                                            <div className="relative overflow-hidden col-span-1 border-l-1.5">
                                                <input
                                                    style={{ width: 110, height: 65, bottom: -10 }}
                                                    className="absolute cursor-pointer"
                                                    type="color"
                                                    value={"#" + (this.state.tab.settings && this.state.tab.settings.table_row_background_color ? this.state.tab.settings.table_row_background_color : "ffffff")}
                                                    onChange={async (e) => {
                                                        if (!this.state.tab.settings) {
                                                            this.state.tab.settings = { title: "" };
                                                        }
                                                        let color_string = e.target.value.slice(1)
                                                        this.state.tab.settings.table_row_background_color = color_string;
                                                        this.props.onTab(this.state.tab);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-span-6">
                                    <div className="text-xs font-medium mb-1">
                                        Table rows font
                                    </div>
                                    <div className={`w-full border-1.5 rounded-md ${this.state.selectedId === "table_row_font_color" ? "border-purple-500" : ""}`}>
                                        <div className='grid grid-cols-4 overflow-hidden rounded-md'>
                                            <div
                                                style={{ backgroundColor: "#ffffff" }}
                                                className="flex items-center col-span-3 p-1">
                                                <span className={`pl-3`}>#</span>
                                                <input
                                                    id="table_row_font_color"
                                                    className="w-full border-none p-2 bg-transparent"
                                                    type="text"
                                                    placeholder='333333'
                                                    value={this.state.tab.settings && this.state.tab.settings.table_row_font_color ? this.state.tab.settings.table_row_font_color : "333333"}
                                                    onClick={(e) => {
                                                        this.setState({ selectedId: e.target.id })
                                                    }}
                                                    onChange={async (e) => {
                                                        if (!this.state.tab.settings) {
                                                            this.state.tab.settings = { title: "" };
                                                        }
                                                        e.target.value = e.target.value.replace(/#/g, "")
                                                        this.state.tab.settings.table_row_font_color = e.target.value;
                                                        this.props.onTab(this.state.tab);
                                                    }}
                                                />
                                            </div>
                                            <div className="relative overflow-hidden col-span-1 border-l-1.5">
                                                <input
                                                    style={{ width: 110, height: 65, bottom: -10 }}
                                                    className="absolute cursor-pointer"
                                                    type="color"
                                                    value={"#" + (this.state.tab.settings && this.state.tab.settings.table_row_font_color ? this.state.tab.settings.table_row_font_color : "333333")}
                                                    onChange={async (e) => {
                                                        if (!this.state.tab.settings) {
                                                            this.state.tab.settings = { title: "" };
                                                        }
                                                        let color_string = e.target.value.slice(1)
                                                        this.state.tab.settings.table_row_font_color = color_string;
                                                        this.props.onTab(this.state.tab);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            this.state.tab.celltype && this.state.tab.celltype.id !== "performance" &&
                            !(this.state.tab.settings && this.state.tab.settings.customNumber && this.state.tab.celltype && this.state.tab.celltype.value === "number") &&
                            this.state.tab.celltype && (this.state.tab.celltype.value === "table" || (this.state.tab.celltype.value === "chart" && (this.state.tab.chart && (this.state.tab.chart.value === "bar" || this.state.tab.chart.value === "line"))) || this.state.tab.celltype.value === "number") &&
                            <div className="mb-4 grid grid-cols-12 gap-4">
                                <div className={this.state.tab.settings && this.state.tab.settings.groupBreakdowns ? "col-span-6" : "col-span-12"}>
                                    <div className="text-xs font-medium mb-1">
                                        Group values
                                    </div>
                                    <div className='flex flex-col w-full'>
                                        <div className='flex items-center justify-center border-gray-300 w-full bg-custom-input border-1.5 rounded-md' style={{ paddingTop: "0.5rem", paddingBottom: "0.6rem" }}>
                                            <div className="flex flex-1 ml-5 text-sm">
                                                {this.state.tab.settings && this.state.tab.settings.groupBreakdowns ? "Enabled" : "Disabled"}
                                            </div>
                                            <div className="relative overflow-hidden mr-5">
                                                <SwitchTailwind
                                                    value={this.state.tab.settings && this.state.tab.settings.groupBreakdowns ? true : false}
                                                    onSwitch={async () => {
                                                        if (!this.state.tab.settings) {
                                                            this.state.tab.settings = { title: "" };
                                                        }
                                                        this.state.tab.settings.groupBreakdowns = !this.state.tab.settings.groupBreakdowns;
                                                        if (this.state.tab.settings.groupBreakdowns) {
                                                            if (this.state.tab.chart && this.state.tab.chart.value === "line") {
                                                                this.state.tab.settings.groupBreakdownsType = { id: 2, title: "Channel", name: "Channel", value: "channel" };
                                                            } else {
                                                                this.state.tab.settings.groupBreakdownsType = { id: 1, title: "Breakdown", name: "Breakdown", value: "breakdown_value", default: true };
                                                            }
                                                            if (!Array.isArray(this.state.tab.settings.groupBreakdownsGroups)) {
                                                                this.state.tab.settings.groupBreakdownsGroups = [];
                                                            }
                                                            if (!(this.state.tab.settings.groupBreakdownsGroups.length > 0 && this.state.tab && this.state.tab.celltype && this.state.tab.celltype.value == "number")) {
                                                                this.state.tab.settings.groupBreakdownsGroups.push({ id: Math.floor((Math.random() * 9999999999) + 1), items: [{ id: Math.floor((Math.random() * 9999999999) + 1), field: "" }] });
                                                                if (this.props.onTab) {
                                                                    this.props.onTab(this.state.tab);
                                                                }
                                                            }
                                                        } else {
                                                            delete this.state.tab.settings.groupBreakdownsGroups;
                                                        }
                                                        this.props.onTab(this.state.tab);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {
                                    this.state.tab.settings && this.state.tab.settings.groupBreakdowns &&
                                    <div className="col-span-6">
                                        <DropdownTailwind
                                            label={"Group by"}
                                            selected={this.state.tab.settings && this.state.tab.settings.groupBreakdownsType && this.state.tab.settings.groupBreakdownsType.value ? this.state.tab.settings.groupBreakdownsType : { id: 1, title: "Breakdown", name: "Breakdown", value: "breakdown_value", default: true }}
                                            options={this.state.tab.celltype.value === "number" ? [{ id: 1, title: "Breakdown", name: "Breakdown", value: "breakdown_value", default: true }] : [
                                                { id: 1, title: "Breakdown", name: "Breakdown", value: "breakdown_value", default: true },
                                                { id: 2, title: "Channel", name: "Channel", value: "channel" },
                                                { id: 3, title: "Datasource name", name: "Datasource name", value: "name" }
                                            ].filter((item) => {
                                                if (this.state.tab.chart && this.state.tab.chart.value === "line") {
                                                    return item.value !== "breakdown_value";
                                                } else {
                                                    return true;
                                                }
                                            })}
                                            onChange={(value) => {
                                                if (!this.state.tab.settings) {
                                                    this.state.tab.settings = { title: "" };
                                                }
                                                this.state.tab.settings.groupBreakdownsType = value;
                                                this.props.onTab(this.state.tab);
                                            }}
                                        />
                                    </div>
                                }
                            </div>
                        }
                        {
                            this.state.tab.celltype && this.state.tab.celltype.id !== "performance" &&
                            !(this.state.tab.settings && this.state.tab.settings.customNumber && this.state.tab.celltype && this.state.tab.celltype.value === "number") &&
                            this.state.tab.celltype && (this.state.tab.celltype.value === "table" || (this.state.tab.celltype.value === "chart" && (this.state.tab.chart && (this.state.tab.chart.value === "bar" || this.state.tab.chart.value === "line"))) || this.state.tab.celltype.value === "number") &&
                            this.state.tab.settings && this.state.tab.settings.groupBreakdowns &&
                            <div>
                                {
                                    (Array.isArray(this.state.tab.settings.groupBreakdownsGroups) ? this.state.tab.settings.groupBreakdownsGroups : []).map((group, index) => {
                                        let last = (this.state.tab.settings.groupBreakdownsGroups.length - 1) == index;
                                        return (
                                            <div className="bg-gray-100 mb-2 rounded-md p-4">
                                                {
                                                    this.state.tab && this.state.tab.celltype && this.state.tab.celltype.value !== "number" &&
                                                    <div className="flex flex-1 w-full mb-2">
                                                        <div className="flex flex-1">
                                                            <div className="w-full">
                                                                <InputTailwind
                                                                    label={"Group name"}
                                                                    value={group.name}
                                                                    onChange={(value) => {
                                                                        group.name = value;
                                                                        if (this.props.onTab) {
                                                                            this.props.onTab(this.state.tab);
                                                                        }
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="ml-2">
                                                            <div className="mb-1 text-xs font-medium text-gray-700">Exclude</div>
                                                            <div className="h-12 border-1.5 px-4 text-sm font-medium bg-white rounded-md flex items-center justify-center">
                                                                <SwitchTailwind
                                                                    value={group.exclude ? true : false}
                                                                    onSwitch={async () => {
                                                                        group.exclude = !group.exclude;
                                                                        if (this.props.onTab) {
                                                                            this.props.onTab(this.state.tab);
                                                                        }
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                        {
                                                            index > 0 &&
                                                            last &&
                                                            <div className="ml-2">
                                                                <div className="mb-1 text-xs font-medium text-gray-700">Catch rest</div>
                                                                <div className="h-12 border-1.5 px-4 text-sm font-medium bg-white rounded-md flex items-center justify-center">
                                                                    <SwitchTailwind
                                                                        value={group.catch_all ? true : false}
                                                                        onSwitch={async () => {
                                                                            group.catch_all = !group.catch_all;
                                                                            if (this.props.onTab) {
                                                                                this.props.onTab(this.state.tab);
                                                                            }
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                }
                                                {
                                                    this.state.tab.celltype && this.state.tab.celltype.value === "chart" &&
                                                    this.state.tab.chart && (this.state.tab.chart.value === "bar" || this.state.tab.chart.value === "line") &&
                                                    <div className="w-full mb-2">
                                                        <div className="text-xs font-medium mb-1">
                                                            Color
                                                        </div>
                                                        <div className={"w-full h-12 border-1.5 items-center bg-white justify-center flex flex-row rounded-md"}>
                                                            <div className="ml-4">
                                                                <SwitchTailwind
                                                                    value={group.useColor}
                                                                    onSwitch={async () => {
                                                                        group.color = "453FF1";
                                                                        group.useColor = !group.useColor;
                                                                        this.props.onTab(this.state.tab);
                                                                    }}
                                                                />
                                                            </div>
                                                            <div className='overflow-hidden flex flex-1 rounded-md relative'>
                                                                {
                                                                    !group.useColor &&
                                                                    <div className="absolute right-0 left-0 z-10 top-0 bottom-0 bg-white bg-opacity-75 cursor-not-allowed"></div>
                                                                }
                                                                <div
                                                                    style={{ backgroundColor: "#fcfcfc" }}
                                                                    className="flex items-center flex-1 p-1 relative">
                                                                    <span className={`pl-3`}>#</span>
                                                                    <input
                                                                        id="default_color"
                                                                        className="w-full border-none p-2 bg-transparent"
                                                                        type="text"
                                                                        placeholder=''
                                                                        value={group.color ? group.color : ""}
                                                                        onClick={(e) => {
                                                                            this.setState({ selectedId: e.target.id })
                                                                        }}
                                                                        onChange={async (e) => {
                                                                            e.target.value = e.target.value.replace(/#/g, "")
                                                                            group.color = e.target.value;
                                                                            this.props.onTab(this.state.tab);
                                                                        }}
                                                                    />
                                                                </div>
                                                                {
                                                                    group.useColor &&
                                                                    <div className="relative overflow-hidden w-16 border-l-1.5">
                                                                        <input
                                                                            style={{ width: "100%", height: 65, bottom: -10 }}
                                                                            className="absolute cursor-pointer"
                                                                            type="color"
                                                                            value={"#" + group.color}
                                                                            onChange={async (e) => {
                                                                                let color_string = e.target.value.slice(1);
                                                                                group.color = color_string;
                                                                                this.props.onTab(this.state.tab);
                                                                            }}
                                                                        />
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    !group.catch_all &&
                                                    group.items.map((item, index) => {
                                                        return (
                                                            <div className="mb-2 flex">
                                                                <div className="flex flex-1">
                                                                    <div className="flex flex-2">
                                                                        <div className="w-full">
                                                                            <InputTailwind
                                                                                label={index == 0 ? "Fields" : ""}
                                                                                value={item.field}
                                                                                onChange={(value) => {
                                                                                    item.field = value;
                                                                                    if (this.props.onTab) {
                                                                                        this.props.onTab(this.state.tab);
                                                                                    }
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="flex flex-1">
                                                                        <div className="w-full ml-2">
                                                                            <DropdownTailwind
                                                                                label={index == 0 ? "Match type" : ""}
                                                                                selected={item.match_type && item.match_type.value ? item.match_type : { id: 1, name: "Exact match", value: "exact", default: true }}
                                                                                options={[
                                                                                    { id: 1, name: "Exact match", value: "exact", default: true },
                                                                                    { id: 2, name: "Contains", value: "substring" }
                                                                                ]}
                                                                                onChange={(value) => {
                                                                                    item.match_type = value;
                                                                                    if (this.props.onTab) {
                                                                                        this.props.onTab(this.state.tab);
                                                                                    }
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div onClick={() => {
                                                                    group.items = group.items.filter((inner_item) => {
                                                                        return inner_item.id !== item.id;
                                                                    });
                                                                    if (this.props.onTab) {
                                                                        this.props.onTab(this.state.tab);
                                                                    }
                                                                }} className={(index == 0 ? "mt-5.5" : "mt-1") + " w-12 h-12 ml-2 inline-flex cursor-pointer bg-white border-1.5 rounded-md items-center justify-center"}><TrashIcon className="w-5 text-red-500"></TrashIcon></div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                                {/* ERROR, CANT EXIST TWO ITEMS WITH SAME INPUT */}
                                                {
                                                    Array.isArray(group.items) && group.items.length > 0 &&
                                                    this.renders.groupWithSameFields(group) &&
                                                    <div className="p-4 bg-red-100 rounded-md mt-4 font-medium text-red-500 text-sm">
                                                        OBS! Two or more fields contain the same value, not allowed!
                                                    </div>
                                                }
                                                <div className="flex">
                                                    {
                                                        !group.catch_all &&
                                                        <div onClick={() => {
                                                            group.items.push({ id: Math.floor((Math.random() * 9999999999) + 1), field: "" });
                                                            if (this.props.onTab) {
                                                                this.props.onTab(this.state.tab);
                                                            }
                                                        }} className="text-sm font-medium mt-4 inline-flex hover:text-purple-500 cursor-pointer">Add extra field<PlusIcon className="w-5 ml-2" /></div>
                                                    }
                                                    <div className="flex flex-1"></div>
                                                    <div className="flex">
                                                        <div onClick={() => {
                                                            this.state.tab.settings.groupBreakdownsGroups = this.state.tab.settings.groupBreakdownsGroups.filter((inner_item) => {
                                                                return group.id !== inner_item.id;
                                                            });
                                                            if (this.props.onTab) {
                                                                this.props.onTab(this.state.tab);
                                                            }
                                                        }} className="text-sm text-red-500 font-medium mt-4 inline-flex hover:text-red-700 cursor-pointer">Remove group</div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                                {
                                    !(Array.isArray(this.state.tab.settings.groupBreakdownsGroups) && this.state.tab.settings.groupBreakdownsGroups.length > 0 && this.state.tab && this.state.tab.celltype && this.state.tab.celltype.value == "number") &&
                                    !(Array.isArray(this.state.tab.settings.groupBreakdownsGroups) && this.state.tab.settings.groupBreakdownsGroups.filter((item) => { return item.catch_all }).length > 0) &&
                                    <div onClick={() => {
                                        if (!Array.isArray(this.state.tab.settings.groupBreakdownsGroups)) {
                                            this.state.tab.settings.groupBreakdownsGroups = [];
                                        }
                                        if (!(this.state.tab.settings.groupBreakdownsGroups.length > 0 && this.state.tab && this.state.tab.celltype && this.state.tab.celltype.value == "number")) {
                                            this.state.tab.settings.groupBreakdownsGroups.push({ id: Math.floor((Math.random() * 9999999999) + 1), items: [{ id: Math.floor((Math.random() * 9999999999) + 1), field: "" }] });
                                            if (this.props.onTab) {
                                                this.props.onTab(this.state.tab);
                                            }
                                        }
                                    }} className="text-sm mb-4 rounded-md font-medium hover:text-purple-500 bg-gray-100 p-4 flex cursor-pointer">Add new group<PlusIcon className="w-5 ml-2" /></div>
                                }
                            </div>
                        }
                        {
                            this.state.tab.celltype && this.state.tab.celltype.value === "table" &&
                            <div className="mb-4 grid grid-cols-12 gap-4">
                                <div className={this.state.tab.settings && this.state.tab.settings.maxRowsEnabled ? "col-span-6" : "col-span-12"}>
                                    <div className="text-xs font-medium mb-1">
                                        Table max rows
                                    </div>
                                    <div className='flex flex-col w-full'>
                                        <div className='flex items-center justify-center border-gray-300 w-full bg-custom-input border-1.5 rounded-md' style={{ paddingTop: "0.5rem", paddingBottom: "0.6rem" }}>
                                            <div className="flex flex-1 ml-5 text-sm">
                                                {this.state.tab.settings && this.state.tab.settings.maxRowsEnabled ? "Enabled" : "Disabled"}
                                            </div>
                                            <div className="relative overflow-hidden mr-5">
                                                <SwitchTailwind
                                                    value={this.state.tab.settings && this.state.tab.settings.maxRowsEnabled ? true : false}
                                                    onSwitch={async () => {
                                                        if (!this.state.tab.settings) {
                                                            this.state.tab.settings = { title: "" };
                                                        }
                                                        this.state.tab.settings.maxRows = 100;
                                                        this.state.tab.settings.maxRowsEnabled = !this.state.tab.settings.maxRowsEnabled;
                                                        this.props.onTab(this.state.tab);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {
                                    this.state.tab.settings && this.state.tab.settings.maxRowsEnabled &&
                                    <div className="col-span-6">
                                        <div className="text-xs text-opacity-0 font-medium">
                                            Rows
                                        </div>
                                        <div className='flex flex-col w-full'>
                                            <InputTailwind
                                                disabled={!(this.state.tab.settings && this.state.tab.settings.maxRowsEnabled)}
                                                value={this.state.tab.settings && this.state.tab.settings.maxRows ? this.state.tab.settings.maxRows : ""}
                                                label=''
                                                placeholder='rows'
                                                onChange={(value) => {
                                                    this.state.tab.settings.maxRows = value;
                                                    this.props.onTab(this.state.tab);
                                                }}
                                            />
                                        </div>
                                    </div>
                                }
                            </div>
                        }

                        {
                            this.state.tab.celltype && this.state.tab.celltype.value === "table" &&
                            <div className="mt-4 mb-4">
                                <div className="w-full">
                                    <DropdownTailwind
                                        label={"Table font size"}
                                        selected={this.state.tab.settings && this.state.tab.settings.table_font_size && this.state.tab.settings.table_font_size.value ? this.state.tab.settings.table_font_size : { id: 1, title: "Small", name: "Small", subtitle: "Small font size (default)", value: "small", default: true }}
                                        options={[
                                            { id: 1, title: "Small", name: "Small", subtitle: "Small font size (default)", value: "small", default: true },
                                            { id: 2, title: "Medium", name: "Medium", subtitle: "Medium font size", value: "medium" }
                                        ]}
                                        onChange={(value) => {
                                            if (!this.state.tab.settings) {
                                                this.state.tab.settings = { title: "" };
                                            }
                                            this.state.tab.settings.table_font_size = value;
                                            this.props.onTab(this.state.tab);
                                        }}
                                    />
                                </div>
                            </div>
                        }

                        {
                            this.state.tab.celltype && (this.state.tab.celltype.value === "table" || this.state.tab.celltype.value === "number") &&
                            <div className="mt-4 mb-4">
                                <div className="w-full">
                                    <div className="text-xs font-medium mb-1">
                                        Show text overlay if zero rows
                                    </div>
                                    <div className='flex flex-col w-full'>
                                        <div className='flex items-center justify-center border-gray-300 w-full bg-custom-input border-1.5 rounded-md' style={{ paddingTop: "0.5rem", paddingBottom: "0.6rem" }}>
                                            <div className="flex flex-1 ml-5 text-sm">
                                                {this.state.tab.settings && this.state.tab.settings.zeroValueOverlay ? "Enabled" : "Disabled"}
                                            </div>
                                            <div className="relative overflow-hidden mr-5">
                                                <SwitchTailwind
                                                    value={this.state.tab.settings && this.state.tab.settings.zeroValueOverlay ? true : false}
                                                    onSwitch={async () => {
                                                        if (!this.state.tab.settings) {
                                                            this.state.tab.settings = { title: "" };
                                                        }
                                                        this.state.tab.settings.zeroValueOverlay = !this.state.tab.settings.zeroValueOverlay;
                                                        this.props.onTab(this.state.tab);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }

                        {
                            this.state.tab.celltype && (this.state.tab.celltype.value === "table" || this.state.tab.celltype.value === "number") &&
                            this.state.tab.settings && this.state.tab.settings.zeroValueOverlay &&
                            <div className="mt-4 mb-4">
                                <div className="w-full">
                                    <div className="text-xs -mb-0.5 text-opacity-0 font-medium">
                                        Text (overlay)
                                    </div>
                                    <div className='flex flex-col w-full'>
                                        <TextArea
                                            value={this.state.tab.settings && this.state.tab.settings.zeroValueOverlayText ? this.state.tab.settings.zeroValueOverlayText : ""}
                                            label=''
                                            placeholder=''
                                            onChange={(event) => {
                                                this.state.tab.settings.zeroValueOverlayText = event.target.value;
                                                this.props.onTab(this.state.tab);
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        }

                        {
                            this.state.tab.celltype && this.state.tab.celltype.value === "number" &&
                            <div className="mb-4">
                                <DropdownTailwind
                                    label={"Font size"}
                                    selected={this.state.tab.settings && this.state.tab.settings.font_size && this.state.tab.settings.font_size.value ? this.state.tab.settings.font_size : { id: 1, title: "Small", name: "Small", subtitle: "Recommended for small cells", value: "small" }}
                                    options={[
                                        { id: 1, title: "Small", name: "Small", subtitle: "Recommended for small cells", value: "small" },
                                        { id: 2, title: "Medium", name: "Medium", subtitle: "Recommended for medium cells", value: "medium" },
                                        { id: 3, title: "Large", name: "Large", subtitle: "Recommended for large cells", value: "large" }
                                    ]}
                                    onChange={(value) => {
                                        if (!this.state.tab.settings) {
                                            this.state.tab.settings = { title: "" };
                                        }
                                        this.state.tab.settings.font_size = value;
                                        this.props.onTab(this.state.tab);
                                    }}
                                />
                            </div>
                        }

                        {
                            this.state.tab.celltype && this.state.tab.celltype.value === "image" &&
                            <div className="mb-4">
                                <DropdownTailwind
                                    label={"Media styling"}
                                    selected={this.state.tab.settings && this.state.tab.settings.media_style && this.state.tab.settings.media_style.value ? this.state.tab.settings.media_style : { id: 1, title: "Fill", name: "Fill", subtitle: "The media is resized to fill the given cell", value: "fill", default: true }}
                                    options={[
                                        { id: 1, title: "Fill", name: "Fill", subtitle: "The media is resized to fill the given cell", value: "fill" },
                                        { id: 2, title: "Contain", name: "Contain", subtitle: "Resized to fit within the given dimension", value: "contain" },
                                        { id: 3, title: "Cover", name: "Cover", subtitle: "The aspect ratio of the media is preserved", value: "cover" }
                                    ]}
                                    onChange={(value) => {
                                        if (!this.state.tab.settings) {
                                            this.state.tab.settings = { title: "" };
                                        }
                                        this.state.tab.settings.media_style = value;
                                        this.props.onTab(this.state.tab);
                                    }}
                                />
                            </div>
                        }

                        {/* CELL COLORS */}
                        <div className=''>
                            <div class="border-b-2 pb-1 mb-4 text-sm border-gray-600 font-medium pt-2 w-full">Cell styling</div>
                            <div className="grid grid-cols-12 gap-4 mb-4">
                                <div className={"col-span-4"}>
                                    <div className={`-mb-4 text-xs font-medium text-gray-700 flex flex-1`}>Transparent background</div>
                                    <div className='flex items-center justify-center mt-5 border-gray-300 w-full bg-custom-input border-1.5 rounded-md' style={{ paddingTop: "0.5rem", paddingBottom: "0.6rem" }}>
                                        <div className={`flex flex-1 font-medium text-xs ml-4`}>
                                            {this.state.tab.settings && this.state.tab.settings.transparent ? "Enabled" : "Disabled"}
                                        </div>
                                        <div className="relative overflow-hidden mr-5">
                                            <SwitchTailwind
                                                value={this.state.tab.settings && this.state.tab.settings.transparent ? true : false}
                                                onSwitch={async () => {
                                                    if (!this.state.tab.settings) {
                                                        this.state.tab.settings = { title: "" };
                                                    }
                                                    this.state.tab.settings.transparent = !this.state.tab.settings.transparent;
                                                    if (this.state.tab.settings.transparent) {
                                                        this.state.tab.settings.shadow = false;
                                                    }
                                                    this.props.onTab(this.state.tab);
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                {
                                    //!(this.state.tab.settings && this.state.tab.settings.transparent) &&
                                    <div className="col-span-4">
                                        <div className="text-xs font-medium mb-1">
                                            Background color
                                        </div>
                                        <div className={`w-full border-1.5 rounded-md ${this.state.selectedId === "background_color" ? "border-purple-500" : ""}`}>
                                            <div className='grid grid-cols-4 overflow-hidden rounded-md'>
                                                <div
                                                    style={{ backgroundColor: "#fcfcfc" }}
                                                    className="flex items-center col-span-3 p-1">
                                                    <span className={`pl-3`}>#</span>
                                                    <input
                                                        id="background_color"
                                                        className="w-full border-none p-2 bg-transparent"
                                                        type="text"
                                                        placeholder='FFFFFF'
                                                        value={this.state.tab.settings && this.state.tab.settings.background_color ? this.state.tab.settings.background_color : ""}
                                                        onClick={(e) => {
                                                            this.setState({ selectedId: e.target.id })
                                                        }}
                                                        onBlur={() => {
                                                            try {
                                                                if (this.state.tab.settings.background_color == "") {
                                                                    this.state.tab.settings.background_color = "FFFFFF";
                                                                    this.props.onTab(this.state.tab);
                                                                }
                                                            } catch (error) { }
                                                        }}
                                                        onChange={async (e) => {
                                                            if (!this.state.tab.settings) {
                                                                this.state.tab.settings = { title: "" };
                                                            }
                                                            e.target.value = e.target.value.replace(/#/g, "")
                                                            this.state.tab.settings.background_color = e.target.value;
                                                            this.props.onTab(this.state.tab);
                                                        }}
                                                    />
                                                </div>
                                                <div className="relative overflow-hidden col-span-1 border-l-1.5">
                                                    <input
                                                        style={{ width: 110, height: 65, bottom: -10 }}
                                                        className="absolute cursor-pointer"
                                                        type="color"
                                                        value={"#" + (this.state.tab.settings && this.state.tab.settings.background_color ? this.state.tab.settings.background_color : "")}
                                                        onChange={async (e) => {
                                                            if (!this.state.tab.settings) {
                                                                this.state.tab.settings = { title: "" };
                                                            }
                                                            let color_string = e.target.value.slice(1)
                                                            this.state.tab.settings.background_color = color_string;
                                                            this.props.onTab(this.state.tab);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                                <div className="col-span-4">
                                    <div className="text-xs font-medium mb-1">
                                        Font color
                                    </div>
                                    <div className={`w-full  border-1.5 rounded-md ${this.state.selectedId === "font_color" ? "border-purple-500" : ""}`}>
                                        <div className='grid grid-cols-4 overflow-hidden rounded-md'>
                                            <div
                                                style={{ backgroundColor: "#fcfcfc" }}
                                                className="flex items-center col-span-3 p-1">
                                                <span className={`pl-3`}>#</span>
                                                <input
                                                    id="font_color"
                                                    className="w-full border-none p-2 bg-transparent"
                                                    type="text"
                                                    placeholder='000000'
                                                    value={this.state.tab.settings && this.state.tab.settings.font_color ? this.state.tab.settings.font_color : ""}
                                                    onClick={(e) => {
                                                        this.setState({ selectedId: e.target.id })
                                                    }}
                                                    onBlur={() => {
                                                        try {
                                                            if (this.state.tab.settings.font_color == "") {
                                                                this.state.tab.settings.font_color = "000000";
                                                                this.props.onTab(this.state.tab);
                                                            }
                                                        } catch (error) { }
                                                    }}
                                                    onChange={async (e) => {
                                                        if (!this.state.tab.settings) {
                                                            this.state.tab.settings = { title: "" };
                                                        }
                                                        e.target.value = e.target.value.replace(/#/g, "");
                                                        this.state.tab.settings.font_color = e.target.value;
                                                        this.props.onTab(this.state.tab);
                                                    }}
                                                />
                                            </div>
                                            <div className="relative overflow-hidden col-span-1 border-l-1.5">
                                                <input
                                                    style={{ width: 110, height: 65, bottom: -10 }}
                                                    className="absolute cursor-pointer"
                                                    type="color"
                                                    value={"#" + (this.state.tab.settings && this.state.tab.settings.font_color ? this.state.tab.settings.font_color : "")}
                                                    onChange={async (e) => {
                                                        if (!this.state.tab.settings) {
                                                            this.state.tab.settings = { title: "" };
                                                        }
                                                        let color_string = e.target.value.slice(1)
                                                        this.state.tab.settings.font_color = color_string;
                                                        this.props.onTab(this.state.tab);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {
                                //!(this.state.tab.settings && this.state.tab.settings.transparent) &&
                                <div className="text-xs font-medium mb-1 mt-4">
                                    Margins
                                </div>
                            }
                            {
                                //!(this.state.tab.settings && this.state.tab.settings.transparent) &&
                                <div className='grid grid-cols-4 w-full gap-4 mb-4'>
                                    <div className="col-span-1">
                                        <div onClick={() => {
                                            if (!this.state.tab.settings) {
                                                this.state.tab.settings = { title: "" };
                                            }
                                            this.state.tab.settings.disable_margin_top = !this.state.tab.settings.disable_margin_top;
                                            if (this.state.tab.settings.disable_margin_top) {
                                                this.state.tab.settings.shadow = false;
                                            }
                                            this.props.onTab(this.state.tab);
                                        }} className={(!(this.state.tab.settings && this.state.tab.settings.disable_margin_top) ? "border-purple-400 text-purple-400 " : "hover:border-gray-500") + ' flex cursor-pointer items-center flex-col h-12 justify-center border-gray-300 w-full bg-custom-input border-1.5 rounded-md'}>
                                            <div className="flex flex-col items-center justify-center">
                                                <ArrowUpIcon className="w-4 mb-1" />
                                                <div className={(!(this.state.tab.settings && this.state.tab.settings.disable_margin_top) ? "bg-purple-400" : "bg-gray-700") + " rounded-md"} style={{ height: "1.5px", width: "20px" }}></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-span-1">
                                        <div onClick={() => {
                                            if (!this.state.tab.settings) {
                                                this.state.tab.settings = { title: "" };
                                            }
                                            this.state.tab.settings.disable_margin_bottom = !this.state.tab.settings.disable_margin_bottom;
                                            if (this.state.tab.settings.disable_margin_bottom) {
                                                this.state.tab.settings.shadow = false;
                                            }
                                            this.props.onTab(this.state.tab);
                                        }} className={(!(this.state.tab.settings && this.state.tab.settings.disable_margin_bottom) ? "border-purple-400 text-purple-400 " : "hover:border-gray-500") + ' flex cursor-pointer items-center flex-col h-12 justify-center border-gray-300 w-full bg-custom-input border-1.5 rounded-md'}>
                                            <div className="flex flex-col items-center justify-center">
                                                <div className={(!(this.state.tab.settings && this.state.tab.settings.disable_margin_bottom) ? "bg-purple-400" : "bg-gray-700") + " rounded-md"} style={{ height: "1.5px", width: "20px" }}></div>
                                                <ArrowDownIcon className="w-4 mt-1" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-span-1">
                                        <div onClick={() => {
                                            if (!this.state.tab.settings) {
                                                this.state.tab.settings = { title: "" };
                                            }
                                            this.state.tab.settings.disable_margin_left = !this.state.tab.settings.disable_margin_left;
                                            if (this.state.tab.settings.disable_margin_left) {
                                                this.state.tab.settings.shadow = false;
                                            }
                                            this.props.onTab(this.state.tab);
                                        }} className={(!(this.state.tab.settings && this.state.tab.settings.disable_margin_left) ? "border-purple-400 text-purple-400 " : "hover:border-gray-500") + ' flex cursor-pointer items-center flex-col h-12 justify-center border-gray-300 w-full bg-custom-input border-1.5 rounded-md'}>
                                            <div className="flex flex-row items-center justify-center">
                                                <ArrowLeftIcon className="w-4 mr-1" />
                                                <div className={(!(this.state.tab.settings && this.state.tab.settings.disable_margin_left) ? "bg-purple-400" : "bg-gray-700") + " rounded-md"} style={{ height: "20px", width: "1.5px" }}></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-span-1">
                                        <div onClick={() => {
                                            if (!this.state.tab.settings) {
                                                this.state.tab.settings = { title: "" };
                                            }
                                            this.state.tab.settings.disable_margin_right = !this.state.tab.settings.disable_margin_right;
                                            if (this.state.tab.settings.disable_margin_right) {
                                                this.state.tab.settings.shadow = false;
                                            }
                                            this.props.onTab(this.state.tab);
                                        }} className={(!(this.state.tab.settings && this.state.tab.settings.disable_margin_right) ? "border-purple-400 text-purple-400 " : "hover:border-gray-500") + ' flex cursor-pointer items-center flex-col h-12 justify-center border-gray-300 w-full bg-custom-input border-1.5 rounded-md'}>
                                            <div className="flex flex-row items-center justify-center">
                                                <div className={(!(this.state.tab.settings && this.state.tab.settings.disable_margin_right) ? "bg-purple-400" : "bg-gray-700") + " rounded-md"} style={{ height: "20px", width: "1.5px" }}></div>
                                                <ArrowRightIcon className="w-4 ml-1" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            {
                                !(this.state.tab.settings && (this.state.tab.settings.disable_margin_left || this.state.tab.settings.disable_margin_right || this.state.tab.settings.disable_margin_bottom || this.state.tab.settings.disable_margin_top)) &&
                                !(this.state.tab.settings && this.state.tab.settings.transparent) &&
                                <div className="text-xs font-medium mb-1">
                                    Shadow
                                </div>
                            }
                            {
                                !(this.state.tab.settings && (this.state.tab.settings.disable_margin_left || this.state.tab.settings.disable_margin_right || this.state.tab.settings.disable_margin_bottom || this.state.tab.settings.disable_margin_top)) &&
                                !(this.state.tab.settings && this.state.tab.settings.transparent) &&
                                <div className='flex flex-col w-full'>
                                    <div className='flex items-center justify-center border-gray-300 w-full bg-custom-input border-1.5 rounded-md' style={{ paddingTop: "0.5rem", paddingBottom: "0.6rem" }}>
                                        <div className={`flex flex-1 text-sm ml-5 ${!this.state.public && "opacity-50"}`}>
                                            {this.state.tab.settings && this.state.tab.settings.shadow ? "Enabled" : "Disabled"}
                                        </div>
                                        <div className="relative overflow-hidden mr-5">
                                            <SwitchTailwind
                                                value={this.state.tab.settings && this.state.tab.settings.shadow ? true : false}
                                                onSwitch={async () => {
                                                    if (!this.state.tab.settings) {
                                                        this.state.tab.settings = { title: "" };
                                                    }
                                                    this.state.tab.settings.shadow = !this.state.tab.settings.shadow;
                                                    this.props.onTab(this.state.tab);
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>

                    </SlideDown >
                </div >
            </>
        )
    }
}

export default SelectCellSettings