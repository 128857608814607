import React, { Component, Fragment } from 'react';
import { apiRegister } from "../services/apiRegister";
import { tokenRegister } from '../services/tokenRegister';
import cn from "classnames";
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon, CubeIcon, TrashIcon, UserAddIcon, ArrowDownIcon, ArrowUpIcon, PencilAltIcon, DuplicateIcon, ExclamationIcon } from '@heroicons/react/outline';
import moment from 'moment';
import WarningModalTailwind from '../moduleFiles/warningModalTailwind';
import classNames from 'classnames';
import SlideoutTailwind from '../moduleFiles/slideoutTailwind';
import CreateOrder from '../moduleFiles/createOrder';
import { clientRegister } from '../services/clientRegister';
import { userRegister } from '../services/userRegister';
import TableColumnSorter from '../moduleFiles/tableColumnSorter';
import { createChangelog } from '../services/createChangelog';
import SideNotification from '../moduleFiles/sideNotification';
import ReactTooltip from "react-tooltip";
import TableFilters from '../moduleFiles/tableFilters';

class OrderList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            loading_partial: false,
            meta: {},
            limit: 100,
            page: 1,
            search: "",
            sort: "startDate",
            direction: "descending",
            create_wizard: false,
            wizard_name: "",
            orders: [],
            columns: [
                { name: 'name', value: 'name', min_width: '350px' },
                //{ name: 'actions', value: 'actions' },
                { name: 'start date', value: 'startDate', min_width: '150px' },
                { name: 'end date', value: 'endDate', min_width: '150px' },
                { name: 'status', value: 'status', min_width: '150px' },
                { name: 'warnings', value: 'warnings', min_width: '150px', noSort: true },
                { name: 'assigned agents', value: 'agents', min_width: '150px', noSort: true },
                { name: 'channels', value: 'channels', noSort: true },

                { name: 'Fullfilled', value: 'percentage', border: true },
                { name: 'pacing', value: 'pacing', border: true },
                { name: 'Secondary status', value: 'maxBudgetReached', border: true },
                { name: 'Total/Maximum budget', value: 'totalVersusMaximum', border: true, noSort: true },

                { name: 'Spend', value: 'spend', min_width: '200px', border: true },
                { name: 'Impressions', value: 'impressions', min_width: '200px', border: true },
                { name: 'Click', value: 'clicks', min_width: '200px', border: true },
                { name: 'CTR', value: 'ctr', min_width: '200px', border: true },
                { name: 'CPC', value: 'cpc', min_width: '200px', border: true },
                { name: 'CPM', value: 'cpm', min_width: '200px', border: true },

                { name: 'client', value: 'client', min_width: '450px', noSort: true },
                { name: 'created', value: 'created', min_width: '150px' },
                { name: 'Reference ID', value: 'crmId', min_width: '150px' },
                //{ name: 'drafts', value: 'drafts', min_width: '100px', noSort: true },
                //{ name: 'campaigns', value: 'campaigns', min_width: '100px', noSort: true },
            ],
            status: { id: 0, name: "All", value: "total" },
            client: {},
            clients: [
                { id: 1, name: "All", value: "all" }
            ],
            stats: [
                { id: 1, name: "All", value: "all", link: "/v2/orders" },
                { id: 2, name: "Active", value: "active", link: "/v2/orders/active" },
                { id: 3, name: "Upcoming", value: "upcoming", link: "/v2/orders/upcoming" },
                { id: 4, name: "Paused", value: "paused", link: "/v2/orders/paused" },
                { id: 6, name: "Ended", value: "ended", link: "/v2/orders/ended" },
                { id: 7, name: "Archived", value: "archived", link: "/v2/orders/archived" }
            ],
            filters: [
                { name: "Client", type: "client", selected: { id: 0, name: "All" }, options: [] }
            ],
            advancedFilters: [
                {
                    maxWidth: true,
                    type: "timeSpan",
                    placeholder: "This month",
                    defaultOptions: [],
                    options: [
                        { id: 1, name: "This month", value: "this_month" },
                        { id: 2, name: "Last month", value: "last_month" },
                        { id: 3, name: "Two months ago", value: "2_months" },
                        { id: 4, name: "Three months ago", value: "3_months" }
                    ],
                    selected: { id: 1, name: "This month", value: "this_month" },
                    loading: false
                },
                {
                    multiSelect: true,
                    maxWidth: true,
                    type: "channel",
                    placeholder: "All channels (default)",
                    defaultOptions: [],
                    defaultSelected: { id: 1, name: "All channels (default)", value: "all" },
                    options: [
                        { id: 1, name: "All channels (default)", value: "all" },
                        { id: 7, name: "No channels", value: "none" },
                        { id: 2, name: "Facebook", value: "facebook" },
                        { id: 3, name: "Tiktok", value: "tiktok" },
                        { id: 4, name: "Linkedin", value: "linkedin" },
                        { id: 5, name: "Google", value: "google" },
                        { id: 6, name: "Bing", value: "bing" },
                        { id: 8, name: "Snapchat", value: "snapchat" }
                    ],
                    selected: []
                },
                {
                    maxWidth: true,
                    type: "agents",
                    multiSelect: true,
                    search: true,
                    searchValue: '',
                    placeholder: "All",
                    defaultSelected: { id: 1, name: "Assigned/Unassigned", value: "all" },
                    defaultOptions: [
                        { id: 1, name: "Assigned/Unassigned", value: "all" },
                        { id: 2, name: "Unassigned", value: "unassigned" },
                        { id: 3, name: "Assigned", value: "assigned" }
                    ],
                    options: [
                        { id: 1, name: "Assigned/Unassigned", value: "all" },
                        { id: 2, name: "Unassigned", value: "unassigned" },
                        { id: 3, name: "Assigned", value: "assigned" }
                    ],
                    selected: [],
                    loadingPagination: false,
                    loadingSearch: false,
                    page: 1,
                    onPagination: async () => {
                        await this.functions.getAgents(false, true, false)
                    },
                    onSearch: async () => {
                        await this.functions.getAgents(false, false, true);
                    }
                }
            ],
            orderOptions: ["Claim order", "Assign to order", "Open", "Open new tab", "Archive", "Pause", "Activate", "Delete"],
            throttling: {},
            show_table_settings: false,
            view_name: "",
            pause: null,
            selected_tags: [],
            initial_data: {},
            updated_data: {},
            error_slideout: "",
            pause_all_campaigns: { id: 1, name: "Yes, pause all campaigns", value: true },
            doughnuts: {},
            previous: {},
        }
    };

    async componentDidMount() {
        await this.functions.getUser();
        this.functions.client();
        this.props.updateStatistics();
        this.functions.getColumns()
        this.functions.getAgents(true, false, false);
        this.functions.listOrdersCharts();

        await this.promisedSetState({
            view_name: this.props.view_name,
        })
    }

    functions = {
        sleep: (ms) => {
            return new Promise(resolve => setTimeout(resolve, ms));
        },
        getRandomColorWithBlackText: () => {
            function getRandomInt(min, max) {
                return Math.floor(Math.random() * (max - min + 1)) + min;
            }

            const r = getRandomInt(0, 255);
            const g = getRandomInt(0, 255);
            const b = getRandomInt(0, 255);

            const brightness = (r * 299 + g * 587 + b * 114) / 1000;

            if (brightness < 128) {
                return this.functions.getRandomColorWithBlackText();
            }

            return `rgb(${r}, ${g}, ${b})`;
        },
        getUserOrderChannel: async () => {
            return new Promise(async (resolve) => {
                try {
                    let res = await this.calls.getUserOrderChannel()
                    if ("orders" in res.data) {
                        if (res.data.orders) {
                            this.state.advancedFilters[1].selected = res.data.orders;
                        }
                        await this.promisedSetState({
                            advancedFilters: this.state.advancedFilters
                        })
                    }
                } catch (error) {
                    console.log(error)
                }
                resolve()
            })
        },
        saveUserOrderChannel: async () => {
            try {
                let data = { status: this.state.advancedFilters[1].selected, view_name: "orders" }
                let res = await this.calls.saveUserOrderChannel(data)
                if (res.data.orders.length > 0) {
                    this.state.advancedFilters[1].selected = res.data.orders;
                    await this.promisedSetState({
                        advancedFilters: this.state.advancedFilters
                    })
                }
            } catch (error) {
                console.log(error)
            }

        },
        getUser: async () => {
            return new Promise(async (resolve) => {
                await this.promisedSetState({
                    user: userRegister.get() ? userRegister.get() : {}
                });
                if (this.state.user && this.state.user.userRole === "sales") {
                    await this.promisedSetState({
                        orderOptions: ["Open"],
                    });
                }
                await this.functions.getUserOrderChannel();
                resolve()
            })
        },
        query: async () => {

            let queries = window.location.search.replace("?", "").split("&");
            let status = null;

            //SET STATUS
            this.state.stats.map((item) => {
                if (item.link === window.location.pathname) {
                    status = item;
                }
            })
            await this.promisedSetState({
                status: status ? status : this.state.status
            });

            if (window.location.pathname == "/v2/orders/unassigned") {
                this.state.advancedFilters[2].locked = true;
                this.state.advancedFilters[2].selected = { id: 2, name: "Unassigned", value: "unassigned" };
                await this.promisedSetState({
                    advancedFilters: this.state.advancedFilters
                });
            }

            //CHECK IF CLIENT
            for (let i = 0; i < queries.length; i++) {
                if (queries[i].indexOf("client=") !== -1) {
                    let value = queries[i].replace("client=", "");
                    let client = null;
                    this.state.clients.map((item) => {
                        if (item.id == value) {
                            client = item;
                        }
                    });
                    await this.promisedSetState({
                        client: client ? client : this.state.client
                    });
                }
            }

            //CHECK IF NEW ORDER
            for (let i = 0; i < queries.length; i++) {
                if (queries[i].indexOf("trigger=newOrder") !== -1) {
                    await this.promisedSetState({
                        create_order: true
                    });
                    this.props.history.replace({});
                }
            }

            this.functions.orders(true);

        },
        client: async () => {
            await this.promisedSetState({
                client: clientRegister.get()
            });
            this.functions.query();
        },
        orders: async (init, search) => {
            await this.promisedSetState({ loading: init, loading_partial: !init });
            try {
                let params = "";
                (Array.isArray(this.state.selected_tags) ? this.state.selected_tags : []).map((tag) => {
                    params = params + "&tags[]=" + tag.name + "___" + (tag.exclude ? "exclude" : "include");
                });
                this.state.advancedFilters.filter((item) => { return item.type !== "channel" }).map((filter) => {
                    if (Array.isArray(filter.selected)) {
                        filter.selected.forEach(selected => {
                            params = params + "&" + filter.type + "[]=" + selected.value
                        })
                    } else {
                        params = params + "&" + filter.type + "=" + filter.selected.value;
                    }
                });
                this.state.advancedFilters.filter((item) => { return item.type === "channel" }).map((filter) => {
                    if (filter.selected.length > 0) {
                        filter.selected.map((channel) => {
                            params = params + "&channels[]=" + channel.value;
                        })
                    }
                });
                let response = await this.calls.orders(params);
                let orders = response.data.map(order => {
                    if (Array.isArray(order.agents)) {
                        order.agents = order.agents.map(agent => {
                            agent.color = "rgb(69, 63, 241)"; //this.functions.getRandomColorWithBlackText();
                            return agent
                        });
                    }
                    return order;
                })
                if (!search || (search && search == this.state.search)) {
                    await this.promisedSetState({
                        orders: orders,
                        meta: response.meta,
                        initial_data: response.data,
                    });
                }
            } catch (error) { }
            await this.promisedSetState({ loading: false, loading_partial: false });
        },
        remove: async () => {
            let temp = JSON.parse(JSON.stringify(this.state.remove));
            await this.promisedSetState({ remove: null });
            await this.promisedSetState({ remove_name: "" });
            this.state.orders.map((item) => { if (item.id === temp.id) { item.loading = true } return item });
            await this.promisedSetState({ orders: this.state.orders });
            try {
                await this.calls.remove(temp.id);
                await this.promisedSetState({ orders: this.state.orders.filter((item) => { return item.id !== temp.id }) });
                let order = this.state.initial_data.find(item => item.id === temp.id)
                let client = order.client.id
                this.functions.handleCreateChangelog(temp.id, client, "removed")
            } catch (error) {
                this.state.orders.map((item) => { if (item.id === temp.id) { item.loading = false } return item });
                await this.promisedSetState({ orders: this.state.orders });
            }
            this.props.updateStatistics();
        },
        archive: async (id) => {
            this.state.orders.map((item) => { if (item.id === id) { item.loading = true } return item });
            await this.promisedSetState({ orders: this.state.orders });
            try {
                await this.calls.archive(id);
                await this.promisedSetState({ orders: this.state.orders.filter((item) => { return item.id !== id }) });
                let order = this.state.initial_data.find(item => item.id === id)
                let client = order.client.id
                this.functions.handleCreateChangelog(id, client, "archived")
            } catch (error) {
                this.state.orders.map((item) => { if (item.id === id) { item.loading = false } return item });
                await this.promisedSetState({ orders: this.state.orders });
            }
            this.props.updateStatistics();
        },
        pause: async (id, client) => {
            this.state.orders.map((item) => { if (item.id === id) { item.loading = true } return item });
            //await this.promisedSetState({ orders: this.state.orders, pause: null });
            await this.promisedSetState({ orders: this.state.orders, warning_loading: true });
            try {
                await this.calls.pause(id, client);
                await this.promisedSetState({ orders: this.state.orders.filter((item) => { return item.id !== id }), pause: null });
                this.functions.handleCreateChangelog(id, client, "paused")
                await this.promisedSetState({ orders: this.state.orders.filter((item) => { return item.id !== id }), pause: null, pause_all_campaigns: { id: 1, name: "Yes, pause all campaigns", value: true } });
            } catch (error) {
                if (error) {
                    this.state.error_slideout = error.body && error.body.message ? error.body.message : "Error";
                    await this.promisedSetState({ error_slideout: this.state.error_slideout })
                    this.refs.SideNotification.functions.trigger();
                }
                this.state.orders.map((item) => { if (item.id === id) { item.loading = false } return item });
                await this.promisedSetState({ orders: this.state.orders, pause: null, pause_all_campaigns: { id: 1, name: "Yes, pause all campaigns", value: true } });
            }
            await this.promisedSetState({ warning_loading: false });
            this.props.updateStatistics();
        },
        activate: async (id) => {
            this.state.orders.map((item) => { if (item.id === id) { item.loading = true } return item });
            await this.promisedSetState({ orders: this.state.orders });
            try {
                let res = await this.calls.activate(id);
                await this.promisedSetState({ orders: this.state.orders.filter((item) => { return item.id !== id }) });
                let order = this.state.initial_data.find(item => item.id === id)
                let client = order.client.id
                this.functions.handleCreateChangelog(id, client, "active")
            } catch (error) {
                this.state.orders.map((item) => { if (item.id === id) { item.loading = false } return item });
                await this.promisedSetState({ orders: this.state.orders });
            }
            this.props.updateStatistics();
        },
        calimOrder: async (id) => {
            this.state.orders.map((item) => { if (item.id === id) { item.loading = true } return item });
            await this.promisedSetState({ orders: this.state.orders });
            try {
                await this.calls.calimOrder(id, { claim_agent: { email: this.state.user.email, id: this.state.user.id } });
                this.props.history.push("/v2/orders/" + id);
            } catch (error) {
                this.state.orders.map((item) => { if (item.id === id) { item.loading = false } return item });
                await this.promisedSetState({ orders: this.state.orders });
            }
            this.props.updateStatistics();
        },
        menu: (option, row) => {
            if (option === "Open") {
                this.props.history.push("/v2/orders/" + row.id);
            } else if (option == "Open new tab") {
                if (window.location.hostname === 'localhost') {
                    window.open("http://localhost:5001/v2/orders/" + row.id);
                } else if (window.location.hostname === 'app.adcredo.io') {
                    window.open("https://app.adcredo.io/v2/orders/" + row.id);
                } else if (window.location.hostname === 'dev.adcredo.io') {
                    window.open("https://dev.adcredo.io/v2/orders/" + row.id);
                }
            } else if (option === "Create draft") {
                this.promisedSetState({
                    create_wizard: true,
                    wizard_name: row.name,
                    order_wizard: row
                })
            } else if (option === "Drafts") {
                this.props.history.push("/v2/campaigns/draft?order=" + row.id);
            } else if (option === "Archive") {
                this.functions.archive(row.id)
            } else if (option === "Pause") {
                this.setState({
                    pause: row
                });
            } else if (option === "Activate") {
                this.functions.activate(row.id)
            } else if (option === "Claim order" || option === "Assign to order") {
                this.functions.calimOrder(row.id)
            } else if (option === "Delete") {
                this.setState({
                    remove: row
                });
            } else if (option === "Refresh Performance") {
                this.functions.handleRefresh(row.id)
            }
        },
        createWizard: async (id) => {
            this.state.orders.map((item) => { if (item.id === id) { item.loading = true } return item });
            await this.promisedSetState({ orders: this.state.orders, loading_wizard: true, create_wizard: false });
            try {
                let response = await this.calls.createWizard({
                    order: this.state.order_wizard.id,
                    name: this.state.wizard_name
                });
                this.props.history.push("/v2/campaigns/new/channels?draft=" + response.data.id);
            } catch (error) {
                console.log(error);
            }
            this.state.orders.map((item) => { if (item.id === id) { item.loading = true } return item });
            await this.promisedSetState({ orders: this.state.orders, loading_wizard: true, wizard_name: "", order_wizard: {} });
        },
        saveColumnOrder: async () => {
            try {
                let data = { columns: this.state.columns, view_name: this.props.view_name }
                await this.promisedSetState({ loading: true })
                let res = await this.calls.saveColumnOrder(data)
                if (res.data[this.props.view_name].length > 0) {
                    await this.promisedSetState({
                        columns: res.data[this.props.view_name],
                        loading: false,
                        open: false,
                    })
                }
            } catch (error) {
                console.log(error)
            }
            // this.props.onColumnSave(this.state.columns)

        },
        getColumns: async () => {
            return new Promise(async (resolve) => {
                try {
                    let res = await this.calls.getColumns()
                    if (this.props.view_name in res.data) {
                        res.data[this.props.view_name].map(savedCol => {
                            this.state.columns = this.state.columns.map(col => {
                                if (savedCol.value === col.value) {
                                    col.disabled = savedCol.disabled
                                    col.index = savedCol.index
                                }
                                return col
                            })
                        })

                        this.state.columns.sort((a, b) => {
                            return a.index - b.index
                        })

                        await this.promisedSetState({
                            columns: this.state.columns
                        })
                    }

                    //this.props.onColumnSave(this.state.columns)

                } catch (error) {
                    console.log(error)
                }
                resolve()
            })
        },
        newOrder: () => {
            this.setState({
                create_order: true
            })
        },
        clientUpdated: async (client) => {
            await this.promisedSetState({
                page: 1,
                client: client
            });
            this.functions.orders();
            this.functions.listOrdersCharts();

        },
        listOrdersCharts: async () => {
            await this.promisedSetState({ loading_chart: true });
            try {
                let params = "";
                (Array.isArray(this.state.selected_tags) ? this.state.selected_tags : []).map((tag) => {
                    params = params + "&tags[]=" + tag.name + "___" + (tag.exclude ? "exclude" : "include");
                });
                let type = window.location.href.split('orders/')[1].split('?')[0];
                if (type !== "all") {
                    params = params + "&status=" + type;
                }
                this.state.advancedFilters.map((filter) => {
                    if (Array.isArray(filter.selected)) {
                        filter.selected.forEach(selected => {
                            params = params + "&" + filter.type + "[]=" + selected.value
                        })
                    } else {
                        params = params + "&" + filter.type + "=" + filter.selected.value;
                    }
                });
                let response = await this.calls.listOrdersCharts(params);
                await this.promisedSetState({
                    doughnuts: response.meta,
                    total: response.meta.total,
                    previous: response.meta.previous
                });
            } catch (error) {
                console.error(error)
            }
            await this.promisedSetState({ loading_chart: false });
        },
        handleRefresh: async (id) => {
            try {
                let updated_rows = []

                await this.promisedSetState({
                    orders: this.state.orders.map(item => {
                        if (item.id === id) {
                            return {
                                ...item,
                                loading: true,
                            }
                        }
                        return item
                    })
                })

                let res = await this.calls.refreshOrder(id)

                if (res.data) {
                    updated_rows = this.state.orders.map(item => {
                        if (item.id === id) {
                            return {
                                ...item,
                                metrics: res.data.metrics,
                                max_budget: res.data.max_budget,
                                loading: false,
                            }
                        }
                        return item
                    })
                    await this.promisedSetState({
                        orders: updated_rows
                    })
                }

            } catch (error) {
                console.log(error)
            }
        },
        handleSetTags: async (value) => {
            await this.promisedSetState({
                selected_tags: value
            });
            this.functions.orders()
            this.functions.listOrdersCharts();
        },
        history: async () => {
            try {
                window.history.pushState({
                    page: this.state.page,
                    limit: this.state.limit,
                    sort: this.state.sort,
                    direction: this.state.direction,
                    ...(this.state.search !== "" && { search: this.state.search }),
                    channels: this.state.advancedFilters[1].selected ? this.state.advancedFilters[1].selected : [],
                    client: JSON.stringify(this.state.client),
                    agents: this.state.advancedFilters[2].selected ? this.state.advancedFilters[2].selected : { id: 1, name: "Assigned/Unassigned", value: "all" },
                    maxBudget: this.state.advancedFilters[3].selected ? this.state.advancedFilters[3].selected : { id: 1, name: "Enabled/Disabled Max budget", value: "all" },
                    tags: this.state.selected_tags ? this.state.selected_tags : []
                }, "",
                    ("?limit=" + this.state.limit + "&page=" + this.state.page + "&sortBy=" + this.state.sort + "&orderBy=" + this.state.direction + (this.state.search !== "" ? ("&search=" + this.state.search) : "") + (this.state.client.id !== 0 ? "&client=" + this.state.client.id : "") + (this.state.advancedFilters[1].selected.length > 0 ? this.state.advancedFilters[1].selected.map(item => "&channels[]=" + item.value).join('') : "") + (this.state.selected_tags.length > 0 ? this.state.selected_tags.map(item => "&tags[]=" + item.name).join('') : "") + "&agents=" + this.state.advancedFilters[2].selected.value + "&maxBudget=" + this.state.advancedFilters[3].selected.value))
            } catch (error) {
                console.log(error)
            }
        },
        handleCreateChangelog: async (id, client, action) => {
            try {
                let order = null;
                order = this.state.initial_data.find(item => item.id === id)
                this.state.updated_data = order
                this.state.updated_data = { ...this.state.updated_data, status: action };
                await this.promisedSetState({
                    updated_data: this.state.updated_data
                })
                createChangelog({ initial_data: order, updated_data: this.state.updated_data, client: client, order: id, item: id, level: "order" });
                order = null;
                await this.promisedSetState({
                    updated_data: {}
                })
            } catch (error) { }

        },
        getAgents: async (init, pagination, search) => {
            let filter = null;

            await this.promisedSetState({
                advancedFilters: this.state.advancedFilters.map(item => {
                    if (item.type === "agents") {
                        item.loadingSearch = search;
                        item.loadingPagination = pagination;
                        item.loading = init;

                        filter = item;
                    }

                    return item;
                })
            });
            if (filter) {
                try {
                    let response = await this.calls.getAgents(filter.searchValue);
                    if (!pagination) {
                        await this.promisedSetState({
                            advancedFilters: this.state.advancedFilters.map(item => {
                                if (item.type === "agents") {
                                    item.options = [...item.defaultOptions, ...response.data.map((item) => {
                                        item.name = item.email;
                                        item.value = item.email;
                                        return item
                                    })];
                                    item.total = response.meta.total;
                                }

                                return item;
                            })
                        });
                    } else {
                        await this.promisedSetState({
                            advancedFilters: this.state.advancedFilters.map(item => {
                                if (item.type === "agents") {
                                    item.options = item.options.concat(response.data.map((item) => {
                                        item.name = item.email;
                                        item.value = item.email;
                                        return item
                                    }));
                                    item.total = response.meta.total;
                                }

                                return item;
                            })
                        });
                    }
                } catch (error) { }
                await this.promisedSetState({
                    advancedFilters: this.state.advancedFilters.map(item => {
                        if (item.type === "agents") {
                            item.loadingSearch = false;
                            item.loadingPagination = false;
                            item.loading = false;
                        }

                        return item;
                    })
                });
            }
        }
    };

    calls = {
        orders: (params) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/listOrders?limit=" + this.state.limit + "&page=" + this.state.page + "&sortBy=" + this.state.sort + "&orderBy=" + this.state.direction + "&status=" + this.state.status.value + (this.state.client && this.state.client.id !== 0 ? "&client=" + this.state.client.id : "") + (this.state.search !== "" ? ("&search=" + this.state.search) : "") + params;
            return apiRegister.call(options, url);
        },
        remove: (id) => {
            let options = apiRegister.options(tokenRegister.get(), 'DELETE', null);
            let url = apiRegister.url.api + "/v3/adcredo/removeOrder?id=" + id;
            return apiRegister.call(options, url);
        },
        archive: (id) => {
            let options = apiRegister.options(tokenRegister.get(), 'PUT', { status: "archived" });
            let url = apiRegister.url.api + "/v3/adcredo/updateOrder?id=" + id;
            return apiRegister.call(options, url);
        },
        pause: (id, client) => {
            let options = apiRegister.options(tokenRegister.get(), 'PUT', { status: "paused", pause_campaigns: this.state.pause_all_campaigns ? this.state.pause_all_campaigns.value : true });
            let url = apiRegister.url.api + "/v3/adcredo/updateOrder?id=" + id + "&client=" + client;
            return apiRegister.call(options, url);
        },
        activate: (id) => {
            let options = apiRegister.options(tokenRegister.get(), 'PUT', { status: "active" });
            let url = apiRegister.url.api + "/v3/adcredo/updateOrder?id=" + id;
            return apiRegister.call(options, url);
        },
        calimOrder: (id, agents) => {
            let options = apiRegister.options(tokenRegister.get(), 'PUT', agents);
            let url = apiRegister.url.api + "/v3/adcredo/updateOrder?id=" + id;
            return apiRegister.call(options, url);
        },
        createWizard: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/adcredo/createWizard";
            return apiRegister.call(options, url);
        },
        saveColumnOrder: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'PUT', data);
            let url = apiRegister.url.api + "/v3/adcredo/updateUserTableColumns";
            return apiRegister.call(options, url);
        },
        getColumns: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/getUserTableColumns";
            return apiRegister.call(options, url);
        },
        saveUserOrderChannel: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'PUT', data);
            let url = apiRegister.url.api + "/v3/adcredo/updateUserOrderStatus";
            return apiRegister.call(options, url);
        },
        listOrdersCharts: (params) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/listOrdersCharts?chart=1" + (this.state.client && this.state.client.id !== 0 ? "&client=" + this.state.client.id : "") + params;
            return apiRegister.call(options, url);
        },
        getUserOrderChannel: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/getUserOrderStatus";
            return apiRegister.call(options, url);
        },
        refreshOrder: (id) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/refreshOrder?id=" + id;
            return apiRegister.call(options, url);
        },
        getAgents: (search) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/listAgencyAgents?limit=" + this.state.limit + "&page=" + this.state.page + "&sortBy=" + (search !== "" ? ("&search=" + search) : "");
            return apiRegister.call(options, url);
        }
    };

    renders = {
        convertToDecimal: (value) => {
            try {
                value = +value;
                return isNaN(value.toFixed(2)) ? null : value.toFixed(2);
            } catch (error) {
                if (value) {
                    return value;
                } else {
                    return null
                }
            }
        },
        pauseAllCampaigns: (value) => {
            try {
                if (value) {
                    return value;
                } else if (this.state.pause_all_campaigns) {
                    return this.state.pause_all_campaigns;
                } else {
                    return { id: 1, name: "Yes, pause all campaigns", value: true }
                }
            } catch (error) {
                return { id: 0, name: "Select ..." }
            }
        },
        ordersStatus: () => {
            let string = null;
            try {
                if (window.location.pathname.indexOf("orders/active") !== -1) {
                    string = "Active";
                }
                if (window.location.pathname.indexOf("orders/upcoming") !== -1) {
                    string = "Upcoming";
                }
                if (window.location.pathname.indexOf("orders/paused") !== -1) {
                    string = "Paused";
                }
                if (window.location.pathname.indexOf("orders/ended") !== -1) {
                    string = "Ended";
                }
                if (window.location.pathname.indexOf("orders/archived") !== -1) {
                    string = "Archived";
                }
                if (window.location.pathname.indexOf("orders/unassigned") !== -1) {
                    string = "Unassigned";
                }
            } catch (error) { }
            return string;
        },
        metricGoal(key) {
            if (key == "impressions" || key == "clicks" || key == "spend" || key == "ctr" || key == "budget") {
                return "highest";
            } else if (key == "cpc" || key == "cpm") {
                return "lowest";
            } else {
                return null;
            }
        },
        comparedValue(org_number, preceding_number) {
            try {
                if (+preceding_number > 0) {
                    let increase = +org_number - +preceding_number;
                    let value = (+increase / +preceding_number) * 100;
                    return value.toFixed(2);;
                } else {
                    return "";
                }
            } catch (error) {
                return "";
            }
        },
        addCommas(nStr) {
            try {
                nStr += '';
                var x = nStr.split('.');
                var x1 = x[0];
                var x2 = x.length > 1 ? '.' + x[1] : '';
                var rgx = /(\d+)(\d{3})/;
                while (rgx.test(x1)) {
                    x1 = x1.replace(rgx, '$1' + ' ' + '$2');
                }
                return x1 + x2;
            } catch (error) {
                return nStr;
            }
        },
        channelWidth: (data, index, pretty) => {
            let total = 0;
            let channel_value = 0;
            try {
                data.datasets[0].data.map((value, inner_index) => {
                    if (index == inner_index) {
                        channel_value = value;
                    }
                    total += +value;
                });
            } catch (error) { }
            return pretty ? +(channel_value / total * 100).toFixed(2) : +(channel_value / total * 100);
        },
        channelValue: (data, index) => {
            let channel_value = 0;
            try {
                data.datasets[0].data.map((value, inner_index) => {
                    if (index == inner_index) {
                        channel_value = value;
                    }
                });
            } catch (error) { }
            return this.renders.addCommas(channel_value);
        },
    }

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {
        return (
            <div className="relative flex flex-1 flex-col">

                {/*HEADER*/}
                {
                    !this.state.loading &&
                    <div className="w-full flex items-center p-4 pb-0 justify-center">
                        <div className="flex flex-1 flex-col relative">
                            <div className="text-2xl font-bold">
                                {this.renders.ordersStatus() && <span className="mr-2">{this.renders.ordersStatus()}</span>}Orders
                            </div>
                        </div>
                        <div className="flex">
                            {
                                this.state.user && this.state.user.userRole && this.state.user.userRole === "sales" ? [] : [
                                    { text: "New order", icon: CubeIcon, value: "new", loading: false, show: !this.state.loading }
                                ].map((item) => {
                                    return (
                                        <button
                                            onClick={() => {
                                                if (item.value === "new") {
                                                    this.setState({
                                                        create_order: true
                                                    })
                                                }
                                            }}
                                            className={(!item.disabled ? (item.red ? "bg-red-500 hover:bg-red-600 focus:ring-red-500 text-white" : "bg-purple-500 hover:bg-purple-600 focus:ring-purple-500 text-white") : " cursor-not-allowed bg-gray-100 text-gray-400") + " inline-flex items-center justify-center shadow relative rounded-md h-10 px-4 text-sm font-medium  focus:outline-none focus:ring-2 focus:ring-offset-2"}
                                        >
                                            <span>{item.text}</span>
                                            {
                                                item.icon &&
                                                <item.icon className="ml-2 h-5 w-5" />
                                            }
                                            {
                                                item.loading &&
                                                <div className="w-full h-full absolute inset-0 bg-purple-500 flex justify-center items-center z-20 rounded-lg">
                                                    <div style={{ borderTopColor: "transparent" }} className="w-4 h-4 border-2 border-white border-solid rounded-full animate-spin"></div>
                                                </div>
                                            }
                                        </button>
                                    )
                                })
                            }
                        </div>
                    </div>
                }

                {/*LOADING VIEW*/}
                {
                    this.state.loading &&
                    <div className="min-h-screen flex justify-center items-center flex-col flex-1 w-full">
                        <div style={{ borderTopColor: "transparent" }} className="w-10 h-10 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                        <div className="font-semibold mt-3">Loading orders ...</div>
                    </div>
                }

                <SideNotification
                    ref={"SideNotification"}
                    icon={ExclamationIcon}
                    text={this.state.error_slideout !== "" ? this.state.error_slideout : "Error"}
                    title={"Error pausing, try again"}
                    error={true}
                />

                {/*CREATE ORDER*/}
                <SlideoutTailwind
                    title={"Create Order"}
                    submitButtonText={"Create"}
                    medium={true}
                    secondaryButton={false}
                    open={this.state.create_order ? true : false}
                    loader={this.state.create_order_loading}
                    onCancel={() => {
                        this.promisedSetState({ create_order: false, order: {} });
                    }}
                    onSubmit={async () => {
                        await this.promisedSetState({
                            create_order_loading: true
                        });
                        this.refs.createOrder.functions.createOrder();
                    }}
                >
                    {
                        this.state.create_order &&
                        <CreateOrder
                            ref="createOrder"
                            onCreated={async (response) => {
                                this.props.history.push("/v2/orders/" + response.data.id);
                            }}
                            onError={async (message) => {
                                await this.promisedSetState({
                                    create_order_loading: false
                                });
                            }}
                        />
                    }
                </SlideoutTailwind>

                {/*REMOVE MODAL*/}
                <WarningModalTailwind
                    open={this.state.remove ? true : false}
                    title={"Delete"}
                    description={'Are you sure you want to delete ? This action cannot be undone.'}
                    cancelButtonText={"Cancel"}
                    submitButtonText={"Remove"}
                    disableSubmitButton={!this.state.remove || (this.state.remove_name != this.state.remove.name)}
                    showInput={true}
                    inputTitle={"Type Name to remove"}
                    copyInput={this.state.remove ? this.state.remove.name : ""}
                    inputError={(!this.state.remove || (this.state.remove_name != this.state.remove.name)) ? "Name is not matching" : false}
                    onClose={async (value) => {
                        await this.promisedSetState({ remove: null });
                        await this.promisedSetState({ remove_name: "" });
                    }}
                    onSubmit={(value) => {
                        if (this.state.remove && this.state.remove_name == this.state.remove.name) {
                            this.functions.remove();
                        }
                    }}
                    onInput={(value) => {
                        this.setState({
                            remove_name: value
                        })
                    }}
                />

                <WarningModalTailwind
                    open={!!this.state.pause}
                    title={"Pause"}
                    description={'Are you sure you want to Pause? Related campaigns will be paused by default'}
                    cancelButtonText={"Cancel"}
                    submitButtonText={"Pause"}
                    showInput={false}
                    onClose={async (value) => await this.promisedSetState({ pause: null, pause_all_campaigns: { id: 1, name: "Yes, pause all campaigns", value: true } })}
                    onSubmit={(value) => this.functions.pause(this.state.pause.id, this.state.pause.client.id)}
                    loading={this.state.warning_loading}
                    dropDown={true}
                    dropDownOptions={[{ id: 1, name: "Yes, pause all campaigns", value: true }, { id: 2, name: "No, don't pause campaigns", value: false }]}
                    dropDownSelectedRender={this.renders.pauseAllCampaigns()}
                    onDropDownSelect={async (value) => {
                        this.state.pause_all_campaigns = value
                        await this.promisedSetState({ pause_all_campaigns: this.state.pause_all_campaigns });
                    }}
                />

                <SlideoutTailwind
                    title={"Edit table columns"}
                    submitButtonText={"Save"}
                    large={false}
                    secondaryButton={false}
                    open={this.state.show_table_settings ? true : false}
                    loader={this.state.loading ? true : false}
                    onCancel={() => {
                        this.promisedSetState({ show_table_settings: false });
                    }}
                    onSubmit={async () => {
                        this.refs.TableColumnSorter.functions.saveColumnOrder()
                        this.promisedSetState({ show_table_settings: false });
                    }}
                >
                    <TableColumnSorter
                        ref={"TableColumnSorter"}
                        columns={this.state.columns}
                        saveColumnOrder={async (newColumns) => {
                            await this.promisedSetState({
                                columns: newColumns.map((item, index) => {
                                    return {
                                        ...item,
                                        index: index,
                                    }
                                }
                                ),
                            })
                            this.functions.saveColumnOrder()
                        }}

                    />

                </SlideoutTailwind>

                {
                    !this.state.loading &&
                    <div className="p-4 flex flex-1 flex-col">

                        {
                            <div className="grid grid-cols-7 mb-4 bg-white shadow rounded-lg divide-x">
                                {
                                    ["impressions", "clicks", "spend", "budget", "spend_vs_budget", "CTR", "CPC"].map((metric) => {
                                        return (
                                            <div
                                                onMouseEnter={() => {
                                                    if (metric !== "budget" && metric !== "spend_vs_budget") {
                                                        this.setState({
                                                            show_metric: metric
                                                        })
                                                    }
                                                }}
                                                onMouseLeave={() => {
                                                    this.setState({
                                                        show_metric: null
                                                    })
                                                }}
                                                className="col-span-1 relative">
                                                {
                                                    this.state.show_metric == metric &&
                                                    <div className="py-2 border shadow px-4 absolute mt-20 w-full overflow-hidden z-70 bg-white rounded-lg">
                                                        <ul>
                                                            {
                                                                this.state.doughnuts[metric.toLowerCase()] &&
                                                                Array.isArray(this.state.doughnuts[metric.toLowerCase()].labels) &&
                                                                this.state.doughnuts[metric.toLowerCase()].labels.map((label, index) => {
                                                                    return {
                                                                        channel: label,
                                                                        percent: this.renders.channelWidth(this.state.doughnuts[metric.toLowerCase()], index, true),
                                                                        value: this.renders.channelValue(this.state.doughnuts[metric.toLowerCase()], index),
                                                                    }
                                                                }).sort((a, b) => b.percent - a.percent).map(item => {
                                                                    return (
                                                                        <div className={"text-xs py-2"}>
                                                                            <div><span className="font-semibold capitalize">{item.channel}</span> {item.percent}%</div>
                                                                            <div>
                                                                                {item.value}
                                                                            </div>
                                                                            <div className={"h-1 rounded-md w-full bg-gray-100 mt-1 overflow-hidden"}>
                                                                                <div style={{ width: item.value + "%" }} className={"bg-" + item.channel + "-500 overflow-hidden h-full"}></div>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </ul>
                                                    </div>
                                                }
                                                <div
                                                    onClick={async () => {
                                                        await this.promisedSetState({
                                                            disable_chart: true,
                                                            metric: metric
                                                        })
                                                        await this.functions.sleep(1000);
                                                        await this.promisedSetState({
                                                            disable_chart: false,
                                                        })
                                                    }}
                                                    className={"p-3 relative overflow-hidden flex w-full h-full cursor-pointer"}>
                                                    {
                                                        this.state.loading_chart &&
                                                        <div className="text-center absolute top-0 bottom-0 z-51 bg-white bg-opacity-75 right-0 left-0 flex justify-center align-middle items-center">
                                                            <div style={{ borderTopColor: "transparent" }} className="w-10 h-10 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                                                        </div>
                                                    }
                                                    <div className="data--item__donut relative text-center items-center w-full">
                                                        {
                                                            !this.state.loading_chart &&
                                                            !(Array.isArray(this.state.advancedFilters) && this.state.advancedFilters.length > 0 && this.state.advancedFilters[0].selected && this.state.advancedFilters[0].selected.value == "this_month") &&
                                                            this.state.previous && this.state.total &&
                                                            <div style={{ top: "4.5px", right: "-2px" }} className="text-xxs absolute font-medium flex flex-row">
                                                                {
                                                                    ((this.renders.metricGoal(metric.toLowerCase()) === "highest" && this.renders.comparedValue(this.state.total[metric.toLowerCase()], this.state.previous[metric.toLowerCase()]) > 0) || (this.renders.metricGoal(metric.toLowerCase()) === "lowest" && this.renders.comparedValue(this.state.total[metric.toLowerCase()], this.state.previous[metric.toLowerCase()]) < 0)) &&
                                                                    <div className="bg-green-100 text-green-500 border-green-500 h-3 w-3 mr-1 rounded-full flex justify-center items-center">
                                                                        {
                                                                            this.renders.comparedValue(this.state.total[metric.toLowerCase()], this.state.previous[metric.toLowerCase()]) > 0 &&
                                                                            <ArrowUpIcon className={"w-2"} />
                                                                        }
                                                                        {
                                                                            this.renders.comparedValue(this.state.total[metric.toLowerCase()], this.state.previous[metric.toLowerCase()]) < 0 &&
                                                                            <ArrowDownIcon className={"w-2"} />
                                                                        }
                                                                    </div>
                                                                }
                                                                {
                                                                    ((this.renders.metricGoal(metric.toLowerCase()) === "lowest" && this.renders.comparedValue(this.state.total[metric.toLowerCase()], this.state.previous[metric.toLowerCase()]) > 0) || (this.renders.metricGoal(metric.toLowerCase()) === "highest" && this.renders.comparedValue(this.state.total[metric.toLowerCase()], this.state.previous[metric.toLowerCase()]) < 0)) &&
                                                                    <div className="bg-red-100 text-red-500 border-red-500 h-3 w-3 mr-1 rounded-full flex justify-center items-center">
                                                                        {
                                                                            this.renders.comparedValue(this.state.total[metric.toLowerCase()], this.state.previous[metric.toLowerCase()]) > 0 &&
                                                                            <ArrowUpIcon className={"w-2"} />
                                                                        }
                                                                        {
                                                                            this.renders.comparedValue(this.state.total[metric.toLowerCase()], this.state.previous[metric.toLowerCase()]) < 0 &&
                                                                            <ArrowDownIcon className={"w-2"} />
                                                                        }
                                                                    </div>
                                                                }
                                                                {
                                                                    ((this.renders.metricGoal(metric.toLowerCase()) === "highest" && this.renders.comparedValue(this.state.total[metric.toLowerCase()], this.state.previous[metric.toLowerCase()]) > 0) || (this.renders.metricGoal(metric.toLowerCase()) === "lowest" && this.renders.comparedValue(this.state.total[metric.toLowerCase()], this.state.previous[metric.toLowerCase()]) < 0)) &&
                                                                    <span className="text-green-500 relative">
                                                                        {this.renders.comparedValue(this.state.total[metric.toLowerCase()], this.state.previous[metric.toLowerCase()])}%
                                                                    </span>
                                                                }
                                                                {
                                                                    ((this.renders.metricGoal(metric.toLowerCase()) === "lowest" && this.renders.comparedValue(this.state.total[metric.toLowerCase()], this.state.previous[metric.toLowerCase()]) > 0) || (this.renders.metricGoal(metric.toLowerCase()) === "highest" && this.renders.comparedValue(this.state.total[metric.toLowerCase()], this.state.previous[metric.toLowerCase()]) < 0)) &&
                                                                    <span className="text-red-500">{this.renders.comparedValue(this.state.total[metric.toLowerCase()], this.state.previous[metric.toLowerCase()])}%</span>
                                                                }
                                                            </div>
                                                        }
                                                        <div className="">
                                                            <div className={"font-bold"}>{this.state.total && this.state.total[metric.toLowerCase()] ? this.renders.addCommas(this.state.total[metric.toLowerCase()]) : "-"}{metric == "spend_vs_budget" ? "%" : ""}</div>
                                                            <div className="text-xs text-gray-700">{metric !== "spend_vs_budget" ? metric : "Spend/Budget"} {(metric == "CTR" || metric == "CPC") ? "Avg." : ""}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        }

                        {
                            <div className={"shadow bg-white w-full flex flex-col overflow-hidden h-full rounded-lg"}>

                                <TableFilters
                                    onAdvancedFilterSearch={async (value, type) => {
                                        let filter = this.state.advancedFilters.find(filter => filter.type === type)

                                        if (filter && filter.onSearch) {
                                            if (value && value === "") {
                                                await this.promisedSetState({
                                                    advancedFilters: this.state.advancedFilters.map(item => {
                                                        if (item.type === type) {
                                                            item.searchValue = "";
                                                            item.page = 1;
                                                            filter = item;
                                                        }

                                                        return item
                                                    }),
                                                });
                                                filter.onSearch();
                                            } else {
                                                if (filter.throttling) {
                                                    //SKIP
                                                } else {
                                                    if (value && value === "") {
                                                        await this.promisedSetState({
                                                            advancedFilters: this.state.advancedFilters.map(item => {
                                                                if (item.type === type) {
                                                                    item.searchValue = value;
                                                                    item.page = 1;
                                                                }

                                                                return item
                                                            }),
                                                        });
                                                        filter.onSearch();
                                                    } else {
                                                        await this.promisedSetState({
                                                            advancedFilters: this.state.advancedFilters.map(item => {
                                                                if (item.type === type) {
                                                                    item.searchValue = value;
                                                                    item.throttling = true;
                                                                }

                                                                return item
                                                            }),
                                                        });
                                                        setTimeout(async () => {
                                                            await this.promisedSetState({
                                                                advancedFilters: this.state.advancedFilters.map(item => {
                                                                    if (item.type === type) {
                                                                        item.throttling = false;
                                                                        item.page = 1;
                                                                    }

                                                                    return item
                                                                }),
                                                            });
                                                            filter.onSearch();
                                                        }, 300);
                                                    }
                                                }
                                            }
                                        }
                                    }}
                                    onAdvancedFilterPagination={async (value, type) => {
                                        let filter = null;

                                        this.setState({
                                            advancedFilters: this.state.advancedFilters.map(item => {
                                                if (item.type === type && !item.loadingPagination) {
                                                    item.page = item.page + 1;
                                                    filter = item;
                                                }

                                                return item
                                            })
                                        })

                                        if (filter && filter.onPagination) {
                                            filter.onPagination();
                                        }
                                    }}
                                    onAdvancedFilter={async (value, type) => {
                                        this.state.advancedFilters = this.state.advancedFilters.map((item) => {
                                            if (item.type === type) {
                                                item.selected = value;
                                            }
                                            return item;
                                        });
                                        this.promisedSetState({
                                            page: 1,
                                            limit: 10,
                                            advancedFilters: this.state.advancedFilters,
                                        });
                                        this.functions.listOrdersCharts();
                                        this.functions.orders();
                                        if (type === "channel") {
                                            this.functions.saveUserOrderChannel();
                                        }
                                    }}
                                    onButton={(type) => {
                                        if (type === "new") {
                                            this.setState({
                                                create_order: true
                                            })
                                        }
                                    }}
                                    onLimit={async (value) => {
                                        await this.promisedSetState({
                                            page: 1,
                                            limit: value
                                        })
                                        this.functions.orders();
                                    }}
                                    onNext={async (value) => {
                                        await this.promisedSetState({
                                            page: value
                                        })
                                        this.functions.orders();
                                        this.functions.history()
                                    }}
                                    onPrevious={async (value) => {
                                        await this.promisedSetState({
                                            page: value
                                        })
                                        this.functions.orders();
                                        this.functions.history()
                                    }}
                                    onSearch={async (value) => {
                                        await this.promisedSetState({
                                            loading_partial: true,
                                            search: value
                                        });
                                        setTimeout(async () => {
                                            if (value === this.state.search) {
                                                await this.promisedSetState({
                                                    page: 1
                                                });
                                                this.functions.orders(false, value);
                                            }
                                        }, 400);
                                    }}
                                    limit={this.state.limit}
                                    page={this.state.page}
                                    total={this.state.meta ? this.state.meta.total : 0}
                                    showPaginaton={!this.state.loading}
                                    showPaginationSearch={!this.state.loading}
                                    showAdvanced={!this.state.loading}
                                    advancedFilter={true}
                                    advancedFilters={this.state.advancedFilters}
                                    filters={[]}
                                    hideUserDropdown={true}
                                    breadcrumb={"Orders"}
                                    /*
                                    buttons={this.state.user && this.state.user.userRole && this.state.user.userRole === "sales" ? [] : [
                                        { text: "New order", icon: CubeIcon, value: "new", loading: false, show: !this.state.loading }
                                    ]}
                                    */
                                    buttonTypes={{}}
                                    buttonLoaders={{}}
                                    showClient={true}
                                    lockClient={false}
                                    onClient={async (client) => {
                                        await this.promisedSetState({
                                            page: 1,
                                            client: client
                                        });
                                        this.functions.orders();
                                        this.props.updateStatistics(client);
                                    }}
                                    show_table_settings={true}
                                    onOpenTableSettings={async () => {
                                        await this.promisedSetState({ show_table_settings: true });
                                    }}
                                    onSetTags={async (value) => {
                                        this.functions.handleSetTags(value)
                                    }}
                                    url_tags={this.state.selected_tags}
                                    showTagsDropdown={true}
                                    tagType={"order"}
                                    searchInputValue={this.state.search}
                                    search={this.state.search}
                                    client={this.state.client}
                                />

                                {/*NO ORDERS*/}
                                {
                                    this.state.orders.length < 1 &&
                                    <div className="border-t border-gray-200 h-120 flex justify-center items-center relative">
                                        <div className="text-center flex justity-center items-center flex-col">
                                            <div className="mt-2 text-xms font-medium">No orders available</div>
                                        </div>
                                        {
                                            this.state.loading_partial &&
                                            <div className="text-center absolute top-0 bottom-0 z-50 bg-white bg-opacity-75 right-0 left-0 flex justify-center align-middle items-center">
                                                <div style={{ borderTopColor: "transparent" }} className="w-10 h-10 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                                            </div>
                                        }
                                    </div>
                                }

                                {/*TABLE*/}
                                {
                                    this.state.orders.length > 0 &&
                                    <div className="relative w-full flex flex-1 border-t">
                                        <div className="overflow-auto absolute table-overflow w-full h-full">

                                            {/*LOADER PARTIAL*/}
                                            {
                                                this.state.loading_partial &&
                                                <div className="text-center absolute top-0 bottom-0 z-50 bg-white bg-opacity-75 right-0 left-0 flex justify-center align-middle items-center">
                                                    <div style={{ borderTopColor: "transparent" }} className="w-10 h-10 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                                                </div>
                                            }

                                            {/*CONTENT*/}
                                            <table className="w-full relative divide-y divide-gray-300 border-gray-300">
                                                <thead className="sticky z-60 bg-white top-0">
                                                    <tr>
                                                        <th style={{ maxWidth: "50px", width: "50px" }} scope="col" className='border-r bg-gray-100 bg-opacity-100'></th>
                                                        {this.state.columns.filter(col => { return !col.disabled }).map((item, index) => {
                                                            return (
                                                                <th
                                                                    onClick={async () => {
                                                                        if (!item.noSort) {
                                                                            await this.promisedSetState({
                                                                                page: 1,
                                                                                sort: item.value,
                                                                                direction: this.state.direction === 'ascending' ? 'descending' : 'ascending'
                                                                            });
                                                                            this.functions.orders();
                                                                        }
                                                                    }}
                                                                    style={index === 0 ? { left: 0, whiteSpace: "nowrap" } : { whiteSpace: "nowrap" }}
                                                                    scope="col"
                                                                    className={(item.value == this.state.sort ? "bg-gray-100 bg-opacity-100 cursor-pointer" : (!item.noSort ? "bg-white hover:bg-gray-100 hover:bg-opacity-100 cursor-pointer" : "")) + " border-r px-3 py-3 border-gray-300 text-left bg-gray-100 text-xxms font-medium whitespace-nowrap text-gray-700 uppercase tracking-wider " + (index === 0 && " sticky ")}
                                                                >
                                                                    <div className="flex flex-row items-center justify-between">
                                                                        <div className="mr-4 text-xxs">{item.name}</div>
                                                                        {
                                                                            !item.noSort &&
                                                                            <div className="flex justify-start flex-col">
                                                                                <ArrowDownIcon className={`h-3 w-3 text-gray-900 opacity-0 ${this.state.sort === item.value && "opacity-100"} ${this.state.sort === item.value && this.state.direction === "ascending" && "transform rotate-180"}`} />
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                </th>
                                                            )
                                                        })}
                                                    </tr>
                                                </thead>
                                                <tbody className="bg-white tiny_table_row divide-gray-300 relative">
                                                    {
                                                        this.state.orders.map((item, index) => {
                                                            return (
                                                                <Fragment>
                                                                    <tr
                                                                        onMouseEnter={() => {
                                                                            item.hover = true;
                                                                            this.setState({
                                                                                orders: this.state.orders
                                                                            })
                                                                        }}
                                                                        onMouseLeave={() => {
                                                                            item.hover = false;
                                                                            this.setState({
                                                                                orders: this.state.orders
                                                                            })
                                                                        }}
                                                                        className="border-b"
                                                                        key={item.id}
                                                                    >
                                                                        <td className={(item.hover ? "bg-gray-50 bg-opacity-100" : "bg-white bg-opacity-100") + " px-4 border-r border-gray-300 overflow-visible"}>
                                                                            <div className="">
                                                                                <Menu as="div" className="">
                                                                                    <div>
                                                                                        <Menu.Button className={"cursor-pointer bg-gray-50 flex relative w-6 h-6 justify-center items-center rounded border border-gray-300 text-xms font-medium text-gray-700 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"}>
                                                                                            <ChevronDownIcon className="w-4" aria-hidden="true" />
                                                                                            {
                                                                                                item.loading &&
                                                                                                <div className="w-full h-full absolute bg-white top-0 bottom-0 rounded-md left-0 right-0 flex justify-center items-center">
                                                                                                    <div style={{ borderTopColor: "transparent" }}
                                                                                                        className="w-4 h-4 border-2 border-purple-500 absolute border-solid rounded-full animate-spin"></div>
                                                                                                </div>
                                                                                            }
                                                                                        </Menu.Button>
                                                                                    </div>
                                                                                    <Transition
                                                                                        as={Fragment}
                                                                                        enter="transition ease-out duration-100"
                                                                                        enterFrom="transform opacity-0 scale-95"
                                                                                        enterTo="transform opacity-100 scale-100"
                                                                                        leave="transition ease-in duration-75"
                                                                                        leaveFrom="transform opacity-100 scale-100"
                                                                                        leaveTo="transform opacity-0 scale-95"
                                                                                    >
                                                                                        <Menu.Items className={`border absolute z-60 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none`}>
                                                                                            {/* <Menu.Items className={`${this.state.orders.length - (index + 1) < 5 && this.state.orders.length > 5 ? "bottom-100 left-100" : ""} border absolute z-60 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none`}> */}
                                                                                            <div className="py-1">
                                                                                                {
                                                                                                    this.state.orderOptions.filter((option) => {
                                                                                                        // console.log("item", item)
                                                                                                        // console.log("OPEN", this.state)
                                                                                                        if (option === "Archive") {
                                                                                                            return item.status === "paused" || item.status === "ended";
                                                                                                        } else if (option === "Activate") {
                                                                                                            return (item.status === "archived" || item.status === "paused") && item.status !== "ended";
                                                                                                        } else if (option === "Delete") {
                                                                                                            return item.status === "archived";
                                                                                                        } else if (option === "Create draft") {
                                                                                                            return item.status !== "archived" && item.status !== "ended";
                                                                                                        } else if (option === "Pause") {
                                                                                                            return item.status !== "paused" && item.status !== "ended";
                                                                                                        } else {
                                                                                                            return true;
                                                                                                        }
                                                                                                    }).filter((option) => {
                                                                                                        if (option === "Claim order") {
                                                                                                            return item.agents < 1
                                                                                                        } else if (option === "Assign to order") {
                                                                                                            return item.agents > 0
                                                                                                        } else {
                                                                                                            return true;
                                                                                                        }
                                                                                                    }).map((option) => {
                                                                                                        return (
                                                                                                            <Menu.Item>
                                                                                                                {({ active }) => (
                                                                                                                    <div
                                                                                                                        onClick={() => {
                                                                                                                            this.functions.menu(option, item);
                                                                                                                        }}
                                                                                                                        className={cn(
                                                                                                                            active ? 'bg-gray-50 text-gray-900' : 'text-gray-700',
                                                                                                                            'px-4 py-2 text-xms relative flex flex-row cursor-pointer ',
                                                                                                                            (option == "Claim order" || option == "Assign to order") ? 'bg-purple-100 text-purple-500' : '',
                                                                                                                            (option == "Delete") ? 'bg-red-100 text-red-500' : ''
                                                                                                                        )}
                                                                                                                    >
                                                                                                                        {option}
                                                                                                                        <div className="flex flex-1"></div>
                                                                                                                        {
                                                                                                                            (option == "Claim order" || option == "Assign to order") &&
                                                                                                                            <UserAddIcon className="w-5" />
                                                                                                                        }
                                                                                                                        {
                                                                                                                            (option == "Edit") &&
                                                                                                                            <PencilAltIcon className="w-5" />
                                                                                                                        }
                                                                                                                        {
                                                                                                                            (option == "Clone") &&
                                                                                                                            <DuplicateIcon className="w-5" />
                                                                                                                        }
                                                                                                                        {
                                                                                                                            (option == "Delete") &&
                                                                                                                            <TrashIcon className="w-5" />
                                                                                                                        }
                                                                                                                    </div>
                                                                                                                )}
                                                                                                            </Menu.Item>
                                                                                                        )
                                                                                                    })
                                                                                                }
                                                                                            </div>
                                                                                        </Menu.Items>
                                                                                    </Transition>
                                                                                </Menu>
                                                                            </div>
                                                                        </td>
                                                                        {
                                                                            this.state.columns.map((column, index) => {
                                                                                if (column.disabled) {
                                                                                    return;
                                                                                } else if (column.value === "name") {
                                                                                    return (
                                                                                        <td style={{ zIndex: 0 }} className={((this.state.sort === 'name' || item.hover) ? "bg-gray-50 bg-opacity-100" : "bg-white bg-opacity-100") + " sticky left-0 border-r text-left px-3 py-4 whitespace-no-wrap font-semibold cursor-pointer hover:text-purple-500 items-center text-xms border-gray-300"}>
                                                                                            <div className="flex flex-row items-center justify-start">
                                                                                                <div onClick={() => {
                                                                                                    this.functions.history();
                                                                                                    this.functions.menu("Open", item);
                                                                                                }}>
                                                                                                    {item.name ? item.name : "-"}
                                                                                                </div>
                                                                                                {
                                                                                                    Array.isArray(item.tags) && item.tags.length > 0 &&
                                                                                                    <div className="flex flex-row whitespace-no-wrap ml-2">
                                                                                                        {
                                                                                                            item.tags.map((item) => {
                                                                                                                return (
                                                                                                                    <div>
                                                                                                                        <div className="text-xxs text-gray-900 font-medium mr-1 py-0.5 rounded-full bg-gray-100 border px-2">{item.name}</div>
                                                                                                                    </div>
                                                                                                                )
                                                                                                            })
                                                                                                        }
                                                                                                    </div>
                                                                                                }
                                                                                            </div>
                                                                                        </td>
                                                                                    )

                                                                                } else if (column.value === "created") {
                                                                                    return (
                                                                                        <td className={((this.state.sort === 'created' || item.hover) ? "bg-gray-50" : "") + " border-r whitespace-no-wrap px-3 font-medium py-2 items-center text-xms border-gray-300"}>
                                                                                            {item.created ? moment(item.created).format('YYYY-MM-DD') : '-'}
                                                                                        </td>
                                                                                    )
                                                                                } else if (column.value === "client") {
                                                                                    return (
                                                                                        <td className={((this.state.sort === 'client' || item.hover) ? "bg-gray-50" : "bg-white") + " border-r px-3 py-2 font-medium whitespace-no-wrap  items-center text-xms   border-gray-300"}>
                                                                                            <div className="flex flex-row items-center">
                                                                                                <div className="h-6 w-6 border rounded-md bg-gray-50 overflow-hidden flex justify-center items-center">
                                                                                                    {
                                                                                                        item.client &&
                                                                                                        <img src={item.client.logo} className="bg-cover" />
                                                                                                    }
                                                                                                </div>
                                                                                                <div className="ml-2 whitespace-no-wrap">
                                                                                                    {item.client ? item.client.name : "-"}
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                    )
                                                                                } else if (column.value === "status") {
                                                                                    return (
                                                                                        <td className={((this.state.sort === 'status' || item.hover) ? "bg-gray-50" : "") + " border-r px-3 py-2 items-center text-xxms border-gray-300"}>
                                                                                            <span
                                                                                                className={classNames("px-2 py-1 inline-flex capitalize text-xxms font-semibold rounded-md",
                                                                                                    {
                                                                                                        ["bg-green-100 text-green-500 border-green-500 "]: item.status === "active",
                                                                                                        ["bg-purple-100 text-purple-500 border-purple-500 "]: item.status === "upcoming",
                                                                                                        ["bg-gray-100 text-gray-500 border-gray-500 "]: item.status === "ended" || item.status === "archived",
                                                                                                        ["bg-orange-100 text-orange-500 bg-opacity-50 border-orange-500 "]: item.status === "paused"
                                                                                                    })
                                                                                                }>
                                                                                                {item.status ? item.status : "-"}
                                                                                            </span>
                                                                                        </td>
                                                                                    )
                                                                                } else if (column.value === "warnings") {
                                                                                    return (
                                                                                        <td className={((this.state.sort === 'warnings' || item.hover) ? "bg-gray-50" : "") + " border-r px-3 py-2 items-center text-xxms border-gray-300"}>
                                                                                            {
                                                                                                Array.isArray(item.warnings) && item.warnings.length > 0 &&
                                                                                                <div className={"flex justify-center whitespace-no-wrap"} data-tip='' data-for={item.id + '_warnings'} key={item.id + '_warnings'} >
                                                                                                    <span
                                                                                                        className={"px-2 py-1 bg-red-100 text-red-500 justify-center items-center bg-opacity-50 border-red-500 inline-flex capitalize text-xxms font-semibold rounded-md"}>
                                                                                                        {item.warnings.map((item) => { return item.warning }).filter((value, index, array) => array.indexOf(value) === index).length} warning{item.warnings.map((item) => { return item.warning }).filter((value, index, array) => array.indexOf(value) === index).length > 1 ? "s" : ""}
                                                                                                        <ExclamationIcon className="h-3 w-3 text-red-500 ml-1" />
                                                                                                    </span>
                                                                                                    <ReactTooltip effect='solid' id={item.id + '_warnings'}>
                                                                                                        <ul>
                                                                                                            {
                                                                                                                item.warnings.map((item) => { return item.warning }).filter((value, index, array) => array.indexOf(value) === index).map(warning => {
                                                                                                                    return (
                                                                                                                        <li>
                                                                                                                            {warning}
                                                                                                                        </li>
                                                                                                                    )
                                                                                                                })
                                                                                                            }
                                                                                                        </ul>
                                                                                                    </ReactTooltip>
                                                                                                </div>
                                                                                            }
                                                                                        </td>
                                                                                    )
                                                                                } else if (column.value === "agents") {
                                                                                    return (
                                                                                        <td className={((this.state.sort === 'agents' || item.hover) ? "bg-gray-50" : "") + " border-r px-3 font-medium py-2 items-center text-xms border-gray-300"}>
                                                                                            {
                                                                                                Array.isArray(item.agents) &&
                                                                                                item.agents.length > 0 &&
                                                                                                <div className="flex flex-row pl-2"
                                                                                                    data-tip='' data-for={item._id + "_agents"} key={item._id + "_agents"}>
                                                                                                    {
                                                                                                        item.agents.map(agent => {
                                                                                                            return (
                                                                                                                <div
                                                                                                                    className="w-7 h-7 rounded-full p-1.5 border-2 border-white -ml-2"
                                                                                                                    style={{ backgroundColor: agent.color }}>
                                                                                                                    <div className="text-white flex w-full uppercase h-full items-center justify-center">
                                                                                                                        {agent.email ? agent.email[0] : ""}
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            )
                                                                                                        })
                                                                                                    }
                                                                                                    <ReactTooltip effect='solid' id={item._id + "_agents"}>
                                                                                                        {
                                                                                                            item.agents.map(agent => {
                                                                                                                return (
                                                                                                                    <div>{agent.email}</div>
                                                                                                                )
                                                                                                            })
                                                                                                        }
                                                                                                    </ReactTooltip>
                                                                                                </div>
                                                                                            }
                                                                                            {
                                                                                                ((!Array.isArray(item.agents)) || (Array.isArray(item.agents) && item.agents.length < 1)) &&
                                                                                                <span
                                                                                                    className="px-2 py-1 inline-flex text-xxms font-semibold rounded-md bg-orange-100 text-orange-500 bg-opacity-50 border-orange-500 ">
                                                                                                    Unassigned
                                                                                                </span>
                                                                                            }
                                                                                        </td>
                                                                                    )
                                                                                } else if (column.value === "channels") {
                                                                                    return (
                                                                                        <td className={((this.state.sort === 'channels' || item.hover) ? "bg-gray-50" : "") + " border-r px-3 py-2 items-center text-xms  border-gray-300"}>
                                                                                            <div
                                                                                                className="flex flex-row pl-2">
                                                                                                {item.channels && item.channels.adform &&
                                                                                                    <div
                                                                                                        className="w-7 h-7 bg-adform-500 rounded-full p-1.5 border-2 border-white -ml-2">
                                                                                                        <div
                                                                                                            className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                                            style={{ backgroundImage: "url(" + require('../assets/images/adform_icon.png') + ")" }}></div>
                                                                                                    </div>
                                                                                                }
                                                                                                {item.channels && item.channels.facebook &&
                                                                                                    <div
                                                                                                        className="w-7 h-7 bg-facebook-500 rounded-full p-1.5 border-2 border-white -ml-2">
                                                                                                        <div
                                                                                                            className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                                            style={{ backgroundImage: "url(" + require('../assets/images/facebook_icon.svg') + ")" }}></div>
                                                                                                    </div>
                                                                                                }
                                                                                                {item.channels && item.channels.linkedin &&
                                                                                                    <div
                                                                                                        className={'w-7 h-7 bg-linkedin-500 rounded-full p-1.5 border-2 border-white -ml-2'}>
                                                                                                        <div
                                                                                                            className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                                            style={{ backgroundImage: "url(" + require('../assets/images/linkedin_icon.svg') + ")" }}></div>
                                                                                                    </div>
                                                                                                }
                                                                                                {item.channels && item.channels.google &&
                                                                                                    <div
                                                                                                        className={'w-7 h-7 bg-google-500 rounded-full p-1.5 border-2 border-white -ml-2'}>
                                                                                                        <div
                                                                                                            className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                                            style={{ backgroundImage: "url(" + require('../assets/images/google_icon.svg') + ")" }}></div>
                                                                                                    </div>
                                                                                                }
                                                                                                {item.channels && item.channels.tiktok &&
                                                                                                    <div
                                                                                                        className={'w-7 h-7 bg-black rounded-full p-1.5 border-2 border-white -ml-2'}>
                                                                                                        <div
                                                                                                            className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                                            style={{ backgroundImage: "url(" + require('../assets/images/tiktok_icon.png') + ")" }}></div>
                                                                                                    </div>
                                                                                                }
                                                                                                {item.channels && item.channels.twitter &&
                                                                                                    <div
                                                                                                        className={'w-7 h-7 bg-twitter-500 rounded-full p-1.5 border-2 border-white -ml-2'}>
                                                                                                        <div
                                                                                                            className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                                            style={{ backgroundImage: "url(" + require('../assets/images/twitter_icon.svg') + ")" }}></div>
                                                                                                    </div>
                                                                                                }
                                                                                                {item.channels && item.channels.google_analytics &&
                                                                                                    <div
                                                                                                        className={'w-7 h-7 bg-googleanalytics-500 rounded-full p-1.5 border-2 border-white -ml-2'}>
                                                                                                        <div
                                                                                                            className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                                            style={{ backgroundImage: "url(" + require('../assets/images/google_analytics_icon.png') + ")" }}></div>
                                                                                                    </div>
                                                                                                }
                                                                                                {item.channels && item.channels.google_analytics_4 &&
                                                                                                    <div
                                                                                                        className={'w-7 h-7 bg-googleanalytics-500 rounded-full p-1.5 border-2 border-white -ml-2'}>
                                                                                                        <div
                                                                                                            className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                                            style={{ backgroundImage: "url(" + require('../assets/images/ga_icon.png') + ")" }}></div>
                                                                                                    </div>
                                                                                                }
                                                                                                {item.channels && item.channels.snapchat &&
                                                                                                    <div
                                                                                                        className={'w-7 h-7 bg-snapchat-500 rounded-full p-1.5 border-2 border-white -ml-2'}>
                                                                                                        <div
                                                                                                            className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                                            style={{ backgroundImage: "url(" + require('../assets/images/snapchat.svg') + ")" }}></div>
                                                                                                    </div>
                                                                                                }
                                                                                                {item.channels && item.channels.bing &&
                                                                                                    <div
                                                                                                        className={'w-7 h-7 bg-bing-500 rounded-full p-1.5 border-2 border-white -ml-2'}>
                                                                                                        <div
                                                                                                            className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                                            style={{ backgroundImage: "url(" + require('../assets/images/bing-logo.png') + ")" }}></div>
                                                                                                    </div>
                                                                                                }
                                                                                                {item.channels && item.channels.bidtheatre &&
                                                                                                    <div
                                                                                                        className={'w-7 h-7 bg-bidtheatre-500 rounded-full p-1.5 border-2 border-white -ml-2'}>
                                                                                                        <div
                                                                                                            className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                                            style={{ backgroundImage: "url(" + require('../assets/images/bidtheatre_icon.png') + ")" }}></div>
                                                                                                    </div>
                                                                                                }
                                                                                            </div>
                                                                                        </td>
                                                                                    )
                                                                                } else if (column.value === "startDate") {
                                                                                    return (
                                                                                        <td className={((this.state.sort === 'startDate' || item.hover) ? "bg-gray-50" : "") + " border-r px-3 font-medium py-2 items-center text-xms border-gray-300"}>
                                                                                            {item.startDate ? moment(item.startDate).format('YYYY-MM-DD') : '-'}
                                                                                        </td>
                                                                                    )
                                                                                } else if (column.value === "endDate") {
                                                                                    return (
                                                                                        <td className={((this.state.sort === 'endDate' || item.hover) ? "bg-gray-50" : "") + " border-r px-3 font-medium py-2 items-center text-xms  border-gray-300"}>
                                                                                            {item.endDate ? moment(item.endDate).format('YYYY-MM-DD') : '-'}
                                                                                        </td>
                                                                                    )
                                                                                } else if (column.value === "drafts") {
                                                                                    return (
                                                                                        <td className={((this.state.sort === 'drafts' || item.hover) ? "bg-gray-50" : "") + " border-r px-3 font-medium py-2 items-center text-xms border-gray-300"}>
                                                                                            {item.drafts}
                                                                                        </td>
                                                                                    )
                                                                                } else if (column.value === "campaigns") {
                                                                                    return (
                                                                                        <td className={((this.state.sort === 'campaigns' || item.hover) ? "bg-gray-50" : "") + " border-r border-l px-3 font-medium py-2 items-center text-xms border-gray-300"}>
                                                                                            {item.campaigns}
                                                                                        </td>
                                                                                    )
                                                                                } else if (column.value === "totalVersusMaximum") {
                                                                                    return (
                                                                                        <td className={((this.state.sort === 'totalVersusMaximum' || item.hover) ? "bg-gray-50" : "") + " border-r border-l px-3 font-medium py-2 items-center text-xms border-gray-300"}>
                                                                                            {item.maxBudgetEnabled ? item.totalVersusMaximum : "-"}
                                                                                        </td>
                                                                                    )
                                                                                } else if (column.value === "pacing") {
                                                                                    return (
                                                                                        <td className={((this.state.sort === 'pacing' || item.hover) ? "bg-gray-50" : "") + " border-r border-l px-3 font-medium py-2 items-center text-xms border-gray-300"}>
                                                                                            {
                                                                                                this.state.status && this.state.status.value == "active" &&
                                                                                                <span
                                                                                                    className={classNames("px-2 py-1 inline-flex text-xxms font-semibold rounded-md capitalize",
                                                                                                        {
                                                                                                            ["bg-green-100 text-green-500  border-green-500"]: (item.pacing > 0 && item.pacing < 10) || (item.pacing < 0 && item.pacing > -10),
                                                                                                            ["bg-yellow-100 text-yellow-500  border-yellow-500"]: (item.pacing >= 10 && item.pacing < 20) || (item.pacing <= -10 && item.pacing > -20),
                                                                                                            ["bg-red-100 text-red-500 border-red-500"]: item.pacing >= 20 || item.pacing <= -20
                                                                                                        })
                                                                                                    }>
                                                                                                    {this.renders.convertToDecimal(item.pacing) ? (this.renders.convertToDecimal(item.pacing) + "%") : "-"}
                                                                                                    {
                                                                                                        item.pacing &&
                                                                                                        item.pacing < 0 &&
                                                                                                        <ArrowDownIcon className=" ml-1 w-2.5" />
                                                                                                    }
                                                                                                    {
                                                                                                        item.pacing &&
                                                                                                        item.pacing > 0 &&
                                                                                                        <ArrowUpIcon className=" ml-1 w-2.5" />
                                                                                                    }
                                                                                                </span>
                                                                                            }
                                                                                        </td>
                                                                                    )
                                                                                } else if (column.value === "percentage") {
                                                                                    return (
                                                                                        <td className={((this.state.sort === 'percentage' || item.hover) ? "bg-gray-50" : "") + " border-r px-3 py-2 whitespace-no-wrap font-medium cursor-pointer  items-center text-xms   border-gray-300"}>
                                                                                            {this.renders.convertToDecimal(item.max_budget.percentage) + " % of " + item.max_budget.budget + " " + (item.client.currency ? item.client.currency : "")}
                                                                                        </td>
                                                                                    )
                                                                                } else if (column.value === "maxBudgetReached") {
                                                                                    return (
                                                                                        <td className={((this.state.sort === 'status' || item.hover) ? "bg-gray-50" : "") + " border-r px-3 py-2 font-medium cursor-pointer  items-center text-xms   border-gray-300"}>
                                                                                            {
                                                                                                item.maxBudgetEnabled &&
                                                                                                <span
                                                                                                    className={classNames("px-2 py-1 inline-flex text-xxms font-semibold rounded-md capitalize",
                                                                                                        {
                                                                                                            ["bg-purple-100 text-purple-500"]: !item.maxBudgetReached,
                                                                                                            ["bg-red-100 text-orange-500 bg-opacity-50"]: item.maxBudgetReached
                                                                                                        })
                                                                                                    }>
                                                                                                    {item.maxBudgetReached ? "Paused" : "Running"}
                                                                                                </span>
                                                                                            }
                                                                                            {
                                                                                                !item.maxBudgetEnabled &&
                                                                                                <span
                                                                                                    className={classNames("px-2 py-1 inline-flex text-xxms font-semibold rounded-md capitalize",
                                                                                                        {
                                                                                                            ["bg-purple-100 text-purple-500"]: true
                                                                                                        })
                                                                                                    }>
                                                                                                    Running
                                                                                                </span>
                                                                                            }
                                                                                        </td>
                                                                                    )
                                                                                } else if (column.value === "crmId") {
                                                                                    return (
                                                                                        <td className={((this.state.sort === 'crmId' || item.hover) ? "bg-gray-50" : "") + " border-r px-3 py-2 font-medium cursor-pointer items-center text-xms   border-gray-300"}>
                                                                                            {item.crmId ? item.crmId : "-"}
                                                                                        </td>
                                                                                    )
                                                                                } else if (column.value === "spend") {
                                                                                    return (
                                                                                        <td className={((this.state.sort === 'spend' || item.hover) ? "bg-gray-50" : "") + " border-r px-3 py-2 font-medium cursor-pointer items-center text-xms   border-gray-300"}>
                                                                                            {this.renders.convertToDecimal(item.metrics.spend)} {item.client.currency}
                                                                                        </td>
                                                                                    )
                                                                                } else if (column.value === "impressions") {
                                                                                    return (
                                                                                        <td className={((this.state.sort === 'impressions' || item.hover) ? "bg-gray-50" : "") + " border-r px-3 py-2 font-medium cursor-pointer items-center text-xms   border-gray-300"}>
                                                                                            {item.metrics.impressions ? item.metrics.impressions : "0"}
                                                                                        </td>
                                                                                    )
                                                                                } else if (column.value === "clicks") {
                                                                                    return (
                                                                                        <td className={((this.state.sort === 'clicks' || item.hover) ? "bg-gray-50" : "") + " border-r px-3 py-2 font-medium cursor-pointer items-center text-xms   border-gray-300"}>
                                                                                            {item.metrics.clicks ? item.metrics.clicks : "0"}
                                                                                        </td>
                                                                                    )
                                                                                } else if (column.value === "ctr") {
                                                                                    return (
                                                                                        <td className={((this.state.sort === 'ctr' || item.hover) ? "bg-gray-50" : "") + " border-r px-3 py-2 font-medium cursor-pointer items-center text-xms   border-gray-300"}>
                                                                                            {this.renders.convertToDecimal(item.metrics.ctr)}
                                                                                        </td>
                                                                                    )
                                                                                } else if (column.value === "cpc") {
                                                                                    return (
                                                                                        <td className={((this.state.sort === 'cpc' || item.hover) ? "bg-gray-50" : "") + " border-r px-3 py-2 font-medium cursor-pointer items-center text-xms   border-gray-300"}>
                                                                                            {this.renders.convertToDecimal(item.metrics.cpc)} {item.client.currency}
                                                                                        </td>
                                                                                    )
                                                                                } else if (column.value === "cpm") {
                                                                                    return (
                                                                                        <td className={((this.state.sort === 'cpm' || item.hover) ? "bg-gray-50" : "") + " border-r px-3 py-2 font-medium cursor-pointer items-center text-xms   border-gray-300"}>
                                                                                            {this.renders.convertToDecimal(item.metrics.cpm)} {item.client.currency}
                                                                                        </td>
                                                                                    )
                                                                                } else {
                                                                                    return (
                                                                                        <td></td>
                                                                                    )
                                                                                }
                                                                            })
                                                                        }
                                                                    </tr>
                                                                </Fragment>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                }

                            </div>
                        }

                    </div >
                }

            </div>
        )
    }
}

export default OrderList;
