import React, { Component, Fragment } from 'react';
import GoogleAnalyticsDimensions from "../../assets/json/dimensions/google_analytics.json";
import GoogleAnalytics4Dimensions from "../../assets/json/dimensions/google_analytics_4.json";
import AdformDimensions from "../../assets/json/dimensions/adform.json";
import Cm360Dimensions from "../../assets/json/dimensions/cm360.json";
import fuzzysort from "fuzzysort";
import { apiRegister } from "../../services/apiRegister";
import { tokenRegister } from "../../services/tokenRegister";
import FeatherIcon from "feather-icons-react";
import SlideDown from "react-slidedown";
import InputTailwind from "../inputTailwind";

class SelectCellBreakdown extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tab: {},
            accounts: [],
            campaigns: [],
            adgroups: [],
            ads: [],
            selected_channels_internal: {},
            selected_accounts_internal: {},
            selected_campaigns_internal: {},
            selected_adgroups_internal: {},
            selected_ads_internal: {},
            channel_breakdowns: [],
            custom_breakdowns: [],
            ga_dimensions: GoogleAnalyticsDimensions,
            ga4_dimensions: GoogleAnalytics4Dimensions,
            adform_dimensions: AdformDimensions,
            cm360_dimensions: Cm360Dimensions,
            open_channel: {},
            master_template: false,
            search_breakdowns: "",
            test_123: ""
        };
    };

    async componentDidMount() {

        await this.promisedSetState({
            tab: this.props.tab ? this.props.tab : {},
            open: this.props.open,
            master_template: this.props.masterTemplate,
            accounts: this.props.accounts ? this.props.accounts : [],
            campaigns: this.props.campaigns ? this.props.campaigns : [],
            adgroups: this.props.adgroups ? this.props.adgroups : [],
            ads: this.props.ads ? this.props.ads : [],
            selected_channels_internal: this.props.selectedChannelsInternal ? this.props.selectedChannelsInternal : {},
            selected_accounts_internal: this.props.selectedAccountsInternal ? this.props.selectedAccountsInternal : {},
            selected_campaigns_internal: this.props.selectedCampaignsInternal ? this.props.selectedCampaignsInternal : {},
            selected_adgroups_internal: this.props.selectedAdgroupsInternal ? this.props.selectedAdgroupsInternal : {},
            selected_ads_internal: this.props.selectedAdsInternal ? this.props.selectedAdsInternal : {},
        });

        if (!this.state.tab.settings) {
            this.state.tab.settings = {};
            await this.promisedSetState({
                tab: this.state.tab
            });
        }

        let channels = [];
        channels.push({ channel: "google", breakdowns: this.functions.breakdownsGoogle() });
        channels.push({ channel: "adform", breakdowns: this.state.adform_dimensions });
        channels.push({ channel: "facebook", breakdowns: this.functions.breakdownsFacebook() });
        channels.push({ channel: "bidtheatre", breakdowns: this.functions.breakdownsBidtheatre() });
        channels.push({ channel: "linkedin", breakdowns: this.functions.breakdownsLinkedin() });
        channels.push({ channel: "tiktok", breakdowns: this.functions.breakdownsTiktok() });
        channels.push({ channel: "twitter", breakdowns: this.functions.breakdownsTwitter() });
        channels.push({ channel: "google_analytics", breakdowns: this.state.ga_dimensions });
        channels.push({ channel: "google_analytics_4", breakdowns: this.state.ga4_dimensions });
        channels.push({ channel: "snapchat", breakdowns: this.functions.breakdownsSnapchat() });
        channels.push({ channel: "bing", breakdowns: this.functions.breakdownsBing() });
        channels.push({ channel: "dv360", breakdowns: this.functions.breakdownsDv360() });
        channels.push({ channel: "cm360", breakdowns: this.state.cm360_dimensions });
        channels.push({ channel: "google_search_consoole", breakdowns: this.functions.breakdownsGoogleSC() });
        if (!(Array.isArray(this.state.custom_breakdowns) && this.state.custom_breakdowns.length > 0)) {
            if (!this.state.loading_custom_breakdowns) {
                let merged_breakdowns = await this.functions.getCustomDatasourceBreakdowns(channels);
                await this.promisedSetState({
                    channel_breakdowns: merged_breakdowns
                });
            }
        } else {
            channels = channels.concat(this.state.custom_breakdowns);
            await this.promisedSetState({
                channel_breakdowns: channels
            });
        }
    }

    async componentWillReceiveProps(nextProps, nextContext) {

        await this.promisedSetState({
            tab: nextProps.tab ? nextProps.tab : {},
            open: nextProps.open,
            master_template: nextProps.masterTemplate,
            campaigns: nextProps.campaigns ? nextProps.campaigns : [],
            accounts: nextProps.accounts ? nextProps.accounts : [],
            adgroups: nextProps.adgroups ? nextProps.adgroups : [],
            ads: nextProps.ads ? nextProps.ads : [],
            selected_channels_internal: nextProps.selectedChannelsInternal ? nextProps.selectedChannelsInternal : {},
            selected_accounts_internal: nextProps.selectedAccountsInternal ? nextProps.selectedAccountsInternal : {},
            selected_campaigns_internal: nextProps.selectedCampaignsInternal ? nextProps.selectedCampaignsInternal : {},
            selected_adgroups_internal: nextProps.selectedAdgroupsInternal ? nextProps.selectedAdgroupsInternal : {},
            selected_ads_internal: nextProps.selectedAdsInternal ? nextProps.selectedAdsInternal : {},
        });

        if (!this.state.tab.settings) {
            this.state.tab.settings = {};
            await this.promisedSetState({
                tab: this.state.tab
            });
        }

        let channels = [];
        channels.push({ channel: "google", breakdowns: this.functions.breakdownsGoogle() });
        channels.push({ channel: "adform", breakdowns: this.state.adform_dimensions });
        channels.push({ channel: "facebook", breakdowns: this.functions.breakdownsFacebook() });
        channels.push({ channel: "bidtheatre", breakdowns: this.functions.breakdownsBidtheatre() });
        channels.push({ channel: "linkedin", breakdowns: this.functions.breakdownsLinkedin() });
        channels.push({ channel: "tiktok", breakdowns: this.functions.breakdownsTiktok() });
        channels.push({ channel: "twitter", breakdowns: this.functions.breakdownsTwitter() });
        channels.push({ channel: "google_analytics", breakdowns: this.state.ga_dimensions });
        channels.push({ channel: "google_analytics_4", breakdowns: this.state.ga4_dimensions });
        channels.push({ channel: "snapchat", breakdowns: this.functions.breakdownsSnapchat() });
        channels.push({ channel: "bing", breakdowns: this.functions.breakdownsBing() });
        channels.push({ channel: "dv360", breakdowns: this.functions.breakdownsDv360() });
        channels.push({ channel: "cm360", breakdowns: this.state.cm360_dimensions });
        channels.push({ channel: "google_search_console", breakdowns: this.functions.breakdownsGoogleSC() });
        if (!(Array.isArray(this.state.custom_breakdowns) && this.state.custom_breakdowns.length > 0)) {
            if (!this.state.loading_custom_breakdowns) {
                let merged_breakdowns = await this.functions.getCustomDatasourceBreakdowns(channels);
                await this.promisedSetState({
                    channel_breakdowns: merged_breakdowns
                });
            }
        } else {
            channels = channels.concat(this.state.custom_breakdowns);
            await this.promisedSetState({
                channel_breakdowns: channels
            });
        }
    }

    functions = {
        valid: () => {
            return true
        },
        onlyAccountSelected: () => {
            let other_total = 0;
            let account_total = 0;
            if (this.state.selected_channels_internal && Object.keys(this.state.selected_channels_internal).length > 0) {
                account_total += 1;
            }
            if (this.state.selected_accounts_internal && Object.keys(this.state.selected_accounts_internal).length > 0) {
                account_total += 1;
            }
            if (this.state.selected_campaigns_internal && Object.keys(this.state.selected_campaigns_internal).length > 0) {
                other_total += 1;
            }
            if (this.state.selected_adgroups_internal && Object.keys(this.state.selected_adgroups_internal).length > 0) {
                other_total += 1;
            }
            if (this.state.selected_ads_internal && Object.keys(this.state.selected_ads_internal).length > 0) {
                other_total += 1;
            }
            return other_total < 1 && account_total > 0
        },
        onlyCampaignSelected: () => {
            let other_total = 0;
            let campaigns_total = 0;
            if (this.state.selected_channels_internal && Object.keys(this.state.selected_channels_internal).length > 0) {
                other_total += 1;
            }
            if (this.state.selected_accounts_internal && Object.keys(this.state.selected_accounts_internal).length > 0) {
                other_total += 1;
            }
            if (this.state.selected_campaigns_internal && Object.keys(this.state.selected_campaigns_internal).length > 0) {
                campaigns_total += 1;
            }
            if (this.state.selected_adgroups_internal && Object.keys(this.state.selected_adgroups_internal).length > 0) {
                other_total += 1;
            }
            if (this.state.selected_ads_internal && Object.keys(this.state.selected_ads_internal).length > 0) {
                other_total += 1;
            }
            return other_total < 1 && campaigns_total > 0
        },
        onlyAccountAndCampaignSelected: () => {
            let other_total = 0;
            let account_total = 0;
            let campaign_total = 0;
            if (this.state.selected_channels_internal && Object.keys(this.state.selected_channels_internal).length > 0) {
                account_total += 1;
            }
            if (this.state.selected_accounts_internal && Object.keys(this.state.selected_accounts_internal).length > 0) {
                account_total += 1;
            }
            if (this.state.selected_campaigns_internal && Object.keys(this.state.selected_campaigns_internal).length > 0) {
                campaign_total += 1;
            }
            if (this.state.selected_adgroups_internal && Object.keys(this.state.selected_adgroups_internal).length > 0) {
                other_total += 1;
            }
            if (this.state.selected_ads_internal && Object.keys(this.state.selected_ads_internal).length > 0) {
                other_total += 1;
            }
            return other_total < 1 && (account_total > 0 || campaign_total > 0)
        },
        anythingSelected: () => {
            let total = 0;
            if (this.state.selected_channels_internal && Object.keys(this.state.selected_channels_internal).length > 0) {
                total = total + 1;
            }
            if (this.state.selected_accounts_internal && Object.keys(this.state.selected_accounts_internal).length > 0) {
                total = total + 1;
            }
            if (this.state.selected_campaigns_internal && Object.keys(this.state.selected_campaigns_internal).length > 0) {
                total = total + 1;
            }
            if (this.state.selected_adgroups_internal && Object.keys(this.state.selected_adgroups_internal).length > 0) {
                total = total + 1;
            }
            if (this.state.selected_ads_internal && Object.keys(this.state.selected_ads_internal).length > 0) {
                total = total + 1;
            }
            return total > 0
        },
        amountSelected: () => {
            let total = 0;
            if (this.state.selected_campaigns_internal && Object.keys(this.state.selected_campaigns_internal).length > 0) {
                total = total + Object.keys(this.state.selected_campaigns_internal).length;
            }
            if (this.state.selected_adgroups_internal && Object.keys(this.state.selected_adgroups_internal).length > 0) {
                total = total + Object.keys(this.state.selected_adgroups_internal).length;
            }
            if (this.state.selected_ads_internal && Object.keys(this.state.selected_ads_internal).length > 0) {
                total = total + Object.keys(this.state.selected_ads_internal).length;
            }
            return total;
        },
        selectedDataSourcesChannells: () => {
            let channels = {};
            if (this.state.selected_channels_internal) {
                for (let key in this.state.selected_channels_internal) {
                    channels[this.state.selected_channels_internal[key].channel] = true;
                }
            }
            if (this.state.selected_accounts_internal) {
                for (let key in this.state.selected_accounts_internal) {
                    channels[this.state.selected_accounts_internal[key].channel] = true;
                }
            }
            if (this.state.selected_campaigns_internal) {
                for (let key in this.state.selected_campaigns_internal) {
                    channels[this.state.selected_campaigns_internal[key].channel] = true;
                }
            }
            if (this.state.selected_adgroups_internal) {
                for (let key in this.state.selected_adgroups_internal) {
                    channels[this.state.selected_adgroups_internal[key].channel] = true;
                }
            }
            if (this.state.selected_ads_internal) {
                for (let key in this.state.selected_ads_internal) {
                    channels[this.state.selected_ads_internal[key].channel] = true;
                }
            }
            return channels;
        },
        breakdownsBing: () => {
            let breakdowns = [
                {
                    title: "Basic",
                    values: [
                        { name: "Overview", value: "overview", parent: "basic" },
                        { name: "Device", value: "device", parent: "basic" },
                        { name: "Age & Gender", value: "age_gender", parent: "basic" },
                        { name: "Country & City ", value: "country_city", parent: "basic" }
                    ]
                },
                {
                    title: "Resources",
                    values: [
                        { name: "Keywords", value: "keyword", parent: "Resources" },
                    ]
                },
                {
                    title: "Time",
                    values: [
                        { name: "Date", value: "date", parent: "Time" },
                        { name: "Monthly", value: "monthly", parent: "Time" },
                        { name: "Quarterly", value: "quarterly", parent: "Time" }
                    ]
                }
            ];
            if (this.functions.onlyAccountSelected()) {
                breakdowns[1].values.push({ name: "Campaigns", value: "campaign", parent: "Resources" });
                breakdowns[1].values.push({ name: "Adgroup", value: "adgroup", parent: "Resources" });
                //breakdowns[1].values.push({ name: "Ad", value: "ad", parent: "Resources" });
            }
            return breakdowns;
        },
        breakdownsDv360: () => {
            let breakdowns = [
                {
                    title: "Basic",
                    values: [
                        { name: "Overview", value: "overview", parent: "basic" },
                        //{ name: "Browser", value: "FILTER_BROWSER", parent: "basic" },
                        { name: "Active View: Custom Metric ID", value: "FILTER_ACTIVE_VIEW_CUSTOM_METRIC_ID", parent: "basic" },
                        { name: "Active View: Custom Metric Name", value: "FILTER_ACTIVE_VIEW_CUSTOM_METRIC_NAME", parent: "basic" },
                        { name: "Active View Expected Viewability", value: "FILTER_ACTIVE_VIEW_EXPECTED_VIEWABILITY", parent: "basic" },
                        { name: "Advertiser ID", value: "FILTER_ADVERTISER", parent: "basic" },
                        { name: "Advertiser Currency", value: "FILTER_ADVERTISER_CURRENCY", parent: "basic" },
                        { name: "Advertiser Integration Code", value: "FILTER_ADVERTISER_INTEGRATION_CODE", parent: "basic" },
                        { name: "Advertiser Status", value: "FILTER_ADVERTISER_INTEGRATION_STATUS", parent: "basic" },
                        { name: "Advertiser", value: "FILTER_ADVERTISER_NAME", parent: "basic" },
                        { name: "Advertiser Time Zone", value: "FILTER_ADVERTISER_TIMEZONE", parent: "basic" },
                        { name: "Ad Position", value: "FILTER_AD_POSITION", parent: "basic" },
                        { name: "Ad Type", value: "FILTER_AD_TYPE", parent: "basic" },
                        { name: "Age", value: "FILTER_AGE", parent: "basic" },
                        { name: "Algorithm", value: "FILTER_ALGORITHM", parent: "basic" },
                        { name: "Algorithm ID", value: "FILTER_ALGORITHM_ID", parent: "basic" },
                        { name: "AMP Page Request", value: "FILTER_AMP_PAGE_REQUEST", parent: "basic" },
                        { name: "Anonymous Inventory Modeling", value: "FILTER_ANONYMOUS_INVENTORY_MODELING", parent: "basic" },
                        { name: "App/URL", value: "FILTER_APP_URL", parent: "basic" },
                        { name: "App/URL Excluded", value: "FILTER_APP_URL_EXCLUDED", parent: "basic" },
                        { name: "Asset Combination", value: "FILTER_ASSET_COMBINATION_STRING", parent: "basic" },
                        { name: "Attributed Userlist", value: "FILTER_ATTRIBUTED_USERLIST", parent: "basic" },
                        { name: "Attributed Userlist Cost", value: "FILTER_ATTRIBUTED_USERLIST_COST", parent: "basic" },
                        { name: "Attributed Userlist Type", value: "FILTER_ATTRIBUTED_USERLIST_TYPE", parent: "basic" },
                        { name: "Attribution Model", value: "FILTER_ATTRIBUTION_MODEL", parent: "basic" },
                        { name: "Audience List", value: "FILTER_AUDIENCE_LIST", parent: "basic" },
                        { name: "Audience List Cost", value: "FILTER_AUDIENCE_LIST_COST", parent: "basic" },
                        { name: "Audience List Type", value: "FILTER_AUDIENCE_LIST_TYPE", parent: "basic" },
                        { name: "Audience Name", value: "FILTER_AUDIENCE_NAME", parent: "basic" },
                        { name: "Audience Type", value: "FILTER_AUDIENCE_TYPE", parent: "basic" },
                        { name: "Audio Feed Type", value: "FILTER_AUDIO_FEED_TYPE_NAME", parent: "basic" },
                        { name: "Authorized Seller State", value: "FILTER_AUTHORIZED_SELLER_STATE", parent: "basic" },
                        { name: "Bid Strategy Type", value: "FILTER_BID_STRATEGY_TYPE_NAME", parent: "basic" },
                        { name: "Billable Outcome", value: "FILTER_BILLABLE_OUTCOME", parent: "basic" },
                        { name: "Brand Lift Type", value: "FILTER_BRAND_LIFT_TYPE", parent: "basic" },
                        //{ name: "Browser", value: "FILTER_BROWSER", parent: "basic" },
                        { name: "Budget Segment Budget", value: "FILTER_BUDGET_SEGMENT_BUDGET", parent: "basic" },
                        { name: "Budget Segment Name", value: "FILTER_BUDGET_SEGMENT_DESCRIPTION", parent: "basic" },
                        { name: "Budget Segment End Date", value: "FILTER_BUDGET_SEGMENT_END_DATE", parent: "basic" },
                        { name: "Budget Segment Pacing Percentage", value: "FILTER_BUDGET_SEGMENT_PACING_PERCENTAGE", parent: "basic" },
                        { name: "Budget Segment Start Date", value: "FILTER_BUDGET_SEGMENT_START_DATE", parent: "basic" },
                        { name: "Budget Type (Segment)", value: "FILTER_BUDGET_SEGMENT_TYPE", parent: "basic" },
                        { name: "Insertion Order Daily Frequency", value: "FILTER_CAMPAIGN_DAILY_FREQUENCY", parent: "basic" },
                        { name: "ISP or Carrier ID", value: "FILTER_CARRIER", parent: "basic" },
                        { name: "ISP or Carrier", value: "FILTER_CARRIER_NAME", parent: "basic" },
                        { name: "Channel ID", value: "FILTER_CHANNEL_ID", parent: "basic" },
                        { name: "Channel", value: "FILTER_CHANNEL_NAME", parent: "basic" },
                        { name: "Channel Type", value: "FILTER_CHANNEL_TYPE", parent: "basic" },
                        { name: "City ID", value: "FILTER_CITY", parent: "basic" },
                        { name: "City", value: "FILTER_CITY_NAME", parent: "basic" },
                        { name: "CM360 Placement ID", value: "FILTER_CM360_PLACEMENT_ID", parent: "basic" },
                        { name: "Companion Creative ID", value: "FILTER_COMPANION_CREATIVE_ID", parent: "basic" },
                        { name: "Companion Creative", value: "FILTER_COMPANION_CREATIVE_NAME", parent: "basic" },
                        { name: "Publisher ID", value: "FILTER_CONTAINER_PERMISSIONED_LIST", parent: "basic" },
                        { name: "Conversion Ad Event Type", value: "FILTER_CONVERSION_AD_EVENT_TYPE", parent: "basic" },
                        { name: "Conversion Ad Event Type ID", value: "FILTER_CONVERSION_AD_EVENT_TYPE_ID", parent: "basic" },
                        { name: "Time to Conversion", value: "FILTER_CONVERSION_DELAY", parent: "basic" },
                        { name: "Conversion Source", value: "FILTER_CONVERSION_SOURCE", parent: "basic" },
                        { name: "Conversion Source ID", value: "FILTER_CONVERSION_SOURCE_ID", parent: "basic" },
                        { name: "Country", value: "FILTER_COUNTRY", parent: "basic" },
                        { name: "Country ID", value: "FILTER_COUNTRY_ID", parent: "basic" },
                        { name: "Creative", value: "FILTER_CREATIVE", parent: "basic" },
                        { name: "Creative Asset", value: "FILTER_CREATIVE_ASSET", parent: "basic" },
                        { name: "Creative Attributes", value: "FILTER_CREATIVE_ATTRIBUTE", parent: "basic" },
                        { name: "Creative Height", value: "FILTER_CREATIVE_HEIGHT", parent: "basic" },
                        { name: "Creative ID", value: "FILTER_CREATIVE_ID", parent: "basic" },
                        { name: "Creative Integration Code", value: "FILTER_CREATIVE_INTEGRATION_CODE", parent: "basic" },
                        { name: "Creative Rendered in AMP", value: "FILTER_CREATIVE_RENDERED_IN_AMP", parent: "basic" },
                        { name: "Creative Size", value: "FILTER_CREATIVE_SIZE", parent: "basic" },
                        { name: "Creative Source", value: "FILTER_CREATIVE_SOURCE", parent: "basic" },
                        { name: "Creative Status", value: "FILTER_CREATIVE_STATUS", parent: "basic" },
                        { name: "Creative Type", value: "FILTER_CREATIVE_TYPE", parent: "basic" },
                        { name: "Creative Width", value: "FILTER_CREATIVE_WIDTH", parent: "basic" },
                        { name: "CTV Audience Expansion", value: "FILTER_CTV_AUDIENCE_EXPANSION", parent: "basic" },
                        { name: "Data Provider ID", value: "FILTER_DATA_PROVIDER", parent: "basic" },
                        { name: "Data Provider", value: "FILTER_DATA_PROVIDER_NAME", parent: "basic" },
                        { name: "Data Source", value: "FILTER_DATA_SOURCE", parent: "basic" },
                        { name: "Date", value: "FILTER_DATE", parent: "basic" },
                        { name: "Day of Week", value: "FILTER_DAY_OF_WEEK", parent: "basic" },
                        { name: "Detailed Demographics", value: "FILTER_DETAILED_DEMOGRAPHICS", parent: "basic" },
                        { name: "Detailed Demographics ID", value: "FILTER_DETAILED_DEMOGRAPHICS_ID", parent: "basic" },
                        { name: "Device", value: "FILTER_DEVICE", parent: "basic" },
                        { name: "Device Make", value: "FILTER_DEVICE_MAKE", parent: "basic" },
                        { name: "Device Model", value: "FILTER_DEVICE_MODEL", parent: "basic" },
                        { name: "Device Type", value: "FILTER_DEVICE_TYPE", parent: "basic" },
                        { name: "DFP Insertion Order ID", value: "FILTER_DFP_ORDER_ID", parent: "basic" },
                        { name: "Digital Content Label", value: "FILTER_DIGITAL_CONTENT_LABEL", parent: "basic" },
                        { name: "DMA Code", value: "FILTER_DMA", parent: "basic" },
                        { name: "DMA", value: "FILTER_DMA_NAME", parent: "basic" },
                        { name: "Domain", value: "FILTER_DOMAIN", parent: "basic" },
                        { name: "Eligible Cookies on First-Party Audience List", value: "FILTER_ELIGIBLE_COOKIES_ON_FIRST_PARTY_AUDIENCE_LIST", parent: "basic" },
                        { name: "Eligible Cookies on Third-Party Audience List and Interest", value: "FILTER_ELIGIBLE_COOKIES_ON_THIRD_PARTY_AUDIENCE_LIST_AND_INTEREST", parent: "basic" },
                        { name: "Attributed Event Platform Type", value: "FILTER_EVENT_PLATFORM_TYPE", parent: "basic" },
                        { name: "Event Type", value: "FILTER_EVENT_TYPE", parent: "basic" },
                        { name: "Exchange", value: "FILTER_EXCHANGE", parent: "basic" },
                        { name: "Exchange Code", value: "FILTER_EXCHANGE_CODE", parent: "basic" },
                        { name: "Exchange ID", value: "FILTER_EXCHANGE_ID", parent: "basic" },
                        { name: "Asset", value: "FILTER_EXTENSION", parent: "basic" },
                        { name: "Asset (upgraded)", value: "FILTER_EXTENSION_ASSET", parent: "basic" },
                        { name: "Asset Status (upgraded)", value: "FILTER_EXTENSION_ASSET_STATUS", parent: "basic" },
                        { name: "Asset Type (upgraded)", value: "FILTER_EXTENSION_ASSET_TYPE", parent: "basic" },
                        { name: "Asset Status", value: "FILTER_EXTENSION_STATUS", parent: "basic" },
                        { name: "Asset Type", value: "FILTER_EXTENSION_TYPE", parent: "basic" },
                        { name: "First Party Audience List Cost", value: "FILTER_FIRST_PARTY_AUDIENCE_LIST_COST", parent: "basic" },
                        { name: "First Party Audience List Type", value: "FILTER_FIRST_PARTY_AUDIENCE_LIST_TYPE", parent: "basic" },
                        { name: "Floodlight Activity", value: "FILTER_FLOODLIGHT_ACTIVITY", parent: "basic" },
                        { name: "Floodlight Activity ID", value: "FILTER_FLOODLIGHT_ACTIVITY_ID", parent: "basic" },
                        { name: "Format", value: "FILTER_FORMAT", parent: "basic" },
                        { name: "DFP Insertion Order", value: "FILTER_GAM_INSERTION_ORDER", parent: "basic" },
                        { name: "DFP Line Item", value: "FILTER_GAM_LINE_ITEM", parent: "basic" },
                        { name: "DFP Line Item ID", value: "FILTER_GAM_LINE_ITEM_ID", parent: "basic" },
                        { name: "Gender", value: "FILTER_GENDER", parent: "basic" },
                        { name: "Gender (gmail)", value: "FILTER_GMAIL_GENDER", parent: "basic" },
                        { name: "Sponsored Product Id", value: "FILTER_GUILDER_PRODUCT_ID", parent: "basic" },
                        { name: "Household Income", value: "FILTER_HOUSEHOLD_INCOME", parent: "basic" },
                        { name: "Impression Counting Method", value: "FILTER_IMPRESSION_COUNTING_METHOD", parent: "basic" },
                        { name: "Rejection Reasond", value: "FILTER_IMPRESSION_LOSS_REJECTION_REASON", parent: "basic" },
                        { name: "Insertion Order ID", value: "FILTER_INSERTION_ORDER", parent: "basic" },
                        { name: "Insertion Order Goal Type", value: "FILTER_INSERTION_ORDER_GOAL_TYPE", parent: "basic" },
                        { name: "Insertion Order Goal Value", value: "FILTER_INSERTION_ORDER_GOAL_VALUE", parent: "basic" },
                        { name: "Insertion Order Integration Code", value: "FILTER_INSERTION_ORDER_INTEGRATION_CODE", parent: "basic" },
                        { name: "Insertion Order", value: "FILTER_INSERTION_ORDER_NAME", parent: "basic" },
                        { name: "Insertion Order Status", value: "FILTER_INSERTION_ORDER_STATUS", parent: "basic" },
                        { name: "Inventory Commitment Type", value: "FILTER_INVENTORY_COMMITMENT_TYPE", parent: "basic" },
                        { name: "Inventory Delivery Method", value: "FILTER_INVENTORY_DELIVERY_METHOD", parent: "basic" },
                        { name: "Format", value: "FILTER_INVENTORY_FORMAT", parent: "basic" },
                        { name: "Inventory Media Cost Type", value: "FILTER_INVENTORY_MEDIA_COST_TYPE", parent: "basic" },
                        { name: "Inventory Rate Type", value: "FILTER_INVENTORY_RATE_TYPE", parent: "basic" },
                        { name: "Inventory Source ID (Legacy)", value: "FILTER_INVENTORY_SOURCE", parent: "basic" },
                        { name: "Inventory Sources Permissions", value: "FILTER_INVENTORY_SOURCES_PERMISSIONS", parent: "basic" },
                        { name: "Inventory Source ID (external)", value: "FILTER_INVENTORY_SOURCE_EXTERNAL_ID", parent: "basic" },
                        { name: "Inventory Source Group", value: "FILTER_INVENTORY_SOURCE_GROUP", parent: "basic" },
                        { name: "Inventory Source Group ID", value: "FILTER_INVENTORY_SOURCE_GROUP_ID", parent: "basic" },
                        { name: "Inventory Source ID", value: "FILTER_INVENTORY_SOURCE_ID", parent: "basic" },
                        { name: "Inventory Source", value: "FILTER_INVENTORY_SOURCE_NAME", parent: "basic" },
                        { name: "Inventory Source Type", value: "FILTER_INVENTORY_SOURCE_TYPE", parent: "basic" },
                        { name: "Keyword", value: "FILTER_KEYWORD", parent: "basic" },
                        { name: "Life Event", value: "FILTER_LIFE_EVENT", parent: "basic" },
                        { name: "Life Events", value: "FILTER_LIFE_EVENTS", parent: "basic" },
                        { name: "Line Item ID", value: "FILTER_LINE_ITEM", parent: "basic" },
                        { name: "Line Item Budget", value: "FILTER_LINE_ITEM_BUDGET", parent: "basic" },
                        { name: "Line Item Daily Frequency", value: "FILTER_LINE_ITEM_DAILY_FREQUENCY", parent: "basic" },
                        { name: "Line Item End Date", value: "FILTER_LINE_ITEM_END_DATE", parent: "basic" },
                        { name: "Line Item Integration Code", value: "FILTER_LINE_ITEM_INTEGRATION_CODE", parent: "basic" },
                        { name: "Line Item Lifetime Frequency", value: "FILTER_LINE_ITEM_LIFETIME_FREQUENCY", parent: "basic" },
                        { name: "Line Item", value: "FILTER_LINE_ITEM_NAME", parent: "basic" },
                        { name: "Line Item Pacing Percentage", value: "FILTER_LINE_ITEM_PACING_PERCENTAGE", parent: "basic" },
                        { name: "Line Item Start Date", value: "FILTER_LINE_ITEM_START_DATE", parent: "basic" },
                        { name: "Line Item Status", value: "FILTER_LINE_ITEM_STATUS", parent: "basic" },
                        { name: "Line Item Type", value: "FILTER_LINE_ITEM_TYPE", parent: "basic" },
                        { name: "Asset (upgraded Location)", value: "FILTER_LOCATION_ASSET", parent: "basic" },
                        { name: "Asset Status (upgraded Location)", value: "FILTER_LOCATION_ASSET_STATUS", parent: "basic" },
                        { name: "Asset Type (upgraded Location)", value: "FILTER_LOCATION_ASSET_TYPE", parent: "basic" },
                        { name: "Matched Genre Target", value: "FILTER_MATCHED_GENRE_TARGET", parent: "basic" },
                        { name: "Match Ratio", value: "FILTER_MATCH_RATIO", parent: "basic" },
                        { name: "Measurement Source", value: "FILTER_MEASUREMENT_SOURCE", parent: "basic" },
                        { name: "Campaign ID", value: "FILTER_MEDIA_PLAN", parent: "basic" },
                        { name: "Campaign", value: "FILTER_MEDIA_PLAN_NAME", parent: "basic" },
                        { name: "Media Type", value: "FILTER_MEDIA_TYPE", parent: "basic" },
                        { name: "Business Chain", value: "FILTER_MOBILE_GEO", parent: "basic" },
                        { name: "Month", value: "FILTER_MONTH", parent: "basic" },
                        { name: "MRAID Support", value: "FILTER_MRAID_SUPPORT", parent: "basic" },
                        { name: "Age (Nielsen)", value: "FILTER_NIELSEN_AGE", parent: "basic" },
                        { name: "Country (Nielsen)", value: "FILTER_NIELSEN_COUNTRY_CODE", parent: "basic" },
                        { name: "Date Range for Cumulative Metrics (Nielsen)", value: "FILTER_NIELSEN_DATE_RANGE", parent: "basic" },
                        { name: "Device ID (Nielsen)", value: "FILTER_NIELSEN_DEVICE_ID", parent: "basic" },
                        { name: "Gender (Nielsen)", value: "FILTER_NIELSEN_GENDER", parent: "basic" },
                        { name: "Restatement Date (Nielsen)", value: "FILTER_NIELSEN_RESTATEMENT_DATE", parent: "basic" },
                        { name: "OM SDK Capable", value: "FILTER_OMID_CAPABLE", parent: "basic" },
                        { name: "OM SDK Available", value: "FILTER_OM_SDK_AVAILABLE", parent: "basic" },
                        { name: "Order ID", value: "FILTER_ORDER_ID", parent: "basic" },
                        { name: "Operating System", value: "FILTER_OS", parent: "basic" },
                        { name: "Other Advertiser ID", value: "FILTER_OTHER_ADVERTISER_ID", parent: "basic" },
                        { name: "Other Advertiser", value: "FILTER_OTHER_ADVERTISER_NAME", parent: "basic" },
                        { name: "Other Insertion Order ID", value: "FILTER_OTHER_CAMPAIGN_ID", parent: "basic" },
                        { name: "Other Insertion Order", value: "FILTER_OTHER_CAMPAIGN_NAME", parent: "basic" },
                        { name: "Other Device Type", value: "FILTER_OTHER_DEVICE_TYPE", parent: "basic" },
                        { name: "Category", value: "FILTER_PAGE_CATEGORY", parent: "basic" },
                        { name: "Environment", value: "FILTER_PAGE_LAYOUT", parent: "basic" },
                        { name: "Partner ID", value: "FILTER_PARTNER", parent: "basic" },
                        { name: "Partner Currency", value: "FILTER_PARTNER_CURRENCY", parent: "basic" },
                        { name: "Partner", value: "FILTER_PARTNER_NAME", parent: "basic" },
                        { name: "Partner Status", value: "FILTER_PARTNER_STATUS", parent: "basic" },
                        { name: "Placement (All YouTube Channels)", value: "FILTER_PLACEMENT_ALL_YOUTUBE_CHANNELS", parent: "basic" },
                        { name: "Placement Name (All YouTube Channels)", value: "FILTER_PLACEMENT_NAME_ALL_YOUTUBE_CHANNELS", parent: "basic" },
                        { name: "Platform", value: "FILTER_PLATFORM", parent: "basic" },
                        { name: "Playback Method", value: "FILTER_PLAYBACK_METHOD", parent: "basic" },
                        { name: "Position in Content", value: "FILTER_POSITION_IN_CONTENT", parent: "basic" },
                        { name: "Public Inventory", value: "FILTER_PUBLIC_INVENTORY", parent: "basic" },
                        { name: "Publisher Property", value: "FILTER_PUBLISHER_PROPERTY", parent: "basic" },
                        { name: "Publisher Property ID", value: "FILTER_PUBLISHER_PROPERTY_ID", parent: "basic" },
                        { name: "Publisher Property Section", value: "FILTER_PUBLISHER_PROPERTY_SECTION", parent: "basic" },
                        { name: "Publisher Property Section ID", value: "FILTER_PUBLISHER_PROPERTY_SECTION_ID", parent: "basic" },
                        { name: "Publisher Traffic Source", value: "FILTER_PUBLISHER_TRAFFIC_SOURCE", parent: "basic" },
                        { name: "Quarter", value: "FILTER_QUARTER", parent: "basic" },
                        { name: "Refreshed Ad", value: "FILTER_REFRESHED_AD_NAME", parent: "basic" },
                        { name: "Refund Reason", value: "FILTER_REFUND_REASON", parent: "basic" },
                        { name: "Region ID", value: "FILTER_REGION", parent: "basic" },
                        { name: "Region", value: "FILTER_REGION_NAME", parent: "basic" },
                        { name: "Remarketing List", value: "FILTER_REMARKETING_LIST", parent: "basic" },
                        { name: "Rewarded", value: "FILTER_REWARDED", parent: "basic" },
                        { name: "Sensitive Category", value: "FILTER_SENSITIVE_CATEGORY", parent: "basic" },
                        { name: "Served Pixel Density", value: "FILTER_SERVED_PIXEL_DENSITY", parent: "basic" },
                        { name: "App/URL ID", value: "FILTER_SITE_ID", parent: "basic" },
                        { name: "Language", value: "FILTER_SITE_LANGUAGE", parent: "basic" },
                        { name: "Video Skippable Support", value: "FILTER_SKIPPABLE_SUPPORT", parent: "basic" },
                        { name: "Subdomain", value: "FILTER_SUBDOMAIN", parent: "basic" },
                        { name: "Targeted Data Providers", value: "FILTER_TARGETED_DATA_PROVIDERS", parent: "basic" },
                        { name: "Attributed Userlist ID", value: "FILTER_TARGETED_USER_LIST", parent: "basic" },
                        { name: "Optimized Targeting", value: "FILTER_TARGETING_EXPANSION", parent: "basic" },
                        { name: "Third Party Audience List Cost", value: "FILTER_THIRD_PARTY_AUDIENCE_LIST_COST", parent: "basic" },
                        { name: "Third Party Audience List Type", value: "FILTER_THIRD_PARTY_AUDIENCE_LIST_TYPE", parent: "basic" },
                        { name: "Time of Day", value: "FILTER_TIME_OF_DAY", parent: "basic" },
                        { name: "YouTube A (trueview)", value: "FILTER_TRUEVIEW_AD", parent: "basic" },
                        { name: "YouTube Ad Group (trueview)", value: "FILTER_TRUEVIEW_AD_GROUP", parent: "basic" },
                        { name: "YouTube Ad ID (trueview)", value: "FILTER_TRUEVIEW_AD_GROUP_AD_ID", parent: "basic" },
                        { name: "YouTube Ad Group ID (trueview)", value: "FILTER_TRUEVIEW_AD_GROUP_ID", parent: "basic" },
                        { name: "YouTube Ad Type (trueview)", value: "FILTER_TRUEVIEW_AD_TYPE_NAME", parent: "basic" },
                        { name: "Age (YouTube) (trueview)", value: "FILTER_TRUEVIEW_AGE", parent: "basic" },
                        { name: "Audience Segment (trueview)", value: "FILTER_TRUEVIEW_AUDIENCE_SEGMENT", parent: "basic" },
                        { name: "Audience Segment Type (trueview)", value: "FILTER_TRUEVIEW_AUDIENCE_SEGMENT_TYPE", parent: "basic" },
                        { name: "Category (trueview)", value: "FILTER_TRUEVIEW_CATEGORY", parent: "basic" },
                        { name: "City (trueview)", value: "FILTER_TRUEVIEW_CITY", parent: "basic" },
                        { name: "Click Type (trueview)", value: "FILTER_TRUEVIEW_CLICK_TYPE_NAME", parent: "basic" },
                        { name: "Conversion Type (trueview)", value: "FILTER_TRUEVIEW_CONVERSION_TYPE", parent: "basic" },
                        { name: "Country (YouTube) (trueview)", value: "FILTER_TRUEVIEW_COUNTRY", parent: "basic" },
                        { name: "Custom Affinity (trueview)", value: "FILTER_TRUEVIEW_CUSTOM_AFFINITY", parent: "basic" },
                        { name: "Detailed Demographics (trueview)", value: "FILTER_TRUEVIEW_DETAILED_DEMOGRAPHICS", parent: "basic" },
                        { name: "Detailed Demographics ID (trueview)", value: "FILTER_TRUEVIEW_DETAILED_DEMOGRAPHICS_ID", parent: "basic" },
                        { name: "DMA ID (trueview)", value: "FILTER_TRUEVIEW_DMA", parent: "basic" },
                        { name: "DMA (trueview)", value: "FILTER_TRUEVIEW_DMA_NAME", parent: "basic" },
                        { name: "Gender (trueview)", value: "FILTER_TRUEVIEW_GENDER", parent: "basic" },
                        { name: "Household Income (trueview)", value: "FILTER_TRUEVIEW_HOUSEHOLD_INCOME", parent: "basic" },
                        { name: "Age (trueview IAR)", value: "FILTER_TRUEVIEW_IAR_AGE", parent: "basic" },
                        { name: "Category (trueview IAR)", value: "FILTER_TRUEVIEW_IAR_CATEGORY", parent: "basic" },
                        { name: "City (trueview IAR)", value: "FILTER_TRUEVIEW_IAR_CITY", parent: "basic" },
                        { name: "Country (trueview IAR)", value: "FILTER_TRUEVIEW_IAR_COUNTRY", parent: "basic" },
                        { name: "Country Name (trueview IAR)", value: "FILTER_TRUEVIEW_IAR_COUNTRY_NAME", parent: "basic" },
                        { name: "Gender (trueview IAR)", value: "FILTER_TRUEVIEW_IAR_GENDER", parent: "basic" },
                        { name: "Interest (trueview IAR)", value: "FILTER_TRUEVIEW_IAR_INTEREST", parent: "basic" },
                        { name: "Language (trueview IAR)", value: "FILTER_TRUEVIEW_IAR_LANGUAGE", parent: "basic" },
                        { name: "Parental Status (trueview IAR)", value: "FILTER_TRUEVIEW_IAR_PARENTAL_STATUS", parent: "basic" },
                        { name: "Region (trueview IAR)", value: "FILTER_TRUEVIEW_IAR_REGION_NAME", parent: "basic" },
                        { name: "Remarketing List ID (trueview IAR)", value: "FILTER_TRUEVIEW_IAR_REMARKETING_LIST", parent: "basic" },
                        { name: "Time of Day (trueview IAR)", value: "FILTER_TRUEVIEW_IAR_TIME_OF_DAY", parent: "basic" },
                        { name: "YouTube Channel (trueview IAR)", value: "FILTER_TRUEVIEW_IAR_YOUTUBE_CHANNEL", parent: "basic" },
                        { name: "YouTube Video (trueview IAR)", value: "FILTER_TRUEVIEW_IAR_YOUTUBE_VIDEO", parent: "basic" },
                        { name: "Zip Code (trueview IAR)", value: "FILTER_TRUEVIEW_IAR_ZIPCODE", parent: "basic" },
                        { name: "Interest (trueview)", value: "FILTER_TRUEVIEW_INTEREST", parent: "basic" },
                        { name: "Keyword (trueview)", value: "FILTER_TRUEVIEW_KEYWORD", parent: "basic" },
                        { name: "Parental Status (trueview)", value: "FILTER_TRUEVIEW_PARENTAL_STATUS", parent: "basic" },
                        { name: "Placement (Managed) (trueview)", value: "FILTER_TRUEVIEW_PLACEMENT", parent: "basic" },
                        { name: "Placement ID (Managed) (trueview)", value: "FILTER_TRUEVIEW_PLACEMENT_ID", parent: "basic" },
                        { name: "Region ID (trueview)", value: "FILTER_TRUEVIEW_REGION", parent: "basic" },
                        { name: "Region (trueview)", value: "FILTER_TRUEVIEW_REGION_NAME", parent: "basic" },
                        { name: "Remarketing List ID (trueview)", value: "FILTER_TRUEVIEW_REMARKETING_LIST", parent: "basic" },
                        { name: "Remarketing List (trueview)", value: "FILTER_TRUEVIEW_REMARKETING_LIST_NAME", parent: "basic" },
                        { name: "Optimized Targeting (trueview)", value: "FILTER_TRUEVIEW_TARGETING_EXPANSION", parent: "basic" },
                        { name: "Placement (All) (trueview)", value: "FILTER_TRUEVIEW_URL", parent: "basic" },
                        { name: "Zipcode (trueview)", value: "FILTER_TRUEVIEW_ZIPCODE", parent: "basic" },
                        { name: "Audience List ID", value: "FILTER_USER_LIST", parent: "basic" },
                        { name: "First Party Audience List ID", value: "FILTER_USER_LIST_FIRST_PARTY", parent: "basic" },
                        { name: "First Party Audience List", value: "FILTER_USER_LIST_FIRST_PARTY_NAME", parent: "basic" },
                        { name: "Third Party Audience List ID", value: "FILTER_USER_LIST_THIRD_PARTY", parent: "basic" },
                        { name: "Third Party Audience List", value: "FILTER_USER_LIST_THIRD_PARTY_NAME", parent: "basic" },
                        { name: "Date (UTC)", value: "FILTER_UTC_DATE", parent: "basic" },
                        { name: "Variant ID", value: "FILTER_VARIANT_ID", parent: "basic" },
                        { name: "Variant Name", value: "FILTER_VARIANT_NAME", parent: "basic" },
                        { name: "Variant Version", value: "FILTER_VARIANT_VERSION", parent: "basic" },
                        { name: "Vendor Measurement Mode", value: "FILTER_VENDOR_MEASUREMENT_MODE", parent: "basic" },
                        { name: "Audibility At Complete", value: "FILTER_VERIFICATION_AUDIBILITY_COMPLETE", parent: "basic" },
                        { name: "Audibility At Start", value: "FILTER_VERIFICATION_AUDIBILITY_START", parent: "basic" },
                        { name: "Verification Video Player Size", value: "FILTER_VERIFICATION_VIDEO_PLAYER_SIZE", parent: "basic" },
                        { name: "Video Player Size at Completion", value: "FILTER_VERIFICATION_VIDEO_PLAYER_SIZE_COMPLETE", parent: "basic" },
                        { name: "Video Player Size at First Quartile", value: "FILTER_VERIFICATION_VIDEO_PLAYER_SIZE_FIRST_QUARTILE", parent: "basic" },
                        { name: "Video Player Size at Midpoint", value: "FILTER_VERIFICATION_VIDEO_PLAYER_SIZE_MID_POINT", parent: "basic" },
                        { name: "Video Player Size at Start", value: "FILTER_VERIFICATION_VIDEO_PLAYER_SIZE_START", parent: "basic" },
                        { name: "Video Player Size at Third Quartile", value: "FILTER_VERIFICATION_VIDEO_PLAYER_SIZE_THIRD_QUARTILE", parent: "basic" },
                        { name: "Verification Video Position", value: "FILTER_VERIFICATION_VIDEO_POSITION", parent: "basic" },
                        { name: "Video Resized", value: "FILTER_VERIFICATION_VIDEO_RESIZED", parent: "basic" },
                        { name: "Video Ad Position In Stream", value: "FILTER_VIDEO_AD_POSITION_IN_STREAM", parent: "basic" },
                        { name: "Companion Creative Size", value: "FILTER_VIDEO_COMPANION_CREATIVE_SIZE", parent: "basic" },
                        { name: "Video Content Duration", value: "FILTER_VIDEO_CONTENT_DURATION", parent: "basic" },
                        { name: "Video Content Live Stream", value: "FILTER_VIDEO_CONTENT_LIVE_STREAM", parent: "basic" },
                        { name: "Video Continuous Play", value: "FILTER_VIDEO_CONTINUOUS_PLAY", parent: "basic" },
                        { name: "Video Creative Duration", value: "FILTER_VIDEO_CREATIVE_DURATION", parent: "basic" },
                        { name: "Video Creative Duration (Skippable)", value: "FILTER_VIDEO_CREATIVE_DURATION_SKIPPABLE", parent: "basic" },
                        { name: "Video Duration", value: "FILTER_VIDEO_DURATION", parent: "basic" },
                        { name: "Max Video Duration", value: "FILTER_VIDEO_DURATION_SECONDS", parent: "basic" },
                        { name: "Max Video Duration Range", value: "FILTER_VIDEO_DURATION_SECONDS_RANGE", parent: "basic" },
                        { name: "Video Format Support", value: "FILTER_VIDEO_FORMAT_SUPPORT", parent: "basic" },
                        { name: "Video Player Size", value: "FILTER_VIDEO_PLAYER_SIZE", parent: "basic" },
                        { name: "Digital Content Label", value: "FILTER_VIDEO_RATING_TIER", parent: "basic" },
                        { name: "Video Skippable Support", value: "FILTER_VIDEO_SKIPPABLE_SUPPORT", parent: "basic" },
                        { name: "Week", value: "FILTER_WEEK", parent: "basic" },
                        { name: "Year", value: "FILTER_YEAR", parent: "basic" },
                        { name: "YouTube Adapted Audience List (Youtube)", value: "FILTER_YOUTUBE_ADAPTED_AUDIENCE_LIST", parent: "basic" },
                        { name: "YouTube Ad Video (Youtube)", value: "FILTER_YOUTUBE_AD_VIDEO", parent: "basic" },
                        { name: "YouTube Ad Video ID (Youtube)", value: "FILTER_YOUTUBE_AD_VIDEO_ID", parent: "basic" },
                        { name: "YouTube Channel (Youtube)", value: "FILTER_YOUTUBE_CHANNEL", parent: "basic" },
                        { name: "Advertiser (Youtube)", value: "FILTER_YOUTUBE_PROGRAMMATIC_GUARANTEED_ADVERTISER", parent: "basic" },
                        { name: "Insertion Order (Youtube)", value: "FILTER_YOUTUBE_PROGRAMMATIC_GUARANTEED_INSERTION_ORDER", parent: "basic" },
                        { name: "Partner (Youtube)", value: "FILTER_YOUTUBE_PROGRAMMATIC_GUARANTEED_PARTNER", parent: "basic" },
                        { name: "YouTube Video (Youtube)", value: "FILTER_YOUTUBE_VIDEO", parent: "basic" },
                        { name: "Zip Code ID (Youtube)", value: "FILTER_ZIP_CODE", parent: "basic" },
                        { name: "Zip Code (Youtube)", value: "FILTER_ZIP_POSTAL_CODE", parent: "basic" },
                    ]
                }
            ];
            return breakdowns;
        },
        breakdownsGoogleSC: () => {
            let breakdowns = [
                {
                    title: "Basic",
                    values: [
                        { name: "Overview", value: "overview", parent: "basic" },
                        { name: "country", value: "country", parent: "basic" },
                        { name: "device", value: "device", parent: "basic" },
                        { name: "page", value: "page", parent: "basic" },
                        { name: "query", value: "query", parent: "basic" },
                        { name: "search appearance", value: "searchAppearance", parent: "basic" },
                    ]
                },
            ];
            return breakdowns;
        },
        breakdownsTiktok: () => {
            let breakdowns = [
                {
                    title: "Basic",
                    values: [
                        { name: "Overview", value: "overview", parent: "Popular" },
                        { name: "Age", value: "age", parent: "Popular" },
                        { name: "Gender", value: "gender", parent: "Popular" },
                        { name: "Country code", value: "country_code", parent: "Popular" }
                    ]
                },
                {
                    title: "Time",
                    values: [
                        { name: "Date", value: "date", parent: "Time" },
                        { name: "Monthly", value: "monthly", parent: "Time" },
                        { name: "Quarterly", value: "quarterly", parent: "Time" }
                    ]
                },
            ];
            if (this.functions.onlyAccountSelected()) {
                breakdowns.push({
                    title: "Resources",
                    values: [
                        { name: "Ads", value: "ads", parent: "Basic" }
                    ]
                });
            }
            return breakdowns;
        },
        breakdownsSnapchat: () => {
            let breakdowns = [
                {
                    title: "Basic",
                    values: [
                        { name: "Overview", value: "overview", parent: "basic" },
                        { name: "Gender", value: "gender", parent: "basic" },
                        { name: "Age", value: "age", parent: "basic" },
                        { name: "Country", value: "country", parent: "basic" },
                        { name: "Region", value: "region", parent: "basic" },
                        { name: "Dma", value: "dma", parent: "basic" }
                    ]
                },
                {
                    title: "Time",
                    values: [
                        { name: "Date", value: "date", parent: "Time" },
                        { name: "Weekly", value: "weekly", parent: "basic" },
                        { name: "Monthly", value: "monthly", parent: "Time" },
                        { name: "Quarterly", value: "quarterly", parent: "Time" }
                    ]
                }
            ];
            if (this.functions.onlyAccountSelected()) {
                breakdowns.push({
                    title: "Resources",
                    values: [
                        { name: "Ads", value: "ads", parent: "Basic" }
                    ]
                });
            }
            return breakdowns;
        },
        breakdownsTwitter: () => {
            let breakdowns = [
                {
                    title: "Basic",
                    values: [
                        { name: "Overview", value: "overview", parent: "Popular" },
                        { name: "Date", value: "date", parent: "Popular" }
                    ]
                },
            ];
            return breakdowns;
        },
        breakdownsGoogle: () => {
            let breakdowns = [
                {
                    title: "Basic",
                    values: [
                        { name: "Overview", value: "overview", parent: "Popular" },
                        { name: "Gender", value: "gender", parent: "Popular" },
                        { name: "Age", value: "age", parent: "Popular" },
                        { name: "Device", value: "device", parent: "Popular" },
                        { name: "Region", value: "region", parent: "Popular" },
                        { name: "County", value: "county", parent: "Popular" },
                        { name: "City", value: "city", parent: "Popular" },
                        { name: "District", value: "district", parent: "Popular" },
                        { name: "Ad network type", value: "adNetworkType", parent: "Popular" },
                        { name: "Conversion", value: "conversion", parent: "Popular" }
                    ]
                },
                {
                    title: "Resources",
                    values: [
                        { name: "Search Terms", value: "search_terms", parent: "Resources" },
                        { name: "Keywords", value: "keyword", parent: "Resources" }
                    ]
                },
                {
                    title: "Time",
                    values: [
                        { name: "Date", value: "date", parent: "Time" },
                        { name: "Weekday", value: "dayOfWeek", parent: "Time" },
                        { name: "Monthly", value: "monthly", parent: "Time" },
                        { name: "Quarterly", value: "quarterly", parent: "Time" }
                    ]
                }
            ];
            if (this.functions.onlyCampaignSelected()) {
                breakdowns[0].values.push({ name: "Performance Max Search Terms (category)", value: "campaign_search_term_insight", parent: "Popular" });
            }
            if (this.functions.onlyAccountAndCampaignSelected()) {
                breakdowns[0].values.push({ name: "Topic", value: "topic_view", parent: "Popular" });
                breakdowns[0].values.push({ name: "Audience campaign", value: "audience", parent: "Popular" });
                breakdowns[0].values.push({ name: "Audience adgroup", value: "audience_adgroup", parent: "Popular" });
                //breakdowns[0].values.push({ name: "Audience (custom)", value: "audience_custom", parent: "Popular" });
                //breakdowns[0].values.push({ name: "Audience (combined)", value: "audience_combined", parent: "Popular" });
                breakdowns[0].values.push({ name: "Placement (detail)", value: "placement_detail", parent: "Popular" });
                breakdowns[0].values.push({ name: "Placement (group)", value: "placement_group", parent: "Popular" });
                //breakdowns[0].values.push({ name: "Placement (type)", value: "placement_type", parent: "Popular" });
                breakdowns[0].values.push({ name: "Display/video keyword", value: "display_keyword", parent: "Popular" });
            }
            if (this.functions.onlyAccountSelected()) {
                breakdowns[1].values.push({ name: "Campaigns", value: "campaigns", parent: "Resources" });
                breakdowns[1].values.push({ name: "Adgroups", value: "adgroups", parent: "Resources" });
                breakdowns[1].values.push({ name: "Campaign assets", value: "campaign_assets", parent: "Resources" });
                breakdowns[1].values.push({ name: "Adgroup assets", value: "adgroup_assets", parent: "Resources" });
                //breakdowns[1].values.push({ name: "Ads", value: "ads", parent: "Popular" });
                breakdowns[1].values.push({ name: "Bidding strategy", value: "bidding_strategy", parent: "Resources" });
                breakdowns[0].values.push({ name: "Shopping", value: "shopping", parent: "Popular" });
            }
            return breakdowns;
        },
        breakdownsLinkedin: () => {
            let breakdowns = [
                {
                    title: "Basic",
                    values: [
                        { name: "Overview", value: "overview", parent: "Popular" }
                    ]
                },
                {
                    title: "Time",
                    values: [
                        { name: "Date", value: "date", parent: "Time" },
                        { name: "Monthly", value: "monthly", parent: "Time" },
                        { name: "Quarterly", value: "quarterly", parent: "Time" }
                    ]
                },
                {
                    title: "Compnay",
                    values: [
                        { name: "Company", value: "MEMBER_COMPANY", parent: "Popular" },
                        { name: "Company Industry", value: "MEMBER_INDUSTRY", parent: "Popular" },
                        { name: "Company Size", value: "MEMBER_COMPANY_SIZE", parent: "Popular" },
                    ]
                },
                {
                    title: "Job",
                    values: [
                        { name: "Job Title", value: "MEMBER_JOB_TITLE", parent: "Popular" },
                        { name: "Job Function", value: "MEMBER_JOB_FUNCTION", parent: "Popular" },
                        { name: "Job Seniority", value: "MEMBER_SENIORITY", parent: "Popular" },
                    ]
                },
                {
                    title: "Location",
                    values: [
                        { name: "Location", value: "MEMBER_REGION_V2", parent: "Popular" },
                        { name: "Country/Region", value: "MEMBER_COUNTRY_V2", parent: "Popular" },
                        { name: "County", value: "MEMBER_COUNTY", parent: "Popular" }
                    ]
                },
            ];
            if (this.functions.onlyAccountSelected()) {
                breakdowns.push({
                    title: "Resources",
                    values: [
                        { name: "Ads", value: "ads", parent: "Basic" }
                    ]
                });
            }
            return breakdowns;
        },
        breakdownsBidtheatre: () => {
            let breakdowns = [
                {
                    title: "Basic",
                    values: [
                        { name: "Overview", value: "overview", parent: "Basic" },
                        { name: "Year / Month / Day", value: "day", parent: "Basic" },
                        { name: "Campaign Label", value: "campaignLabel", parent: "Basic" },
                        { name: "Campaign Package", value: "campaignPackage", parent: "Basic" },
                        { name: "Zone", value: "zone", parent: "Basic" },
                        { name: "Category", value: "category", parent: "Basic" },
                        { name: "Label", value: "label", parent: "Basic" },
                        { name: "Campaign Type", value: "campaignType", parent: "Basic" },
                        { name: "Site Label", value: "siteLabel", parent: "Basic" },
                        { name: "Zone Label", value: "zoneLabel", parent: "Basic" },
                        { name: "Advertiser Label", value: "advertiserLabel", parent: "Basic" },
                        { name: "Client", value: "client", parent: "Basic" },
                        { name: "Adgroup", value: "adgroup", parent: "Basic" },
                        { name: "Ad", value: "ad", parent: "Basic" },
                        { name: "Publisher", value: "publisher", parent: "Basic" },
                        { name: "rtb Supplier", value: "rtbSupplier", parent: "Basic" },
                        { name: "rtb Site", value: "rtbSite", parent: "Basic" },
                        { name: "rtb Supplier", value: "rtbSupplier", parent: "Basic" }
                    ]
                },
                {
                    title: "Time",
                    values: [
                        { name: "Year", value: "year", parent: "Time" },
                        { name: "Year / week", value: "week", parent: "Time" },
                        { name: "Year / Month", value: "month", parent: "Time" },
                        { name: "Year / Month / Day", value: "day", parent: "Time" }
                    ]
                },
            ];
            return breakdowns;
        },
        breakdownsAdform: () => {
            let breakdowns = [
                {
                    title: "Basic",
                    values: [
                        { name: "Overview", value: "overview", parent: "Popular", description: "" }]
                },
                {
                    title: "Banner", values: [
                        { name: "Banner/Adgroups", value: "banner", parent: "Banner", description: "" },
                        { name: "Banner ID", value: "bannerID", parent: "Banner", description: "" },
                        { name: "Banner Size", value: "bannerSize", parent: "Banner", description: "" },
                        { name: "Banner type", value: "bannerType", parent: "Banner", description: "" },
                        { name: "Banner type ID", value: "bannerTypeID", parent: "Banner", description: "" },
                        { name: "Banner format", value: "bannerFormat", parent: "Banner", description: "" },
                        { name: "Banner format ID", value: "bannerFormatID", parent: "Banner", description: "" },
                        { name: "Banner member", value: "bannerMember", parent: "Banner", description: "" },
                        { name: "Backup banner", value: "bannerBackup", parent: "Banner", description: "" },
                        { name: "Backup banner", value: "bannerBackup", parent: "Banner", description: "" },
                    ]
                },
                {
                    title: "Site Tracking", values: [
                        { name: "Page", value: "page", parent: "Site Tracking", description: "Website tracking dimension. Page is a definable content unit in advertiser's site. Usually one tracking point is implemented per page." },
                    ]
                },
                {
                    title: "Time", values: [
                        { name: "Date", value: "date", parent: "Time", decription: "This dimension groups information by date when a transaction happened." },
                    ]
                },
                {
                    title: "Technical", values: [
                        { name: "Device Type", value: "deviceType", parent: "Technical", description: "Technical dimension, groups data by device type, which can be: Desktop and Laptop, Small Screen Phone, Smart Phone, Tablet, Other Mobile, Media Hub, Console, TV, eReader, Smart Watch, Smart Speaker. It shows what share of traffic comes from different device types." },
                    ]
                },
                {
                    title: "RTB", values: [
                        { name: "RTB Domain", value: "rtbDomain", parent: "RTB", description: "This dimension groups data by RTB domain. RTB domains show 2nd level URLs of publisher sites (e.g. cnn.com), where a banner was displayed when impression was registered. RTB domain values are tracked and sent to Adform by AdExchanges." },
                        { name: "RTB Audience", value: "rtbAudience", parent: "RTB", description: "This dimension groups information by RTB audience selected in the Targeting step. An audience is a customized segment of users to be targeted, e.g. women from Denmark using Android devices." }
                    ]
                },
            ];
            return breakdowns;
        },
        breakdownsGoogleAnalytics: () => {
            let beakdowns = [
                {
                    title: "Basic",
                    values: [
                        { name: "Overview", value: "overview", parent: "basic" }
                    ]
                },
                {
                    title: "User",
                    values: [
                        { name: "User Type", value: "ga:userType", parent: "User" },
                        { name: "Count of Sessions", value: "ga:sessionCount", parent: "User" }
                    ]
                },
                {
                    title: "Traffic sources",
                    values: [
                        { name: "Medium", value: "ga:medium", parent: "Traffic sources" },
                        { name: "Source / Medium", value: "ga:sourceMedium", parent: "Traffic sources" },
                        { name: "Full Referrer", value: "ga:fullReferrer", parent: "Traffic sources" },
                        { name: "Referral path", value: "ga:referralPath", parent: "Traffic sources" }
                    ]
                }
            ];
            return beakdowns;
        },
        breakdownsFacebook: () => {
            let breakdowns = [
                {
                    title: "Basic",
                    values: [
                        { name: "Overview", value: "overview", parent: "Basic" },
                        { name: "Ad Format Asset", value: "ad_format_asset", parent: "Basic" },
                        { name: "App ID", value: "app_id", parent: "Basic" },
                        { name: "Body Asset", value: "body_asset", parent: "Basic" },
                        { name: "Call To Action Asset", value: "call_to_action_asset", parent: "Basic" },
                        { name: "Description Asset", value: "description_asset", parent: "Basic" },
                        { name: "Image Asset", value: "image_asset", parent: "Basic" },
                        { name: "Link URL Asset", value: "link_url_asset", parent: "Basic" },
                        { name: "Skan Conversion ID", value: "skan_conversion_id", parent: "Basic" },
                        { name: "Title Asset", value: "title_asset", parent: "Basic" },
                        { name: "Video Asset", value: "video_asset", parent: "Basic" },
                        { name: "DMA", value: "dma", parent: "Basic" },
                        { name: "Frequency Value", value: "frequency_value", parent: "Basic" },
                        { name: "Place Page ID", value: "place_page_id", parent: "Basic" },
                        { name: "Country", value: "country", parent: "Basic" },
                        { name: "Region", value: "region", parent: "Basic" },
                        { name: "Age", value: "age", parent: "Basic" },
                        { name: "Gender", value: "gender", parent: "Basic" },
                        { name: "Platform posistion", value: "platform_position", parent: "Basic" },
                        { name: "Publisher Platform", value: "publisher_platform", parent: "Basic" },
                        { name: "Impression Device", value: "impression_device", parent: "Basic" }
                    ]
                },
                {
                    title: "Time",
                    values: [
                        { name: "Date", value: "date", parent: "Time" },
                        { name: "Weekly", value: "weekly", parent: "Time" },
                        { name: "Monthly", value: "monthly", parent: "Time" },
                        { name: "Quarterly", value: "quarterly", parent: "Time" },
                        { name: "Hourly stats by advertiser timezone", value: "hourly_stats_aggregated_by_advertiser_time_zone", parent: "Time" },
                        { name: "Hourly stats by audience timezone", value: "hourly_stats_aggregated_by_audience_time_zone", parent: "Time" }
                    ]
                }
            ];
            if (this.functions.onlyAccountSelected()) {
                breakdowns.push({
                    title: "Resources",
                    values: [
                        //{ name: "Campaigns", value: "campaigns", parent: "Basic" },
                        //{ name: "Adgroups", value: "adgroups", parent: "Basic" },
                        { name: "Ads", value: "ads", parent: "Basic" },
                        { name: "Shopping", value: "product_id", parent: "Basic" }
                    ]
                });
            }
            return breakdowns;
        },
        getCustomDatasourceBreakdowns: async (channel_breakdowns) => {
            return new Promise(async (resolve) => {
                await this.promisedSetState({
                    loading_custom_breakdowns: true
                });
                try {
                    let response = await this.calls.getCustomDatasourceBreakdowns();
                    channel_breakdowns = channel_breakdowns.concat(response.data);
                    await this.promisedSetState({
                        custom_breakdowns: response.data,
                        loading_custom_breakdowns: false
                    });
                    resolve(channel_breakdowns);
                } catch (error) {
                    await this.promisedSetState({
                        loading_custom_breakdowns: false
                    });
                    resolve(channel_breakdowns);
                }
            })
        },
        search: (search, options) => {
            if (!search || search == "") {
                return options;
            }
            let result = fuzzysort.go(search, options.map((item) => { return item.name }));
            let keywords = [];
            let old_keywords = JSON.parse(JSON.stringify(options));
            result.map((item) => {
                for (let i = 0; i < old_keywords.length; i++) {
                    if (item.target && item.target.toLowerCase() === old_keywords[i].name.toLowerCase()) {
                        let html_string = "";
                        if (Array.isArray(item.indexes) && item.indexes.length > 0) {
                            for (let m = 0; m < item.target.length; m++) {
                                if (!item.indexes.includes(m)) {
                                    html_string = html_string + '<span class="opacity-75">' + item.target.charAt(m) + '</span>';
                                } else {
                                    html_string = html_string + '<span class="">' + item.target.charAt(m) + '</span>';
                                }
                            }
                        }
                        old_keywords[i].html = html_string;
                        keywords.push(old_keywords[i]);
                        old_keywords.splice(i, 1);
                        break;
                    }
                }
            });
            return keywords;
        }
    };

    calls = {
        getCustomDatasourceBreakdowns: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/listCustomDatasourceBreakdowns";
            return apiRegister.call(options, url);
        }
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {
        return (
            <>
                <div
                    className={(this.functions.valid() ? "" : "opacity-50 ") + " px-4 sm:px-6 pb-6 border-b pt-6"}>
                    <div className="flex flex-1 flex-row justify-center items-center">
                        <div onClick={() => {
                            if (this.props.onToggle) {
                                this.props.onToggle(this.state.open);
                            }
                        }} className="flex flex-col flex-1 cursor-pointer">
                            <h3 className="mb-0 font-semibold text-sm">
                                Select breakdown
                            </h3>
                            {
                                !(this.state.tab.channel_breakdowns && Object.keys(this.state.tab.channel_breakdowns).length > 0) &&
                                <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                    There are <span
                                        className="font-bold ml-1 mr-1">{this.state.channel_breakdowns.length}</span> breakdowns
                                    available
                                </p>
                            }
                            {
                                (this.state.tab.channel_breakdowns && Object.keys(this.state.tab.channel_breakdowns).length > 0) &&
                                <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                    You have selected <span className="font-bold ml-1 mr-1">{this.state.channel_breakdowns.filter((item) => {
                                        let channels = this.functions.selectedDataSourcesChannells();
                                        return channels[item.channel];
                                    }).map((item) => {
                                        let dimension = "";
                                        if (this.state.tab.channel_breakdowns && this.state.tab.channel_breakdowns[item.channel]) {
                                            Object.keys(this.state.tab.channel_breakdowns[item.channel]).map((breakdown) => {
                                                if (this.state.tab.channel_breakdowns[item.channel][breakdown]) {
                                                    dimension = this.state.tab.channel_breakdowns[item.channel][breakdown].name;
                                                    dimension += " (" + item.channel + ")";
                                                }
                                            });
                                        }
                                        return dimension;
                                    }).join(", ")}</span> breakdowns
                                </p>
                            }
                        </div>
                        {
                            this.functions.anythingSelected() &&
                            <div className="flex flex-row">
                                {
                                    this.state.tab.channel_breakdowns && Object.keys(this.state.tab.channel_breakdowns).length > 0 &&
                                    <div
                                        className="mr-2 text-purple-500 border-purple-500 border-2 bg-purple-100 cursor-pointer h-10 w-10 flex rounded font-bold justify-center align-middle items-center">
                                        <FeatherIcon className={'stroke-current'} size={16}
                                            icon={"check"} />
                                    </div>
                                }
                                <div onClick={() => {
                                    if (this.props.onToggle) {
                                        this.props.onToggle(this.state.open);
                                    }
                                }}
                                    className="text-purple-500 bg-purple-100 cursor-pointer h-10 w-10 flex rounded font-bold justify-center align-middle items-center">
                                    {
                                        !this.state.open &&
                                        <FeatherIcon className={'stroke-current'} size={16}
                                            icon={"chevron-down"} />
                                    }
                                    {
                                        this.state.open &&
                                        <FeatherIcon className={'stroke-current'} size={16}
                                            icon={"chevron-up"} />
                                    }
                                </div>
                            </div>
                        }
                    </div>
                    <SlideDown closed={!this.state.open}>
                        <div className="w-full h-6" />
                        <div className="flex flex-1 mb-4 flex-col justify-center">
                            <InputTailwind
                                label={null}
                                placeholder={"Search breakdowns ..."}
                                value={this.state.search_breakdowns}
                                onChange={(value) => {
                                    this.setState({
                                        search_breakdowns: value
                                    });
                                }}
                            />
                        </div>
                        <div className="">
                            {
                                this.state.channel_breakdowns.filter((item) => {
                                    let channels = this.functions.selectedDataSourcesChannells();
                                    return channels[item.channel];
                                }).map((item, i) => {
                                    return (
                                        <Fragment key={item.channel + i}>
                                            <div
                                                className="flex flex-1 flex-row justify-center mb-2">
                                                <div onClick={() => {
                                                    if (!(this.state.tab.celltype && this.state.tab.celltype.id === "performance")) {
                                                        this.state.open_channel[item.channel] = !this.state.open_channel[item.channel];
                                                        this.setState({
                                                            open_channel: this.state.open_channel,
                                                            channel_breakdowns: this.state.channel_breakdowns
                                                        })
                                                    }
                                                }}
                                                    className={"text-purple-500 bg-purple-100 cursor-pointer h-10 w-10 flex rounded font-bold mr-2 justify-center align-middle items-center"}>
                                                    {
                                                        !(this.state.tab.celltype && this.state.tab.celltype.id === "performance") &&
                                                        !this.state.open_channel[item.channel] &&
                                                        <FeatherIcon className={'stroke-current'}
                                                            size={16}
                                                            icon="plus" />
                                                    }
                                                    {
                                                        !(this.state.tab.celltype && this.state.tab.celltype.id === "performance") &&
                                                        this.state.open_channel[item.channel] &&
                                                        <FeatherIcon className={'stroke-current'}
                                                            size={16}
                                                            icon="minus" />
                                                    }
                                                </div>
                                                {
                                                    [
                                                        { name: 'facebook', icon: 'facebook_icon.svg', style: { width: '0.4rem' } },
                                                        { name: 'google', icon: 'google_icon.svg' },
                                                        { name: 'google_analytics', icon: 'google_analytics_icon.png' },
                                                        { name: 'google_analytics_4', icon: 'ga_icon.png' },
                                                        { name: 'google_shopping', icon: 'google_shopping_icon.png' },
                                                        { name: 'adform', icon: 'adform_icon.png' },
                                                        { name: 'linkedin', icon: 'linkedin_icon.svg' },
                                                        { name: 'twitter', icon: 'twitter_icon.svg' },
                                                        { name: 'bidtheatre', icon: 'bidtheatre_icon.png' },
                                                        { name: 'snapchat', icon: 'snapchat.svg' },
                                                        { name: 'bing', icon: 'bing-logo.png' },
                                                        { name: 'dv360', icon: 'dv360-logo.svg' },
                                                        { name: 'cm360', icon: 'cm360-logo.png' },
                                                        { name: 'marchex', icon: 'marchex_logo.jpg', style: { width: '80%' } }
                                                    ].filter((channel) => { return (item.channel == channel.name) }).map((item) => {
                                                        return (
                                                            <div
                                                                className={"bg-" + item.name.replace('_', '').replace('_', '') + "-500 mr-2 h-10 w-10 rounded flex justify-center align-middle items-center"}>
                                                                <img className={item.style ? "" : "w-3"}
                                                                    style={item.style ? item.style : {}}
                                                                    src={require('../../assets/images/' + item.icon)} />
                                                            </div>
                                                        )
                                                    })
                                                }
                                                {
                                                    [
                                                        { name: 'facebook', icon: 'facebook_icon.svg', style: { width: '0.4rem' } },
                                                        { name: 'google', icon: 'google_icon.svg' },
                                                        { name: 'google_analytics', icon: 'google_analytics_icon.png' },
                                                        { name: 'google_analytics_4', icon: 'ga_icon.png' },
                                                        { name: 'google_shopping', icon: 'google_shopping_icon.png' },
                                                        { name: 'adform', icon: 'adform_icon.png' },
                                                        { name: 'linkedin', icon: 'linkedin_icon.svg' },
                                                        { name: 'twitter', icon: 'twitter_icon.svg' },
                                                        { name: 'bidtheatre', icon: 'bidtheatre_icon.png' },
                                                        { name: 'snapchat', icon: 'snapchat.svg' },
                                                        { name: 'bing', icon: 'bing-logo.png' },
                                                        { name: 'dv360', icon: 'dv360-logo.svg' },
                                                        { name: 'cm360', icon: 'cm360-logo.png' },
                                                        { name: 'marchex', icon: 'marchex_logo.jpg', style: { width: '80%' } }
                                                    ].filter((channel) => { return (item.channel == channel.name) }).length < 1 &&
                                                    item.custom &&
                                                    <div
                                                        style={{ backgroundColor: "#" + item.color }}
                                                        className={"mr-2 h-10 w-10 rounded flex justify-center align-middle items-center"}>
                                                        {
                                                            item.logo &&
                                                            <div
                                                                className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                style={{ backgroundImage: "url(" + item.logo + ")" }}></div>
                                                        }
                                                        {
                                                            !item.logo && item.channel && typeof item.channel == "string" &&
                                                            <div className="w-full h-full relative flex items-center justify-center text-semibold text-white text-sm uppercase">
                                                                {item.channel[0]}{item.channel[1]}
                                                            </div>
                                                        }
                                                    </div>
                                                }
                                                <div className="flex flex-1 flex-col capitalize">
                                                    <div className="font-medium text-sm">
                                                        {item.channel.replace("_", " ").replace("_", " ")}
                                                    </div>
                                                    {
                                                        this.state.tab.channel_breakdowns && this.state.tab.channel_breakdowns[item.channel] &&
                                                        <div className="text-xxs">
                                                            Selected <span className="font-bold">{Object.keys(this.state.tab.channel_breakdowns[item.channel]).map((breakdown) => {
                                                                return this.state.tab.channel_breakdowns[item.channel][breakdown].name
                                                            }).join(",")}</span>
                                                        </div>
                                                        ||
                                                        <div className="text-xxs">
                                                            {item.breakdowns.length} categories available
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                            <SlideDown closed={!this.state.open_channel[item.channel] && this.state.search_breakdowns == ""}>
                                                <div className="ml-12">
                                                    {
                                                        item.breakdowns.filter((item) => {
                                                            if (this.state.tab.celltype && this.state.tab.celltype.id === "performance") {
                                                                return false;
                                                            } else {
                                                                return true;
                                                            }
                                                        }).map((inner_item, index) => {
                                                            return (
                                                                <Fragment key={"innner" + index}>
                                                                    <div
                                                                        className="flex flex-1 flex-row justify-center mb-2">
                                                                        <div onClick={() => {
                                                                            this.state.open_channel[item.channel + index] = !this.state.open_channel[item.channel + index];
                                                                            this.setState({
                                                                                open_channel: this.state.open_channel,
                                                                                channel_breakdowns: this.state.channel_breakdowns
                                                                            })
                                                                        }}
                                                                            className="text-purple-500 bg-purple-100 cursor-pointer h-10 w-10 flex rounded font-bold mr-2 justify-center align-middle items-center">
                                                                            {
                                                                                !this.state.open_channel[item.channel + index] &&
                                                                                <FeatherIcon className={'stroke-current'}
                                                                                    size={16}
                                                                                    icon="plus" />
                                                                            }
                                                                            {
                                                                                this.state.open_channel[item.channel + index] &&
                                                                                <FeatherIcon className={'stroke-current'}
                                                                                    size={16}
                                                                                    icon="minus" />
                                                                            }
                                                                        </div>
                                                                        <div className="flex flex-1 flex-col capitalize">
                                                                            <div className="font-medium text-sm">
                                                                                {inner_item.title}
                                                                            </div>
                                                                            <div className="text-xxs">
                                                                                Breakdowns {inner_item.values.length} available
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <SlideDown closed={!this.state.open_channel[item.channel + index] && this.state.search_breakdowns == ""}>
                                                                        {
                                                                            false &&
                                                                            <div className="text-xs font-bold mb-1 pl-10">
                                                                                Search
                                                                            </div>
                                                                        }
                                                                        {
                                                                            false &&
                                                                            <div className="flex flex-1 flex-col justify-center mb-2 pl-10">
                                                                                <input
                                                                                    autocomplete="off"
                                                                                    onChange={(event) => {
                                                                                        inner_item.search = event.target.value;
                                                                                        this.setState({
                                                                                            channel_breakdowns: this.state.channel_breakdowns
                                                                                        });
                                                                                    }}
                                                                                    placeholder={"Search ..."}
                                                                                    value={inner_item.search ? inner_item.search : ""}
                                                                                    className="h-10 w-full rounded text-purple-500 border-2 flex pl-4 pr-4 box-border"
                                                                                />
                                                                            </div>
                                                                        }
                                                                        {
                                                                            this.functions.search(this.state.search_breakdowns, inner_item.values).filter((item) => {
                                                                                if (this.state.tab && this.state.tab.celltype && this.state.tab.celltype.value === "chart") {
                                                                                    return item.value !== "search_terms";
                                                                                } else {
                                                                                    return true
                                                                                }
                                                                            }).filter((item) => {
                                                                                if ((item.value === "search_terms" || item.value === "page") && this.functions.amountSelected() > 1) {
                                                                                    return false;
                                                                                } else {
                                                                                    return true;
                                                                                }
                                                                            }).map((dimension) => {
                                                                                return (
                                                                                    <Fragment>
                                                                                        <div className="ml-12 flex flex-1 flex-row mb-2">
                                                                                            <div onClick={() => {
                                                                                                if (!this.state.tab.channel_breakdowns) {
                                                                                                    this.state.tab.channel_breakdowns = {};
                                                                                                }
                                                                                                this.state.tab.channel_breakdowns[item.channel] = {};
                                                                                                this.state.tab.channel_breakdowns[item.channel][dimension.value] = dimension;
                                                                                                this.state.tab.channel_breakdowns[item.channel][dimension.value].filters = [];
                                                                                                //this.state.tab.channel_breakdowns[item.channel][dimension.value].filters = [{ name: "", id: Math.floor((Math.random() * 9999999999) + 1), items: [{ id: Math.floor((Math.random() * 9999999999) + 1), field: "" }] }];
                                                                                                if (this.props.onTab) {
                                                                                                    this.props.onTab(this.state.tab);
                                                                                                }
                                                                                            }}
                                                                                                className={((this.state.tab && this.state.tab.channel_breakdowns && this.state.tab.channel_breakdowns[item.channel] && this.state.tab.channel_breakdowns[item.channel][dimension.value]) ? "border-purple-500" : "") + " h-10 w-10 p-1 cursor-pointer rounded-full border-2 mr-2 flex justify-center align-middle items-center"}>
                                                                                                {
                                                                                                    this.state.tab && this.state.tab.channel_breakdowns && this.state.tab.channel_breakdowns[item.channel] && this.state.tab.channel_breakdowns[item.channel][dimension.value] &&
                                                                                                    <div
                                                                                                        className="w-full h-full rounded-full bg-purple-500" />
                                                                                                }
                                                                                            </div>
                                                                                            <div className="flex flex-1 flex-col capitalize">
                                                                                                <div className="font-medium text-sm">
                                                                                                    {dimension.name}
                                                                                                </div>
                                                                                                <div className="text-xxs">
                                                                                                    {dimension.parent}
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        {
                                                                                            false &&
                                                                                            dimension.value !== "overview" &&
                                                                                            this.state.tab.channel_breakdowns &&
                                                                                            this.state.tab.channel_breakdowns[item.channel] &&
                                                                                            this.state.tab.channel_breakdowns[item.channel][dimension.value] &&
                                                                                            <div className="text-xs font-bold mb-1 pl-20">
                                                                                                Filter (optional)
                                                                                            </div>
                                                                                        }
                                                                                        {
                                                                                            false &&
                                                                                            dimension.value !== "overview" &&
                                                                                            this.state.tab.channel_breakdowns &&
                                                                                            this.state.tab.channel_breakdowns[item.channel] &&
                                                                                            this.state.tab.channel_breakdowns[item.channel][dimension.value] &&
                                                                                            <div className="flex flex-1 flex-col justify-center mb-2 pl-20">
                                                                                                <input
                                                                                                    autocomplete="off"
                                                                                                    onChange={(event) => {
                                                                                                        this.state.tab.channel_breakdowns[item.channel][dimension.value].filter = event.target.value;
                                                                                                        if (this.props.onTab) {
                                                                                                            this.props.onTab(this.state.tab);
                                                                                                        }
                                                                                                    }}
                                                                                                    placeholder={"Filer value"}
                                                                                                    value={this.state.tab.channel_breakdowns[item.channel][dimension.value].filter}
                                                                                                    className="h-10 w-full rounded text-purple-500 border-2 flex pl-4 pr-4 box-border"
                                                                                                />
                                                                                            </div>
                                                                                        }
                                                                                    </Fragment>

                                                                                )
                                                                            })
                                                                        }
                                                                    </SlideDown>
                                                                </Fragment>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </SlideDown>
                                            {
                                                (this.state.channel_breakdowns.filter((item) => {
                                                    let channels = this.functions.selectedDataSourcesChannells();
                                                    return channels[item.channel];
                                                }).length - 1) !== i &&
                                                <div className="border-t flex-1 flex mb-2" />
                                            }
                                        </Fragment>

                                    )
                                })
                            }
                        </div>
                        {
                            this.state.tab.channel_breakdowns && Object.keys(this.state.tab.channel_breakdowns).length > 0 &&
                            <div className="flex justify-center items-center mt-6">
                                <button onClick={() => {
                                    this.props.onNext();
                                }} class="bg-purple-500 shadow inline-flex relative justify-center rounded-md border border-transparent w-48 py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2" tabindex="0">Next</button>
                            </div>
                        }
                    </SlideDown >
                </div >
            </>
        )
    }
}

export default SelectCellBreakdown