import React from 'react';
//import './index.css';
import { apiRegister } from "../services/apiRegister";
import { tokenRegister } from "../services/tokenRegister";
import { UploadIcon, LinkIcon, ExclamationCircleIcon, XIcon } from '@heroicons/react/outline';
import SlideoutTailwind from "./slideoutTailwind";
import SnapshotModal from "./advancedReportCellCreative/snapshotModal";

export default class AdvancedReportCellCreative extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            data: null,
            header: '',
            htmlContent: '',
            loading: false,
            cell: {},
            show: false,
            show_component: false,
        };
    }

    async componentDidMount() {
        await this.promisedSetState({
            data: this.props.data,
            format: this.props.format,
            header: this.props.header,
            static: this.props.static,
            cell: this.props.source ? this.props.source : {},
            transparent_cells: this.props.transparent_cells,
            report_background_color: this.props.report_background_color,
            show_component: this.props.show_component
        });

        setTimeout(() => {
            try {
                if (this.refs.video) {
                    this.refs.video.setAttribute("muted", true);
                }
            } catch (error) {
                console.log(error)
            }
        }, 200);

        //ONLY SHOW CONTENT IN VIEW
        this.functions.showInView();

    }


    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            data: nextProps.data,
            format: nextProps.format,
            header: nextProps.header,
            static: nextProps.static,
            cell: nextProps.source ? nextProps.source : {},
            transparent_cells: nextProps.transparent_cells,
            report_background_color: nextProps.report_background_color,
            show_component: nextProps.show_component
        })
    }

    functions = {
        onSelectFile: (e) => {
            let files = e.target.files;
            if (!files) {
                files = {};
            }
            for (let i in files) {
                if (files[i].type === 'video/mp4') {
                    this.functions.uploadVideo(files[i]);
                } else if (files[i].type === 'image/jpg' || files[i].type === 'image/jpeg' || files[i].type === 'image/png') {
                    this.functions.uploadImage(files[i]);
                }
                const randomString = Math.random().toString(36);
                this.setState({
                    theInputKeyOne: randomString + 1
                });
            }
        },
        uploadImage: (image) => {
            this.setState({
                loading: true
            }, () => {
                const formData = new FormData();
                formData.append('file', image);
                this.calls.image(formData).then((response) => {
                    this.setState({
                        loading: false,
                        data: response.data.url,
                        format: "image"
                    }, () => {
                        if (this.props.onImage) {
                            this.props.onImage({ data: this.state.data, settings: { width: response.data.width, height: response.data.height } });
                        }
                    })
                }, (error) => {
                    this.setState({
                        loading: false
                    })
                });
            })
        },
        uploadVideo: (video) => {
            this.setState({
                loading: true
            }, () => {
                const formData = new FormData();
                formData.append('file', video);
                this.calls.video(formData).then((response) => {
                    this.setState({
                        loading: false,
                        data: response.data.url,
                        format: "video"
                    }, () => {
                        if (this.props.onVideo) {
                            this.props.onVideo({ data: this.state.data, settings: { width: response.data.width, height: response.data.height } });
                        }
                    })
                }, (error) => {
                    this.setState({
                        loading: false
                    })
                });
            })
        },
        showInView: async () => {
            let self = this;
            if (this.state.show_component) {
                self.setState({ show: true });
            } else {
                await self.functions.sleep(2000);
                try {
                    let box = null;
                    if (window.innerWidth <= 767) {
                        box = document.getElementById(self.state.cell.i + "_preview_mobile");
                    } else {
                        box = document.getElementById(self.state.cell.i + "_preview");
                    }
                    const rect = box.getBoundingClientRect();
                    const isInView = rect.top < (window.innerHeight || document.documentElement.clientHeight) &&
                        rect.left < (window.innerWidth || document.documentElement.clientWidth) &&
                        rect.bottom > 0 &&
                        rect.right > 0;
                    if (isInView) {
                        self.setState({ show: isInView });
                    }
                } catch (error) {
                    self.setState({ show: true });
                }
                document.addEventListener('scroll', async function () {
                    try {
                        let box = null;
                        if (window.innerWidth <= 767) {
                            box = document.getElementById(self.state.cell.i + "_preview_mobile");
                        } else {
                            box = document.getElementById(self.state.cell.i + "_preview");
                        }
                        const rect = box.getBoundingClientRect();
                        const isInView = rect.top < (window.innerHeight || document.documentElement.clientHeight) &&
                            rect.left < (window.innerWidth || document.documentElement.clientWidth) &&
                            rect.bottom > 0 &&
                            rect.right > 0;
                        self.setState({ show: isInView });
                    } catch (error) {
                        self.setState({ show: true });
                    }
                }, {
                    capture: true,
                });
            }
        },
        sleep: (ms) => {
            return new Promise(resolve => setTimeout(resolve, ms));
        },
    };

    calls = {
        image: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data, true);
            let url = apiRegister.url.api + "/image?report=true";
            return apiRegister.call(options, url);
        },
        video: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data, true);
            let url = apiRegister.url.api + "/video?report=true";
            return apiRegister.call(options, url);
        },
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {
        return (
            this.state.show ? <div
                key={this.state.theInputKeyOne || ''}
                className="chart-template-img text-left text-sm w-full h-full flex flex-col justify-center"
                style={{
                    ...(this.state.cell && this.state.cell.settings && this.state.cell.settings.background_color && !this.state.cell.settings.transparent && { backgroundColor: "#" + this.state.cell.settings.background_color }),
                    ...(this.state.cell && this.state.cell.settings && !this.state.cell.settings.background_color && !this.state.cell.settings.transparent && { backgroundColor: "#FFFFFF" }),
                }}
            >
                {/*this.state.cell && this.state.cell.settings && this.state.cell.settings.background_color ? { backgroundColor: "#" + this.state.cell.settings.background_color } : { backgroundColor: "#FFFFFF" } */}
                {
                    !this.state.static &&
                    <div>
                        <button
                            style={{ top: "14px", right: "13px" }}
                            className="transition-all hover:bg-purple-200 duration-200 cursor-pointer absolute z-60 leading-none flex items-center justify-center mr-1 w-10 h-10 bg-purple-100 text-purple-500 rounded">
                            <UploadIcon className={"w-5 cursor-pointer"} />
                            <input
                                onChange={(e) => this.functions.onSelectFile(e, true)}
                                type="file"
                                multiple
                                accept={"image/png, image/jpg, image/jpeg, video/mp4"}
                                className="absolute top-0 left-0 w-full h-full cursor-pointer opacity-0"
                            />
                        </button>
                        <button
                            onClick={() => {
                                this.setState({
                                    urlPopupOpen: true
                                });
                            }}
                            style={{ top: "14px", right: "58px" }}
                            className="transition-all hover:bg-purple-200 duration-200 cursor-pointer absolute z-60 leading-none flex items-center justify-center mr-1 w-10 h-10 bg-purple-100 text-purple-500 rounded">
                            <LinkIcon className={"w-5 cursor-pointer"} />
                        </button>
                    </div>
                }
                {
                    this.state.data && !this.state.loading && this.state.format === "image" &&
                    <img
                        className={(this.state.cell && this.state.cell.settings && this.state.cell.settings.media_style && this.state.cell.settings.media_style.value ? ('object-' + this.state.cell.settings.media_style.value) : "object-contain") + " w-full h-full"}
                        src={this.state.data}
                        alt={this.state.header}
                    />
                }
                {
                    this.state.data && !this.state.loading && this.state.format === "video" &&
                    <video className={(this.state.cell && this.state.cell.settings && this.state.cell.settings.media_style && this.state.cell.settings.media_style.value ? ('object-' + this.state.cell.settings.media_style.value) : "object-contain") + " w-full h-full"} controls={true} loop={true} autoPlay={false} muted={true} ref="video">
                        <source alt={this.state.header} src={this.state.data} type="video/mp4" />
                    </video>
                }
                {
                    this.state.loading &&
                    <div className="w-full h-full relative flex flex-col justify-center items-center">
                        <div className="bg-transparent">
                            <div style={{ borderTopColor: "transparent" }}
                                className="w-6 h-6 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                        </div>
                    </div>
                }
                {
                    !this.state.data && !this.state.loading && !this.state.static &&
                    <div
                        className="w-full h-full flex flex-col cursor-pointer justify-center items-center duration-200 text-black relative">
                        <div>
                            <span
                                className="text-purple-500 cursor-pointer hover:text-purple-700 duration-200 text-center text-sm font-medium">Upload Image/Video or paste an URL</span>
                            <input
                                onChange={(e) => this.functions.onSelectFile(e, true)}
                                type="file"
                                multiple
                                style={{ height: "50%" }}
                                accept={"image/png, image/jpg, image/jpeg, video/mp4"}
                                className="absolute top-0 left-0 w-full cursor-pointer opacity-0"
                            />
                        </div>
                        {
                            false &&
                            <div>
                                <div
                                    className="text-purple-500 text-center cursor-pointer hover:text-purple-700 duration-200 text-sm font-medium">Or</div>
                                <div
                                    className="text-purple-500 text-center cursor-pointer hover:text-purple-700 duration-200 text-sm font-medium">Paste URL</div>
                                <div
                                    onClick={() => {
                                        this.setState({
                                            urlPopupOpen: true
                                        });
                                    }}
                                    style={{ height: "50%" }}
                                    className="absolute bottom-0 left-0 w-full cursor-pointer opacity-0" />
                            </div>
                        }
                    </div>
                }
                <SlideoutTailwind
                    title={"Paste site URL"}
                    submitButtonText={"Make snapshot"}
                    large={false}
                    medium={true}
                    secondaryButton={false}
                    open={this.state.urlPopupOpen ? true : false}
                    loader={this.state.urlPopupLoader}
                    onCancel={async () => {
                        await this.promisedSetState({
                            urlPopupOpen: false,
                        });
                    }}

                    onSubmit={async () => {
                        await this.promisedSetState({
                            urlPopupLoader: true,
                        });

                        await this.refs.SnapshotModal.functions.makeSiteSnapshot();
                    }}
                >
                    <SnapshotModal
                        ref={"SnapshotModal"}
                        onError={async () => {
                            await this.promisedSetState({
                                urlPopupLoader: false,
                            })
                        }}
                        onCreated={async (response) => {
                            this.setState({
                                loading: false,
                                data: response.data.url,
                                format: "image",
                                urlPopupOpen: false,
                                urlPopupLoader: false,
                            }, () => {
                                if (this.props.onImage) {
                                    this.props.onImage({
                                        data: this.state.data,
                                        settings: { width: response.data.width, height: response.data.height }
                                    });
                                }
                            })
                        }}
                    />
                </SlideoutTailwind>
            </div> : <div
                style={this.state.cell && this.state.cell.settings && this.state.cell.settings.background_color ? { backgroundColor: "#" + this.state.cell.settings.background_color } : { backgroundColor: "#FFFFFF" }}
                className="w-full h-full relative flex flex-col justify-center items-center">
                <div className="bg-transparent">
                    <div style={{ borderTopColor: "transparent" }}
                        className="w-6 h-6 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                </div>
            </div>
        );
    }

}
