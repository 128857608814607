import React, { Component, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react'
import { BeakerIcon, CheckIcon, ExclamationIcon, MinusIcon, PlusIcon, ReplyIcon, XCircleIcon } from '@heroicons/react/outline';
import cn from "classnames";
import InputTailwind from './inputTailwind';
import { tokenRegister } from '../services/tokenRegister';
import { apiRegister } from '../services/apiRegister';
import DropdownTailwind from './dropdownTailwind';
import InputDatepickerTailwind from './inputDatepickerTailwind';
import moment from 'moment';
import TextAreaTailwind from './textAreaTailwind';
import SwitchTailwind from './switchTailwind';

class EditLiveGoogleAdgroup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: false,
            wizard: false,
            open: false,
            adgroup: {},
            updated: {},
            client: {},
            loader: false,
            tab: { id: 1, name: "Basic", value: "basic" },
            tabs: [
                { id: 1, name: "Basic", value: "basic" },
                { id: 2, name: "Targeting", value: "targeting" },
            ],
            status_values: [
                { id: 1, name: 'Enabled', value: "ENABLED" },
                { id: 2, name: 'Paused', value: "PAUSED" },
                { id: 3, name: 'Deleted', value: "REMOVED" }
            ],
            targeting_types: [
                { id: 0, name: "Select Type", value: null },
                { id: 1, name: "Affinity", value: "userInterest", type: "AFFINITY" },
                { id: 3, name: "In-market", value: "userInterest", type: "IN_MARKET" }, //yes, it is userInterests segment
            ],
            selected_type: { id: 0, name: "Select Type", value: null },
            targeting: {
                userInterest: [],
            },
            parental_status: [
                { id: 1, name: "Not a Parent", value: "NOT_A_PARENT", included: true },
                { id: 2, name: "Parent", value: "PARENT", included: true },
                { id: 3, name: "Undetermined", value: "UNDETERMINED", included: true }
            ]
        }
    };

    async componentDidMount() {
        await this.promisedSetState({
            adgroup: this.props.adgroup,
            client: this.props.client,
            wizard: this.props.wizard
        })
        if (this.props.adgroup.parental_status) {
            await this.promisedSetState({
                parental_status: JSON.parse(JSON.stringify(this.props.adgroup.parental_status))
            });
        }
    }

    componentWillReceiveProps(nextProps) {

    }

    functions = {
        update: async (data) => {
            if (!this.state.wizard) {
                this.props.onSuccess(false);
                this.props.onLoader(true);
                this.promisedSetState({
                    error: false
                });
                if (!data) {
                    if (Object.keys(this.state.updated).length > 0) {
                        let body = {
                            ...(this.state.updated.name) && { name: this.state.updated.name },
                            ...(this.state.updated.user_interests) && { user_interests: { updated: this.state.updated.user_interests, old: this.state.adgroup.user_interests } },
                            ...(this.state.updated.parental_status) && { parental_status: { updated: this.state.updated.parental_status, old: this.state.adgroup.parental_status } },
                        };
                        try {
                            let response = await this.calls.updateLiveAdgroup(body);

                            if (response.data.error) {
                                this.promisedSetState({
                                    error: response.data.error
                                });
                            }
                        } catch (error) {
                            //this.props.onError(error.error ? error.error : "Something went wrong");
                        }
                    }
                } else {
                    this.promisedSetState({
                        loading_status: true
                    });
                    try {
                        if (data.value === "PAUSED") {
                            this.props.onPauseAdgroup(true);
                        } else {
                            this.props.onActivateAdgroup(true);
                        }
                        this.state.updated.status = data;
                        this.promisedSetState({
                            loading_status: false,
                            updated: this.state.updated
                        });
                    } catch (error) {
                        //this.props.onError(error.error ? error.error : "Something went wrong");
                    }
                }
                if (!this.state.error) {
                    if (!data) this.props.onSuccess(true);
                }
                this.props.onLoader(false);
            } else {
                this.props.onUpdate(this.state.updated);
            }
        },
        getUserInterests: async (data = null) => {
            await this.promisedSetState({ loading_targeting: true });
            try {
                let res = await this.calls.getUserInterests(data);
                if (res.data) {
                    this.state.targeting.userInterest = res.data.flatMap(item => ({
                        name: "(" + item.userInterest.taxonomyType.charAt(0).toUpperCase() + item.userInterest.taxonomyType.toLowerCase().replace("_", " ").slice(1) + ") " + item.userInterest.name,
                        resourceName: item.userInterest.resourceName,
                        type: 'userInterest'
                    })
                    );
                    await this.promisedSetState({ targeting: this.state.targeting });
                }
            } catch (error) {

            }
            /*this.calls.getUserInterests(data).then(async res => {
                this.state.targeting.userInterest = res.data.flatMap(item => ({
                    name: item.userInterest.name,
                    resourceName: item.userInterest.resourceName,
                    type: 'userInterest'
                })
                );
                await this.promisedSetState({ targeting: this.state.targeting });
            });*/
            await this.promisedSetState({ loading_targeting: false });
        }
    };

    calls = {
        updateLiveAdgroup: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/adcredo/updateLiveAdgroup?client=" + this.state.client.id + "&adgroup=" + this.state.adgroup.id + "&channel=google";
            return apiRegister.call(options, url);
        },
        getUserInterests: (query) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET');
            let url = apiRegister.url.api + "/v3/google/listUserInterests?client=" + this.state.client.id + "&type=" + this.state.selected_type.type + "&query=" + (query || '');
            return apiRegister.call(options, url);
        }
    };

    renders = {
        name: () => {
            try {
                if ('name' in this.state.updated) {
                    return this.state.updated.name;
                } else if (this.state.adgroup.name) {
                    return this.state.adgroup.name;
                } else {
                    return "";
                }
            } catch (error) {
                return "";
            }
        },
        gender: () => {
            try {
                if (this.state.adgroup.gender) {
                    return this.state.adgroup.gender;
                } else {
                    return { id: 0, name: "Select ...", value: "Unkown" };
                }
            } catch (error) {
                return { id: 0, name: "Select ...", value: "Unkown" };
            }
        },
        ages: () => {
            try {
                if (this.state.adgroup.ages) {
                    return this.state.adgroup.ages;
                } else {
                    return false;
                }
            } catch (error) {
                return false;
            }
        },
        type: () => {
            try {
                if (this.state.adgroup.advertising_type) {
                    return this.state.adgroup.advertising_type;
                } else {
                    return "";
                }
            } catch (error) {
                return "";
            }
        },
        status: () => {
            try {
                if ('status' in this.state.updated) {
                    return this.state.updated.status;
                } else if (this.state.status_values.filter((item) => { return item.value.toLowerCase() === this.state.adgroup.status.toLowerCase() }).length > 0) {
                    return this.state.status_values.filter((item) => { return item.value.toLowerCase() === this.state.adgroup.status.toLowerCase() })[0];
                } else {
                    return { id: 0, name: 'Click here ...', value: "PAUSED" }
                }
            } catch (error) {
                return { id: 0, name: 'Click here ...', value: "PAUSED" }
            }
        },
        userInterest: () => {
            if ("user_interests" in this.state.updated) {
                return this.state.updated.user_interests
            } else {
                return [...this.state.adgroup.user_interests]
            }
        }
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {
        return (
            <>
                <div className="grid grid-cols-6 gap-4">

                    <div className="col-span-6">
                        <div className="block overflow-scroll w-ful">
                            <nav className="flex space-x-4" aria-label="Tabs">
                                {this.state.tabs.filter((item) => {
                                    if (this.renders.type() === "DISPLAY_STANDARD") {
                                        return true;
                                    } else {
                                        return item.id !== 2;
                                    }
                                }).map((tab) => (
                                    <div
                                        onClick={() => (
                                            this.setState({
                                                tab: tab
                                            })
                                        )}
                                        key={tab.name}
                                        style={{ whiteSpace: "nowrap" }}
                                        className={cn(
                                            tab.value == this.state.tab.value ? 'bg-purple-100 text-purple-500' : 'text-gray-500 hover:text-purple-500',
                                            'px-3 py-2 font-medium text-sm rounded-md whitespace-nowrap cursor-pointer'
                                        )}
                                        aria-current={tab.value == this.state.tab.value ? 'page' : undefined}
                                    >
                                        {tab.name}
                                    </div>
                                ))}
                            </nav>
                        </div>
                    </div>

                    {/*BASIC*/}
                    {
                        this.state.tab.id == 1 &&
                        <>

                            <div className="col-span-6">
                                <InputTailwind
                                    label={"Adgroup name"}
                                    value={this.renders.name()}
                                    onChange={(value) => {
                                        this.state.updated.name = value;
                                        this.setState({
                                            updated: this.state.updated
                                        })
                                    }}
                                />
                            </div>

                            <div className="col-span-3">
                                <div className='-mb-4 text-xs font-medium text-gray-700 flex flex-1'>Status</div>
                                <div className='flex items-center justify-center my-5 border-gray-300 w-full bg-custom-input border-1.5 rounded-md' style={{ paddingTop: "0.5rem", paddingBottom: "0.6rem" }}>
                                    <div className="flex flex-1 ml-5">
                                        {this.renders.status().name}
                                    </div>
                                    <div className="relative overflow-hidden mr-5">
                                        <SwitchTailwind
                                            value={this.renders.status().value === "ENABLED" ? true : false}
                                            onSwitch={async () => {
                                                if (this.renders.status().value === "ENABLED") {
                                                    this.functions.update({ id: 2, name: 'Paused', value: "PAUSED" });
                                                } else if (this.renders.status().value === "PAUSED") {
                                                    this.functions.update({ id: 1, name: 'Active', value: "ENABLED" });
                                                }

                                            }}
                                        />
                                        {
                                            this.state.loading_status &&
                                            <div className="w-full h-full bg-opacity-50 absolute bg-white top-0 bottom-0 rounded-md left-0 right-0 flex justify-center items-center">
                                                <div style={{ borderTopColor: "transparent" }}
                                                    class="w-4 h-4 border-2 border-purple-500 absolute border-solid rounded-full animate-spin"></div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </>
                    }

                    {/*Targeting*/}
                    {
                        this.state.tab.id == 2 &&
                        <>
                            <div className="col-span-6">
                                <DropdownTailwind
                                    label={"Gender"}
                                    locked={true}
                                    selected={this.renders.gender()}
                                    options={[
                                        { id: 1, name: "All genders", value: "ALL" },
                                        { id: 2, name: "Male only", value: "MALE" },
                                        { id: 3, name: "Female only", value: "FEMALE" }
                                    ]}
                                    onChange={async (value) => {
                                        /*
                                        this.state.updated.gender = value;
                                        this.setState({
                                            updated: this.state.updated
                                        })*/
                                    }}
                                />
                            </div>
                            <div className="col-span-6">
                                <DropdownTailwind
                                    label={"Age"}
                                    locked={true}
                                    selected={{ id: 0, name: "Select ages" }}
                                    options={[
                                        { id: 1, name: "18 - 24", value: "AGE_RANGE_18_24", included: true },
                                        { id: 2, name: "25 - 34", value: "AGE_RANGE_25_34", included: true },
                                        { id: 3, name: "35 - 44", value: "AGE_RANGE_35_44", included: true },
                                        { id: 4, name: "45 - 54", value: "AGE_RANGE_45_54", included: true },
                                        { id: 5, name: "55 - 64", value: "AGE_RANGE_55_64", included: true },
                                        { id: 6, name: "65+", value: "AGE_RANGE_65_UP", included: true },
                                        { id: 7, name: "Undetermined", value: "AGE_RANGE_UNDETERMINED", included: true }
                                    ].filter((item) => {
                                        return (!Array.isArray(this.renders.ages()) || (Array.isArray(this.renders.ages()) && this.renders.ages().filter((inner_item) => { return inner_item.id === item.id }).length < 1))
                                    })}
                                    onChange={async (value) => {
                                        /*
                                        if (!Array.isArray(this.state.updated.ages)) {
                                            this.state.updated.ages = [];
                                        }
                                        this.state.updated.ages.push(value);
                                        this.setState({
                                            updated: this.state.updated
                                        })
                                        */
                                    }}
                                    onSearch={async (value) => {

                                    }}
                                />
                            </div>
                            {
                                Array.isArray(this.renders.ages()) &&
                                this.renders.ages().length > 0 &&
                                <div className="col-span-6 flex flex-col">
                                    {
                                        this.renders.ages().map((item, index) => {
                                            return (
                                                <Fragment>

                                                    <div className={(item.included ? " bg-indigo-100 text-indigo-700" : " bg-red-100 text-red-700") + " flex flex-1 rounded-full mb-2 items-center py-1.5 px-3 text-sm font-medium "}>
                                                        <div className="flex flex-1">{item.name}</div>
                                                    </div>
                                                </Fragment>
                                            )
                                        })
                                    }
                                </div>
                            }

                            {
                                this.renders.type() === "DISPLAY_STANDARD" &&
                                <>
                                    <div className="col-span-6 grid grid-cols-12">
                                        <div class="col-span-12 text-xs font-medium text-gray-700 mb-1 w-full flex cursor-pointer">Parental Status</div>
                                        {this.state.parental_status.map((content, index) => {
                                            return (
                                                <div
                                                    key={index}
                                                    onClick={async () => {
                                                        content.included = !content.included;
                                                        await this.promisedSetState({
                                                            parental_status: this.state.parental_status
                                                        });
                                                        this.state.updated.parental_status = JSON.parse(JSON.stringify(this.state.parental_status));
                                                        await this.promisedSetState({
                                                            updated: this.state.updated
                                                        });

                                                    }}
                                                    className={"col-span-12 md:col-span-4 lg:col-span-2 " + (content.included ? "bg-indigo-100 text-indigo-700" : "bg-gray-100 text-gray-500 hover:text-indigo-500") + " cursor-pointer inline-flex flex-row rounded-full mb-2 mr-2 items-center py-1.5 px-3 text-sm font-medium"}>
                                                    <div className="">{content.name}</div>
                                                    <div className="ml-auto">
                                                        <div className={(content.included ? "border-indigo-500 bg-indigo-500" : "border-gray-500 ") + " h-5 w-5 flex justify-center items-center rounded-full border-2"}>
                                                            <CheckIcon className={(content.included ? "" : "opacity-0") + " h-5 w-5 text-white"} />
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                    <div className="col-span-6">
                                        <DropdownTailwind
                                            label={"Type"}
                                            searchInput={true}
                                            selected={this.state.selected_type}
                                            options={this.state.targeting_types}
                                            onChange={async (value) => {
                                                this.state.targeting.userInterest = [];
                                                await this.promisedSetState({
                                                    selected_type: value,
                                                    targeting: this.state.targeting
                                                })
                                                const types = { 'userInterest': 'getUserInterests' }
                                                if (['userInterest'].includes(value.value) && !this.state.targeting[value.value].length) {
                                                    this.functions[types[value.value]]();
                                                }
                                            }}
                                        />
                                    </div>
                                    {
                                        !!this.state.selected_type.id &&
                                        <div className="col-span-6">
                                            <div className='flex'>
                                                <div className='mx-4 self-center'>
                                                    <ReplyIcon className='h-6 w-6' style={{ transform: 'rotate(180deg)' }} />
                                                </div>
                                                <div className='flex-col flex-1'>
                                                    <DropdownTailwind
                                                        ref="searchDropdown"
                                                        label={"Audience"}
                                                        searchInput={true}
                                                        loader={this.state.loading_targeting}
                                                        selected={{ name: "Search ..." }}
                                                        options={!!this.state.selected_type.id ? this.state.targeting[this.state.selected_type.value] : []}
                                                        loadingSearch={this.state.loading_targeting || false}
                                                        onChange={async (value) => {
                                                            const type = this.state.selected_type.value;
                                                            value.included = true;
                                                            if (!this.state.updated.user_interests) {
                                                                this.state.updated.user_interests = JSON.parse(JSON.stringify(this.state.adgroup.user_interests))
                                                            }

                                                            if (!this.state.updated.user_interests.some(item => item.resourceName === value.resourceName)) {
                                                                this.state.updated.user_interests.push(value)
                                                            }

                                                            await this.promisedSetState({ updated: this.state.updated })
                                                        }}
                                                        onSearch={async (value) => {
                                                            if (this.state.selected_type.value === 'userInterest') {
                                                                this.functions.getUserInterests(value)
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    <div className="col-span-6 grid grid-cols-12">
                                        {this.renders.userInterest().map((content, index) => {
                                            return (
                                                <div key={index}

                                                    className={"col-span-12 md:col-span-6 lg:col-span-4 bg-indigo-100 text-indigo-700 cursor-pointer inline-flex flex-row rounded-full mb-2 mr-2 items-center py-1.5 px-3 text-sm font-medium"}>
                                                    <div className="">
                                                        <span className='w-full'>{content.name}</span>
                                                    </div>
                                                    <div className="ml-auto">
                                                        <div className={"bg-indigo-500 h-5 w-5 flex justify-center items-center rounded-full border-2"}>
                                                            <XCircleIcon
                                                                onClick={async () => {
                                                                    if (!this.state.updated.user_interests) {
                                                                        this.state.updated.user_interests = JSON.parse(JSON.stringify(this.renders.userInterest()))
                                                                    }
                                                                    const find = this.state.updated.user_interests.findIndex(item => item.resourceName === content.resourceName);
                                                                    if (find >= 0) {
                                                                        this.state.updated.user_interests = this.state.updated.user_interests.filter(item => item.resourceName !== content.resourceName);
                                                                    }

                                                                    await this.promisedSetState({
                                                                        updated: this.state.updated
                                                                    });
                                                                    console.log(this.state.updated.user_interests, "updated====")
                                                                }}
                                                                className={"h-5 w-5 text-white"}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </>
                            }

                        </>
                    }


                    {
                        this.state.error &&
                        <div className='text-red-500 text-sm p-4 col-span-6 bg-red-100 rounded-md'>{this.state.error}</div>
                    }

                </div>
            </>
        )
    }
}

export default EditLiveGoogleAdgroup;
