import React, { Component, Fragment } from 'react';
import Pusher from 'pusher-js';
import { SparklesIcon, TrashIcon, PhotographIcon, FilmIcon, ChevronUpIcon, SwitchVerticalIcon, ExternalLinkIcon } from '@heroicons/react/outline'
import InputTailwind from './inputTailwind';
import { tokenRegister } from '../services/tokenRegister';
import { apiRegister } from '../services/apiRegister';
import DropdownTailwind from './dropdownTailwind';
import TextAreaTailwind from './textAreaTailwind';
import FacebookCTA from '../assets/json/facebook_cta.json';
import { adFacebook } from '../validators/adFacebook';
import { SortableContainer, SortableElement, arrayMove } from 'react-sortable-hoc';
import WarningModalTailwind from './warningModalTailwind';
import { update } from 'immutable';

var pusherService = null;

class EditLiveFacebookAd extends Component {

	constructor(props) {
		super(props);
		this.state = {
			open: false,
			instagram_post: false,
			ad: {},
			updated: {},
			client: {},
			apps: [],
			pixels: [],
			loader: false,
			wizard: false,
			channel: { id: 1, name: "facebook feed" },
			pages: [],
			selectedAsset: 0,
			instagram_accounts: [],
			call_to_actions: FacebookCTA.filter((item) => { return item.objective.length > 0 }),
			wizardId: 0,
			collection_catalogs: [],
			product_sets: [],
			loading_fb_catalogs: false,
			load_fb_product_sets: false,
			lead_forms: [],
			instant_type: { id: 0, name: "Click here ..." },
			instant_types: [
				{ id: 1932289657009030, name: "Store Front", value: "store_front" },
				{ id: 1369752616394017, name: "Look Book", value: "look_book" },
				{ id: 133471657203838, name: "Customer Acquisition", value: "customer_acquisition" }
			],
			carousel_menu: "Main ad",
			preview_data: false,
			open_slide: 0,
			instantExperience: { id: 0, name: "Click here ..." },
			instant_options: [],
			fb_lead_form: false,
			selected_preview: { id: 1, value: "feed", name: "Feed" },
			assetItems: [],
		}
	};

	async componentDidMount() {

		pusherService = new Pusher('a07698cf59a1788c44d4', {
			cluster: 'eu'
		});

		await this.promisedSetState({
			ad: this.props.ad,
			client: this.props.client,
			//wizard: this.props.wizard,
			//assetItems: this.props.assetItems,
			//wizardId: this.props.wizardId,
			//preview_data: this.props.preview_data
		})

		console.log("AD", this.state.ad)
		if (this.state.ad.unsupported) {
			this.props.onDisable(true);
		}

		if (this.state.ad.creative && this.state.ad.creative.ad_type) {
			if (this.state.ad.creative.ad_type === "carousel_feed") {
				await this.promisedSetState({
					carousel: true
				});
			} else if (this.state.ad.creative.ad_type === "merge_ad") {
				await this.promisedSetState({
					merge: true
				});
			}
		}

		/*
				this.functions.instagram_post();
				this.functions.pixels();
				this.functions.listApps();
				this.functions.listPages();
				this.functions.getFacebookCatalogs();
				if (this.state.updated.campaign_objective && this.state.updated.campaign_objective.id) {
					await this.promisedSetState({
						call_to_actions: this.state.call_to_actions.filter((item) => { return item.objective.includes(this.state.updated.campaign_objective.id) })
					});
				}
				if (this.state.updated.collection_catalog && this.state.updated.collection_catalog.id !== 0) {
					this.functions.getFacebookProductSets();
				}
				if (this.state.wizard && this.state.updated.page && this.state.updated.page.id !== 0) {
					this.functions.listInstagramAccounts(this.state.updated.page.id);
				}
		
				if (!this.state.wizard && this.renders.type(this.state.ad) == "video") {
					try {
						this.functions.getVideo(this.state.ad.creative.object_story_spec.video_data.video_id);
					} catch (error) { }
				}
				if (this.state.updated.page && this.state.updated.page.id !== 0 && this.state.updated.file && this.state.updated.file.selected_lead && this.state.updated.file.selected_lead.id !== 0) {
					this.state.updated.selected_lead = this.state.updated.file.selected_lead;
					await this.promisedSetState({
						fb_lead_form: true, updated: this.state.updated
					})
					this.functions.listLeadForms();
				}
				if (this.state.updated.page && this.state.updated.page.id !== 0 && this.state.updated.selected_lead && this.state.updated.selected_lead.id !== 0) {
					this.functions.listLeadForms();
				}
				if (this.state.updated.instantExperience && this.state.updated.instantExperience.id !== 0) {
					this.functions.getInstant();
				}
				this.functions.init();
		
				if (this.state.updated) {
					await this.promisedSetState({
						carousel: this.state.updated && this.state.updated.type === "carousel" ? this.state.updated : null,
						merge: this.state.updated && this.state.updated.type === "merge" ? this.state.updated : null,
					});
				}
		
				if (this.state.updated && Array.isArray(this.state.updated.OpenAiResult) && this.state.updated.OpenAiResult.length > 0) {
					this.props.onUpdatedChat(this.state.updated.OpenAiResult);
				}
		*/
		this.functions.updatePreview();
		//this.functions.listInstantExpriences()
	}

	async componentWillReceiveProps(nextProps) {

		await this.promisedSetState({
			//ad: nextProps.ad,
			//client: nextProps.client,
			//wizard: nextProps.wizard,
			//assetItems: nextProps.assetItems,
			//wizardId: nextProps.wizardId,
			//preview_data: nextProps.preview_data
		})
		if (this.state.ad.creative && this.state.ad.creative.ad_type) {
			if (this.state.ad.creative.ad_type === "carousel_feed") {
				await this.promisedSetState({
					carousel: true
				});
			} else if (this.state.ad.creative.ad_type === "merge_ad") {
				await this.promisedSetState({
					merge: true
				});
			}
		}
		console.log("AD", this.state.ad)
		/*
				if (nextProps.updated) {
					this.setState({
						updated: nextProps.updated
					});
				}
				this.renderSortableList(this.state.updated.slides ? this.state.updated.slides : []);
				*/
	}

	functions = {
		init: () => {
			window.fbAsyncInit = function () {
				window.FB.init({
					appId: '3033879003330818',
					version: 'v19.0',
					status: true,
					autoLogAppEvents: true,
					xfbml: true,
				});
				window.FB.AppEvents.logPageView();
			}.bind(this);
			(function (d, s, id) {
				var js, fjs = d.getElementsByTagName(s)[0];
				if (d.getElementById(id)) {
					return;
				}
				js = d.createElement(s);
				js.id = id;
				js.src = "https://connect.facebook.net/en_US/sdk.js";
				fjs.parentNode.insertBefore(js, fjs);
			}(document, 'script', 'facebook-jssdk'));
		},
		/*
		getFacebookCatalogs: async () => {
			await this.promisedSetState({ loading_fb_catalogs: true });
			try {
				let response = await this.calls.facebookCatalogs();
				this.state.collection_catalogs = response.data;
				await this.promisedSetState({ collection_catalogs: this.state.collection_catalogs });
			} catch (error) { }
			await this.promisedSetState({ loading_fb_catalogs: false });
		},
		getFacebookProductSets: async () => {
			await this.promisedSetState({ loading_fb_productsets: true });
			try {
				let response = await this.calls.facebookProductSets(this.state.updated.collection_catalog.id);
				this.state.product_sets = response.data;
				await this.promisedSetState({ product_sets: this.state.product_sets });
			} catch (error) { }
			await this.promisedSetState({ loading_fb_productsets: false });
		},
		getFacebookProducts: async (product_set) => {
			try {
				let response = await this.calls.facebookProducts(product_set);
				this.state.updated.preview_products = response.data;
				await this.promisedSetState({ updated: this.state.updated });
			} catch (error) { }
		},*/
		onSelectFile: async (e, slide = false) => {
			let files = e.target.files;
			if (files) {
				for (let i in files) {
					if (files[i].type === 'video/mp4' || files[i].type === 'image/gif' || files[i].type === 'video/quicktime' || files[i].type === 'video/mov') {
						await this.functions.uploadVideo(files[i], slide);
					} else if (files[i].type === 'image/jpg' || files[i].type === 'image/jpeg' || files[i].type === 'image/png') {
						await this.functions.uploadImage(files[i], slide);
					}
					const randomString = Math.random().toString(36);
					await this.promisedSetState({
						theInputKeyOne: randomString + 1
					});
				}
			}
			this.functions.updatePreview();
		},
		/*
		listLeadForms: async () => {
			await this.promisedSetState({ loading_leadforms: true });
			try {
				let response = await this.calls.listLeadForms(this.renders.page().id);
				this.state.lead_forms = Array.isArray(response.data) ? response.data.map((item) => { return { name: item.name, id: item.id, status: item.status } }) : [];
				this.promisedSetState({ lead_forms: this.state.lead_forms })
			} catch (error) {
			}
			await this.promisedSetState({ loading_leadforms: false });
		},
		instantExperience: () => {
			let self = this;
			//1932289657009030  STORE FRONT
			//1369752616394017 LOOK BOOK
			//133471657203838 CUSTUMER ACQUISITION

			if (self.renders.page() && self.renders.page().id !== 0 && self.state.instant_type.id !== 0) {
				window.FB.ui({
					account_id: this.state.client.channels.filter((item) => { return item.value === "facebook" })[0].facebookAdAccountId.replace("act_", ""),
					page_id: self.renders.page().id,
					business_id: this.state.client.channels.filter((item) => { return item.value === "facebook" })[0].facebookBusinessManagerId,
					display: 'popup',
					method: 'instant_experiences_builder',
					template_id: self.state.instant_type.id,
				}, function (response) {
					try {
						if (response.success) {
							self.functions.instantCreated(response);
						}
						self.setState({ loading_instant: false });
					} catch (e) {
						self.setState({ loading_instant: false });
					}
				})
			}
		},
		instantCreated: async (response) => {
			try {
				this.state.updated.instantExperience = { id: response.id, name: response.id };
				await this.promisedSetState({
					updated: this.state.updated
				});
				this.functions.getInstant();
			} catch (error) {
				console.log(error)
			}
		},
		getInstant: async () => {
			try {
				await this.promisedSetState({ loading_instant: true });
				let response = await this.calls.getInstant();
				if (response.data && ((response.data.photo || response.data.video) && (response.data.carousel || response.data.product_set))) {
					this.state.updated.instantExperience.elements = response.data;
					await this.promisedSetState({
						updated: this.state.updated
					});
					this.functions.updatePreview();
				}
				await this.promisedSetState({ loading_instant: false });
			} catch (error) {
				console.log(error)
			}
		},
		*/
		uploadImage: async (file, slide) => {
			return new Promise(async (resolve) => {
				let files_object = {
					id: Math.floor((Math.random() * 9999999999) + 1),
					loading: true,
					name: file.name,
					name_by_channels: {
						facebook: file.name,
						linkedin: file.name,
						tiktok: file.name,
						twitter: file.name,
						snapchat: file.name
					},
					type: 'image'
				};

				files_object.allowed_channels = { facebook: true, linkedin: true, twitter: true, snapchat: true };
				files_object.allowed_types = { regular: true };
				files_object.allowed_multiple = true;

				this.state.assetItems.push(files_object);
				//this.state.updated.file = { ...this.state.updated.file,  };
				await this.promisedSetState({ assetItems: this.state.assetItems, loader: true });
				try {
					const formData = new FormData();
					formData.append('file', file);
					let response = await this.calls.image(formData);
					this.state.assetItems = this.state.assetItems.map((item) => {
						if (item.id === files_object.id) {
							item.loading = false;
							item = { ...item, ...response.data };
						}
						return item;
					});
					await this.promisedSetState({ assetItems: this.state.assetItems });
					//await this.props.updateAssets(this.state.assetItems);
					if (!slide) {
						this.state.updated.file = { ...this.state.updated.file, ...files_object, ...response.data, ...{ assetId: response.data.id } };
						this.state.updated.picture = response.data.url;
					} else {
						if (!this.state.updated.slides) {
							this.state.updated.slides = this.state.ad.origin_data.creative.object_story_spec.link_data.child_attachments;
						}
						this.state.updated.slides = JSON.parse(JSON.stringify(this.state.updated.slides)).map((inner_item) => {
							if ((inner_item.image_hash || inner_item.picture) && !inner_item.video_id) {
								if ((inner_item.image_hash && inner_item.image_hash === slide.hash) || (inner_item.picture && inner_item.picture === slide.picture)) {
									inner_item.file = { ...files_object, ...response.data, ...{ assetId: response.data.id } };
								}
							}
							return inner_item;
						});
						this.state.ad.creative.slides = JSON.parse(JSON.stringify(this.state.ad.creative.slides)).map((inner_item) => {
							if (inner_item.hash || (inner_item.picture && !inner_item.video_id)) {
								if ((inner_item.hash && inner_item.hash === slide.hash) || (inner_item.picture && inner_item.picture === slide.picture)) {
									inner_item.picture = response.data.url;
								}
							}
							return inner_item;
						});
					}
					await this.promisedSetState({ updated: this.state.updated, ad: this.state.ad, selectedAsset: response.data.id, loader: false });

					console.log("UPDATED", this.state.updated)
					console.log("AD", this.state.ad.creative)
					this.functions.updatePreview();

				} catch (error) {
					this.state.assetItems = this.state.assetItems.map((item) => {
						if (item.id === files_object.id) {
							item.loading = false;
							item.error = true;
						}
						return item;
					});
					await this.promisedSetState({ assetItems: this.state.assetItems, loader: false });
				}
				resolve()
			});
		},
		uploadVideo: async (file, slide) => {

			let files_object = {
				id: Math.floor((Math.random() * 9999999999) + 1),
				loading: true,
				name: file.name,
				name_by_channels: {
					facebook: file.name,
					linkedin: file.name,
					tiktok: file.name,
					twitter: file.name,
					snapchat: file.name
				},
				type: 'video'
			};

			files_object.allowed_channels = { facebook: true, linkedin: true, twitter: true, tiktok: true, snapchat: true };
			files_object.allowed_types = { regular: true };
			files_object.allowed_multiple = true;

			this.state.assetItems.push(files_object);
			//this.state.updated.file = { ...this.state.updated.file, ...files_object };
			await this.promisedSetState({ assetItems: this.state.assetItems, loader: true });
			this.props.onDisable(true);

			let channel_id = Math.floor((Math.random() * 9999999999) + 1) + "_videoUpload";
			let self = this;
			var channel = pusherService.subscribe(channel_id);
			channel.bind('videoUpload', async function (response) {
				//console.log("RESPONSE VIDEO UPLOAD", response)
				if (response && response.status === 200) {
					self.state.assetItems = self.state.assetItems.map((item) => {
						if (item.id === files_object.id) {
							item.loading = false;
							item = { ...item, ...response.data };
						}
						return item;
					});
					await self.promisedSetState({ assetItems: self.state.assetItems });
					//await self.props.updateAssets(self.state.assetItems);
					if (!slide) {
						self.state.updated.file = { ...self.state.updated.file, ...files_object, ...response.data, ...{ assetId: response.data.id } };
						self.state.updated.video = response.data.url;
					} else {
						if (!self.state.updated.slides) {
							self.state.updated.slides = self.state.ad.origin_data.creative.object_story_spec.link_data.child_attachments;
						}
						self.state.updated.slides = JSON.parse(JSON.stringify(self.state.updated.slides)).map((inner_item) => {
							if (inner_item.video_id) {
								if (inner_item.video_id === slide.video_id) {
									inner_item.file = { ...files_object, ...response.data, ...{ assetId: response.data.id } };
								}
							}
							return inner_item;
						});
						self.state.ad.creative.slides = JSON.parse(JSON.stringify(self.state.ad.creative.slides)).map((inner_item) => {
							if (inner_item.video_id) {
								if (inner_item.video_id === slide.video_id) {
									inner_item.url = response.data.url;
								}
							}
							return inner_item;
						});
					}
					await self.promisedSetState({ updated: self.state.updated, selectedAsset: response.data.id, ad: self.state.ad, loader: false });
					self.props.onDisable(false);
					self.functions.updatePreview();

				} else {
					self.state.assetItems = self.state.assetItems.map((item) => {
						if (item.id === files_object.id) {
							item.loading = false;
							item.error = true;
						}
						return item;
					});
					await self.promisedSetState({ assetItems: self.state.assetItems, loader: false });
					self.props.onDisable(false);
				}
				try {
					pusherService.unsubscribe(channel_id);
				} catch (error) {
					console.log(error);
					self.props.onDisable(false);
				}
			});

			try {
				const formData = new FormData();
				formData.append('file', file);
				await this.calls.video_upload(formData, channel_id);
			} catch (error) {
				this.state.assetItems = this.state.assetItems.map((item) => {
					if (item.id === files_object.id) {
						item.loading = false;
						item.error = true;
					}
					return item;
				});
				this.props.onDisable(false);
				await this.promisedSetState({ assetItems: this.state.assetItems, loader: false });
				//await this.props.updateAssets(this.state.assetItems);

			}
		},
		update: async () => {
			this.props.onSuccess(false);
			this.props.onLoader(true);
			await this.promisedSetState({
				error: false
			});
			if (Object.keys(this.state.updated).length > 0) {//&& this.state.ad.creative_type !== "unknown" && this.state.ad.origin_data) {

				let new_id = null
				if (["body", "title", "description", "links", "link", "cta", "slides", "file"].some(item => this.state.updated[item])) {
					//if (this.state.ad.origin_data.creative.object_story_spec) {
					let body = {};
					try {
						if (!this.state.ad.creative.is_post && this.state.ad.origin_data.creative.object_story_spec) {
							if (this.state.ad.creative_type === "image" && !this.state.ad.creative.ad_type) {
								body.degrees_of_freedom_spec = {
									"creative_features_spec": {
										"standard_enhancements": {
											"enroll_status": "OPT_OUT"
										}
									}
								};
								body.object_story_spec = this.state.ad.origin_data.creative.object_story_spec;
								if (this.state.updated.title) {
									body.title = this.state.updated.title;
									body.object_story_spec.link_data.name = this.state.updated.title;
								}
								if (this.state.updated.body) {
									body.object_story_spec.link_data.message = this.state.updated.body;
								}
								if (this.state.updated.cta) {
									body.object_story_spec.link_data.call_to_action = {
										type: this.renders.cta().type,
										value: {
											link: this.renders.link()
										}
									}
								}
								if (this.state.updated.link) {
									body.object_story_spec.link_data.link = this.state.updated.link;
									if (body.object_story_spec.link_data.call_to_action && body.object_story_spec.link_data.call_to_action.value) {
										body.object_story_spec.link_data.call_to_action.value.link = this.state.updated.link;
									}
								}
								if (this.state.updated.description) {
									body.object_story_spec.link_data.description = this.state.updated.description;
								}
								if ("file" in this.state.updated) {
									delete body.object_story_spec.link_data.image_hash;
									body.object_story_spec.link_data.picture = this.state.updated.file.url;
								}
								if ("image_hash" in body.object_story_spec.link_data && "picture" in body.object_story_spec.link_data) {
									delete body.object_story_spec.link_data.image_hash;
								}

							} else if (this.state.ad.creative_type === "video" && !this.state.ad.creative.ad_type) {
								body.degrees_of_freedom_spec = {
									"creative_features_spec": {
										"standard_enhancements": {
											"enroll_status": "OPT_OUT"
										}
									}
								};
								body.object_story_spec = this.state.ad.origin_data.creative.object_story_spec;
								if (this.state.updated.title) {
									body.object_story_spec.video_data.title = this.state.updated.title;
								}
								if (this.state.updated.body) {
									body.object_story_spec.video_data.message = this.state.updated.body;
								}
								if (this.state.updated.link) {
									if (body.object_story_spec.video_data.call_to_action && body.object_story_spec.video_data.call_to_action.value && body.object_story_spec.video_data.call_to_action.value.link) {
										body.object_story_spec.video_data.call_to_action.value.link = this.state.updated.link;
									}
								}
								if (this.state.updated.description) {
									body.object_story_spec.video_data.link_description = this.state.updated.description;
								}
								if ("file" in this.state.updated) {
									let resp = await this.calls.uploadCreatives(this.state.updated.file.id);
									//console.log("RESP", resp)
									if (resp.data && resp.data.facebook && resp.data.facebook.id) {
										delete body.object_story_spec.video_data.image_hash;
										body.object_story_spec.video_data.image_url = this.state.updated.file.thumbnail;
										body.object_story_spec.video_data.video_id = resp.data.facebook.id;
									} else {
										body = {};
										await this.promisedSetState({
											error: resp.data && resp.data.facebook && resp.data.facebook.error ? JSON.stringify(resp.data.facebook.error) : "Something went wrong"
										});
									}
									//body.object_story_spec.video_data.image_url = this.state.updated.file.url;
								}

								//MAKE SURE IMAGE_HASH AND IMAGE_URL DOESNT EXIST AT SAME TIME
								if (body.object_story_spec.video_data.image_hash) {
									delete body.object_story_spec.video_data.image_url;
								}

							} else if (this.state.ad.creative.ad_type && this.state.ad.creative.ad_type === "carousel_feed") {
								body.degrees_of_freedom_spec = {
									"creative_features_spec": {
										"standard_enhancements": {
											"enroll_status": "OPT_OUT"
										}
									}
								};
								body.object_story_spec = this.state.ad.origin_data.creative.object_story_spec;
								delete body.object_story_spec.link_data.image_hash;
								if (this.state.updated.body) {
									body.object_story_spec.link_data.message = this.state.updated.body;
								}

								if (this.state.updated.slides) {
									body.object_story_spec.link_data.child_attachments = this.state.updated.slides;
								}
								body.object_story_spec.link_data.child_attachments = JSON.parse(JSON.stringify(body.object_story_spec.link_data.child_attachments)).map((item) => {
									if (item.file) {
										if (!item.video_id) {
											delete item.image_hash;
											delete item.picture;
											item.picture = item.file.url;
											delete item.file;
										}
									} else {
										if (item.image_hash && item.picture) delete item.picture;
									}
									return item;
								});

								if (this.state.updated.link || this.state.updated.cta) {
									body.object_story_spec.link_data.link = this.renders.link();
									body.object_story_spec.link_data.call_to_action = {
										type: this.renders.cta().type,
										value: {
											link: this.renders.link()
										}
									};
									body.object_story_spec.link_data.child_attachments = JSON.parse(JSON.stringify(body.object_story_spec.link_data.child_attachments)).map((item) => {
										if (item.image_hash && item.picture) delete item.picture;
										item.call_to_action = {
											type: this.renders.cta().type,
											value: {
												link: item.link
											}
										}
										item.link = item.link;
										return item;
									});
								}
								for (let i in body.object_story_spec.link_data.child_attachments) {
									if (body.object_story_spec.link_data.child_attachments[i].file && body.object_story_spec.link_data.child_attachments[i].file.type === "video") {
										let resp = await this.calls.uploadCreatives(body.object_story_spec.link_data.child_attachments[i].file.assetId);
										//console.log("RESP", resp)
										if (resp.data && resp.data.facebook && resp.data.facebook.id) {
											delete body.object_story_spec.link_data.child_attachments[i].image_hash;
											body.object_story_spec.link_data.child_attachments[i].video_id = resp.data.facebook.id;
											body.object_story_spec.link_data.child_attachments[i].picture = body.object_story_spec.link_data.child_attachments[i].file.thumbnail;
											delete body.object_story_spec.link_data.child_attachments[i].file;
										} else {
											body = {};
											await this.promisedSetState({
												error: resp.data && resp.data.facebook && resp.data.facebook.error ? JSON.stringify(resp.data.facebook.error) : "Something went wrong"
											});
										}
									}
								}

							} else if (this.state.ad.creative.ad_type && this.state.ad.creative.ad_type === "merge_ad") {
								body.degrees_of_freedom_spec = {
									"creative_features_spec": {
										"standard_enhancements": {
											"enroll_status": "OPT_OUT"
										}
									}
								};
								body.object_story_spec = this.state.ad.origin_data.creative.object_story_spec;
								body.asset_feed_spec = {
									...(this.state.ad.origin_data.creative.asset_feed_spec && this.state.ad.origin_data.creative.asset_feed_spec.images && {
										images: this.state.ad.origin_data.creative.asset_feed_spec.images.map((item) => {
											if (item.image_crops && item.image_crops["191x100"]) {
												//item.image_crops["100x100"] = item.image_crops["191x100"];
												delete item.image_crops["191x100"];

											}
											return item;
										})
									}),
									...(this.state.ad.origin_data.creative.asset_feed_spec && this.state.ad.origin_data.creative.asset_feed_spec.videos && { videos: this.state.ad.origin_data.creative.asset_feed_spec.videos }),
									asset_customization_rules: this.state.ad.origin_data.creative.asset_feed_spec.asset_customization_rules.map((item) => {
										return {
											customization_spec: item.customization_spec,
											...(item.image_label && { image_label: item.image_label }),
											...(item.video_label && { video_label: item.video_label }),
										}
									}),
									ad_formats: this.state.ad.origin_data.creative.asset_feed_spec.ad_formats,
									call_to_action_types: [this.renders.cta().type],
									bodies: [{ text: this.renders.body() }],
									titles: [{ text: this.renders.title() }],
									descriptions: [{ text: this.renders.description() }],
									link_urls: [{ website_url: this.renders.link() }],
								}
							}
						} else {
							//FOR POSTS
							if (this.state.ad.origin_data.creative.source_instagram_media_id) {
								//INSTAGRAM POSTS
								body.source_instagram_media_id = this.state.ad.origin_data.creative.source_instagram_media_id;
								body.object_id = this.state.ad.origin_data.creative.actor_id;
								body.instagram_user_id = this.state.ad.origin_data.creative.instagram_user_id;
								if (this.state.updated.cta) {
									body.call_to_action = {
										type: this.renders.cta().type,
										value: {
											link: this.renders.link()
										}
									}
								}
							} else if (this.state.ad.origin_data.creative.object_story_id) {
								//FACEBOOK POSTS
							}
						}
						if (Object.keys(body).length > 0) {
							try {
								let response = await this.calls.createCreative(body);
								if (response.data && response.message !== "") {
									this.promisedSetState({
										error: response.data.message
									});
								} else {
									new_id = response.data.id;
								}
							} catch (error) {
								//console.log("ERROR HERRRRRRRRR", error)
								if (error.body && error.body.message) {
									this.promisedSetState({
										error: error.body.message
									});
								} else {
									this.promisedSetState({
										error: "Something went wrong"
									});
								}
							}
						}


					} catch (error) {

					}
					//}

				}

				if (!this.state.error) {

					let body = {
						...(this.state.updated.name && { name: this.state.updated.name }),
						...(new_id && { creative: { creative_id: new_id } }),
						redownload: 1
					}
					try {
						if (this.state.updated.name || new_id) {
							let response = await this.calls.updateAd(body, this.state.ad.id);

							if (response.data && response.data.message !== "") {
								this.promisedSetState({
									error: response.data.message
								});
							}
						}
					} catch (error) {
						if (error.body && error.body.message) {
							this.promisedSetState({
								error: error.body.message
							});
						} else {
							this.promisedSetState({
								error: "Something went wrong"
							});
						}
					}

				}


			}

			/*
							let body = {
								name: this.state.updated.name,
								redownload:1
							}
							try {
								let response = await this.calls.updateAd(body, this.state.ad.id);
								/*if (response.data && response.data.message !== "") {
									this.promisedSetState({
										error: response.data.message
									});
								}
							} catch (error) {
							}
							*/


			if (!this.state.error) {
				this.props.onSuccess(true);
			}
			this.props.onLoader(false);

		},
		/*
		listPages: async () => {
			try {
				await this.promisedSetState({ loading_pages: true });
				let response = await this.calls.pages();
				await this.promisedSetState({ loading_pages: false, pages: Array.isArray(response.data) ? response.data.map((item) => { return { name: item.name, id: item.id, image: item.picture.data.url } }) : [] })
				if (!this.state.wizard) {
					this.functions.listInstagramAccounts(this.renders.page(this.state.ad).id);
				}
			} catch (error) { }
		},
		listInstagramAccounts: async (page) => {
			try {
				await this.promisedSetState({ loading_instagram: true });
				let response = await this.calls.instagramAccounts(page);
				this.promisedSetState({ loading_instagram: false, instagram_accounts: Array.isArray(response.data) ? response.data.map((item) => { return { name: item.name, id: item.id, image: item.profile_pic, profile_pic: item.profile_pic } }) : [] })
			} catch (error) { }
		},
		listInstagramBusinessAccounts: async (page) => {
			try {
				await this.promisedSetState({ loading_instagram: true });
				let response = await this.calls.instagramBusinessAccounts(page);
				this.promisedSetState({ loading_instagram: false, instagram_accounts: Array.isArray(response.data) ? response.data.map((item) => { return { name: item.name, id: item.id, image: item.profile_pic, profile_pic: item.profile_pic } }) : [] })
			} catch (error) { }
		},
		getVideo: async (video) => {
			try {
				await this.promisedSetState({ loading_video: true });
				let response = await this.calls.video(video);
				this.promisedSetState({ loading_video: false, video: response.data.source })
			} catch (error) { }
		},
		instagram_post: async () => {
			if (this.state.updated.instagram_post) {
				await this.promisedSetState({ instagram_post: true });
			}
		},
		pixels: async () => {
			try {
				this.promisedSetState({ loading_pixels: true });
				let response = await this.calls.pixels();
				response.data.unshift({ id: 0, name: "No pixel", value: null });
				this.promisedSetState({ loading_pixels: false, pixels: response.data });
			} catch (error) {
				this.promisedSetState({
					pixels: []
				})
			}
		},
		listApps: async () => {
			try {
				this.promisedSetState({ loading_apps: true });
				let response = await this.calls.apps();
				let data = [];
				data = data.concat(response.data);
				this.promisedSetState({ loading_apps: false, apps: data });
			} catch (error) {
				this.promisedSetState({
					apps: []
				})
			}
		},
		*/
		updatePreview: async () => {
			let preview_data = {
				merge: false,
				carousel: this.state.carousel ? true : false,
				catalog: false,
				creative: this.state.ad && this.state.ad.creative ? this.state.ad.creative : false,
				headline_by_channels: { facebook: this.renders.title() },
				body_by_channels: { facebook: this.renders.body() },
				cta: { facebook: this.renders.cta() },
				page: { facebook: this.renders.page(), instagram: this.renders.instagram() },
				website_by_channels: { facebook: this.renders.link() },
				description: this.renders.description(),
				caption: this.renders.caption(),
				preview_channel: "facebook",//this.state.updated && this.state.updated.preview_channel ? this.state.updated.preview_channel : "facebook",
				previews: {},
				preview: { id: 1, value: "feed", name: "Feed", ratio: "1:1" },
				utm_channels: ["facebook"],
				channel_menu: "facebook",
				validation: {
					facebook: false
				},
			};
			if (!this.state.merge) {
				if (this.state.carousel || (this.state.ad.creative_type === "carousel" && this.state.ad.creative && this.state.ad.creative.is_post)) {
					preview_data.previews = { facebook: [{ id: 1, value: "feed", name: "Feed" }], instagram: [{ id: 1, value: "feed_instagram", name: "Feed" }] };
				} else {
					preview_data.previews = { facebook: [{ id: 1, value: "feed", name: "Feed" }, { id: 2, value: "story", name: "Story" }], instagram: [{ id: 1, value: "feed_instagram", name: "Feed" }, { id: 2, value: "story_instagram", name: "Story" }] };
				}
			} else {
				preview_data.previews = { facebook: [{ id: 1, value: "feed", name: "Feed (Square)" }, { id: 2, value: "feed", name: "Feed (Horizontal)" }, { id: 3, value: "story", name: "Story" }], instagram: [{ id: 1, value: "feed_instagram", name: "Feed (Square)" }, { id: 2, value: "feed_instagram", name: "Feed (Horizontal)" }, { id: 3, value: "story_instagram", name: "Story" }] };
			}
			preview_data.preview = preview_data.previews.facebook[0];
			if (preview_data.creative) {
				preview_data.creative.body = this.renders.body();
				preview_data.creative.title = this.renders.title();
				preview_data.creative.link = this.renders.link();
				preview_data.creative.description = this.renders.description();
				preview_data.creative.caption = this.renders.caption();
				preview_data.creative.call_to_action = this.renders.cta().id;
				if (this.state.ad.image_url) {
					preview_data.creative.image = this.state.ad.image_url;
					if (this.state.updated && this.state.updated.file) {
						preview_data.creative.image = this.state.updated.file.url;
					}
				}
				if (this.state.ad.video_url) {
					preview_data.creative.video = this.state.ad.video_url;
					if (this.state.updated && this.state.updated.file) {
						preview_data.creative.video = this.state.updated.file.url;
					}
				}
				//preview_data.creative.image = this.state.ad.image_url ? this.state.ad.image_url : null;
				//preview_data.creative.video = this.state.ad.video_url ? this.state.ad.video_url : null;
			}
			//console.log("UPDATED", this.state.updated)
			//console.log("PREVIEW", preview_data)

			//carousel: this.state.updated && this.state.updated.ad_type && this.state.updated.ad_type.value === "carousel_ad" ? this.state.carousel : false,
			// this.state.updated && this.state.updated.is_post ? [this.state.updated] : this.state.updated.files && this.state.updated.ad_type && this.state.updated.ad_type.value !== "carousel_ad" ? this.state.updated.files : (this.state.updated.ad_type.value === "carousel_ad" ? [this.state.carousel] : (this.state.updated.file ? [this.state.updated.file] : [])),




			try {
				this.props.updatePreview(preview_data)
			} catch (error) {
				console.log(error)
			}
		},
		previewChannel: async (channel) => {
			try {
				this.state.updated.preview_channel = channel;
				await this.promisedSetState({
					updated: this.state.updated
				});
				this.functions.updatePreview();
			} catch (error) {
			}
		},
		updateOpenAi: async (data) => {
			try {
				this.state.updated.OpenAiResult = data;
				await this.promisedSetState({
					updated: this.state.updated
				})
			} catch (error) {
				console.log(error)
			}
		},
		addHeadlines: async (data) => {
			try {
				if (this.state.updated && !this.state.updated.is_post && !this.state.updated.catalog) {
					for (let i = 0; i < data.length; i++) {
						if (data[i].force) {
							if (this.state.merge) {
								this.state.updated.headlines[0] = { value: data[i].value[0] };
							} else if (!this.state.carousel) {
								this.state.updated.title = data[i].value[0];
							}
						} else {
							if (this.state.merge) {
								if (this.state.updated.headlines[0] && this.state.updated.headlines[0].value !== "") {
									this.state.updated.headlines[0].value = data[i].value[0];
								} else if (this.state.updated.headlines[0] && this.state.updated.headlines[0].value === "") {
									this.state.updated.headlines[0].value = data[i].value[0];
								} else if (!this.state.updated.headlines[0]) {
									this.state.updated.headlines[0] = { value: data[i].value[0] };
								}
							}
							if (!this.state.carousel) {
								// if (this.renders.title() === "") {
								this.state.updated.title = data[i].value[0];
								// }
							}
							// if (this.state.updated.headline_by_channels[data[i].channel] === "") this.state.updated.headline_by_channels[data[i].channel] = data[i].value[0];
						}
					}
					await this.promisedSetState({
						updated: this.state.updated
					});
					this.functions.updatePreview();
				} else {
					return;
				}
			} catch (error) {
				console.log(error)
			}
		},
		addDescriptions: async (data) => {
			try {
				if (this.state.updated && !this.state.updated.is_post) {
					for (let i = 0; i < data.length; i++) {
						if (data[i].force) {
							if (this.state.merge) {
								this.state.updated.bodies[0] = { value: data[i].value[0] };
							} else {
								this.state.updated.body = data[i].value[0];
							}
						} else {
							if (this.state.merge) {
								if (this.state.updated.bodies[0] && this.state.updated.bodies[0].value !== "") {
									this.state.updated.bodies[0].value = data[i].value[0];
								} else if (this.state.updated.bodies[0] && this.state.updated.bodies[0].value === "") {
									this.state.updated.bodies[0].value = data[i].value[0];
								} else if (!this.state.updated.bodies[0]) {
									this.state.updated.bodies[0] = { value: data[i].value[0] };
								}
							} else {
								// IF MAX THINKS ITS FINE TO AUTO POPULATE ON EDIT
								// if (this.renders.body() === "") {
								this.state.updated.body = data[i].value[0];
								// }
								//if (this.state.updated.body_by_channels[data[i].channel] === "") this.state.updated.body_by_channels[data[i].channel] = data[i].value[0];
							}
						}
					}
					await this.promisedSetState({
						updated: this.state.updated
					});
					this.functions.updatePreview();
				} else {
					return;
				}
			} catch (error) {
				console.log(error)
			}
		},
		removeDescription: async () => {
			try {
				if (this.state.merge) {
					this.state.updated.bodies[0].value = "";
				}
				this.state.updated.body = "";
				await this.promisedSetState({
					updated: this.state.updated
				});
				this.functions.updatePreview();
			} catch (error) {
				console.log(error)
			}
		},
		removeHeadline: async () => {
			try {
				if (this.state.merge) {
					this.state.updated.headlines[0].value = "";
				}
				this.state.updated.title = "";
				await this.promisedSetState({
					updated: this.state.updated
				});
				this.functions.updatePreview();
			} catch (error) {
				console.log(error)
			}
		},
		deleteSlide: async (slide) => {
			try {
				if (this.state.updated && this.state.updated.files && Array.isArray(this.state.updated.files)) {
					this.state.updated.files = this.state.updated.files.filter((item) => {
						return slide.id !== item.id;
					});
				}
				this.state.updated.slides = this.state.updated.files;
				await this.promisedSetState({
					updated: this.state.updated, open_slide: 0, delete_modal: false
				});
				if (this.props.onChange) {
					this.props.onChange(this.state.updated);
				}
				this.functions.updatePreview();
			} catch (error) {
				console.log(error)
			}
		},
		listInstantExpriences: async () => {
			try {
				let response = await this.calls.listInstantExpriences();
				await this.promisedSetState({
					instant_options: response.data
				});
			} catch (error) {
			}
		},
		leadForm: () => {
			let self = this;
			/*
			account_id: this.state.client.channels.filter((item) => { return item.value === "facebook"})[0].facebookAdAccountId.replace("act_", ""),
					page_id: this.state.page.facebook.id,
					business_id: this.state.client.channels.filter((item) => { return item.value === "facebook"})[0].facebookBusinessManagerId,
					display: 'popup',
					method: 'instant_experiences_builder',
					template_id: "1932289657009030"
			*/
			if (self.state.fb_lead_form && self.state.updated.page.id && self.state.updated.page.id !== 0) {
				window.FB.ui({
					ad_account_id: self.state.client.channels.filter((item) => { return item.value === "facebook" })[0].facebookAdAccountId,
					page_id: self.state.updated.page.id,
					display: 'popup',
					method: 'lead_gen',
				}, function (response) {
					try {
						if (response.status === "success") {
							self.functions.leadCreated(response);
						}

						self.setState({ loading_new_lead: false });
					} catch (e) {
						self.setState({ loading_new_lead: false });
					}
				})
			}
		},
		leadCreated: async (response) => {
			try {
				this.state.updated.selected_lead = { id: response.formID, name: response.name.split("+").join(" "), status: "ACTIVE" };
				this.state.lead_forms.unshift({ id: response.formID, name: response.name.split("+").join(" "), status: "ACTIVE" });
				await this.promisedSetState({
					updated: this.state.updated,
					lead_forms: this.state.lead_forms
				});
			} catch (error) {
			}
		},
	};

	renders = {
		updateCheck: () => {
			try {
				if (this.state.ad && this.state.ad.creative && !this.state.ad.creative.is_post) {
					return true;
				} else {
					return false;
				}
			} catch (error) {

			}
		},
		creative: (item, placement_format) => {
			let creative = null;
			try {
				if (this.renders.type(item) === "video") {
					if (this.state.updated.video) {
						if (!this.state.wizard) {
							creative = { thumbnail: this.state.updated.video.thumbnail, video: this.state.updated.video.url };
						} else {
							creative = { thumbnail: this.state.updated.thumbnail, video: this.state.updated.video };
						}
					} else {
						creative = { thumbnail: item.creative.object_story_spec.video_data.image_url, video: this.state.video };
					}
				}
			} catch (eror) { }
			try {
				if (this.renders.type(item) === "posts") {
					if (Array.isArray(this.state.updated.files)) {
						creative = this.state.updated.files.map((inner_item) => {
							let buff = {
								headline: item.headline,
								description: item.body,
								video: false,
								image: false,
								loading: item.loading,
								cta: item.cta,
								creative: { thumbnail: { url: inner_item.url }, url: inner_item.url, ratio: "1:1" }
							}
							if (inner_item.type === "image") {
								buff.image = true;
							} else {
								buff.video = true;
							}
							return buff
						});
					} else if (this.state.updated.ad_type.type === "image") {
						creative = this.state.updated.picture;
					} else if (this.state.updated.ad_type.type === "video") {
						creative = { thumbnail: "", video: this.state.updated.url };
					}
				}
			} catch (error) { }

			try {
				if (this.renders.type(item) === "image") {
					if (this.state.updated.picture && this.state.updated.picture !== "") {
						creative = this.state.updated.picture;
					} else {
						creative = item.creative.object_story_spec.link_data.picture;
					}
				}
			} catch (eror) { }
			try {
				if (this.renders.type(item) === "merge") {
					this.state.updated.slides.map((item) => {
						if ((item.ratio === '9:16') && placement_format === 'vertical') {
							if (item.type === 'video') {
								creative = { thumbnail: item.thumbnail, url: item.url };
								console.log(creative);
							} else {
								creative = { url: item.url };
							}
						} else if ((item.ratio !== '9:16' && item.ratio !== '1.91:1') && placement_format === 'square') {
							if (item.type === 'video') {
								creative = { thumbnail: item.thumbnail, video: item.url };
							} else {
								creative = item.url;
							}
						}
					});
				}
			} catch (error) { }
			try {
				if (this.renders.type(item) === "carousel") {
					if (Array.isArray(this.state.updated.slides)) {
						creative = this.state.updated.slides.map((item) => {
							return {
								headline: item.headline,
								description: item.body,
								video: item.video_id || item.video,
								image: !item.video_id && !item.video,
								loading: item.loading,
								cta: item.cta,
								creative: { thumbnail: { url: !item.video_data ? item.picture : item.video_data.thumbnail }, url: !item.video_data ? item.picture : item.video_data.url, ratio: "1:1" }
							}
						});
					} else {
						if (Array.isArray(item.creative.object_story_spec.link_data.child_attachments)) {
							creative = item.creative.object_story_spec.link_data.child_attachments.map((item) => {
								return {
									headline: item.name,
									description: item.description,
									video: item.video_id,
									image: !item.video_id,
									creative: { thumbnail: { url: item.picture }, url: item.picture, ratio: "1:1" }
								}
							});
						}
					}
				}
			} catch (eror) { }
			try {
				if (this.renders.type(item) === "catalog") {
					if (Array.isArray(this.state.updated.preview_products)) {
						creative = this.state.updated.preview_products.map((item) => {
							return {
								headline: item.name,
								description: item.price,
								image: true,
								loading: item.loading,
								cta: item.cta,
								creative: { thumbnail: { url: !item.video_data ? item.picture : item.video_data.thumbnail }, url: !item.video_data ? item.image_url : item.video_data.url, ratio: "1:1" }
							}
						});
					} else {
						if (Array.isArray(item.creative.object_story_spec.link_data.child_attachments)) {
							creative = item.creative.object_story_spec.link_data.child_attachments.map((item) => {
								return {
									headline: item.name,
									description: item.price,
									video: item.video_id,
									image: !item.video_id,
									creative: { thumbnail: { url: item.picture }, url: item.picture, ratio: "1:1" }
								}
							});
						}
					}
				}
			} catch (eror) { }
			return creative;
		},
		status: () => {
			try {
				if ("status" in this.state.updated) {
					return this.state.updated.status;
				} else if (this.state.ad.status === 'ACTIVE') {
					return { id: 1, name: 'Active', value: "ACTIVE" }
				} else if (this.state.ad.status === 'PAUSED') {
					return { id: 2, name: 'Paused', value: "PAUSED" }
				} else if (this.state.ad.status === 'DELETED') {
					return { id: 3, name: 'Deleted', value: "DELETED" }
				} else if (this.state.ad.status === 'ARCHIVED') {
					return { id: 4, name: 'Archived', value: "ARCHIVED" }
				} else {
					return { id: 2, name: 'Paused', value: "PAUSED" }
				}
			} catch (error) {
				return { id: 2, name: 'Paused', value: "PAUSED" }
			}
		},
		lead: () => {
			try {
				if ("selected_lead" in this.state.updated) {
					return this.state.updated.selected_lead;
				} else {
					return { id: 0, name: 'Select ...', value: null }
				}
			} catch (error) {
				return { id: 0, name: 'Select ...', value: null }
			}
		},
		collection_catalog: () => {
			try {
				if ("collection_catalog" in this.state.updated) {
					return this.state.updated.collection_catalog;
				} else {
					return { id: 0, name: 'Select catalog', value: null }
				}
			} catch (error) {
				return { id: 0, name: 'Select catalog', value: null }
			}
		},
		product_set: () => {
			try {
				if ("product_set" in this.state.updated) {
					return this.state.updated.product_set;
				} else {
					return { id: 0, name: 'Select product set', value: null }
				}
			} catch (error) {
				return { id: 0, name: 'Select product set', value: null }
			}
		},
		adType: () => {
			try {
				if ("ad_type" in this.state.updated) {
					return this.state.updated.ad_type;
				} else {
					return { id: 0, name: 'Not specified', value: null }
				}
			} catch (error) {
				return { id: 0, name: 'Not specified', value: null }
			}
		},
		type: () => {
			let type = null;
			try {
				if (this.state.ad.creative_type) {
					type = this.state.ad.creative_type;
				}
			} catch (eror) { }
			return type;

		},
		correctType: () => {
			let type = null;
			try {
				if (this.state.ad.creative_type && this.state.ad.creative_type === "carousel_ad" && this.state.ad.unsupported) {
					type = "Unsupported";
				} else if (this.state.ad.creative_type && !this.state.ad.creative.ad_type) {
					type = this.state.ad.creative_type;
				} else {
					type = this.state.ad.creative.ad_type;
					if (type === "merge_ad") {
						if (this.state.ad.origin_data.creative.asset_feed_spec && this.state.ad.origin_data.creative.asset_feed_spec.images) {
							if (this.state.ad.origin_data.creative.asset_feed_spec.images.map((item) => {
								if (item.image_crops && item.image_crops["191x100"]) {
									return true;
								} else return false;
							}).filter((item) => { return item }).length > 0) {
								type = "Unsupported";
							}

						}
					}
				}
			} catch (eror) { }
			return type;

		},
		name: () => {
			try {
				if ('name' in this.state.updated) {
					return this.state.updated.name;
				} else {
					return this.state.ad.name
				}
			} catch (error) {
				return 0;
			}
		},
		title: () => {
			try {
				if ('title' in this.state.updated) {
					return this.state.updated.title;
				} else {
					if (this.state.ad.creative.title && this.state.ad.creative.title !== "") {
						return this.state.ad.creative.title;
					} else {
						return "";
					}
				}
			} catch (error) {
				return "";
			}
		},
		button_label: () => {
			try {
				if ('button_label' in this.state.updated) {
					return this.state.updated.button_label;
				} else return "";
			} catch (error) {
				return "";
			}
		},
		header_title: () => {
			try {
				if ('header_title' in this.state.updated) {
					return this.state.updated.header_title;
				} else return "";
			} catch (error) {
				return "";
			}
		},
		body: () => {
			try {
				if ('body' in this.state.updated) {
					return this.state.updated.body;
				} else {
					if (this.state.ad.creative.body && this.state.ad.creative.body !== "") {
						return this.state.ad.creative.body;
					} else {
						return "";
					}
				}
			} catch (error) {
				return "";
			}
		},
		description: () => {
			try {
				if ('description' in this.state.updated) {
					return this.state.updated.description;
				} else {
					if (this.state.ad.creative.description && this.state.ad.creative.description !== "") {
						return this.state.ad.creative.description;
					} else {
						return "";
					}
				}
			} catch (error) {
				return "";
			}
		},
		caption: () => {
			try {
				if ('caption' in this.state.caption) {
					return this.state.updated.caption;
				} else {
					if (this.state.ad.creative.caption && this.state.ad.creative.caption !== "") {
						return this.state.ad.creative.caption;
					} else {
						return "";
					}
				}
			} catch (error) {
				return "";
			}
		},
		link: () => {
			try {
				if ('links' in this.state.updated) {
					return this.state.updated.links[0].value;
				} else if ('link' in this.state.updated) {
					return this.state.updated.link;
				} else {
					if (this.state.ad.creative.link) {
						return this.state.ad.creative.link;
					} else {
						return "";
					}
				}
			} catch (error) {
				return "";
			}
		},
		cta: () => {
			try {
				if ("cta" in this.state.updated) {
					return this.state.updated.cta;
				} else if (this.state.call_to_actions.filter((item) => { return item.id == this.state.ad.creative.call_to_action }).length > 0) {
					return this.state.call_to_actions.filter((item) => { return item.id == this.state.ad.creative.call_to_action })[0];
				} else {
					return { id: 0, name: "No Button", type: "NO_BUTTON" };
				}
			} catch (error) {
				return { id: 0, name: "No Button", type: "NO_BUTTON" };
			}
		},
		// try {
		// 	if (this.renders.type(item) === "merge") {
		// 		let cta = { id: 0, name: "Select ...", type: "NO_BUTTON" };
		// 		if (Array.isArray(this.state.updated.call_to_actions)) {
		// 			this.state.updated.call_to_actions.map((item) => {
		// 				cta = item.value;
		// 			});
		// 		}
		// 		return cta;
		// 	} else if (this.state.updated.cta) {
		// 		return this.state.updated.cta;
		// 	} else {
		// 		let cta = null;
		// 		if (this.renders.type(item) === "image") {
		// 			cta = this.state.call_to_actions.filter((cta) => { return cta.type == item.creative.object_story_spec.link_data.call_to_action.type })[0];
		// 		} else if (this.renders.type(item) === "video") {
		// 			cta = this.state.call_to_actions.filter((cta) => { return cta.type == item.creative.object_story_spec.video_data.call_to_action.type })[0];
		// 		}
		// 		if (cta) {
		// 			return cta;
		// 		} else {
		// 			return { id: 0, name: "Select ...", type: "NO_BUTTON" }
		// 		}
		// 	}
		// } catch (error) {
		// 	console.log(error);
		// 	return { id: 0, name: "Select ...", type: "NO_BUTTON" };
		// }
		app: () => {
			try {
				if (this.state.ad.tracking && this.state.ad.tracking.app) {
					return this.state.ad.tracking.app;
				} else {
					return { id: 0, name: "No App", value: null }
				}
			} catch (error) {
				console.log(error);
				return { id: 0, name: "No App", value: null };
			}
		},
		pixel: () => {
			try {
				if (this.state.ad.tracking && this.state.ad.tracking.pixel) {
					return this.state.ad.tracking.pixel;
				} else {
					return { id: 0, name: "No Pixel", value: null }
				}
			} catch (error) {
				return { id: 0, name: "No Pixel", value: null };
			}
		},
		page: (item) => {
			try {
				if (this.state.ad.creative.page && this.state.ad.creative.page.id) {
					return this.state.ad.creative.page
				} else {
					return { id: 0, name: "Couldn't load page" };
				}

			} catch (error) {
				return { id: 0, name: "Couldn't load page" };
			}
		},
		instagram: (item) => {
			try {
				if (this.state.ad.creative.instagram_page) {
					return this.state.ad.creative.instagram_page;

				} else {
					return { id: 0, name: "Couldn't load instagram" };
				}
			} catch (error) {
				return { id: 0, name: "Couldn't load instagram" };
			}
		},
		autoCards: (item) => {
			try {
				if (this.state.updated.auto_cards) {
					return this.state.updated.auto_cards
				} else {
					if (item.creative.object_story_spec.link_data.multi_share_optimized) {
						return { id: 1, name: "Yes", value: true };
					} else {
						return { id: 1, name: "no", value: false };
					}
				}
			} catch (error) {
				return { id: 1, name: "Yes", value: true };
			}
		},
		lastCard: (item) => {
			try {
				if (this.state.updated.last_card) {
					return this.state.updated.last_card
				} else {
					if (item.creative.object_story_spec.link_data.multi_share_end_card) {
						return { id: 1, name: "Yes", value: true };
					} else {
						return { id: 1, name: "no", value: false };
					}
				}
			} catch (error) {
				return { id: 1, name: "Yes", value: true };
			}
		},
		instantExperience: () => {
			try {
				if (this.state.updated && this.state.updated.instantExperience) {
					return { id: this.state.updated.instantExperience.id, name: this.state.updated.instantExperience.name }
				} else if (this.state.instantExperience) {
					return { id: this.state.instantExperience.id, name: this.state.instantExperience.name }
				} else {
					return { id: 0, name: "Select instant experience" };
				}
			} catch (error) {
				return { id: 0, name: "Select instant experience" };
			}
		}
	};

	calls = {
		uploadCreatives: (id) => {
			let options = apiRegister.options(tokenRegister.get(), 'POST', {}, true);
			let url = apiRegister.url.api + "/v3/adcredo/uploadCreativeChannels?client=" + this.state.client.id + "&facebook=true&creative=" + id + "&id=" + id;
			return apiRegister.call(options, url);
		},
		image: (data) => {
			let options = apiRegister.options(tokenRegister.get(), 'POST', data, true);
			let url = apiRegister.url.api + "/v3/adcredo/uploadImage?client=" + this.state.client.id;
			return apiRegister.call(options, url);
		},
		video_upload: (data, channel_id) => {
			let options = apiRegister.options(tokenRegister.get(), 'POST', data, true);
			let url = apiRegister.url.api + "/v3/adcredo/uploadVideo?client=" + this.state.client.id + "&directUpload=true&large=true" + "&channel=" + channel_id;
			return apiRegister.call(options, url);
		},
		getInstant: () => {
			let options = apiRegister.options(tokenRegister.get(), 'GET', null);
			let url = apiRegister.url.api + "/v3/facebook/getInstant?client=" + this.state.client.id + "&id=" + this.state.updated.instantExperience.id + "&page=" + this.renders.page().id;
			return apiRegister.call(options, url);
		},
		listLeadForms: (page) => {
			let options = apiRegister.options(tokenRegister.get(), 'GET', null);
			let url = apiRegister.url.api + "/v3/facebook/listLeadForms?client=" + this.state.client.id + "&page=" + page;
			return apiRegister.call(options, url);
		},
		update: (data, id) => {
			let options = apiRegister.options(tokenRegister.get(), 'POST', data);
			let url = apiRegister.url.api + "/v3/facebook/updateAdCreative?client=" + this.state.client.id;
			return apiRegister.call(options, url);
		},
		duplicate: (data, id) => {
			let options = apiRegister.options(tokenRegister.get(), 'POST', data);
			let url = apiRegister.url.api + "/v3/facebook/duplicateAd?client=" + this.state.client.id;
			return apiRegister.call(options, url);
		},
		facebookCatalogs: () => {
			let options = apiRegister.options(tokenRegister.get(), 'GET', null);
			let url = apiRegister.url.api + "/v3/facebook/listCatalogs?client=" + this.state.client.id;
			return apiRegister.call(options, url);
		},
		facebookProductSets: (catalog) => {
			let options = apiRegister.options(tokenRegister.get(), 'GET', null);
			let url = apiRegister.url.api + "/v3/facebook/listProductSets?client=" + this.state.client.id + "&id=" + catalog;
			return apiRegister.call(options, url);
		},
		facebookProducts: (product_set) => {
			let options = apiRegister.options(tokenRegister.get(), 'GET', null);
			let url = apiRegister.url.api + "/v3/facebook/listProducts?client=" + this.state.client.id + "&id=" + product_set;
			return apiRegister.call(options, url);
		},
		pages: () => {
			let options = apiRegister.options(tokenRegister.get(), 'GET', null);
			let url = apiRegister.url.api + "/v3/facebook/listPages?client=" + this.state.client.id;
			return apiRegister.call(options, url);
		},
		instagramAccounts: (page) => {
			let options = apiRegister.options(tokenRegister.get(), 'GET', null);
			let url = apiRegister.url.api + "/v3/facebook/listInstagramAccounts?client=" + this.state.client.id + "&page=" + page;
			return apiRegister.call(options, url);
		},
		instagramBusinessAccounts: () => {
			let options = apiRegister.options(tokenRegister.get(), 'GET', null);
			let url = apiRegister.url.api + "/v3/facebook/listBusinessInstagramAccounts?client=" + this.state.client.id;
			return apiRegister.call(options, url);
		},
		video: (id) => {
			let options = apiRegister.options(tokenRegister.get(), 'GET', null);
			let url = apiRegister.url.api + "/v3/facebook/getVideo?client=" + this.state.client.id + "&id=" + id;
			return apiRegister.call(options, url);
		},
		apps: () => {
			let options = apiRegister.options(tokenRegister.get(), 'GET', null);
			let url = apiRegister.url.api + "/v3/facebook/listApps?client=" + this.state.client.id;
			return apiRegister.call(options, url);
		},
		pixels: () => {
			let options = apiRegister.options(tokenRegister.get(), 'GET', null);
			let url = apiRegister.url.api + "/v3/facebook/listPixels?client=" + this.state.client.id;
			return apiRegister.call(options, url);
		},
		listInstantExpriences: () => {
			let options = apiRegister.options(tokenRegister.get(), 'GET', null);
			let url = apiRegister.url.api + "/v3/facebook/listInstantExpriences?client=" + this.state.client.id + "&page=" + this.state.updated.page.id;
			return apiRegister.call(options, url);
		},
		updateAd: (data, id) => {
			let options = apiRegister.options(tokenRegister.get(), 'POST', data);
			let url = apiRegister.url.api + "/v3/facebook/updateAd?client=" + this.state.client.id + "&id=" + id;
			return apiRegister.call(options, url);
		},
		createCreative: (data) => {
			let options = apiRegister.options(tokenRegister.get(), 'POST', data);
			let url = apiRegister.url.api + "/v3/facebook/createAdCreative?client=" + this.state.client.id;
			return apiRegister.call(options, url);
		}
	};

	promisedSetState = (newState) => {
		return new Promise((resolve) => {
			this.setState(newState, () => {
				resolve()
			});
		});
	}

	truncText(string, maxLength) {
		if (string.length > maxLength) {
			return string.substring(0, maxLength) + "..."
		}
		else {
			return string
		}
	}

	SortableItem = SortableElement(({ item, itemIndex }) => {
		return (
			<div
				style={{ zIndex: 99999999 }}
				className="cursor-pointer">
				{
					<>
						<div className={`bg-gray-100 p-4 rounded-md space-y-4 ${this.state.open_slide === itemIndex ? ' h-auto z-55 overflow-y-visible' : ' h-20 overflow-hidden'}`}>
							<div className='flex w-full justify-between'>
								<div className='flex'>
									<div className={"border-1.5 h-12 w-12 mr-2 flex justify-center cursor-pointer items-center rounded-md"}>
										{
											this.state.carousel &&
											<SwitchVerticalIcon className="h-5 w-5" />
										}
										{
											this.state.merge &&
											<div className="text-sm font-medium">
												{item.ratio ? item.ratio : "Unknown ratio"}
											</div>
										}
									</div>
									<div className='flex items-center'>
										<div className='flex items-center justify-center h-12 w-12 rounded-md mr-2'>
											{
												item.type === "image" &&
												<img src={item.url ? item.url : (item.picture ? item.picture : "")} className="w-full object-contain rounded-md"
													style={{ maxHeight: "48px" }}
												/>
											}
											{
												item.type === "video" &&
												<video src={item.url ? item.url : ""} autoPlay={false} muted={true} controls={false} className="rounded-md"
													style={{ maxHeight: "48px" }}
												/>
											}
										</div>
										<div className='flex flex-col'>
											<div className="text-sm font-medium">
												Slide {itemIndex + 1}
											</div>
											<div className="flex text-xs text-gray-600">
												{
													this.state.open_slide === itemIndex ?
														item.type === "image" ?
															<>
																<PhotographIcon className="h-4 w-4 mr-1" />
																Image
															</>
															:
															<>
																<FilmIcon className="h-4 w-4 mr-1" />
																Video
															</>
														:
														<div className='grid grid-cols-2 gap-4 text-xs text-gray-600'>
															<div className='col-span-1'>
																Headline: {item.title && typeof item.title === 'string' ? this.truncText(item.title, 15) : ''}
															</div>
															<div className='col-span-1'>
																Description: {item.body && typeof item.body === 'string' ? this.truncText(item.body, 15) : ''}
															</div>
														</div>
												}
											</div>
										</div>
									</div>
								</div>
								<div className="flex items-center justify-center space-x-4">
									{
										this.state.updated && this.state.updated.slides && Array.isArray(this.state.updated.slides) && this.state.updated.slides.length > 2 && false &&
										<div className='bg-red-200 p-2 rounded-md cursor-pointer'
											onClick={async () => {
												await this.promisedSetState({ delete_modal: true, delete_slide: item })
											}}
										>
											<TrashIcon className="h-5 w-5 text-red-500 cursor-pointer" />
										</div>
									}
									<div
										onClick={async () => await this.promisedSetState({ open_slide: this.state.open_slide === itemIndex ? null : itemIndex })}
										className={`bg-gray-200 p-2 cursor-pointer flex rounded font-bold justify-center align-middle items-center`}>
										<ChevronUpIcon className={`h-5 w-5text-grey-600 transform rotate-0 transition-all duration-200 ${this.state.open_slide === itemIndex && "rotate-180"}`} />
									</div >
								</div>
							</div>
							{/*
							<div className='flex w-full space-x-4'>
								<div className='w-full'>
									<DropdownTailwind
										label={"Select other asset"}
										loader={this.state.loader}
										searchInput={true}
										locked={(this.state.updated && this.state.updated.is_post) || !this.state.wizard}
										selected={this.state.assetItems && this.state.assetItems.filter((item) => { return item.id === this.state.selectedAsset }).length > 0 ? this.state.assetItems.filter((item) => { return item.id === this.state.selectedAsset })[0] : { id: 0, name: "Select an asset" }}
										options={this.state.assetItems && this.state.assetItems.filter((item) => {
											if (this.state.updated && this.state.updated.ad_type && (this.state.updated.ad_type.value === "carousel_ad" || this.state.updated.ad_type === "carousel_ad")) {
												return item.type === "image"
											} else if (this.state.updated && this.state.updated.ad_type && (this.state.updated.ad_type.value === "carousel_ad" || this.state.updated.ad_type === "carousel_ad")) {
												return item.type === "video"
											} else return item
										}).map((item) => {
											if (this.state.updated && this.state.updated.ad_type && (this.state.updated.ad_type.value === "carousel_ad" || this.state.updated.ad_type === "carousel_ad")) {
												return { id: item.id, name: item.name, image: item.url, assetId: item.id, url: item.url }
											} else if (this.state.updated && this.state.updated.ad_type && (this.state.updated.ad_type.value === "carousel_ad" || this.state.updated.ad_type === "carousel_ad")) {
												return { id: item.id, name: item.name, video: item.url, assetId: item.id, url: item.url }
											} else if (this.state.updated && this.state.updated.collection_ad) {
												return { id: item.id, name: item.name, assetId: item.id, url: item.url, ...(item.type === "video" && { video: item.url }), ...(item.type === "image" && { image: item.url }) };
											}
										}).filter((item) => { return item })}
										onChange={async (value) => {
											if (this.state.updated && this.state.updated.slides && Array.isArray(this.state.updated.slides)) {
												this.state.updated.slides[itemIndex] = { ...this.state.updated.slides[itemIndex], ...value };
											}
											// this.state.updated.slides = this.state.updated.files;
											await this.promisedSetState({
												updated: this.state.updated,
												selectedAsset: value.id
											});
											this.functions.updatePreview();
										}}
									/>
								</div>
								<div key={this.state.theInputKeyOne || ''} className="w-1/3 pt-5.5">
									<div className="flex items-center justify-center h-12 relative w-full bg-custom-input rounded-md text-left focus:outline-none sm:text-sm hover:border-gray-500 border-gray-300 border-2 border-dashed">
										<input
											onChange={(e) => this.functions.onSelectFile(e, item)}
											type="file"
											accept={(this.state.updated && this.state.updated.ad_type && this.state.updated.ad_type.value === "image_ad" ? "image/png, image/jpg, image/jpeg, image/gif" : (this.state.updated && this.state.updated.ad_type && this.state.updated.ad_type.value === "video_ad" ? "video/*" : "image/png, image/jpg, image/jpeg, image/gif, video/*"))}
											className="absolute z-50 top-0 left-0 w-full h-full cursor-pointer opacity-0"
										/>
										{
											this.state.loader &&
											<div className="bg-white z-50 bg-opacity-75 absolute right-0 left-0 top-0 bottom-0 flex flex-row justify-center items-center">
												<div style={{ borderTopColor: "transparent" }}
													class="w-4 h-4 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
												<div className="ml-2 text-xs">
													Loading ...
												</div>
											</div>
										}
										{
											!this.state.loader &&
											<div className="text-xs text-gray-600">
												<span className="relative cursor-pointer whitespace-no-wrap bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">Upload a file</span>
											</div>
										}
									</div>
								</div>
							</div>
							*/}
							<div className="w-full">
								<InputTailwind
									label={"Website"}
									value={item.link ? item.link : ""}
									locked={true}
									onChange={async (value) => {

									}}
								/>
							</div>
							{
								(!item.link || !item.link.includes("https://")) &&
								<div className="w-full mt-2 p-4 rounded-md font-medium text-sm text-orange-500 bg-orange-100 items-center inline-flex">
									OBS! Prefix https:// is required.
								</div>
							}
							<div className="flex space-x-4 w-full">
								<div className='w-full'>
									<InputTailwind
										label={"Headline"}
										value={item.title ? item.title : ""}
										locked={true}
										// error={this.state.utm_channels.filter((item) => { return item === "facebook" }).length > 0 && (!item.title || item.title === "") ? true : false}
										onChange={async (value) => {

										}}
									/>
								</div>
								{
									<div className="w-full">
										<InputTailwind
											label={"Description"}
											value={item.body ? item.body : ""}
											locked={true}
											onChange={async (value) => {

											}}
										/>
									</div>
								}
							</div>
						</div>

					</>
				}
			</div>
		)
	});

	SortableList = SortableContainer(({ items }) => (
		<div className="relative space-y-4">
			{items.map((item, index) => {
				if (item) {
					return (
						<div>
							{
								<>
									<div className={`bg-gray-100 p-4 rounded-md space-y-4 ${this.state.open_slide === index ? ' h-auto z-55 overflow-y-visible' : ' h-20 overflow-hidden'}`}>
										<div className='flex w-full justify-between'>
											<div className='flex'>
												<div className='flex items-center'>
													<div className='flex items-center justify-center h-12 w-12 rounded-md mr-2'>
														{
															item.type === "image" &&
															<img src={item.url ? item.url : (item.picture ? item.picture : "")} className="w-full object-contain rounded-md"
																style={{ maxHeight: "48px" }}
															/>
														}
														{
															item.type === "video" &&
															<video src={item.url ? item.url : ""} autoPlay={false} muted={true} controls={false} className="rounded-md"
																style={{ maxHeight: "48px" }}
															/>
														}
													</div>
													<div className='flex flex-col'>
														<div className="text-sm font-medium">
															Slide {index + 1}
														</div>
														<div className="flex text-xs text-gray-600">
															{
																this.state.open_slide === index ?
																	item.type === "image" ?
																		<>
																			<PhotographIcon className="h-4 w-4 mr-1" />
																			Image
																		</>
																		:
																		<>
																			<FilmIcon className="h-4 w-4 mr-1" />
																			Video
																		</>
																	:
																	<div className='grid grid-cols-2 gap-4 text-xs text-gray-600'>
																		<div className='col-span-1'>
																			Headline: {item.title && typeof item.title === 'string' ? this.truncText(item.title, 15) : ''}
																		</div>
																		<div className='col-span-1'>
																			Description: {item.body && typeof item.body === 'string' ? this.truncText(item.body, 15) : ''}
																		</div>
																	</div>
															}
														</div>
													</div>
												</div>
											</div>
											<div className="flex items-center justify-center space-x-4">
												{
													this.state.updated && this.state.updated.slides && Array.isArray(this.state.updated.slides) && this.state.updated.slides.length > 2 && false &&
													<div className='bg-red-200 p-2 rounded-md cursor-pointer'
														onClick={async () => {
															await this.promisedSetState({ delete_modal: true, delete_slide: item })
														}}
													>
														<TrashIcon className="h-5 w-5 text-red-500 cursor-pointer" />
													</div>
												}
												<div
													onClick={async () => await this.promisedSetState({ open_slide: this.state.open_slide === index ? null : index })}
													className={`bg-gray-200 p-2 cursor-pointer flex rounded font-bold justify-center align-middle items-center`}>
													<ChevronUpIcon className={`h-5 w-5text-grey-600 transform rotate-0 transition-all duration-200 ${this.state.open_slide === index && "rotate-180"}`} />
												</div >
											</div>
										</div>
										{
											<div className='flex w-full space-x-4'>
												<div className='w-full'>
													<DropdownTailwind
														label={"Select other asset"}
														loader={this.state.loader}
														searchInput={true}
														locked={false}
														selected={{ id: 0, name: "Select an asset" }}
														options={this.state.assetItems.filter((inner_item) => { return inner_item.type === item.type }).map((inner_item) => {
															if (item.type === "image") {
																return { id: inner_item.id, name: inner_item.name, assetId: inner_item.id, url: inner_item.url, image: inner_item.url };
															} else if (item.type === "video") {
																return { id: inner_item.id, name: inner_item.name, assetId: inner_item.id, url: inner_item.url, video: inner_item.url };
															} else {
																return { id: inner_item.id, name: inner_item.name, assetId: inner_item.id, url: inner_item.url, ...(inner_item.type === "video" && { video: inner_item.url }), ...(inner_item.type === "image" && { inner_item: item.url }) };
															}
														}).filter((item) => { return item })}
														onChange={async (value) => {
															if (this.state.ad.creative.ad_type && this.state.ad.creative.ad_type === "carousel_feed") {
																if (!this.state.updated.slides) {
																	this.state.updated.slides = this.state.ad.origin_data.creative.object_story_spec.link_data.child_attachments;
																}
																this.state.updated.slides = JSON.parse(JSON.stringify(this.state.updated.slides)).map((slide) => {
																	if (slide.image_hash || (slide.picture && !slide.video_id)) {
																		if ((slide.image_hash && slide.image_hash === item.hash) || (slide.picture && slide.picture === item.picture)) {
																			slide.file = value;
																		}
																	} else if (slide.video_id) {
																		if (slide.video_id === item.video_id) {
																			slide.file = value;
																		}
																	}
																	return slide;
																});
																this.state.ad.creative.slides = JSON.parse(JSON.stringify(this.state.ad.creative.slides)).map((slide) => {
																	if (slide.hash || (slide.picture && !slide.video_id)) {
																		if ((slide.hash && slide.hash === item.hash) || (slide.picture && slide.picture === item.picture)) {
																			slide.picture = value.url;
																		}
																	} else if (slide.video_id) {
																		if (slide.video_id === item.video_id) {
																			slide.url = value.url;
																		}
																	}
																	return slide;
																});
																await this.promisedSetState({
																	updated: this.state.updated,
																	ad: this.state.ad
																})
															}
															this.functions.updatePreview();
														}}
													/>
												</div>
												<div key={this.state.theInputKeyOne || ''} className="w-1/3 pt-5.5">
													<div className="flex items-center justify-center h-12 relative w-full bg-custom-input rounded-md text-left focus:outline-none sm:text-sm hover:border-gray-500 border-gray-300 border-2 border-dashed">
														<input
															onChange={(e) => this.functions.onSelectFile(e, item)}
															type="file"
															accept={(item.type === "image" ? "image/png, image/jpg, image/jpeg, image/gif" : (item.type === "video" ? "video/*" : "image/png, image/jpg, image/jpeg, image/gif, video/*"))}
															className="absolute z-50 top-0 left-0 w-full h-full cursor-pointer opacity-0"
														/>
														{
															this.state.loader &&
															<div className="bg-white z-50 bg-opacity-75 absolute right-0 left-0 top-0 bottom-0 flex flex-row justify-center items-center">
																<div style={{ borderTopColor: "transparent" }}
																	class="w-4 h-4 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
																<div className="ml-2 text-xs">
																	Loading ...
																</div>
															</div>
														}
														{
															!this.state.loader &&
															<div className="text-xs text-gray-600">
																<span className="relative cursor-pointer whitespace-no-wrap bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">Upload a file</span>
															</div>
														}
													</div>
												</div>
											</div>
										}
										<div className="w-full">
											<InputTailwind
												label={"Website"}
												value={item.link ? item.link : ""}
												locked={this.renders.updateCheck() && this.state.ad.creative.ad_type && this.state.ad.creative.ad_type === "carousel_feed" ? false : true}
												disabled={this.renders.updateCheck() && this.state.ad.creative.ad_type && this.state.ad.creative.ad_type === "carousel_feed" ? false : true}
												onChange={async (value) => {
													if (this.state.ad.creative.ad_type && this.state.ad.creative.ad_type === "carousel_feed") {
														if (!this.state.updated.slides) {
															this.state.updated.slides = this.state.ad.origin_data.creative.object_story_spec.link_data.child_attachments;
														}
														this.state.updated.slides = JSON.parse(JSON.stringify(this.state.updated.slides)).map((slide) => {
															if ((slide.image_hash || slide.picture) && !slide.video_id) {
																if ((slide.image_hash && slide.image_hash === item.hash) || (slide.picture && slide.picture === item.picture)) {
																	slide.link = value;
																	if (slide.call_to_action && slide.call_to_action.value && slide.call_to_action.value.link) {
																		slide.call_to_action.value.link = value;
																	}

																}
															} else if (slide.video_id) {
																if (slide.video_id === item.video_id) {
																	slide.link = value;
																	if (slide.call_to_action && slide.call_to_action.value && slide.call_to_action.value.link) {
																		slide.call_to_action.value.link = value;
																	}
																}
															}
															return slide;
														});
														this.state.ad.creative.slides = JSON.parse(JSON.stringify(this.state.ad.creative.slides)).map((slide) => {
															if (slide.hash || (slide.picture && !slide.video_id)) {
																if ((slide.hash && slide.hash === item.hash) || (slide.picture && slide.picture === item.picture)) {
																	slide.link = value;
																}
															} else if (slide.video_id) {
																if (slide.video_id === item.video_id) {
																	slide.link = value;
																}
															}
															return slide;
														});
														await this.promisedSetState({
															updated: this.state.updated,
															ad: this.state.ad
														})
														this.functions.updatePreview();
													}
												}}
											/>
										</div>
										{
											(!item.link || !item.link.includes("https://")) &&
											<div className="w-full mt-2 p-4 rounded-md font-medium text-sm text-orange-500 bg-orange-100 items-center inline-flex">
												OBS! Prefix https:// is required.
											</div>
										}
										<div className="flex space-x-4 w-full">
											<div className='w-full'>
												<InputTailwind
													label={"Headline"}
													value={item.title ? item.title : ""}
													locked={this.renders.updateCheck() && this.state.ad.creative.ad_type && this.state.ad.creative.ad_type === "carousel_feed" ? false : true}
													disabled={this.renders.updateCheck() && this.state.ad.creative.ad_type && this.state.ad.creative.ad_type === "carousel_feed" ? false : true}
													// error={this.state.utm_channels.filter((item) => { return item === "facebook" }).length > 0 && (!item.title || item.title === "") ? true : false}
													onChange={async (value) => {
														if (this.state.ad.creative.ad_type && this.state.ad.creative.ad_type === "carousel_feed") {
															if (!this.state.updated.slides) {
																this.state.updated.slides = this.state.ad.origin_data.creative.object_story_spec.link_data.child_attachments;
															}
															this.state.updated.slides = JSON.parse(JSON.stringify(this.state.updated.slides)).map((slide) => {
																if ((slide.image_hash || slide.picture) && !slide.video_id) {
																	if ((slide.image_hash && slide.image_hash === item.hash) || (slide.picture && slide.picture === item.picture)) {
																		slide.name = value;
																	}
																} else if (slide.video_id) {
																	if (slide.video_id === item.video_id) {
																		slide.name = value;
																	}
																}
																return slide;
															});
															this.state.ad.creative.slides = JSON.parse(JSON.stringify(this.state.ad.creative.slides)).map((slide) => {
																if (slide.hash || (slide.picture && !slide.video_id)) {
																	if ((slide.hash && slide.hash === item.hash) || (slide.picture && slide.picture === item.picture)) {
																		slide.title = value;
																	}
																} else if (slide.video_id) {
																	if (slide.video_id === item.video_id) {
																		slide.title = value;
																	}
																}
																return slide;
															});
															await this.promisedSetState({
																updated: this.state.updated,
																ad: this.state.ad
															})
															this.functions.updatePreview();
														}
													}}
												/>
											</div>
											{
												<div className="w-full">
													<InputTailwind
														label={"Description"}
														value={item.body ? item.body : ""}
														locked={this.renders.updateCheck() && this.state.ad.creative.ad_type && this.state.ad.creative.ad_type === "carousel_feed" ? false : true}
														disabled={this.renders.updateCheck() && this.state.ad.creative.ad_type && this.state.ad.creative.ad_type === "carousel_feed" ? false : true}
														onChange={async (value) => {
															if (this.state.ad.creative.ad_type && this.state.ad.creative.ad_type === "carousel_feed") {
																if (!this.state.updated.slides) {
																	this.state.updated.slides = this.state.ad.origin_data.creative.object_story_spec.link_data.child_attachments;
																}
																this.state.updated.slides = JSON.parse(JSON.stringify(this.state.updated.slides)).map((slide) => {
																	if ((slide.image_hash || slide.picture) && !slide.video_id) {
																		if ((slide.image_hash && slide.image_hash === item.hash) || (slide.picture && slide.picture === item.picture)) {
																			slide.description = value;
																		}
																	} else if (slide.video_id) {
																		if (slide.video_id === item.video_id) {
																			slide.description = value;
																		}
																	}
																	return slide;
																});
																this.state.ad.creative.slides = JSON.parse(JSON.stringify(this.state.ad.creative.slides)).map((slide) => {
																	if (slide.hash || (slide.picture && !slide.video_id)) {
																		if ((slide.hash && slide.hash === item.hash) || (slide.picture && slide.picture === item.picture)) {
																			slide.body = value;
																		}
																	} else if (slide.video_id) {
																		if (slide.video_id === item.video_id) {
																			slide.body = value;
																		}
																	}
																	return slide;
																});
																await this.promisedSetState({
																	updated: this.state.updated,
																	ad: this.state.ad
																})
																this.functions.updatePreview();
															}
														}}
													/>
												</div>
											}
										</div>
									</div>

								</>
							}
						</div>
					)
					/*
					return (
						<this.SortableItem
							index={index}
							itemIndex={index}
							item={item}
						/>
					)*/
				}
			})}
		</div>
	));

	onSortEnd = async ({ oldIndex, newIndex }) => {
		await this.promisedSetState({
			array: arrayMove((this.state.updated.slides ? this.state.updated.slides : []), oldIndex, newIndex),
		})
		if (this.state.updated.slides) {
			this.state.updated.slides = this.state.array;
			// this.state.updated.files = this.state.array;
			await this.promisedSetState({
				updated: this.state.updated
			})
			this.functions.updatePreview()
		}
	};

	renderSortableList(items) {
		return (
			<div className="relative space-y-4">
				<this.SortableList
					items={items}
					//onSortEnd={this.onSortEnd}
					axis="y"
					distance={1}
				/>
			</div>
		)
	}

	render() {

		function classNames(...classes) {
			return classes.filter(Boolean).join(' ')
		}

		return (
			<>
				<WarningModalTailwind
					open={this.state.delete_modal ? true : false}
					title={"Delete slide"}
					description={'Are you sure you want to delete this slide ? This action cannot be undone.'}
					cancelButtonText={"Cancel"}
					submitButtonText={"Delete"}
					onClose={async (value) => {
						await this.promisedSetState({ delete_modal: false });
					}}
					onSubmit={() => {
						this.functions.deleteSlide(this.state.delete_slide);
					}}
				/>

				{/* SECOND REMOVED CODE GOES HERE */}


				<div className='flex flex-col px-4 space-y-4 bg-gray-50'>
					{
						(this.state.carousel || this.state.fb_lead_form) &&
						<div className="block w-full">
							<div className="border-gray-200 w-full">
								<nav className="-mb-px flex space-x-4" aria-label="Tabs">
									{
										["Main ad", "Slides information", "Lead Form"].map((item) => {
											if (item === "Lead Form" && !this.state.fb_lead_form) {
												return null;
											} else if (item === "Slides information" && !this.state.carousel) {
												return null;
											} else return item
										}).filter((item) => { return item }).map((item) => (
											<div
												onClick={async () => {
													await this.promisedSetState({
														carousel_menu: item
													})
												}}
												key={item}
												className={classNames(
													item === this.state.carousel_menu
														? 'border-purple-500 text-purple-600'
														: ('border-transparent text-gray-700 hover:text-gray-900 hover:border-gray-500 border-gray-400 cursor-pointer'),
													'whitespace-nowrap py-4 px-4 border-b-2 cursor-pointer font-medium text-sm bg-gray-50 border-t-1.5 border-r-1.5 border-l-1.5 rounded-md'
												)}
												aria-current={item === this.state.carousel_menu ? 'page' : undefined}
											>
												{item}
											</div>
										))
									}
								</nav>
							</div>
						</div>
					}

					{
						this.state.error &&
						<div className="flex w-full bg-red-100 rounded-md p-4 text-sm font-medium text-red-500">
							{typeof this.state.error === "string" ? this.state.error : "Something goes wrong"}
						</div>
					}
					{

						this.renders.correctType() && typeof this.renders.correctType() === 'string' && ["unknown", "Unsupported"].includes(this.renders.correctType()) &&
						<div className="flex w-full bg-purple-100 rounded-md p-4 text-sm font-medium text-purple-500">
							{"This format is unsupported. Open external link to edit ad."}
						</div>
					}
					{

						this.state.ad.creative && this.state.ad.origin_data && !this.state.ad.creative.is_post && this.state.ad.origin_data.creative.object_story_spec &&
						(this.state.ad.creative_type === "image" || this.state.ad.creative_type === "video") && !this.state.ad.creative.ad_type &&
						<div className="flex flex-row items-center space-x-4">
							<div className='w-full'>
								<DropdownTailwind
									label={"Select other asset"}
									loader={this.state.loader}
									selected={this.state.assetItems && this.state.assetItems.filter((item) => { return item.id === this.state.selectedAsset }).length > 0 ? this.state.assetItems.filter((item) => { return item.id === this.state.selectedAsset })[0] : { id: 0, name: "Select an asset" }}
									options={this.state.assetItems && this.state.assetItems.filter((item) => {
										if (this.state.ad.creative_type === "image") {
											return item.type === "image"
										} else if (this.state.ad.creative_type === "video") {
											return item.type === "video"
										} else return item
									}).map((item) => {
										if (this.state.ad.creative_type === "image") {
											return { id: item.id, name: item.name, image: item.url, assetId: item.id, url: item.url }
										} else if (this.state.ad.creative_type === "video") {
											return { id: item.id, name: item.name, video: item.url, assetId: item.id, url: item.url }
										} else {
											return { id: item.id, name: item.name, assetId: item.id, url: item.url, ...(item.type === "video" && { video: item.url }), ...(item.type === "image" && { image: item.url }) };
										}
									}).filter((item) => { return item })}
									onChange={async (value) => {
										this.state.updated.file = { ...this.state.updated.file, ...value };
										await this.promisedSetState({
											updated: this.state.updated,
											selectedAsset: value.id
										});
										this.functions.updatePreview();
									}}
								/>
							</div>
							{
								!this.state.loader &&
								<div key={this.state.theInputKeyOne || ''} className="w-1/3 pt-5.5">
									<div className="flex items-center justify-center h-12 relative w-full bg-custom-input rounded-md text-left focus:outline-none sm:text-sm hover:border-gray-500 border-gray-300 border-2 border-dashed">
										<input
											onChange={(e) => this.functions.onSelectFile(e)}
											type="file"
											accept={(this.state.ad.creative_type === "image" ? "image/png, image/jpg, image/jpeg, image/gif" : (this.state.ad.creative_type === "video" ? "video/*" : "image/png, image/jpg, image/jpeg, image/gif, video/*"))}
											className="absolute z-55 top-0 left-0 w-full h-full cursor-pointer opacity-0"
										/>
										{
											this.state.loader &&
											<div className="bg-white z-50 bg-opacity-75 absolute right-0 left-0 top-0 bottom-0 flex flex-row justify-center items-center">
												<div style={{ borderTopColor: "transparent" }}
													class="w-4 h-4 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
												<div className="ml-2 text-xs">
													Loading ...
												</div>
											</div>
										}
										{
											!this.state.loader &&
											<div className="text-xs text-gray-600">
												<span className="relative cursor-pointer whitespace-no-wrap bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">Upload a file</span>
											</div>
										}
									</div>
								</div>
							}
						</div>
					}


					{
						this.state.merge && this.state.merge.error &&
						<div className="col-span-12 py-5 text-sm px-5 text-center items-center border-red-600 border-2 bg-red-200 rounded-lg">
							{this.state.merge.message}
						</div>
					}



					{
						this.state.carousel_menu === "Slides information" &&
						<div className="flex flex-col space-y-4">

							{
								this.state.ad.creative && this.state.ad.creative && Array.isArray(this.state.ad.creative.slides) && this.state.ad.creative.slides.length > 0 &&
								this.renderSortableList(this.state.ad.creative.slides)
							}
							{/* {
									this.state.merge &&
									<div>
										<SortableList
											items={this.state.merge.files}
											onSortEnd={onSortEnd}
											distance={1}
										/>
									</div>
								} */}

							{/* REMOVED CODE GOES HERE */}
						</div>
					}

					{
						(this.state.carousel_menu === "Lead Form") && false &&
						<div className="w-full space-y-4">
							{
								this.state.updated && this.state.updated.page && this.state.updated.page.id === 0 && this.state.fb_lead_form &&
								<div className="col-span-6 bg-orange-100 rounded-md p-4 text-sm font-medium text-orange-500">
									OBS! Choose a Facebook page to create a lead form
								</div>
							}
							<div className='w-full'>
								<div className="w-full">
									<DropdownTailwind
										channel="facebook"
										locked={this.state.updated && this.state.updated.page && this.state.updated.page.id === 0 && this.state.fb_lead_form ? true : false}
										disabled={this.state.updated && this.state.updated.page && this.state.updated.page.id === 0 && this.state.fb_lead_form ? true : false}
										label={"Lead forms"}
										loader={this.state.loading_leadforms}
										selected={this.state.updated.selected_lead ? this.state.updated.selected_lead : { id: 0, name: "Select a lead form" }}
										options={this.state.lead_forms.length > 0 ? this.state.lead_forms : [{ id: 0, name: "No lead forms available" }]}
										onChange={async (item) => {
											this.state.updated.selected_lead = item;
											if (this.state.updated && this.state.updated.cta && this.state.updated.cta.id && this.state.updated.cta.id !== 0 && [{ name: "Subscribe", id: "SUBSCRIBE", type: "SUBSCRIBE", objective: [1, 2, 3, 4, 5, 6] },
											{ name: "Apply Now", id: "APPLY_NOW", type: "APPLY_NOW", objective: [1, 2, 3, 4, 6] },
											{ name: "Book now", id: "BOOK_TRAVEL", type: "BOOK_TRAVEL", objective: [] },
											{ name: "Download", id: "DOWNLOAD", type: "DOWNLOAD", objective: [] },
											{ name: "Get offer", id: "GET_OFFER", type: "GET_OFFER", objective: [2, 3, 4, 6] },
											{ name: "Get quote", id: "GET_QUOTE", type: "GET_QUOTE", objective: [1, 2, 3, 6] },
											{ name: "Learn more", id: "LEARN_MORE", type: "LEARN_MORE", objective: [1, 2, 3, 4, 5, 6] },
											{ name: "Sign up", id: "SIGN_UP", type: "SIGN_UP", objective: [1, 2, 3, 4, 5, 6] }].filter((item) => { return item.id === this.state.updated.cta.id }).length < 1) {
												this.state.updated.cta = { id: 0, name: "Click here ..." };
											}
											this.state.updated.link = "";

											await this.promisedSetState({
												updated: this.state.updated
											});
										}}
									/>
								</div>
							</div>
							<div className="">
								<button
									onClick={async () => {
										if (this.state.updated && this.state.updated.page && this.state.updated.page.id === 0 && this.state.fb_lead_form) {
											// SOMETHING HERE ?
										} else {
											await this.promisedSetState({
												loading_new_lead: true
											});
											this.functions.leadForm();
										}
									}}
									className={(this.state.updated && this.state.updated.page && this.state.updated.page.id === 0 && this.state.fb_lead_form ? "bg-gray-500 hover:bg-gray-600 text-white cursor-not-allowed" : "bg-purple-500 hover:bg-purple-600 text-white") + "  py-3 px-4 text-sm font-medium focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 inline-flex shadow relative justify-center rounded-md border border-transparent "}
								>
									Create new
									{
										this.state.loading_new_lead &&
										<div className="w-full h-full absolute inset-0 bg-purple-500 flex justify-center items-center z-20 rounded-lg">
											<div style={{ borderTopColor: "transparent" }} class="w-4 h-4 border-2 border-white border-solid rounded-full animate-spin"></div>
										</div>
									}
								</button>
							</div>

						</div>
					}

					{
						this.state.updated && this.state.updated.collection_ad && false &&
						<>
							<div className='flex space-x-4'>
								{
									["Main ad", "Instant Experience"].map((item) => {
										return (
											<div
												onClick={async () => {
													await this.promisedSetState({
														carousel_menu: item
													})
												}}
												className={classNames(
													item === this.state.carousel_menu
														? 'border-purple-500 text-purple-600'
														: ('border-transparent text-gray-700 hover:text-gray-900 hover:border-gray-500 border-gray-400 cursor-pointer'),
													'whitespace-nowrap py-4 px-4 border-b-2 cursor-pointer font-medium text-sm bg-gray-50 border-t-1.5 border-r-1.5 border-l-1.5 rounded-md')}
											>
												{item}
											</div>
										)
									})
								}
							</div>
							{
								this.state.carousel_menu === "Instant Experience" &&
								<>
									<div className='w-full'>
										<DropdownTailwind
											channel="facebook"
											label={"Instant Experience"}
											locked={this.state.updated && this.state.updated && this.state.updated.page ? this.state.updated.page.id === 0 ? true : false : true}
											disabled={this.state.updated && this.state.updated && this.state.updated.page ? this.state.updated.page.id === 0 ? true : false : true}
											placeholder={"Click to search ..."}
											searchInput={true}
											selected={this.renders.instantExperience()}
											options={this.state.instant_options}
											onChange={async (instant) => {
												this.state.instantExperience = instant;
												this.state.updated.instantExperience = instant;
												await this.promisedSetState({
													updated: this.state.updated, instantExperience: this.state.instantExperience
												})
												this.functions.getInstant();
											}}
										/>
									</div>
									{/* <div className='flex space-x-4'>
										<div className='w-full'>
											<DropdownTailwind
												label={"Facebook catalogs"}
												loader={this.state.loading_fb_catalogs}
												locked={!this.state.wizard}
												selected={this.renders.collection_catalog(this.state.ad)}
												options={this.state.wizard ? this.state.collection_catalogs : []}
												onChange={async (value) => {
													this.state.updated.collection_catalog = value;
													await this.promisedSetState({
														updated: this.state.updated
													});
													this.functions.getFacebookProductSets();

												}}
											/>
										</div>
										<div className="w-full">
											<DropdownTailwind
												error={(this.renders.product_set(this.state.ad).id !== 0 && Array.isArray(this.state.updated.preview_products) && this.state.updated.preview_products.length < 4 ? true : false)}
												label={"Facebook product sets"}
												searchInput={true}
												placeholder={"Click to search ..."}
												loader={this.state.loading_fb_productsets}
												locked={false}
												selected={this.renders.product_set(this.state.ad)}
												options={this.state.wizard ? this.state.product_sets : []}
												onChange={async (value) => {
													this.state.updated.product_set = value;
													await this.promisedSetState({
														updated: this.state.updated
													});
													this.functions.getFacebookProducts(this.state.updated.product_set.id);
												}}
											/>
										</div>
									</div>
									{
										this.renders.product_set(this.state.ad).id !== 0 && Array.isArray(this.state.updated.preview_products) && this.state.updated.preview_products.length < 4 &&
										<div className="bg-red-100 rounded-md col-span-6 p-4 text-sm font-medium text-red-500">
											Insufficient products in product set for collection
										</div>
									} */}
									<div className='space-y-4'>
										<div className="col-span-4 text-lg font-semibold">
											New Instant Experience
										</div>
										<div className='w-full'>
											<DropdownTailwind
												channel="facebook"
												label={"Select type"}
												locked={this.state.updated && this.state.updated && this.state.updated.page ? this.state.updated.page.id === 0 ? true : false : true}
												disabled={this.state.updated && this.state.updated && this.state.updated.page ? this.state.updated.page.id === 0 ? true : false : true}
												selected={this.state.instant_type}
												options={this.state.instant_types}
												onChange={async (instant) => {
													this.state.instant_type = instant;
													this.state.updated.instant_type = instant;
													await this.promisedSetState({
														updated: this.state.updated, instant_type: this.state.instant_type
													})
													this.functions.updatePreview();
												}}
											/>
										</div>
										<button
											onClick={async () => {
												if (this.state.updated.page.id !== 0 && this.state.instant_type.id !== 0) {
													await this.promisedSetState({
														loading_instant: true
													});
													this.functions.instantExperience();
												}
											}}
											className={(this.state.updated.page.id === 0 || this.state.instant_type.id === 0 ? "bg-gray-500 hover:bg-gray-600 text-white cursor-not-allowed" : "bg-purple-500 hover:bg-purple-600 text-white") + " py-3 px-4 text-sm font-medium focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 inline-flex shadow relative justify-center rounded-md border border-transparent"}
										>
											Create new
											{
												this.state.loading_instant &&
												<div className="w-full h-full absolute inset-0 bg-purple-500 flex justify-center items-center z-20 rounded-lg">
													<div style={{ borderTopColor: "transparent" }} class="w-4 h-4 border-2 border-white border-solid rounded-full animate-spin"></div>
												</div>
											}
										</button>
									</div>
								</>
							}
						</>
					}

					{
						this.state.carousel_menu === "Main ad" &&
						<>
							{
								this.state.fb_lead_form && false &&
								<div className="flex flex-row items-center space-x-4">
									<div className='w-full'>
										<DropdownTailwind
											label={"Select other asset"}
											loader={this.state.loader}
											locked={(this.state.updated && this.state.updated.is_post) || !this.state.wizard}
											selected={this.state.assetItems && this.state.assetItems.filter((item) => { return item.id === this.state.selectedAsset }).length > 0 ? this.state.assetItems.filter((item) => { return item.id === this.state.selectedAsset })[0] : { id: 0, name: "Select an asset" }}
											options={this.state.assetItems && this.state.assetItems.filter((item) => {
												if (this.state.updated && this.state.updated.ad_type && (this.state.updated.ad_type.value === "image_ad" || this.state.updated.ad_type === "image")) {
													return item.type === "image"
												} else if (this.state.updated && this.state.updated.ad_type && (this.state.updated.ad_type.value === "video_ad" || this.state.updated.ad_type === "video")) {
													return item.type === "video"
												} else return item
											}).map((item) => {
												if (this.state.updated && this.state.updated.ad_type && (this.state.updated.ad_type.value === "image_ad" || this.state.updated.ad_type === "image")) {
													return { id: item.id, name: item.name, image: item.url, assetId: item.id, url: item.url }
												} else if (this.state.updated && this.state.updated.ad_type && (this.state.updated.ad_type.value === "video_ad" || this.state.updated.ad_type === "video")) {
													return { id: item.id, name: item.name, video: item.url, assetId: item.id, url: item.url }
												} else if (this.state.updated && this.state.updated.collection_ad) {
													return { id: item.id, name: item.name, assetId: item.id, url: item.url, ...(item.type === "video" && { video: item.url }), ...(item.type === "image" && { image: item.url }) };
												}
											}).filter((item) => { return item })}
											onChange={async (value) => {
												this.state.updated.file = { ...this.state.updated.file, ...value };
												if (value.image) {
													delete this.state.updated.video;
													this.state.updated.picture = value.image;
													this.state.updated.type = "image";
												} else if (value.video) {
													delete this.state.updated.picture;
													this.state.updated.video = value.video;
													this.state.updated.type = "video";
												}

												await this.promisedSetState({
													updated: this.state.updated,
													selectedAsset: value.id
												});
												this.functions.updatePreview();
											}}
										/>
									</div>
									{
										!this.state.loader &&
										<div key={this.state.theInputKeyOne || ''} className="w-1/3 pt-5.5">
											<div className="flex items-center justify-center h-12 relative w-full bg-custom-input rounded-md text-left focus:outline-none sm:text-sm hover:border-gray-500 border-gray-300 border-2 border-dashed">
												<input
													onChange={(e) => this.functions.onSelectFile(e)}
													type="file"
													accept={(this.state.updated && this.state.updated.ad_type && this.state.updated.ad_type.value === "image_ad" ? "image/png, image/jpg, image/jpeg, image/gif" : (this.state.updated && this.state.updated.ad_type && this.state.updated.ad_type.value === "video_ad" ? "video/*" : "image/png, image/jpg, image/jpeg, image/gif, video/*"))}
													className="absolute z-55 top-0 left-0 w-full h-full cursor-pointer opacity-0"
												/>
												{
													this.state.loader &&
													<div className="bg-white z-50 bg-opacity-75 absolute right-0 left-0 top-0 bottom-0 flex flex-row justify-center items-center">
														<div style={{ borderTopColor: "transparent" }}
															class="w-4 h-4 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
														<div className="ml-2 text-xs">
															Loading ...
														</div>
													</div>
												}
												{
													!this.state.loader &&
													<div className="text-xs text-gray-600">
														<span className="relative cursor-pointer whitespace-no-wrap bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">Upload a file</span>
													</div>
												}
											</div>
										</div>
									}
								</div>
							}

							<div className="flex w-full space-x-4">
								<div className='w-full'>
									<InputTailwind
										label={"Name"}
										channel="facebook"
										locked={!this.state.ad.origin_data && this.state.ad.creative_type === "unknown" ? true : false}
										value={this.renders.name()}
										onChange={async (value) => {
											this.state.updated.name = value;
											await this.promisedSetState({
												updated: this.state.updated
											})
										}}
									/>
								</div>
								<div className='w-1/3'>
									<InputTailwind
										label={"Type"}
										channel="facebook"
										value={this.renders.correctType() && typeof this.renders.correctType() === 'string' ? this.renders.correctType().charAt(0).toUpperCase() + this.renders.correctType().slice(1) : ''}
										disabled={true}
									/>
								</div>
								{
									this.renders.correctType() && typeof this.renders.correctType() === 'string' && ["unknown", "Unsupported"].includes(this.renders.correctType()) &&
									<div className='w-1/3'>
										<div>
											<label class="text-xs flex flex-row font-medium text-gray-700 ">
												<div class="mr-1">
													<div class="bg-facebook-500 h-4 w-4 rounded-full flex justify-center items-center">
														<img className="w-2"
															alt=""
															src={require('../assets/images/facebook_icon.svg')} />
													</div>
												</div>
												<div class="flex-1  flex flex-row">External link</div>
											</label>
											<div class="mt-1 relative flex flex-row rounded-md">
												<div className="items-center text-sm bg-purple-500 hover:bg-purple-600 mr-2 cursor-pointer focus:ring-purple-500 text-white inline-flex shadow relative justify-center rounded-md py-3 px-3 font-medium  focus:outline-none focus:ring-2 focus:ring-offset-2"
													onClick={async () => {
														window.open("https://adsmanager.facebook.com/adsmanager/manage/ads/edit?act=" + this.state.ad.account + "&business_id=" + this.state.ad.manager + "&selected_campaign_ids=" + this.state.ad.campaign_id + "&selected_adset_ids=" + this.state.ad.adgroup_id + "&selected_ad_ids=" + this.state.ad.id + "&breakdown_regrouping=1");
													}}
												>
													<ExternalLinkIcon class="w-5 h-5 mr-2" />
													<span>Open</span>
												</div>
											</div>
										</div>
									</div>
								}
							</div>

							<div className="col-span-4 text-lg font-semibold">
								Identity
							</div>

							<div className='flex space-x-4 w-full'>
								<div className='w-full'>
									<DropdownTailwind
										label={"Facebook page"}
										channel="facebook"
										locked={true}
										selected={this.renders.page()}
										options={[]}
										searchInput={true}
										onChange={async (value) => {

										}}
									/>
								</div>

								<div className='w-full'>
									<DropdownTailwind
										label={"Instagram Account"}
										channel="instagram"
										locked={true}
										selected={this.renders.instagram()}
										options={[]}
										onChange={async (value) => {

										}}
									/>

								</div>

							</div>

							<div className="col-span-4 text-lg font-semibold">
								Ad settings
							</div>

							{
								false &&
								this.state.carousel && this.state.carousel_menu === "Main ad" &&
								<div className='flex w-full space-x-4'>
									<div className="w-full">
										<DropdownTailwind
											label={"Automatically show the best performing cards first"}
											selected={this.renders.autoCards(this.state.ad)}
											options={[{ id: 1, name: "Yes", value: true }, { id: 2, name: "No", value: false }]}
											onChange={(value) => {
												this.state.updated.auto_cards = value;
												this.setState({
													updated: this.state.updated
												})
											}}
										/>
									</div>
									<div className="w-full">
										<DropdownTailwind
											label={"Add a card at the end with your Page profile picture"}
											selected={this.renders.lastCard(this.state.ad)}
											options={[{ id: 1, name: "Yes", value: true }, { id: 2, name: "No", value: false }]}
											onChange={(value) => {
												this.state.updated.last_card = value;
												this.setState({
													updated: this.state.updated
												})
											}}
										/>
									</div>
								</div>
							}

							{

								<>
									<div className="w-full">
										<InputTailwind
											label={this.state.carousel ? "See more URL" : "Website"}
											// value={this.state.updated.link}
											locked={this.renders.updateCheck() && ["image", "video", "merge_ad", "carousel_feed"].includes(this.renders.correctType()) ? false : true}
											disabled={this.renders.updateCheck() && ["image", "video", "merge_ad", "carousel_feed"].includes(this.renders.correctType()) ? false : true}
											value={this.renders.link()}
											onChange={async (value) => {
												if (this.renders.correctType() === "merge_ad") {
													this.state.updated.links = [{ id: 1, value: value }]
												} else {
													this.state.updated.link = value;
												}
												this.promisedSetState({
													updated: this.state.updated
												})
												this.functions.updatePreview();
											}}
										/>
									</div>

									{
										(this.state.updated.links && !this.state.updated.links[0].value.includes("https://")) || (this.state.updated.link && !this.state.updated.link.includes("https://")) &&
										<div className="w-full mt-4 p-3 rounded-md font-medium text-sm text-orange-500 bg-orange-100 items-center inline-flex">
											OBS! Prefix https:// is required.
										</div>
									}

								</>
							}

							{
								!this.state.merge &&
								!this.state.carousel &&
								//!this.state.updated.collection_ad &&
								!this.state.catalog &&
								!//this.state.updated.selected_lead &&
								//!(this.state.updated.type === "posts" && this.state.updated.files && this.state.updated.files.length > 1) &&
								<div className="w-full">
							<InputTailwind
								//label={this.renders.type() !== "carousel" ? "Display link" : "See more display link"}
								label={"Display link"}
								value={this.renders.caption()}
								locked={true}
								onChange={async (value) => {
								}}
							/>
						</div>
					}
					{
						this.renders.updateCheck() &&
						// !this.state.merge &&
						!this.state.carousel &&
						(!this.state.catalog || this.state.catalog && this.state.updated.collection_ad) &&
						<div className="w-full relative">
							<InputTailwind
								label={"Headline"}
								value={this.renders.title()}
								locked={this.renders.updateCheck() && ["image", "video", "merge_ad"].includes(this.renders.correctType()) ? false : true}
								disabled={this.renders.updateCheck() && ["image", "video", "merge_ad"].includes(this.renders.correctType()) ? false : true}
								onChange={async (value) => {
									this.state.updated.title = value;
									await this.promisedSetState({
										updated: this.state.updated
									})
									this.functions.updatePreview();
								}}
							/>
							{
								this.state.updated && Array.isArray(this.state.updated.OpenAiResult) && this.state.updated.OpenAiResult.filter((result) => { return result.fake && result.headlines && result.headlines.filter((inner_item) => { return inner_item.channel === "facebook" && inner_item.value.includes(this.renders.title()) }).length > 0 }).length > 0 &&
								<div className="absolute right-0 top-0 mt-5.5 w-8 h-12 flex items-center justify-items-center">
									<SparklesIcon className="h-5 w-5 text-purple-500" />
								</div>
							}
						</div>
					}

					<div className='flex space-x-4 w-full'>
						{
							// this.renders.type(this.state.updated) !== "merge" &&
							<div className="w-full relative">
								<TextAreaTailwind
									label={"Body"}
									value={this.renders.body()}
									locked={this.renders.updateCheck() && ["image", "video", "merge_ad", "carousel_feed"].includes(this.renders.correctType()) ? false : true}
									disabled={this.renders.updateCheck() && ["image", "video", "merge_ad", "carousel_feed"].includes(this.renders.correctType()) ? false : true}
									onChange={async (value) => {
										this.state.updated.body = value;
										await this.promisedSetState({
											updated: this.state.updated
										})
										this.functions.updatePreview();
									}}
								/>
								{
									this.state.updated && Array.isArray(this.state.updated.OpenAiResult) && this.state.updated.OpenAiResult.filter((result) => { return result.fake && result.descriptions && result.descriptions.filter((inner_item) => { return inner_item.channel === "facebook" && inner_item.value.includes(this.renders.body()) }).length > 0 }).length > 0 &&
									<div className="absolute right-0 top-0 mt-5.5 w-8 h-12 flex items-center justify-items-center">
										<SparklesIcon className="h-5 w-5 text-purple-500" />
									</div>
								}
							</div>
						}

						{
							this.renders.updateCheck() &&
							// !this.state.merge &&
							!this.state.carousel &&
							//!this.state.catalog &&
							//!this.state.updated.collection_ad &&
							<div className="w-full">
								<TextAreaTailwind
									label={"Description"}
									value={this.renders.description()}
									locked={this.renders.updateCheck() && ["image", "video", "merge_ad"].includes(this.renders.correctType()) ? false : true}
									disabled={this.renders.updateCheck() && ["image", "video", "merge_ad"].includes(this.renders.correctType()) ? false : true}
									onChange={async (value) => {
										this.state.updated.description = value;
										await this.promisedSetState({
											updated: this.state.updated
										})
										this.functions.updatePreview();
									}}
								/>
							</div>
						}
					</div>

					{
						// !this.state.merge &&
						//!this.state.updated.collection_ad &&
						true &&
						<div className="col-span-4 text-lg font-semibold">
							Select CTA
						</div>
					}
					{
						// !this.state.merge &&
						//!this.state.updated.collection_ad &&
						true &&
						<div className="w-full">
							<DropdownTailwind
								label={"CTA button"}
								selected={this.renders.cta()}
								locked={this.renders.updateCheck() && ["image", "video", "merge_ad", "carousel_feed"].includes(this.renders.correctType()) ? false : true}
								disabled={this.renders.updateCheck() && ["image", "video", "merge_ad", "carousel_feed"].includes(this.renders.correctType()) ? false : true}
								options={true ? [
									{ name: "Subscribe", id: "SUBSCRIBE", type: "SUBSCRIBE", objective: [1, 2, 3, 4, 5, 6] },
									{ name: "Apply Now", id: "APPLY_NOW", type: "APPLY_NOW", objective: [1, 2, 3, 4, 6] },
									{ name: "Book now", id: "BOOK_TRAVEL", type: "BOOK_TRAVEL", objective: [] },
									{ name: "Download", id: "DOWNLOAD", type: "DOWNLOAD", objective: [] },
									{ name: "Get offer", id: "GET_OFFER", type: "GET_OFFER", objective: [2, 3, 4, 6] },
									{ name: "Get quote", id: "GET_QUOTE", type: "GET_QUOTE", objective: [1, 2, 3, 6] },
									{ name: "Learn more", id: "LEARN_MORE", type: "LEARN_MORE", objective: [1, 2, 3, 4, 5, 6] },
									{ name: "Sign up", id: "SIGN_UP", type: "SIGN_UP", objective: [1, 2, 3, 4, 5, 6] },
								] : []}
								onChange={async (value) => {
									this.state.updated.cta = value;
									await this.promisedSetState({
										updated: this.state.updated
									})
									this.functions.updatePreview();
								}}
							/>
						</div>
					}

					<div className="col-span-4 text-lg font-semibold">
						Tracking settings
					</div>

					<div className='w-full pb-4'>

						<div className="flex space-x-4 w-full">
							<div className="w-full">
								<DropdownTailwind
									label={"Facebook tracking website pixel"}
									locked={true}
									selected={this.renders.pixel()}
									options={[]}
									onChange={async (value) => {

									}}
								/>
							</div>

							<div className="w-full">
								<DropdownTailwind
									label={"Facebook tracking app"}
									locked={true}
									selected={this.renders.app()}
									options={[]}
									onChange={async (value) => {

									}}
								/>
							</div>
						</div>

					</div>

				</>
					}
			</div >


			</>
		)
	}
}

export default EditLiveFacebookAd;
