import React, { Component, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react'
import { BeakerIcon, CheckIcon, ExclamationIcon, MinusIcon, PlusIcon } from '@heroicons/react/outline';
import cn from "classnames";
import InputTailwind from './inputTailwind';
import { tokenRegister } from '../services/tokenRegister';
import { apiRegister } from '../services/apiRegister';
import DropdownTailwind from './dropdownTailwind';
import TextAreaTailwind from './textAreaTailwind';
import SwitchTailwind from './switchTailwind';

class EditLiveFacebookCampaign extends Component {

    constructor(props) {
        super(props);
        this.state = {
            error: false,
            wizard: false,
            open: false,
            campaign: {},
            ios_options: [{ id: 0, name: "No", value: false }, { id: 0, name: "Yes", value: true }],
            adgroups: [],
            updated: {},
            client: {},
            loader: false,
            app_promotion_types: [
                { id: 1, name: 'Advantage+ App Campaigns', value: "SMART_APP_PROMOTION" },
                { id: 2, name: 'App Ads', value: "GUIDED_CREATION" }
            ],
            special_ad_categories: [
                { id: 1, name: 'None', value: "NONE" },
                { id: 2, name: 'Housing', value: "HOUSING" },
                { id: 3, name: 'Credit', value: "CREDIT" },
                { id: 4, name: 'Employment', value: "EMPLOYMENT" },
                { id: 5, name: 'Issues Elections Politics', value: "ISSUES_ELECTIONS_POLITICS" }
            ],
            objectives: [
                { id: 1, name: 'Awareness', value: "OUTCOME_AWARENESS" },
                { id: 2, name: 'Traffic', value: "OUTCOME_TRAFFIC" },
                { id: 3, name: 'Engagement', value: "OUTCOME_ENGAGEMENT" },
                { id: 4, name: 'Leads', value: "OUTCOME_LEADS" },
                { id: 5, name: 'App Promotion', value: "OUTCOME_APP_PROMOTION" },
                { id: 6, name: 'Sales', value: "OUTCOME_SALES" }
            ],
            tab: { id: 1, name: "Basic", value: "basic" },
            tabs: [
                { id: 1, name: "Basic", value: "basic" }
            ],
            status_values: [
                { id: 1, name: 'Active', value: "ACTIVE" },
                { id: 2, name: 'Paused', value: "PAUSED" },
                { id: 3, name: 'Deleted', value: "DELETED" },
                { id: 4, name: 'Archived', value: "ARCHIVED" }
            ],
            locked: false,
        }
    };

    async componentDidMount() {
        await this.promisedSetState({
            campaign: this.props.campaign,
            client: this.props.client,
            wizard: this.props.wizard,
            locked: this.props.locked ? this.props.locked : false,
        })
    }

    componentWillReceiveProps(nextProps) {

    }

    functions = {
        update: async (data) => {
            if (!this.state.wizard) {
                this.props.onSuccess(false);
                this.props.onLoader(true);
                this.promisedSetState({
                    error: false
                });
                if (!data) {
                    if (Object.keys(this.state.updated).length > 0) {
                        let body = {
                            ...(this.state.updated.name) && { name: this.state.updated.name },
                            ...(this.state.updated.daily_budget) && { daily_budget: this.state.updated.daily_budget * 100 },
                            ...(this.state.updated.lifetime_budget) && { lifetime_budget: this.state.updated.lifetime_budget * 100 },
                            ...(this.state.updated.spend_cap !== "" && this.renders.budgetLimit().id === 2) && { spend_cap: this.state.updated.spend_cap * 100 },
                            ...(this.state.updated.add_spend_limit && this.state.updated.add_spend_limit.id === 1 && this.state.campaign.spend_cap !== null) && { spend_cap: 922337203685478 },
                        };
                        try {
                            let response = await this.calls.updateLiveCampaign(body);
                            if (response.data.error) {
                                this.promisedSetState({
                                    error: response.data.error
                                });
                            }
                        } catch (error) {
                            console.log("error", error)
                            //this.props.onError(error.error ? error.error : "Something went wrong");
                        }
                    }
                } else {
                    this.promisedSetState({
                        loading_status: true
                    });
                    try {
                        if (data.value === "PAUSED") {
                            this.props.onPauseCampaign(true);
                        } else {
                            this.props.onActivateCampaign(true);
                        }

                        this.state.updated.status = data;
                        this.promisedSetState({
                            loading_status: false,
                            updated: this.state.updated
                        });

                    } catch (error) {
                        //this.props.onError(error.error ? error.error : "Something went wrong");
                    }
                }
                if (!this.state.error) {
                    if (!data) {
                        this.props.onSuccess(true);
                    } else {
                        //this.props.onUpdateData(true)
                    }
                }
                this.props.onLoader(false);
            } else {
                this.props.onUpdate(this.state.updated);
            }

        }
    };

    calls = {
        updateLiveCampaign: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/adcredo/updateLiveCampaign?client=" + this.state.client.id + "&campaign=" + this.state.campaign.id + "&channel=facebook";
            return apiRegister.call(options, url);
        },
    };

    renders = {
        name: () => {
            try {
                if ('name' in this.state.updated) {
                    return this.state.updated.name;
                } else if (this.state.campaign.name) {
                    return this.state.campaign.name;
                } else {
                    return "";
                }
            } catch (error) {
                return "";
            }
        },
        optimization: () => {
            try {
                if (this.state.campaign.lifetime_budget === null && this.state.campaign.daily_budget === null) {
                    return { id: 1, name: "No", value: false };
                } else {
                    return { id: 2, name: "Yes", value: true };
                }
            } catch (error) {
                return "";
            }
        },
        objective: () => {
            try {
                if (this.state.campaign.objective) {
                    return this.state.campaign.objective.name;
                } else {
                    return "";
                }
            } catch (error) {
                return "";
            }
        },
        budget: () => {
            try {
                if (this.state.campaign.daily_budget !== null) {
                    return { id: 1, name: "Daily budget", value: 'daily' };
                } else if (this.state.campaign.lifetime_budget !== null) {
                    return { id: 2, name: "Total budget", value: 'total' };
                } else {
                    return { id: 0, name: "...", value: 'total' };
                }
            } catch (error) {
                return "";
            }
        },
        totalBudget: () => {
            try {
                if ('lifetime_budget' in this.state.updated) {
                    return this.state.updated.lifetime_budget;
                } else if (this.state.campaign.lifetime_budget !== null) {
                    return this.state.campaign.lifetime_budget;
                } else {
                    return null;
                }
            } catch (error) {
                return "";
            }
        },
        dailyBudget: () => {
            try {
                if ('daily_budget' in this.state.updated) {
                    return this.state.updated.daily_budget
                } else if (this.state.campaign.daily_budget !== null) {
                    return this.state.campaign.daily_budget;
                } else {
                    return null;
                }
            } catch (error) {
                return "";
            }
        },
        budgetLimit: () => {
            try {
                if ('add_spend_limit' in this.state.updated) {
                    return this.state.updated.add_spend_limit;
                } else if (this.state.campaign.spend_cap !== null) {
                    return { id: 2, name: "Yes", value: true };
                } else {
                    return { id: 1, name: "No", value: false };
                }
            } catch (error) {
                return "";
            }
        },
        spendLimit: () => {
            try {
                if ('spend_cap' in this.state.updated) {
                    return this.state.updated.spend_cap;
                } else if (this.state.campaign.spend_cap !== null) {
                    return parseInt(this.state.campaign.spend_cap);
                } else {
                    return "";
                }
            } catch (error) {
                return "";
            }
        },
        status: () => {
            try {
                if ('status' in this.state.updated) {
                    return this.state.updated.status;
                } else if (typeof this.state.campaign.status === 'object') {
                    return this.state.campaign.status
                } else if (this.state.status_values.filter((item) => { return item.value.toLowerCase() === this.state.campaign.status.toLowerCase() }).length > 0) {
                    return this.state.status_values.filter((item) => { return item.value.toLowerCase() === this.state.campaign.status.toLowerCase() })[0];
                } else {
                    return { id: 0, name: 'Click here ...', value: "PAUSED" }
                }
            } catch (error) {
                return { id: 0, name: 'Click here ...', value: "PAUSED" }
            }
        }
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {
        return (
            <>
                <div className="grid grid-cols-6 gap-4">

                    <div className="col-span-6">
                        <div className="block overflow-scroll w-ful">
                            <nav className="flex space-x-4" aria-label="Tabs">
                                {this.state.tabs.map((tab) => (
                                    <div
                                        onClick={() => (
                                            this.setState({
                                                tab: tab
                                            })
                                        )}
                                        key={tab.name}
                                        style={{ whiteSpace: "nowrap" }}
                                        className={cn(
                                            tab.value == this.state.tab.value ? 'bg-purple-100 text-purple-500' : 'text-gray-500 hover:text-purple-500',
                                            'px-3 py-2 font-medium text-sm rounded-md whitespace-nowrap cursor-pointer flex'
                                        )}
                                        aria-current={tab.value == this.state.tab.value ? 'page' : undefined}
                                    >
                                        <div>
                                            {
                                                (tab.id === 1 && this.renders.optimization().id > 1 && parseInt(this.renders.budget().id === 1 ? this.renders.dailyBudget() : this.renders.totalBudget()) < 1)
                                                    ? <ExclamationIcon className="h-5 w-5 text-red-600 mr-2" /> : ""
                                            }
                                        </div>
                                        {tab.name}
                                    </div>
                                ))}
                            </nav>
                        </div>
                    </div>

                    <div className="col-span-6">
                        <InputTailwind
                            label={"Campaign name"}
                            value={this.renders.name()}
                            onChange={(value) => {
                                this.state.updated.name = value;
                                this.setState({
                                    updated: this.state.updated
                                })
                            }}
                        />
                    </div>
                    <div className="col-span-6">
                        <InputTailwind
                            label={"Campaign objective"}
                            value={this.renders.objective()}
                            disabled={true}
                            locked={true}
                        />
                    </div>

                    <div className={this.renders.optimization().value ? "col-span-2" : "col-span-6"}>
                        <DropdownTailwind
                            disabled={true}
                            label={"Campaign Budget Optimization"}
                            selected={this.renders.optimization()}
                            options={[
                                { id: 1, name: "No", value: false },
                                { id: 2, name: "Yes", value: true }
                            ]}
                            locked={true}
                        />
                    </div>
                    {
                        this.renders.optimization().value &&
                        <>
                            <div className="col-span-2">
                                <DropdownTailwind
                                    disabled={true}
                                    label={"Campaign Budget"}
                                    selected={this.renders.budget()}
                                    options={[
                                        { id: 1, name: "Daily budget", value: 'daily' },
                                        { id: 2, name: "Total budget", value: 'total' }
                                    ]}
                                    locked={true}

                                />
                            </div>
                            {
                                this.state.campaign.daily_budget !== null &&
                                <div className="col-span-2">
                                    <InputTailwind
                                        error={parseInt(this.renders.dailyBudget()) < 1 || isNaN(parseInt(this.renders.dailyBudget())) ? true : false}
                                        leftSection={(this.state.client.currency && this.state.client.currency.facebook ? true : false)}
                                        leftSectionLabel={(this.state.client.currency && this.state.client.currency.facebook ? this.state.client.currency.facebook : "")}
                                        disabled={false}
                                        label={"Amount"}
                                        type={"number"}
                                        value={this.renders.dailyBudget()}
                                        onChange={(value) => {
                                            this.state.updated.daily_budget = value;
                                            this.setState({
                                                updated: this.state.updated
                                            })
                                        }}
                                    />
                                </div>
                            }
                            {
                                this.state.campaign.lifetime_budget !== null &&
                                <div className="col-span-2">
                                    <InputTailwind
                                        error={parseInt(this.renders.totalBudget()) < 1 || isNaN(parseInt(this.renders.totalBudget())) ? true : false}
                                        disabled={false}
                                        label={"Amount"}
                                        type={"number"}
                                        leftSection={(this.state.client.currency && this.state.client.currency.facebook ? true : false)}
                                        leftSectionLabel={(this.state.client.currency && this.state.client.currency.facebook ? this.state.client.currency.facebook : "")}
                                        value={this.renders.totalBudget()}
                                        onChange={(value) => {
                                            this.state.updated.lifetime_budget = value;
                                            this.setState({
                                                updated: this.state.updated
                                            })
                                        }}
                                    />
                                </div>
                            }
                        </>
                    }
                    {
                        this.state.campaign.lifetime_budget === null && this.state.campaign.daily_budget === null && false &&
                        <div className="col-span-2">
                            <InputTailwind
                                leftSection={(this.state.client.currency && this.state.client.currency.facebook ? true : false)}
                                leftSectionLabel={(this.state.client.currency && this.state.client.currency.facebook ? this.state.client.currency.facebook : "")}
                                locked={true}
                                label={"Amount"}
                                value={"..."}

                            />
                        </div>
                    }
                    <div className="col-span-2">
                        <DropdownTailwind
                            label={"Add campaign spending limit? (optional)"}
                            selected={this.renders.budgetLimit()}
                            options={[
                                { id: 1, name: "No", value: false },
                                { id: 2, name: "Yes", value: true }
                            ]}
                            locked={false}
                            onChange={(value) => {
                                this.state.updated.add_spend_limit = value;
                                if (value.id === 1) {
                                    this.state.updated.spend_cap = 0;
                                }
                                this.setState({
                                    updated: this.state.updated
                                })
                            }}
                        />
                    </div>
                    <div className="col-span-2">
                        <InputTailwind
                            disabled={!(this.renders.budgetLimit().id === 2)}
                            leftSection={(this.state.client.currency && this.state.client.currency.facebook ? true : false)}
                            leftSectionLabel={(this.state.client.currency && this.state.client.currency.facebook ? this.state.client.currency.facebook : "")}
                            label={"Total spend limit"}
                            value={this.renders.spendLimit()}
                            onChange={(value) => {
                                this.state.updated.spend_cap = value;
                                this.setState({
                                    updated: this.state.updated
                                })
                            }}
                        />
                    </div>

                    <div className="col-span-2">
                        <div className='-mb-4 text-xs font-medium text-gray-700 flex flex-1'>Status</div>
                        <div className='flex items-center justify-center my-5 border-gray-300 w-full bg-custom-input border-1.5 rounded-md' style={{ paddingTop: "0.5rem", paddingBottom: "0.6rem" }}>
                            <div className="flex flex-1 ml-5">
                                {this.renders.status().name}
                            </div>
                            <div className="relative overflow-hidden mr-5">
                                <SwitchTailwind
                                    value={this.renders.status().value === "ACTIVE" ? true : false}
                                    disabled={this.state.locked}
                                    onSwitch={async () => {
                                        if (!this.state.locked) {
                                            if (this.renders.status().value === "ACTIVE") {
                                                this.functions.update({ id: 2, name: 'Paused', value: "PAUSED" });
                                            } else if (this.renders.status().value === "PAUSED") {
                                                this.functions.update({ id: 1, name: 'Active', value: "ACTIVE" });
                                            }
                                        }

                                    }}
                                />
                                {
                                    this.state.loading_status &&
                                    <div className="w-full h-full bg-opacity-50 absolute bg-white top-0 bottom-0 rounded-md left-0 right-0 flex justify-center items-center">
                                        <div style={{ borderTopColor: "transparent" }}
                                            class="w-4 h-4 border-2 border-purple-500 absolute border-solid rounded-full animate-spin"></div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>


                    {
                        this.state.error &&
                        <div className='text-red-500 text-sm p-4 col-span-6 bg-red-100 rounded-md'>{this.state.error}</div>
                    }

                </div>
            </>
        )
    }
}

export default EditLiveFacebookCampaign;
