import React from 'react';
import { apiRegister } from "../../services/apiRegister";
import { tokenRegister } from "../../services/tokenRegister";
import { ExclamationCircleIcon } from '@heroicons/react/outline';
import InputTailwind from "../inputTailwind";
import DropdownTailwind from "../dropdownTailwind";
import GridLayout from "react-grid-layout";

export default class SnapshotModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            urlPopupError: null,
            siteUrl: null,
            renderMode: {id: 1, name: "Laptop", width: 1920, height: 1080, scaleParam: 737, left: 8.5},
            snapshotHeight: 1080,
            htmlContent: null,
        }
        this.childRef = React.createRef();
    };

    isValidUrl = (string) => {
        const urlRegex = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/\S*)?$/;
        return urlRegex.test(string);
    };

    functions = {
        makeSiteSnapshotPreview: async () => {
            this.setState({
                htmlContent: null,
                snapshotHeight: this.state.renderMode.height
            })
            try {
                let response = await this.calls.makeSiteSnapshotPreview(this.state.siteUrl);
                let html = response.data.html
                let regex = /(src|href|srcdoc)="(\/[^"]+)"/g;
                html = html.replace(regex, (match, p1, p2) => {
                    return `${p1}="${response.data.domain}${p2}"`;
                });
                regex = /, "(\/[^"]+)"/g;
                html = html.replace(/, \//g, () => {return `, ${response.data.domain}/`})
                this.setState({
                    htmlContent: html
                })
            } catch (error) {
                console.error(error)
                this.setState({
                    urlPopupLoader: false,
                    urlPopupError: error.body && error.body.message ? error.body.message : null,
                })
            }
        },
        calculatedPreview: () => {
            if (this.childRef && this.childRef.current && this.childRef.current.contentDocument) {
                this.setState({
                    snapshotHeight: this.childRef.current.contentDocument.children[0].scrollHeight
                });
            }
        },
        makeSiteSnapshot: async () => {
            try {
                if (this.isValidUrl(this.state.siteUrl) && this.refs.layout && this.refs.layout.state.layout) {
                    let response = await this.calls.makeSiteSnapshot({
                        url: this.state.siteUrl,
                        snapshotWidth: this.state.renderMode.width,
                        snapshotHeight: this.refs.layout.state.layout[0].h / (this.state.renderMode.scaleParam / this.state.renderMode.width),
                        snapshotScroll: this.refs.layout.state.layout[0].y / (this.state.renderMode.scaleParam / this.state.renderMode.width),
                    })
                    if (this.props.onCreated) {
                        this.props.onCreated(response)
                    }
                } else {
                    if (this.props.onError) {
                        await this.props.onError()
                    }

                    await this.setState({
                        urlPopupError: "Not valid URL"
                    });
                }
            } catch (e) {
                console.error(e)
                if (this.props.onError) {
                    await this.props.onError()
                }
            }
        }
    };

    calls = {
        makeSiteSnapshotPreview: (siteUrl) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/snapshot/preview?url=" + siteUrl;
            return apiRegister.call(options, url);
        },
        makeSiteSnapshot: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/adcredo/snapshot";
            return apiRegister.call(options, url);
        }
    };

    render() {
        return(
            <div>
                <div className="grid grid-cols-12 col-gap-4 row-gap-4">
                    <div className="col-span-12">
                        <InputTailwind
                            label={"Site URL"}
                            value={this.state.siteUrl}
                            onChange={(value) => {
                                this.setState({
                                    siteUrl: value
                                }, () => {
                                    if (this.isValidUrl(value)) {
                                        this.functions.makeSiteSnapshotPreview()
                                    }
                                })
                            }}
                        />
                    </div>
                    <div className="col-span-3">
                        <DropdownTailwind
                            label={"Render mode"}
                            selected={this.state.renderMode}
                            options={[
                                {id: 1, name: "Laptop", width: 1920, height: 1080, scaleParam: 737, left: 8.5},
                                {id: 2, name: "Tablet", width: 820, height: 1180, scaleParam: 424, left: 26.2},
                                {id: 3, name: "Mobile", width: 412, height: 915, scaleParam: 212, left: 38.2},
                            ]}
                            onChange={(value) => {
                                this.setState({
                                    renderMode: value,
                                    snapshotHeight: value.height
                                }, () => {
                                    this.functions.calculatedPreview();
                                })
                            }}
                        />
                    </div>
                </div>
                {
                    this.isValidUrl(this.state.siteUrl) &&
                    <div>
                        <div className="pt-5">
                            Preview
                        </div>
                        {
                            this.state.htmlContent &&
                            <div
                                className="pt-5 image-selector flex justify-center"
                                style={{}}>
                                <div
                                    style={{
                                        position: 'absolute',
                                        zIndex: 2,
                                        width: `${this.state.renderMode.width * (this.state.renderMode.scaleParam / this.state.renderMode.width)}px`,
                                        height: `${this.state.snapshotHeight * (this.state.renderMode.scaleParam / this.state.renderMode.width)}px`,
                                    }}>
                                    <GridLayout
                                        ref="layout"
                                        className="layout"
                                        layout={[
                                            { i: "1", x: 0, y: 0, w: 1, h: this.state.renderMode.height * (this.state.renderMode.scaleParam / this.state.renderMode.width), static: false },
                                        ]}
                                        cols={1}
                                        rowHeight={1}
                                        margin={[0, 0]}
                                        maxRows={this.state.snapshotHeight * (this.state.renderMode.scaleParam / this.state.renderMode.width)}
                                        verticalCompact={false}
                                        resizeHandles={["s", "n"]}
                                        width={this.state.renderMode.width * (this.state.renderMode.scaleParam / this.state.renderMode.width)}
                                        isResizable={true}
                                        isDraggable={true}
                                    >
                                        <div key="1" style={{ border: "2px solid red" }}>
                                        </div>
                                    </GridLayout>
                                </div>
                                <iframe
                                    ref={this.childRef}
                                    id="siteIframe"
                                    style={{
                                        width: `${this.state.renderMode.width}px`,
                                        height: `${this.state.snapshotHeight}px`,
                                        position: 'absolute',
                                        border: 'none',
                                        left: `${this.state.renderMode.left}%`,
                                        transform: `scale(${this.state.renderMode.scaleParam / this.state.renderMode.width})`,
                                        transformOrigin: 'top left',
                                        pointerEvents: "none"
                                    }}
                                    srcDoc={this.state.htmlContent}
                                    onLoad={() => {
                                        if (this.childRef.current.contentDocument) {
                                            let elements = this.childRef.current.contentDocument.querySelectorAll('*')
                                            const regex = /cookie/i;

                                            elements.forEach((el) => {
                                                if (el.id && regex.test(el.id)) {
                                                    el.remove();
                                                }
                                            });
                                            this.functions.calculatedPreview();
                                        }
                                    }}
                                    frameBorder="0">
                                </iframe>
                            </div>
                        }
                        {
                            !this.state.htmlContent &&
                            <div className="w-full h-full relative flex flex-col justify-center items-center">
                                <div className="bg-transparent">
                                    <div style={{borderTopColor: "transparent"}}
                                         className="w-6 h-6 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                                </div>
                            </div>
                        }
                    </div>
                }
                {
                    this.state.urlPopupError &&
                    <div className="relative mt-2" style={{height: '100%'}}>
                        <div
                            className="relative text-sm font-medium border-1.5 py-3 items-center px-4 border-red-500 text-red-500 bg-red-100 rounded-md">
                            <div className="flex  justify-center">
                                <ExclamationCircleIcon
                                    style={{minWidth: '20px'}}
                                    className="w-5"/>
                                <span className="ml-2">
                                        {this.state.urlPopupError}
                                    </span>
                            </div>
                        </div>
                    </div>
                }
            </div>
        )
    }
}