import React, { Component, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react'
import { BeakerIcon, CheckIcon, ExclamationIcon, InformationCircleIcon, MinusIcon, PlusIcon } from '@heroicons/react/outline'
import InputTailwind from './inputTailwind';
import { tokenRegister } from '../services/tokenRegister';
import { apiRegister } from '../services/apiRegister';
import DropdownTailwind from './dropdownTailwind';
import TextAreaTailwind from './textAreaTailwind';
import cn from "classnames";
import { campaignTiktok } from '../validators/campaignTiktok';

import moment from 'moment';
import SwitchTailwind from './switchTailwind';
import SlideDown from 'react-slidedown';
import { Bar } from 'react-chartjs-2';
import InputDatepickerTailwind from './inputDatepickerTailwind';

import { budgetPlannerGraph } from '../validators/budgetPlannerGraph';

class EditTikTokCampaign extends Component {

    constructor(props) {
        super(props);
        this.state = {
            wizard: false,
            open: false,
            currency: "",
            campaign: {},
            adgroups: [],
            updated: {},
            client: {},
            loader: false,
            apps: {
                loading: false,
                options: []
            },
            objectives: [
                { id: 2, name: 'Traffic', value: "TRAFFIC", goal: { id: 8, name: 'Click', value: "CLICK" }, billing_event: { id: 1, name: 'CPC', value: "CPC" } },
                { id: 7, name: 'App Installs', value: "APP_INSTALL", goal: { id: 8, name: 'Click', value: "CLICK" }, billing_event: { id: 1, name: 'CPC', value: "CPC" } },
                { id: 6, name: 'Conversions', value: "CONVERSIONS", goal: { id: 5, name: 'Conversion', value: "CONVERT" }, billing_event: { id: 4, name: 'OCPM', value: "OCPM" } },
                { id: 1, name: 'Reach', value: "REACH", goal: { id: 10, name: 'Reach', value: "REACH" }, billing_event: { id: 2, name: 'CPM', value: "CPM" } },
                //{ id: 5, name: 'Video Views', value: "VIDEO_VIEWS", goal: { id: 13, name: 'Video view', value: "VIDEO_VIEW" }, billing_event: { id: 3, name: 'CPV', value: "CPV" } },
                //{ id: 6, name: 'Lead Generation', value: "LEAD_GENERATION", goal: { id: 9, name: 'Lead generation', value: "LEAD_GENERATION" }, billing_event: { id: 4, name: 'oCPM', value: "oCPM" } },
                //{ id: 7, name: 'Catalog Sales', value: "CATALOG_SALES" },
            ],
            goal: [
                { id: 1, name: 'Show', value: "SHOW" },
                { id: 2, name: 'In App Event', value: "IN_APP_EVENT" },
                { id: 4, name: 'Product click in live', value: "PRODUCT_CLICK_IN_LIVE" },
                { id: 5, name: 'Conversion', value: "CONVERT" },
                { id: 7, name: 'Install', value: "INSTALL" },
                { id: 8, name: 'Click', value: "CLICK" },
                //{ id: 9, name: 'Lead generation', value: "LEAD_GENERATION" },
                { id: 10, name: 'Reach', value: "REACH" },
                { id: 11, name: 'MT Live room', value: "MT_LIVE_ROOM" },
                { id: 12, name: 'Followers', value: "FOLLOWERS" },
                //{ id: 13, name: '6-Second video views', value: "VIDEO_VIEW" },
                { id: 14, name: 'Profile views', value: "PROFILE_VIEWS" },
                { id: 15, name: 'Value', value: "VALUE" },
            ],
            pacings: [
                { id: 1, name: "Standard (recommended)", value: "STANDARD" }
            ],
            bid_strategies: [
                { id: 1, name: "Lowest cost", value: "LOWEST_COST" }
            ],
            tab: { id: 1, name: "Basic", value: "basic" },
            tabs: [
                { id: 1, name: "Basic", value: "basic" }
            ]
        }
    };

    async componentDidMount() {
        await this.promisedSetState({
            campaign: this.props.campaign,
            client: this.props.client,
            wizard: this.props.wizard,
            adgroups: this.props.adgroups ? this.props.adgroups : []
        })
        if (this.state.client.channels.filter((item) => { return item.value === "tiktok" }).length > 0) {
            await this.promisedSetState({
                currency: this.state.client.channels.filter((item) => { return item.value === "tiktok" })[0].currency
            })
        }
        if (this.props.updated) {
            await this.promisedSetState({
                updated: this.props.updated
            });

            if (this.state.updated.scheduler) {
                this.state.tabs.push({ id: 2, name: "Scheduler", value: "schedule" });
                //REDO THIS
                await this.promisedSetState({
                    tabs: this.state.tabs,
                    updated: this.state.updated
                })
            }
        }
        this.functions.listApps();
    }

    componentWillReceiveProps(nextProps) {
        /*this.setState({
            campaign: nextProps.campaign,
            client: nextProps.client,
            wizard: nextProps.wizard
        })
        if (nextProps.updated) {
            this.setState({
                updated: nextProps.updated
            });
        }*/
    }

    functions = {
        update: async () => {
            if (!this.state.wizard) {

            } else {
                this.state.updated.name = this.renders.name();
                this.state.updated.objective = this.renders.objective();
                this.state.updated.daily_budget = this.renders.dailyBudget();
                this.state.updated.total_budget = this.renders.totalBudget();
                this.state.updated.delivery_type = this.renders.deliveryType();
                this.state.updated.budget_optimization = this.renders.budgetOptimization();
                this.state.updated.budget_mode = this.renders.budgetMode();
                this.state.updated.bid_strategy = this.renders.bidStrategy();
                this.state.updated.budget_limit = this.renders.budgetLimit();
                this.state.updated.note = this.renders.internalNote();

                this.state.updated.app_promotion_type = this.renders.promotionType();
                this.state.updated.ios14_campaign = this.renders.ios14_campaign();
                this.state.updated.app = this.renders.app();

                this.functions.updateAdgroups();

                this.props.onUpdate(this.state.updated, this.state.adgroups);
            }
        },
        updateAdgroups: async () => {
            let campaigns = [];
            if (this.state.updated.id && this.state.updated.id !== "multiple") {
                campaigns.push(this.state.updated.id);
            } else if (this.state.updated.ids) {
                campaigns = this.state.updated.ids;
            }
            campaigns.map((campaign_id) => {
                this.state.adgroups = this.state.adgroups.map((item) => {
                    if (item.campaign === campaign_id) {
                        item = {
                            ...item, ...{
                                campaign_budget_optimization: this.renders.budgetOptimization().value,
                                campaign_budget: {
                                    mode: this.renders.budgetOptimization().value ? this.renders.budgetMode().value : false,
                                    value: this.renders.budgetOptimization().value ? (this.renders.budgetMode().id === 1 ? this.renders.dailyBudget() : this.renders.totalBudget()) : false
                                },
                                ...(this.renders.objective().value === "APP_PROMOTION" && { ios14_campaign: this.renders.ios14_campaign(), app_promotion_type: this.renders.promotionType() }),
                                ...(this.renders.objective().value === "APP_PROMOTION" && this.renders.ios14_campaign() && { app: this.renders.app() }),
                                ...(this.renders.budgetOptimization().value && !item.end_date && { end_date: moment(item.start_date).add(1, 'days').format("YYYY-MM-DD HH:mm") }),
                                ...(this.renders.budgetOptimization().value && this.renders.objective().value === "CONVERSIONS" && { goal: { id: 5, name: 'Conversion', value: "CONVERT" } }),
                                ...(this.renders.budgetOptimization().value && this.renders.objective().value === "CONVERSIONS" && { billing_event: { id: 4, name: "OCPM", value: "OCPM" } }),
                                ...(this.renders.budgetOptimization().value && { pacing: { id: 1, name: "Standard", value: "PACING_MODE_SMOOTH" } })

                            }
                        }
                    }

                    return item;
                });
            });
            this.promisedSetState({
                adgroups: this.state.adgroups
            });
        },
        listApps: async () => {
            try {
                this.state.apps.loading = true;
                await this.promisedSetState({ apps: this.state.apps });
                let response = await this.calls.apps();
                this.state.apps.options = response.data;
                this.state.apps.loading = false;
                await this.promisedSetState({ apps: this.state.apps });
            } catch (error) {
                this.state.apps.options = [];
                this.state.apps.loading = false;
                await this.promisedSetState({ apps: this.state.apps });
            }
        },
    };

    calls = {
        update: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/tiktok/updateCampaign?client=" + this.state.client.id + "&id=" + this.state.campaign.id;
            return apiRegister.call(options, url);
        },
        apps: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/tiktok/listApps?client=" + this.state.client.id;
            return apiRegister.call(options, url);
        }
    };

    renders = {
        name: () => {
            try {
                if ('name' in this.state.updated) {
                    return this.state.updated.name;
                } else if (this.state.campaign.name) {
                    return this.state.campaign.name;
                } else {
                    return "";
                }
            } catch (error) {
                return "";
            }
        },
        objective: () => {
            try {
                if (this.state.updated.objective) {
                    return this.state.updated.objective;
                } else {
                    return { id: 0, name: 'Select value' }
                }
            } catch (error) {
                return { id: 0, name: 'Select value' }
            }
        },
        dailyBudget: () => {
            try {
                if ('daily_budget' in this.state.updated) {
                    return this.state.updated.daily_budget;
                } else {
                    return 0;
                }
            } catch (error) {
                return 0;
            }
        },
        totalBudget: () => {
            try {
                if ('total_budget' in this.state.updated) {
                    return this.state.updated.total_budget;
                } else {
                    return 0;
                }
            } catch (error) {
                return 0;
            }
        },
        deliveryType: () => {
            try {
                if ("delivery_type" in this.state.updated) {
                    return this.state.updated.delivery_type;
                } else {
                    return { id: 1, name: "Standard", value: "STANDARD" };
                }
            } catch (error) {
                return { id: 1, name: "Standard", value: "STANDARD" };
            }
        },
        budgetOptimization: () => {
            try {
                if ("budget_optimization" in this.state.updated) {
                    return this.state.updated.budget_optimization;
                } else {
                    return { id: 1, name: "No", value: false };
                }
            } catch (error) {
                return { id: 1, name: "No", value: false };
            }
        },
        budgetMode: () => {
            try {
                if ("budget_mode" in this.state.updated) {
                    return this.state.updated.budget_mode;
                } else {
                    return { id: 1, name: "Daily budget", value: "BUDGET_MODE_DAY" };
                }
            } catch (error) {
                return { id: 1, name: "Daily budget", value: "BUDGET_MODE_DAY" };
            }
        },
        bidStrategy: () => {
            try {
                if ("bid_strategy" in this.state.updated) {
                    return this.state.updated.bid_strategy;
                } else {
                    return { id: 1, name: "Lowest cost", value: "LOWEST_COST" };
                }
            } catch (error) {
                return { id: 1, name: "Lowest cost", value: "LOWEST_COST" };
            }
        },
        budgetLimit: () => {
            try {
                if ("budget_limit" in this.state.updated) {
                    return this.state.updated.budget_limit;
                } else {
                    return { id: 1, name: "No", value: false };
                }
            } catch (error) {
                return { id: 1, name: "No", value: false };
            }
        },
        internalNote: () => {
            try {
                if ('note' in this.state.updated) {
                    return this.state.updated.note;
                } else {
                    return "";
                }
            } catch (error) {
                return "";
            }
        },
        promotionType: () => {
            try {
                if ('app_promotion_type' in this.state.updated) {
                    return this.state.updated.app_promotion_type;
                } else {
                    return { id: 0, name: 'Select' }
                }
            } catch (error) {
                return { id: 0, name: 'Select' }
            }
        },
        ios14_campaign: () => {
            try {
                if ('ios14_campaign' in this.state.updated) {
                    return this.state.updated.ios14_campaign;
                } else {
                    return false;
                }
            } catch (error) {
                return false;
            }
        },
        app: () => {
            try {
                if ('app' in this.state.updated) {
                    return this.state.updated.app;
                } else {
                    return { id: 0, name: 'Select' }
                }
            } catch (error) {
                return { id: 0, name: 'Select' }
            }
        }
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {

        function classNames(...classes) {
            return classes.filter(Boolean).join(' ')
        }

        return (
            <>
                <div className="grid grid-cols-3 gap-4 transition-all duration-1000">

                    {/*Menu*/}
                    <div className="col-span-3">
                        <div className="block overflow-scroll w-ful">
                            <nav className="flex space-x-4" aria-label="Tabs">
                                {this.state.tabs.map((tab) => (
                                    <div
                                        onClick={() => (
                                            this.setState({
                                                tab: tab
                                            })
                                        )}
                                        key={tab.name}
                                        style={{ whiteSpace: "nowrap" }}
                                        className={cn(
                                            tab.value == this.state.tab.value ? 'bg-purple-100 text-purple-500' : 'text-gray-500 hover:text-purple-500',
                                            'px-3 py-2 font-medium text-sm rounded-md whitespace-nowrap cursor-pointer flex'
                                        )}
                                        aria-current={tab.value == this.state.tab.value ? 'page' : undefined}
                                    >
                                        <div>
                                            {
                                                (tab.id === 1 && campaignTiktok.validate(this.state.updated)) ||
                                                    (tab.id === 2 && ((this.state.updated.scheduler.planer && budgetPlannerGraph.main(this.state.updated.scheduler).datasets[0].data[0] === Infinity) || Object.keys(this.state.updated.scheduler.weekdays).length < 1) && this.state.updated.scheduler.schedule) ? <ExclamationIcon className="h-5 w-5 text-red-600 mr-2" /> : ""
                                            }
                                        </div>
                                        {tab.name}
                                    </div>
                                ))}
                            </nav>
                        </div>
                    </div>

                    {/*BASIC*/}
                    {
                        this.state.tab.id === 1 &&
                        <>
                            {/*Name*/}
                            <div className="col-span-3">
                                <InputTailwind
                                    label={"Campaign name"}
                                    value={this.renders.name()}
                                    onChange={(value) => {
                                        this.state.updated.name = value;
                                        this.setState({
                                            updated: this.state.updated
                                        })
                                    }}
                                />
                            </div>

                            {/*objective*/}
                            <div className="col-span-3">
                                <DropdownTailwind
                                    label={"Campaign objective"}
                                    selected={this.state.updated.objective ? this.state.updated.objective : { id: 0, name: "select value" }}
                                    locked={true}
                                    options={[]}
                                    onChange={async (value) => {
                                        /*
                                        if (this.state.wizard) {
                                            this.state.updated.objective = value;
                                            this.state.updated.goal = value.goal;
                                            this.state.updated.billing_event = value.billing_event;
                                            await this.setState({
                                                updated: this.state.updated
                                            })
    
                                            //UPDATE CONNECTED ADGROUPS
                                            this.functions.updateAdgroups();
                                        }
                                        */
                                    }}
                                />
                            </div>
                            {
                                this.renders.objective().value === "APP_PROMOTION" &&
                                <>
                                    <div className="col-span-2">
                                        <DropdownTailwind
                                            label={"Campaign type"}
                                            selected={this.renders.promotionType()}
                                            options={[{ id: 1, name: "App install", value: "APP_INSTALL" }]}
                                            onChange={async (value) => {
                                                /*
                                                if (this.state.wizard) {
                                                    this.state.updated.objective = value;
                                                    this.state.updated.goal = value.goal;
                                                    this.state.updated.billing_event = value.billing_event;
                                                    await this.setState({
                                                        updated: this.state.updated
                                                    })
            
                                                    //UPDATE CONNECTED ADGROUPS
                                                    this.functions.updateAdgroups();
                                                }
                                                */
                                            }}
                                        />
                                    </div>
                                    <div className='col-span-1'>
                                        <div className='-mb-4 text-xs font-medium text-gray-700 flex flex-1'>Use iOS 14 dedicated campaign</div>
                                        <div className='flex items-center justify-center mt-5 border-gray-300 w-full bg-custom-input border-1.5 rounded-md' style={{ paddingTop: "0.5rem", paddingBottom: "0.6rem" }}>
                                            <div className="flex flex-1 ml-5">
                                                {this.state.updated.ios14_campaign ? "Active" : "Disabled"}
                                            </div>
                                            <div className="relative overflow-hidden mr-5">
                                                <SwitchTailwind
                                                    value={this.renders.ios14_campaign()}
                                                    onSwitch={async () => {
                                                        this.state.updated.ios14_campaign = !this.state.updated.ios14_campaign;
                                                        if (this.state.updated.ios14_campaign) {
                                                            this.state.updated.budget_optimization = { id: 1, name: "No", value: false };
                                                        }
                                                        await this.promisedSetState({
                                                            updated: this.state.updated
                                                        });
                                                        this.functions.updateAdgroups();
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className={"col-span-3"}>
                                        <div className={"w-full flex-row items-center flex-1 transition-all duration-1000"}>
                                            {
                                                this.renders.ios14_campaign() &&
                                                <div className={"w-full flex-col flex-1"}>
                                                    <DropdownTailwind
                                                        label={"App"}
                                                        error={this.renders.app().id === 0 && this.renders.ios14_campaign() ? true : false}
                                                        selected={this.renders.app()}
                                                        options={this.state.apps.options.filter((item) => { return item.platform === "IOS" })}
                                                        loading={this.state.apps.loading}
                                                        onChange={async (value) => {
                                                            this.state.updated.app = value;
                                                            await this.promisedSetState({
                                                                updated: this.state.updated
                                                            });
                                                            this.functions.updateAdgroups();
                                                        }}
                                                    />
                                                </div>
                                            }
                                            <div className={"w-full flex transition-all duration-1000 overflow-hidden text-sm text-blue-500 rounded-sm bg-blue-100 px-2 " + (this.state.updated.ios14_campaign ? " h-0" : " h-auto")}>
                                                <InformationCircleIcon className="h-5 w-5 text-blue-500 mr-1" />
                                                Devices using iOS 14.5 or later iOS versions make up a large part of traffic. Turn on the toggle to ensure you're able to reach these users. If the toggle is off, your ads will not reach devices using iOS 14.5 or later iOS versions.
                                            </div>
                                        </div>
                                    </div>

                                </>
                            }
                            {
                                !this.renders.ios14_campaign() &&
                                <>
                                    <div className="col-span-3">
                                        <DropdownTailwind
                                            label={"Campaign budget optimization"}
                                            selected={this.renders.budgetOptimization()}
                                            options={[
                                                { id: 1, name: "No", value: false },
                                                { id: 2, name: "Yes", value: true }
                                            ]}
                                            locked={((this.state.updated.scheduler && this.state.updated.scheduler.schedule && this.state.updated.scheduler.planer)) ? true : false}
                                            onChange={(value) => {
                                                this.state.updated.budget_optimization = value;
                                                if (value.value) {
                                                    this.state.updated.budget_limit = { id: 1, name: "No", value: false };
                                                    if (!this.state.updated.budget_mode) {
                                                        this.state.updated.budget_mode = { id: 1, name: "Daily budget", value: "BUDGET_MODE_DAY" };
                                                    }
                                                }
                                                this.setState({
                                                    updated: this.state.updated
                                                })
                                            }}
                                        />
                                    </div>
                                    {
                                        this.renders.budgetOptimization().id === 2 &&
                                        <div className="col-span-3">
                                            <div className="bg-blue-100 rounded-md inline-flex w-full p-4 text-sm font-medium text-blue-500">
                                                <InformationCircleIcon className="h-5 w-5 text-blue-500 mr-2" />
                                                To optimize performance, add at least two adgroups to each campaign.
                                            </div>
                                        </div>
                                    }
                                    {
                                        this.renders.budgetOptimization().id === 1 &&
                                        <div className="col-span-3">
                                            <DropdownTailwind
                                                label={"Set campaign budget"}
                                                selected={this.renders.budgetLimit()}
                                                options={[
                                                    { id: 1, name: "No", value: false },
                                                    { id: 2, name: "Yes", value: true }
                                                ]}
                                                locked={false}
                                                onChange={(value) => {
                                                    this.state.updated.budget_limit = value;
                                                    if (!this.state.updated.budget_mode) {
                                                        this.state.updated.budget_mode = { id: 1, name: "Daily budget", value: "BUDGET_MODE_DAY" };
                                                    }
                                                    this.setState({
                                                        updated: this.state.updated
                                                    })
                                                }}
                                            />
                                        </div>
                                    }
                                    {
                                        (this.renders.budgetOptimization().id === 2 || this.renders.budgetLimit().id === 2) &&
                                        <div className="col-span-3 grid grid-cols-6 col-gap-2">
                                            <div className="col-span-2">
                                                <DropdownTailwind
                                                    label={"Mode"}
                                                    selected={this.renders.budgetMode()}
                                                    options={[
                                                        { id: 1, name: "Daily budget", value: "BUDGET_MODE_DAY" },
                                                        { id: 2, name: "Lifetime budget", value: "BUDGET_MODE_TOTAL" }
                                                    ]}
                                                    onChange={(value) => {
                                                        this.state.updated.budget_mode = value;
                                                        this.setState({
                                                            updated: this.state.updated
                                                        })
                                                    }}
                                                />
                                            </div>

                                            <div className="col-span-4">
                                                <InputTailwind
                                                    error={campaignTiktok.validate(this.state.updated)}
                                                    label={"Budget"}
                                                    leftSection={(this.state.currency !== "" ? true : false)}
                                                    leftSectionLabel={(this.state.currency)}
                                                    type={'number'}
                                                    value={this.renders.budgetMode().id === 1 ? this.renders.dailyBudget() : this.renders.totalBudget()}
                                                    onChange={(value) => {
                                                        if (this.renders.budgetMode().id === 1) {
                                                            this.state.updated.daily_budget = value;
                                                        } else {
                                                            this.state.updated.total_budget = value;
                                                        }
                                                        this.setState({
                                                            updated: this.state.updated
                                                        })
                                                    }}
                                                />
                                            </div>

                                        </div>
                                    }
                                    {
                                        this.renders.budgetOptimization().id === 2 && false &&
                                        <div className="col-span-3">
                                            <InputTailwind
                                                error={campaignTiktok.validate(this.state.updated)}
                                                label={"Daily campaign budget"}
                                                leftSection={(this.state.currency !== "" ? true : false)}
                                                leftSectionLabel={(this.state.currency)}
                                                type={'number'}
                                                locked={this.state.updated.scheduler && this.state.updated.scheduler.schedule && this.state.updated.scheduler.planer}
                                                disabled={this.state.updated.scheduler && this.state.updated.scheduler.schedule && this.state.updated.scheduler.planer}
                                                value={this.renders.dailyBudget()}
                                                onChange={(value) => {
                                                    this.state.updated.daily_budget = value;
                                                    this.setState({
                                                        updated: this.state.updated
                                                    })
                                                }}
                                            />
                                        </div>
                                    }


                                    {
                                        this.renders.budgetOptimization().id === 1 && this.renders.budgetLimit().id === 2 && false &&
                                        <div className="col-span-3 grid grid-cols-6 col-gap-2">
                                            <div className="col-span-2">
                                                <DropdownTailwind
                                                    label={"Mode"}
                                                    selected={this.renders.budgetMode()}
                                                    options={[
                                                        { id: 1, name: "Daily budget", value: "BUDGET_MODE_DAY" },
                                                        { id: 2, name: "Lifetime budget", value: "BUDGET_MODE_TOTAL" }
                                                    ]}
                                                    onChange={(value) => {
                                                        this.state.updated.budget_mode = value;
                                                        this.setState({
                                                            updated: this.state.updated
                                                        })
                                                    }}
                                                />
                                            </div>
                                            {
                                                this.renders.budgetLimit().id === 2 && this.renders.budgetOptimization().id === 1 && this.renders.budgetMode().id === 1 &&
                                                <div className="col-span-4">
                                                    <InputTailwind
                                                        error={campaignTiktok.validate(this.state.updated)}
                                                        label={"Budget"}
                                                        leftSection={(this.state.currency !== "" ? true : false)}
                                                        leftSectionLabel={(this.state.currency)}
                                                        type={'number'}
                                                        value={this.renders.dailyBudget()}
                                                        onChange={(value) => {
                                                            this.state.updated.daily_budget = value;
                                                            this.setState({
                                                                updated: this.state.updated
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            }
                                            {
                                                this.renders.budgetLimit().id === 2 && this.renders.budgetOptimization().id === 1 && this.renders.budgetMode().id === 2 && false &&
                                                <div className="col-span-4">
                                                    <InputTailwind
                                                        error={campaignTiktok.validate(this.state.updated)}
                                                        label={"Budget"}
                                                        leftSection={(this.state.currency !== "" ? true : false)}
                                                        leftSectionLabel={(this.state.currency)}
                                                        type={'number'}
                                                        value={this.renders.totalBudget()}
                                                        onChange={(value) => {
                                                            this.state.updated.total_budget = value;
                                                            this.setState({
                                                                updated: this.state.updated
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            }
                                        </div>
                                    }

                                    {
                                        this.renders.budgetOptimization().id === 2 && false &&
                                        <div className="col-span-3">
                                            <DropdownTailwind
                                                label={"Bid strategy"}
                                                selected={this.renders.bidStrategy()}
                                                options={this.state.bid_strategies}
                                                locked={true}
                                                onChange={(value) => {
                                                    this.state.updated.bid_strategy = value;
                                                    this.setState({
                                                        updated: this.state.updated
                                                    })
                                                }}
                                            />
                                        </div>
                                    }
                                    {
                                        this.renders.budgetOptimization().id === 2 && false &&
                                        <div className="col-span-3">
                                            <DropdownTailwind
                                                label={"Delivery Type"}
                                                locked={true}
                                                selected={this.renders.deliveryType()}
                                                options={this.state.pacings}
                                                onChange={(value) => {
                                                    this.state.updated.delivery_type = value;
                                                    this.setState({
                                                        updated: this.state.updated
                                                    })
                                                }}
                                            />
                                        </div>
                                    }
                                </>
                            }
                        </>
                    }

                    {/*SCHEDULER*/}
                    {
                        this.state.tab.id == 2 &&
                        <>
                            <div className='col-span-3'>
                                <div className='-mb-4 text-xs font-medium text-gray-700 flex flex-1'>Schedule</div>
                                <div className='flex items-center justify-center mt-5 mb-3 border-gray-300 w-full bg-custom-input border-1.5 rounded-md' style={{ paddingTop: "0.5rem", paddingBottom: "0.6rem" }}>
                                    <div className="flex flex-1 ml-5">
                                        {this.state.updated.scheduler.schedule ? "Active" : "Disabled"}
                                    </div>
                                    <div className="relative overflow-hidden mr-5">
                                        <SwitchTailwind
                                            value={this.state.updated.scheduler.schedule ? true : false}
                                            onSwitch={async () => {
                                                this.state.updated.scheduler.schedule = !this.state.updated.scheduler.schedule;
                                                if (this.state.updated.scheduler.schedule && this.state.updated.scheduler.planer) {
                                                    this.state.updated.budget_optimization = { id: 2, name: "Yes", value: true };
                                                    if (budgetPlannerGraph.main(this.state.updated.scheduler).datasets[0].data[0] !== Infinity) {
                                                        this.state.updated.daily_budget = budgetPlannerGraph.main(this.state.updated.scheduler).datasets[0].data[0];
                                                    } else {
                                                        this.state.updated.daily_budget = 0;
                                                    }
                                                }
                                                await this.promisedSetState({
                                                    updated: this.state.updated
                                                });
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={'col-span-3 grid grid-cols-12 gap-4 transform transition-all duration-500 ease-in-out overflow-hidden' + (!this.state.updated.scheduler.schedule ? " h-0" : "")}>
                                <div className='col-span-12'>
                                    <div className={"text-xs font-medium mb-1 text-gray-700"}>
                                        Days
                                    </div>
                                    <div className='flex flex-wrap'>
                                        {
                                            [
                                                { name: "Monday", property: "scheduleMonday" },
                                                { name: "Tuesday", property: "scheduleTuesday" },
                                                { name: "Wednesday", property: "scheduleWednesday" },
                                                { name: "Thursday", property: "scheduleThursday" },
                                                { name: "Friday", property: "scheduleFriday" },
                                                { name: "Saturday", property: "scheduleSaturday" },
                                                { name: "Sunday", property: "scheduleSunday" }
                                            ].map((weekday) => {
                                                return (
                                                    <div onClick={async () => {
                                                        if (this.state.updated.scheduler.weekdays[weekday.property]) {
                                                            delete (this.state.updated.scheduler.weekdays[weekday.property]);
                                                        } else {
                                                            this.state.updated.scheduler.weekdays[weekday.property] = true;
                                                        }
                                                        if (this.state.updated.scheduler.planer) {
                                                            if (budgetPlannerGraph.main(this.state.updated.scheduler).datasets[0].data[0] !== Infinity) {
                                                                this.state.updated.daily_budget = budgetPlannerGraph.main(this.state.updated.scheduler).datasets[0].data[0];
                                                            } else {
                                                                this.state.updated.daily_budget = 0;
                                                            }
                                                        }
                                                        await this.promisedSetState({
                                                            updated: this.state.updated
                                                        })


                                                    }} className={(this.state.updated.scheduler.weekdays[weekday.property] ? "bg-indigo-100 text-indigo-700" : "bg-gray-100 text-gray-500 hover:text-indigo-500") + " cursor-pointer inline-flex flex-row rounded-full mb-2 mr-2 items-center py-1.5 px-3 text-sm font-medium"}>
                                                        <div>{weekday.name}</div>
                                                        <div className="ml-3">
                                                            <div className={(this.state.updated.scheduler.weekdays[weekday.property] ? "border-indigo-500 bg-indigo-500" : "border-gray-500 ") + " h-5 w-5 flex justify-center items-center rounded-full border-2"}>
                                                                <CheckIcon className={(this.state.updated.scheduler.weekdays[weekday.property] ? "" : "opacity-0") + " h-5 w-5 text-white"} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                                <div className='col-span-12 overflow-hidden transition-all duration-500 grid grid-cols-4 gap-4'>
                                    <div className='col-span-4'>
                                        <div className='flex flex-row overflow-hidden transition-all duration-500'>
                                            <div className={'flex flex-col transition-all transform duration-500 overflow-hidden' + (!this.state.updated.scheduler.planer ? " flex-1" : " pr-4")}>
                                                <div className='-mb-4 text-xs font-medium text-gray-700 flex flex-1'>Daily budget planer</div>
                                                <div className='flex items-center justify-center my-5 border-gray-300 w-full bg-custom-input border-1.5 rounded-md' style={{ paddingTop: "0.5rem", paddingBottom: "0.6rem" }}>
                                                    <div className="flex flex-1 ml-5 min-w-24">
                                                        {(this.state.updated.scheduler.planer ? "Active" : "Disabled")}
                                                    </div>
                                                    <div className={"relative overflow-hidden mr-5"}>
                                                        <SwitchTailwind
                                                            value={this.state.updated.scheduler.planer}
                                                            disabled={this.renders.objective().id === 2}
                                                            onSwitch={async () => {

                                                                this.state.updated.scheduler.planer = !this.state.updated.scheduler.planer;

                                                                if (this.state.updated.scheduler.planer) {
                                                                    this.state.updated.budget_optimization = { id: 2, name: "Yes", value: true };
                                                                    if (budgetPlannerGraph.main(this.state.updated.scheduler).datasets[0].data[0] !== Infinity) {
                                                                        this.state.updated.daily_budget = budgetPlannerGraph.main(this.state.updated.scheduler).datasets[0].data[0];
                                                                    } else {
                                                                        this.state.updated.daily_budget = 0;
                                                                    }
                                                                }
                                                                await this.promisedSetState({
                                                                    updated: this.state.updated
                                                                });
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={'flex transition-all transform duration-500 overflow-hidden ' + (this.state.updated.scheduler.planer ? "pr-4" : "w-0 hidden")}>
                                                <div className=' pr-2'>
                                                    <InputTailwind
                                                        locked={!this.state.updated.scheduler.planer}
                                                        disabled={!this.state.updated.scheduler.planer}
                                                        label={"Total monthly"}
                                                        leftSection={(this.state.currency !== "" ? true : false)}
                                                        leftSectionLabel={(this.state.currency)}
                                                        value={this.state.updated.scheduler.schedule_budget}
                                                        type={"number"}
                                                        onChange={async (value) => {
                                                            this.state.updated.scheduler.schedule_budget = value;
                                                            if (budgetPlannerGraph.main(this.state.updated.scheduler).datasets[0].data[0] !== Infinity) {
                                                                this.state.updated.daily_budget = budgetPlannerGraph.main(this.state.updated.scheduler).datasets[0].data[0];
                                                            } else {
                                                                this.state.updated.daily_budget = 0;
                                                            }
                                                            await this.promisedSetState({
                                                                updated: this.state.updated
                                                            });
                                                        }}
                                                    />
                                                </div>
                                                <div className=''>
                                                    <InputTailwind
                                                        locked={true}
                                                        disabled={true}
                                                        leftSection={(this.state.currency !== "" ? true : false)}
                                                        leftSectionLabel={(this.state.currency)}
                                                        label={"Daily budget"}
                                                        value={this.state.updated.daily_budget}
                                                        type={"number"}
                                                    />
                                                </div>
                                            </div>
                                            <div className={'flex transition-all transform duration-500 overflow-hidden ' + (this.state.updated.scheduler.planer ? "flex-1" : "w-0 hidden")}>
                                                <div className='w-full'>
                                                    <InputDatepickerTailwind
                                                        disabled={!this.state.updated.scheduler.planer}
                                                        locked={!this.state.updated.scheduler.planer}
                                                        placeholder={moment(this.state.updated.scheduler.start_date).format("MM/DD/YYYY")}
                                                        label={"Start date"}
                                                        value={moment(this.state.updated.scheduler.start_date).format("YYYY-MM-DD")}
                                                        onChange={async (value) => {
                                                            this.state.updated.scheduler.start_date = moment(value).format("YYYY-MM-DD");
                                                            if (budgetPlannerGraph.main(this.state.updated.scheduler).datasets[0].data[0] !== Infinity) {
                                                                this.state.updated.daily_budget = budgetPlannerGraph.main(this.state.updated.scheduler).datasets[0].data[0];
                                                            } else {
                                                                this.state.updated.daily_budget = 0;
                                                            }
                                                            await this.promisedSetState({
                                                                updated: this.state.updated
                                                            });
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            {
                                                this.state.updated.scheduler.order_info && this.state.updated.scheduler.order_info.maxBudgetSubAction &&
                                                <div className={'flex transition-all transform duration-500 overflow-hidden pl-4 ' + (this.state.updated.scheduler.planer ? "flex-1" : "w-0 hidden")}>
                                                    <div className=''>
                                                        <InputTailwind
                                                            locked={true}
                                                            disabled={true}
                                                            label={"Order pacing"}
                                                            value={this.state.updated.scheduler.order_info.maxBudgetSubAction && this.state.updated.scheduler.order_info.maxBudgetSubAction.id ? (this.state.updated.scheduler.order_info.maxBudgetSubAction.id === 1 ? "Spend total" : (this.state.updated.scheduler.order_info.maxBudgetSubAction.id === 2 ? "Spend pacing" : "Unkown")) : "Unkown"}
                                                        />
                                                    </div>

                                                </div>
                                            }
                                        </div>
                                        {
                                            this.state.updated.scheduler.planer && this.state.updated.scheduler.schedule_budget > 0 && budgetPlannerGraph.main(this.state.updated.scheduler).datasets[0].data[0] === Infinity &&
                                            <div className='flex flex-row border-t mt-4'>
                                                <div className="bg-orange-100 w-full p-4 mt-4 text-orange-500 text-sm font-medium rounded-md">
                                                    OBS! No remaining weekdays this month match your selection. Please select a different start date or weekdays.
                                                </div>
                                            </div>
                                        }
                                    </div>

                                    <div className='col-span-4 transform transition-all duration-500 '>
                                        <div className={" mt-4 pt-4" + (this.state.updated.scheduler.planer ? " h-64" : " h-0")}>
                                            <Bar
                                                options={{
                                                    maintainAspectRatio: false,
                                                    interaction: {
                                                        intersect: false,
                                                    },
                                                    legend: {
                                                        display: false
                                                    },
                                                    scales: {
                                                        yAxes: [{
                                                            ticks: {
                                                                suggestedMin: 0
                                                            }
                                                        }]
                                                    },
                                                }}
                                                data={budgetPlannerGraph.main(this.state.updated.scheduler)}
                                            />
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </>

                    }

                </div>
            </>
        )
    }
}

export default EditTikTokCampaign;
