import React, { Component, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react'
import { ViewBoardsIcon, PhotographIcon, XIcon } from '@heroicons/react/outline'
import moment from 'moment';
import 'react-slidedown/lib/slidedown.css';
import 'sweetalert/dist/sweetalert.css';
import SelectCellSettings from "./advancedReportCellSlideIn/selectCellSettings";
import SelectCellMetric from "./advancedReportCellSlideIn/selectCellMetric";
import SelectCellDaterange from "./advancedReportCellSlideIn/selectCellDaterange";
import SelectCellChart from "./advancedReportCellSlideIn/selectCellChart";
import SelectCellBreakdown from "./advancedReportCellSlideIn/selectCellBreakdown";
import SelectCellDatasource from "./advancedReportCellSlideIn/selectCellDatasource";
import SelectCellType from "./advancedReportCellSlideIn/selectCellType";

class AdvancedReportCellSlideIn extends Component {

    constructor(props) {
        super(props);
        this.state = {
            datasource: true,
            data: {},
            tab: {},
            cell: {},
            accounts: [],
            campaigns: [],
            adgroups: [],
            ads: [],
            selected_channels: {},
            selected_accounts: {},
            selected_campaigns: {},
            selected_adgroups: {},
            selected_ads: {},
            selected_channels_internal: {},
            selected_accounts_internal: {},
            selected_campaigns_internal: {},
            selected_adgroups_internal: {},
            selected_ads_internal: {},
            premium: false,
            celltype: true,
            metrics: {},
            channel_breakdowns: [],
            analytics_breakdowns: [],
            closed: true
        };
    };

    componentDidMount() {
        this.setState({
            closed: this.props.closed,
            all_metrics: this.props.metrics,
            premium: this.props.premium,
            accounts: this.props.accounts,
            campaigns: this.props.campaigns,
            adgroups: this.props.adgroups,
            ads: this.props.ads,
            template: this.props.template,
            masterTemplate: this.props.masterTemplate,
            metrics: this.props.metrics
        });
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            closed: nextProps.closed,
            metrics: nextProps.metrics,
            premium: nextProps.premium,
            template: nextProps.template,
            masterTemplate: nextProps.masterTemplate,
            accounts: nextProps.accounts,
            campaigns: nextProps.campaigns,
            adgroups: nextProps.adgroups,
            ads: nextProps.ads,
        });
    }

    functions = {
        initiate: (channels, accounts, campaigns, adgroups, ads, group) => {

            if (!channels) {
                channels = {};
            }
            if (!accounts) {
                accounts = {};
            }
            if (!campaigns) {
                campaigns = {};
            }
            if (!adgroups) {
                adgroups = {};
            }
            if (!ads) {
                ads = {};
            }

            let cell = null;
            if (this.props.cell) {
                if (this.props.cell.typeOfBlock == "input" || this.props.cell.typeOfBlock == "img") {
                    cell = JSON.parse(JSON.stringify(this.props.cell));
                } else {
                    delete this.props.cell.data;
                    delete this.props.cell.parsed_data;
                    delete this.props.cell.parsed_items;
                    delete this.props.cell.parsed_metrics;
                    cell = JSON.parse(JSON.stringify(this.props.cell));
                }
            }

            this.setState({
                celltype: true,
                datasource: !this.state.premium,
                breakdown: false,
                daterange: false,
                piechart: false,
                settings: false,
                update_cell: false,
                init_tab: { ...this.props.tab },
                tab: { ...this.props.tab },
                cell: cell,
                selected_channels_internal: JSON.parse(JSON.stringify(channels)),
                selected_accounts_internal: JSON.parse(JSON.stringify(accounts)),
                selected_campaigns_internal: JSON.parse(JSON.stringify(campaigns)),
                selected_adgroups_internal: JSON.parse(JSON.stringify(adgroups)),
                selected_ads_internal: JSON.parse(JSON.stringify(ads)),
            }, () => {

                if (this.state.cell && this.state.cell.typeOfBlock) {
                    this.state.update_cell = true;
                }

                delete this.state.tab.celltype;

                if (this.state.cell) {
                    this.state.tab.settings = this.state.cell.settings;
                    if (!this.state.tab.settings) {
                        this.state.tab.settings = {};
                    }

                    if (this.state.cell.typeOfBlock === 'input') {
                        this.state.tab.celltype = {
                            title: "Text",
                            subtitle: "Write text in the cell",
                            icon: "file-text",
                            value: "text"
                        };
                    } else if (this.state.cell.typeOfBlock === 'img') {
                        this.state.tab.celltype = {
                            title: "Image",
                            subtitle: "Upload an image to the cell",
                            icon: "image",
                            value: "image"
                        };
                    } else if (this.state.cell.typeOfBlock === 'preview') {
                        this.state.tab.celltype = {
                            title: "Preview",
                            subtitle: "Preview an ad creative",
                            icon: "image",
                            value: "preview",
                        };
                        if (cell.preview_placement && cell.preview_placement.value === "story") {
                            this.state.tab.settings.selected_placement = { id: 1, name: "Story", description: "Ads that appear in a user's swipable content", value: "story", icon: <ViewBoardsIcon style={{ width: 14, height: 14 }} />, allowed: { facebook: true, snapchat: true } }
                        } else if (cell.preview_placement && cell.preview_placement.value === "no_frame") {
                            this.state.tab.settings.selected_placement = { id: 2, name: "No frame", description: "Remove ad frame and only show media", value: "no_frame", icon: <PhotographIcon style={{ width: 14, height: 14 }} />, allowed: { facebook: true, linkedin: true, tiktok: true, twitter: true, snapchat: true } }
                        } else if (cell.preview_placement && cell.preview_placement.value === "feed_instagram") {
                            this.state.tab.settings.selected_placement = { id: 3, name: "Feed (Instagram)", description: "Ads that appear in a users scrollable content on Instagram", icon: <ViewBoardsIcon style={{ width: 14, height: 14, transform: 'rotate(-90deg)' }} />, value: "feed_instagram", allowed: { facebook: true } }
                        } else if (cell.preview_placement && cell.preview_placement.value === "story_instagram") {
                            this.state.tab.settings.selected_placement = { id: 4, name: "Story (Instagram)", description: "Ads that appear in a users swipable content on Instagram", icon: <ViewBoardsIcon style={{ width: 14, height: 14 }} />, value: "story_instagram", allowed: { facebook: true } }
                        } else if (cell.preview_placement && cell.preview_placement.value === "square") {
                            this.state.tab.settings.selected_placement = { id: 5, name: "Square", description: "Display ad in a square format", icon: <PhotographIcon style={{ width: 14, height: 14 }} />, value: "square", allowed: { google: true } }
                        } else if (cell.preview_placement && cell.preview_placement.value === "billboard") {
                            this.state.tab.settings.selected_placement = { id: 6, name: "Billboard", description: "Display ad in a rectangle format", icon: <PhotographIcon style={{ width: 14, height: 14 }} />, value: "billboard", allowed: { google: true } }
                        } else if (cell.preview_placement && cell.preview_placement.value === "skyscraper") {
                            this.state.tab.settings.selected_placement = { id: 7, name: "Skyscraper", description: "Display ad in a vertical format", icon: <PhotographIcon style={{ width: 14, height: 14 }} />, value: "skyscraper", allowed: { google: true } }
                        } else {
                            this.state.tab.settings.selected_placement = { id: 0, name: "Feed", description: "Ads that appear in a user's scrollable content", value: "feed", icon: <ViewBoardsIcon style={{ width: 14, height: 14, transform: 'rotate(-90deg)' }} />, allowed: { facebook: true, linkedin: true, tiktok: true, twitter: true } }
                        }
                    } else if (this.state.cell.typeOfBlock === 'number') {
                        this.state.tab.celltype = {
                            id: this.state.cell.celltype && this.state.cell.celltype.id ? this.state.cell.celltype.id : null,
                            title: "Aggregated number",
                            subtitle: "Summarize data from datasources",
                            icon: "tag",
                            value: "number"
                        };
                        this.state.tab.metrics = this.state.cell.metrics;
                        this.state.tab.daterange = this.state.cell.daterange;
                        if (!this.state.cell.channel_breakdowns) {
                            this.state.tab.channel_breakdowns = {
                                "google_analytics": { "overview": { name: "Overview", value: "overview", parent: "Popular" } },
                                "google_analytics_4": { "overview": { name: "Overview", value: "overview", parent: "Popular" } },
                                "adform": { "overview": { name: "Overview", value: "overview", parent: "Popular" } },
                                "facebook": { "overview": { name: "Overview", value: "overview", parent: "Popular" } },
                                "google": { "overview": { name: "Overview", value: "overview", parent: "Popular" } },
                                "bidtheatre": { "overview": { name: "Overview", value: "overview", parent: "Popular" } },
                                "tiktok": { "overview": { name: "Overview", value: "overview", parent: "Popular" } },
                                "twitter": { "overview": { name: "Overview", value: "overview", parent: "Popular" } },
                                "snapchat": { "overview": { name: "Overview", value: "overview", parent: "Popular" } },
                                "bing": { "overview": { name: "Overview", value: "overview", parent: "Popular" } },
                                "linkedin": { "overview": { name: "Overview", value: "overview", parent: "Popular" } },
                                "dv360": { "overview": { name: "Overview", value: "overview", parent: "Popular" } },
                                "cm360": { "overview": { name: "Overview", value: "overview", parent: "Popular" } },
                                "marchex": { "overview": { name: "Overview", value: "overview", parent: "Popular" } }
                            }
                        } else {
                            this.state.tab.channel_breakdowns = this.state.cell.channel_breakdowns;
                        }
                    } else if (this.state.cell.typeOfBlock === 'chart') {
                        this.state.tab.celltype = {
                            title: "Chart",
                            subtitle: "Show data in a chart",
                            icon: "pie-chart",
                            value: "chart"
                        };
                        this.state.tab.breakdown = this.state.cell.breakdown;
                        this.state.tab.chart = this.state.cell.chart;
                        this.state.tab.metrics = this.state.cell.metrics;
                        this.state.tab.daterange = this.state.cell.daterange;
                        this.state.tab.channel_breakdowns = this.state.cell.channel_breakdowns;
                    } else if (this.state.cell.typeOfBlock === 'table') {
                        this.state.tab.celltype = {
                            title: "Table",
                            subtitle: "Show data in a table",
                            icon: "align-justify",
                            value: "table"
                        };
                        this.state.tab.breakdown = this.state.cell.breakdown;
                        this.state.tab.chart = this.state.cell.chart;
                        this.state.tab.metrics = this.state.cell.metrics;
                        this.state.tab.table_metrics_index = this.state.cell.table_metrics_index;
                        this.state.tab.daterange = this.state.cell.daterange;
                        this.state.tab.channel_breakdowns = this.state.cell.channel_breakdowns;
                    }

                    //SET TABLE REMOVES
                    this.state.tab.remove_preview = this.state.cell.remove_preview;
                    this.state.tab.remove_breakdown = this.state.cell.remove_breakdown;
                    this.state.tab.remove_breakdown_value = this.state.cell.remove_breakdown_value;
                    this.state.tab.remove_channel = this.state.cell.remove_channel;
                    this.state.tab.remove_id = this.state.cell.remove_id;
                    this.state.tab.remove_level = this.state.cell.remove_level;
                    this.state.tab.remove_name = this.state.cell.remove_name;

                    this.state.tab.group = this.state.cell.group;

                    //SET COLOR AND NAME
                    if (this.state.selected_campaigns_internal) {
                        for (let id in this.state.selected_campaigns_internal) {
                            if (this.state.tab.settings && this.state.tab.settings.color && this.state.tab.settings.color[id]) {
                                this.state.selected_campaigns_internal[id].hex = this.state.tab.settings.color[id];
                            }
                            this.state.campaigns.map((item) => {
                                if (item.id == id && item.name) {
                                    this.state.selected_campaigns_internal[id].name = item.name;
                                }
                            })
                        }
                    }
                    if (this.state.selected_adgroups_internal) {
                        for (let id in this.state.selected_adgroups_internal) {
                            if (this.state.tab.settings && this.state.tab.settings.color && this.state.tab.settings.color[id]) {
                                this.state.selected_adgroups_internal[id].hex = this.state.tab.settings.color[id];
                            }
                            this.state.adgroups.map((item) => {
                                if (item.id == id && item.name) {
                                    this.state.selected_adgroups_internal[id].name = item.name;
                                }
                            })
                        }
                    }
                    if (this.state.selected_ads_internal) {
                        for (let id in this.state.selected_ads_internal) {
                            if (this.state.tab.settings && this.state.tab.settings.color && this.state.tab.settings.color[id]) {
                                this.state.selected_ads_internal[id].hex = this.state.tab.settings.color[id];
                            }
                            this.state.ads.map((item) => {
                                if (item.id == id && item.name) {
                                    this.state.selected_ads_internal[id].name = item.name;
                                }
                            })
                        }
                    }

                    //CHECK IF TEMPLATE AND IF NEW OR OLD CELL
                    if (this.state.cell.typeOfBlock !== 'input' && this.state.cell.typeOfBlock !== 'img') {

                        if (!this.state.cell.data_sources) {
                            this.state.cell.data_sources = {};
                        }
                        if (!this.state.cell.data_sources.campaigns) {
                            this.state.cell.data_sources.campaigns = {};
                        }
                        if (!this.state.cell.data_sources.adgroups) {
                            this.state.cell.data_sources.adgroups = {};
                        }
                        if (!this.state.cell.data_sources.ads) {
                            this.state.cell.data_sources.ads = {};
                        }

                        if (
                            this.state.template &&
                            this.state.cell.typeOfBlock &&
                            Object.keys(this.state.cell.data_sources.campaigns).length < 1 &&
                            Object.keys(this.state.cell.data_sources.adgroups).length < 1 &&
                            Object.keys(this.state.cell.data_sources.ads).length < 1
                        ) {
                            this.state.celltype = false;
                            this.state.datasource = true;
                        }
                    }

                } else {

                    if (group) {
                        this.state.tab.group = group;
                    }

                    this.state.tab.cellType = null;
                    this.state.tab.metrics = {};
                    this.state.tab.channel_breakdowns = {
                        "google_analytics": { "overview": { name: "Overview", value: "overview", parent: "Popular" } },
                        "google_analytics_4": { "overview": { name: "Overview", value: "overview", parent: "Popular" } },
                        "adform": { "overview": { name: "Overview", value: "overview", parent: "Popular" } },
                        "facebook": { "overview": { name: "Overview", value: "overview", parent: "Popular" } },
                        "google": { "overview": { name: "Overview", value: "overview", parent: "Popular" } },
                        "bidtheatre": { "overview": { name: "Overview", value: "overview", parent: "Popular" } },
                        "tiktok": { "overview": { name: "Overview", value: "overview", parent: "Popular" } },
                        "twitter": { "overview": { name: "Overview", value: "overview", parent: "Popular" } },
                        "snapchat": { "overview": { name: "Overview", value: "overview", parent: "Popular" } },
                        "bing": { "overview": { name: "Overview", value: "overview", parent: "Popular" } },
                        "linkedin": { "overview": { name: "Overview", value: "overview", parent: "Popular" } },
                        "dv360": { "overview": { name: "Overview", value: "overview", parent: "Popular" } },
                        "cm360": { "overview": { name: "Overview", value: "overview", parent: "Popular" } },
                        "google_search_console": { "overview": { name: "Overview", value: "overview", parent: "Popular" } },
                        "marchex": { "overview": { name: "Overview", value: "overview", parent: "Popular" } },
                        "basic": {
                            "overview": {
                                title: "Overview",
                                subtitle: "Aggregated data",
                                icon: "globe",
                                value: "overview"
                            }
                        }
                    };

                }

                this.setState({
                    celltype: this.state.celltype,
                    datasource: this.state.datasource,
                    update_cell: this.state.update_cell,
                    cell: this.state.cell,
                    tab: this.state.tab
                })

            })
        },
        valid: () => {

            let valid = false;

            //TEXT AND IMAGE
            if (this.state.tab.celltype && (this.state.tab.celltype.value === "image" || this.state.tab.celltype.value === "text")) {
                valid = true;
            }

            //PREVIEW
            if (this.state.tab.celltype && this.state.tab.celltype.value === "preview") {
                let datasource = this.functions.adsOnlySelected() || this.functions.accountsOnlySelected() || this.functions.channelsOnlySelected();
                if (datasource) {
                    valid = true;
                }
            }

            //NUMBER
            if (this.state.tab.celltype && this.state.tab.celltype.value === "number") {
                let datasource = this.functions.anythingSelected();
                let daterange = ((this.state.tab.daterange && this.state.tab.daterange.value !== "custom_dates") || (this.state.tab.daterange && this.state.tab.daterange.value === "custom_dates" && this.state.tab.daterange.start_date && this.state.tab.daterange.end_date));
                let metrics = this.state.tab.metrics && Object.keys(this.state.tab.metrics).length > 0;
                if (datasource && daterange && metrics) {
                    valid = true;
                }
            }

            //TABLE
            if (this.state.tab.celltype && this.state.tab.celltype.value === "table") {
                let datasource = this.functions.anythingSelected();
                let daterange = ((this.state.tab.daterange && this.state.tab.daterange.value !== "custom_dates") || (this.state.tab.daterange && this.state.tab.daterange.value === "custom_dates" && this.state.tab.daterange.start_date && this.state.tab.daterange.end_date));
                let breakdowns = this.state.tab.channel_breakdowns && Object.keys(this.state.tab.channel_breakdowns).length > 0;
                let metrics = this.state.tab.metrics && Object.keys(this.state.tab.metrics).length > 0;
                if (datasource && daterange && breakdowns && metrics) {
                    valid = true;
                }
            }

            //LINE OR BAR CHART
            if (this.state.tab.celltype && this.state.tab.celltype.value === "chart") {
                let datasource = this.functions.anythingSelected();
                let daterange = ((this.state.tab.daterange && this.state.tab.daterange.value !== "custom_dates") || (this.state.tab.daterange && this.state.tab.daterange.value === "custom_dates" && this.state.tab.daterange.start_date && this.state.tab.daterange.end_date));
                let breakdowns = this.state.tab.channel_breakdowns && Object.keys(this.state.tab.channel_breakdowns).length > 0;
                let metrics = this.state.tab.metrics && Object.keys(this.state.tab.metrics).length > 0;
                let chart = this.state.tab.chart && (this.state.tab.chart.value == "line" || this.state.tab.chart.value == "bar");
                if (datasource && daterange && breakdowns && metrics && chart) {
                    valid = true;
                }
            }

            //DONUT CHART
            if (this.state.tab.celltype && this.state.tab.celltype.value === "chart") {
                let allow = false;
                let amount_of_datasources = 0;
                let breakdowns = {};
                let channels = {};
                amount_of_datasources += Object.keys(this.state.selected_channels_internal).length;
                amount_of_datasources += Object.keys(this.state.selected_accounts_internal).length;
                amount_of_datasources += Object.keys(this.state.selected_campaigns_internal).length;
                amount_of_datasources += Object.keys(this.state.selected_adgroups_internal).length;
                amount_of_datasources += Object.keys(this.state.selected_ads_internal).length;
                Object.keys(this.state.selected_channels_internal).map((item) => {
                    channels[this.state.selected_channels_internal[item].channel] = true;
                });
                Object.keys(this.state.selected_accounts_internal).map((item) => {
                    channels[this.state.selected_accounts_internal[item].channel] = true;
                });
                Object.keys(this.state.selected_campaigns_internal).map((item) => {
                    channels[this.state.selected_campaigns_internal[item].channel] = true;
                });
                Object.keys(this.state.selected_adgroups_internal).map((item) => {
                    channels[this.state.selected_adgroups_internal[item].channel] = true;
                });
                Object.keys(this.state.selected_ads_internal).map((item) => {
                    channels[this.state.selected_ads_internal[item].channel] = true;
                });
                for (let channel in this.state.tab.channel_breakdowns) {
                    for (let breakdown in this.state.tab.channel_breakdowns[channel]) {
                        if (breakdown !== "basic") {
                            breakdowns[breakdown] = channel;
                        }
                    }
                }
                if (amount_of_datasources > 0 && Object.keys(channels).length > 1 && ("overview" in breakdowns) && Object.keys(breakdowns).length === 1) {
                    allow = true;
                } else if (amount_of_datasources > 0 && Object.keys(channels).length === 1) {
                    allow = true;
                } else if (amount_of_datasources === 1) {
                    allow = true;
                }
                let daterange = ((this.state.tab.daterange && this.state.tab.daterange.value !== "custom_dates") || (this.state.tab.daterange && this.state.tab.daterange.value === "custom_dates" && this.state.tab.daterange.start_date && this.state.tab.daterange.end_date));
                let metrics = this.state.tab.metrics && Object.keys(this.state.tab.metrics).length === 1;
                let chart = this.state.tab.chart && this.state.tab.chart.value == "donut";
                if (allow && daterange && breakdowns && metrics && chart) {
                    valid = true;
                }
            }

            return valid;

        },
        create: () => {
            if (this.functions.valid()) {

                if (this.state.tab.daterange && this.state.tab.daterange.value === "7_days") {
                    this.state.tab.daterange.start_date = moment().subtract(7, 'days').format("YYYY-MM-DD");
                    this.state.tab.daterange.end_date = moment().subtract(1, 'days').format("YYYY-MM-DD");
                } else if (this.state.tab.daterange && this.state.tab.daterange.value === "10_days") {
                    this.state.tab.daterange.start_date = moment().subtract(10, 'days').format("YYYY-MM-DD");
                    this.state.tab.daterange.end_date = moment().subtract(1, 'days').format("YYYY-MM-DD");
                } else if (this.state.tab.daterange && this.state.tab.daterange.value === "14_days") {
                    this.state.tab.daterange.start_date = moment().subtract(14, 'days').format("YYYY-MM-DD");
                    this.state.tab.daterange.end_date = moment().subtract(1, 'days').format("YYYY-MM-DD");
                } else if (this.state.tab.daterange && this.state.tab.daterange.value === "20_days") {
                    this.state.tab.daterange.start_date = moment().subtract(20, 'days').format("YYYY-MM-DD");
                    this.state.tab.daterange.end_date = moment().subtract(1, 'days').format("YYYY-MM-DD");
                } else if (this.state.tab.daterange && this.state.tab.daterange.value === "30_days") {
                    this.state.tab.daterange.start_date = moment().subtract(30, 'days').format("YYYY-MM-DD");
                    this.state.tab.daterange.end_date = moment().subtract(1, 'days').format("YYYY-MM-DD");
                } else if (this.state.tab.daterange && this.state.tab.daterange.value === "last_week") {
                    this.state.tab.daterange.start_date = moment().subtract(1, 'weeks').startOf('isoWeek').format("YYYY-MM-DD");
                    this.state.tab.daterange.end_date = moment().subtract(1, 'weeks').endOf('isoWeek').format("YYYY-MM-DD");
                } else if (this.state.tab.daterange && this.state.tab.daterange.value === "last_month") {
                    this.state.tab.daterange.start_date = moment().subtract(1, 'months').startOf('month').format("YYYY-MM-DD");
                    this.state.tab.daterange.end_date = moment().subtract(1, 'months').endOf('month').format("YYYY-MM-DD");
                } else if (this.state.tab.daterange && this.state.tab.daterange.value === "this_month") {
                    this.state.tab.daterange.start_date = moment().startOf('month').format("YYYY-MM-DD");
                    if (moment().startOf('month').format("DD") == "01") {
                        this.state.tab.daterange.end_date = moment().format("YYYY-MM-DD");
                    } else {
                        this.state.tab.daterange.end_date = moment().subtract(1, 'days').format("YYYY-MM-DD");
                    }
                } else if (this.state.tab.daterange && this.state.tab.daterange.value === "custom_dates") {

                } else if (this.state.tab.daterange && this.state.tab.daterange.value === "x_days") {
                    if (this.state.tab.daterange.days && this.state.tab.daterange.days.value) {
                        this.state.tab.daterange.start_date = moment().subtract(this.state.tab.daterange.days.value, 'days').format("YYYY-MM-DD");
                        this.state.tab.daterange.end_date = moment().subtract(1, 'days').format("YYYY-MM-DD");
                    }
                }

                if (!this.state.tab.channel_breakdowns) {
                    this.state.tab.channel_breakdowns = {};
                }

                if (!this.state.cell) {
                    this.state.cell = {};
                }


                this.state.cell.remove_preview = this.state.tab.remove_preview;
                this.state.cell.remove_name = this.state.tab.remove_name;
                this.state.cell.remove_id = this.state.tab.remove_id;
                this.state.cell.remove_level = this.state.tab.remove_level;
                this.state.cell.remove_breakdown_value = this.state.tab.remove_breakdown_value;
                this.state.cell.remove_breakdown = this.state.tab.remove_breakdown;
                this.state.cell.remove_channel = this.state.tab.remove_channel;

                this.state.cell.group = this.state.tab.group ? this.state.tab.group : 1;

                this.state.cell.channel_breakdowns = this.state.tab.channel_breakdowns;
                this.state.cell.daterange = this.state.tab.daterange;
                this.state.cell.celltype = this.state.tab.celltype;
                this.state.cell.metrics = this.state.tab.metrics;

                this.state.cell.table_metrics_index = this.state.tab.table_metrics_index;
                this.state.cell.chart = this.state.tab.chart;
                this.state.cell.settings = this.state.tab.settings;

                if (!this.state.cell.settings) {
                    this.state.cell.settings = {};
                }

                this.state.cell.data_sources = {
                    channels: this.state.selected_channels_internal,
                    accounts: this.state.selected_accounts_internal,
                    campaigns: this.state.selected_campaigns_internal,
                    adgroups: this.state.selected_adgroups_internal,
                    ads: this.state.selected_ads_internal
                };

                //SET COLOR TO SETTINGS
                if (this.state.cell.settings && this.state.cell.data_sources) {
                    this.state.cell.settings.color = {};
                    for (let level in this.state.cell.data_sources) {
                        for (let id in this.state.cell.data_sources[level]) {
                            this.state.cell.settings.color[id] = this.state.cell.data_sources[level][id].hex;
                        }
                    }
                }

                //REMOVE COMPARE IF NOT VALID
                if (!this.functions.compareFormValidation()) {
                    delete this.state.cell.daterange.compare_dates;
                    delete this.state.tab.daterange.compare_dates;
                }

                //UPDATE CAMPAIGNS
                this.props.updateData(
                    this.state.cell
                );

                this.props.toggle();

            } else {

                //NOT VALID

            }


        },
        anythingSelected: () => {
            let total = 0;
            if (this.state.selected_channels_internal && Object.keys(this.state.selected_channels_internal).length > 0) {
                total = total + 1;
            }
            if (this.state.selected_accounts_internal && Object.keys(this.state.selected_accounts_internal).length > 0) {
                total = total + 1;
            }
            if (this.state.selected_campaigns_internal && Object.keys(this.state.selected_campaigns_internal).length > 0) {
                total = total + 1;
            }
            if (this.state.selected_adgroups_internal && Object.keys(this.state.selected_adgroups_internal).length > 0) {
                total = total + 1;
            }
            if (this.state.selected_ads_internal && Object.keys(this.state.selected_ads_internal).length > 0) {
                total = total + 1;
            }
            return total > 0
        },
        channelsOnlySelected: () => {
            let channels = 0;
            let accounts = 0;
            let campaigns = 0;
            let adgroups = 0;
            let ads = 0;
            if (this.state.selected_channels_internal && Object.keys(this.state.selected_channels_internal).length > 0) {
                channels = channels + 1;
            }
            if (this.state.selected_accounts_internal && Object.keys(this.state.selected_accounts_internal).length > 0) {
                accounts = accounts + 1;
            }
            if (this.state.selected_campaigns_internal && Object.keys(this.state.selected_campaigns_internal).length > 0) {
                campaigns = campaigns + 1;
            }
            if (this.state.selected_adgroups_internal && Object.keys(this.state.selected_adgroups_internal).length > 0) {
                adgroups = adgroups + 1;
            }
            if (this.state.selected_ads_internal && Object.keys(this.state.selected_ads_internal).length > 0) {
                ads = ads + 1;
            }
            return ads < 1 && campaigns < 1 && adgroups < 1 && accounts < 1 && channels > 0
        },
        accountsOnlySelected: () => {
            let accounts = 0;
            let campaigns = 0;
            let adgroups = 0;
            let ads = 0;
            if (this.state.selected_accounts_internal && Object.keys(this.state.selected_accounts_internal).length > 0) {
                accounts = accounts + 1;
            }
            if (this.state.selected_campaigns_internal && Object.keys(this.state.selected_campaigns_internal).length > 0) {
                campaigns = campaigns + 1;
            }
            if (this.state.selected_adgroups_internal && Object.keys(this.state.selected_adgroups_internal).length > 0) {
                adgroups = adgroups + 1;
            }
            if (this.state.selected_ads_internal && Object.keys(this.state.selected_ads_internal).length > 0) {
                ads = ads + 1;
            }
            return ads < 1 && campaigns < 1 && adgroups < 1 && accounts > 0
        },
        adsOnlySelected: () => {
            let accounts = 0;
            let campaigns = 0;
            let adgroups = 0;
            let ads = 0;
            if (this.state.selected_accounts_internal && Object.keys(this.state.selected_accounts_internal).length > 0) {
                accounts = accounts + 1;
            }
            if (this.state.selected_campaigns_internal && Object.keys(this.state.selected_campaigns_internal).length > 0) {
                campaigns = campaigns + 1;
            }
            if (this.state.selected_adgroups_internal && Object.keys(this.state.selected_adgroups_internal).length > 0) {
                adgroups = adgroups + 1;
            }
            if (this.state.selected_ads_internal && Object.keys(this.state.selected_ads_internal).length > 0) {
                ads = ads + 1;
            }
            return accounts < 1 && campaigns < 1 && adgroups < 1 && ads > 0
        },
        compareFormValidation: () => {
            let amount_of_datasources = 0;
            amount_of_datasources += Object.keys(this.state.selected_accounts_internal).length;
            amount_of_datasources += Object.keys(this.state.selected_campaigns_internal).length;
            amount_of_datasources += Object.keys(this.state.selected_adgroups_internal).length;
            amount_of_datasources += Object.keys(this.state.selected_ads_internal).length;
            if ((amount_of_datasources > 1 && this.state.tab.celltype && this.state.tab.celltype.value === "chart") || (this.state.tab.metrics && Object.keys(this.state.tab.metrics).length > 1 && this.state.tab.celltype && this.state.tab.celltype.value === "chart")) {
                return false;
            } else if (this.state.tab.celltype && this.state.tab.celltype.value === "chart" && this.state.tab.chart && this.state.tab.chart.value === "donut") {
                return false;
            } else {
                return true;
            }
        }
    };

    render() {
        return (

            <Transition.Root show={!this.state.closed} as={Fragment}>
                <Dialog style={{ zIndex: 151 }} as="div" className="fixed inset-0 overflow-hidden" onClose={() => { }}>
                    <div className="absolute inset-0 overflow-hidde">
                        <Dialog.Overlay className="absolute inset-0 bg-purple-900 bg-opacity-50" />
                        <div className="pointer-events-none fixed inset-y-0 p-4 right-0 flex max-w-full pl-10 sm:pl-16">
                            <Transition.Child
                                as={Fragment}
                                enter="transform transition ease-in-out duration-500 sm:duration-700"
                                enterFrom="translate-x-full"
                                enterTo="translate-x-0"
                                leave="transform transition ease-in-out duration-500 sm:duration-700"
                                leaveFrom="translate-x-0"
                                leaveTo="translate-x-full"
                            >
                                <div className={"max-w-2xl pointer-events-auto rounded-md overflow-hidden w-screen flex"}>
                                    <div className="h-full w-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl">
                                        <div className="py-4 px-4 sm:px-6 bg-purple-900 ">
                                            <div className="flex items-center justify-between">
                                                <Dialog.Title className="flex flex-1 flex-row pr-14">
                                                    <div className="text-xl flex-1 flex leading-6 font-semibold text-white">
                                                        Create/Update cell
                                                    </div>
                                                </Dialog.Title>
                                                <div onClick={() => {
                                                    this.props.toggle();
                                                }} className="ml-3 cursor-pointer h-12 w-12 flex justify-center transition-colors ease-in duration-75 hover:bg-red-700 text-white items-center rounded-full bg-red-500 ">
                                                    <XIcon className="h-6 w-6" aria-hidden="true" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex-1 h-0 overflow-y-auto">
                                            <div className="flex-1 flex flex-col justify-between">

                                                {/*SELECT CELL TYPE*/}
                                                <SelectCellType
                                                    tab={this.state.tab}
                                                    selected={this.state.tab.celltype ? this.state.tab.celltype : null}
                                                    open={this.state.celltype}
                                                    onTab={(tab) => {
                                                        this.setState({
                                                            tab: tab
                                                        })
                                                    }}
                                                    onToggle={(value) => {
                                                        this.setState({
                                                            celltype: !value
                                                        })
                                                    }}
                                                    onSelect={(celltype) => {

                                                        /*
                                                        if (celltype.value == "number") {
                                                            this.state.tab.channel_breakdowns = {
                                                                "google_analytics": { "overview": { name: "Overview", value: "overview", parent: "Overview" } },
                                                                "google_analytics_4": { "overview": { name: "Overview", value: "overview", parent: "Overview" } },
                                                                "adform": { "overview": { name: "Overview", value: "overview", parent: "Overview" } },
                                                                "facebook": { "overview": { name: "Overview", value: "overview", parent: "Overview" } },
                                                                "google": { "overview": { name: "Overview", value: "overview", parent: "Overview" } },
                                                                "linkedin": { "overview": { name: "Overview", value: "overview", parent: "Overview" } },
                                                                "bing": { "overview": { name: "Overview", value: "overview", parent: "Overview" } },
                                                                "tiktok": { "overview": { name: "Overview", value: "overview", parent: "Overview" } },
                                                                "snapchat": { "overview": { name: "Overview", value: "overview", parent: "Overview" } },
                                                                "dv360": { "overview": { name: "Overview", value: "overview", parent: "Overview" } },
                                                            };
                                                        }
                                                        */

                                                        this.state.tab.celltype = celltype;
                                                        if (!this.state.tab.group) {
                                                            this.state.tab.group = 0;
                                                        }
                                                        this.setState({
                                                            tab: this.state.tab,
                                                            celltype: false,
                                                            datasource: (celltype.value !== "image" && celltype.value !== "text"),
                                                            settings: (celltype.value === "image" || celltype.value === "text")
                                                        })
                                                    }}
                                                    onNext={() => {
                                                        this.setState({
                                                            celltype: false,
                                                            datasource: (this.state.tab.celltype.value !== "image" && this.state.tab.celltype.value !== "text"),
                                                            settings: (this.state.tab.celltype.value === "image" || this.state.tab.celltype.value === "text")
                                                        })
                                                    }}
                                                />

                                                {/*SELECT CELL DATASOURCE*/}
                                                {
                                                    this.state.tab.celltype && (this.state.tab.celltype.value !== "image" && this.state.tab.celltype.value !== "text") &&
                                                    <SelectCellDatasource
                                                        masterTemplate={this.state.masterTemplate}
                                                        template={this.state.template}
                                                        tab={this.state.tab}
                                                        accounts={this.state.accounts}
                                                        campaigns={this.state.campaigns}
                                                        adgroups={this.state.adgroups}
                                                        ads={this.state.ads}
                                                        selectedChannelsInternal={this.state.selected_channels_internal}
                                                        selectedAccountsInternal={this.state.selected_accounts_internal}
                                                        selectedCampaignsInternal={this.state.selected_campaigns_internal}
                                                        selectedAdgroupsInternal={this.state.selected_adgroups_internal}
                                                        selectedAdsInternal={this.state.selected_ads_internal}
                                                        open={this.state.datasource}
                                                        onTab={(tab) => {
                                                            console.log(tab);
                                                            this.setState({
                                                                tab: tab
                                                            });
                                                        }}
                                                        getAdgroups={(campaign) => {
                                                            if (this.props.getAdgroups) {
                                                                this.props.getAdgroups(campaign);
                                                            }
                                                        }}
                                                        getAds={(adgroup) => {
                                                            if (this.props.getAds) {
                                                                this.props.getAds(adgroup);
                                                            }
                                                        }}
                                                        onToggle={(value) => {
                                                            this.setState({
                                                                datasource: !value
                                                            })
                                                        }}
                                                        onCampaigns={(campaigns) => {
                                                            this.setState({
                                                                campaigns: campaigns
                                                            })
                                                        }}
                                                        onSelectedChannels={(channels) => {
                                                            this.setState({
                                                                selected_channels_internal: channels
                                                            })
                                                        }}
                                                        onSelectedAccounts={(accounts) => {
                                                            this.setState({
                                                                selected_accounts_internal: accounts
                                                            })
                                                        }}
                                                        onSelectedCampaigns={(campaigns) => {
                                                            this.setState({
                                                                selected_campaigns_internal: campaigns
                                                            })
                                                        }}
                                                        onAdgroups={(adgroups) => {
                                                            this.setState({
                                                                adgroups: adgroups
                                                            })
                                                        }}
                                                        onSelectedAdgroups={(adgroups) => {
                                                            this.setState({
                                                                selected_adgroups_internal: adgroups
                                                            })
                                                        }}
                                                        onAds={(ads) => {
                                                            this.setState({
                                                                ads: ads
                                                            })
                                                        }}
                                                        onSelectedAds={(ads) => {
                                                            this.setState({
                                                                selected_ads_internal: ads
                                                            })
                                                        }}
                                                        onNext={() => {
                                                            this.setState({
                                                                datasource: false,
                                                                //daterange: this.state.premium ? (this.state.tab.celltype.value !== "chart" && this.state.tab.celltype.value !== "table") : false,
                                                                breakdown: true
                                                            })
                                                        }}
                                                    />
                                                }

                                                {/*SELECT CELL BREAKDOWN*/}
                                                {
                                                    this.functions.anythingSelected() &&
                                                    this.state.tab.celltype && (this.state.tab.celltype.value !== "image" && this.state.tab.celltype.value !== "preview" && this.state.tab.celltype.value !== "text") &&
                                                    <SelectCellBreakdown
                                                        masterTemplate={this.state.masterTemplate}
                                                        tab={this.state.tab}
                                                        open={this.state.breakdown}
                                                        selectedChannelsInternal={this.state.selected_channels_internal}
                                                        selectedAccountsInternal={this.state.selected_accounts_internal}
                                                        selectedCampaignsInternal={this.state.selected_campaigns_internal}
                                                        selectedAdgroupsInternal={this.state.selected_adgroups_internal}
                                                        selectedAdsInternal={this.state.selected_ads_internal}
                                                        onTab={(tab) => {
                                                            this.setState({
                                                                tab: tab
                                                            })
                                                        }}
                                                        onToggle={(value) => {
                                                            this.setState({
                                                                breakdown: !value
                                                            })
                                                        }}
                                                        onNext={() => {
                                                            this.setState({
                                                                breakdown: false,
                                                                daterange: !(this.state.premium && this.state.tab.celltype.value === "chart"),
                                                                chart: this.state.premium && this.state.tab.celltype.value === "chart"
                                                            })
                                                        }}
                                                    />
                                                }

                                                {/*SELECT CELL CHART*/}
                                                {
                                                    this.functions.anythingSelected() &&
                                                    this.state.tab.celltype && (this.state.tab.celltype.value !== "image" && this.state.tab.celltype.value !== "preview" && this.state.tab.celltype.value !== "text" && this.state.tab.celltype.value !== "number" && this.state.tab.celltype.value !== "table") &&
                                                    <SelectCellChart
                                                        tab={this.state.tab}
                                                        open={this.state.chart}
                                                        selectedChannels={this.state.selected_channels_internal}
                                                        selectedAccounts={this.state.selected_accounts_internal}
                                                        selectedCampaigns={this.state.selected_campaigns_internal}
                                                        selectedAdgroups={this.state.selected_adgroups_internal}
                                                        selectedAds={this.state.selected_ads_internal}
                                                        onToggle={(value) => {
                                                            this.setState({
                                                                chart: !value
                                                            })
                                                        }}
                                                        onTab={(tab) => {
                                                            this.setState({
                                                                tab: tab
                                                            })
                                                        }}
                                                        onNext={() => {
                                                            this.setState({
                                                                chart: false,
                                                                daterange: true
                                                            })
                                                        }}
                                                    />
                                                }

                                                {/*SELECT CELL CHART*/}
                                                {
                                                    this.functions.anythingSelected() &&
                                                    this.state.tab.celltype && (this.state.tab.celltype.value !== "image" && this.state.tab.celltype.value !== "preview" && this.state.tab.celltype.value !== "text") &&
                                                    <SelectCellDaterange
                                                        tab={this.state.tab}
                                                        selectedChannels={this.state.selected_channels_internal}
                                                        selectedAccounts={this.state.selected_accounts_internal}
                                                        selectedCampaigns={this.state.selected_campaigns_internal}
                                                        selectedAdgroups={this.state.selected_adgroups_internal}
                                                        selectedAds={this.state.selected_ads_internal}
                                                        open={this.state.daterange}
                                                        onToggle={(value) => {
                                                            this.setState({
                                                                daterange: !value
                                                            })
                                                        }}
                                                        onTab={(tab) => {
                                                            this.setState({
                                                                tab: tab
                                                            })
                                                        }}
                                                        onNext={() => {
                                                            this.setState({
                                                                daterange: false,
                                                                metric: true
                                                            })
                                                        }}
                                                    />
                                                }

                                                {/*SELECT CELL METRIC*/}
                                                {
                                                    (
                                                        (this.state.tab.daterange && this.state.tab.daterange.value !== "custom_dates")
                                                        ||
                                                        (this.state.tab.daterange && this.state.tab.daterange.value === "custom_dates" && this.state.tab.daterange.start_date && this.state.tab.daterange.end_date)
                                                    ) &&
                                                    this.functions.anythingSelected() &&
                                                    this.state.tab.celltype && (this.state.tab.celltype.value !== "image" && this.state.tab.celltype.value !== "preview" && this.state.tab.celltype.value !== "text") &&
                                                    <SelectCellMetric
                                                        tab={this.state.tab}
                                                        open={this.state.metric}
                                                        metrics={this.state.metrics}
                                                        selectedChannels={this.state.selected_channels_internal}
                                                        selectedAccounts={this.state.selected_accounts_internal}
                                                        selectedCampaigns={this.state.selected_campaigns_internal}
                                                        selectedAdgroups={this.state.selected_adgroups_internal}
                                                        selectedAds={this.state.selected_ads_internal}
                                                        onToggle={(value) => {
                                                            this.setState({
                                                                metric: !value
                                                            })
                                                        }}
                                                        onTab={(tab) => {
                                                            this.setState({
                                                                tab: tab
                                                            })
                                                        }}
                                                        onNext={() => {
                                                            this.setState({
                                                                metric: false,
                                                                settings: true
                                                            })
                                                        }}
                                                    />
                                                }

                                                {/*SELECT CELL SETTINGS*/}
                                                {
                                                    (
                                                        (this.state.tab.metrics && Object.keys(this.state.tab.metrics).length > 0 && ((this.state.tab.daterange && this.state.tab.daterange.value !== "custom_dates") || (this.state.tab.daterange && this.state.tab.daterange.value === "custom_dates" && this.state.tab.daterange.start_date && this.state.tab.daterange.end_date)) && this.functions.anythingSelected() && this.state.tab.celltype && (this.state.tab.celltype.value !== "image" && this.state.tab.celltype.value !== "preview" && this.state.tab.celltype.value !== "text"))
                                                        ||
                                                        (this.state.tab.celltype && (this.state.tab.celltype.value == "image" || this.state.tab.celltype.value == "text"))
                                                        ||
                                                        (this.functions.anythingSelected() && this.state.tab.celltype && (this.state.tab.celltype.value == "preview"))
                                                    ) &&
                                                    <SelectCellSettings
                                                        masterTemplate={this.state.masterTemplate}
                                                        template={this.state.template}
                                                        tab={this.state.tab}
                                                        open={this.state.settings}
                                                        selectedChannelsInternal={this.state.selected_channels_internal}
                                                        selectedAccountsInternal={this.state.selected_accounts_internal}
                                                        selectedCampaignsInternal={this.state.selected_campaigns_internal}
                                                        selectedAdgroupsInternal={this.state.selected_adgroups_internal}
                                                        selectedAdsInternal={this.state.selected_ads_internal}
                                                        onToggle={(value) => {
                                                            this.setState({
                                                                settings: !value
                                                            })
                                                        }}
                                                        onSelected={(object) => {
                                                            this.setState({
                                                                selected_campaigns_internal: object.selected_campaigns_internal,
                                                                selected_adgroups_internal: object.selected_adgroups_internal,
                                                                selected_ads_internal: object.selected_ads_internal
                                                            });
                                                        }}
                                                        onTab={(tab) => {
                                                            this.setState({
                                                                tab: tab
                                                            })
                                                        }}
                                                        onNext={() => {
                                                            this.setState({
                                                                settings: false
                                                            })
                                                        }}
                                                    />
                                                }
                                            </div>
                                        </div>
                                        <div className="flex-shrink-0 px-4 py-4 flex justify-end">
                                            <button
                                                onClick={() => {
                                                    if (this.functions.valid()) {
                                                        this.functions.create();
                                                    }
                                                }}
                                                className={(this.state.success ? "cursor-pointer bg-green-700 text-white" : (this.functions.valid() ? "cursor-pointer bg-purple-500 hover:bg-purple-600 text-white" : "cursor-not-allowed bg-gray-300 text-gray-600")) + " shadow inline-flex relative justify-center rounded-md border border-transparent w-48 py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
                                                }
                                            >
                                                Create
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        )
    }

}

export default AdvancedReportCellSlideIn;