class adSnapchat {

    static validate(ad) {
        let error = {
            name: false,
            page: false,
            website: false,
            headline: false,
            cta: false,
            ios: false,
            android: false,
            icon: false,
        };

        if (ad) {
            if (!ad.name || ad.name === "") {
                error.name = true;
            }
            if (!ad.page || ad.page.id === 0) {
                error.page = true;
            }
            if (!ad.link || ad.link === "" || !ad.link.includes("https://")) {
                if (ad.file && ad.file.snapchat_attachment && ad.file.snapchat_attachment.value === "WEB_VIEW") {
                    error.website = true;
                }
            }
            if (!ad.cta || ad.cta.id === 0) {
                error.cta = true;
            }
            if (!ad.title || ad.title === "" || ad.title.length > 34) {
                error.headline = true;
            }
            if (ad.file && ad.file.snapchat_attachment && ad.file.snapchat_attachment.value !== "WEB_VIEW" && ad.file.snapchat_attachment.value !== "NONE") {
                if (!ad.file.snapchat_url_ios || ad.file.snapchat_url_ios === "") {
                    if (ad.adgroup_info && ad.adgroup_info.operating_system &&  [1, 3].includes(ad.adgroup_info.operating_system.id)) {
                        error.ios = true;
                    }
                }
                if (!ad.file.snapchat_url_android || ad.file.snapchat_url_android === "" || ad.file.snapchat_url_android.includes("https")) {
                    if (ad.adgroup_info &&  ad.adgroup_info.operating_system && [1, 2].includes(ad.adgroup_info.operating_system.id)) {
                        error.android = true;
                    }
                }
                if (!ad.file.snapchat_app_icon || ad.file.snapchat_app_icon.ratio !== "1:1") {
                    error.icon = true;
                }
            }
        }


        return error;
    }
}
export { adSnapchat }