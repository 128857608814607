import { PDFDocument } from 'pdf-lib';
import html2canvas from 'html2canvas';
import ReactDOM from 'react-dom';

const exportPdf = async (getReportHtml) => {
    let tempDiv;
    try {
        tempDiv = document.createElement('div');
        tempDiv.style.position = 'absolute';
        tempDiv.style.width = '1366px';
        tempDiv.style.top = '-99999px';
        document.body.appendChild(tempDiv);

        ReactDOM.render(getReportHtml(), tempDiv);

        const pdfDoc = await PDFDocument.create();

        for (let node of tempDiv.childNodes) {
            const imgs = node.querySelectorAll('img');
            const videos = node.querySelectorAll('video');
            const contentPromises = [];


            videos.forEach(video => {
                const videoPromise = new Promise((resolve, reject) => {
                    const canvas = document.createElement('canvas');
                    const ctx = canvas.getContext('2d');
                    video.crossOrigin = 'anonymous';
                    video.muted = false;


                    if (video.readyState >= 3) {
                        return processVideo(video, canvas, ctx, resolve);
                    } else {
                        video.addEventListener('loadeddata', () => {
                            if (video.readyState >= 3) {
                                return processVideo(video, canvas, ctx, resolve);
                            }
                        }, { once: true });
                    }

                })
                contentPromises.push(videoPromise);
            });

            imgs.forEach(img => {
                if (img.complete) {
                    processImage(img);
                } else {
                    const imgPromise = new Promise((resolve, reject) => {
                        console.log("img start")
                        img.onload = () => {
                            processImage(img);
                            resolve();
                        };
                        img.onerror = () => {
                            console.error('Error loading image', img.src);
                            reject(new Error('Error loading image'));
                        };
                    });
                    contentPromises.push(imgPromise);
                }
            });

            await Promise.all(contentPromises).catch(error => {
                console.error(error)
            });

            const canvas = await html2canvas(node, { useCORS: true, logging: false });
            const imgData = canvas.toDataURL('image/png');
            const page = pdfDoc.addPage([canvas.width, canvas.height]);
            const pngImage = await pdfDoc.embedPng(imgData);
            page.drawImage(pngImage, { x: 0, y: 0, width: canvas.width, height: canvas.height });
        }

        const pdfBytes = await pdfDoc.save();
        const blob = new Blob([pdfBytes], { type: 'application/pdf' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'report.pdf';
        link.click();
    } catch (error) {
        console.error('exportPdf:', error);
    } finally {
        if (tempDiv) {
            document.body.removeChild(tempDiv);
        }
    }
};

function processImage(img) {
    const parent = img.parentNode;
    const scaleFactor = parent.clientHeight / img.naturalHeight;
    img.style.width = `${img.naturalWidth * scaleFactor}px`;
    //img.style.height = `${parent.clientHeight}px`;
    img.style.height = "auto";
    parent.style.display = 'flex';
    parent.style.alignItems = 'center';
    parent.style.justifyContent = 'center';
}

function processVideo(video, canvas, ctx, resolve) {
    if (!video.src && video.querySelector('source')) {
        video.src = video.querySelector('source').src
    }
    video.currentTime = 0.1;
    video.addEventListener('seeked', () => {
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;

        ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

        try {
            video.style.backgroundImage = `url(${canvas.toDataURL()})`;
        } catch (error) {
            try {
                video.crossOrigin = "anonymous";
                video.style.backgroundImage = `url(${canvas.toDataURL()})`;
            } catch (error) {
                console.error(error)
            }
        }
        video.style.backgroundSize = 'cover';
        ctx.clearRect(0, 0, canvas.width, canvas.height);

        return resolve();
    })
}

export default exportPdf;
