import React, { Component } from "react";
import InputTailwind from "../moduleFiles/inputTailwind";
import SuccessModal from "../moduleFiles/successModal";
import { apiRegister } from "../services/apiRegister";
import { clientRegister } from "../services/clientRegister";
import { tokenRegister } from "../services/tokenRegister";
import { userRegister } from "../services/userRegister";

class VerificationCode extends Component {

	constructor(props) {
		super(props);
		this.state = {
			code: "",
			loading: false,
			error_message: ""
		};
	}

	componentDidMount() {

	}

	functions = {
		verify: async () => {
			if (!this.state.loading) {
				await this.promisedSetState({
					loading: true,
					error: false
				});
				try {
					let response = await this.calls.verifyCode({ code: this.state.code });
					//tokenRegister.set(response.headers['x-auth-token'], response.data.type === 'admin');
					//userRegister.set(response.data, response.data.type === 'admin');
					tokenRegister.set(response.headers['x-auth-token'], response.headers['x-auth-refresh-token'], response.data.type === 'admin');
					userRegister.set(response.data, response.data.type === 'admin');
					clientRegister.remove();
					if (response.data.type === 'admin') {
						this.props.history.push('/admin/dashboard');
					} else if (response.data.type === 'agent') {
						let url = localStorage.getItem("redirectUrl")
						if (url) {
							localStorage.removeItem("redirectUrl");
							this.props.history.push(url);
						} else {
							this.props.history.push('/v2/dashboard');
						}
					}
				} catch (error) {
					this.promisedSetState({
						error: true,
						loading: false,
						error_message: error && error.body ? error.body.message : ""
					})
				}
			}
		}
	};

	calls = {
		verifyCode: (data) => {
			let options = apiRegister.options(tokenRegister.get(), "POST", data);
			let url = apiRegister.url.api + "/v3/adcredo/verifyUserCode";
			return apiRegister.call(options, url);
		}
	};

	promisedSetState = (newState) => {
		return new Promise((resolve) => {
			this.setState(newState, () => {
				resolve()
			});
		});
	}

	render() {
		return (
			<>
				<div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
					<div className="sm:mx-auto sm:w-full sm:max-w-md">
						<img
							className="mx-auto h-12 w-auto"
							src={require("../assets/images/adcredo_icon_purple.png")}
							alt="Workflow"
						/>
						<h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
							Verification code
						</h2>
						<p className="mt-2 text-center text-sm text-gray-600">
							Please type your code below
						</p>
					</div>
					<div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
						<div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
							<div className="space-y-6">
								<InputTailwind
									error={this.state.error}
									errorMessage={this.state.error_message}
									label={"Code"}
									value={this.state.code}
									onChange={(value) => {
										this.setState({
											code: value
										})
									}}
								/>
								<div className={this.state.error ? "pt-4" : ""}>
									<button
										onClick={() => {
											this.functions.verify();
										}}
										className={"inline-flex relative shadow justify-center rounded-md bg-purple-500 hover:bg-purple-600 py-2 w-full text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"}
									>
										<span>Verify</span>
										{
											this.state.loading &&
											<div className="w-full h-full absolute inset-0 bg-purple-500 flex justify-center items-center z-20 rounded-lg">
												<div style={{ borderTopColor: "transparent" }} class="w-4 h-4 border-2 border-white border-solid rounded-full animate-spin"></div>
											</div>
										}
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

export default VerificationCode;
