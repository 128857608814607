import React, { PureComponent } from 'react';
import pptxgen from 'pptxgenjs';
import { decodeEntity } from 'html-entities';
import { apiRegister } from '../services/apiRegister';
import { tokenRegister } from '../services/tokenRegister';
import PreviewTikTokFeed from "../previews/tiktok/feed";
import tiktok_cta from "../assets/json/tiktok_cta.json";
import PreviewSectionFacebook from "./previewSectionFacebook";
import PreviewSectionTwitter from "./previewSectionTwitter";
import PreviewSectionSnapchat from "./previewSectionSnapchat";
import PreviewSectionLinkedin from "./previewSectionLinkedin";
import PreviewSectionGoogle from "./previewSectionGoogle";
import PreviewGoogleDisplaySquare from "../previews/google/displayBannerSquare";
import PreviewGoogleDisplaySkyscraper from "../previews/google/displayBannerSkyscraper";
import PreviewGoogleDisplayBillboard from "../previews/google/displayBannerBillboard";
import PreviewGoogleSearch from "../previews/google/search";
import ReactDOM from "react-dom";
import html2canvas from "html2canvas";
var moment = require('moment');

// SHADOW OPTIONS GOES HERE
const shadowOpts = { type: 'outer', color: '696969', blur: 10, offset: 0, angle: 90, opacity: 0.3 };

export default class CreatePowerPoint extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {};
    }

    functions = {
        create: async (tabs, logo, title, description, report_background_color, report_font_color, report_shadows, report_transparent_cells, report_number_cell_font, report_text_cell_font) => {
            return new Promise(async (resolve, reject) => {
                try {
                    let pptx = new pptxgen();
                    let response = {};

                    response = await this.functions.initSettings(pptx);
                    pptx = response.pptx;
                    const powerPointSettings = response.powerPointSettings;

                    const processGroup = async (pptx, group, powerPointSettings) => {
                        const slide = pptx.addSlide(`${group.index}_SLIDE`);
                        slide.background = { fill: report_background_color || '#fefefe' };

                        if (group.enable_image) {
                            slide.background = { path: group.pageImage };
                        } else if (group.enable_background && typeof group.background_color == "string" && group.background_color !== "") {
                            slide.background = { fill: "#" + group.background_color };
                        } else {
                            slide.background = { fill: (typeof report_background_color == "string" && report_background_color !== "") ? report_background_color : '#fefefe' };
                        }

                        await Promise.all(
                            group.cells.map(async (cell) => {
                                let cell_background_color;
                                let cell_font_color;

                                // IF SETTINGS EXISTS, SET BACKGROUND AND FONT COLOR
                                if (cell.settings) {
                                    if (cell.settings.background_color && !cell.settings.transparent) {
                                        cell_background_color = cell.settings.background_color;
                                    }
                                    if (cell.settings.font_color) {
                                        cell_font_color = cell.settings.font_color;
                                    }
                                }

                                if (!cell.abstract) {
                                    let response;
                                    switch (cell.typeOfBlock) {
                                        case "img":
                                            response = await this.functions.createImage(pptx, slide, powerPointSettings, cell, group);
                                            break;
                                        case "preview":
                                            response = await this.functions.createPreview(pptx, slide, powerPointSettings, cell, group);
                                            break;
                                        case "number":
                                            response = await this.functions.createNumber(pptx, slide, powerPointSettings, cell, group.cells, report_shadows, cell_background_color, cell_font_color, report_text_cell_font);
                                            break;
                                        case "input":
                                            response = await this.functions.createText(pptx, slide, powerPointSettings, cell, report_shadows, cell_background_color, cell_font_color, report_text_cell_font);
                                            break;
                                        case "table":
                                            response = await this.functions.createTable(pptx, slide, powerPointSettings, cell, group.cells, report_shadows, cell_background_color, cell_font_color, report_background_color, report_transparent_cells, group, report_text_cell_font);
                                            break;
                                        case "chart":
                                            if (cell.chart) {
                                                switch (cell.chart.value) {
                                                    case "bar":
                                                        response = await this.functions.createChartBar(pptx, slide, powerPointSettings, cell, group.cells, report_shadows, cell_background_color, cell_font_color, report_background_color, report_transparent_cells, group, report_text_cell_font);
                                                        break;
                                                    case "line":
                                                        response = await this.functions.createChartLine(pptx, slide, powerPointSettings, cell, group.cells, report_shadows, cell_background_color, cell_font_color, report_background_color, report_transparent_cells, group, report_text_cell_font);
                                                        break;
                                                    case "donut":
                                                        response = await this.functions.createChartDonut(pptx, slide, powerPointSettings, cell, report_shadows, cell_background_color, cell_font_color, report_background_color, report_transparent_cells, group, report_text_cell_font);
                                                        break;
                                                }
                                            }
                                            break;
                                    }
                                }
                            })
                        );
                        return slide;
                    };

                    const chunkArray = (array, size) => {
                        const result = [];
                        for (let i = 0; i < array.length; i += size) {
                            result.push(array.slice(i, i + size));
                        }
                        return result;
                    };

                    const processTabs = async (tabs, pptx, powerPointSettings) => {
                        for (const tab of tabs) {
                            if (!tab.data_fetched) continue;

                            const groups = tab.groups
                                .filter((group) => group.rows === 6)
                                .map((group, index) => ({
                                    ...group,
                                    index,
                                    cells: tab.grid_data.filter((cell) => cell.group === group.id),
                                }));

                            const chunks = chunkArray(groups, 10);
                            for (const chunk of chunks) {
                                await Promise.all(
                                    chunk.map((group) => processGroup(pptx, group, powerPointSettings,))
                                );
                            }
                        }
                    };

                    await processTabs(tabs, pptx, powerPointSettings);

                    await pptx.writeFile(title || "report");

                    resolve("Report created");
                } catch (error) {
                    reject(error);
                }
            });
        },
        initSettings: async (pptx) => {
            return new Promise(async (resolve, reject) => {
                const documentWidth = 14.74;
                const documentHeight = 7.5;
                pptx.defineLayout({ name: 'TST', width: documentWidth, height: documentHeight });
                pptx.layout = 'TST';
                pptx.author = 'User';
                pptx.company = 'Adcredo';
                let blockWidth = documentWidth / 8;
                let blockHeight = documentHeight / 6;
                let powerPointSettings = { documentWidth: documentWidth, documentHeight: documentHeight, blockWidth: blockWidth, blockHeight: blockHeight };
                resolve({ pptx: pptx, powerPointSettings: powerPointSettings });
            });
        },
        createText: async (pptx, slide, powerPointSettings, cell, report_shadows, cell_background_color, cell_font_color, report_text_cell_font) => {
            return new Promise(async (resolve, reject) => {
                const { blockWidth, blockHeight } = powerPointSettings;

                let { x, y, w, h } = this.helpers.getCoordinates(cell);

                let items = [];

                let default_font_name = "Poppins";
                if (report_text_cell_font && typeof report_text_cell_font.name == "string" && report_text_cell_font.name !== "") {
                    try {
                        default_font_name = report_text_cell_font.name.replace(/\x00/g, '');
                    } catch (error) { }
                }

                if (typeof cell.data == "string") {
                    const lines = cell.data.split('\n');
                    let bullit_list = false;
                    let number_list = false;
                    lines.map((line) => {
                        let start_list = false;
                        let end_list = false;
                        let item = {
                            text: convertHTML(line.replace(/<[^>]*>?/gm, '')),
                            options: {
                                fontFace: default_font_name,
                                color: cell_font_color ? cell_font_color : '333333',
                                breakLine: true,
                                isTextBox: true
                            }
                        };
                        if (line.indexOf("<br>") !== -1) {
                            item.text = " ";
                        } else {
                            if (line.indexOf("<ol>") !== -1) {
                                start_list = true;
                                number_list = true;
                            }
                            if (line.indexOf("</ol>") !== -1) {
                                number_list = false;
                                end_list = true;
                            }
                            if (line.indexOf("<ul>") !== -1) {
                                start_list = true;
                                bullit_list = true;
                            }
                            if (line.indexOf("</ul>") !== -1) {
                                bullit_list = false;
                                end_list = true;
                            }
                            if (line.indexOf("<li>") !== -1) {
                                item.text = item.text.trim();
                                item.options.indentLevel = 2;
                                item.options.fontSize = 14;
                                if (bullit_list) {
                                    item.options.bullet = {
                                        indent: 10
                                    };
                                } else {
                                    item.options.bullet = {
                                        type: "number",
                                        style: "arabicPeriod",
                                        indent: 10
                                    };
                                }
                                item.options.fontSize = 14;
                            }
                            if (line.indexOf("<h1>") !== -1) {
                                item.options.fontSize = 36;
                            } else if (line.indexOf("<h2>") !== -1) {
                                item.options.fontSize = 24;
                            } else if (line.indexOf("<h3>") !== -1) {
                                item.options.fontSize = 21;
                            } else if (line.indexOf("<h4>") !== -1) {
                                item.options.fontSize = 18;
                            } else if (line.indexOf("<p>") !== -1) {
                                item.options.fontSize = 14;
                            }
                            if (line.indexOf("<strong>") !== -1) {
                                item.options.bold = true;
                            }
                            if (line.indexOf("font-size:") !== -1) {
                                item.options.fontSize = +line.slice(line.indexOf("font-size:") + 11).slice(0, line.slice(line.indexOf("font-size:") + 11).indexOf('px'));
                            }
                            if (line.indexOf("font-family:") !== -1) {
                                item.options.fontFace = line.slice(line.indexOf("font-family:") + 13).slice(0, line.slice(line.indexOf("font-family:") + 13).indexOf('"')).replace(/\x00/g, '');
                            }
                            if (line.indexOf("<em>") !== -1) {
                                item.options.italic = true;
                            }
                            if (line.indexOf("<u>") !== -1) {
                                item.options.underline = true;
                            }
                            if (line.indexOf("<del>") !== -1) {
                                item.options.strike = true;
                            }
                        }
                        if (!start_list && !end_list) {
                            items.push(item);
                        }
                    });
                }

                let background = "FFFFFF";
                if (cell_background_color) {
                    background = cell_background_color;
                }

                let shadow = false;
                if (cell.settings && cell.settings.shadow) {
                    shadow = true;
                }
                if (cell && cell.settings && cell.settings.transparent) {
                    background = null;
                    shadow = false;
                }

                items = items.map((item) => {
                    let new_item = JSON.parse(JSON.stringify(item));
                    return new_item;
                });


                slide.addShape(pptx.shapes.ROUNDED_RECTANGLE, {
                    x: x * blockWidth + 0.1,
                    y: y * blockHeight + 0.1,
                    w: w * blockWidth - 0.2,
                    h: h * blockHeight - 0.2,
                    rectRadius: 0.1,
                    ...(background && { fill: { color: background } }),
                    ...(shadow && { shadow: { ...shadowOpts } })
                });

                slide.addText(items, {
                    x: x * blockWidth + 0.3,
                    y: y * blockHeight + 0.3,
                    w: w * blockWidth - 0.6,
                    ...(cell.verticalAlign && { h: h * blockHeight - 0.6 }),
                    align: typeof cell.textAlign == 'string' ? cell.textAlign.toLowerCase() : 'left',
                    valign: cell.verticalAlign ? 'middle' : 'top',
                    color: cell_font_color ? cell_font_color : '333333',
                    margin: 0
                });

                resolve({ pptx: pptx, slide: slide });

                function convertHTML(str) {
                    let seperator = str.split(/(&.+;)/ig);
                    seperator = seperator.map((item) => {
                        item = decodeEntity(item);
                        return item;
                    });
                    return seperator.join("");
                }

            })
        },
        createPreview: async (pptx, slide, powerPointSettings, cell, group) => {
            return new Promise(async (resolve, reject) => {
                const { blockWidth, blockHeight } = powerPointSettings;

                let { x, y, w, h } = this.helpers.getCoordinates(cell);

                let background = "FFFFFF";
                if (cell && cell.settings && cell.settings.background_color) {
                    background = cell.settings.background_color;
                }
                let shadow = false;
                if (cell.settings && cell.settings.shadow) {
                    shadow = true;
                }
                if (cell && cell.settings && cell.settings.transparent) {
                    background = null;
                    shadow = false;
                }

                this.helpers.addShapes(pptx, cell, slide, powerPointSettings, background);

                slide.addShape(pptx.shapes.ROUNDED_RECTANGLE, {
                    x: x * blockWidth + 0.1,
                    y: y * blockHeight + 0.1,
                    w: w * blockWidth - 0.2,
                    h: h * blockHeight - 0.2,
                    rectRadius: 0.1,
                    ...(background && { fill: { color: background } }),
                    ...(shadow && { shadow: { ...shadowOpts } })
                });

                let preview_url = cell.preview_url

                if (!cell.loading && cell.data) {
                    if (!preview_url) {
                        if (!cell.fake) {
                            preview_url = await this.functions.renderPreview(cell);
                        }
                    }
                }

                if (preview_url) {
                    slide.addImage({
                        data: preview_url,
                        x: x * blockWidth + 0.1,
                        y: y * blockHeight + 0.1,
                        w: w * blockWidth - 0.2,
                        h: h * blockHeight - 0.2,
                        margin: 0
                    });

                    if (cell && cell.data && cell.data.type == 'video') {
                        let url_data = await this.calls.convertURLToInternal(cell.data.video_url);
                        if (url_data) {
                            let box_width = 2.95;
                            let box_height = h * blockHeight - 0.2;
                            let image_height = url_data.height;
                            let image_width = url_data.width;
                            let scale = (box_width / image_width);
                            if (image_height * scale > box_height) {
                                scale = box_height / image_height;
                            }
                            let width = image_width * scale;
                            let height = image_height * scale;
                            let y_coordinates = y * blockHeight + 0.1;
                            let x_coordinates = x * blockWidth + 0.1;
                            let half_height_source = height / 2;
                            let half_height_cell = box_height / 2;
                            y_coordinates = (y_coordinates + half_height_cell) - half_height_source;
                            let half_width_source = width / 2;
                            let half_width_cell = (w * blockWidth - 0.2) / 2;
                            x_coordinates = (x_coordinates + half_width_cell) - half_width_source;
                            slide.addMedia({
                                type: "video",
                                path: url_data.secure_url,
                                x: x_coordinates,
                                y: y_coordinates,
                                w: width,
                                h: height,
                                margin: 0
                            });
                        }
                    }

                } else {
                    slide.addText([{
                        text: "Previews here",
                        options: { fontSize: 16, bold: false, color: 'FF4D54', breakLine: true }
                    }], {
                        x: x * blockWidth + 0.1,
                        y: y * blockHeight + 0.1,
                        w: w * blockWidth - 0.2,
                        h: h * blockHeight - 0.2,
                        align: 'center',
                        valign: 'middle',
                        fontFace: 'Poppins',
                        margin: 0
                    });
                }

                resolve({ pptx: pptx, slide: slide });
            })
        },
        createImage: async (pptx, slide, powerPointSettings, cell, group) => {
            return new Promise(async (resolve, reject) => {
                const { blockWidth, blockHeight } = powerPointSettings;

                let { x, y, w, h } = this.helpers.getCoordinates(cell);

                let background = "FFFFFF";
                if (cell && cell.settings && cell.settings.background_color) {
                    background = cell.settings.background_color;
                }

                let shadow = false;
                if (cell.settings && cell.settings.shadow) {
                    shadow = true;
                }
                if (cell && cell.settings && cell.settings.transparent) {
                    background = null;
                    shadow = false;
                }

                let all_margins = cell.settings && cell.settings.disable_margin_top && cell.settings.disable_margin_bottom && cell.settings.disable_margin_left && cell.settings.disable_margin_right;

                this.helpers.addShapes(pptx, cell, slide, powerPointSettings, background);

                slide.addShape(pptx.shapes.ROUNDED_RECTANGLE, {
                    x: x * blockWidth + 0.1,
                    y: y * blockHeight + 0.1,
                    w: w * blockWidth - 0.2,
                    h: h * blockHeight - 0.2,
                    rectRadius: !group.disable_padding ? 0.1 : 0.0000000000000001,
                    ...(background && { fill: { color: background } }),
                    ...(shadow && { shadow: { ...shadowOpts } })
                });

                if (cell.data) {
                    if (cell && cell.settings && cell.settings.media_style && cell.settings.media_style.value == "contain") {
                        let box_width = w * blockWidth - 0.2;
                        let box_height = h * blockHeight - 0.2;
                        let image_height = cell.settings && cell.settings.height ? cell.settings.height : box_height;
                        let image_width = cell.settings && cell.settings.width ? cell.settings.width : box_width;
                        let scale = (box_width / image_width);
                        if (image_height * scale > box_height) {
                            scale = box_height / image_height;
                        }
                        let width = image_width * scale;
                        let height = image_height * scale;
                        let y_coordinates = y * blockHeight + 0.1;
                        let x_coordinates = x * blockWidth + 0.1;

                        let half_source = height / 2;
                        let half_cell = box_height / 2;
                        y_coordinates = (y_coordinates + half_cell) - half_source;

                        let half_source_w = width / 2;
                        let half_cell_w = box_width / 2;
                        x_coordinates = (x_coordinates + half_cell_w) - half_source_w;

                        if (cell.format === "image") {
                            slide.addImage({
                                path: cell.data,
                                x: x_coordinates,
                                y: y_coordinates,
                                w: width,
                                h: height,
                                margin: 0
                            });
                        } else {
                            slide.addMedia({
                                type: "video",
                                path: cell.data,
                                x: x_coordinates,
                                y: y_coordinates,
                                w: width,
                                h: height,
                                margin: 0
                            });
                        }
                    } else if (cell && cell.settings && cell.settings.media_style && cell.settings.media_style.value == "fill") {
                        if (cell.format === "image") {
                            slide.addImage({
                                path: cell.data,
                                x: !all_margins ? x * blockWidth + 0.1 : x * blockWidth,
                                y: !all_margins ? y * blockHeight + 0.1 : y * blockHeight,
                                w: !all_margins ? w * blockWidth - 0.2 : w * blockWidth,
                                h: !all_margins ? h * blockHeight - 0.2 : h * blockHeight,
                                margin: 0
                            });
                        } else {
                            slide.addMedia({
                                type: "video",
                                path: cell.data,
                                x: !all_margins ? x * blockWidth + 0.1 : x * blockWidth,
                                y: !all_margins ? y * blockHeight + 0.1 : y * blockHeight,
                                w: !all_margins ? w * blockWidth - 0.2 : w * blockWidth,
                                h: !all_margins ? h * blockHeight - 0.2 : h * blockHeight,
                                margin: 0
                            });
                        }
                    } else if (cell && cell.settings && cell.settings.media_style && cell.settings.media_style.value == "cover") {
                        let box_width = !all_margins ? w * blockWidth - 0.2 : w * blockWidth;
                        let box_height = !all_margins ? h * blockHeight - 0.2 : h * blockHeight;
                        let image_height = cell.settings && cell.settings.height ? cell.settings.height : box_height;
                        let image_width = cell.settings && cell.settings.width ? cell.settings.width : box_width;
                        let scale = (box_width / image_width);
                        if (image_height * scale > box_height) {
                            scale = box_height / image_height;
                        }
                        let width = image_width * scale;
                        let height = image_height * scale;
                        if (cell.format === "image") {
                            slide.addImage({
                                path: cell.data,
                                x: !all_margins ? x * blockWidth + 0.1 : x * blockWidth,
                                y: !all_margins ? y * blockHeight + 0.1 : y * blockHeight,
                                w: !all_margins ? w * blockWidth - 0.2 : w * blockWidth,
                                h: !all_margins ? h * blockHeight - 0.2 : h * blockHeight,
                                margin: 0,
                                sizing: {
                                    type: 'cover',
                                    w: width,
                                    h: height
                                }
                            });
                        } else {
                            slide.addMedia({
                                type: "video",
                                path: cell.data,
                                x: !all_margins ? x * blockWidth + 0.1 : x * blockWidth,
                                y: !all_margins ? y * blockHeight + 0.1 : y * blockHeight,
                                w: !all_margins ? w * blockWidth - 0.2 : w * blockWidth,
                                h: !all_margins ? h * blockHeight - 0.2 : h * blockHeight,
                                margin: 0,
                                sizing: {
                                    type: 'cover',
                                    w: width,
                                    h: height
                                }
                            });
                        }
                    } else {
                        if (cell.format === "image") {
                            slide.addImage({
                                path: cell.data,
                                x: !all_margins ? x * blockWidth + 0.1 : x * blockWidth,
                                y: !all_margins ? y * blockHeight + 0.1 : y * blockHeight,
                                w: !all_margins ? w * blockWidth - 0.2 : w * blockWidth,
                                h: !all_margins ? h * blockHeight - 0.2 : h * blockHeight,
                                margin: 0
                            });
                        } else {
                            slide.addMedia({
                                type: "video",
                                path: cell.data,
                                x: !all_margins ? x * blockWidth + 0.1 : x * blockWidth,
                                y: !all_margins ? y * blockHeight + 0.1 : y * blockHeight,
                                w: !all_margins ? w * blockWidth - 0.2 : w * blockWidth,
                                h: !all_margins ? h * blockHeight - 0.2 : h * blockHeight,
                                margin: 0
                            });
                        }
                    }
                }

                resolve({ pptx: pptx, slide: slide });
            })
        },
        createNumber: async (pptx, slide, powerPointSettings, cell, items, report_shadows, cell_background_color, cell_font_color, report_background_color, report_transparent_cells, group, report_text_cell_font) => {
            return new Promise(async (resolve, reject) => {
                const { blockWidth, blockHeight } = powerPointSettings;
                if (cell.data) {

                    let default_font_name = "Poppins";
                    if (report_text_cell_font && typeof report_text_cell_font.name == "string" && report_text_cell_font.name !== "") {
                        try {
                            default_font_name = report_text_cell_font.name.replace(/\x00/g, '');
                        } catch (error) { }
                    }

                    let { x, y, w, h } = this.helpers.getCoordinates(cell);

                    let background = "FFFFFF";
                    if (cell && cell.settings && cell.settings.background_color) {
                        background = cell.settings.background_color;
                    }

                    let shadow = false;
                    if (cell.settings && cell.settings.shadow) {
                        shadow = true;
                    }
                    if (cell && cell.settings && cell.settings.transparent) {
                        background = null;
                        shadow = false;
                    }

                    let size = 'large';
                    let font_size_headline = 25;
                    let font_size_subline = 12;
                    if (cell && cell.settings && cell.settings.font_size) {
                        size = cell.settings.font_size.value;
                    }

                    if (size === 'large') {
                        font_size_headline = 25;
                        font_size_subline = 12;
                    } else if (size === 'medium') {
                        font_size_headline = 20;
                        font_size_subline = 10;
                    } else if (size === 'small') {
                        font_size_headline = 16;
                        font_size_subline = 8;
                    } else {
                        font_size_headline = 25;
                        font_size_subline = 12;
                    }

                    this.helpers.addShapes(pptx, cell, slide, powerPointSettings, background);

                    slide.addShape(pptx.shapes.ROUNDED_RECTANGLE, {
                        x: x * blockWidth + 0.1,
                        y: y * blockHeight + 0.1,
                        w: w * blockWidth - 0.2,
                        h: h * blockHeight - 0.2,
                        rectRadius: 0.1,
                        ...(background && { fill: { color: background } }),
                        ...(shadow && { shadow: { ...shadowOpts } })
                    });

                    if (cell.celltype && cell.celltype.id == "performance") {

                        let labels = ["Time passed"];
                        let labelsA = [daysLeft(cell)];
                        let labelsB = [totalDays(cell)];
                        Object.keys(cell.settings.performance_target).map((key) => {
                            labels.push(key);
                            labelsA.push(cell.data ? +cell.data.total : "-");
                            labelsB.push(+cell.settings.performance_target[key]);
                        })

                        let dataChartBar = [
                            {
                                name: "A",
                                labels: labels,
                                values: labelsA.map((num, index) => {
                                    if (num) {
                                        const data = (num / labelsB[index]) * 100
                                        return data > 100 ? 100 : data;
                                    }
                                }),
                            },
                            {
                                name: "B",
                                labels: labels,
                                values: labelsA.map((num, index) => {
                                    if (num) {
                                        const data = 100 - (num / labelsB[index]) * 100

                                        return data < 0 ? 0 : data;
                                    }
                                }),
                            },
                        ];

                        let char_options = {
                            x: (x * blockWidth + 0.1) + 0.5,
                            y: (y * blockHeight + 0.2) + (0.92),
                            w: (w * blockWidth - 0.2) - 1,
                            h: (h * blockHeight - 0.2) - 1.1,
                            barDir: "bar",
                            barGrouping: "percentStacked",
                            showTitle: false,
                            catAxisHidden: true,
                            valAxisHidden: true,
                            barGapWidthPct: 230,
                            chartColors: [cell_font_color || '333333', calculateColorWithOpacity(cell_font_color || '333333', 0.2, background)],
                            valGridLine: { style: "none" },
                        };

                        slide.addText(cell.header, {
                            x: (x * blockWidth + 0.1) + 0.5,
                            y: (y * blockHeight + 0.2),
                            w: (w * blockWidth - 0.2) - 1,
                            h: 0.5,
                            fontSize: 14,
                            bold: true,
                            align: "center",
                            color: cell_font_color ? cell_font_color : '333333',
                            fontFace: default_font_name,
                        });

                        slide.addChart(
                            pptx.charts.BAR,
                            dataChartBar,
                            char_options
                        );

                        let yStart = (y * blockHeight + 0.2) + (0.92);
                        let heightStep = ((h * blockHeight - 0.2) - 1.1) / labels.length;

                        labels.forEach((label, index) => {
                            slide.addText(`${label}`, {
                                x: (x * blockWidth + 0.15) + 0.5,
                                y: (yStart + index * heightStep) + 0.25 - index / 10,
                                w: label.length * 0.2,
                                color: cell_font_color ? cell_font_color : '333333',
                                align: "left",
                                fontFace: default_font_name,
                                fontSize: 12,
                            });

                            let valueText = `${labelsA[index]}/${labelsB[index]}`;
                            let textWidth = valueText.length * 0.2;

                            slide.addText(`${labelsA[index]}/${labelsB[index]}`, {
                                x: (w * blockWidth - 0.7) - textWidth,
                                y: (yStart + index * heightStep) + 0.25 - index / 10,
                                w: textWidth,
                                color: cell_font_color ? cell_font_color : '333333',
                                align: "right",
                                fontFace: default_font_name,
                                fontSize: 12,
                            });
                        });

                        if (cell.settings && typeof cell.settings.title == "string" && cell.settings.title !== "") {
                            slide.addText((cell.settings ? (cell.settings.title !== "" ? cell.settings.title : "...") : "..."), {
                                x: x * blockWidth + 0.1,
                                y: (y + (y * 0.24)) + 0.5,
                                w: w * blockWidth - 0.2,
                                color: cell_font_color ? cell_font_color : '000000',
                                fontFace: default_font_name,
                                fontSize: 14,
                                align: 'center'
                            });
                        }

                    } else {
                        let ops = [
                            {
                                text: addCommas(cell.data.total) + (cell.data.suffix ? (" " + cell.data.suffix) : ""),
                                options: { fontSize: font_size_headline, bold: true, color: cell_font_color ? cell_font_color : '333333', breakLine: true }
                            },
                            {
                                text: cell.header,
                                options: { fontSize: font_size_subline, bold: false, color: cell_font_color ? cell_font_color : '333333', breakLine: true }
                            },
                        ];

                        let align = "center";
                        if (cell.settings && cell.settings.align && cell.settings.align.value == "left") {
                            align = "left";
                        } else if (cell.settings && cell.settings.align && cell.settings.align.value == "end") {
                            align = "right";
                        }

                        slide.addText([...ops], {
                            x: x * blockWidth + 0.1,
                            y: y * blockHeight + 0.1,
                            w: w * blockWidth - 0.2,
                            h: h * blockHeight - 0.2,
                            align: align,
                            valign: 'middle',
                            fontFace: default_font_name,
                            margin: 0,
                        });

                        if (cell.daterange && cell.daterange.compare_dates && cell.daterange.compare_dates.value === "enabled") {
                            let value = compareNumber();
                            let color = "";

                            //GREEN
                            if (((metricGoal() === "highest" && compareNumber() > 0) || (metricGoal() === "lowest" && compareNumber() < 0))) {
                                color = "1AD5BD";
                            }

                            //RED
                            if (((metricGoal() === "lowest" && compareNumber() > 0) || (metricGoal() === "highest" && compareNumber() < 0))) {
                                color = "FF4D54";
                            }

                            if (value) {
                                slide.addText([{
                                    text: value + '%',
                                    options: { fontSize: 12, bold: false, color: color, breakLine: false }
                                }], {
                                    x: (x * blockWidth - 0),
                                    y: (y * blockHeight + 0.25),
                                    w: (w * blockWidth - 0.2),
                                    align: 'right',
                                    valign: 'top',
                                    fontFace: default_font_name,
                                });
                            }
                        }
                    }
                }

                resolve({ pptx: pptx, slide: slide });

                function metricGoal() {
                    let metric = null;
                    for (let key in cell.metrics) {
                        metric = cell.metrics[key];
                    }
                    if (metric && metric.goal && metric.goal.value === "highest") {
                        return "highest"
                    } else if (metric && metric.goal && metric.goal.value === "lowest") {
                        return "lowest"
                    } else {
                        return null;
                    }
                }

                function compareNumber() {
                    if (cell.daterange && cell.daterange.compare_dates && cell.daterange.compare_dates.value === "enabled") {
                        let preceding_number = 0;
                        let total = cell.data.total;
                        items.map((item) => {
                            if (item.abstract && item.parent_cell == cell.i) {
                                preceding_number = item.data ? item.data.total : 0;
                            }
                        });
                        if (preceding_number > 0) {
                            let increase = total - preceding_number;
                            let value = (increase / preceding_number) * 100;
                            if (value % 1 != 0) {
                                return value.toFixed(2);
                            } else {
                                return value;
                            }
                        } else {
                            return null;
                        }
                    }
                }

                function addCommas(nStr) {
                    nStr += '';
                    var x = nStr.split('.');
                    var x1 = x[0];
                    var x2 = x.length > 1 ? '.' + x[1] : '';
                    var rgx = /(\d+)(\d{3})/;
                    while (rgx.test(x1)) {
                        x1 = x1.replace(rgx, '$1' + ' ' + '$2');
                    }
                    return x1 + x2;
                }

                function daysLeft(cell) {
                    try {
                        if (moment(cell.daterange.end_date).isBefore(moment())) {
                            return totalDays(cell);
                        } else {
                            var b = moment(cell.daterange.start_date);
                            var a = moment();
                            return a.diff(b, 'days')
                        }
                    } catch (error) {
                        return "-";
                    }
                }

                function totalDays(cell) {
                    try {
                        var a = moment(cell.daterange.end_date);
                        var b = moment(cell.daterange.start_date);
                        return a.diff(b, 'days')
                    } catch (error) {
                        return "-";
                    }
                }

                function calculateColorWithOpacity(hex, opacity, mixColor) {
                    let cleanHex = hex.replace('#', '');
                    let cleanMixHex = mixColor.replace('#', '');

                    if (cleanHex.length === 3) {
                        cleanHex = cleanHex.split('').map(char => char + char).join('');
                    }
                    if (cleanMixHex.length === 3) {
                        cleanMixHex = cleanMixHex.split('').map(char => char + char).join('');
                    }

                    let r1 = parseInt(cleanHex.slice(0, 2), 16);
                    let g1 = parseInt(cleanHex.slice(2, 4), 16);
                    let b1 = parseInt(cleanHex.slice(4, 6), 16);

                    let r2 = parseInt(cleanMixHex.slice(0, 2), 16);
                    let g2 = parseInt(cleanMixHex.slice(2, 4), 16);
                    let b2 = parseInt(cleanMixHex.slice(4, 6), 16);

                    let r = Math.round((1 - opacity) * r2 + opacity * r1);
                    let g = Math.round((1 - opacity) * g2 + opacity * g1);
                    let b = Math.round((1 - opacity) * b2 + opacity * b1);

                    let newHex = `${r.toString(16).padStart(2, '0').toUpperCase()}${g.toString(16).padStart(2, '0').toUpperCase()}${b.toString(16).padStart(2, '0').toUpperCase()}`;

                    return newHex;
                }
            })
        },
        createChartBar: async (pptx, slide, powerPointSettings, cell, items, report_shadows, cell_background_color, cell_font_color, report_background_color, report_transparent_cells, group, report_text_cell_font) => {
            return new Promise(async (resolve, reject) => {
                const { blockWidth, blockHeight } = powerPointSettings;
                let dataChartAreaLine = [];
                if (cell.data && Array.isArray(cell.data.datasets) && cell.data.datasets.length > 0) {

                    let default_font_name = "Poppins";
                    if (report_text_cell_font && typeof report_text_cell_font.name == "string" && report_text_cell_font.name !== "") {
                        try {
                            default_font_name = report_text_cell_font.name.replace(/\x00/g, '');
                        } catch (error) { }
                    }

                    let { x, y, w, h } = this.helpers.getCoordinates(cell);

                    cell.data = compareNumber(cell);

                    cell.data.datasets.map((item) => {
                        let dataset = {
                            name: item.label,
                            labels: cell.data.labels,
                            backgroundColor: item.backgroundColor.replace('#', ''),
                            borderColor: item.borderColor.replace('#', ''),
                            borderWidth: 2,
                            values: item.data.filter((item) => {
                                return !(item && {}.toString.call(item) === '[object Function]');
                            })
                        };
                        dataChartAreaLine.push(dataset);
                    });

                    let background = "FFFFFF";
                    if (cell && cell.settings && cell.settings.background_color) {
                        background = cell.settings.background_color;
                    }
                    if (cell && cell.settings && cell.settings.transparent) {
                        background = null;
                    }

                    let shadow = false;
                    if (cell.settings && cell.settings.shadow) {
                        shadow = true;
                    }

                    this.helpers.addShapes(pptx, cell, slide, powerPointSettings, background);

                    slide.addShape(pptx.shapes.ROUNDED_RECTANGLE, {
                        x: x * blockWidth + 0.1,
                        y: y * blockHeight + 0.1,
                        w: w * blockWidth - 0.2,
                        h: h * blockHeight - 0.2,
                        rectRadius: 0.1,
                        ...(background && { fill: { color: background } }),
                        ...(shadow && { shadow: { ...shadowOpts } })
                    });

                    let char_options = {
                        x: (x * blockWidth + 0.1) + 0.5,
                        y: (y * blockHeight + 0.2) + (cell.settings && typeof cell.settings.title == "string" && cell.settings.title !== "" ? 0.62 : 0),
                        w: (w * blockWidth - 0.2) - 1,
                        h: (h * blockHeight - 0.2) - 0.8,
                        chartColorsOpacity: 100,
                        showTitle: false,
                        showLegend: !(cell.settings && cell.settings.show_datasources && cell.settings.show_datasources.value == "disabled"),
                        legendColor: typeof cell_font_color == "string" && cell_font_color !== "" ? cell_font_color : '000000',
                        dataLabelColor: cell_font_color ? cell_font_color : '000000',
                        dataLabelFontFace: default_font_name,
                        showValue: cell.settings && cell.settings.chart_numbers && cell.settings.chart_numbers.value === "enabled" ? true : false,
                        legendFontFace: default_font_name,
                        legendPos: 't',
                        catAxisLabelFontFace: default_font_name,
                        valAxisLabelFontFace: default_font_name,
                        catAxisLabelColor: typeof cell_font_color == "string" && cell_font_color !== "" ? cell_font_color : '000000',
                        valAxisLabelColor: typeof cell_font_color == "string" && cell_font_color !== "" ? cell_font_color : '000000'
                    };

                    if (dataChartAreaLine && dataChartAreaLine.length === 2 && cell.settings.multi_y_axis && cell.settings.multi_y_axis.value === 'enabled') {
                        // make offset
                        dataChartAreaLine[0].labels = [];
                        dataChartAreaLine[0].values = [];
                        dataChartAreaLine[1].labels = [];
                        dataChartAreaLine[1].values = [];

                        cell.data.labels.map(value => {
                            dataChartAreaLine[0].labels.push(value);
                            dataChartAreaLine[0].labels.push("");
                            dataChartAreaLine[1].labels.push("");
                            dataChartAreaLine[1].labels.push(value);
                        });

                        cell.data.datasets.map((item, index) => {
                            item.data.filter((item) => {
                                return !(item && {}.toString.call(item) === '[object Function]');
                            }).map(value => {
                                if (index === 0) {
                                    dataChartAreaLine[index].values.push(value);
                                    dataChartAreaLine[index].values.push(0);
                                } else {
                                    dataChartAreaLine[index].values.push(0);
                                    dataChartAreaLine[index].values.push(value);
                                }
                            });
                        });
                        // make offset
                        char_options.valAxes = [
                            {
                                showValAxisTitle: false,
                                valAxisTitleColor: dataChartAreaLine[0].backgroundColor.replace('#', ''),
                                valAxisLabelColor: dataChartAreaLine[0].backgroundColor.replace('#', ''),
                            },
                            {
                                showValAxisTitle: false,
                                valGridLine: { style: "none" },
                                valAxisTitleColor: dataChartAreaLine[1].backgroundColor.replace('#', ''),
                                valAxisLabelColor: dataChartAreaLine[1].backgroundColor.replace('#', ''),
                            }
                        ]
                        char_options.catAxes = [{ catAxisTitle: "X axis" }, { catAxisHidden: true }];

                        let combo_types = [
                            {
                                type: pptx.charts.BAR,
                                data: [dataChartAreaLine[0]],
                                options: { chartColors: [char_options.valAxes[0].valAxisTitleColor],  barGrouping: "stacked" },
                            },
                            {
                                type: pptx.charts.BAR,
                                data: [dataChartAreaLine[1]],
                                options: { chartColors: [char_options.valAxes[1].valAxisTitleColor], secondaryValAxis: true, secondaryCatAxis: true },
                            },
                        ];

                        slide.addChart(combo_types, char_options)
                    } else {
                        char_options.dataLabelPosition = "outEnd";

                        let datasetColors = [];
                        cell.data.datasets.map((item) => {
                            datasetColors = datasetColors.concat(Array.isArray(item.backgroundColor) ? item.backgroundColor[0] : item.backgroundColor);
                        });

                        if (datasetColors.length) {
                            char_options.chartColors = datasetColors
                        }

                        slide.addChart(
                            pptx.charts.BAR,
                            dataChartAreaLine,
                            char_options
                        );
                    }

                    if (cell.settings && typeof cell.settings.title == "string" && cell.settings.title !== "") {
                        slide.addText((cell.settings ? (cell.settings.title !== "" ? cell.settings.title : "...") : "..."), {
                            x: x * blockWidth + 0.1,
                            y: (y + (y * 0.24)) + 0.5,
                            w: w * blockWidth - 0.2,
                            color: typeof cell_font_color == "string" && cell_font_color !== "" ? cell_font_color : '000000',
                            fontFace: default_font_name,
                            fontSize: 14,
                            align: 'center'
                        });
                    }
                }
                resolve({ pptx: pptx, slide: slide });

                function compareNumber(cell) {
                    if (cell.daterange && cell.daterange.compare_dates && cell.daterange.compare_dates.value === "enabled" && cell.data) {

                        let compare_data_to_labels = {};
                        let original_data = cell.data;
                        let preceding_data = null;
                        items.map((item) => {
                            if (item.abstract && item.parent_cell == cell.i) {
                                preceding_data = item;
                            }
                        });
                        if (preceding_data && preceding_data.data && Array.isArray(preceding_data.data.datasets) && preceding_data.data.datasets.length > 0) {

                            //GET CHANNEL
                            let channel = null;
                            for (let campaign in cell.data_sources.campaigns) {
                                if (!channel) {
                                    channel = cell.data_sources.campaigns[campaign].channel;
                                }
                            }
                            for (let account in cell.data_sources.accounts) {
                                if (!channel) {
                                    channel = cell.data_sources.accounts[account].channel;
                                }
                            }
                            for (let campaign in cell.data_sources.campaigns) {
                                if (!channel) {
                                    channel = cell.data_sources.campaigns[campaign].channel;
                                }
                            }
                            for (let adgroup in cell.data_sources.adgroups) {
                                if (!channel) {
                                    channel = cell.data_sources.adgroups[adgroup].channel;
                                }
                            }
                            for (let ad in cell.data_sources.ads) {
                                if (!channel) {
                                    channel = cell.data_sources.ads[ad].channel;
                                }
                            }

                            //CHECK IF SAME DATA EXIST IN BOTH DATASET, OTHERWISE MERGE

                            //ORIGINAL DATA
                            original_data.labels.map((label, index) => {
                                compare_data_to_labels[label] = { "original": original_data.datasets[0].data[index], "preciding": 0 };

                                if (channel) {
                                    if ("date" in cell.channel_breakdowns[channel]) {
                                        compare_data_to_labels[label] = { "original": original_data.datasets[0].data[index], "preciding": preceding_data.data.datasets[0].data[index] };
                                    }
                                }

                            });

                            //PRECIDING DATA
                            if (channel && !("date" in cell.channel_breakdowns[channel])) {
                                preceding_data.data.labels.map((label, index) => {
                                    if (!compare_data_to_labels[label]) {
                                        compare_data_to_labels[label] = { "original": 0, "preciding": preceding_data.data.datasets[0].data[index] };
                                    } else {
                                        compare_data_to_labels[label]["preciding"] = preceding_data.data.datasets[0].data[index];
                                    }
                                });
                            }

                            let time_string = "";
                            if (preceding_data.daterange.start_date && preceding_data.daterange.end_date) {
                                var start = moment(preceding_data.daterange.start_date, "YYYY-MM-DD");
                                var end = moment(preceding_data.daterange.end_date, "YYYY-MM-DD");
                                let days = moment.duration(start.diff(end)).asDays();
                                days = days * -1;
                                let start_date = moment(start).subtract(days + 1, 'days').format("YYYY-MM-DD");
                                let end_date = moment(end).subtract(days + 1, 'days').format("YYYY-MM-DD");
                                time_string = start_date + " - " + end_date;
                            }

                            let merged_data = {
                                datasets: [
                                    {
                                        label: original_data.datasets[0].label,
                                        backgroundColor: original_data.datasets[0].backgroundColor,
                                        borderColor: original_data.datasets[0].borderColor,
                                        data: [],
                                        borderWidth: 2
                                    },
                                    {
                                        label: "Preciding period: " + time_string,
                                        backgroundColor: "cfbcb6",
                                        borderColor: "cfbcb6",
                                        borderDash: [5, 5],
                                        data: [],
                                        borderWidth: 2
                                    }],
                                labels: []
                            };

                            for (let key in compare_data_to_labels) {

                                //SET DATA
                                merged_data.datasets[0].data.push(compare_data_to_labels[key].original);
                                merged_data.datasets[1].data.push(compare_data_to_labels[key].preciding);

                                //SET LABELS
                                let label = [];
                                label.push(key);
                                merged_data.labels.push(label);

                            }

                            return merged_data;
                        } else {
                            return original_data;
                        }
                    } else {
                        return cell.data;
                    }

                }

            })
        },
        createChartLine: async (pptx, slide, powerPointSettings, cell, items, report_shadows, cell_background_color, cell_font_color, report_background_color, report_transparent_cells, group, report_text_cell_font) => {
            return new Promise(async (resolve, reject) => {
                const { blockWidth, blockHeight } = powerPointSettings;
                let dataChartAreaLine = [];

                if (cell.data && Array.isArray(cell.data.datasets) && cell.data.datasets.length > 0) {

                    let default_font_name = "Poppins";
                    if (report_text_cell_font && typeof report_text_cell_font.name == "string" && report_text_cell_font.name !== "") {
                        try {
                            default_font_name = report_text_cell_font.name.replace(/\x00/g, '');
                        } catch (error) { }
                    }

                    let { x, y, w, h } = this.helpers.getCoordinates(cell);

                    cell.data = compareNumber(cell);

                    cell.data.datasets.map((item) => {
                        let dataset = {
                            name: item.label,
                            labels: cell.data.labels,
                            backgroundColor: item.backgroundColor.replace('#', ''),
                            borderColor: item.borderColor.replace('#', ''),
                            borderWidth: 2,
                            BorderOptions: { type: "dash" },
                            values: item.data.filter((item) => {
                                return !(item && {}.toString.call(item) === '[object Function]');
                            })
                        };
                        dataChartAreaLine.push(dataset);
                    });

                    let background = "FFFFFF";
                    if (cell && cell.settings && cell.settings.background_color) {
                        background = cell.settings.background_color;
                    }
                    if (cell && cell.settings && cell.settings.transparent) {
                        background = null;
                    }

                    let shadow = false;
                    if (cell.settings && cell.settings.shadow) {
                        shadow = true;
                    }

                    this.helpers.addShapes(pptx, cell, slide, powerPointSettings, background);

                    slide.addShape(pptx.shapes.ROUNDED_RECTANGLE, {
                        x: x * blockWidth + 0.1,
                        y: y * blockHeight + 0.1,
                        w: w * blockWidth - 0.2,
                        h: h * blockHeight - 0.2,
                        rectRadius: 0.1,
                        ...(background && { fill: { color: background } }),
                        ...(shadow && { shadow: { ...shadowOpts } })
                    });

                    let char_options =  {
                        lineSmooth: true,
                        x: (x * blockWidth + 0.1) + 0.5,
                        y: (y * blockHeight + 0.2) + (cell.settings && typeof cell.settings.title == "string" && cell.settings.title !== "" ? 0.62 : 0),
                        w: (w * blockWidth - 0.2) - 1,
                        h: (h * blockHeight - 0.2) - 0.8,
                        chartColorsOpacity: 100,
                        showTitle: false,
                        showLegend: !(cell.settings && cell.settings.show_datasources && cell.settings.show_datasources.value == "disabled"),
                        legendColor: cell_font_color ? cell_font_color : '000000',
                        legendFontFace: default_font_name,
                        dataLabelColor: cell_font_color ? cell_font_color : '000000',
                        dataLabelFontFace: default_font_name,
                        showValue: cell.settings && cell.settings.chart_numbers && cell.settings.chart_numbers.value === "enabled" ? true : false,
                        legendPos: 't',
                        catAxisLabelFontFace: default_font_name,
                        valAxisLabelFontFace: default_font_name,
                        catAxisLabelColor: cell_font_color ? cell_font_color : '000000',
                        valAxisLabelColor: cell_font_color ? cell_font_color : '000000'
                    }


                    if (dataChartAreaLine && dataChartAreaLine.length === 2 && cell.settings.multi_y_axis && cell.settings.multi_y_axis.value === 'enabled') {
                        char_options.valAxes = [
                            {
                                showValAxisTitle: false,
                                valAxisTitleColor: dataChartAreaLine[0].backgroundColor.replace('#', ''),
                                valAxisLabelColor: dataChartAreaLine[0].backgroundColor.replace('#', ''),
                            },
                            {
                                showValAxisTitle: false,
                                valGridLine: { style: "none" },
                                valAxisTitleColor: dataChartAreaLine[1].backgroundColor.replace('#', ''),
                                valAxisLabelColor: dataChartAreaLine[1].backgroundColor.replace('#', ''),
                            }
                        ]
                        char_options.catAxes = [{ catAxisTitle: "X axis" }, { catAxisHidden: true }];

                        let combo_types = [
                            {
                                type: pptx.charts.LINE,
                                data: [dataChartAreaLine[0]],
                                options: { chartColors: [char_options.valAxes[0].valAxisTitleColor],  barGrouping: "stacked" },
                            },
                            {
                                type: pptx.charts.LINE,
                                data: [dataChartAreaLine[1]],
                                options: { chartColors: [char_options.valAxes[1].valAxisTitleColor], secondaryValAxis: true, secondaryCatAxis: true },
                            },
                        ];

                        slide.addChart(combo_types, char_options)
                    } else {
                        let datasetColors = [];
                        cell.data.datasets.map((item) => {
                            datasetColors = datasetColors.concat(Array.isArray(item.backgroundColor) ? item.backgroundColor[0] : item.backgroundColor);
                        });

                        if (datasetColors.length) {
                            char_options.chartColors = datasetColors
                        }

                        slide.addChart(
                            pptx.charts.LINE,
                            dataChartAreaLine,
                            char_options
                        );
                    }

                    if (cell.settings && typeof cell.settings.title == "string" && cell.settings.title !== "") {
                        slide.addText((cell.settings ? (cell.settings.title !== "" ? cell.settings.title : "...") : "..."), {
                            x: x * blockWidth + 0.1,
                            y: (y + (y * 0.24)) + 0.5,
                            w: w * blockWidth - 0.2,
                            color: cell_font_color ? cell_font_color : '000000',
                            fontFace: default_font_name,
                            fontSize: 14,
                            align: 'center'
                        });
                    }
                }

                resolve({ pptx: pptx, slide: slide });

                function compareNumber(cell) {
                    if (cell.daterange && cell.daterange.compare_dates && cell.daterange.compare_dates.value === "enabled" && cell.data) {

                        let compare_data_to_labels = {};
                        let original_data = cell.data;
                        let preceding_data = null;
                        items.map((item) => {
                            if (item.abstract && item.parent_cell == cell.i) {
                                preceding_data = item;
                            }
                        });
                        if (preceding_data && preceding_data.data && Array.isArray(preceding_data.data.datasets) && preceding_data.data.datasets.length > 0) {

                            //GET CHANNEL
                            let channel = null;
                            for (let campaign in cell.data_sources.campaigns) {
                                if (!channel) {
                                    channel = cell.data_sources.campaigns[campaign].channel;
                                }
                            }
                            for (let account in cell.data_sources.accounts) {
                                if (!channel) {
                                    channel = cell.data_sources.accounts[account].channel;
                                }
                            }
                            for (let campaign in cell.data_sources.campaigns) {
                                if (!channel) {
                                    channel = cell.data_sources.campaigns[campaign].channel;
                                }
                            }
                            for (let adgroup in cell.data_sources.adgroups) {
                                if (!channel) {
                                    channel = cell.data_sources.adgroups[adgroup].channel;
                                }
                            }
                            for (let ad in cell.data_sources.ads) {
                                if (!channel) {
                                    channel = cell.data_sources.ads[ad].channel;
                                }
                            }

                            //CHECK IF SAME DATA EXIST IN BOTH DATASET, OTHERWISE MERGE

                            //ORIGINAL DATA
                            original_data.labels.map((label, index) => {
                                compare_data_to_labels[label] = { "original": original_data.datasets[0].data[index], "preciding": 0 };

                                if (channel) {
                                    if ("date" in cell.channel_breakdowns[channel]) {
                                        compare_data_to_labels[label] = { "original": original_data.datasets[0].data[index], "preciding": preceding_data.data.datasets[0].data[index] };
                                    }
                                }

                            });

                            //PRECIDING DATA
                            if (channel && !("date" in cell.channel_breakdowns[channel])) {
                                preceding_data.data.labels.map((label, index) => {
                                    if (!compare_data_to_labels[label]) {
                                        compare_data_to_labels[label] = { "original": 0, "preciding": preceding_data.data.datasets[0].data[index] };
                                    } else {
                                        compare_data_to_labels[label]["preciding"] = preceding_data.data.datasets[0].data[index];
                                    }
                                });
                            }

                            let time_string = "";
                            if (preceding_data.daterange.start_date && preceding_data.daterange.end_date) {
                                var start = moment(preceding_data.daterange.start_date, "YYYY-MM-DD");
                                var end = moment(preceding_data.daterange.end_date, "YYYY-MM-DD");
                                let days = moment.duration(start.diff(end)).asDays();
                                days = days * -1;
                                let start_date = moment(start).subtract(days + 1, 'days').format("YYYY-MM-DD");
                                let end_date = moment(end).subtract(days + 1, 'days').format("YYYY-MM-DD");
                                time_string = start_date + " - " + end_date;
                            }

                            let merged_data = {
                                datasets: [
                                    {
                                        label: original_data.datasets[0].label,
                                        backgroundColor: original_data.datasets[0].backgroundColor,
                                        borderColor: original_data.datasets[0].borderColor,
                                        data: [],
                                        borderWidth: 2
                                    },
                                    {
                                        label: "Preciding period: " + time_string,
                                        backgroundColor: "cfbcb6",
                                        borderColor: "cfbcb6",
                                        borderDash: [5, 5],
                                        data: [],
                                        borderWidth: 2
                                    }],
                                labels: []
                            };

                            for (let key in compare_data_to_labels) {

                                //SET DATA
                                merged_data.datasets[0].data.push(compare_data_to_labels[key].original);
                                merged_data.datasets[1].data.push(compare_data_to_labels[key].preciding);

                                //SET LABELS
                                let label = [];
                                label.push(key);
                                merged_data.labels.push(label);

                            }

                            return merged_data;
                        } else {
                            return original_data;
                        }
                    } else {
                        return cell.data;
                    }
                }

            })
        },
        createChartDonut: async (pptx, slide, powerPointSettings, cell, report_shadows, cell_background_color, cell_font_color, report_background_color, report_transparent_cells, group, report_text_cell_font) => {
            return new Promise(async (resolve, reject) => {
                const { blockWidth, blockHeight } = powerPointSettings;
                let dataChartAreaLine = [];
                if (cell.data && Array.isArray(cell.data.datasets) && cell.data.datasets.length > 0) {

                    let default_font_name = "Poppins";
                    if (report_text_cell_font && typeof report_text_cell_font.name == "string" && report_text_cell_font.name !== "") {
                        try {
                            default_font_name = report_text_cell_font.name.replace(/\x00/g, '');
                        } catch (error) { }
                    }

                    let { x, y, w, h } = this.helpers.getCoordinates(cell);

                    cell.data.datasets.map((item) => {
                        let dataset = {
                            name: item.label,
                            labels: cell.data.labels,
                            values: item.data.filter((item) => {
                                return !(item && {}.toString.call(item) === '[object Function]');
                            })
                        };
                        dataChartAreaLine.push(dataset);
                    });

                    let background = "FFFFFF";
                    if (cell && cell.settings && cell.settings.background_color) {
                        background = cell.settings.background_color;
                    }
                    if (cell && cell.settings && cell.settings.transparent) {
                        background = null;
                    }

                    let shadow = false;
                    if (cell.settings && cell.settings.shadow) {
                        shadow = true;
                    }

                    this.helpers.addShapes(pptx, cell, slide, powerPointSettings, background);

                    slide.addShape(pptx.shapes.ROUNDED_RECTANGLE, {
                        x: x * blockWidth + 0.1,
                        y: y * blockHeight + 0.1,
                        w: w * blockWidth - 0.2,
                        h: h * blockHeight - 0.2,
                        rectRadius: !group.disable_padding ? 0.1 : 0.0000000000000001,
                        ...(background && { fill: { color: background } }),
                        ...(shadow && { shadow: { ...shadowOpts } })
                    });

                    let chartColors = [];
                    cell.data.datasets.map((item) => {
                        chartColors = chartColors.concat(item.backgroundColor.map((color) => {
                            return color;
                        }));
                    });

                    slide.addChart(
                        pptx.charts.DOUGHNUT,
                        dataChartAreaLine,
                        {
                            chartColors: chartColors,
                            x: (x * blockWidth + 0.1) + 0.5,
                            y: (y * blockHeight + 0.2) + (cell.settings && typeof cell.settings.title == "string" && cell.settings.title !== "" ? 0.62 : 0),
                            w: (w * blockWidth - 0.2) - 1,
                            h: (h * blockHeight - 0.2) - 0.8,
                            chartColorsOpacity: 100,
                            showTitle: false,
                            legendPos: 'l',
                            showLegend: !(cell.settings && cell.settings.show_datasources && cell.settings.show_datasources.value == "disabled"),
                            legendColor: cell_font_color ? cell_font_color : '000000',
                            legendFontFace: default_font_name,
                            dataLabelColor: cell_font_color ? cell_font_color : '000000',
                            dataLabelFontFace: default_font_name,
                            dataLabelPosition: "outEnd",
                            showValue: cell.settings && cell.settings.chart_numbers && cell.settings.chart_numbers.value === "enabled" ? true : false,
                            catAxisLabelFontFace: default_font_name,
                            valAxisLabelFontFace: default_font_name,
                            catAxisLabelColor: cell_font_color ? cell_font_color : '000000',
                            valAxisLabelColor: cell_font_color ? cell_font_color : '000000'
                        }
                    );
                    if (cell.settings && typeof cell.settings.title == "string" && cell.settings.title !== "") {
                        slide.addText((cell.settings ? (cell.settings.title !== "" ? cell.settings.title : "...") : "..."), {
                            x: x * blockWidth + 0.1,
                            y: (y + (y * 0.24)) + 0.5,
                            w: w * blockWidth - 0.2,
                            color: cell_font_color ? cell_font_color : '000000',
                            fontFace: default_font_name,
                            fontSize: 14,
                            align: 'center'
                        });
                    }
                }
                resolve({ pptx: pptx, slide: slide });
            })
        },
        createTable: async (pptx, slide, powerPointSettings, cell, items, report_shadows, cell_background_color, cell_font_color, report_background_color, report_transparent_cells, group, report_text_cell_font) => {
            return new Promise(async (resolve, reject) => {

                const { blockWidth, blockHeight } = powerPointSettings;

                if (Array.isArray(cell.data) && Array.isArray(cell.columns)) {

                    let default_font_name = "Poppins";
                    if (report_text_cell_font && typeof report_text_cell_font.name == "string" && report_text_cell_font.name !== "") {
                        try {
                            default_font_name = report_text_cell_font.name.replace(/\x00/g, '');
                        } catch (error) { }
                    }

                    let { x, y, w, h } = this.helpers.getCoordinates(cell);

                    cell = compareNumber(cell);

                    let background = "FFFFFF";
                    if (cell && cell.settings && cell.settings.background_color) {
                        background = cell.settings.background_color;
                    }
                    let shadow = false;
                    if (cell.settings && cell.settings.shadow) {
                        shadow = true;
                    }
                    if (cell && cell.settings && cell.settings.transparent) {
                        background = null;
                        shadow = false;
                    }

                    let header_background = 'FCFCFC';
                    if (cell.settings && cell.settings.table_header_background_color) {
                        header_background = cell.settings && cell.settings.table_header_background_color;
                    }

                    let header_font = '333333';
                    if (cell.settings && cell.settings.table_header_font_color) {
                        header_font = cell.settings && cell.settings.table_header_font_color;
                    }

                    let row_background = 'FCFCFC';
                    if (cell.settings && cell.settings.table_row_background_color) {
                        row_background = cell.settings && cell.settings.table_row_background_color;
                    }

                    let row_font = '333333';
                    if (cell.settings && cell.settings.table_row_font_color) {
                        row_font = cell.settings && cell.settings.table_row_font_color;
                    }

                    this.helpers.addShapes(pptx, cell, slide, powerPointSettings, background);

                    slide.addShape(pptx.shapes.ROUNDED_RECTANGLE, {
                        x: x * blockWidth + 0.1,
                        y: y * blockHeight + 0.1,
                        w: w * blockWidth - 0.2,
                        h: h * blockHeight - 0.2,
                        rectRadius: 0.1,
                        ...(background && { fill: { color: background } }),
                        ...(shadow && { shadow: { ...shadowOpts } })
                    });

                    let headers = [];
                    if (Array.isArray(cell.default_columns)) {
                        cell.default_columns.map((item) => {
                            if (item.value !== "preview") {
                                let header = {
                                    text: item.title ? item.title : item.value, options: {
                                        color: { color: header_font }, fill: { color: header_background }, valign: "middle", align: "left"
                                    }
                                };
                                headers.push(header);
                            }
                        })
                    }
                    cell.columns.map((item) => {
                        let header = {};
                        if (item.indexOf("##preciding##") !== -1) {
                            item = columnName(item);
                            header = {
                                text: item, options: {
                                    color: { color: header_font },
                                    fill: { color: header_background }, valign: "middle", align: "left"
                                }
                            };
                        } else {
                            item = columnName(item);
                            header = {
                                text: item, options: {
                                    color: { color: header_font },
                                    fill: { color: header_background }, valign: "middle", align: "left"
                                }
                            };
                        }
                        headers.push(header);
                    })
                    let rows = [];
                    cell.data.map((item) => {
                        let row = [];
                        if (Array.isArray(cell.default_columns)) {
                            cell.default_columns.map((inner_item) => {
                                if (inner_item.value !== "preview") {
                                    let value = item[inner_item.value];
                                    let row_item = {
                                        text: value, options: {
                                            color: { color: row_font },
                                            fill: { color: row_background }, valign: "middle", align: "left"
                                        }
                                    };
                                    row.push(row_item);
                                }
                            });
                        }
                        cell.columns.map((column) => {
                            let value = 0;
                            let row_item = {};
                            if (column.indexOf("##preciding##") !== -1 && item[column]) {
                                value = item[column] + "%";
                                let color = "#000000";
                                if (((metricGoal(column) === "highest" && item[column] > 0) || (metricGoal(column) === "lowest" && item[column] < 0))) {
                                    color = "#1AD5BD";
                                }
                                if (((metricGoal(column) === "lowest" && item[column] > 0) || (metricGoal(column) === "highest" && item[column] < 0))) {
                                    color = "#FF4D54";
                                }
                                row_item = {
                                    text: value, options: {
                                        fill: { color: row_background }, color: color, valign: "middle", align: "left"
                                    }
                                };
                            } else {
                                value = item[column] + (metricSuffix(column) ? " " + metricSuffix(column) : "");
                                row_item = {
                                    text: value, options: {
                                        color: { color: row_font },
                                        fill: { color: row_background }, valign: "middle", align: "left"
                                    }
                                };
                            }
                            row.push(row_item);
                        })
                        rows.push(row);
                    });
                    rows.unshift(headers);
                    slide.addTable(rows, {
                        x: (x * blockWidth + 0.1) + 0.5,
                        y: (cell.settings && typeof cell.settings.title == "string" && cell.settings.title !== "" ? (y * blockHeight + 0.8) : (y * blockHeight + 0.6)),
                        w: (w * blockWidth - 0.2) - 1,
                        fill: { color: "F7F7F7" },
                        fontSize: 12,
                        rowH: 0.1,
                        color: "363636",
                        border: { pt: "1", color: "BBCCDD" },
                        fontFace: default_font_name
                    });
                    if (cell.settings && typeof cell.settings.title == "string" && cell.settings.title !== "") {
                        slide.addText((cell.settings ? (cell.settings.title !== "" ? cell.settings.title : "...") : "..."), {
                            x: x * blockWidth + 0.1,
                            y: (y + (y * 0.24)) + 0.5,
                            w: w * blockWidth - 0.2,
                            color: cell_font_color ? cell_font_color : '000000',
                            fontFace: default_font_name,
                            fontSize: 14,
                            align: 'center'
                        });
                    }
                }

                resolve({ pptx: pptx, slide: slide });

                function compareNumber(cell) {

                    if (cell.data && cell.daterange && cell.daterange.compare_dates && cell.daterange.compare_dates.value === "enabled") {

                        let compare_data_to_labels = {};
                        let preceding_table = {};
                        let orginal_table = JSON.parse(JSON.stringify(cell.data));

                        //REMOVE OLD PRECIDING COLUMN
                        let clean_columns = cell.columns.filter((column) => {
                            return column.indexOf("##preciding##") === -1
                        });

                        items.map((item) => {
                            if (item.abstract && item.parent_cell == cell.i) {
                                preceding_table = JSON.parse(JSON.stringify(item.data));
                            }
                        });

                        orginal_table.map((item) => {
                            if (!compare_data_to_labels[item.id + item.channel + item.breakdown_value]) {
                                compare_data_to_labels[item.id + item.channel + item.breakdown_value] = { 'original': item, 'preciding': {} };
                            }
                        });

                        if (Array.isArray(preceding_table) && preceding_table.length > 0) {
                            var start = moment(preceding_table[0].breakdown_value, "YYYY-MM-DD");
                            var end = moment(preceding_table[preceding_table.length - 1].breakdown_value, "YYYY-MM-DD");
                            let days = moment.duration(end.diff(start)).asDays();
                            preceding_table.map((item) => {
                                if (item.breakdown !== "date") {
                                    if (!compare_data_to_labels[item.id + item.channel + item.breakdown_value]) {
                                        compare_data_to_labels[item.id + item.channel + item.breakdown_value] = { 'original': {}, 'preciding': item };
                                    } else {
                                        compare_data_to_labels[item.id + item.channel + item.breakdown_value].preciding = item;
                                    }
                                } else {
                                    let new_date = moment(item.breakdown_value).add(days + 1, 'days').format("YYYY-MM-DD");
                                    if (compare_data_to_labels[item.id + item.channel + new_date]) {
                                        compare_data_to_labels[item.id + item.channel + new_date].preciding = item;
                                        compare_data_to_labels[item.id + item.channel + new_date].preciding_value = { date: item.breakdown_value, item }
                                    }
                                }
                            });
                        }

                        let new_columns = [];
                        let new_rows = [];
                        clean_columns.map((column) => {
                            let exist = false;
                            new_columns.push(column);
                            Object.keys(cell.metrics).map((key) => {
                                if (!exist) {
                                    let metric = cell.metrics[key];
                                    if (metric.goal && metric.goal.value) {
                                        if (key == column) {
                                            exist = true;
                                            new_columns.push("##preciding##" + key + "##preciding##");
                                        }
                                    }
                                }
                            })
                        });

                        for (let key in compare_data_to_labels) {
                            let new_row = compare_data_to_labels[key].original;
                            for (let item in compare_data_to_labels[key].preciding) {
                                new_row["##preciding##" + item + "##preciding##"] = comparedValue(cell, compare_data_to_labels[key].original[item], compare_data_to_labels[key].preciding[item], item);
                            }
                            new_rows.push(new_row);
                        }

                        cell.columns = new_columns;
                        cell.data = new_rows;

                        return cell;

                    } else {

                        return cell;

                    }

                    function comparedValue(cell, org_number, preceding_number, metric_name) {
                        let metric = cell.metrics[metric_name];
                        if (metric) {
                            try {
                                if (preceding_number > 0) {
                                    let increase = org_number - preceding_number;
                                    let value = (increase / preceding_number) * 100;
                                    return value.toFixed(2);;
                                } else {
                                    return "";
                                }
                            } catch (error) {
                                return "";
                            }
                        } else {
                            return "";
                        }
                    }

                }

                function metricGoal(key) {
                    key = key.replace("##preciding##", '').replace("##preciding##", '');
                    let metric = cell.metrics[key];
                    if (metric && metric.goal && metric.goal.value === "highest") {
                        return "highest"
                    } else if (metric && metric.goal && metric.goal.value === "lowest") {
                        return "lowest"
                    } else {
                        return null;
                    }
                }

                function metricSuffix(key) {
                    try {
                        if (cell.all_metrics[key]) {
                            if (cell.all_metrics[key].suffix && cell.all_metrics[key].suffix.value == "%") {
                                return "%";
                            } else if (cell.all_metrics[key].suffix && cell.all_metrics[key].suffix.value == "currency") {
                                if (cell.all_metrics[key].currencyOption && cell.all_metrics[key].currencyOption.value == "static") {
                                    return cell.all_metrics[key].currency;
                                } else {
                                    //ADACCOUNT
                                    return null;
                                }
                            }
                        }
                    } catch (error) {
                        return null;
                    }
                    return null;
                }

                function metricExternalName(key) {
                    try {
                        if (cell.all_metrics[key]) {
                            if (cell.all_metrics[key].showExternalName) {
                                return cell.all_metrics[key].externalName;
                            }
                        }
                    } catch (error) {
                        return key;
                    }
                    return key;
                }

                function columnName(column) {
                    if (column.indexOf("##preciding##") !== -1) {
                        return "% Change";
                    } else {
                        return metricExternalName(column)
                    }
                }

            })
        },
        renderPreview: async (cell) => {
            function processImage(img) {
                const parent = img.parentNode;
                const scaleFactor = parent.clientHeight / img.naturalHeight;
                img.style.width = `${img.naturalWidth * scaleFactor}px`;
                //img.style.height = `${parent.clientHeight}px`;
                img.style.height = "auto";
                parent.style.display = 'flex';
                parent.style.alignItems = 'center';
                parent.style.justifyContent = 'center';
            }

            let tempDiv;

            tempDiv = document.createElement('div');
            tempDiv.style.position = 'absolute';
            tempDiv.style.top = '-99999px';
            tempDiv.style.width = `${cell.w * 150}px`;
            document.body.appendChild(tempDiv);

            let cellComponent = this.functions.getPreviewHtml(cell);

            console.log(cellComponent)

            try {
                ReactDOM.render(cellComponent, tempDiv);
            } catch (e) {
                console.error(e)
            }


            const contentPromises = [];

            for (let node of tempDiv.childNodes) {
                const imgs = node.querySelectorAll('img');
                const videos = node.querySelectorAll('video');

                videos.forEach(video => {
                    const videoPromise = new Promise((resolve, reject) => {
                        const canvas = document.createElement('canvas');
                        const ctx = canvas.getContext('2d');
                        video.crossOrigin = 'anonymous';

                        video.addEventListener('loadeddata', () => {
                            if (video.readyState >= 3) {
                                if (!video.src && video.querySelector('source')) {
                                    video.src = video.querySelector('source').src
                                }
                                video.currentTime = 0.1;
                                video.addEventListener('seeked', () => {
                                    canvas.width = video.videoWidth;
                                    canvas.height = video.videoHeight;

                                    ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

                                    try {
                                        video.style.backgroundImage = `url(${canvas.toDataURL()})`;
                                    } catch (error) {
                                        try {
                                            video.crossOrigin = "anonymous";
                                            video.style.backgroundImage = `url(${canvas.toDataURL()})`;
                                        } catch (error) {
                                            console.error(error)
                                        }
                                    }
                                    video.style.backgroundSize = 'cover';
                                    ctx.clearRect(0, 0, canvas.width, canvas.height);

                                    resolve();
                                })
                            }
                        }, { once: true });
                    })
                    contentPromises.push(videoPromise);
                });

                imgs.forEach(img => {
                    if (img.complete) {
                        processImage(img);
                    } else {
                        const imgPromise = new Promise((resolve, reject) => {
                            img.onload = () => {
                                processImage(img);
                                resolve();
                            };
                            img.onerror = () => {
                                console.error('Error loading image', img.src);
                                reject(new Error('Error loading image'));
                            };
                        });
                        contentPromises.push(imgPromise);
                    }
                });
            }

            return await Promise.all(contentPromises).then(async () => {
                const canvas = await html2canvas(tempDiv, { useCORS: true, logging: false });
                if (tempDiv) {
                    document.body.removeChild(tempDiv);
                }
                return canvas.toDataURL('image/png');
            });
        },
        getPreviewHtml: (cell) => {
            return (
                <div
                    className="chart-template-img w-full h-full flex justify-center relative"
                    //style={cell && cell.settings && cell.settings.background_color ? { backgroundColor: "#" + cell.settings.background_color } : { backgroundColor: "#FFFFFF" }}
                    style={{
                        ...(cell && cell.settings && cell.settings.background_color && !cell.settings.transparent && {backgroundColor: "#" + cell.settings.background_color}),
                        ...(cell && cell.settings && !cell.settings.background_color && !cell.settings.transparent && {backgroundColor: "#FFFFFF"}),
                    }}
                >
                    {/* FAKE PREVIEWS */}
                    {
                        cell.data && cell.data.fake &&
                        !cell.loading &&
                        <div
                            className={"flex-row px-8 w-full justify-start overflow-x-auto flex items-center table-overflow"}>
                            {
                                [{}, {}, {}, {}, {}, {}].map((ad) => {
                                    return (
                                        <div className="flex items-center justify-center">
                                            <div className="scale-75 transform w-full flex items-center justify-center">
                                                <div
                                                    className="border rounded-md bg-custom-input flex items-center justify-center h-56 w-80">
                                                    <div className="text-xs">Preview placeholder</div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    }

                    {/* FORMATS */}
                    {
                        !(cell.data && cell.data.fake) &&
                        !cell.loading &&
                        cell && cell.data &&
                        <div
                            className={((cell.data.multiple && Array.isArray(cell.data.ads) || (cell.preview_placement && cell.preview_placement.value == "no_frame")) ? "" : "scale-75") + " transform w-full h-full flex justify-center"}>

                            {/* TIKTOK VIDEO */}
                            {
                                cell.data.channel === "tiktok" &&
                                !(cell.preview_placement && cell.preview_placement.value == "no_frame") &&
                                !(cell.data.multiple && Array.isArray(cell.data.ads)) &&
                                <PreviewTikTokFeed
                                    video={cell.data.url || null}
                                    cta={tiktok_cta.find((cta => cta.id.includes(cell.data.report_ad_data.call_to_action)))}
                                    page={(cell.data.report_ad_data && cell.data.report_ad_data.page) || null}
                                    text={(cell.data.report_ad_data && cell.data.report_ad_data.text) || null}
                                    thumbnail={null}
                                />
                            }

                            {/* TIKTOK IMAGE NO FRAME */}
                            {
                                cell.data.channel === "tiktok" &&
                                cell.data.creative_type === "image" &&
                                (cell.preview_placement && cell.preview_placement.value == "no_frame") &&
                                !(cell.data.multiple && Array.isArray(cell.data.ads)) &&
                                <img
                                    className="h-full object-contain"
                                    src={cell.data.url}
                                    alt={"Creative media"}
                                />
                            }

                            {/* TIKTOK VIDEO NO FRAME */}
                            {
                                cell.data.channel === "tiktok" &&
                                cell.data.creative_type === "video" &&
                                (cell.preview_placement && cell.preview_placement.value == "no_frame") &&
                                !(cell.data.multiple && Array.isArray(cell.data.ads)) &&
                                <video className="w-full h-full" controls={true} loop={true} autoPlay={false}
                                       muted={true}>
                                    <source src={cell.data.url} type="video/mp4"/>
                                </video>
                            }

                            {
                                cell.data.channel === "tiktok" &&
                                cell.data.multiple && Array.isArray(cell.data.ads) &&
                                <div
                                    className={"flex-row px-8 w-full justify-start overflow-x-auto flex items-center table-overflow"}>
                                    {
                                        cell.data.ads.map((ad) => {
                                            return (
                                                <div className="flex items-center justify-center">
                                                    <div
                                                        className="scale-75 transform w-full flex items-center justify-center">
                                                        <div className="w-full">
                                                            {
                                                                !(cell.preview_placement && cell.preview_placement.value == "no_frame") &&
                                                                ad.report_ad_data &&
                                                                <PreviewTikTokFeed
                                                                    video={ad.url || null}
                                                                    cta={ad.report_ad_data && ad.report_ad_data.call_to_action ? tiktok_cta.find((cta => cta.id.includes(ad.report_ad_data.call_to_action))) : {}}
                                                                    page={(ad.report_ad_data && ad.report_ad_data.page) || null}
                                                                    text={(ad.report_ad_data && ad.report_ad_data.text) || null}
                                                                    thumbnail={null}
                                                                />
                                                            }
                                                            {
                                                                (cell.preview_placement && cell.preview_placement.value == "no_frame") &&
                                                                (!ad.data || (ad.data && ad.data.creative_type === "video")) &&
                                                                <img
                                                                    className="h-full object-contain"
                                                                    src={ad.url}
                                                                    alt={"Creative media"}
                                                                />
                                                            }
                                                            {
                                                                (cell.preview_placement && cell.preview_placement.value == "no_frame") &&
                                                                ad.data && ad.data.creative_type === "video" &&
                                                                <video className="w-full h-full" controls={false}
                                                                       loop={true} autoPlay={true} muted={true}>
                                                                    <source src={ad.url} type="video/mp4"/>
                                                                </video>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            }

                            {/* FACEBOOK */}
                            {
                                cell.data.channel === "facebook" &&
                                !(cell.data.multiple && Array.isArray(cell.data.ads)) &&
                                <PreviewSectionFacebook
                                    ad={cell.data.report_ad_data ? cell.data.report_ad_data : {}}
                                    channel={{value: cell.data.channel}}
                                    for_report={true}
                                    is_post={cell.data.report_ad_data && cell.data.report_ad_data.is_post}
                                    preview_placement={cell && cell.preview_placement ? cell.preview_placement.value : "feed"}
                                />
                            }
                            {
                                cell.data.channel === "facebook" &&
                                cell.data.multiple && Array.isArray(cell.data.ads) &&
                                <div
                                    className={"flex-row px-8 w-full justify-start overflow-x-auto flex items-center table-overflow"}>
                                    {
                                        cell.data.ads.map((ad) => {
                                            return (
                                                <div className="flex items-center justify-center">
                                                    <div
                                                        className="scale-75 transform w-full flex items-center justify-center">
                                                        <div className="w-full">
                                                            <PreviewSectionFacebook
                                                                ad={ad.report_ad_data ? ad.report_ad_data : {}}
                                                                channel={{value: ad.channel}}
                                                                for_report={true}
                                                                is_post={ad.report_ad_data && ad.report_ad_data.is_post}
                                                                preview_placement={cell && cell.preview_placement ? cell.preview_placement.value : "feed"}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            }

                            {/* TWITTER  */}
                            {
                                cell.data.channel === "twitter" &&
                                <PreviewSectionTwitter
                                    ad={cell.data.report_ad_data}
                                    channel={{value: cell.data.channel}}
                                    for_report={true}
                                    preview_placement={cell && cell.preview_placement ? cell.preview_placement.value : "feed"}
                                />
                            }

                            {/* SNAPCHAT */}
                            {
                                cell.data.channel === "snapchat" &&
                                !(cell.data.multiple && Array.isArray(cell.data.ads)) &&
                                <PreviewSectionSnapchat
                                    ad={cell.data.report_ad_data}
                                    channel={{value: cell.data.channel}}
                                    for_report={true}
                                    preview_placement={cell && cell.settings.preview_placement ? cell.settings.preview_placement.value : "story"}
                                />
                            }
                            {
                                cell.data.channel === "snapchat" &&
                                cell.data.multiple && Array.isArray(cell.data.ads) &&
                                <div
                                    className={"flex-row px-8 w-full justify-start overflow-x-auto flex items-center table-overflow"}>
                                    {
                                        cell.data.ads.filter((item) => {
                                            return item.report_ad_data
                                        }).map((ad) => {
                                            return (
                                                <div className="flex items-center justify-center">
                                                    <div
                                                        className="scale-75 transform w-full flex items-center justify-center">
                                                        <div className="w-full">
                                                            <PreviewSectionSnapchat
                                                                ad={ad.report_ad_data}
                                                                channel={{value: ad.channel}}
                                                                for_report={true}
                                                                preview_placement={cell && cell.settings.preview_placement ? cell.settings.preview_placement.value : "story"}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            }

                            {/* LINKEDIN */}
                            {
                                cell.data.channel === "linkedin" &&
                                !(cell.data.multiple && Array.isArray(cell.data.ads)) &&
                                <PreviewSectionLinkedin
                                    ad={cell.data.report_ad_data}
                                    channel={{value: cell.data.channel}}
                                    for_report={true}
                                    preview_placement={cell && cell.preview_placement ? cell.preview_placement.value : "feed"}
                                />
                            }
                            {
                                cell.data.channel === "linkedin" &&
                                cell.data.multiple && Array.isArray(cell.data.ads) &&
                                <div
                                    className={"flex-row px-8 w-full justify-start overflow-x-auto flex items-center table-overflow"}>
                                    {
                                        cell.data.ads.map((ad) => {
                                            return (
                                                <div className="flex items-center justify-center">
                                                    <div
                                                        className="scale-75 transform w-full flex items-center justify-center">
                                                        <div className="w-full">
                                                            <PreviewSectionLinkedin
                                                                ad={ad.report_ad_data ? ad.report_ad_data : {}}
                                                                channel={{value: ad.channel}}
                                                                for_report={true}
                                                                preview_placement={cell && cell.preview_placement ? cell.preview_placement.value : "feed"}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            }

                            {
                                <>
                                    {(cell.data.channel === "google" || cell.data.channel === "bing") &&
                                        !(cell.data.multiple && Array.isArray(cell.data.ads)) &&
                                        <PreviewSectionGoogle
                                            ad={cell.data.report_ad_data}
                                            subType={cell.data.sub_type}
                                            channel={{value: "google"}}
                                            for_report={true}
                                            preview_placement={cell && cell.preview_placement ? cell.preview_placement.value : "square"}
                                        />}
                                </>
                            }

                            {
                                (cell.data.channel === "google" || cell.data.channel === "bing") &&
                                cell.data.multiple && Array.isArray(cell.data.ads) &&
                                <div
                                    className={(cell.h >= 5 ? "flex-col py-8" : "flex-row px-8") + " w-full justify-start overflow-x-auto flex items-center table-overflow"}>
                                    {
                                        cell.data.ads.map((ad) => {
                                            if (ad.type == "display" && Array.isArray(ad.square_images) && ad.square_images.length > 0) {
                                                console.log("ad1", ad)
                                                return (
                                                    <div className={cell.h >= 5 ? "mb-4" : "mr-4"}>
                                                        <PreviewGoogleDisplaySquare
                                                            image={ad.square_images && ad.square_images.length > 0 ? ad.square_images[0].image : ""}
                                                            subType={ad.sub_type}
                                                            logo={ad.square_logo_images && ad.square_logo_images.length > 0 ? ad.square_logo_images[0].image : ""}
                                                            headlines={Array.isArray(ad.headlines) && ad.headlines.length > 0 ? ad.headlines : []}
                                                            bodies={Array.isArray(ad.descriptions) && ad.descriptions.length > 0 ? ad.descriptions : []}
                                                            main_color={ad.main_color ? ad.main_color : ""}
                                                            accent_color={ad.accent_color ? ad.accent_color : ""}
                                                        />
                                                    </div>
                                                )
                                            } else if (ad.type == "display" && Array.isArray(ad.vertical_images) && ad.vertical_images.length > 0) {
                                                console.log("ad2", ad)

                                                return (
                                                    <div className={cell.h >= 5 ? "mb-4" : "mr-4"}>
                                                        <PreviewGoogleDisplaySkyscraper
                                                            image={ad.vertical_images && ad.vertical_images.length > 0 ? ad.vertical_images[0].image : ""}
                                                            subType={ad.sub_type}
                                                            logo={ad.square_logo_images && ad.square_logo_images.length > 0 ? ad.square_logo_images[0].image : ""}
                                                            headlines={Array.isArray(ad.headlines) && ad.headlines.length > 0 ? ad.headlines : []}
                                                            bodies={Array.isArray(ad.descriptions) && ad.descriptions.length > 0 ? ad.descriptions : []}
                                                            main_color={ad.main_color ? ad.main_color : ""}
                                                            accent_color={ad.accent_color ? ad.accent_color : ""}
                                                        />
                                                    </div>
                                                )
                                            } else if (ad.type == "display" && Array.isArray(ad.horizontal_images) && ad.horizontal_images.length > 0) {
                                                console.log("ad3", ad)

                                                return (
                                                    <div className={cell.h >= 5 ? "mb-4" : "mr-4"}>
                                                        <PreviewGoogleDisplayBillboard
                                                            image={ad.horizontal_images && ad.horizontal_images.length > 0 ? ad.horizontal_images[0].image : ""}
                                                            subType={ad.sub_type}
                                                            logo={ad.square_logo_images && ad.square_logo_images.length > 0 ? ad.square_logo_images[0].image : ""}
                                                            headlines={Array.isArray(ad.headlines) && ad.headlines.length > 0 ? ad.headlines : []}
                                                            bodies={Array.isArray(ad.descriptions) && ad.descriptions.length > 0 ? ad.descriptions : []}
                                                            main_color={ad.main_color ? ad.main_color : ""}
                                                            accent_color={ad.accent_color ? ad.accent_color : ""}
                                                        />
                                                    </div>
                                                )
                                            } else {
                                                return (
                                                    <div className="flex items-center justify-center"
                                                         style={{maxWidth: "500px"}}>
                                                        <div
                                                            className="scale-75 transform w-full flex items-center justify-center">
                                                            <div style={{minWidth: "600px"}} className="w-full">
                                                                <PreviewGoogleSearch
                                                                    headlineFirst={Array.isArray(ad.headlines) && ad.headlines.length > 0 ? (typeof ad.headlines[0] == "string" ? ad.headlines[0] : ad.headlines[0].text) : false}
                                                                    headlineSecond={Array.isArray(ad.headlines) && ad.headlines.length > 1 ? (typeof ad.headlines[1] == "string" ? ad.headlines[1] : ad.headlines[1].text) : false}
                                                                    headlineThird={Array.isArray(ad.headlines) && ad.headlines.length > 2 ? (typeof ad.headlines[2] == "string" ? ad.headlines[2] : ad.headlines[2].text) : false}
                                                                    bodyOne={Array.isArray(ad.descriptions) && ad.descriptions.length > 0 ? (typeof ad.descriptions[0] == "string" ? ad.descriptions[0] : ad.descriptions[0].text) : false}
                                                                    bodySecond={Array.isArray(ad.descriptions) && ad.descriptions.length > 1 ? (typeof ad.descriptions[1] == "string" ? ad.descriptions[1] : ad.descriptions[1].text) : false}
                                                                    link={Array.isArray(ad.final_urls) && ad.final_urls.length > 0 ? ad.final_urls[0] : false}
                                                                    path1={ad.path1}
                                                                    path2={ad.path2}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        })
                                    }
                                </div>
                            }

                        </div>
                    }

                    {/* NO FORMAT */}
                    {
                        !(cell.data && cell.data.fake) &&
                        !cell.loading &&
                        cell.data &&
                        cell.data.type === "" &&
                        <div className="w-full h-full flex flex-col justify-center items-center">
                            <span className="text-red-500 text-sm cursor-pointer font-bold">Format not supported</span>
                        </div>
                    }
                </div>
            )
        }
    };

    calls = {
        convertURLToInternal: (url) => {
            return new Promise(async (resolve, reject) => {
                try {
                    let options = apiRegister.options(tokenRegister.get(), 'POST', {url: url, video: true});
                    let response = await apiRegister.call(options, apiRegister.url.api + "/v3/adcredo/convertURLToInternal");
                    if (response && response.data) {
                        resolve(response.data);
                    } else {
                        resolve(null);
                    }
                } catch (error) {
                    resolve(null);
                }
            })
        }
    };

    helpers = {
        getCoordinates(cell) {
            let x = "";
            let y = "";
            let w = "";
            let h = "";
            if ('x_desktop' in cell) {
                x = cell.x_desktop;
            } else {
                x = cell.x;
            }
            if ('y_desktop' in cell) {
                y = cell.y_desktop;
            } else {
                y = cell.y;
            }
            if ('h_desktop' in cell) {
                h = cell.h_desktop;
            } else {
                h = cell.h;
            }
            if ('w_desktop' in cell) {
                w = cell.w_desktop;
            } else {
                w = cell.w;
            }

            return {x: x, y: y, w: w, h: h};
        },
        addShapes(pptx, cell, slide, powerPointSettings, background) {
            let {x, y, w, h} = this.getCoordinates(cell);
            const {blockWidth, blockHeight} = powerPointSettings;

            if (cell.settings && cell.settings.disable_margin_top) {
                slide.addShape(pptx.shapes.ROUNDED_RECTANGLE, {
                    x: x * blockWidth + 0.1,
                    y: y * blockHeight,
                    w: w * blockWidth - 0.2,
                    h: 0.2,
                    rectRadius: 0.0000000000000001,
                    ...(background && {fill: {color: background}})
                });
            }

            if (cell.settings && cell.settings.disable_margin_bottom) {
                slide.addShape(pptx.shapes.ROUNDED_RECTANGLE, {
                    x: x * blockWidth + 0.1,
                    y: (y * blockHeight) + (h * blockHeight - 0.2),
                    w: w * blockWidth - 0.2,
                    h: 0.2,
                    rectRadius: 0.0000000000000001,
                    ...(background && {fill: {color: background}})
                });
            }

            if (cell.settings && cell.settings.disable_margin_left) {
                slide.addShape(pptx.shapes.ROUNDED_RECTANGLE, {
                    x: x * blockWidth,
                    y: y * blockHeight + 0.1,
                    w: 0.2,
                    h: h * blockHeight - 0.2,
                    rectRadius: 0.0000000000000001,
                    ...(background && {fill: {color: background}})
                });
            }

            if (cell.settings && cell.settings.disable_margin_right) {
                slide.addShape(pptx.shapes.ROUNDED_RECTANGLE, {
                    x: (x * blockWidth) + (w * blockWidth - 0.2),
                    y: y * blockHeight + 0.1,
                    w: 0.2,
                    h: h * blockHeight - 0.2,
                    rectRadius: 0.0000000000000001,
                    ...(background && {fill: {color: background}})
                });
            }

            if (cell.settings && cell.settings.disable_margin_top && cell.settings.disable_margin_left) {
                slide.addShape(pptx.shapes.ROUNDED_RECTANGLE, {
                    x: x * blockWidth,
                    y: y * blockHeight,
                    w: 0.2,
                    h: 0.2,
                    rectRadius: 0.0000000000000001,
                    ...(background && {fill: {color: background}})
                });
            }

            if (cell.settings && cell.settings.disable_margin_bottom && cell.settings.disable_margin_left) {
                slide.addShape(pptx.shapes.ROUNDED_RECTANGLE, {
                    x: x * blockWidth,
                    y: (y * blockHeight) + (h * blockHeight - 0.2),
                    w: 0.2,
                    h: 0.2,
                    rectRadius: 0.0000000000000001,
                    ...(background && {fill: {color: background}})
                });
            }

            if (cell.settings && cell.settings.disable_margin_top && cell.settings.disable_margin_right) {
                slide.addShape(pptx.shapes.ROUNDED_RECTANGLE, {
                    x: x * blockWidth + (w * blockWidth - 0.2),
                    y: y * blockHeight,
                    w: 0.2,
                    h: 0.2,
                    rectRadius: 0.0000000000000001,
                    ...(background && {fill: {color: background}})
                });
            }

            if (cell.settings && cell.settings.disable_margin_bottom && cell.settings.disable_margin_right) {
                slide.addShape(pptx.shapes.ROUNDED_RECTANGLE, {
                    x: x * blockWidth + (w * blockWidth - 0.2),
                    y: (y * blockHeight) + (h * blockHeight - 0.2),
                    w: 0.2,
                    h: 0.2,
                    rectRadius: 0.0000000000000001,
                    ...(background && {fill: {color: background}})
                });
            }
        }
    }


    render() {
        return <></>;
    }
}
