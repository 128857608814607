import React, { Component, Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { apiRegister } from "../services/apiRegister";
import { tokenRegister } from '../services/tokenRegister';
import cn from "classnames";
import { CalendarIcon, CheckIcon, ChevronDownIcon, ChevronUpIcon, ClockIcon, DownloadIcon, ExternalLinkIcon, FolderIcon, PencilAltIcon, LightBulbIcon, NewspaperIcon, SearchIcon, ThumbDownIcon, TrashIcon, ViewGridIcon, PlusCircleIcon, AdjustmentsIcon, BanIcon, ArrowDownIcon, CogIcon, ExclamationIcon, DuplicateIcon, PresentationChartBarIcon } from '@heroicons/react/outline';
import TopNavigationAgent from '../moduleFiles/topNavigationAgent';
import WarningModalTailwind from '../moduleFiles/warningModalTailwind';
import WizardModal from '../moduleFiles/wizardModal';
import SlideoutTailwind from '../moduleFiles/slideoutTailwind';
import CustomizeOrderMetrics from '../moduleFiles/customizeOrderMetrics';
import CustomizeOrderDates from '../moduleFiles/customizeOrderDates';
import NewCampaignWizard from '../moduleFiles/newCampaignWizard';
import EditLiveFacebookCampaign from '../moduleFiles/editLiveFacebookCampaign';
import EditLiveFacebookAdgroup from '../moduleFiles/editLiveFacebookAdgroup';
import EditLiveFacebookAd from '../moduleFiles/editLiveFacebookAd';
import EditLiveTikTokCampaign from '../moduleFiles/editLiveTikTokCampaign';
import EditLiveTikTokAdgroup from '../moduleFiles/editLiveTikTokAdgroup';
import EditLiveTikTokAd from '../moduleFiles/editLiveTikTokAd';
import EditLiveGoogleCampaign from '../moduleFiles/editLiveGoogleCampaign';
import EditLiveGoogleAdgroup from '../moduleFiles/editLiveGoogleAdgroup';
import EditLiveGoogleSearchAd from '../moduleFiles/editLiveGoogleSearchAd';
import EditLiveLinkedinCampaign from '../moduleFiles/editLiveLinkedinCampaign';
import EditLiveGoogleAssets from '../moduleFiles/editLiveGoogleAssets';
import EditLiveLinkedinAdgroup from '../moduleFiles/editLiveLinkedinAdgroup';
import EditLiveLinkedinAd from '../moduleFiles/editLiveLinkedinAd';
import CampaignScheduler from '../moduleFiles/campaignScheduler';
import AdgroupScheduler from '../moduleFiles/adgroupScheduler';
import KeywordModal from '../moduleFiles/keywordModal';
import SuccessModal from '../moduleFiles/successModal';
import EditLiveSnapchatCampaign from '../moduleFiles/editLiveSnapchatCampaign';
import EditLiveSnapchatAdgroup from '../moduleFiles/editLiveSnapchatAdgroup';
import EditLiveSnapchatAd from '../moduleFiles/editLiveSnapchatAd';
import SyncWithBing from '../moduleFiles/syncWithBing';
import SweetAlert from 'sweetalert-react'
import 'sweetalert/dist/sweetalert.css';
import moment from 'moment';
import CampaignCrmSettings from '../moduleFiles/campaignCrmSettings';
import TableHeadTailwind from '../moduleFiles/tableHeadTailwind';
import NegativeKeywordList from '../moduleFiles/negativeKeywordList';
import { set } from 'immutable';
import { userRegister } from "../services/userRegister";
import CampaignTags from '../moduleFiles/campaignTags';
import OrderCharts from '../pageFIles/orderCharts';
import ToolTip from '../moduleFiles/toolTip';
import SlideoutTailwindAsset from '../moduleFiles/slideoutTailwindAsset';
import { createChangelog } from '../services/createChangelog';
import DropdownTailwind from '../moduleFiles/dropdownTailwind';
import { single } from 'fuzzysort';

class OrderOptimizeNew extends Component {

    constructor(props) {
        super(props);
        this.state = {
            wizzard: "",
            edit_loader: false,
            loading: true,
            order: {},
            client: {},
            campaigns: [],
            campaign: { channel: 'all' },
            adgroups: [],
            adgroup: {},
            ad: {},
            group: false,
            ads: [],
            keywords: [],
            assets: [],
            negative_keywords: [],
            search_terms: [],
            channels: [],
            channel: { id: "all", name: "All channels", value: "all" },
            syncToBing: false,
            reload_charts: false,
            importAlertShow: false,
            tab_search: {
                adgroups: "",
                ads: "",
            },
            tabs: {
                "all": [
                    { id: 1, name: "Campaigns", value: "campaigns", selected: 'campaign' }
                ],
                'adform': [
                    { id: 1, name: "Campaigns", value: "campaigns", selected: 'campaign' },
                    { id: 2, name: "Adgroups", value: "adgroups", selected: 'adgroup', previous: 'campaign' },
                    { id: 3, name: "Ads", value: "ads", previous: 'adgroup' }
                ],
                'facebook': [
                    { id: 1, name: "Campaigns", value: "campaigns", selected: 'campaign' },
                    { id: 2, name: "Adgroups", value: "adgroups", selected: 'adgroup', previous: 'campaign' },
                    { id: 3, name: "Ads", value: "ads", previous: 'adgroup' }
                ],
                'tiktok': [
                    { id: 1, name: "Campaigns", value: "campaigns", selected: 'campaign' },
                    { id: 2, name: "Adgroups", value: "adgroups", selected: 'adgroup' },
                    { id: 3, name: "Ads", value: "ads" }
                ],
                'linkedin': [
                    { id: 1, name: "Campaigns", value: "campaigns", selected: 'campaign' },
                    { id: 2, name: "Adgroups", value: "adgroups", selected: 'adgroup' },
                    { id: 3, name: "Ads", value: "ads" }
                ],
                'snapchat': [
                    { id: 1, name: "Campaigns", value: "campaigns", selected: 'campaign' },
                    { id: 2, name: "Adgroups", value: "adgroups", selected: 'adgroup' },
                    { id: 3, name: "Ads", value: "ads" }
                ],
                'twitter': [
                    { id: 1, name: "Campaigns", value: "campaigns", selected: 'campaign' },
                    { id: 2, name: "Adgroups", value: "adgroups", selected: 'adgroup' },
                    { id: 3, name: "Ads", value: "ads" }
                ],
                'google': [
                    { id: 1, name: "Campaigns", value: "campaigns", selected: 'campaign' },
                    { id: 2, name: "Adgroups", value: "adgroups", selected: 'adgroup' },
                    { id: 3, name: "Ads", value: "ads" },
                    { id: 4, name: "Keywords", value: "keywords" },
                    { id: 6, name: "Negative keywords", value: "negative_keywords" },
                    { id: 5, name: "Search terms", value: "search_terms" },
                    { id: 9, name: "Assets", value: "assets" }
                ],
                'bing': [
                    { id: 1, name: "Campaigns", value: "campaigns", selected: 'campaign' },
                    { id: 2, name: "Adgroups", value: "adgroups", selected: 'adgroup' },
                    { id: 3, name: "Ads", value: "ads" },
                    { id: 4, name: "Keywords", value: "keywords" }
                ]
            },
            tab: { id: 1, name: "Campaigns", value: "campaigns", selected: 'campaign' },
            show: {},
            subtabs: {
                'adform': {
                    'adgroups': [
                        { id: 1, name: "Adgroups", value: "adgroups" }
                    ],
                    'ads': [
                        { id: 1, name: "Ads", value: "ads" }
                    ]
                },
                'facebook': {
                    'adgroups': [
                        { id: 1, name: "Adgroups", value: "adgroups" }
                    ],
                    'ads': [
                        { id: 1, name: "Ads", value: "ads" }
                    ]
                },
                'google': {
                    'adgroups': [
                        { id: 1, name: "Adgroups", value: "adgroups" }
                    ],
                    'ads': [
                        { id: 1, name: "Ads", value: "ads" }
                    ],
                    'search_terms': [
                        { id: 1, name: "Search terms", value: "search_terms" }
                    ],
                    'keywords': [
                        { id: 1, name: "Positive", value: "positive" },
                        { id: 2, name: "Negative", value: "negative" }
                    ]
                },
                'bing': {
                    'adgroups': [
                        { id: 1, name: "Adgroups", value: "adgroups" }
                    ],
                    'ads': [
                        { id: 1, name: "Ads", value: "ads" }
                    ],
                    'keywords': [
                        { id: 1, name: "Positive", value: "positive" },
                        { id: 2, name: "Negative", value: "negative" }
                    ]
                },
                'linkedin': {
                    'adgroups': [
                        { id: 1, name: "Adgroups", value: "adgroups" }
                    ],
                    'ads': [
                        { id: 1, name: "Ads", value: "ads" }
                    ]
                },
                'twitter': {
                    'adgroups': [
                        { id: 1, name: "Adgroups", value: "adgroups" }
                    ],
                    'ads': [
                        { id: 1, name: "Ads", value: "ads" }
                    ]
                },
                'tiktok': {
                    'adgroups': [
                        { id: 1, name: "Adgroups", value: "adgroups" }
                    ],
                    'ads': [
                        { id: 1, name: "Ads", value: "ads" }
                    ]
                },
                'snapchat': {
                    'adgroups': [
                        { id: 1, name: "Adgroups", value: "adgroups" }
                    ],
                    'ads': [
                        { id: 1, name: "Ads", value: "ads" }
                    ]
                }
            },
            subtab: {},
            date_span: { id: 3, name: "Last 7 days", value: "last_7" },
            columns: [
                { name: 'name', value: 'name', min_width: '250px' },
                { name: 'keyword', value: 'keyword', min_width: '250px', channel: 'google', tab: 'search_terms' },
                { name: 'type', value: 'keyword_type', min_width: '250px', channel: 'google', tab: 'keywords' },
                { name: 'campaign', value: 'campaign', min_width: '250px', channel: 'google', tab: 'keywords' },
                { name: 'adgroup', value: 'adgroup', min_width: '250px', channel: 'google', tab: 'negative_keywords' },
                { name: 'campaign', value: 'campaign', min_width: '250px', channel: 'google', tab: 'negative_keywords' },
                { name: 'type', value: 'keyword_type', min_width: '250px', channel: 'google', tab: 'negative_keywords' },
                { name: 'type', value: 'keyword_type', min_width: '250px', channel: 'google', tab: 'search_terms' },
                { name: 'type', value: 'asset_type', min_width: '250px', channel: 'google', tab: 'assets' },
                { name: 'level', value: 'asset_level', min_width: '250px', channel: 'google', tab: 'assets' },
                { name: 'campaign', value: 'campaign', min_width: '250px', channel: 'google', tab: 'assets' },
                { name: 'adgroup', value: 'adgroup', min_width: '250px', channel: 'google', tab: 'assets' },
                { name: 'status', value: 'status', min_width: '250px' },
                { name: 'approval status', value: 'approval_status', min_width: '250px', channel: 'google', tab: 'keywords' },
                { name: 'channel', value: 'channel', min_width: '250px' },
                { name: 'start date', value: 'start', min_width: '250px' },
                { name: 'end date', value: 'end', min_width: '250px' },
                { name: 'Scheduler', value: 'scheduleActivated', min_width: '250px', scheduler: true }
            ],
            default_metrics: [
                { name: 'Spend', value: 'spend', min_width: '250px', metric: true },
                { name: 'Impressions', value: 'impressions', min_width: '250px', metric: true },
                { name: 'Clicks', value: 'clicks', min_width: '250px', metric: true },
                { name: 'CTR', value: 'ctr', min_width: '250px', metric: true },
                { name: 'CPC', value: 'cpc', min_width: '250px', metric: true },
                { name: 'CPM', value: 'cpm', min_width: '250px', metric: true },
                { name: 'Conversions', value: 'conversions', min_width: '250px', metric: true }
            ],
            metrics: [
                { name: 'Spend', value: 'spend', min_width: '250px', metric: true, reportName: "Spend (default)" },
                { name: 'Impressions', value: 'impressions', min_width: '250px', metric: true, reportName: "Impressions (default)" },
                { name: 'Clicks', value: 'clicks', min_width: '250px', metric: true, reportName: "Clicks (default)" },
                { name: 'CTR', value: 'ctr', min_width: '250px', metric: true, reportName: "Avg. CTR (default)" },
                { name: 'CPC', value: 'cpc', min_width: '250px', metric: true, reportName: "Avg. CPC (default)" },
                { name: 'CPM', value: 'cpm', min_width: '250px', metric: true, reportName: "Avg. CPM (default)" },
                { name: 'Conversions', value: 'conversions', min_width: '250px', metric: true, reportName: "Conversions (default)" }
            ],
            status: {},
            search_term: {},
            edit_negative: {},
            edit_positive: {},
            keyword: {},
            selected_adgroup: { id: 0, name: "Select adgroup" },
            selected_adgroups: [],
            selected_campaigns: [],
            selected_campaign: { id: 0, name: "Select campaign" },
            add_negative_level: { id: 2, name: "Adgroup", value: "adgroup" },
            create_wizard: false,
            create_keyword: false,
            edit_keyword: false,
            add_keyword: false,
            locked_adgroup: false,
            new_multi_input: false,
            customMatch: false,
            selected_adgroup_error: false,
            pagination: {
                search_term: "1",
                negative_pagination: "1",
                positive_pagination: "1",
                bing_pagination: "1",
                bing_search_term: "1",
                campaign_negative_pagination: "1",
            },
            //pagination: "1",
            //negative_pagination: "1",
            sortBy: 'name',
            orderBy: 'DESC',
            pageSize: 50,
            disabled: false,
            cutomize_open: false,
            cutomize_dates: false,
            custom_dates: {
                start: false,
                end: false
            },
            merged_columns: [],
            user: {},
            selected_channels: ["all"],
            advancedFilters: [
                {
                    showOnTop: false,
                    multiSelect: true,
                    maxWidth: true,
                    type: "status",
                    placeholder: "All Status (default)",
                    defaultOptions: [],
                    defaultSelected: { id: 1, name: "All status (default)", value: "all" },
                    options: [
                        { id: 1, name: "All status (default)", value: "all" },
                        { id: 5, name: "Enabled", value: "enabled" },
                        { id: 2, name: "Active", value: "active" },
                        { id: 3, name: "Paused", value: "paused" },
                        { id: 4, name: "Disable", value: "disable" },
                        { id: 6, name: "Syncing", value: "syncing" }

                    ],
                    selected: []
                },
                {
                    showOnTop: true,
                    multiSelect: false,
                    maxWidth: true,
                    type: "orders",
                    placeholder: "Orders",
                    search: true,
                    search_value: "",
                    skipInternalSearch: true,
                    total: 0,
                    page: 1,
                    loading: false,
                    defaultOptions: [],
                    defaultSelected: { id: 1, name: "All status (default)", value: "all" },
                    options: [],
                    selected: {}
                }
            ],
            charts_open: false,
            adgroupAdsFetchedKeys: null,
            client_change: false,
            order_search: "",
            order_page: 1,
            orders: [],
            negative_keywords_campaign: [],
            pause_campaign_schedule: { id: 1, name: "Remove schedule settings", value: "remove_schedule" }
        }
    }

    async componentDidMount() {
        if (this.props.setOrder) {
            this.props.setOrder({ name: "..." });
        }
        await this.functions.getUserOrderStatus();
        await this.functions.getCampaigns();
        this.props.updateStatistics();
        this.functions.user();
        await this.functions.query();
        await this.functions.getOrders(true, false, false);
        //this.calls.test();
        this.state.advancedFilters[1].defaultSelected = {
            id: this.state.order.id,
            name: this.state.order.name,
        }
        this.state.advancedFilters[1].selected = this.state.advancedFilters[1].defaultSelected;
        await this.promisedSetState({ advancedFilters: this.state.advancedFilters });
    }

    functions = {
        getOrders: async (init, search, pagination) => {
            this.state.advancedFilters[1].loading = true;
            await this.promisedSetState({ advancedFilters: this.state.advancedFilters });
            try {
                let response = await this.calls.getOrders();
                if (pagination) {
                    this.state.advancedFilters[1].options = this.state.advancedFilters[1].options.concat(response.data);
                } else if (!search || (search && search == this.state.order_search)) {
                    this.state.advancedFilters[1].options = response.data;
                }
                await this.promisedSetState({
                    advancedFilters: this.state.advancedFilters,
                });
                this.state.advancedFilters[1].total = response.meta.total;
            } catch (error) {
                console.log(error);
            }

            this.state.advancedFilters[1].loading = false;
            await this.promisedSetState({ advancedFilters: this.state.advancedFilters });
        },
        getUserOrderStatus: async () => {
            return new Promise(async (resolve) => {
                try {
                    let res = await this.calls.getUserOrderStatus()
                    if (this.state.tab.value in res.data) {
                        if (res.data[this.state.tab.value]) {
                            this.state.advancedFilters[0].selected = res.data[this.state.tab.value];
                        }
                        await this.promisedSetState({
                            advancedFilters: this.state.advancedFilters
                        })
                    }
                } catch (error) {
                    console.log(error)
                }
                resolve()
            })
        },
        query: async () => {
            return new Promise(async (resolve) => {
                let queries = window.location.search.replace("?", "").split("&");
                let ad = null;
                let adgroup = null;
                let campaign = null;
                let keyword = null;
                let channel = null;
                for (let i = 0; i < queries.length; i++) {
                    if (queries[i].indexOf("ad=") !== -1) {
                        ad = queries[i].replace("ad=", "");
                    } else if (queries[i].indexOf("adgroup=") !== -1) {
                        adgroup = queries[i].replace("adgroup=", "");
                    } else if (queries[i].indexOf("campaign=") !== -1) {
                        campaign = queries[i].replace("campaign=", "");
                    } else if (queries[i].indexOf("keyword=") !== -1) {
                        keyword = queries[i].replace("keyword=", "");
                    } else if (queries[i].indexOf("channel=") !== -1) {
                        channel = queries[i].replace("channel=", "");
                    }
                }
                if (campaign || adgroup || ad || keyword) {
                    await await this.promisedSetState({
                        loading: true
                    });
                }
                if (campaign) {
                    if (this.state.campaigns.some(item => item.campaignId == campaign)) {
                        this.state.campaigns.map((item) => {
                            if (item.campaignId == campaign) {
                                this.state.selected_campaigns.push(item);
                                this.state.selected_channels = [item.channel];
                                campaign = item;
                            }
                        })
                    } else {
                        campaign = false;
                    }

                }
                if (adgroup && campaign && !keyword) {
                    await this.functions.getAdgroups(campaign);
                    if (this.state.adgroups.filter((item) => { return item.id == adgroup }).length > 0) {
                        this.state.adgroups.map((item) => {
                            if (item.id == adgroup) {
                                // this.state.adgroup = item;
                                this.state.selected_adgroups.push(item);
                                //this.state.adgroup = item;
                                adgroup = item;
                                this.state.tab = { id: 2, name: 'Adgroups', value: 'adgroups', selected: 'adgroup', previous: 'campaign' };
                            }
                        })
                    } else {
                        adgroup = false;
                    }
                }
                if (ad && adgroup) {
                    await this.functions.getAds(adgroup);
                    this.state.tab = { id: 3, name: 'Ads', value: 'ads' };
                }

                if (keyword && channel && channel === "google") {
                    let response = await this.calls.getKeywords("google", "&positive=true", null, "&campaign_ids[]=" + campaign.id + "&keyword=" + adgroup + "~" + keyword);
                    response.data = response.data.map((item) => {
                        if (!item.list) {
                            return {
                                channel: 'google',
                                adgroup: item.adGroup.name,
                                adgroup_id: item.adGroup.id,
                                campaign: item.campaign.name,
                                id: item.adGroupCriterion.resourceName,
                                resourceName: item.adGroupCriterion.resourceName,
                                name: item.adGroupCriterion.keyword.text,
                                status: item.adGroupCriterion.status ? item.adGroupCriterion.status.toLowerCase() : '-',
                                approval_status: item.adGroupCriterion.approvalStatus ? item.adGroupCriterion.approvalStatus.toLowerCase() : '-',
                                keyword_type: item.adGroupCriterion.keyword.matchType,
                                impressions: item.metrics.impressions,
                                spend: parseInt(item.metrics.costMicros) / 1000000,
                                ctr: (item.metrics.ctr).toFixed(2),
                                clicks: item.metrics.clicks,
                                conversions: item.metrics.conversions,
                                ...(item.metrics.averageCpc) && { cpc: (parseInt(item.metrics.averageCpc) / 1000000).toFixed(2) },
                                ...(item.metrics.averageCpm) && { cpm: (parseInt(item.metrics.averageCpm) / 1000000).toFixed(2) },
                                metrics: item.metrics,
                            };
                        } else {
                            return {
                                channel: 'google',
                                list: true,
                                keywords: item.keywords,
                                id: item.resourceName,
                                resourceName: item.resourceName,
                                name: item.name,
                                status: item.status.toLowerCase(),
                                approval_status: '-',
                                keyword_type: "LIST",
                                impressions: "-",
                                spend: "-",
                                ctr: "-",
                                clicks: "-",
                                conversions: "-",
                                metrics: {},
                            };
                        }
                    });
                    this.state.pagination.positive_pagination = false;
                    this.state.keywords = this.state.keywords.concat(response.data)
                    this.state.tab = { id: 4, name: "Keywords", value: "keywords" };
                }

                await this.promisedSetState({
                    selected_campaigns: this.state.selected_campaigns,
                    selected_adgroups: this.state.selected_adgroups,
                    selected_channels: this.state.selected_channels,
                    keywords: this.state.keywords,
                    pagination: this.state.pagination,
                    // adgroup: this.state.adgroup,
                    campaign: this.state.campaign,
                    adgroup: this.state.adgroup,
                    tab: this.state.tab,
                    loading: false
                });

                resolve();
            })
        },
        saveUserOrderStatus: async () => {
            try {
                let data = { status: this.state.advancedFilters[0].selected, view_name: this.state.tab.value }
                let res = await this.calls.saveUserOrderStatus(data)
                if (res.data[this.state.tab.value].length > 0) {
                    this.state.advancedFilters[0].selected = res.data[this.state.tab.value];
                    await this.promisedSetState({
                        advancedFilters: this.state.advancedFilters
                    })
                }
            } catch (error) {
                console.log(error)
            }

        },
        user: async () => {
            await this.promisedSetState({
                user: userRegister.get()
            });
        },
        createPartialWizard: async (item) => {
            await this.promisedSetState({ loading_wizard: true });
            await this.functions.getAdgroups();
            try {

                let response = await this.calls.createWizard({
                    order: item.order,
                    name: item.name,
                    partial: true,
                    partial_data: {
                        campaigns: this.state.selected_campaigns,
                        adgroups: this.state.adgroups
                    },

                });
                this.props.history.push("/v2/campaigns/new/channels?draft=" + response.data.id);

            } catch (error) {
                console.log(error);
            }
            await this.promisedSetState({ loading_wizard: false });
        },
        createPartialWizard_V2: async () => {
            await this.promisedSetState({ loading_wizard: true });
            let adgroups = [];
            if (this.state.selected_campaigns.filter((item) => { return item.channel === "google" && !item.campaign_data }).length > 0) {
                await this.promisedSetState({ loading_partial: true });
                let buffer = this.state.selected_campaigns.filter((item) => { return item.channel === "google" && !item.campaign_data })
                for (let i = 0; i < buffer.length; i++) {
                    await this.functions.getCampaignsData({ channel: buffer[i].channel, id: buffer[i].campaignId });
                    try {
                        this.state.selected_campaigns = this.state.selected_campaigns.map((inner_item) => {
                            if (inner_item.campaignId === buffer[i].campaignId) {
                                inner_item = this.state.campaigns.filter((campaign) => { return campaign.campaignId === inner_item.campaignId })[0];
                            }
                            return inner_item;
                        })
                    } catch (error) {
                    }
                }
                await this.promisedSetState({ loading_partial: false, selected_campaigns: this.state.selected_campaigns });
            }
            await this.functions.getAdgroups();
            adgroups = this.state.adgroups;
            try {

                let response = await this.calls.createWizard({
                    order: this.state.selected_campaigns[0].order,
                    name: this.state.selected_campaigns.length === 1 ? this.state.selected_campaigns[0].name : "Multi channel edit",
                    partial: true,
                    partial_data: {
                        campaigns: this.state.selected_campaigns,
                        adgroups: adgroups,
                        channels: {
                            ...(this.state.selected_campaigns.filter((item) => { return item.channel === "facebook" }).length > 0) && { facebook: true },
                            ...(this.state.selected_campaigns.filter((item) => { return item.channel === "linkedin" }).length > 0) && { linkedin: true },
                            ...(this.state.selected_campaigns.filter((item) => { return item.channel === "snapchat" }).length > 0) && { snapchat: true },
                            ...(this.state.selected_campaigns.filter((item) => { return item.channel === "tiktok" }).length > 0) && { tiktok: true },
                        }
                    },

                });
                this.props.history.push("/v2/campaigns/new/channels?draft=" + response.data.id);

            } catch (error) {
                console.log(error);
            }
            await this.promisedSetState({ loading_wizard: false });
        },
        duplicateCampaign: async (campaignData, channel) => {
            /* DONT REMOVE THIS COMMENT PART FOR FUTURE USE
                        //await this.promisedSetState({ loading_wizard: true });
                        let adgroupsData = [];
                        //for (let i = 0; i < this.state.selected_campaigns.length; i++) {
                        await this.functions.getAdgroups(campaignData);
                        adgroupsData = adgroupsData.concat(this.state.adgroups);
                        //}
            
                        console.log(campaignData, "----CAMPAIGN===");
                        console.log(adgroupsData, "----Adgroups===");
                        if (channel === "google") {
                        } else if (channel === "facebook") {
                            let campaign = {
                                id: Math.floor((Math.random() * 99999999999999) + 1),
                                name: campaignData.name,
                                objective: campaignData.objective,
                                level: "campaign",
                                channel: "facebook",
                                budget_optimization: campaignData.budget_optimization,
                                budget_optimization_type: campaignData.lifetime_budget ? { id: 2, name: "Total budget", value: 'total' } : { id: 1, name: "Daily budget", value: 'daily' },
                                special_ad_category: { id: 1, name: 'None', value: "NONE" },
                                daily_budget: campaignData.daily_budget ? campaignData.daily_budget : 0,
                                lifetime_budget: campaignData.lifetime_budget ? campaignData.lifetime_budget : 0,
                                ios: { id: 0, name: "No", value: false },
                                app_promotion_type: campaignData.app_promotion_type ? campaignData.app_promotion_type : { id: 2, name: 'App Ads', value: "GUIDED_CREATION" }
                            }
                            let adgroups = [];
                            adgroupsData.map((item) => {
                                let adgroup_object = {
                                    id: Math.floor((Math.random() * 99999999999999) + 1),
                                    level: 'adgroup',
                                    channel: "facebook",
                                    campaign: campaign.id,
                                    name: item.name,
                                    start_date: this.state.order.startDate && moment(this.state.order.startDate).isAfter(moment()) ? moment(this.state.order.startDate).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD"),
                                    end_date: this.state.order.endDate && moment(this.state.order.endDate).isAfter(moment()) ? moment(this.state.order.endDate).format("YYYY-MM-DD") : (this.state.order.endDate ? moment().format("YYYY-MM-DD") : null),
                                    gender: item.gender,
                                    age_min: item.age_min ? item.age_min : { id: 18, name: "18", value: "18" },
                                    age_max: item.age_max ? item.age_max : { id: 65, name: "65", value: "65" },
                                    adset_budget: { id: 1, name: "Yes", value: true },
                                    daily_budget: item.daily_budget ? item.daily_budget : 0,
                                    lifetime_budget: item.lifetime_budget ? item.lifetime_budget : 0,
                                    budget_type: item.lifetime_budget ? { id: 2, name: "Lifetime budget", value: 'total' } : { id: 1, name: "Daily budget", value: 'daily' },
                                    daily_minimum: item.daily_minimum ? item.daily_minimum : 0,
                                    daily_maximum: item.daily_maximum ? item.daily_maximum : 0,
                                    spend_limit: item.daily_minimum || item.daily_maximum ? { id: 1, name: "Yes", value: true } : { id: 2, name: "No", value: false },
                                    auto_placements: item.auto_placements,
                                    ...(item.devices && { devices: item.devices }),
                                    ...(item.platforms && { platforms: item.platforms }),
                                    ...(item.facebook_positions && { facebook_positions: item.facebook_positions }),
                                    ...(item.instagram_positions && { instagram_positions: item.instagram_positions }),
                                    ...(item.messenger_positions && { messenger_positions: item.messenger_positions }),
                                    ...(item.audience_network_positions && { audience_network_positions: item.audience_network_positions }),
                                    beneficiary: item.beneficiary ? item.beneficiary : "",//this.state.client.company,
                                    payor: item.payor ? item.payor : "",//this.state.agency.name
                                    ...(item.campaign_objective && { campaign_objective: item.campaign_objective }),
                                    locations: item.locations,
                                    safety_facebook: item.safety_facebook ? item.safety_facebook : { id: 2, name: "Standard", value: "FACEBOOK_STANDARD" },
                                    safety_network: item.safety_network ? item.safety_network : { id: 2, name: "Standard", value: "AN_STANDARD" },
                                    languages: item.languages,
                                    audience: item.audience,
                                    ...(item.application && { application: item.application }),
                                    ...(item.store && { store: item.store }),
                                    ...(item.pixel && { pixel: item.pixel }),
                                    ...(item.event_type && { event_type: item.event_type }),
                                };
                                adgroups.push(adgroup_object);
                            })
                        }
                        //await this.functions.getAdgroups();
                        try {
                            /*
                            let response = await this.calls.createWizard({
                                order: this.state.selected_campaigns[0].order,
                                name: this.state.selected_campaigns.length === 1 ? this.state.selected_campaigns[0].name : "Multi channel edit",
                                partial: true,
                                partial_data: {
                                    campaigns: this.state.selected_campaigns,
                                    adgroups: adgroups,
                                    channels: {
                                        ...(this.state.selected_campaigns.filter((item) => { return item.channel === "facebook" }).length > 0) && { facebook: true },
                                        ...(this.state.selected_campaigns.filter((item) => { return item.channel === "linkedin" }).length > 0) && { linkedin: true },
                                        ...(this.state.selected_campaigns.filter((item) => { return item.channel === "snapchat" }).length > 0) && { snapchat: true },
                                        ...(this.state.selected_campaigns.filter((item) => { return item.channel === "tiktok" }).length > 0) && { tiktok: true },
                                    }
                                },
            
                            });
                            this.props.history.push("/v2/campaigns/new/channels?draft=" + response.data.id);
                            
                        } catch (error) {
                            console.log(error);
                        }
                        ///await this.promisedSetState({ loading_wizard: false });
                        */
        },
        pauseCampaign: async (campaign) => {
            let body = null;
            if (campaign.channel === "snapchat") {
                body = {
                    id: campaign.id,
                    name: campaign.name,
                    start_time: campaign.start_time,
                    ...(campaign.end_time) && { end_time: campaign.end_time },
                    ...(campaign.daily_budget_micro) && { daily_budget_micro: campaign.daily_budget_micro },
                    ...(campaign.lifetime_spend_cap_micro) && { lifetime_spend_cap_micro: campaign.lifetime_spend_cap_micro },
                    ...(campaign.buy_model) && { buy_model: campaign.buy_model }
                }
            }
            this.state.campaigns.map((item) => { if (campaign.id === item.id) { item.loading = true } return item });
            await this.promisedSetState({ campaigns: this.state.campaigns });
            if (body === null) {
                body = {}
            }
            if (!campaign.campaign_data) {
                campaign.id = campaign.campaignId;
            }
            body["keep_schedule"] = this.state.pause_campaign_schedule && this.state.pause_campaign_schedule.value == "keep_schedule";
            if (this.state.schedule_warning) {
                await this.promisedSetState({ schedule_warning: false, pause_item: false });
            }
            try {
                let response = await this.calls.pauseCampaign(campaign, body);
                this.state.campaigns.map((item) => {
                    if (campaign.id === item.id) {
                        item.status = response.data.status;
                        if ("scheduleActivated" in response.data) {
                            item.scheduleActivated = response.data.scheduleActivated;
                        }
                        if ("scheduleDatesActivated" in response.data) {
                            item.scheduleDatesActivated = response.data.scheduleDatesActivated;
                        }
                        if ("budgetPlanerActivated" in response.data) {
                            item.budgetPlanerActivated = response.data.budgetPlanerActivated;
                        }
                    }
                    return item
                });
                await this.promisedSetState({ campaigns: this.state.campaigns });
            } catch (error) { }
            this.state.campaigns.map((item) => { if (campaign.id === item.id) { item.loading = false } return item });
            await this.promisedSetState({ campaigns: this.state.campaigns });
        },
        pauseAdgroup: async (adgroup) => {
            let body = null
            if (adgroup.channel === "snapchat") {
                body = JSON.parse(JSON.stringify(adgroup));
                ["channel", "level", "account", "status", "meta", "metrics", "internal_campaign", "loading"].map((key) => {
                    delete body[key];
                });
                Object.keys(body).map((key) => {
                    if (body[key] === null) {
                        delete body[key];
                    }
                })
            }
            this.state.adgroups.map((item) => { if (adgroup.id === item.id) { item.loading = true } return item });
            await this.promisedSetState({ adgroups: this.state.adgroups });
            if (body === null) {
                body = {}
            }
            body["keep_schedule"] = this.state.pause_campaign_schedule && this.state.pause_campaign_schedule.value == "keep_schedule";
            try {
                let response = await this.calls.pauseAdgroup(adgroup, body);
                this.state.adgroups.map((item) => {
                    if (adgroup.id === item.id) {
                        item.status = response.data.status;
                        if (response.data.removedSchedule) {
                            item.scheduleActivated = false;
                            item.scheduleDatesActivated = false;
                            item.budgetPlanerActivated = false;
                        }
                    }
                    return item
                });
                if (this.state.campaign.scheduleAdgroups) {
                    delete this.state.campaign.scheduleAdgroups[adgroup.id];
                    await this.promisedSetState({ campaign: this.state.campaign });
                    this.state.campaigns = this.state.campaigns.map((item) => {
                        if (item.id === this.state.campaign.id) {
                            item.scheduleAdgroups = this.state.campaign.scheduleAdgroups;
                        }
                        return item;
                    });
                }
                await this.promisedSetState({ campaigns: this.state.campaigns });
            } catch (error) { console.log(error) }
            this.state.adgroups.map((item) => { if (adgroup.id === item.id) { item.loading = false } return item });
            await this.promisedSetState({ adgroups: this.state.adgroups });
            if (this.state.schedule_warning) {
                await this.promisedSetState({ schedule_warning: false, pause_item: false });
            }
        },
        activateCampaign: async (campaign) => {
            let body = null;
            if (campaign.channel === "snapchat") {
                body = {
                    id: campaign.id,
                    name: campaign.name,
                    start_time: campaign.start_time,
                    ...(campaign.end_time) && { end_time: campaign.end_time },
                    ...(campaign.daily_budget_micro) && { daily_budget_micro: campaign.daily_budget_micro },
                    ...(campaign.lifetime_spend_cap_micro) && { lifetime_spend_cap_micro: campaign.lifetime_spend_cap_micro },
                    ...(campaign.buy_model) && { buy_model: campaign.buy_model },
                }
            }
            this.state.campaigns.map((item) => { if (campaign.id === item.id) { item.loading = true } return item });
            await this.promisedSetState({ campaigns: this.state.campaigns });
            if (body === null) {
                body = {}
            }
            if (!campaign.campaign_data) {
                campaign.id = campaign.campaignId;
            }
            try {
                let response = await this.calls.activateCampaign(campaign, body);
                this.state.campaigns.map((item) => {
                    if (campaign.id === item.id) {
                        item.status = response.data.status;
                        if ("scheduleActivated" in response.data) {
                            item.scheduleActivated = response.data.scheduleActivated;
                        }
                        if ("scheduleDatesActivated" in response.data) {
                            item.scheduleDatesActivated = response.data.scheduleDatesActivated;
                        }
                        if ("budgetPlanerActivated" in response.data) {
                            item.budgetPlanerActivated = response.data.budgetPlanerActivated;
                        }
                    }
                    return item
                });
                await this.promisedSetState({ campaigns: this.state.campaigns });
            } catch (error) { }
            this.state.campaigns.map((item) => { if (campaign.id === item.id) { item.loading = false } return item });
            await this.promisedSetState({ campaigns: this.state.campaigns });
        },
        activateAdgroup: async (adgroup) => {
            let body = null
            if (adgroup.channel === "snapchat") {
                body = JSON.parse(JSON.stringify(adgroup));
                ["channel", "level", "account", "status", "meta", "metrics", "internal_campaign", "loading"].map((key) => {
                    delete body[key];
                });
                Object.keys(body).map((key) => {
                    if (body[key] === null) {
                        delete body[key];
                    }
                })
            }
            this.state.adgroups.map((item) => { if (adgroup.id === item.id) { item.loading = true } return item });
            await this.promisedSetState({ adgroups: this.state.adgroups });
            if (body === null) {
                body = {}
            }
            try {
                let response = await this.calls.activateAdgroup(adgroup, body);
                this.state.adgroups.map((item) => {
                    if (adgroup.id === item.id) {
                        item.status = response.data.status;
                        if (response.data.removedSchedule) {
                            item.scheduleActivated = false;
                            item.scheduleDatesActivated = false;
                            item.budgetPlanerActivated = false;
                        }
                    }
                    return item
                });
                if (this.state.campaign.scheduleAdgroups) {
                    delete this.state.campaign.scheduleAdgroups[adgroup.id];
                    await this.promisedSetState({ campaign: this.state.campaign });
                    this.state.campaigns = this.state.campaigns.map((item) => {
                        if (item.id === this.state.campaign.id) {
                            item.scheduleAdgroups = this.state.campaign.scheduleAdgroups;
                        }
                        return item;
                    });
                }
                await this.promisedSetState({ campaigns: this.state.campaigns });
                await this.promisedSetState({ adgroups: this.state.adgroups });
                this.functions.handleCreateChangelog(adgroup.id, "activate", "adgroup")
            } catch (error) { }
            this.state.adgroups.map((item) => { if (adgroup.id === item.id) { item.loading = false } return item });
            await this.promisedSetState({ adgroups: this.state.adgroups });
        },
        pauseAd: async (ad) => {
            let body = null
            this.state.ads.map((item) => { if (ad.id === item.id) { item.loading = true } return item });
            await this.promisedSetState({ ads: this.state.ads });
            if (body === null) {
                body = {}
            }
            try {
                let response = await this.calls.pauseAd(ad, body);
                this.state.ads.map((item) => {
                    if (ad.id === item.id) {
                        item.status = response.data.status;
                        if (item.channel === "tiktok") {
                            item.ad_status = response.data.status.toUpperCase();
                        }
                    }
                    return item
                });
                await this.promisedSetState({ ads: this.state.ads });
            } catch (error) { }
            this.state.ads.map((item) => { if (ad.id === item.id) { item.loading = false } return item });
            await this.promisedSetState({ ads: this.state.ads });
        },
        updateCriteria: async (criteria) => {
            if (this.state.keywords.filter((item) => { return item.id === criteria.id }).length > 0) {
                this.state.keywords.map((item) => { if (criteria.id === item.id) { item.loading = true } return item });
                await this.promisedSetState({ keywords: this.state.keywords });
                try {
                    let body = {
                        operations: [{
                            updateMask: "status",
                            update: {
                                resourceName: criteria.resourceName,
                                status: criteria.status.toLowerCase() === "enabled" ? "PAUSED" : "ENABLED"
                            }
                        }
                        ]
                    }
                    let response = await this.calls.updateAdgroupCriteria(body);
                    this.state.keywords.map((item) => {
                        if (criteria.id === item.id) {
                            item.status = criteria.status.toLowerCase() === "enabled" ? "paused" : "enabled";
                        }
                        return item
                    });
                    await this.promisedSetState({ keywords: this.state.keywords });
                } catch (error) { }
                this.state.keywords.map((item) => { if (criteria.id === item.id) { item.loading = false } return item });
                await this.promisedSetState({ keywords: this.state.keywords });
            }
        },
        activateAd: async (ad) => {
            let body = null
            this.state.ads.map((item) => { if (ad.id === item.id) { item.loading = true } return item });
            await this.promisedSetState({ ads: this.state.ads });
            if (body === null) {
                body = {}
            }
            try {
                //let response = await this.calls.activateAd(ad, body);
                console.log(ad, "AD");
                let response = await this.calls.activateAd(ad);
                this.state.ads.map((item) => {
                    if (ad.id === item.id) {
                        item.status = response.data.status;
                        if (item.channel === "tiktok") {
                            item.ad_status = response.data.status.toUpperCase();;
                        }
                    }
                    return item
                });
                this.functions.handleCreateChangelog(ad.id, "activate", "ad")
                await this.promisedSetState({ ads: this.state.ads });
            } catch (error) { }
            this.state.ads.map((item) => { if (ad.id === item.id) { item.loading = false } return item });
            await this.promisedSetState({ ads: this.state.ads });
        },
        getCampaigns: async () => {
            return new Promise(async (resolve) => {
                try {
                    this.promisedSetState({
                        loading: true,
                    });
                    let response = await this.calls.getCampaigns();
                    let channels = [{ id: "all", name: "All channels", value: "all" }];
                    response.data.campaigns = response.data.campaigns.map((item) => {
                        if (channels.filter((channel) => { return channel.value === item.channel }).length < 1) {
                            channels.push({ id: item.channel, name: item.channel, value: item.channel });
                        }
                        return item;
                    });
                    await this.promisedSetState({
                        order: response.data.order ? response.data.order : {},
                        client: response.data.client ? response.data.client : {},
                        campaigns: response.data.campaigns,
                        channels: channels,
                        loading: false,
                    });
                    if (this.state.order.metrics && Object.keys(this.state.order.metrics).length > 0) {
                        await this.promisedSetState({
                            metrics: Object.keys(this.state.order.metrics).map((item) => { return { name: this.state.order.metrics[item].name, value: this.state.order.metrics[item].name, min_width: '250px', metric: true, id: this.state.order.metrics[item].id } })
                        });
                        await this.promisedSetState({
                            reload_table: true
                        })
                    }
                    if (this.props.setOrder) {
                        this.props.setOrder(this.state.order);
                    }
                    if (this.state.order.googleBudgets && this.state.order.googleBudgets.length > 0) {
                        let additional = false
                        if (this.state.order.googleBudgets.map((item) => { if (Object.keys(item).length === 1) { return true } else return false }).filter((item) => { return item }).length > 0) {
                            additional = true
                        }
                        if (additional) {
                            await this.functions.getGoogleBudgets();
                        }
                    }
                    this.state.campaigns.map((campaign) => {
                        if (this.state.advancedFilters[0].options.filter((item) => { return item.value === campaign.status }).length < 1) {
                            this.state.advancedFilters[0].options.push({ id: this.state.advancedFilters[0].options.length + 1, name: campaign.status[0].toUpperCase() + campaign.status.slice(1), value: campaign.status });
                        }
                    });
                    if (this.state.advancedFilters[0].options.filter((item) => { return item.value === this.state.advancedFilters[0].selected.value }).length < 1) {
                        //this.state.advancedFilters[0].selected = [];
                    }
                    await this.promisedSetState({
                        advancedFilters: this.state.advancedFilters
                    });


                    this.functions.getCampaignsData(false, true);
                    await this.promisedSetState({
                        reload_table: false
                    })
                } catch (error) {
                    this.promisedSetState({
                        error: true,
                        loading: false
                    })
                }
                resolve();
            })
        },
        getGoogleBudgets: async () => {
            try {
                let response = await this.calls.listGoogleBudgets();
                this.state.order.googleBudgets = this.state.order.googleBudgets.map((item) => {
                    if (response.data.filter((inner_item) => { return inner_item.id === item.id }).length > 0) {
                        item = response.data.filter((inner_item) => { return inner_item.id === item.id })[0];
                    }
                    return item;
                });
                await this.promisedSetState({
                    order: this.state.order
                });
            } catch (error) {
                console.log(error);
            }
        },
        getAssets: async () => {
            try {
                await this.promisedSetState({
                    loading_partial: true,
                    assets: []
                });
                let location_assets = await this.calls.getCampaignAssetsSets({
                    campaign_ids: this.state.selected_campaigns.map((campaign) => { return campaign.campaignId }),
                    properties: "all",
                    location_type: true
                });
                if (location_assets.data && location_assets.data.length > 0) {
                    let uniqueCampaigns = new Map();

                    // Filter data to ensure unique `campaignAssetSet.campaign`
                    let filteredArray = location_assets.data.filter(item => {
                        let campaign = item.campaignAssetSet.campaign;
                        if (!uniqueCampaigns.has(campaign)) {
                            uniqueCampaigns.set(campaign, true);
                            return true;
                        }
                        return false;
                    });
                    filteredArray.map((item) => {
                        this.state.assets.push({
                            id: Math.floor((Math.random() * 99999999999999) + 1),
                            data: location_assets.data.filter(inner_item => { return inner_item.campaignAssetSet.campaign === item.campaignAssetSet.campaign }),
                            type: "LOCATION",
                            asset_type: "Location",
                            asset_level: "Campaign",
                            level: "asset",
                            channel: "google",
                            status: item.campaignAssetSet.status.toLowerCase(),
                            resourceName: item.campaignAssetSet.resourceName,
                            campaign: this.state.campaigns.filter((campaign) => { return item.campaignAssetSet.campaign.includes(campaign.campaignId) })[0].name,
                            name: "Location asset",
                        })
                    });
                }


                let response = await this.calls.getCampaignAssets({
                    campaign_ids: this.state.selected_campaigns.map((campaign) => { return campaign.campaignId }),
                    properties: "all"
                });

                response.data.map((item) => {
                    let name = "Unknown type";
                    let types = { "SITELINK": { "sitelinkAsset": "linkText" }, "TEXT": { "textAsset": "text" }, "CALLOUT": { "calloutAsset": "calloutText" }, "STRUCTURED_SNIPPET": { "structuredSnippetAsset": "header" }, "PROMOTION": { "promotionAsset": "occasion" }, "CALL": { "callAsset": "phoneNumber" }, "PRICE": { "priceAsset": "type" } };
                    if (item.asset.type === "IMAGE") {
                        name = "Image type";
                    } if (Object.keys(types).includes(item.asset.type)) {
                        name = item.asset[Object.keys(types[item.asset.type])[0]][types[item.asset.type][Object.keys(types[item.asset.type])[0]]];
                        if (item.asset.type === "PROMOTION" || item.asset.type === "PRICE") {
                            name = name.charAt(0) + name.toLowerCase().replace(/\_/g, " ").slice(1);
                        }
                    }
                    this.state.assets.push({
                        id: Math.floor((Math.random() * 99999999999999) + 1),
                        data: item,
                        type: item.campaignAsset.fieldType,
                        asset_type: item.campaignAsset.fieldType.charAt(0) + item.campaignAsset.fieldType.toLowerCase().replace("_", " ").slice(1),
                        asset_level: "Campaign",
                        level: "asset",
                        channel: "google",
                        status: item.campaignAsset.status.toLowerCase(),
                        resourceName: item.campaignAsset.resourceName,
                        campaign: this.state.campaigns.filter((campaign) => { return item.campaignAsset.campaign.includes(campaign.campaignId) })[0].name,
                        name: name,
                    })
                });

                await this.functions.getAdgroups();

                if (this.state.selected_adgroups.length > 0) {
                    this.state.assets = [];
                    let response = await this.calls.getAdgroupsAssets({
                        adgroup_ids: this.state.selected_adgroups.map((adgroup) => { return adgroup.id }),
                        properties: "all"
                    });

                    response.data.map((item) => {
                        let name = "Unknown type";
                        let types = { "SITELINK": { "sitelinkAsset": "linkText" }, "TEXT": { "textAsset": "text" }, "CALLOUT": { "calloutAsset": "calloutText" }, "STRUCTURED_SNIPPET": { "structuredSnippetAsset": "header" }, "PROMOTION": { "promotionAsset": "occasion" }, "CALL": { "callAsset": "phoneNumber" }, "PRICE": { "priceAsset": "type" } };
                        if (item.asset.type === "AD_IMAGE" || item.asset.type === "IMAGE") {
                            name = "Image type";
                        } if (Object.keys(types).includes(item.asset.type)) {
                            name = item.asset[Object.keys(types[item.asset.type])[0]][types[item.asset.type][Object.keys(types[item.asset.type])[0]]];
                            if (item.asset.type === "PROMOTION" || item.asset.type === "PRICE") {
                                name = name.charAt(0) + name.toLowerCase().replace(/\_/g, " ").slice(1);
                            }
                        }

                        this.state.assets.push({
                            id: Math.floor((Math.random() * 99999999999999) + 1),
                            data: item,
                            type: item.adGroupAsset.fieldType,
                            asset_type: item.adGroupAsset.fieldType.charAt(0) + item.adGroupAsset.fieldType.toLowerCase().replace("_", " ").slice(1),
                            asset_level: "Adgroup",
                            level: "asset",
                            channel: "google",
                            status: item.adGroupAsset.status.toLowerCase(),
                            adgroup: this.state.adgroups.filter((adgroup) => { return item.adGroupAsset.adGroup.includes(adgroup.id) })[0].name,
                            resourceName: item.adGroupAsset.resourceName,
                            name: name,
                        })
                    });
                } else {
                    let response = await this.calls.getAdgroupsAssets({
                        adgroup_ids: this.state.adgroups.filter((item) => { return item.channel === "google" }).map((adgroup) => { return adgroup.id }),
                        properties: "all"
                    });
                    //console.log(response, "RESPONSE");

                    response.data.map((item) => {
                        let name = "Unknown type";
                        let types = { "SITELINK": { "sitelinkAsset": "linkText" }, "TEXT": { "textAsset": "text" }, "CALLOUT": { "calloutAsset": "calloutText" }, "STRUCTURED_SNIPPET": { "structuredSnippetAsset": "header" }, "PROMOTION": { "promotionAsset": "occasion" }, "CALL": { "callAsset": "phoneNumber" }, "PRICE": { "priceAsset": "type" } };
                        if (item.asset.type === "AD_IMAGE" || item.asset.type === "IMAGE") {
                            name = "Image type";
                        } if (Object.keys(types).includes(item.asset.type)) {
                            name = item.asset[Object.keys(types[item.asset.type])[0]][types[item.asset.type][Object.keys(types[item.asset.type])[0]]];
                            if (item.asset.type === "PROMOTION" || item.asset.type === "PRICE") {
                                name = name.charAt(0) + name.toLowerCase().replace(/\_/g, " ").slice(1);
                            }
                        }
                        this.state.assets.push({
                            id: Math.floor((Math.random() * 99999999999999) + 1),
                            data: item,
                            type: item.adGroupAsset.fieldType,
                            asset_type: item.adGroupAsset.fieldType.charAt(0) + item.adGroupAsset.fieldType.toLowerCase().replace("_", " ").slice(1),
                            asset_level: "Adgroup",
                            level: "asset",
                            channel: "google",
                            status: item.adGroupAsset.status.toLowerCase(),
                            adgroup: this.state.adgroups.filter((adgroup) => { return item.adGroupAsset.adGroup.includes(adgroup.id) })[0].name,
                            resourceName: item.adGroupAsset.resourceName,
                            name: name,
                        })
                    });

                }



                await this.promisedSetState({
                    assets: this.state.assets,
                    loading_partial: false,
                });

                console.log(this.state.assets, "RESPONSE");
            } catch (error) {

            }
        },
        getCampaignsData: async (campaign, basic = false) => {
            try {
                await this.promisedSetState({
                    loading_metrics: true
                });
                let campaigns_string = "";
                if (!campaign) {
                    this.state.campaigns.map((campaign) => {
                        campaigns_string = campaigns_string + ("&" + campaign.channel + "_campaigns[]=" + campaign.campaignId);
                    });
                } else {
                    campaigns_string = ("&" + campaign.channel + "_campaigns[]=" + campaign.id + "&information=true");
                }
                campaigns_string = campaigns_string + "&calculate_basic_metrics=true" + (basic ? "&information=true&information_basic=true" : "");
                if (basic) {
                    await this.promisedSetState({
                        loading_basic: true
                    });
                }

                let response = await this.calls.getCampaignsData(campaigns_string);

                this.state.campaigns = this.state.campaigns.map((campaign) => {
                    if (response.data[campaign.channel]) {
                        response.data[campaign.channel].map((item) => {
                            if (campaign.id === item.id || campaign.campaignId === item.id) {

                                //PARSE METRICS
                                let metrics = {};
                                if (Array.isArray(item.metrics)) {
                                    item.metrics.map((metric) => {
                                        metrics[metric.name] = metric.value;
                                    });
                                }
                                item.metrics = metrics;
                                //campaign.name = item.name;
                                //campaign.status = item.status;
                                campaign = { ...campaign, ...item };
                            }
                        })
                    }
                    return campaign;
                });

                if (this.state.order.metrics && Object.keys(this.state.order.metrics).length > 0) {
                    let date_intervals = {
                        "last_7": { start: moment().subtract(7, 'd').format('YYYY-MM-DD'), end: moment().format('YYYY-MM-DD') },
                        "last_10": { start: moment().subtract(10, 'd').format('YYYY-MM-DD'), end: moment().format('YYYY-MM-DD') },
                        "last_14": { start: moment().subtract(14, 'd').format('YYYY-MM-DD'), end: moment().format('YYYY-MM-DD') },
                        "last_20": { start: moment().subtract(20, 'd').format('YYYY-MM-DD'), end: moment().format('YYYY-MM-DD') },
                        "last_30": { start: moment().subtract(30, 'd').format('YYYY-MM-DD'), end: moment().format('YYYY-MM-DD') },
                        "last_60": { start: moment().subtract(60, 'd').format('YYYY-MM-DD'), end: moment().format('YYYY-MM-DD') },
                        "last_90": { start: moment().subtract(90, 'd').format('YYYY-MM-DD'), end: moment().format('YYYY-MM-DD') }
                    };
                    let body = [{
                        id: 1,
                        campaigns: this.state.campaigns.map((campaign) => { return { id: campaign.id, channel: campaign.channel, name: campaign.name } }),
                        adgroups: [],
                        start_date: this.state.custom_dates.start ? this.state.custom_dates.start : date_intervals[this.state.date_span.value].start,
                        end_date: this.state.custom_dates.end ? this.state.custom_dates.end : date_intervals[this.state.date_span.value].end,
                        metrics: Object.keys(this.state.order.metrics).map((item) => { return this.state.order.metrics[item] }),
                        information: {},
                        type: "table",
                        level: "campaign",
                        dimensions: {
                            adform: "overview",
                            basic: "overview",
                            bidtheatre: "overview",
                            bing: "overview",
                            facebook: "overview",
                            google: "overview",
                            google_analytics: "overview",
                            google_analytics_4: "overview",
                            linkedin: "overview",
                            snapchat: "overview",
                            tiktok: "overview",
                            twitter: "overview"
                        }
                    }];
                    this.state.campaigns = this.state.campaigns.map((campaign) => {
                        Object.keys(this.state.order.metrics).map((item) => {
                            if (!campaign.metrics[this.state.order.metrics[item].name]) {
                                campaign.metrics[this.state.order.metrics[item].name] = "-";
                            }
                        })
                        return campaign;
                    });
                    let response = await this.calls.getLineChart(body);
                    if (response.data && response.data[0] && response.data[0].data) {
                        this.state.campaigns = this.state.campaigns.map((campaign) => {
                            if (response.data[0].data.filter((item) => { return item.id === campaign.id }).length > 0) {
                                let buffer = response.data[0].data.filter((item) => { return item.id === campaign.id })[0];
                                Object.keys(buffer).map((item) => {
                                    if (campaign.metrics[item]) {
                                        campaign.metrics[item] = buffer[item];
                                    }
                                });
                            }
                            return campaign;
                        });
                    }
                }
                if (basic) {
                    await this.promisedSetState({
                        loading_basic: false
                    });
                }
                this.promisedSetState({
                    campaigns: this.state.campaigns,
                    loading_metrics: false
                })
            } catch (error) {
                this.promisedSetState({
                    error: true,
                    loading_metrics: false
                })
            }
        },
        getAdgroups: async (campaign = false) => {
            try {
                this.promisedSetState({
                    loading_partial: true
                });

                let campaigns_string = "&" + this.state.selected_campaigns.map(campaign => `${campaign.channel}_campaigns[]=${campaign.campaignId}`).join('&');
                let response = await this.calls.getAdgroups(campaigns_string);
                let adgroups = [];
                for (let channel in response.data) {
                    response.data[channel] = response.data[channel].map((item, index) => {
                        let campaign = null;
                        for (let i in this.state.selected_campaigns) {
                            if (this.state.selected_campaigns[i].campaignId === item.campaign_id) {
                                campaign = this.state.selected_campaigns[i];
                                item.internal_campaign = this.state.selected_campaigns[i].internal;
                                if (channel === 'facebook' && campaign.objective) { item.campaign_objective = campaign.objective; }
                                if (channel === "google" && campaign.advertising_type && campaign.advertising_type.value) {
                                    if (campaign.advertising_type.value === "SEARCH") {
                                        item.advertising_type = "SEARCH_STANDARD";
                                        item.advertising_type_ad = "RESPONSIVE_SEARCH_AD";
                                    } else if (campaign.advertising_type.value === "SHOPPING") {
                                        item.advertising_type = "SHOPPING_PRODUCT_ADS";
                                        item.advertising_type_ad = "SHOPPING_PRODUCT_AD";
                                    }
                                }
                                if (channel === "tiktok" && campaign.budget_optimize_on) {
                                    item.campaign_budget_optimization = true;
                                    item.campaign_budget = {
                                        mode: campaign.budget_mode,
                                        value: campaign.budget,
                                    };
                                }
                            }
                        }
                        if (campaign.scheduleAdgroups) {
                            if (item.id in campaign.scheduleAdgroups) {
                                item = { ...item, ...campaign.scheduleAdgroups[item.id] };
                            }
                        }
                        return item;
                    });
                    adgroups = adgroups.concat(response.data[channel]);
                }
                await this.promisedSetState({
                    adgroups: adgroups,
                    loading_partial: false
                });
                this.functions.getAdgroupsData();
            } catch (error) {
                this.promisedSetState({
                    error: true,
                    loading_partial: false
                });
            }
        },
        getAdgroupsData: async (adgroup = false) => {
            try {
                await this.promisedSetState({
                    loading_metrics: true
                });
                let adgroups_string = "";
                if (!adgroup) {
                    this.state.adgroups.map((adgroup) => {
                        adgroups_string += "&" + adgroup.channel + "_adgroups[]=" + adgroup.id;
                    });

                    adgroups_string += "&campaign=" + this.state.adgroups[0].campaign_id
                } else {
                    adgroups_string = ("&" + adgroup.channel + "_adgroups[]=" + adgroup.id + "&information=true&campaign=" + adgroup.campaign_id);
                }
                //adgroups_string = adgroups_string + ("&" + "campaign=" + this.state.campaign.id);
                let response = await this.calls.getAdgroupsData(adgroups_string);
                this.state.adgroups = this.state.adgroups.map((adgroup) => {
                    if (response.data[adgroup.channel]) {
                        response.data[adgroup.channel].map((item) => {
                            if (adgroup.id == item.id) {

                                //PARSE METRICS
                                let metrics = {};
                                if (Array.isArray(item.metrics)) {
                                    item.metrics.map((metric) => {
                                        metrics[metric.name] = metric.value;
                                    });
                                }
                                item.metrics = metrics;

                                adgroup = {
                                    ...adgroup,
                                    ...item,
                                    campaign: adgroup.campaign,
                                    campaign_id: adgroup.camapign_id || adgroup.campaign
                                };
                            }
                        })
                    }
                    return adgroup;
                });
                if (this.state.order.metrics && Object.keys(this.state.order.metrics).length > 0) {
                    let date_intervals = {
                        "last_7": { start: moment().subtract(7, 'd').format('YYYY-MM-DD'), end: moment().format('YYYY-MM-DD') },
                        "last_10": { start: moment().subtract(10, 'd').format('YYYY-MM-DD'), end: moment().format('YYYY-MM-DD') },
                        "last_14": { start: moment().subtract(14, 'd').format('YYYY-MM-DD'), end: moment().format('YYYY-MM-DD') },
                        "last_20": { start: moment().subtract(20, 'd').format('YYYY-MM-DD'), end: moment().format('YYYY-MM-DD') },
                        "last_30": { start: moment().subtract(30, 'd').format('YYYY-MM-DD'), end: moment().format('YYYY-MM-DD') },
                        "last_60": { start: moment().subtract(60, 'd').format('YYYY-MM-DD'), end: moment().format('YYYY-MM-DD') },
                        "last_90": { start: moment().subtract(90, 'd').format('YYYY-MM-DD'), end: moment().format('YYYY-MM-DD') }
                    };
                    let body = [{
                        id: 1,
                        campaigns: [],
                        adgroups: this.state.adgroups.map((adgroup) => { return { id: adgroup.id, channel: adgroup.channel, name: adgroup.name } }),
                        start_date: this.state.custom_dates.start ? this.state.custom_dates.start : date_intervals[this.state.date_span.value].start,
                        end_date: this.state.custom_dates.end ? this.state.custom_dates.end : date_intervals[this.state.date_span.value].end,
                        metrics: Object.keys(this.state.order.metrics).map((item) => { return this.state.order.metrics[item] }),
                        information: {},
                        type: "table",
                        level: "adgroup",
                        dimensions: {
                            adform: "overview",
                            basic: "overview",
                            bidtheatre: "overview",
                            bing: "overview",
                            facebook: "overview",
                            google: "overview",
                            google_analytics: "overview",
                            google_analytics_4: "overview",
                            linkedin: "overview",
                            snapchat: "overview",
                            tiktok: "overview",
                            twitter: "overview"
                        }
                    }];
                    this.state.adgroups = this.state.adgroups.map((adgroup) => {
                        Object.keys(this.state.order.metrics).map((item) => {
                            if (!adgroup.metrics) adgroup.metrics = {};
                            if (!adgroup.metrics[this.state.order.metrics[item].name]) {
                                adgroup.metrics[this.state.order.metrics[item].name] = "-";
                            }
                        })
                        return adgroup;
                    });
                    let response = await this.calls.getLineChart(body);
                    if (response.data && response.data[0] && response.data[0].data) {
                        this.state.adgroups = this.state.adgroups.map((adgroup) => {
                            if (response.data[0].data.filter((item) => { return item.id === adgroup.id }).length > 0) {
                                let buffer = response.data[0].data.filter((item) => { return item.id === adgroup.id })[0];
                                Object.keys(buffer).map((item) => {
                                    if (adgroup.metrics[item]) {
                                        adgroup.metrics[item] = buffer[item];
                                    }
                                });
                            }
                            return adgroup;
                        });
                    }
                }
                this.promisedSetState({
                    adgroups: this.state.adgroups,
                    loading_metrics: false
                });
            } catch (error) {
                this.promisedSetState({
                    error: true,
                    loading_metrics: false
                })
            }
        },
        getAds: async () => {
            try {
                await this.promisedSetState({
                    loading_partial: true
                });
                let ads = [];
                const selected_adgroups = this.state.selected_adgroups.length ? this.state.selected_adgroups : this.state.adgroups
                /*const adgroupKey = selected_adgroups.map(item => item.id).join(",");
                if (adgroupKey === this.state.adgroupAdsFetchedKeys) {
                    await this.promisedSetState({
                        loading_partial: false
                    });
                    return false;
                }
                console.log(selected_adgroups, "SELECTED ADGROUPS")
                console.log(adgroupKey, "ADGROUP KEY")
                await this.promisedSetState({
                    adgroupAdsFetchedKeys: adgroupKey,
                    ads: []
                });*/
                const groupedByCampaignId = selected_adgroups.reduce((acc, obj) => {
                    const id = obj.campaign_id;
                    if (!acc[id]) {
                        acc[id] = [];
                    }
                    acc[id].push(obj);
                    return acc;
                }, {});
                console.log(groupedByCampaignId, "GROUPED BY CAMPAIGN ID")
                for (const groupCampaign in groupedByCampaignId) {
                    await new Promise(resolve => setTimeout(resolve, 300));
                    let adgroups_string = "";
                    groupedByCampaignId[groupCampaign].forEach(adgroup => { adgroups_string += "&" + adgroup.channel + "_adgroups[]=" + adgroup.id; })
                    adgroups_string += "&campaign=" + groupedByCampaignId[groupCampaign].campaign_id
                    let response = await this.calls.getAds(adgroups_string);
                    for (let channel in response.data) {
                        ads.push(...response.data[channel]);
                    }
                }

                await this.promisedSetState({
                    ads: ads,
                    loading_partial: false
                });
                this.functions.getAdsData();
            } catch (error) {
                console.log(error, "ERROR======");
                this.promisedSetState({
                    error: true,
                    loading_partial: false
                });
            }
        },
        getAdsData: async (ad) => {
            try {
                this.promisedSetState({
                    loading_metrics: true
                });
                let ads_string = "";
                if (!ad) {
                    this.state.ads.map((ad) => {
                        ads_string = ads_string + ("&" + ad.channel + "_ads[]=" + ad.id);
                    });
                    ads_string += ("&" + "adgroup=" + this.state.ads[0].adgroup_id) + ("&" + "campaign=" + this.state.ads[0].campaign_id);
                } else {
                    ads_string = ("&" + ad.channel + "_ads[]=" + ad.id + "&campaign=" + ad.camapign_id + "&" + "adgroup=" + ad.adgroup_id);
                }

                let response = await this.calls.getAdsData(ads_string);
                this.state.ads = this.state.ads.map((ad) => {
                    if (response.data[ad.channel]) {
                        response.data[ad.channel].map((item) => {
                            if (ad.id == item.id) {

                                //PARSE METRICS
                                let metrics = {};
                                if (Array.isArray(item.metrics)) {
                                    item.metrics.map((metric) => {
                                        metrics[metric.name] = metric.value;
                                    });
                                }
                                item.metrics = metrics;
                                ad = { ...ad, ...item };
                                
                            }
                        })
                    }
                    return ad;
                });
                if (this.state.order.metrics && Object.keys(this.state.order.metrics).length > 0) {
                    let date_intervals = {
                        "last_7": { start: moment().subtract(7, 'd').format('YYYY-MM-DD'), end: moment().format('YYYY-MM-DD') },
                        "last_10": { start: moment().subtract(10, 'd').format('YYYY-MM-DD'), end: moment().format('YYYY-MM-DD') },
                        "last_14": { start: moment().subtract(14, 'd').format('YYYY-MM-DD'), end: moment().format('YYYY-MM-DD') },
                        "last_20": { start: moment().subtract(20, 'd').format('YYYY-MM-DD'), end: moment().format('YYYY-MM-DD') },
                        "last_30": { start: moment().subtract(30, 'd').format('YYYY-MM-DD'), end: moment().format('YYYY-MM-DD') },
                        "last_60": { start: moment().subtract(60, 'd').format('YYYY-MM-DD'), end: moment().format('YYYY-MM-DD') },
                        "last_90": { start: moment().subtract(90, 'd').format('YYYY-MM-DD'), end: moment().format('YYYY-MM-DD') }
                    };
                    let body = [{
                        id: 1,
                        campaigns: [],
                        ads: this.state.ads.map((ad) => { return { id: ad.id, channel: ad.channel, name: ad.name } }),
                        start_date: this.state.custom_dates.start ? this.state.custom_dates.start : date_intervals[this.state.date_span.value].start,
                        end_date: this.state.custom_dates.end ? this.state.custom_dates.end : date_intervals[this.state.date_span.value].end,
                        metrics: Object.keys(this.state.order.metrics).map((item) => { return this.state.order.metrics[item] }),
                        information: {},
                        type: "table",
                        dimensions: {
                            adform: "overview",
                            basic: "overview",
                            bidtheatre: "overview",
                            bing: "overview",
                            facebook: "overview",
                            google: "overview",
                            google_analytics: "overview",
                            google_analytics_4: "overview",
                            linkedin: "overview",
                            snapchat: "overview",
                            tiktok: "overview",
                            twitter: "overview"
                        }
                    }];
                    this.state.ads = this.state.ads.map((ad) => {
                        Object.keys(this.state.order.metrics).map((item) => {
                            if (!ad.metrics) ad.metrics = {};
                            if (!ad.metrics[this.state.order.metrics[item].name]) {
                                ad.metrics[this.state.order.metrics[item].name] = "-";
                            }
                        })
                        return ad;
                    });
                    let response = await this.calls.getLineChart(body);
                    if (response.data && response.data[0] && response.data[0].data) {
                        this.state.ads = this.state.ads.map((ad) => {
                            if (response.data[0].data.filter((item) => { return item.id === ad.id }).length > 0) {
                                let buffer = response.data[0].data.filter((item) => { return item.id === ad.id })[0];
                                Object.keys(buffer).map((item) => {
                                    if (ad.metrics[item]) {
                                        ad.metrics[item] = buffer[item];
                                    }
                                });
                            }
                            return ad;
                        });
                    }
                }
                await this.promisedSetState({
                    ads: this.state.ads,
                    loading_metrics: false
                });
            } catch (error) {
                this.promisedSetState({
                    error: true,
                    loading_metrics: false
                })
            }
        },
        menu: async (option, item) => {
            if (option === "Schedule") {
                if (item.level === "campaign") {
                    this.promisedSetState({
                        schedule_campaign: item
                    });
                } else if (item.level === "adgroup") {
                    this.promisedSetState({
                        schedule_adgroup: item
                    });
                }
            } else if (option === "Tags") {
                this.promisedSetState({
                    tab_tags: item
                });
            } else if (option === "Preview") {
                if (window.location.hostname === 'localhost') {
                    window.open("http://localhost:5001/preview/v2/" + item.wizard);
                } else if (window.location.hostname === 'app.adcredo.io') {
                    window.open("https://app.adcredo.io/preview/v2/" + item.wizard);
                } else if (window.location.hostname === 'dev.adcredo.io') {
                    window.open("https://dev.adcredo.io/preview/v2/" + item.wizard);
                }
            } else if (item.channel === "google") {
                if (option === "Open" && item.list) {
                    this.promisedSetState({
                        view_negative_list: item
                    });
                } else if (option === "Remove reference") {
                    this.promisedSetState({
                        remove_ref: item
                    })
                } else if (option === "Add new Ad") {
                    this.functions.createPartialWizard_V2();
                } else if (option === "Edit") {
                    if (item.level === "campaign" && !item.campaign_data) {
                        await this.promisedSetState({
                            loading_partial: true
                        });
                        await this.functions.getCampaignsData({ channel: item.channel, id: item.campaignId });
                        await this.promisedSetState({
                            loading_partial: false
                        });
                        this.promisedSetState({
                            edit: JSON.parse(JSON.stringify(this.state.campaigns.filter((campaign) => { return item.campaignId === campaign.campaignId })[0]))
                        })
                    } else {
                        this.promisedSetState({
                            edit: item
                        })
                    }

                } else if (option === "Delete") {
                    if (item.level && item.level === "asset") {
                        this.promisedSetState({
                            delete_asset: true,
                            remove_asset: item
                        });
                    } else {
                        this.promisedSetState({
                            remove: item
                        })
                    }
                } else if (option === "Pause") {
                    if (item.level === "campaign") {
                        if (item.scheduleActivated) {
                            this.setState({
                                schedule_warning: true,
                                pause_campaign_schedule: { id: 1, name: "Remove schedule settings", value: "remove_schedule" },
                                pause_item: item
                            })
                        } else {
                            this.functions.pauseCampaign(item);
                        }
                    } else if (item.level === "adgroup") {
                        this.functions.pauseAdgroup(item);
                    } else if (item.level === "ad") {
                        this.functions.pauseAd(item);
                    } else {
                        this.functions.updateCriteria(item);
                    }
                } else if (option === "Activate") {
                    if (item.level === "campaign") {
                        this.functions.activateCampaign(item);
                    } else if (item.level === "adgroup") {
                        this.functions.activateAdgroup(item);
                    } else if (item.level === "ad") {
                        this.functions.activateAd(item);
                    } else {
                        this.functions.updateCriteria(item);
                    }
                } else if (option === "Add as negative to Adgroup") {
                    if (item.multi) {
                        this.setState({
                            create_keyword: true,
                            keyword_exact: false,
                            keyword_phrase: false,
                            keyword_broad: true,
                            add_negative_level: { id: 2, name: "Adgroup", value: "adgroup" },
                            keyword_multi_text: JSON.parse(JSON.stringify(this.state.search_terms.filter((item) => { return item.selected })))
                        });
                    } else {
                        this.setState({
                            search_term: item,
                            create_keyword: true,
                            keyword_exact: false,
                            keyword_phrase: false,
                            keyword_broad: true,
                            add_negative_level: { id: 2, name: "Adgroup", value: "adgroup" },
                            keyword_text: item.name
                        });
                    }

                } else if (option === "Add positive" || option === "Add negative") {
                    this.setState({
                        add_keyword: true,
                        keyword_exact: true,
                        keyword_phrase: false,
                        keyword_broad: false,
                        selected_campaign: { id: 0, name: "Select campaign" },
                        selected_adgroup: this.state.adgroup.id ? this.state.adgroup : { id: 0, name: "Select adgroup" },
                        locked_adgroup: this.state.adgroup.id ? true : false,
                        keyword_multi_text: [{
                            id: Math.floor((Math.random() * 9999999999) + 1),
                            name: ""
                        }]
                    });
                } else if (option === "Edit positive") {
                    if (item.multi) {
                        this.setState({
                            edit_keyword: true,
                            keyword_exact: false,
                            keyword_phrase: false,
                            keyword_broad: false,
                            keyword_multi_text: JSON.parse(JSON.stringify(this.state.keywords.filter((item) => { return item.selected }).map((item) => { return item })))
                        });
                    } else {
                        this.setState({
                            edit_positive: item,
                            edit_keyword: true,
                            keyword_exact: item.keyword_type === "EXACT" ? true : false,
                            keyword_phrase: item.keyword_type === "PHRASE" ? true : false,
                            keyword_broad: item.keyword_type === "BROAD" ? true : false,
                            keyword_text: item.name
                        });
                    }

                } else if (option === "Delete positive") {
                    if (item.multi) {
                        this.setState({
                            delete_keyword: true,
                            keyword_multi_text: JSON.parse(JSON.stringify(this.state.keywords.filter((item) => { return item.selected }).map((item) => { return item })))
                        });
                    }
                } else if (option === "Edit negative") {
                    if (item.multi) {
                        this.setState({
                            edit_keyword: true,
                            keyword_exact: false,
                            keyword_phrase: false,
                            keyword_broad: false,
                            keyword_multi_text: (this.state.negative_filter && this.state.negative_filter.value === "campaign" ? JSON.parse(JSON.stringify(this.state.negative_keywords_campaign.filter((item) => { return item.selected }).map((item) => { return item }))) : JSON.parse(JSON.stringify(this.state.negative_keywords.filter((item) => { return item.selected }).map((item) => { return item }))))
                        });
                    } else {
                        this.setState({
                            edit_negative: item,
                            edit_keyword: true,
                            keyword_exact: item.keyword_type === "EXACT" ? true : false,
                            keyword_phrase: item.keyword_type === "PHRASE" ? true : false,
                            keyword_broad: item.keyword_type === "BROAD" ? true : false,
                            keyword_text: item.name
                        });
                    }

                } else if (option === "Delete negative") {
                    if (item.multi) {
                        this.setState({
                            delete_keyword: true,
                            keyword_multi_text: this.state.negative_filter && this.state.negative_filter.value === "campaign" ? JSON.parse(JSON.stringify(this.state.negative_keywords_campaign.filter((item) => { return item.selected }).map((item) => { return item }))) : JSON.parse(JSON.stringify(this.state.negative_keywords.filter((item) => { return item.selected }).map((item) => { return item })))
                        });
                    }
                } else if (option === "Negative to Campaign") {
                    //this.google.addNegativeKeywordCampaign(item);
                } else if (option === "Sync With Bing") {
                    this.promisedSetState({
                        campaign: item,
                        syncToBing: true,
                        //edit: true
                    });
                } else if (option === "Delete assets") {
                    this.promisedSetState({
                        delete_asset: true
                    });
                } else {
                    this.open.google(option, item);
                }
            } else if (item.channel === "facebook") {
                if (option === "Duplicate") {
                    this.functions.duplicateCampaign(item, "facebook");
                } else if (option === "Remove reference") {
                    this.promisedSetState({
                        remove_ref: item
                    })
                } else if (option === "Add new Ad") {
                    this.functions.createPartialWizard(item);
                } else if (option === "Edit") {
                    if (item.level === "ad") {

                        this.promisedSetState({
                            open_ad: item
                        });
                    } else {
                        if (item.level === "campaign" && !item.campaign_data) {
                            await this.promisedSetState({
                                loading_partial: true
                            });
                            await this.functions.getCampaignsData({ channel: item.channel, id: item.campaignId });
                            await this.promisedSetState({
                                loading_partial: false
                            });
                            this.promisedSetState({
                                edit: JSON.parse(JSON.stringify(this.state.campaigns.filter((campaign) => { return item.campaignId === campaign.campaignId })[0]))
                            })
                        } else {
                            this.promisedSetState({
                                edit: item
                            })
                        }
                    }
                } else if (option === "Delete") {
                    this.promisedSetState({
                        remove: item
                    })
                } else if (option === "Pause") {
                    if (item.level === "campaign") {
                        if (item.scheduleActivated) {
                            this.setState({
                                schedule_warning: true,
                                pause_campaign_schedule: { id: 1, name: "Remove schedule settings", value: "remove_schedule" },
                                pause_item: item
                            })
                        } else {
                            this.functions.pauseCampaign(item);
                        }
                    } else if (item.level === "adgroup") {
                        if (item.scheduleActivated) {
                            this.setState({
                                schedule_warning: true,
                                pause_campaign_schedule: { id: 1, name: "Remove schedule settings", value: "remove_schedule" },
                                pause_item: item
                            })
                        } else {
                            this.functions.pauseAdgroup(item);
                        }
                    } else if (item.level === "ad") {
                        this.functions.pauseAd(item);
                    }
                } else if (option === "Activate") {
                    if (item.level === "campaign") {
                        this.functions.activateCampaign(item);
                    } else if (item.level === "adgroup") {
                        this.functions.activateAdgroup(item);
                    } else if (item.level === "ad") {
                        this.functions.activateAd(item);
                    }
                } else {
                    this.open.facebook(item);
                }
            } else if (item.channel === "linkedin") {
                if (option === "Remove reference") {
                    this.promisedSetState({
                        remove_ref: item
                    })
                } else if (option === "Edit") {
                    if (item.level === "ad") {
                        this.promisedSetState({
                            open_ad: item
                        });
                    } else {
                        if (item.level === "campaign" && !item.campaign_data) {
                            await this.promisedSetState({
                                loading_partial: true
                            });
                            await this.functions.getCampaignsData({ channel: item.channel, id: item.campaignId });
                            await this.promisedSetState({
                                loading_partial: false
                            });
                            this.promisedSetState({
                                edit: JSON.parse(JSON.stringify(this.state.campaigns.filter((campaign) => { return item.campaignId === campaign.campaignId })[0]))
                            })
                        } else {
                            this.promisedSetState({
                                edit: item
                            })
                        }
                    }
                } else if (option === "Add new Ad") {
                    this.functions.createPartialWizard(item);
                } else if (option === "Delete") {
                    this.promisedSetState({
                        remove: item
                    })
                } else if (option === "Pause") {
                    if (item.level === "campaign") {
                        this.functions.pauseCampaign(item);
                    } else if (item.level === "adgroup") {
                        if (item.scheduleActivated) {
                            this.setState({
                                schedule_warning: true,
                                pause_campaign_schedule: { id: 1, name: "Remove schedule settings", value: "remove_schedule" },
                                pause_item: item
                            })
                        } else {
                            this.functions.pauseAdgroup(item);
                        }
                        //this.functions.pauseAdgroup(item);
                    } else if (item.level === "ad") {
                        this.functions.pauseAd(item);
                    }
                } else if (option === "Activate") {
                    if (item.level === "campaign") {
                        this.functions.activateCampaign(item);
                    } else if (item.level === "adgroup") {
                        this.functions.activateAdgroup(item);
                    } else if (item.level === "ad") {
                        this.functions.activateAd(item);
                    }
                } else {
                    this.open.linkedin(item);
                }
            } else if (item.channel === "twitter") {
                if (option === "Remove reference") {
                    this.promisedSetState({
                        remove_ref: item
                    })
                } else if (option === "Delete") {
                    this.promisedSetState({
                        remove: item
                    })
                } else if (option === "Pause") {
                    if (item.level === "campaign") {
                        this.functions.pauseCampaign(item);
                    } else if (item.level === "adgroup") {
                        if (item.scheduleActivated) {
                            this.setState({
                                schedule_warning: true,
                                pause_campaign_schedule: { id: 1, name: "Remove schedule settings", value: "remove_schedule" },
                                pause_item: item
                            })
                        } else {
                            this.functions.pauseAdgroup(item);
                        }
                    } else if (item.level === "ad") {
                        this.functions.pauseAd(item);
                    }
                } else if (option === "Activate") {
                    if (item.level === "campaign") {
                        this.functions.activateCampaign(item);
                    } else if (item.level === "adgroup") {
                        this.functions.activateAdgroup(item);
                    }
                } else {
                    this.open.twitter(item);
                }
            } else if (item.channel === "tiktok") {
                if (option === "Remove reference") {
                    this.promisedSetState({
                        remove_ref: item
                    })
                } else if (option === "Edit") {
                    if (item.level === "ad") {
                        this.promisedSetState({
                            open_ad: item
                        });
                    } else {
                        if (item.level === "campaign" && !item.campaign_data) {
                            await this.promisedSetState({
                                loading_partial: true
                            });
                            await this.functions.getCampaignsData({ channel: item.channel, id: item.campaignId });
                            await this.promisedSetState({
                                loading_partial: false
                            });
                            this.promisedSetState({
                                edit: JSON.parse(JSON.stringify(this.state.campaigns.filter((campaign) => { return item.campaignId === campaign.campaignId })[0]))
                            })
                        } else {
                            this.promisedSetState({
                                edit: item
                            })
                        }
                    }
                } else if (option === "Add new Ad") {
                    this.functions.createPartialWizard(item);
                } else if (option === "Delete") {
                    this.promisedSetState({
                        remove: item
                    })
                } else if (option === "Pause") {
                    if (item.level === "campaign") {
                        if (item.scheduleActivated) {
                            this.setState({
                                schedule_warning: true,
                                pause_campaign_schedule: { id: 1, name: "Remove schedule settings", value: "remove_schedule" },
                                pause_item: item
                            })
                        } else {
                            this.functions.pauseCampaign(item);
                        }
                    } else if (item.level === "adgroup") {
                        if (item.scheduleActivated) {
                            this.setState({
                                schedule_warning: true,
                                pause_campaign_schedule: { id: 1, name: "Remove schedule settings", value: "remove_schedule" },
                                pause_item: item
                            })
                        } else {
                            this.functions.pauseAdgroup(item);
                        }
                    } else if (item.level === "ad") {
                        this.functions.pauseAd(item);
                    }
                } else if (option === "Activate") {
                    if (item.level === "campaign") {
                        this.functions.activateCampaign(item);
                    } else if (item.level === "adgroup") {
                        this.functions.activateAdgroup(item);
                    } else if (item.level === "ad") {
                        this.functions.activateAd(item);
                    }
                } else {
                    this.open.tiktok(item);
                }
            } else if (item.channel === "snapchat") {
                if (option === "Remove reference") {
                    this.promisedSetState({
                        remove_ref: item
                    })
                } else if (option === "Edit") {
                    if (item.level === "ad") {
                        this.promisedSetState({
                            open_ad: item
                        });
                    } else {
                        if (item.level === "campaign" && !item.campaign_data) {
                            await this.promisedSetState({
                                loading_partial: true
                            });
                            await this.functions.getCampaignsData({ channel: item.channel, id: item.campaignId });
                            await this.promisedSetState({
                                loading_partial: false
                            });
                            this.promisedSetState({
                                edit: JSON.parse(JSON.stringify(this.state.campaigns.filter((campaign) => { return item.campaignId === campaign.campaignId })[0]))
                            })
                        } else {
                            this.promisedSetState({
                                edit: item
                            })
                        }
                    }
                } else if (option === "Add new Ad") {
                    this.functions.createPartialWizard(item);
                } else if (option === "Delete") {
                    this.promisedSetState({
                        remove: item
                    })
                } else if (option === "Pause") {
                    if (item.level === "campaign") {
                        if (!item.campaign_data) {
                            await this.promisedSetState({
                                loading_partial: true
                            });
                            await this.functions.getCampaignsData({ channel: item.channel, id: item.campaignId });
                            await this.promisedSetState({
                                loading_partial: false
                            });
                            this.functions.pauseCampaign(JSON.parse(JSON.stringify(this.state.campaigns.filter((campaign) => { return item.campaignId === campaign.campaignId })[0])));
                        } else {
                            this.functions.pauseCampaign(item);
                        }
                    } else if (item.level === "adgroup") {
                        if (item.scheduleActivated) {
                            this.setState({
                                schedule_warning: true,
                                pause_campaign_schedule: { id: 1, name: "Remove schedule settings", value: "remove_schedule" },
                                pause_item: item
                            })
                        } else {
                            this.functions.pauseAdgroup(item);
                        }
                    } else if (item.level === "ad") {
                        this.functions.pauseAd(item);
                    }
                } else if (option === "Activate") {
                    if (item.level === "campaign") {
                        if (!item.campaign_data) {
                            await this.promisedSetState({
                                loading_partial: true
                            });
                            await this.functions.getCampaignsData({ channel: item.channel, id: item.campaignId });
                            await this.promisedSetState({
                                loading_partial: false
                            });
                            this.functions.activateCampaign(JSON.parse(JSON.stringify(this.state.campaigns.filter((campaign) => { return item.campaignId === campaign.campaignId })[0])));
                        } else {
                            this.functions.activateCampaign(item);
                        }
                    } else if (item.level === "adgroup") {
                        this.functions.activateAdgroup(item);
                    } else if (item.level === "ad") {
                        this.functions.activateAd(item);
                    }
                } else {
                    this.open.snapchat(item);
                }
            } else if (item.channel === "bing") {
                if (option === "Delete") {
                    this.promisedSetState({
                        remove: item
                    })
                } else if (option === "Pause") {
                    if (this.state.tab.value === 'campaigns') {
                        if (item.scheduleActivated) {
                            this.setState({
                                schedule_warning: true,
                                pause_campaign_schedule: { id: 1, name: "Remove schedule settings", value: "remove_schedule" },
                                pause_item: item
                            })
                        } else {
                            this.functions.pauseCampaign(item);
                        }
                    }
                } else if (option === "Activate") {
                    if (this.state.tab.value === 'campaigns') {
                        this.functions.activateCampaign(item);
                    }
                } else if (option === "Remove reference") {
                    this.promisedSetState({
                        remove_ref: item
                    })
                }
                /*
                if (option === "Pause" || option === "Activate") {
                    const name = `update${this.state.tab.name}Status`;
                    this.functions[name](item, option);
                }*/
            }
        },
        getIdFromPath: () => {
            try {
                return window.location.pathname.match(/[0-9a-z]{20,}/g)[0];
            } catch (error) {
                return null
            }
        },
        createWizard: async (schedule = false) => {
            await this.promisedSetState({ loading_wizard: true, create_wizard: false });
            try {
                let response = await this.calls.createWizard({
                    order: this.state.order.id,
                    name: this.state.wizard_name,
                    schedule: schedule,
                    ...(this.state.order.budgetSplit && this.state.order.budgetSplit.value && { bingSync: this.state.order.budgetSplit.value })
                });
                this.props.history.push("/v2/campaigns/new/channels?draft=" + response.data.id);
            } catch (error) {
                console.log(error);
            }
            await this.promisedSetState({ loading_wizard: false });
        },
        createWizardFromTemplate: async (data, schedule = false) => {
            await this.promisedSetState({ loading_wizard: true, create_wizard: false });
            try {
                let response = await this.calls.createWizard({
                    order: this.state.order.id,
                    name: this.state.wizard_name,
                    template_new: data,
                    schedule: schedule,
                    ...(this.state.order.budgetSplit && this.state.order.budgetSplit.value && { bingSync: this.state.order.budgetSplit.value })
                });
                this.props.history.push("/v2/campaigns/new/channels?draft=" + response.data.id);
            } catch (error) {
                console.log(error);
            }
            await this.promisedSetState({ loading_wizard: false });
        },
        removeCampaignReference: async () => {
            let temp = JSON.parse(JSON.stringify(this.state.remove_ref));
            await this.promisedSetState({ remove_ref: null });
            await this.promisedSetState({ remove_name: "" });
            this.state.campaigns.map((item) => { if (item.internal === temp.internal) { item.loading = true } return item });
            await this.promisedSetState({ campaigns: this.state.campaigns });
            let body = {}
            try {
                await this.calls.removeCampaignReference(temp.internal, body);
                await this.promisedSetState({ campaigns: this.state.campaigns.filter((item) => { return item.internal !== temp.internal }) });
            } catch (error) {
                this.state.campaigns.map((item) => { if (item.internal === temp.internal) { item.loading = false } return item });
                await this.promisedSetState({ campaigns: this.state.campaigns });
            }
            this.props.updateStatistics();
        },
        removeCampaign: async () => {
            let temp = JSON.parse(JSON.stringify(this.state.remove));
            await this.promisedSetState({ remove: null });
            await this.promisedSetState({ remove_name: "" });
            this.state.campaigns.map((item) => { if (item.internal === temp.internal) { item.loading = true } return item });
            await this.promisedSetState({ campaigns: this.state.campaigns });
            let body = {}
            try {
                await this.calls.removeCampaign(temp.internal, body);
                await this.promisedSetState({ campaigns: this.state.campaigns.filter((item) => { return item.internal !== temp.internal }) });
            } catch (error) {
                this.state.campaigns.map((item) => { if (item.internal === temp.internal) { item.loading = false } return item });
                await this.promisedSetState({ campaigns: this.state.campaigns });
            }
            this.props.updateStatistics();
        },
        removeAdgroup: async () => {
            let body = {}
            this.calls.removeAdgroup(this.state.remove, body)
                .then(async response => {
                    await this.promisedSetState({
                        adgroups: this.state.adgroups.filter((item) => { return item.id !== this.state.remove.id }),
                        remove: null,
                        remove_name: "",
                    });
                })
        },
        removeAd: async () => {
            let body = {}
            this.calls.removeAd(this.state.remove, body)
                .then(async response => {
                    await this.promisedSetState({
                        ads: this.state.ads.filter((item) => { return item.id !== this.state.remove.id }),
                        remove: null,
                        remove_name: ""
                    });
                })
        },
        exportCSVFile: () => {

            let csv_string = "";

            //SET HEADER SPACES
            let header_spaces = ',';

            csv_string += 'SEARCH' + header_spaces;
            csv_string += 'TRIGGERED KEYWORD' + header_spaces;
            csv_string += 'TYPE' + header_spaces;
            csv_string += 'IMPRESSIONS' + header_spaces;
            csv_string += 'CLICK' + header_spaces;
            csv_string += 'CTR' + header_spaces;
            csv_string += 'SPEND' + header_spaces;
            csv_string += 'CONVERSIONS' + header_spaces;

            //NEXT LINE
            csv_string += '\r\n\t';

            this.state.search_terms.map((item) => {

                csv_string += item.name + ',';
                csv_string += item.keyword + ',';
                csv_string += item.keyword_type + ',';
                csv_string += item.impressions + ',';
                csv_string += item.clicks + ',';
                csv_string += item.ctr + ',';
                csv_string += item.spend + ',';
                csv_string += item.conversions + ',';

                //NEXT LINE
                csv_string += '\r\n\t';
            });

            //NEXT LINE
            csv_string += '\r\n\t';
            csv_string += '\r\n\t';

            var exportedFilenmae = 'campaign.csv';

            var blob = new Blob([csv_string], { type: 'text/csv;charset=utf-8;' });
            var link = document.createElement("a");
            if (link.download !== undefined) {
                var url = URL.createObjectURL(blob);
                link.setAttribute("href", url);
                link.setAttribute("download", exportedFilenmae);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        },
        handleCreateChangelog: async (id, action, level, reference_id, operations, channel) => {
            try {
                let buff = null;
                let initial_data = null;
                let updated_data = null;
                let keyword_level = null;
                let keyword_item_name = null;
                let item_id = null;


                // HANDLE INITIAL DATA
                if (level === "campaign") {
                    initial_data = this.state.campaigns
                } else if (level === "adgroup") {
                    if (action === "remove") {
                        initial_data = this.state.temp_adgroups
                    } else {
                        initial_data = this.state.adgroups
                    }
                } else if (level === "ad") {
                    if (action === "remove") {
                        initial_data = this.state.temp_ads
                    } else {
                        initial_data = this.state.ads
                    }
                } else if (level === "keyword") {
                    if (action === "added" || action === "remove") {
                        initial_data = { keyword: "", matchType: "", status: "" }
                    } else {
                        initial_data = this.state.keywords
                    }
                }

                if (initial_data) {
                    if (reference_id) {
                        buff = initial_data.find(item => item.internal === id)
                    } else if (level === "keyword") {
                        buff = initial_data
                    } else {
                        buff = initial_data.find(item => item.id === id)
                    }
                }

                // HANDLE UPDATED DATA
                // TYPE KEYWORD
                if (level === "keyword") {
                    if (action === "added") {
                        let ops = Array.isArray(operations) ? operations : [operations];
                        ops.forEach(operation => {
                            if (operation.create.hasOwnProperty("adGroup")) {
                                keyword_level = "adgroup"
                                keyword_item_name = this.state.adgroups.filter(adgroup => adgroup.id === id)[0].name;
                            } else {
                                keyword_level = "campaign"
                                keyword_item_name = this.state.campaigns.filter(campaign => campaign.id === id)[0].name;
                            }
                        })
                        let keywords = ops.map(item => ({ ...item.create.keyword, negative: item.create.negative }));
                        let reduced = keywords.reduce((acc, keyword) => {
                            acc.keyword = keyword.text;
                            acc.matchType = acc.matchType ? `${acc.matchType}, ${keyword.matchType}` : keyword.matchType;
                            acc.matchType = [...new Set(acc.matchType.split(', '))].join(', ');
                            acc.type = keyword.negative ? "negative" : "positive";
                            return acc;
                        }, {});
                        updated_data = { ...reduced, status: action };
                    } else if (action === "remove") {
                        let ops = Array.isArray(operations) ? operations : [operations];
                        ops.forEach(operation => {
                            // console.log(operation)
                            if (operation.resourceName.includes("adGroupCriteria")) {
                                keyword_level = "adgroup"
                                keyword_item_name = operation.adgroup
                                item_id = operation.adgroup_id ? operation.adgroup_id : null
                            } else {
                                keyword_level = "campaign"
                                keyword_item_name = operation.campaign
                                item_id = operation.campaign_id ? operation.campaign_id : null
                            }
                            updated_data = { keyword: operation.name, matchType: operation.keyword_type, status: action };
                        })


                    }
                } else {
                    // OTHER TYPES
                    // if (level === "campaign") {
                    //     if (action === "update") {
                    //         updated_data = this.state.temp_camp_data[channel][0]
                    //     } else {
                    updated_data = buff
                    updated_data = { ...updated_data, status: action };
                    // }
                    // }
                }

                if (item_id === null) {
                    item_id = id;
                }
            } catch (error) { }
        }
    }

    google = {
        getKeywords: async (init) => {
            try {
                //if (this.state.tab.value === "keywords") {
                this.state.pagination.positive_pagination = init ? "1" : this.state.pagination.positive_pagination;
                //} else if (this.state.tab.value === "negative_keywords") {
                //  this.state.pagination.negative_pagination = init ? "1" : this.state.pagination.negative_pagination;
                //}
                await this.promisedSetState({
                    keywords: init ? [] : this.state.keywords,
                    //...(this.state.tab.value === "negative_keywords") && { negative_keywords: init ? [] : this.state.negative_keywords },
                    pagination: this.state.pagination,
                    loading_partial: true
                });
                if (!this.state.adgroups.length) {
                    this.functions.getAdgroups();
                }
                let ids = this.state.selected_campaigns.filter(item => item.channel === "google").flatMap(item => item.id);
                if (ids.length < 1) {
                    await this.promisedSetState({
                        loading_partial: false
                    });
                    return false;
                }
                ids = "&campaign_ids[]=" + (ids.join("&campaign_ids[]="));
                let adgroup_ids = this.state.selected_adgroups.filter(item => item.channel === "google").flatMap(item => item.id);
                if (adgroup_ids.length > 0) {
                    ids = "&adgroup_ids[]=" + (adgroup_ids.join("&adgroup_ids[]="));
                }
                let response = await this.calls.getKeywords("google", "&positive=true", null, ids);
                response.data = response.data.map((item) => {
                    if (!item.list) {
                        return {
                            channel: 'google',
                            adgroup: item.adGroup.name,
                            adgroup_id: item.adGroup.id,
                            campaign: item.campaign.name,
                            id: item.adGroupCriterion.resourceName,
                            resourceName: item.adGroupCriterion.resourceName,
                            name: item.adGroupCriterion.keyword.text,
                            status: item.adGroupCriterion.status ? item.adGroupCriterion.status.toLowerCase() : '-',
                            approval_status: item.adGroupCriterion.approvalStatus ? item.adGroupCriterion.approvalStatus.toLowerCase() : '-',
                            keyword_type: item.adGroupCriterion.keyword.matchType,
                            impressions: item.metrics.impressions,
                            spend: parseInt(item.metrics.costMicros) / 1000000,
                            ctr: (item.metrics.ctr).toFixed(2),
                            clicks: item.metrics.clicks,
                            conversions: item.metrics.conversions,
                            ...(item.metrics.averageCpc) && { cpc: (parseInt(item.metrics.averageCpc) / 1000000).toFixed(2) },
                            ...(item.metrics.averageCpm) && { cpm: (parseInt(item.metrics.averageCpm) / 1000000).toFixed(2) },
                            metrics: item.metrics,
                        };
                    } else {
                        return {
                            channel: 'google',
                            list: true,
                            keywords: item.keywords,
                            id: item.resourceName,
                            resourceName: item.resourceName,
                            name: item.name,
                            status: item.status.toLowerCase(),
                            approval_status: '-',
                            keyword_type: "LIST",
                            impressions: "-",
                            spend: "-",
                            ctr: "-",
                            clicks: "-",
                            conversions: "-",
                            metrics: {},
                        };
                    }
                });
                //if (this.state.tab.value === "keywords") {
                this.state.pagination.positive_pagination = response.pagination;
                // } else if (this.state.tab.value === "negative_keywords") {
                //   this.state.pagination.negative_pagination = response.pagination;
                // }
                await this.promisedSetState({
                    keywords: this.state.keywords.concat(response.data),
                    //...(this.state.tab.value === "negative_keywords") && { negative_keywords: this.state.negative_keywords.concat(response.data) },
                    loading_partial: false,
                    pagination: this.state.pagination
                });
            } catch (error) {
                await this.promisedSetState({
                    error: true,
                    loading_partial: false
                });
            }
        },
        getNegativeKeywords: async (init) => {
            try {

                this.state.pagination.negative_pagination = init ? "1" : this.state.pagination.negative_pagination;

                await this.promisedSetState({
                    negative_keywords: init ? [] : this.state.negative_keywords,
                    pagination: this.state.pagination,
                    loading_partial: true
                });

                let ids = this.state.selected_campaigns.filter(item => item.channel === "google").flatMap(item => item.id);
                if (ids.length < 1) {
                    await this.promisedSetState({
                        loading_partial: false
                    });
                    return false;
                }
                ids = "&campaign_ids[]=" + (ids.join("&campaign_ids[]="));

                let adgroup_ids = this.state.selected_adgroups.filter(item => item.channel === "google").flatMap(item => item.id);
                if (adgroup_ids.length > 0) {
                    ids = "&adgroup_ids[]=" + (adgroup_ids.join("&adgroup_ids[]="));
                }
                let response = await this.calls.getKeywords("google", "&negative=true", true, ids);
                response.data = response.data.map((item) => {
                    if (!item.list) {
                        return {
                            channel: 'google',
                            adgroup: item.adGroup.name,
                            adgroup_id: item.adGroup.id,
                            campaign: item.campaign.name,
                            id: item.adGroupCriterion.resourceName,
                            resourceName: item.adGroupCriterion.resourceName,
                            name: item.adGroupCriterion.keyword.text,
                            status: item.adGroupCriterion.status ? item.adGroupCriterion.status.toLowerCase() : '-',
                            approval_status: item.adGroupCriterion.approvalStatus ? item.adGroupCriterion.approvalStatus.toLowerCase() : '-',
                            keyword_type: item.adGroupCriterion.keyword.matchType,
                            impressions: item.metrics.impressions,
                            spend: parseInt(item.metrics.costMicros) / 1000000,
                            ctr: (item.metrics.ctr).toFixed(2),
                            clicks: item.metrics.clicks,
                            conversions: item.metrics.conversions,
                            ...(item.metrics.averageCpc) && { cpc: (parseInt(item.metrics.averageCpc) / 1000000).toFixed(2) },
                            ...(item.metrics.averageCpm) && { cpm: (parseInt(item.metrics.averageCpm) / 1000000).toFixed(2) },
                            metrics: item.metrics,
                        };
                    } else {
                        return {
                            channel: 'google',
                            list: true,
                            keywords: item.keywords,
                            id: item.resourceName,
                            resourceName: item.resourceName,
                            name: item.name,
                            status: item.status.toLowerCase(),
                            approval_status: '-',
                            keyword_type: "LIST",
                            impressions: "-",
                            spend: "-",
                            ctr: "-",
                            clicks: "-",
                            conversions: "-",
                            metrics: {},
                        };
                    }
                });

                this.state.pagination.negative_pagination = response.pagination;

                await this.promisedSetState({
                    negative_keywords: this.state.negative_keywords.concat(response.data),
                    loading_partial: false,
                    pagination: this.state.pagination
                });
            } catch (error) {
                await this.promisedSetState({
                    error: true,
                    loading_partial: false
                });
            }
        },
        getCampaignNegativeKeywords: async (init) => {
            try {

                this.state.pagination.campaign_negative_pagination = init ? "1" : this.state.pagination.campaign_negative_pagination;

                await this.promisedSetState({
                    negative_keywords_campaign: init ? [] : this.state.negative_keywords_campaign,
                    pagination: this.state.pagination,
                    loading_partial: true
                });

                let ids = this.state.selected_campaigns.filter(item => item.channel === "google").flatMap(item => item.id);
                if (ids.length < 1) {
                    await this.promisedSetState({
                        loading_partial: false
                    });
                    return false;
                }
                ids = "&campaign_ids[]=" + (ids.join("&campaign_ids[]="));

                let response = await this.calls.getCampaignKeywords("google", "&negative=true", true, ids);
                response.data = response.data.map((item) => {

                    if (!item.list) {
                        return {
                            channel: 'google',
                            adgroup: "-",
                            adgroup_id: "",
                            campaign: item.campaign.name,
                            id: item.campaignCriterion.resourceName,
                            resourceName: item.campaignCriterion.resourceName,
                            name: item.campaignCriterion.keyword.text,
                            status: 'enabled',
                            approval_status: '-',
                            keyword_type: item.campaignCriterion.keyword.matchType,
                            impressions: "-",
                            spend: "-",
                            ctr: "-",
                            clicks: "-",
                            conversions: "-",
                            metrics: {},
                        };
                    } else {
                        return {
                            channel: 'google',
                            list: true,
                            keywords: item.keywords,
                            id: item.resourceName,
                            resourceName: item.resourceName,
                            name: item.name,
                            status: item.status.toLowerCase(),
                            approval_status: '-',
                            keyword_type: "LIST",
                            impressions: "-",
                            spend: "-",
                            ctr: "-",
                            clicks: "-",
                            conversions: "-",
                            metrics: {},
                        };
                    }
                });

                this.state.pagination.campaign_negative_pagination = response.pagination;

                await this.promisedSetState({
                    negative_keywords_campaign: this.state.negative_keywords_campaign.concat(response.data),
                    loading_partial: false,
                    pagination: this.state.pagination
                });
            } catch (error) {
                await this.promisedSetState({
                    error: true,
                    loading_partial: false
                });
            }
        },
        getSearchterms: async (init) => {
            try {
                this.state.pagination.search_term = init ? "1" : this.state.pagination.search_term;
                await this.promisedSetState({
                    search_terms: init ? [] : this.state.search_terms,
                    pagination: this.state.pagination,
                    loading_partial: true
                });
                let ids = this.state.selected_campaigns.filter(item => item.channel === "google").flatMap(item => item.id);
                if (ids.length < 1) {
                    await this.promisedSetState({
                        loading_partial: false
                    });
                    return false;
                }
                ids = "&campaign_ids[]=" + (ids.join("&campaign_ids[]="));
                let adgroup_ids = this.state.selected_adgroups.filter(item => item.channel === "google").flatMap(item => item.id);
                if (adgroup_ids.length > 0) {
                    ids = "&adgroup_ids[]=" + (adgroup_ids.join("&adgroup_ids[]="));
                }
                let response = await this.calls.getSearchterms(null, ids);
                response.data = response.data.map((item) => {
                    let data = {
                        id: item.searchTermView.resourceName,
                        searchTermView: item.searchTermView,
                        adGroup: item.adGroup,
                        adgroup_id: item.adGroup.id,
                        campaign: item.campaign,
                        channel: 'google',
                        name: item.searchTermView.searchTerm,
                        //keyword: item.segments.keyword.info.text,
                        //keyword_type: item.segments.keyword.info.matchType,
                        impressions: item.metrics.impressions,
                        spend: parseInt(item.metrics.costMicros) / 1000000,
                        ctr: (item.metrics.ctr).toFixed(2),
                        clicks: item.metrics.clicks,
                        conversions: item.metrics.conversions,
                        selected: false,
                        metrics: item.metrics
                    }
                    if (this.state.campaign.campaign_type_property && this.state.campaign.campaign_type_property == "shopping") {
                        data.keyword = item.searchTermView.searchTerm;
                        data.keyword_type = item.segments.searchTermMatchType;
                    } else {
                        data.keyword = item.segments.keyword.info.text;
                        data.keyword_type = item.segments.keyword.info.matchType;
                    }
                    return data;
                });
                this.state.pagination.search_term = response.pagination;
                await this.promisedSetState({
                    pagination: this.state.pagination,
                    search_terms: this.state.search_terms.concat(response.data),
                    loading_partial: false
                });
            } catch (error) {
                console.log(error, "ERROR-----");
                await this.promisedSetState({
                    error: true,
                    loading_partial: false
                });
            }
        },
        addPositiveKeywordAdgroup: async (item) => {
            return new Promise(async (resolve) => {
                if (this.state.selected_adgroup.id !== 0 || item) {
                    console.log("HERE PLEASE NOW THANKS RAIN", this.state)

                    if (this.state.keyword_multi_text && Array.isArray(this.state.keyword_multi_text) && this.state.keyword_multi_text.length > 0) {
                        try {
                            await this.promisedSetState({ selected_adgroup_error: false });
                            let operations = [];
                            if (this.state.new_multi_input) {
                                let buffer = [];
                                buffer = buffer.concat(JSON.parse(JSON.stringify(this.state.keyword_multi_text))[0].name.split("\n"));
                                buffer = buffer.join().split(",");
                                this.state.keyword_multi_text = buffer.map((item) => {
                                    return {
                                        id: Math.floor((Math.random() * 9999999999) + 1),
                                        name: item.trim()
                                    }
                                }).filter((item) => { return item.name !== "" });
                            }

                            if (this.state.customMatch) {
                                this.state.keyword_multi_text.map((keyword) => {
                                    let exact_reg = /\[(.*?)\]/s;
                                    let phrase_reg = /\"(.*?)\"/s;
                                    let exact = keyword.name.match(exact_reg);
                                    let phrase = keyword.name.match(phrase_reg);

                                    let operation = {
                                        create: {
                                            adGroup: keyword.adGroup ? keyword.adGroup.resourceName : this.state.selected_adgroup.resourceName,
                                            type: "KEYWORD",
                                            status: "ENABLED",
                                            negative: false,
                                            keyword: {
                                            }
                                        }
                                    }
                                    if (exact) {
                                        operation.create.keyword.text = exact[1];
                                        operation.create.keyword.matchType = "EXACT";
                                    } else if (phrase) {
                                        operation.create.keyword.text = phrase[1];
                                        operation.create.keyword.matchType = "PHRASE";
                                    } else {
                                        operation.create.keyword.text = keyword.name;
                                        operation.create.keyword.matchType = "BROAD";
                                    }
                                    operations.push(operation);
                                });
                            } else {
                                if (this.state.keyword_exact) {
                                    this.state.keyword_multi_text.map((keyword) => {
                                        operations.push(
                                            {
                                                create: {
                                                    adGroup: keyword.adGroup ? keyword.adGroup.resourceName : this.state.selected_adgroup.resourceName,
                                                    type: "KEYWORD",
                                                    status: "ENABLED",
                                                    negative: false,
                                                    keyword: {
                                                        text: keyword.name,
                                                        matchType: "EXACT"
                                                    }
                                                }
                                            }
                                        );
                                    });
                                }
                                if (this.state.keyword_phrase) {
                                    this.state.keyword_multi_text.map((keyword) => {
                                        operations.push(
                                            {
                                                create: {
                                                    adGroup: keyword.adGroup ? keyword.adGroup.resourceName : this.state.selected_adgroup.resourceName,
                                                    type: "KEYWORD",
                                                    status: "ENABLED",
                                                    negative: false,
                                                    keyword: {
                                                        text: keyword.name,
                                                        matchType: "PHRASE"
                                                    }
                                                }
                                            }
                                        );
                                    });
                                }
                                if (this.state.keyword_broad) {
                                    this.state.keyword_multi_text.map((keyword) => {
                                        operations.push(
                                            {
                                                create: {
                                                    adGroup: keyword.adGroup ? keyword.adGroup.resourceName : this.state.selected_adgroup.resourceName,
                                                    type: "KEYWORD",
                                                    status: "ENABLED",
                                                    negative: false,
                                                    keyword: {
                                                        text: keyword.name,
                                                        matchType: "BROAD"
                                                    }
                                                }
                                            }
                                        );
                                    });
                                }
                            }
                            // GET SELECTED ITEMS WITH TEMP_ITEMS OR SOMETHING, PUSH TO INITIAL DATA
                            console.log("HERE BELOW THANKS")
                            await this.calls.createAdgroupCriterion({
                                operations: operations
                            });
                        } catch (error) { }
                        await this.promisedSetState({ keyword_multi_text: false, success: true, add_keyword: false, new_multi_input: false, customMatch: false, temp_multi_edit: null });
                    } else {
                        try {
                            let operations = [];
                            if (this.state.keyword_exact) {
                                operations.push(
                                    {
                                        create: {
                                            adGroup: this.state.edit_positive.adGroup.resourceName,
                                            type: "KEYWORD",
                                            status: "ENABLED",
                                            negative: false,
                                            keyword: {
                                                text: this.state.keyword_text,
                                                matchType: "EXACT"
                                            }
                                        }
                                    }
                                );
                            }
                            if (this.state.keyword_phrase) {
                                operations.push(
                                    {
                                        create: {
                                            adGroup: this.state.edit_positive.adGroup.resourceName,
                                            type: "KEYWORD",
                                            status: "ENABLED",
                                            negative: false,
                                            keyword: {
                                                text: this.state.keyword_text,
                                                matchType: "PHRASE"
                                            }
                                        }
                                    }
                                );
                            }
                            if (this.state.keyword_broad) {
                                operations.push(
                                    {
                                        create: {
                                            adGroup: this.state.edit_positive.adGroup.resourceName,
                                            type: "KEYWORD",
                                            status: "ENABLED",
                                            negative: false,
                                            keyword: {
                                                text: this.state.keyword_text,
                                                matchType: "BROAD"
                                            }
                                        }
                                    }
                                );
                            }
                            console.log("editpos", this.state.edit_positive)
                            console.log("state", this.state)
                            await this.calls.createAdgroupCriterion({
                                operations: operations,
                            });
                        } catch (error) { }
                    }
                    this.google.getKeywords(true);
                } else {
                    await this.promisedSetState({ selected_adgroup_error: true });
                }
                resolve();
            });
        },
        addNegativeKeywordAdgroup: async (item) => {
            return new Promise(async (resolve) => {
                console.log("=====HERE=======" + item, this.state)
                if (item || this.state.selected_adgroup.id !== 0 || this.state.selected_campaign.id !== 0 || (this.state.search_term && this.state.search_term.adGroup && this.state.search_term.adGroup.resourceName) || (this.state.keyword_multi_text && this.state.keyword_multi_text.filter((keyword) => { return keyword.adGroup }).length > 0)) {

                    let operations = [];
                    if (this.state.keyword_multi_text && Array.isArray(this.state.keyword_multi_text) && this.state.keyword_multi_text.length > 0) {
                        if (!item) {
                            await this.promisedSetState({ search_terms: this.state.search_terms.map((item) => { item.selected = false; return item }), create_keyword: false });
                        }
                        try {
                            if (this.state.new_multi_input) {
                                let buffer = [];
                                buffer = buffer.concat(JSON.parse(JSON.stringify(this.state.keyword_multi_text))[0].name.split("\n"));
                                buffer = buffer.join().split(",");
                                this.state.keyword_multi_text = buffer.map((item) => {
                                    return {
                                        id: Math.floor((Math.random() * 9999999999) + 1),
                                        name: item.trim()
                                    }
                                }).filter((item) => { return item.name !== "" });
                            }
                            if (this.state.keyword_exact) {
                                this.state.keyword_multi_text.map((keyword) => {
                                    let exact_buffer = {
                                        create: {
                                            type: "KEYWORD",
                                            status: "ENABLED",
                                            negative: true,
                                            keyword: {
                                                text: keyword.name,
                                                matchType: "EXACT"
                                            }
                                        }
                                    }

                                    if ((this.state.selected_campaign.id !== 0 || this.state.selected_adgroup.id !== 0) && !keyword.adGroup && !keyword.campaign) {
                                        if ((this.state.selected_campaign.id !== 0 && this.state.selected_adgroup.id === 0)) {
                                            exact_buffer.create.campaign = "customers/" + this.state.selected_campaign.account + "/campaigns/" + this.state.selected_campaign.id;
                                        } else if (this.state.selected_adgroup.id !== 0 && this.state.selected_campaign.id === 0) {
                                            exact_buffer.create.adGroup = this.state.selected_adgroup.resourceName;
                                        }
                                    } else if (keyword.adGroup || keyword.campaign) {
                                        if (this.state.add_negative_level.value === "campaign") {
                                            exact_buffer.create.campaign = keyword.campaign.resourceName;
                                        } else if (this.state.add_negative_level.value === "adgroup") {
                                            exact_buffer.create.adGroup = keyword.adGroup.resourceName;
                                        }
                                    }
                                    operations.push(exact_buffer);
                                });
                            }
                            if (this.state.keyword_phrase) {
                                this.state.keyword_multi_text.map((keyword) => {
                                    let phrase_buffer = {
                                        create: {
                                            type: "KEYWORD",
                                            status: "ENABLED",
                                            negative: true,
                                            keyword: {
                                                text: keyword.name,
                                                matchType: "PHRASE"
                                            }
                                        }
                                    }
                                    if ((this.state.selected_campaign.id !== 0 || this.state.selected_adgroup.id !== 0) && !keyword.adGroup && !keyword.campaign) {
                                        if ((this.state.selected_campaign.id !== 0 && this.state.selected_adgroup.id === 0)) {
                                            phrase_buffer.create.campaign = "customers/" + this.state.selected_campaign.account + "/campaigns/" + this.state.selected_campaign.id;
                                        } else if (this.state.selected_adgroup.id !== 0 && this.state.selected_campaign.id === 0) {
                                            phrase_buffer.create.adGroup = this.state.selected_adgroup.resourceName;
                                        }
                                    } else if (keyword.adGroup || keyword.campaign) {
                                        if (this.state.add_negative_level.value === "campaign") {
                                            phrase_buffer.create.campaign = keyword.campaign.resourceName;
                                        } else if (this.state.add_negative_level.value === "adgroup") {
                                            phrase_buffer.create.adGroup = keyword.adGroup.resourceName;
                                        }
                                    }
                                    operations.push(phrase_buffer);
                                });
                            }
                            if (this.state.keyword_broad) {
                                this.state.keyword_multi_text.map((keyword) => {

                                    let broad_buffer = {
                                        create: {
                                            type: "KEYWORD",
                                            status: "ENABLED",
                                            negative: true,
                                            keyword: {
                                                text: keyword.name,
                                                matchType: "BROAD"
                                            }
                                        }
                                    }
                                    if ((this.state.selected_campaign.id !== 0 || this.state.selected_adgroup.id !== 0) && !keyword.adGroup && !keyword.campaign) {
                                        if ((this.state.selected_campaign.id !== 0 && this.state.selected_adgroup.id === 0)) {
                                            broad_buffer.create.campaign = "customers/" + this.state.selected_campaign.account + "/campaigns/" + this.state.selected_campaign.id;
                                        } else if (this.state.selected_adgroup.id !== 0 && this.state.selected_campaign.id === 0) {
                                            broad_buffer.create.adGroup = this.state.selected_adgroup.resourceName;
                                        }
                                    } else if (keyword.adGroup || keyword.campaign) {
                                        if (this.state.add_negative_level.value === "campaign") {
                                            broad_buffer.create.campaign = keyword.campaign.resourceName;
                                        } else if (this.state.add_negative_level.value === "adgroup") {
                                            broad_buffer.create.adGroup = keyword.adGroup.resourceName;
                                        }
                                    }
                                    operations.push(broad_buffer);
                                });
                            }

                            if (Object.keys(operations[0].create).includes("adGroup") && !Object.keys(operations[0].create).includes("campaign")) {
                                await this.calls.createAdgroupCriterion({
                                    operations: operations
                                });
                            } else if (Object.keys(operations[0].create).includes("campaign") && !Object.keys(operations[0].create).includes("adGroup")) {
                                await this.calls.createCampaignCriterion({
                                    operations: operations
                                });
                            }
                        } catch (error) {
                            console.log(error, "ERROR")
                        }
                        if (!item) {
                            await this.promisedSetState({ keyword_multi_text: false, success: true, add_keyword: false, new_multi_input: false });
                        }
                    } else {
                        if (!item) {
                            this.state.search_terms.map((inner_item) => { if (inner_item.id === this.state.search_term.id) { inner_item.loading = true } return inner_item });
                            await this.promisedSetState({ search_terms: this.state.search_terms, create_keyword: false });
                        }
                        try {
                            if (this.state.keyword_exact) {
                                let exact_buffer = {
                                    create: {
                                        type: "KEYWORD",
                                        status: "ENABLED",
                                        negative: true,
                                        keyword: {
                                            text: this.state.keyword_text,
                                            matchType: "EXACT"
                                        }
                                    }
                                }
                                if (!item) {
                                    if (this.state.search_term.adGroup) {
                                        exact_buffer.create.adGroup = this.state.search_term.adGroup.resourceName;
                                    }
                                } else {
                                    if (this.state.edit_negative.adGroup) {
                                        exact_buffer.create.adGroup = this.state.edit_negative.adGroup.resourceName;
                                    } else if (this.state.edit_negative.campaign) {
                                        exact_buffer.create.campaign = this.state.edit_negative.campaign.resourceName;
                                    }
                                }
                                operations.push(exact_buffer);
                            }
                            if (this.state.keyword_phrase) {
                                let phrase_buffer = {
                                    create: {
                                        type: "KEYWORD",
                                        status: "ENABLED",
                                        negative: true,
                                        keyword: {
                                            text: this.state.keyword_text,
                                            matchType: "PHRASE"
                                        }
                                    }
                                }
                                if (!item) {
                                    if (this.state.search_term.adGroup) {
                                        phrase_buffer.create.adGroup = this.state.search_term.adGroup.resourceName;
                                    }
                                } else {
                                    if (this.state.edit_negative.adGroup) {
                                        phrase_buffer.create.adGroup = this.state.edit_negative.adGroup.resourceName;
                                    } else if (this.state.edit_negative.campaign) {
                                        phrase_buffer.create.campaign = this.state.edit_negative.campaign.resourceName;
                                    }
                                }
                                operations.push(phrase_buffer);
                            }
                            if (this.state.keyword_broad) {
                                let broad_buffer = {
                                    create: {
                                        type: "KEYWORD",
                                        status: "ENABLED",
                                        negative: true,
                                        keyword: {
                                            text: this.state.keyword_text,
                                            matchType: "BROAD"
                                        }
                                    }
                                }
                                if (!item) {
                                    if (this.state.search_term.adGroup) {
                                        broad_buffer.create.adGroup = this.state.search_term.adGroup.resourceName;
                                    }
                                } else {
                                    if (this.state.edit_negative.adGroup) {
                                        broad_buffer.create.adGroup = this.state.edit_negative.adGroup.resourceName;
                                    } else if (this.state.edit_negative.campaign) {
                                        broad_buffer.create.campaign = this.state.edit_negative.campaign.resourceName;
                                    }
                                }
                                operations.push(broad_buffer);
                            }


                            if (Object.keys(operations[0].create).includes("adGroup") && !Object.keys(operations[0].create).includes("campaign")) {
                                await this.calls.createAdgroupCriterion({
                                    operations: operations
                                });
                            } else if (Object.keys(operations[0].create).includes("campaign") && !Object.keys(operations[0].create).includes("adGroup")) {
                                await this.calls.createCampaignCriterion({
                                    operations: operations
                                });
                            }

                        } catch (error) { }
                        if (!item) {
                            this.state.search_terms.map((inner_item) => { if (inner_item.id === this.state.search_term.id) { inner_item.loading = false } return inner_item });
                            await this.promisedSetState({ search_terms: this.state.search_terms, success: true });
                        }
                    }

                    if (!item) {
                        if (Object.keys(operations[0].create).includes("campaign") && !Object.keys(operations[0].create).includes("adGroup")) {
                            this.google.getCampaignNegativeKeywords(true);
                        } else if (Object.keys(operations[0].create).includes("adGroup") && !Object.keys(operations[0].create).includes("campaign")) {
                            this.google.getNegativeKeywords(true);
                        }
                    }
                } else {
                    await this.promisedSetState({ selected_adgroup_error: true });
                }

                resolve();
            });
        },
        editNegativeKeywordCampaign: async (item) => {
            console.log("STATEEEE====", this.state)
            if (this.state.keyword_multi_text && Array.isArray(this.state.keyword_multi_text) && this.state.keyword_multi_text.length > 0) {
                this.state.keyword_multi_text = this.state.keyword_multi_text.map((item) => {
                    item.campaign = {
                        resourceName: item.resourceName.substring(0, item.resourceName.indexOf("~")).replace("campaignCriteria", "campaigns")
                    };
                    return item
                });
                let temp = this.state.keyword_multi_text;
                for (let i = 0; i < temp.length; i++) {
                    await this.calls.removeCampaignCriteria({
                        operations: [
                            {
                                remove: temp[i].resourceName
                            }
                        ]
                    });
                }
                await this.promisedSetState({ keyword_multi_text: this.state.keyword_multi_text, negative_keywords_campaign: this.state.negative_keywords_campaign.map((item) => { item.selected = false; return item }), edit_keyword: false });
                await this.google.addNegativeKeywordAdgroup(true);
            } else {

                await this.calls.removeCampaignCriteria({
                    operations: [
                        {
                            remove: this.state.edit_negative.resourceName
                        }
                    ]
                });
                this.state.edit_negative.campaign = {
                    resourceName: this.state.edit_negative.resourceName.substring(0, this.state.edit_negative.resourceName.indexOf("~")).replace("campaignCriteria", "campaigns")
                }
                await this.promisedSetState({
                    edit_negative: this.state.edit_negative
                });

                await this.google.addNegativeKeywordAdgroup(true);
                await this.promisedSetState({ edit_keyword: false, success: true });

            }
            this.google.getCampaignNegativeKeywords(true);
        },
        editNegativeKeywordAdgroup: async (item) => {
            if (this.state.keyword_multi_text && Array.isArray(this.state.keyword_multi_text) && this.state.keyword_multi_text.length > 0) {
                this.state.keyword_multi_text = this.state.keyword_multi_text.map((item) => {
                    item.adGroup = {
                        resourceName: item.resourceName.substring(0, item.resourceName.indexOf("~")).replace("adGroupCriteria", "adGroups")
                    };
                    return item
                });
                let temp = this.state.keyword_multi_text;
                for (let i = 0; i < temp.length; i++) {
                    await this.calls.removeAdgroupCriteria({
                        operations: [
                            {
                                remove: temp[i].resourceName
                            }
                        ]
                    });
                }
                await this.promisedSetState({ keyword_multi_text: this.state.keyword_multi_text, negative_keywords: this.state.negative_keywords.map((item) => { item.selected = false; return item }), edit_keyword: false });
                await this.google.addNegativeKeywordAdgroup(true);
            } else {
                await this.calls.removeAdgroupCriteria({
                    operations: [
                        {
                            remove: this.state.edit_negative.resourceName
                        }
                    ]
                });
                this.state.edit_negative.adGroup = {
                    resourceName: this.state.edit_negative.resourceName.substring(0, this.state.edit_negative.resourceName.indexOf("~")).replace("adGroupCriteria", "adGroups")
                }
                await this.promisedSetState({
                    edit_negative: this.state.edit_negative
                });

                await this.google.addNegativeKeywordAdgroup(true);
                await this.promisedSetState({ edit_keyword: false, success: true });
            }
            this.google.getNegativeKeywords(true);

        },
        editPositiveKeywordAdgroup: async (item) => {
            if (this.state.keyword_multi_text && Array.isArray(this.state.keyword_multi_text) && this.state.keyword_multi_text.length > 0) {
                this.state.keyword_multi_text = this.state.keyword_multi_text.map((item) => {
                    item.adGroup = {
                        resourceName: item.resourceName.substring(0, item.resourceName.indexOf("~")).replace("adGroupCriteria", "adGroups")
                    };
                    return item
                });
                console.log("PLEASE SKIP")
                let temp = this.state.keyword_multi_text;
                for (let i = 0; i < temp.length; i++) {
                    await this.calls.removeAdgroupCriteria({
                        operations: [
                            {
                                remove: temp[i].resourceName
                            }
                        ]
                    });
                }
                await this.promisedSetState({ keyword_multi_text: this.state.keyword_multi_text, keywords: this.state.keywords.map((item) => { item.selected = false; return item }), edit_keyword: false });
                await this.google.addPositiveKeywordAdgroup(true);

            } else {
                await this.calls.removeAdgroupCriteria({
                    operations: [
                        {
                            remove: this.state.edit_positive.resourceName
                        }
                    ]
                });
                this.state.edit_positive.adGroup = {
                    resourceName: this.state.edit_positive.resourceName.substring(0, this.state.edit_positive.resourceName.indexOf("~")).replace("adGroupCriteria", "adGroups")
                }
                await this.promisedSetState({
                    edit_positive: this.state.edit_positive
                });
                await this.google.addPositiveKeywordAdgroup(true);
            }
        },
        addNegativeKeywordCampaign: async (item) => {
            this.state.search_terms.map((inner_item) => { if (inner_item.id === item.id) { item.loading = true } return inner_item });
            await this.promisedSetState({ search_terms: this.state.search_terms });
            try {
                let buff = [
                    {
                        create: {
                            campaign: item.campaign.resourceName,
                            type: "KEYWORD",
                            status: "ENABLED",
                            negative: true,
                            keyword: {
                                text: item.searchTermView.searchTerm,
                                matchType: "EXACT"
                            }
                        }
                    }
                ]
                await this.calls.createCampaignCriterion({
                    operations: buff
                });
            } catch (error) { }
            this.state.search_terms.map((inner_item) => { if (inner_item.id === item.id) { item.loading = false } return inner_item });
            await this.promisedSetState({ search_terms: this.state.search_terms });
        },
        removeKeyword: async (init) => {
            if (init) {
                let temp = JSON.parse(JSON.stringify(this.state.keyword_multi_text));
                let buffer = JSON.parse(JSON.stringify(this.state.keyword_multi_text));
                buffer = buffer.map((item) => { return item.id });

                if (this.state.tab.value === "keywords") {
                    await this.promisedSetState({ keywords: this.state.keywords.map((inner_item) => { if (buffer.includes(inner_item.id)) { inner_item.loading = true } return inner_item }) });
                } else if (this.state.tab.value === "negative_keywords") {
                    await this.promisedSetState({ negative_keywords: this.state.negative_keywords.map((inner_item) => { if (buffer.includes(inner_item.id)) { inner_item.loading = true } return inner_item }), negative_keywords_campaign: this.state.negative_keywords_campaign.map((inner_item) => { if (buffer.includes(inner_item.id)) { inner_item.loading = true } return inner_item }) });
                }

                try {
                    let operations = {
                        campaign: [],
                        adgroup: [],
                        list: []
                    };
                    let removed_items = {
                        campaign: [],
                        adgroup: [],
                        list: []
                    };
                    temp.map((item) => {
                        if (item.resourceName.includes("campaignCriteria")) {
                            removed_items.campaign.push(item);
                            operations.campaign.push(
                                {
                                    remove: item.resourceName
                                }
                            );
                        } else if (item.resourceName.includes("adGroupCriteria")) {
                            removed_items.adgroup.push(item);
                            operations.adgroup.push(
                                {
                                    remove: item.resourceName
                                }
                            );
                        } else if (item.resourceName.includes("campaignSharedSets")) {
                            removed_items.list.push(item);
                            operations.list.push(
                                {
                                    remove: item.resourceName
                                }
                            );
                        }
                    });
                    if (operations.campaign.length > 0) {
                        await this.calls.removeCampaignCriteria({
                            operations: operations.campaign
                        });
                    }
                    if (operations.adgroup.length > 0) {
                        await this.calls.removeAdgroupCriteria({
                            operations: operations.adgroup
                        });
                    }
                    if (operations.list.length > 0) {
                        await this.calls.removeCampaignSharedSet({
                            operations: operations.list
                        });
                    }
                    if (this.state.tab.value === "keywords") {
                        await this.promisedSetState({ keywords: this.state.keywords.filter((inner_item) => { return !buffer.includes(inner_item.id) }), keyword_multi_text: false, delete_keyword: false });
                    } else if (this.state.tab.value === "negative_keywords") {
                        await this.promisedSetState({ negative_keywords: this.state.negative_keywords.filter((inner_item) => { return !buffer.includes(inner_item.id) }), negative_keywords_campaign: this.state.negative_keywords_campaign.filter((inner_item) => { return !buffer.includes(inner_item.id) }), keyword_multi_text: false, delete_keyword: false });
                    }
                } catch (error) {
                    console.log(error);
                    if (this.state.tab.value === "keywords") {
                        await this.promisedSetState({ keywords: this.state.keywords.map((inner_item) => { if (buffer.includes(inner_item.id)) { inner_item.loading = false } return inner_item }) });
                    } else if (this.state.tab.value === "negative_keywords") {
                        await this.promisedSetState({ negative_keywords: this.state.negative_keywords.map((inner_item) => { if (buffer.includes(inner_item.id)) { inner_item.loading = false } return inner_item }), negative_keywords_campaign: this.state.negative_keywords_campaign.map((inner_item) => { if (buffer.includes(inner_item.id)) { inner_item.loading = false } return inner_item }) });
                    }
                }
            } else {
                let temp = JSON.parse(JSON.stringify(this.state.remove));
                await this.promisedSetState({ remove: null });
                await this.promisedSetState({ remove_name: "" });
                if (this.state.tab.value === "keywords") {
                    await this.promisedSetState({ keywords: this.state.keywords.map((inner_item) => { if (inner_item.id === temp.id) { inner_item.loading = true } return inner_item }) });
                } else if (this.state.tab.value === "negative_keywords") {
                    await this.promisedSetState({ negative_keywords: this.state.negative_keywords.map((inner_item) => { if (inner_item.id === temp.id) { inner_item.loading = true } return inner_item }), negative_keywords_campaign: this.state.negative_keywords_campaign.map((inner_item) => { if (inner_item.id === temp.id) { inner_item.loading = true } return inner_item }) });
                }
                try {
                    if (temp.resourceName.includes("campaignCriteria")) {
                        await this.calls.removeCampaignCriteria({
                            operations: [
                                {
                                    remove: temp.resourceName
                                }
                            ]
                        });
                    } else if (temp.resourceName.includes("adGroupCriteria")) {

                        await this.calls.removeAdgroupCriteria({
                            operations: [
                                {
                                    remove: temp.resourceName
                                }
                            ]
                        });
                    } else if (temp.resourceName.includes("campaignSharedSets")) {
                        await this.calls.removeCampaignSharedSet({
                            operations: [
                                {
                                    remove: temp.resourceName
                                }
                            ]
                        });
                    }

                    if (this.state.tab.value === "keywords") {
                        await this.promisedSetState({ keywords: this.state.keywords.filter((inner_item) => { return inner_item.id !== temp.id }) });
                    } else if (this.state.tab.value === "negative_keywords") {
                        await this.promisedSetState({ negative_keywords: this.state.negative_keywords.filter((inner_item) => { return inner_item.id !== temp.id }), negative_keywords_campaign: this.state.negative_keywords_campaign.filter((inner_item) => { return inner_item.id !== temp.id }) });
                    }
                } catch (error) {
                    console.log(error);
                    if (this.state.tab.value === "keywords") {
                        await this.promisedSetState({ keywords: this.state.keywords.map((inner_item) => { if (inner_item.id === temp.id) { inner_item.loading = false } return inner_item }) });
                    } else if (this.state.tab.value === "negative_keywords") {
                        await this.promisedSetState({ negative_keywords: this.state.negative_keywords.map((inner_item) => { if (inner_item.id === temp.id) { inner_item.loading = false } return inner_item }), negative_keywords_campaign: this.state.negative_keywords_campaign.map((inner_item) => { if (inner_item.id === temp.id) { inner_item.loading = false } return inner_item }) });
                    }
                }
            }
        },
        removeAsset: async () => {
            let temp = JSON.parse(JSON.stringify(this.state.assets));
            let buffer = JSON.parse(JSON.stringify(this.state.assets)).filter(item => item.selected).map((item) => { return item.id });

            if (this.state.remove_asset) {
                temp = [JSON.parse(JSON.stringify(this.state.remove_asset))];
                buffer = [this.state.remove_asset.id];
                temp[0].selected = true;
            }

            await this.promisedSetState({ assets: this.state.assets.map((inner_item) => { if (buffer.includes(inner_item.id)) { inner_item.loading = true } return inner_item }) });
            try {
                let operations = {
                    campaign: [],
                    adgroup: [],
                    location_campaign: [],
                    location_adgroup: []
                };
                let removed_items = {
                    campaign: [],
                    adgroup: []
                };
                temp.filter((item) => { return item.selected }).map((item) => {
                    console.log("ITEM======", item)
                    if (item.type !== "LOCATION") {
                        if (item.asset_level === "Campaign") {
                            removed_items.campaign.push(item);
                            operations.campaign.push(
                                {
                                    remove: item.resourceName
                                }
                            );
                        } else if (item.asset_level === "Adgroup") {
                            removed_items.adgroup.push(item);
                            operations.adgroup.push(
                                {
                                    remove: item.resourceName
                                }
                            );
                        }
                    } else if (item.type === "LOCATION") {
                        if (item.asset_level === "Campaign") {
                            if (Array.isArray(item.data)) {
                                item.data.map((location) => {
                                    operations.location_campaign.push(
                                        {
                                            remove: location.campaignAssetSet.resourceName
                                        }
                                    );
                                });
                            }

                        } else if (item.asset_level === "Adgroup") {
                            if (Array.isArray(item.data)) {
                                item.data.map((location) => {
                                    operations.location_adgroup.push(
                                        {
                                            remove: location.adGroupAssetSet.resourceName
                                        }
                                    );
                                });
                            }
                        }
                    }
                });
                if (operations.campaign.length > 0) {
                    await this.calls.removeCampaignAsset({
                        operations: operations.campaign
                    });
                }
                if (operations.adgroup.length > 0) {
                    await this.calls.removeAdgroupAsset({
                        operations: operations.adgroup
                    });
                }
                if (operations.location_campaign.length > 0) {
                    console.log("DELETE CAMPAIGN ASSET SETS")
                    await this.calls.deleteCampaignAssetSets({
                        operations: operations.location_campaign,
                    });
                }
                if (operations.location_adgroup.length > 0) {
                    await this.calls.deleteAdgroupAssetSets({
                        operations: operations.location_adgroup,
                    });
                }


                await this.promisedSetState({ assets: this.state.assets.filter((inner_item) => { return !buffer.includes(inner_item.id) }), delete_asset: false, remove_asset: false });
            } catch (error) {
                console.log(error);
                await this.promisedSetState({ assets: this.state.assets.map((inner_item) => { if (buffer.includes(inner_item.id)) { inner_item.loading = false } return inner_item }), delete_asset: false, remove_asset: false });

            }

        }
    };

    bing = {
        getKeywords: async (init, campaign) => {
            try {
                this.state.pagination.bing_pagination = init ? "1" : this.state.pagination.bing_pagination;
                await this.promisedSetState({
                    keywords: init ? [] : this.state.keywords,
                    pagination: this.state.pagination,
                    loading_partial: true
                });
                const queryString = this.state.adgroups.map((item, index) => `&adgroups[${index}]=${item.id}`).join('');
                let ids = this.state.selected_campaigns.filter(item => item.channel === "google").flatMap(item => item.id);
                if (ids.length < 1) {
                    await this.promisedSetState({
                        loading_partial: false
                    });
                    return false;
                }
                ids = "&campaign_ids[]=" + (ids.join("&campaign_ids[]="));
                let response = await this.calls.getKeywords("bing", queryString, null, ids);
                response.data = response.data.map((item) => {
                    return {
                        channel: 'bing',
                        id: item.id,
                        resourceName: item.id,
                        name: item.text,
                        status: item.status ? item.status.toLowerCase() : '-',
                        approval_status: item.editorialStatus,
                        keyword_type: item.matchType,
                        impressions: null,
                        spend: 0, // parseInt(item.metrics.costMicros) / 1000000,
                        ctr: "-",//(item.metrics.ctr * 100).toFixed(2),
                        clicks: item.clicks || "-",
                        conversions: item.conversions || "-"
                    };
                });
                this.state.pagination.bing_pagination = response.pagination;
                await this.promisedSetState({
                    pagination: this.state.pagination,
                    keywords: this.state.keywords.concat(response.data),
                    loading_partial: false
                });
            } catch (error) {
                await this.promisedSetState({
                    error: true,
                    loading_partial: false
                });
            }
        },
        getSearchterms: async (init, campaign) => {
            try {
                this.state.pagination.bing_search_term = init ? "1" : this.state.pagination.bing_search_term;
                await this.promisedSetState({
                    search_terms: init ? [] : this.state.search_terms,
                    pagination: this.state.pagination,
                    loading_partial: true
                });
                let ids = this.state.selected_campaigns.filter(item => item.channel === "google").flatMap(item => item.id);
                if (ids.length < 1) {
                    await this.promisedSetState({
                        loading_partial: false
                    });
                    return false;
                }
                ids = "&campaign_ids[]=" + (ids.join("&campaign_ids[]="));
                let response = await this.calls.getSearchterms(true, ids);
                response.data = response.data.map((item) => {
                    return {
                        id: item.searchTermView.resourceName,
                        searchTermView: item.searchTermView,
                        adGroup: item.adGroup,
                        campaign: item.campaign,
                        channel: 'google',
                        name: item.searchTermView.searchTerm,
                        keyword: item.segments.keyword.info.text,
                        keyword_type: item.segments.keyword.info.matchType,
                        impressions: item.metrics.impressions,
                        spend: parseInt(item.metrics.costMicros) / 1000000,
                        ctr: (item.metrics.ctr).toFixed(2),
                        clicks: item.metrics.clicks,
                        conversions: item.metrics.conversions
                    };
                });
                this.state.pagination.bing_search_term = response.pagination;
                await this.promisedSetState({
                    pagination: this.state.pagination,
                    search_terms: this.state.search_terms.concat(response.data),
                    loading_partial: false
                });
            } catch (error) {
                await this.promisedSetState({
                    error: true,
                    loading_partial: false
                });
            }
        },
        addPositiveKeywordAdgroup: async (item) => {
            this.state.search_terms.map((inner_item) => { if (inner_item.id === item.id) { item.loading = true } return inner_item });
            await this.promisedSetState({ search_terms: this.state.search_terms });
            try {
                let buff = [
                    {
                        create: {
                            adGroup: item.adGroup.resourceName,
                            type: "KEYWORD",
                            status: "ENABLED",
                            negative: false,
                            keyword: {
                                text: item.searchTermView.searchTerm,
                                matchType: "EXACT"
                            }
                        }
                    }
                ]
                await this.calls.createAdgroupCriterion({
                    operations: buff
                });
            } catch (error) { }
            this.state.search_terms.map((inner_item) => { if (inner_item.id === item.id) { item.loading = false } return inner_item });
            await this.promisedSetState({ search_terms: this.state.search_terms });
        },
        addNegativeKeywordAdgroup: async (item) => {
            this.state.search_terms.map((inner_item) => { if (inner_item.id === this.state.search_term.id) { inner_item.loading = true } return inner_item });
            await this.promisedSetState({ search_terms: this.state.search_terms, create_keyword: false });
            try {
                let operations = [];
                if (this.state.keyword_exact) {
                    operations.push(
                        {
                            create: {
                                adGroup: this.state.search_term.adGroup.resourceName,
                                type: "KEYWORD",
                                status: "ENABLED",
                                negative: true,
                                keyword: {
                                    text: this.state.keyword_text,
                                    matchType: "EXACT"
                                }
                            }
                        }
                    );
                }
                if (this.state.keyword_phrase) {
                    operations.push(
                        {
                            create: {
                                adGroup: this.state.search_term.adGroup.resourceName,
                                type: "KEYWORD",
                                status: "ENABLED",
                                negative: true,
                                keyword: {
                                    text: this.state.keyword_text,
                                    matchType: "PHRASE"
                                }
                            }
                        }
                    );
                }
                if (this.state.keyword_broad) {
                    operations.push(
                        {
                            create: {
                                adGroup: this.state.search_term.adGroup.resourceName,
                                type: "KEYWORD",
                                status: "ENABLED",
                                negative: true,
                                keyword: {
                                    text: this.state.keyword_text,
                                    matchType: "BROAD"
                                }
                            }
                        }
                    );
                }
                await this.calls.createAdgroupCriterion({
                    operations: operations
                });
            } catch (error) { }
            this.state.search_terms.map((inner_item) => { if (inner_item.id === this.state.search_term.id) { inner_item.loading = false } return inner_item });
            await this.promisedSetState({ search_terms: this.state.search_terms, success: true });
        },
        addNegativeKeywordCampaign: async (item) => {
            this.state.search_terms.map((inner_item) => { if (inner_item.id === item.id) { item.loading = true } return inner_item });
            await this.promisedSetState({ search_terms: this.state.search_terms });
            try {
                let buff = [{
                    create: {
                        campaign: item.campaign.resourceName,
                        type: "KEYWORD",
                        status: "ENABLED",
                        negative: true,
                        keyword: {
                            text: item.searchTermView.searchTerm,
                            matchType: "EXACT"
                        }
                    }

                }]
                await this.calls.createCampaignCriterion({
                    operations: buff
                });
            } catch (error) { }
            this.state.search_terms.map((inner_item) => { if (inner_item.id === item.id) { item.loading = false } return inner_item });
            await this.promisedSetState({ search_terms: this.state.search_terms });
        },
        removeKeyword: async () => {
            let temp = JSON.parse(JSON.stringify(this.state.remove));
            await this.promisedSetState({ remove: null });
            await this.promisedSetState({ remove_name: "" });
            await this.promisedSetState({ keywords: this.state.keywords.map((inner_item) => { if (inner_item.id === temp.id) { inner_item.loading = true } return inner_item }) });
            try {
                await this.calls.removeAdgroupCriteria({
                    operations: [
                        {
                            remove: temp.resourceName
                        }
                    ]
                });
                await this.promisedSetState({ keywords: this.state.keywords.filter((inner_item) => { return inner_item.id !== temp.id }) });
            } catch (error) {
                console.log(error);
                await this.promisedSetState({ keywords: this.state.keywords.map((inner_item) => { if (inner_item.id === temp.id) { inner_item.loading = false } return inner_item }) });
            }
        },

    };

    open = {
        google: (option, item) => {
            if (this.state.tab.value === "campaigns") {
                if (option === "Overview") {
                    window.open("https://ads.google.com/aw/overview?campaignId=" + item.id + "&ocid=" + item.ocid, "_blank");
                } else if (option === "Recommendations") {
                    window.open("https://ads.google.com/aw/recommendations?campaignId=" + item.id + "&ocid=" + item.ocid, "_blank");
                } else if (option === "Insights") {
                    window.open("https://ads.google.com/aw/insights?campaignId=" + item.id + "&ocid=" + item.ocid, "_blank");
                } else if (option === "Adgroups") {
                    window.open("https://ads.google.com/aw/adgroups?campaignId=" + item.id + "&ocid=" + item.ocid, "_blank");
                } else if (option === "Ads") {
                    window.open("https://ads.google.com/aw/ads?campaignId=" + item.id + "&ocid=" + item.ocid, "_blank");
                } else if (option === "Keywords") {
                    window.open("https://ads.google.com/aw/keywords?campaignId=" + item.id + "&ocid=" + item.ocid, "_blank");
                } else if (option === "Negative keywords") {
                    window.open("https://ads.google.com/aw/keywords/negative?campaignId=" + item.id + "&ocid=" + item.ocid, "_blank");
                } else if (option === "Settings") {
                    window.open("https://ads.google.com/aw/settings/campaign/search?campaignId=" + item.id + "&ocid=" + item.ocid, "_blank");
                } else if (option === "Delete") {

                }
            } else if (this.state.tab.value === "adgroups") {
                if (option === "Overview") {
                    window.open("https://ads.google.com/aw/overview?campaignId=" + item.campaign_id + "&adGroupId=" + item.id + "&ocid=" + item.ocid, "_blank");
                } else if (option === "Recommendations") {
                    window.open("https://ads.google.com/aw/recommendations?campaignId=" + item.campaign_id + "&adGroupId=" + item.id + "&ocid=" + item.ocid, "_blank");
                } else if (option === "Ads") {
                    window.open("https://ads.google.com/aw/ads?campaignId=" + item.campaign_id + "&adGroupId=" + item.id + "&ocid=" + item.ocid, "_blank");
                } else if (option === "Keywords") {
                    window.open("https://ads.google.com/aw/keywords?campaignId=" + item.campaign_id + "&adGroupId=" + item.id + "&ocid=" + item.ocid, "_blank");
                } else if (option === "Negative keywords") {
                    window.open("https://ads.google.com/aw/keywords/negative?campaignId=" + item.campaign_id + "&adGroupId=" + item.id + "&ocid=" + item.ocid, "_blank");
                } else if (option === "Search terms") {
                    window.open("https://ads.google.com/aw/keywords/searchterms?campaignId=" + item.campaign_id + "&adGroupId=" + item.id + "&ocid=" + item.ocid, "_blank");
                } else if (option === "Settings") {
                    window.open("https://ads.google.com/aw/settings/adgroups/search?campaignId=" + item.campaign_id + "&adGroupId=" + item.id + "&ocid=" + item.ocid, "_blank");
                } else if (option === "Delete") {

                }
            } else if (this.state.tab.value === "ads") {
                if (option === "Overview") {
                    window.open("https://ads.google.com/aw/ads/edit/search?campaignId=" + item.campaign_id + "&adGroupId=" + item.adgroup_id + "&adGroupIdForAd=" + item.adgroup_id + "&adId=" + item.id + "&ocid=" + item.ocid, "_blank");
                    //window.open("https://ads.google.com/aw/overview?campaignId=" + item.campaign_id + "&adGroupId=" + item.adgroup_id + "&adId=" + item.id + "&ocid=" + item.ocid, "_blank");
                } else if (option === "Edit") {
                    //window.open("https://ads.google.com/aw/ads/edit/search?campaignId=" + item.campaign_id + "&adGroupId=" + item.adgroup_id + "&adId=" + item.id + "&ocid=" + item.ocid, "_blank");
                }
            }
        },
        facebook: (item) => {
            if (this.state.tab.value === "campaigns") {
                window.open("https://business.facebook.com/adsmanager/manage/campaigns/edit?act=" + item.account + "&business_id=" + item.manager + "&selected_campaign_ids=" + item.id, "_blank");
            } else if (this.state.tab.value === "adgroups") {
                window.open("https://business.facebook.com/adsmanager/manage/adsets/edit?act=" + item.account + "&business_id=" + item.manager + "&selected_campaign_ids=" + item.campaign_id + "&selected_adset_ids=" + item.id, "_blank");
            } else if (this.state.tab.value === "ads") {
                window.open("https://business.facebook.com/adsmanager/manage/ads/edit?act=" + item.account + "&business_id=" + item.manager + "&selected_campaign_ids=" + item.campaign_id + "&selected_adset_ids=" + item.adgroup_id + "&selected_ad_ids=" + item.id, "_blank");
            }
        },
        linkedin: (item) => {
            if (this.state.tab.value === "campaigns") {
                window.open("https://www.linkedin.com/campaignmanager/accounts/" + item.account + "/campaign-groups/" + item.id, "_blank");
            } else if (this.state.tab.value === "adgroups") {
                window.open("https://www.linkedin.com/campaignmanager/accounts/" + item.account + "/campaigns/" + item.id + "/details", "_blank");
            } else if (this.state.tab.value === "ads") {
                window.open("https://www.linkedin.com/campaignmanager/accounts/" + item.account + "/campaigns/" + item.adgroup + "/creatives/" + item.id + "?action=edit", "_blank");
            }
        },
        twitter: (item) => {
            if (this.state.tab.value === "campaigns") {
                window.open("https://ads.twitter.com/campaign_form/" + item.account + "/campaign/" + item.ui_id + "/edit", "_blank");
            } else if (this.state.tab.value === "adgroups") {
                window.open("https://ads.twitter.com/campaign_form/" + item.account + "/campaign/" + item.campaign_ui_id + "/adgroup/" + item.ui_id + "/adgroup_details/edit", "_blank");
            } else if (this.state.tab.value === "ads") {
                //window.open("https://ads.twitter.com/campaign_form/" + item.account + "/campaign/" + this.state.campaign.id + "/adgroup/" + this.state.adgroup.id + "/creative_form/" + item.index + "/edit", "_blank");
            }
        },
        tiktok: (item) => {
            if (this.state.tab.value === "campaigns") {
                window.open("https://ads.tiktok.com/i18n/perf?aadvid=" + item.account + "&keyword=" + item.id + "", "_blank");
            } else if (this.state.tab.value === "adgroups") {
                window.open("https://ads.tiktok.com/i18n/perf/adgroup?aadvid=" + item.account + "&keyword=" + item.id + "&search_type=2", "_blank");
            } else if (this.state.tab.value === "ads") {
                window.open("https://ads.tiktok.com/i18n/perf/creative?aadvid=" + item.account + "&keyword=" + item.id + "&search_type=3", "_blank");
            }
        },
        adform: (item) => {
            if (this.state.tab.value === "campaigns") {
                window.open("https://flow.adform.com/campaign/list?campaign=" + item.id + "/overview", "_blank");
            } else if (this.state.tab.value === "adgroups") {
                window.open("https://flow.adform.com/campaign/list?campaign=" + item.campaign + "/overview", "_blank");
            } else if (this.state.tab.value === "ads") {
                window.open("https://flow.adform.com/campaign/line-item/" + item.id + "/edit-rtb/general", "_blank");
            }
        },
        snapchat: (item) => {
            if (this.state.tab.value === "campaigns") {
                window.open("https://ads.snapchat.com/" + item.account + "/manage", "_blank");
            }
            /* else if (this.state.tab.value === "adgroups") {
                window.open("https://ads.snapchat.com/i18n/perf/adgroup?aadvid=" + item.account + "&keyword=" + item.id + "&search_type=2", "_blank");
            } else if (this.state.tab.value === "ads") {
                window.open("https://ads.snapchat.com/i18n/perf/creative?aadvid=" + item.account + "&keyword=" + item.id + "&search_type=3", "_blank");
            }*/
        }
    };

    calls = {
        getOrders: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/listOrders?limit=10&page=" + this.state.order_page + "&sortBy=name&orderBy=descending&client=" + (this.state.client_change ? this.state.client_change.id : this.state.client.id) + (this.state.order_search !== "" ? ("&search=" + this.state.order_search) : "")
            return apiRegister.call(options, url);
        },
        test: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/test";
            return apiRegister.call(options, url);
        },
        getLineChart: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/adcredo/reportABTest?&client=" + this.state.client.id;
            return apiRegister.call(options, url);
        },
        getCampaignAssets: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/google/listCampaignAssets?&client=" + this.state.client.id;
            return apiRegister.call(options, url);
        },
        getCampaignAssetsSets: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/google/listCampaignAssetSets?&client=" + this.state.client.id;
            return apiRegister.call(options, url);
        },
        getAdgroupsAssets: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/google/listAdgroupAssets?&client=" + this.state.client.id;
            return apiRegister.call(options, url);
        },
        pauseCampaign: (item, body) => {
            let options = apiRegister.options(tokenRegister.get(), 'PUT', body);
            let url = apiRegister.url.api + "/v3/adcredo/updateCampaignStatus?client=" + this.state.client.id + "&status=pause&skip_dates=true&campaign=" + item.id + "&channel=" + item.channel + "&internal=" + item.internal + "&order=" + this.state.order.id + "&name=" + item.name;
            return apiRegister.call(options, url);
        },
        activateCampaign: (item, body) => {
            let options = apiRegister.options(tokenRegister.get(), 'PUT', body);
            let url = apiRegister.url.api + "/v3/adcredo/updateCampaignStatus?client=" + this.state.client.id + "&status=activate&skip_dates=true&campaign=" + item.id + "&channel=" + item.channel + "&internal=" + item.internal + "&order=" + this.state.order.id + "&name=" + item.name;
            return apiRegister.call(options, url);
        },
        pauseAdgroup: (item, body) => {
            let options = apiRegister.options(tokenRegister.get(), 'PUT', body);
            let url = apiRegister.url.api + "/v3/adcredo/updateAdgroupStatus?client=" + this.state.client.id + "&status=pause&adgroup=" + item.id + "&channel=" + item.channel + "&internal=" + item.internal + "&campaign=" + item.internal_campaign;
            return apiRegister.call(options, url);
        },
        activateAdgroup: (item, body) => {
            let options = apiRegister.options(tokenRegister.get(), 'PUT', body);
            let url = apiRegister.url.api + "/v3/adcredo/updateAdgroupStatus?client=" + this.state.client.id + "&status=activate&adgroup=" + item.id + "&channel=" + item.channel + "&internal=" + item.internal + "&campaign=" + item.internal_campaign;
            return apiRegister.call(options, url);
        },
        pauseAd: (item, body) => {
            let options = apiRegister.options(tokenRegister.get(), 'PUT', body);
            let url = apiRegister.url.api + "/v3/adcredo/updateAdStatus?client=" + this.state.client.id + "&status=pause&ad=" + item.id + "&channel=" + item.channel + "&internal=" + item.internal + "&adgroup=" + item.adgroup_id + (item.channel === "snapchat" ? "&name=" + item.name + "&type=" + item.type + "&creative_id=" + item.creative.id : "");
            return apiRegister.call(options, url);
        },
        activateAd: (item, body) => {
            let options = apiRegister.options(tokenRegister.get(), 'PUT', body);
            let url = apiRegister.url.api + "/v3/adcredo/updateAdStatus?client=" + this.state.client.id + "&status=activate&ad=" + item.id + "&channel=" + item.channel + "&internal=" + item.internal + "&adgroup=" + item.adgroup_id + (item.channel === "snapchat" ? "&name=" + item.name + "&type=" + item.type + "&creative_id=" + item.creative.id : "");
            return apiRegister.call(options, url);
        },
        updateAd: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/google/updateAd?client=" + this.state.client.id;
            return apiRegister.call(options, url);
        },
        getCampaigns: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/getOrderCampaigns?id=" + this.functions.getIdFromPath() + "&information=true";
            return apiRegister.call(options, url);
        },
        getCampaignsData: (campaigns) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/getExernalCampaignsData?" + "statistics=true" + (this.state.custom_dates.start && this.state.custom_dates.end ? "&start_date=" + this.state.custom_dates.start + "&end_date=" + this.state.custom_dates.end : "&datespan=" + this.state.date_span.value) + "&client=" + this.state.client.id + campaigns;
            return apiRegister.call(options, url);
        },
        getAdgroups: (campaigns) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/getExernalAdgroupsData?" + "client=" + this.state.client.id + "&information=true" + campaigns;
            return apiRegister.call(options, url);
        },
        getAdgroupsData: (adgroups) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            //let url = apiRegister.url.api + "/v3/adcredo/getExernalAdgroupsData?" + "statistics=true" + "&datespan=" + this.state.date_span.value + "&client=" + this.state.client.id + adgroups + "&calculate_basic_metrics=true";
            let url = apiRegister.url.api + "/v3/adcredo/getExernalAdgroupsData?" + "statistics=true" + (this.state.custom_dates.start && this.state.custom_dates.end ? "&start_date=" + this.state.custom_dates.start + "&end_date=" + this.state.custom_dates.end : "&datespan=" + this.state.date_span.value) + "&client=" + this.state.client.id + adgroups + "&calculate_basic_metrics=true";
            return apiRegister.call(options, url);
        },
        getAds: (adgroups) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/getExernalAdsData?" + "client=" + this.state.client.id + "&information=true&creative=true" + adgroups;
            return apiRegister.call(options, url);
        },
        getAdsData: (ads) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/getExernalAdsData?" + "statistics=true" + (this.state.custom_dates.start && this.state.custom_dates.end ? "&start_date=" + this.state.custom_dates.start + "&end_date=" + this.state.custom_dates.end : "&datespan=" + this.state.date_span.value) + "&client=" + this.state.client.id + ads + "&calculate_basic_metrics=true&creative=true";
            return apiRegister.call(options, url);
        },
        getCampaignKeywords: (channel, queryString = null, negative = null, campaign_ids) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + `/v3/${channel}/listCampaignKeywords?` + "client=" + this.state.client.id + campaign_ids + "&pagination=" + this.state.pagination.campaign_negative_pagination + "&sortBy=" + this.state.sortBy + "&orderBy=" + this.state.orderBy + "&pageSize=" + this.state.pageSize + "&" + this.state.subtab.value + "=true" + (this.state.custom_dates.start && this.state.custom_dates.end ? "&start_date=" + this.state.custom_dates.start + "&end_date=" + this.state.custom_dates.end : "&datespan=" + this.state.date_span.value) + queryString;// + "&calculate_basic_metrics=true";
            return apiRegister.call(options, url);
        },
        getKeywords: (channel, queryString = null, negative = null, campaign_ids) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + `/v3/${channel}/listKeywords?` + "client=" + this.state.client.id + campaign_ids + "&pagination=" + (channel === "bing" ? this.state.pagination.bing_pagination : (negative ? this.state.pagination.negative_pagination : this.state.pagination.positive_pagination)) + "&sortBy=" + this.state.sortBy + "&orderBy=" + this.state.orderBy + "&pageSize=" + this.state.pageSize + "&" + this.state.subtab.value + "=true" + (this.state.custom_dates.start && this.state.custom_dates.end ? "&start_date=" + this.state.custom_dates.start + "&end_date=" + this.state.custom_dates.end : "&datespan=" + this.state.date_span.value) + queryString + "&calculate_basic_metrics=true";
            return apiRegister.call(options, url);
        },
        getSearchterms: (bing = null, campaign_ids) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/google/listSearchTerms?" + "client=" + this.state.client.id + campaign_ids + "&pagination=" + (bing ? this.state.pagination.bing_search_term : this.state.pagination.search_term) + "&sortBy=" + this.state.sortBy + "&orderBy=" + this.state.orderBy + "&pageSize=" + this.state.pageSize + (this.state.custom_dates.start && this.state.custom_dates.end ? "&start_date=" + this.state.custom_dates.start + "&end_date=" + this.state.custom_dates.end : "&datespan=" + this.state.date_span.value) + "&calculate_basic_metrics=true";
            return apiRegister.call(options, url);
        },
        createAdgroupCriterion: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/google/createAdgroupCriteria?" + "client=" + this.state.client.id;
            return apiRegister.call(options, url);
        },
        createCampaignCriterion: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/google/createCampaignCriteria?" + "client=" + this.state.client.id;
            return apiRegister.call(options, url);
        },
        removeCampaignCriteria: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'DELETE', data);
            let url = apiRegister.url.api + "/v3/google/removeCampaignCriteria?" + "client=" + this.state.client.id;
            return apiRegister.call(options, url);
        },
        removeAdgroupCriteria: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'DELETE', data);
            let url = apiRegister.url.api + "/v3/google/removeAdgroupCriteria?" + "client=" + this.state.client.id;
            return apiRegister.call(options, url);
        },
        removeCampaignSharedSet: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'DELETE', data);
            let url = apiRegister.url.api + "/v3/google/removeCampaignSharedSet?" + "client=" + this.state.client.id;
            return apiRegister.call(options, url);
        },
        removeCampaignAsset: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'DELETE', data);
            let url = apiRegister.url.api + "/v3/google/removeAssetCampaign?" + "client=" + this.state.client.id;
            return apiRegister.call(options, url);
        },
        removeAdgroupAsset: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'DELETE', data);
            let url = apiRegister.url.api + "/v3/google/removeAssetAdgroup?" + "client=" + this.state.client.id;
            return apiRegister.call(options, url);
        },
        deleteCampaignAssetSets: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/google/deleteCampaignAssetSets?client=" + this.state.client.id;
            return apiRegister.call(options, url);
        },
        deleteAdgroupAssetSets: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/google/deleteAdgroupAssetSets?client=" + this.state.client.id;
            return apiRegister.call(options, url);

        },
        removeAdgroup: (data, body) => {
            console.log("data", data)
            let options = apiRegister.options(tokenRegister.get(), 'DELETE', body);
            // OLD
            // let url = `${apiRegister.url.api}/v3/adcredo/removeAdgroup?adgroupId=${data.id}&client=${this.state.client.id}&campaign_id=${data.campaign_id}`;
            // PREP FOR REMOVE ADGROUP REWORK
            let url = `${apiRegister.url.api}/v3/adcredo/removeAdgroup?adgroupId=${data.id}&ad=${data.id}&client=${this.state.client.id}&campaign_id=${data.campaign_id}&channel=${data.channel}`;
            return apiRegister.call(options, url);
        },
        removeAd: (data, body) => {
            let options = apiRegister.options(tokenRegister.get(), 'DELETE', body);
            let url = `${apiRegister.url.api}/v3/adcredo/removeAd?adgroup=${data.adgroup_id}&ad=${data.id}&client=${this.state.client.id}&channel=${data.channel}`;
            return apiRegister.call(options, url);
        },
        updateAdgroupStatus: (item, status = "pause") => {
            let options = apiRegister.options(tokenRegister.get(), 'PUT', null);
            let url = `${apiRegister.url.api}/v3/adcredo/updateAdgroupStatus?client=${this.state.client.id}&status=${status}&adgroup=${item.id}&campaign=${item.campaign_id}&channel=${item.channel}`;
            return apiRegister.call(options, url);
        },
        updateAdgroupCriteria: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/google/updateAdgroupCriteria?" + "client=" + this.state.client.id;
            return apiRegister.call(options, url);
        },
        removeCampaign: (id, data) => {
            let options = apiRegister.options(tokenRegister.get(), 'DELETE', data);
            let url = apiRegister.url.api + "/v3/adcredo/removeCampaign?id=" + id;
            return apiRegister.call(options, url);
        },
        removeCampaignReference: (id, data) => {
            let options = apiRegister.options(tokenRegister.get(), 'DELETE', data);
            let url = apiRegister.url.api + "/v3/adcredo/removeExternalCampaignRef?id=" + id + "&order=" + this.functions.getIdFromPath();
            return apiRegister.call(options, url);
        },
        createWizard: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/adcredo/createWizard";
            return apiRegister.call(options, url);
        },
        listGoogleBudgets: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/google/listBudgets?client=" + this.state.client.id;
            return apiRegister.call(options, url);
        },
        saveUserOrderStatus: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'PUT', data);
            let url = apiRegister.url.api + "/v3/adcredo/updateUserOrderStatus";
            return apiRegister.call(options, url);
        },
        getUserOrderStatus: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/getUserOrderStatus";
            return apiRegister.call(options, url);
        },
    };

    renders = {
        menuOptions: (item) => {
            let items = [];
            if (item.channel === "google") {
                if (item.ocid) {
                    if (this.state.tab.value === "campaigns") {
                        items = ["Remove reference", "Edit", "Overview", "Recommendations", "Insights", "Adgroups", "Ads", "Keywords", "Negative keywords", "Settings", "Delete"];
                        if (item.status && item.status.toLowerCase() === "enabled") {
                            items.unshift("Pause");
                        } else {
                            items.unshift("Activate");
                        }
                        items.unshift("Schedule");
                        items.unshift("Sync With Bing");
                    } else if (this.state.tab.value === "adgroups") {
                        items = ["Edit", "Overview", "Recommendations", "Ads", "Search keywords", "Negative keywords", "Search terms", "Settings", "Delete"];
                        if (item.status && item.status.toLowerCase() === "enabled") {
                            items.unshift("Pause");
                        } else {
                            items.unshift("Activate");
                        }
                    } else if (this.state.tab.value === "keywords" || this.state.tab.value === "negative_keywords") {
                        items = ["Overview", "Delete"];
                        if (this.state.tab.value === "keywords") {
                            if (item.status.toLowerCase() === "enabled") {
                                items.unshift("Pause");
                            } else {
                                items.unshift("Activate");
                            }
                        }
                    } else if (this.state.tab.value === "ads") {
                        items = ["Overview"];
                        if (item.advertising_type && item.advertising_type === "RESPONSIVE_SEARCH_AD") {
                            items.push("Edit");
                        }
                        if (item.status && item.status.toLowerCase() === "paused") {
                            items.unshift("Activate");
                        } else if (item.status && item.status.toLowerCase() === "enabled") {
                            items.unshift("Pause");
                        }
                        if (item.status && item.status.toLowerCase() !== "removed") {
                            items.push("Delete");
                        }
                        if (item.status && item.status.toLowerCase() === "removed") {
                            items = [];
                        }
                    }
                } else {
                    if (this.state.tab.value === "campaigns") {
                        items = ["Edit", "Remove reference", "Delete"];
                        if (item.status && item.status.toLowerCase() === "enabled") {
                            items.unshift("Pause");
                        } else {
                            items.unshift("Activate");
                        }
                        //items.unshift("Add new Ad");
                        items.unshift("Schedule");
                        items.unshift("Sync With Bing");
                    }
                    if (this.state.tab.value === "adgroups") {
                        items = ["Edit", "Delete"];
                        if (item.status && item.status.toLowerCase() === "enabled") {
                            items.unshift("Pause");
                        } else {
                            items.unshift("Activate");
                        }
                    } else if (this.state.tab.value === "search_terms") {
                        //"Positive to Campaign", "Negative to Campaign"
                        //"Positive to Adgroup", 
                        items = ["Add as negative to Adgroup"];
                    } else if (this.state.tab.value === "keywords") {
                        items = ["Edit positive", "Delete"];
                        if (item.status.toLowerCase() === "enabled") {
                            items.unshift("Pause");
                        } else {
                            items.unshift("Activate");
                        }
                    } else if (this.state.tab.value === "negative_keywords") {

                        items = ["Edit negative", "Delete"];
                        if (item.list) {
                            items = ["Open", "Delete"];
                        }
                    } else if (this.state.tab.value === "assets") {
                        items = ["Edit", "Delete"];
                    }
                    if (this.state.tab.value === "ads") {
                        if (item.advertising_type && item.advertising_type === "RESPONSIVE_SEARCH_AD") {
                            items.unshift("Edit");
                        }
                        if (item.status && item.status.toLowerCase() === "paused") {
                            items.push("Activate");
                        } else if (item.status && item.status.toLowerCase() === "enabled") {
                            items.unshift("Pause");
                        }
                        if (item.status && item.status.toLowerCase() !== "removed") {
                            items.push("Delete");
                        }
                        if (item.status && item.status.toLowerCase() === "removed") {
                            items = [];
                        }
                    }
                }
            } else if (item.channel === "facebook") {
                if (this.state.tab.value === "campaigns") {
                    items = ["Remove reference", "Edit", "Overview", "Delete"];
                    if (item.status === "active") {
                        items.unshift("Pause");
                    } else {
                        items.unshift("Activate");
                    }
                    items.unshift("Schedule")
                    if (this.state.campaign.channel !== "all" && item.id === this.state.campaign.id) {
                        //items.unshift("Add new Ad")
                    }
                } else if (this.state.tab.value === "adgroups") {
                    items = ["Edit", "Overview", "Delete"];
                    if (item.status === "active") {
                        items.unshift("Pause");
                    } else {
                        items.unshift("Activate");
                    }
                    items.unshift("Schedule")
                } else {
                    items = ["Overview", "Edit"];
                    if (item.status === "active") {
                        items.unshift("Pause");
                    } else {
                        items.unshift("Activate");
                    }
                }
            } else if (item.channel === "linkedin") {
                if (this.state.tab.value === "campaigns") {
                    items = ["Remove reference", "Edit", "Overview", "Delete"];
                    if (item.status && item.status.toLowerCase() === "active") {
                        items.unshift("Pause");
                    } else {
                        items.unshift("Activate");
                    }
                    //items.unshift("Schedule")
                    if (this.state.campaign.channel !== "all" && item.id === this.state.campaign.id) {
                        //items.unshift("Add new Ad")
                    }
                } else if (this.state.tab.value === "adgroups") {
                    items = ["Edit", "Overview", "Delete"];
                    if (item.status && item.status.toLowerCase() === "active") {
                        items.unshift("Pause");
                    } else {
                        items.unshift("Activate");
                    }
                    items.unshift("Schedule")
                } else {
                    items = ["Overview", "Edit"];
                    if (item.status === "active") {
                        items.unshift("Pause");
                    } else {
                        items.unshift("Activate");
                    }
                }
            } else if (item.channel === "twitter") {
                if (this.state.tab.value === "campaigns") {
                    items = ["Remove reference", "Overview", "Delete"];
                    if (item.status && item.status.toLowerCase() === "active") {
                        items.unshift("Pause");
                    } else {
                        items.unshift("Activate");
                    }
                    items.unshift("Schedule")
                } else if (this.state.tab.value === "adgroups") {
                    items = ["Overview", "Delete"];
                    if (item.status === "active") {
                        items.unshift("Pause");
                    } else {
                        items.unshift("Activate");
                    }
                    items.unshift("Schedule")
                } else {
                    items = ["Overview"];
                }
            } else if (item.channel === "tiktok") {
                if (this.state.tab.value === "campaigns") {
                    items = ["Remove reference", "Edit", "Overview", "Delete"];
                    if (this.state.campaign.channel !== "all" && item.id == this.state.campaign.id) {
                        //items.unshift("Add new Ad")
                    }
                    if (item.status && item.status.toLowerCase() === "enable") {
                        items.unshift("Pause");
                    } else {
                        items.unshift("Activate");
                    }
                    items.unshift("Schedule")
                } else if (this.state.tab.value === "adgroups") {
                    items = ["Edit", "Overview", "Delete"];
                    if (item.status === "active") {
                        items.unshift("Pause");
                    } else {
                        items.unshift("Activate");
                    }
                    items.unshift("Schedule")
                } else {
                    items = ["Overview", "Edit"];
                    if (item.ad_status && (item.ad_status === "ENABLE" || item.ad_status === "DISABLE")) {
                        if (item.ad_status === "ENABLE") {
                            items.push("Pause");
                        } else if (item.ad_status === "DISABLE") {
                            items.push("Activate");
                        }
                        items.push("Delete");
                    }
                }
            } else if (item.channel === "snapchat") {
                if (this.state.tab.value === "campaigns") {
                    items = ["Remove reference", "Edit", "Overview", "Delete"];
                    if (this.state.campaign.channel !== "all" && item.id == this.state.campaign.id) {
                        //items.unshift("Add new Ad")
                    }
                    if (item.status && item.status.toLowerCase() === "active") {
                        items.unshift("Pause");
                    } else {
                        items.unshift("Activate");
                    }
                    //items.unshift("Schedule")
                } else if (this.state.tab.value === "adgroups") {
                    items = ["Edit", "Delete"];
                    if (item.status && item.status.toLowerCase() === "active") {
                        items.unshift("Pause");
                    } else {
                        items.unshift("Activate");
                        // IF PAUSED THEN SHOW DELETE
                        // items.push("Delete")
                    }
                    items.unshift("Schedule")
                } else {
                    items = ["Overview", "Edit"];
                    if (item.status && item.status.toLowerCase() === "active") {
                        items.unshift("Pause");
                    } else if (item.status && item.status.toLowerCase() === "paused") {
                        items.unshift("Activate");
                    }
                }
            } else if (item.channel === "bing") {
                items = ["Remove reference", "Delete"];
                if (item.status && item.status.toLowerCase() === "paused") {
                    items.unshift("Activate");
                } else {
                    items.unshift("Pause");
                }
                if (this.state.tab.value === "campaigns") {
                    items.unshift("Schedule")
                }
            }
            if (this.state.tab.value === "campaigns" && typeof item.wizard === "string" && item.wizard !== "") {
                items.unshift("Preview");
            }
            if (this.state.tab.value === "campaigns") {
                items.unshift("Tags");
            }
            if (this.state.tab.value === "campaigns" || this.state.tab.value === "adgroups") {

                //items.unshift("Schedule")
            }
            if (this.state.user && this.state.user.userRole === "sales") {
                items = [];
            }
            return items;
        },
        sort: (list) => {

            let sortNumber = (list) => {
                return list.sort((x, y) => {
                    let x_value = x.row_values ? (x.row_values[this.state.sortBy] ? x.row_values[this.state.sortBy] : 0) : (x[this.state.sortBy] ? x[this.state.sortBy] : 0);
                    let y_value = y.row_values ? (y.row_values[this.state.sortBy] ? y.row_values[this.state.sortBy] : 0) : (y[this.state.sortBy] ? y[this.state.sortBy] : 0);
                    if (this.state.orderBy == 'DESC') {
                        return y_value - x_value;
                    } else {
                        return x_value - y_value;
                    }
                });
            }

            let sortCharacter = (list) => {
                return list.sort((x, y) => {
                    let x_value = x.row_values ? (x.row_values[this.state.sortBy] ? x.row_values[this.state.sortBy] : "") : (x[this.state.sortBy] ? x[this.state.sortBy] : "");
                    let y_value = y.row_values ? (y.row_values[this.state.sortBy] ? y.row_values[this.state.sortBy] : "") : (y[this.state.sortBy] ? y[this.state.sortBy] : "");
                    let a = x_value.toUpperCase(),
                        b = y_value.toUpperCase();
                    if (this.state.orderBy == 'ASC') {
                        return a == b ? 0 : a > b ? -1 : 1;
                    } else {
                        return a == b ? 0 : a > b ? 1 : -1;
                    }
                });
            }

            let sortDate = (list) => {
                return list.sort((x, y) => {
                    let x_value = x.row_values ? (x.row_values[this.state.sortBy] ? x.row_values[this.state.sortBy] : new Date()) : (x[this.state.sortBy] ? x[this.state.sortBy] : new Date());
                    let y_value = y.row_values ? (y.row_values[this.state.sortBy] ? y.row_values[this.state.sortBy] : new Date()) : (y[this.state.sortBy] ? y[this.state.sortBy] : new Date());
                    let a = new Date(x_value),
                        b = new Date(y_value);
                    if (this.state.orderBy == 'ASC') {
                        return a - b;
                    } else {
                        return b - a;
                    }
                });
            }

            if (this.state.tab.value !== "keywords" && this.state.tab.value !== "search_terms") {
                if (this.state.sortBy === "name" || this.state.sortBy === "channel" || this.state.sortBy === "status") {
                    return sortCharacter(list);
                } else if (this.state.sortBy === "start" || this.state.sortBy === "end") {
                    return sortDate(list);
                } else {
                    return sortNumber(list);
                }
            } else {
                return list;
            }

        },
        getSelectedTabs: (channels) => {
            const selectedTabs = channels.map(channel => this.state.tabs[channel] || ["all"]);
            const commonObjects = selectedTabs.reduce((accumulator, currentTab) => {
                return accumulator.filter(tab => currentTab.some(current => current.id === tab.id));
            }, selectedTabs[0]);
            return commonObjects;
        }
    };

    promisedSetState = (newState, dontSave, sektion) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {

        let google_ad = (item) => {
            return (
                <td className={"flex justify-start"}>
                    <div style={{ maxWidth: "250px" }} className="">
                        <div className="flex flex-row text-xxxs text-purple-500 flex-wrap">
                            {Array.isArray(item.headlines) && item.headlines.map((item) => { return item.text || item }).filter((item, index) => { return index < 3 }).join(" | ") + (item.headlines.map((item) => { return item.text || item }).length > 3 ? (" +" + (item.headlines.map((item) => { return item.text || item }).length - 3)) : "")}
                        </div>
                        {
                            Array.isArray(item.final_urls) && item.final_urls.length > 0 &&
                            <div className="flex truncate text-xxxs text-green-600">
                                {item.final_urls[0]}
                            </div>
                        }
                        <div className="flex flex-row truncate">
                            <div className="text-xxxs truncate">
                                {Array.isArray(item.descriptions.map((item) => { return item.text || item })) ? item.descriptions.map((item) => { return item.text || item }).join(". ") : ""}
                            </div>
                        </div>
                    </div>
                </td>
            )
        }

        let social_ad = (item) => {
            return (
                <td className={"flex justify-start mr-3"}>
                    <div style={{ maxWidth: "250px" }} className="">
                        <div className="h-10 w-10 rounded overflow-hidden">
                            {
                                item.creative && !item.creative.video &&
                                <img src={item.creative.preview} className="w-full object-cover" />
                            }
                            {
                                item.creative && item.creative.video &&
                                <video poster={""} autoPlay={false} muted={true} controls={false} className="w-full h-full">
                                    <source src={item.creative.preview} type="video/mp4" />
                                </video>
                            }
                        </div>
                    </div>
                </td>
            )
        }

        return (
            <div className="relative flex-1 flex flex-col">

                <div className="sticky z-51 top-0">
                    <TopNavigationAgent
                        onButton={async (type) => {
                            if (type === "new") {
                                this.promisedSetState({
                                    wizard_name: this.state.order.name,
                                    create_wizard: true
                                })
                            }
                        }}
                        custom_dates={true}
                        onCustomDates={async () => {
                            await this.promisedSetState({
                                cutomize_dates: true
                            });
                        }}
                        onDateSpan={async (value) => {
                            await this.promisedSetState({
                                date_span: value,
                                custom_dates: {
                                    start: false,
                                    end: false
                                }
                            });
                            if (this.state.tab.value === "campaigns") {
                                this.functions.getCampaignsData();
                            } else if (this.state.tab.value === "adgroups") {
                                this.functions.getAdgroupsData();
                            } else if (this.state.tab.value === "ads") {
                                this.functions.getAdsData();
                            } else if (this.state.tab.value === "keywords") {
                                [...new Set(this.state.selected_channels)].forEach(item => {
                                    if (this[item].getKeywords) {
                                        this[item].getKeywords(true)
                                    }
                                })
                                //this.google.getKeywords(true);
                            } else if (this.state.tab.value === "negative_keywords") {
                                [...new Set(this.state.selected_channels)].forEach(item => {
                                    if (this[item].getKeywords) {
                                        this[item].getNegativeKeywords(true)
                                    }
                                })
                            } else if (this.state.tab.value === "search_terms") {
                                [...new Set(this.state.selected_channels)].forEach(item => {
                                    if (this[item].getSearchterms) {
                                        this[item].getSearchterms(true)
                                    }
                                })
                            }
                        }}
                        onAdvancedFilter={async (value, type) => {
                            this.state.advancedFilters = this.state.advancedFilters.map((item) => {
                                if (item.type === type) {
                                    item.selected = value;
                                }
                                return item;
                            });
                            await this.promisedSetState({
                                advancedFilters: this.state.advancedFilters,
                            });
                            this.functions.saveUserOrderStatus();
                            if (type === "orders") {
                                let id = window.location.pathname.match(/[0-9a-z]{20,}/g)[0];
                                let path = window.location.pathname.replace(id, value.id);
                                window.open(path, "_self");
                            }

                        }}

                        showAdvanced={!this.state.loading}
                        advancedFilter={true}
                        advancedFilters={this.state.advancedFilters}
                        showDateSpan={!this.state.loading && this.state.campaigns.length > 0}
                        dateSpan={this.state.custom_dates.start ? { value: true, name: moment(this.state.custom_dates.start).format("YYYY/MM/DD") + " - " + moment(this.state.custom_dates.end).format("YYYY/MM/DD") } : this.state.date_span}
                        hideUserDropdown={true}
                        breadcrumb={"Campaigns"}
                        history={this.props.history}
                        buttons={this.state.user && this.state.user.userRole !== "sales" ? [
                            { text: "New campaign", icon: FolderIcon, value: "new", loading: this.state.loading_wizard, show: !this.state.loading && this.state.order.status !== "archived" }
                        ] : []}
                        buttonTypes={{}}
                        buttonLoaders={{}}
                        showClient={true}
                        lockClient={false}
                        onClient={async (client) => {
                            await this.promisedSetState({
                                client_change: client
                            });
                            this.functions.getOrders(true, false, false)
                        }}
                        onAdvancedFilterPagination={async (value) => {
                            if (value === "orders") {
                                this.state.advancedFilters[1].page = this.state.advancedFilters[1].page + 1;
                                await this.promisedSetState({
                                    advancedFilters: this.state.advancedFilters,
                                    order_page: this.state.advancedFilters[1].page
                                });
                                this.functions.getOrders(false, false, true);
                            }
                        }}
                        onAdvancedFilterSearch={async (value, type) => {
                            if (type === "orders") {
                                this.state.advancedFilters[1].page = 1;
                                this.state.advancedFilters[1].search_value = value ? value : "";
                                await this.promisedSetState({
                                    advancedFilters: this.state.advancedFilters,
                                    order_page: 1,
                                    order_search: value ? value : ""
                                });
                                this.functions.getOrders(false, this.state.order_search);
                            }
                        }}
                        client={this.state.client_change ? this.state.client_change : this.state.client}
                    />
                </div>

                {/* TAGS */}
                <SlideoutTailwind
                    open={this.state.tab_tags ? true : false}
                    title={"Tags"}
                    submitButtonText={"Save"}
                    noPadding={true}
                    noSubmit={true}
                    secondaryButton={false}
                    onCancel={() => {
                        this.promisedSetState({ tab_tags: false });
                    }}
                    onSubmit={async () => {
                        await this.refs.CampaignTags.functions.update();
                        this.promisedSetState({ tab_tags: false });
                    }}
                >
                    {
                        this.state.tab_tags &&
                        <CampaignTags
                            ref="CampaignTags"
                            campaign={this.state.tab_tags}
                            onUpdateTags={async (tags) => {
                                if (Array.isArray(tags) && this.state.tab_tags && this.state.tab_tags.internal) {
                                    this.state.campaigns = this.state.campaigns.map((item) => {
                                        if (item.internal === this.state.tab_tags.internal) {
                                            item.tags = tags;
                                        }
                                        return item;
                                    });
                                    this.promisedSetState({ campaigns: this.state.campaigns });
                                }
                            }}
                        />
                    }
                </SlideoutTailwind>

                {/* SLIDEOUT NEW DRAFT */}
                <SlideoutTailwind
                    title={"New Draft"}
                    submitButtonText={"Create"}
                    disabled={this.state.disabled}
                    medium={true}
                    secondaryButton={false}
                    open={this.state.create_wizard}
                    onCancel={() => {
                        this.promisedSetState({ create_wizard: false });
                    }}
                    onSubmit={async () => {
                        await this.promisedSetState({ edit_loader: true });
                        this.refs.newCampaignwizard.functions.create();
                    }}
                    loader={this.state.edit_loader}
                >
                    {
                        this.state.create_wizard &&
                        <NewCampaignWizard
                            ref="newCampaignwizard"
                            order={this.state.order}
                            client={this.state.client}
                            onDisable={async (value) => {
                                await this.promisedSetState({
                                    disabled: value
                                });
                            }}
                            input={this.state.wizard_name}
                            onNewDraft={(value, data = false) => {
                                this.promisedSetState({ wizard_name: value });
                                this.functions.createWizard(data);
                            }}
                            onNewDraftFromTemplate={(value, data = false) => {
                                this.functions.createWizardFromTemplate(value, data);
                            }}

                        />

                    }

                </SlideoutTailwind>

                {/* SLIDEOUT NEW DRAFT */}
                <SlideoutTailwind
                    title={"Customize"}
                    submitButtonText={"Save"}
                    disabled={this.state.disabled}
                    medium={true}
                    secondaryButton={false}
                    open={this.state.cutomize_open}
                    onCancel={() => {
                        this.promisedSetState({ cutomize_open: false });
                    }}
                    onSubmit={async () => {
                        await this.promisedSetState({ edit_loader: true });
                        this.refs.CustomizeOrderMetrics.functions.create();
                    }}
                    loader={this.state.edit_loader}
                >
                    {
                        this.state.cutomize_open &&
                        <CustomizeOrderMetrics
                            ref="CustomizeOrderMetrics"
                            onDisable={async (value) => {
                                await this.promisedSetState({
                                    disabled: value
                                });
                            }}
                            order={this.state.order}
                            metrics={this.state.order.metrics ? this.state.order.metrics : {}}
                            onUpdate={async (value) => {
                                await this.promisedSetState({ reload_table: true });
                                this.state.order.metrics = value;
                                await this.promisedSetState({ cutomize_open: false, order: this.state.order, edit_loader: false, metrics: Object.keys(value).length > 0 ? Object.keys(value).map((item) => { return { name: value[item].name, value: value[item].name, min_width: '250px', metric: true, id: value[item].id } }) : this.state.default_metrics });
                                if (this.state.tab.value === 'campaigns') {
                                    this.functions.getCampaignsData();
                                } else if (this.state.tab.value === "adgroups") {
                                    this.functions.getAdgroupsData();
                                } else if (this.state.tab.value === "ads") {
                                    this.functions.getAdsData();
                                }

                                await this.promisedSetState({ reload_table: false });

                                //window.location.reload();
                            }}

                        />

                    }

                </SlideoutTailwind>

                {/* SLIDEOUT NEW CUSTOM DATES */}
                <SlideoutTailwind
                    title={"Date span"}
                    submitButtonText={"Save"}
                    disabled={this.state.disabled}
                    secondaryButton={false}
                    open={this.state.cutomize_dates}
                    onCancel={() => {
                        this.promisedSetState({ cutomize_dates: false });
                    }}
                    onSubmit={async () => {
                        await this.promisedSetState({ edit_loader: true });
                        this.refs.CustomizeOrderDates.functions.create();
                    }}
                    loader={this.state.edit_loader}
                >
                    {
                        this.state.cutomize_dates &&
                        <CustomizeOrderDates
                            ref="CustomizeOrderDates"
                            onDisable={async (value) => {
                                await this.promisedSetState({
                                    disabled: value
                                });
                            }}
                            order={this.state.order}
                            dates={this.state.custom_dates.start ? this.state.custom_dates : false}
                            onUpdate={async (value) => {
                                await this.promisedSetState({ custom_dates: value, cutomize_dates: false, edit_loader: false });
                                await this.promisedSetState({ reload_charts: true });
                                await this.promisedSetState({ reload_charts: false });
                                if (this.state.tab.value === "campaigns") {
                                    this.functions.getCampaignsData();
                                } else if (this.state.tab.value === "adgroups") {
                                    this.functions.getAdgroupsData();
                                } else if (this.state.tab.value === "ads") {
                                    this.functions.getAdsData();
                                } else if (this.state.tab.value === "keywords") {
                                    this.google.getKeywords(true);
                                } else if (this.state.tab.value === "negative_keywords") {
                                    this.google.getNegativeKeywords(true);
                                } else if (this.state.tab.value === "search_terms") {
                                    this.google.getSearchterms(true);
                                }
                            }}


                        />

                    }

                </SlideoutTailwind>

                {/* SLIDEOUT NEGATIVE KEYWORD LIST */}
                <SlideoutTailwind
                    title={this.state.view_negative_list ? this.state.view_negative_list.name : "Negative keyword list"}
                    submitButtonText={"Save"}
                    disabled={true}
                    medium={true}
                    secondaryButton={false}
                    open={this.state.view_negative_list ? true : false}
                    onCancel={() => {
                        this.promisedSetState({ view_negative_list: false });
                    }}
                    onSubmit={async () => {

                    }}
                    loader={this.state.edit_loader}
                >
                    {
                        this.state.view_negative_list &&
                        <NegativeKeywordList
                            ref="NegativeKeywordList"
                            onDisable={async (value) => {
                                await this.promisedSetState({
                                    disabled: value
                                });
                            }}
                            list={this.state.view_negative_list}
                            onUpdate={async (value) => {


                            }}


                        />

                    }

                </SlideoutTailwind>


                {/* SLIDEOUT SCHEDULE */}
                <SlideoutTailwind
                    title={"Schedule"}
                    submitButtonText={"Update"}
                    large={true}
                    secondaryButton={false}
                    disabled={this.state.disabled}
                    open={(this.state.schedule_campaign || this.state.schedule_adgroup) ? true : false}
                    onCancel={() => {
                        this.promisedSetState({ schedule_campaign: false, schedule_adgroup: false, disabled: false });
                    }}
                    onSubmit={() => {
                        if (this.state.schedule_campaign) {
                            this.refs.CampaignScheduler.functions.updateSchedule();
                        } else if (this.state.schedule_adgroup) {
                            this.refs.AdgroupScheduler.functions.updateSchedule();
                        }
                    }}
                    loader={this.state.schedule_loader}
                >
                    {
                        this.state.schedule_campaign &&
                        <CampaignScheduler
                            ref="CampaignScheduler"
                            campaign={JSON.parse(JSON.stringify(this.state.schedule_campaign))}
                            order={JSON.parse(JSON.stringify(this.state.order))}
                            onLoader={(loading) => {
                                this.promisedSetState({ schedule_loader: loading });
                            }}
                            onDisable={async (value) => {
                                await this.promisedSetState({
                                    disabled: value
                                });
                            }}
                            onSuccess={(value) => {
                                if (value) {
                                    this.state.campaigns = this.state.campaigns.map((item) => {
                                        if (item.internal === value.internal) {
                                            item.scheduleDatesActivated = value.scheduleDatesActivated;
                                            item.scheduleDates = value.scheduleDates;
                                            item.budgetPlanerActivated = value.budgetPlanerActivated;
                                            item.budgetPlanerTotal = value.budgetPlanerTotal;
                                            item.scheduleActivated = value.scheduleActivated;
                                            item.scheduleMonday = value.scheduleMonday;
                                            item.scheduleTuesday = value.scheduleTuesday;
                                            item.scheduleWednesday = value.scheduleWednesday;
                                            item.scheduleThursday = value.scheduleThursday;
                                            item.scheduleFriday = value.scheduleFriday;
                                            item.scheduleSaturday = value.scheduleSaturday;
                                            item.scheduleSunday = value.scheduleSunday;
                                            item.scheduleStartDate = value.scheduleStartDate;
                                        }
                                        return item;
                                    });
                                    this.promisedSetState({ schedule_campaign: false, campaigns: this.state.campaigns });
                                }
                            }}
                        />
                    }
                    {
                        this.state.schedule_adgroup &&
                        <AdgroupScheduler
                            ref="AdgroupScheduler"
                            adgroup={JSON.parse(JSON.stringify(this.state.schedule_adgroup))}
                            order={JSON.parse(JSON.stringify(this.state.order))}
                            onLoader={(loading) => {
                                this.promisedSetState({ schedule_loader: loading });
                            }}
                            onDisable={async (value) => {
                                await this.promisedSetState({
                                    disabled: value
                                });
                            }}
                            onSuccess={(value) => {
                                if (value) {
                                    this.state.adgroups = this.state.adgroups.map((item) => {
                                        if (item.internal === value.internal) {
                                            item.scheduleDatesActivated = value.scheduleDatesActivated;
                                            item.scheduleDates = value.scheduleDates;
                                            item.budgetPlanerActivated = value.budgetPlanerActivated;
                                            item.budgetPlanerTotal = value.budgetPlanerTotal;
                                            item.scheduleActivated = value.scheduleActivated;
                                            item.scheduleMonday = value.scheduleMonday;
                                            item.scheduleTuesday = value.scheduleTuesday;
                                            item.scheduleWednesday = value.scheduleWednesday;
                                            item.scheduleThursday = value.scheduleThursday;
                                            item.scheduleFriday = value.scheduleFriday;
                                            item.scheduleSaturday = value.scheduleSaturday;
                                            item.scheduleSunday = value.scheduleSunday;
                                            item.scheduleStartDate = value.scheduleStartDate;
                                        }
                                        return item;
                                    });
                                    this.promisedSetState({ schedule_adgroup: false, adgroups: this.state.adgroups });
                                }
                            }}
                        />
                    }
                </SlideoutTailwind>

                <SlideoutTailwindAsset
                    title={"Ad"}
                    full={true}
                    preview_data={this.state.preview_data ? this.state.preview_data : false}
                    disabled={this.state.disabled}
                    submitButtonText={"Save"}
                    client={this.state.client}
                    open={this.state.open_ad ? true : false}
                    noPadding={true}
                    onChannel={(channel) => {

                    }}
                    onPreviewChannel={(channel) => {

                    }}
                    onUpdateChat={(data) => {

                    }}
                    onAddHeadlines={(data) => {

                    }}
                    onAddDescriptions={(data) => {

                    }}
                    onRemoveDescription={(data) => {

                    }}
                    onRemoveHeadline={(data) => {

                    }}
                    chat={this.state.ai_result ? this.state.ai_result : []}
                    onCancel={() => {
                        this.promisedSetState({ update: {}, open_ad: false, edit: false });
                    }}
                    onSubmit={() => {
                        if (this.state.open_ad.level === "ad" && this.state.open_ad.channel === "tiktok") {
                            this.refs.EditLiveTikTokAd.functions.update();
                        } else if (this.state.open_ad.level === "ad" && this.state.open_ad.channel === "snapchat") {
                            this.refs.EditLiveSnapchatAd.functions.update();
                        } else if (this.state.open_ad.level === "ad" && this.state.open_ad.channel === "facebook") {
                            this.refs.EditLiveFacebookAd.functions.update();
                        } else if (this.state.open_ad.level === "ad" && this.state.open_ad.channel === "linkedin") {
                            this.refs.EditLiveLinkedinAd.functions.update();
                        } else if (this.state.open_ad.level === "ad" && this.state.open_ad.channel === "twitter") {
                        }
                    }}
                    changePreview={async (preview) => {
                        this.state.preview_data.preview = preview;
                        await this.promisedSetState({
                            preview_data: this.state.preview_data
                        });

                    }}
                    onSecondarySubmit={() => {
                        //this.refs.CreateOrderCreativeEdit.functions.pushCarousel();
                    }}
                    loader={this.state.edit_loader}
                >

                    {/* FACEBOOK */}
                    {
                        this.state.open_ad &&
                        this.state.open_ad.level === "ad" &&
                        this.state.open_ad.channel === "facebook" &&
                        <EditLiveFacebookAd
                            ref="EditLiveFacebookAd"
                            wizard={false}
                            ad={JSON.parse(JSON.stringify(this.state.open_ad))}
                            client={this.state.client}
                            onLoader={(loading) => {
                                this.promisedSetState({ edit_loader: loading });
                            }}
                            onDisable={async (disable) => {
                                await this.promisedSetState({ disabled: disable });
                            }}
                            onSuccess={(value) => {
                                if (value) {
                                    this.functions.getAds();
                                    this.promisedSetState({ open_ad: false });
                                }
                            }}
                            updatePreview={async (data) => {
                                await this.promisedSetState({
                                    preview_data: data
                                });
                            }}
                        />
                    }
                    {/* LINKEDIN */}
                    {
                        this.state.open_ad &&
                        this.state.open_ad.level === "ad" &&
                        this.state.open_ad.channel === "linkedin" &&
                        <EditLiveLinkedinAd
                            ref="EditLiveLinkedinAd"
                            wizard={false}
                            ad={JSON.parse(JSON.stringify(this.state.open_ad))}
                            client={this.state.client}
                            onLoader={(loading) => {
                                this.promisedSetState({ edit_loader: loading });
                            }}
                            onDisable={async (disable) => {
                                await this.promisedSetState({ disabled: disable });
                            }}
                            onSuccess={(value) => {
                                if (value) {
                                    this.functions.getAds();
                                    this.promisedSetState({ open_ad: false });
                                }
                            }}
                            updatePreview={async (data) => {
                                await this.promisedSetState({
                                    preview_data: data
                                });
                            }}
                        />
                    }
                    {/* TIKTOK */}
                    {
                        this.state.open_ad &&
                        this.state.open_ad.level === "ad" &&
                        this.state.open_ad.channel === "tiktok" &&
                        <EditLiveTikTokAd
                            ref="EditLiveTikTokAd"
                            wizard={false}
                            ad={JSON.parse(JSON.stringify(this.state.open_ad))}
                            client={this.state.client}
                            onLoader={(loading) => {
                                this.promisedSetState({ edit_loader: loading });
                            }}
                            onDisable={async (disable) => {
                                await this.promisedSetState({ disabled: disable });
                            }}
                            onSuccess={(value) => {
                                if (value) {
                                    this.functions.getAds();
                                    this.promisedSetState({ open_ad: false });
                                }
                            }}
                            updatePreview={async (data) => {
                                await this.promisedSetState({
                                    preview_data: data
                                });
                            }}
                            campaign={this.state.temp_selected_item && this.state.temp_selected_item.campaign_id ? this.state.temp_selected_item.campaign_id : false}
                        />
                    }
                    {/* SNAPCHAT */}
                    {
                        this.state.open_ad &&
                        this.state.open_ad.level === "ad" &&
                        this.state.open_ad.channel === "snapchat" &&
                        <EditLiveSnapchatAd
                            ref="EditLiveSnapchatAd"
                            wizard={false}
                            ad={JSON.parse(JSON.stringify(this.state.open_ad))}
                            client={this.state.client}
                            onLoader={(loading) => {
                                this.promisedSetState({ edit_loader: loading });
                            }}
                            onSuccess={(value) => {
                                if (value) {
                                    this.functions.getAds();
                                    this.promisedSetState({ open_ad: false });
                                }
                            }}
                            updatePreview={async (data) => {
                                await this.promisedSetState({
                                    preview_data: data
                                });
                            }}
                        />
                    }
                </SlideoutTailwindAsset>

                {/* SLIDEOUT UPDATE */}
                <SlideoutTailwind
                    title={this.state.edit ? this.state.edit.level : ""}
                    submitButtonText={"Update"}
                    large={this.state.edit && this.state.edit.level === "ad" && this.state.edit.channel === "google" ? false : true}
                    full={this.state.edit && this.state.edit.level === "ad" && this.state.edit.channel === "google" ? true : false}
                    secondaryButton={false}
                    search_ad={this.state.edit && this.state.edit.level === "ad" && this.state.edit.channel === "google" ? true : false}
                    group={this.state.group ? this.state.group : {}}
                    keywordList={this.state.group ? { keywords: this.state.group.keywords } : false}
                    open={this.state.edit ? true : false}
                    onCancel={() => {
                        this.promisedSetState({ update: {}, edit: false, group: false });
                    }}
                    client={this.state.client}
                    onAddKeyword={(person) => {
                        if (this.state.edit && this.state.edit.level === "ad" && this.state.edit.channel === "google") {
                            this.refs.EditLiveGoogleSearchAd.functions.addKeyword(person);
                        }
                    }}
                    onAddHeadlines={(data) => {
                        if (this.state.edit && this.state.edit.level === "ad" && this.state.edit.channel === "google") {
                            this.refs.EditLiveGoogleSearchAd.functions.addHeadlines(data);
                        }
                    }}
                    onAddDescriptions={(data) => {
                        if (this.state.edit && this.state.edit.level === "ad" && this.state.edit.channel === "google") {
                            this.refs.EditLiveGoogleSearchAd.functions.addDescriptions(data);
                        }
                    }}
                    onUpdateChat={(data) => {
                        if (this.state.edit && this.state.edit.level === "ad" && this.state.edit.channel === "google") {
                            this.refs.EditLiveGoogleSearchAd.functions.updateOpenAi(data);
                        }
                    }}
                    onRemoveDescription={(data) => {
                        if (this.state.edit && this.state.edit.level === "ad" && this.state.edit.channel === "google") {
                            this.refs.EditLiveGoogleSearchAd.functions.removeDescription(data);
                        }
                    }}
                    onRemoveHeadline={(data) => {
                        if (this.state.edit && this.state.edit.level === "ad" && this.state.edit.channel === "google") {
                            this.refs.EditLiveGoogleSearchAd.functions.removeHeadline(data);
                        }
                    }}
                    open_chat={false}
                    chat={this.state.ai_result ? this.state.ai_result : []}
                    disabled={this.state.disabled}
                    onSubmit={() => {
                        if (this.state.edit && this.state.edit.channel === "facebook") {
                            if (this.state.edit.level === "campaign") {
                                this.refs.EditLiveFacebookCampaign.functions.update();
                            } else if (this.state.edit.level === "adgroup") {
                                this.refs.EditLiveFacebookAdgroup.functions.update();
                            }
                        } else if (this.state.edit && this.state.edit.channel === "tiktok") {
                            if (this.state.edit.level === "campaign") {
                                this.refs.EditLiveTikTokCampaign.functions.update();
                            } else if (this.state.edit.level === "adgroup") {
                                this.refs.EditLiveTikTokAdgroup.functions.update();
                            }
                        } else if (this.state.edit && this.state.edit.channel === "snapchat") {
                            if (this.state.edit.level === "campaign") {
                                this.refs.EditLiveSnapchatCampaign.functions.update();
                            } else if (this.state.edit.level === "adgroup") {
                                this.refs.EditLiveSnapchatAdgroup.functions.update();
                            }
                        } else if (this.state.edit && this.state.edit.channel === "google") {
                            if (this.state.edit.level === "campaign") {
                                this.refs.EditLiveGoogleCampaign.functions.update();
                            } else if (this.state.edit.level === "adgroup") {
                                this.refs.EditLiveGoogleAdgroup.functions.update();
                            } else if (this.state.edit.level === "ad") {
                                this.refs.EditLiveGoogleSearchAd.functions.update();
                            } else if (this.state.edit.level === "asset") {
                                this.refs.EditLiveGoogleAssets.functions.update();
                            }
                        } else if (this.state.edit && this.state.edit.channel === "linkedin") {
                            if (this.state.edit.level === "campaign") {
                                this.refs.EditLiveLinkedinCampaign.functions.update();
                            } else if (this.state.edit.level === "adgroup") {
                                this.refs.EditLiveLinkedinAdgroup.functions.update();
                            }
                        }
                    }}
                    loader={this.state.edit_loader}
                >

                    {/* FACEBOOK */}
                    {
                        this.state.edit &&
                        this.state.edit.level === "campaign" &&
                        this.state.edit.channel === "facebook" &&
                        <EditLiveFacebookCampaign
                            ref="EditLiveFacebookCampaign"
                            wizard={false}
                            campaign={JSON.parse(JSON.stringify(this.state.edit))}
                            client={this.state.client}
                            onLoader={(loading) => {
                                this.promisedSetState({ edit_loader: loading });
                            }}
                            onPauseCampaign={(value) => {
                                if (value) this.functions.pauseCampaign(this.state.edit);
                            }}
                            onActivateCampaign={(value) => {
                                if (value) this.functions.activateCampaign(this.state.edit);
                            }}
                            onSuccess={async (value) => {
                                if (value) {
                                    this.functions.getCampaignsData(this.state.edit);
                                    this.promisedSetState({ edit: false, temp_camp: null });
                                }
                            }}
                        />
                    }
                    {
                        this.state.edit &&
                        this.state.edit.level === "adgroup" &&
                        this.state.edit.channel === "facebook" &&
                        <EditLiveFacebookAdgroup
                            ref="EditLiveFacebookAdgroup"
                            wizard={false}
                            adgroup={JSON.parse(JSON.stringify(this.state.edit))}
                            client={this.state.client}
                            onPauseAdgroup={(value) => {
                                if (value) this.functions.pauseAdgroup(this.state.edit);
                            }}
                            onActivateAdgroup={(value) => {
                                if (value) this.functions.activateAdgroup(this.state.edit);
                            }}
                            onLoader={(loading) => {
                                this.promisedSetState({ edit_loader: loading });
                            }}
                            onSuccess={(value) => {
                                if (value) {
                                    this.functions.getAdgroups();
                                    this.promisedSetState({ edit: false });
                                }
                            }}
                        />
                    }



                    {/* TIKTOK */}
                    {
                        this.state.edit &&
                        this.state.edit.level === "campaign" &&
                        this.state.edit.channel === "tiktok" &&
                        <EditLiveTikTokCampaign
                            ref="EditLiveTikTokCampaign"
                            wizard={false}
                            campaign={JSON.parse(JSON.stringify(this.state.edit))}
                            client={this.state.client}
                            onPauseCampaign={(value) => {
                                if (value) this.functions.pauseCampaign(this.state.edit);
                            }}
                            onActivateCampaign={(value) => {
                                if (value) this.functions.activateCampaign(this.state.edit);
                            }}
                            onLoader={(loading) => {
                                this.promisedSetState({ edit_loader: loading });
                            }}
                            onSuccess={(value) => {
                                if (value) {
                                    this.functions.getCampaignsData(this.state.edit);
                                    this.promisedSetState({ edit: false });
                                }
                            }}
                        />
                    }
                    {
                        this.state.edit &&
                        this.state.edit.level === "adgroup" &&
                        this.state.edit.channel === "tiktok" &&
                        <EditLiveTikTokAdgroup
                            ref="EditLiveTikTokAdgroup"
                            wizard={false}
                            adgroup={JSON.parse(JSON.stringify(this.state.edit))}
                            client={this.state.client}
                            onLoader={(loading) => {
                                this.promisedSetState({ edit_loader: loading });
                            }}
                            onPauseAdgroup={(value) => {
                                if (value) this.functions.pauseAdgroup(this.state.edit);
                            }}
                            onActivateAdgroup={(value) => {
                                if (value) this.functions.activateAdgroup(this.state.edit);
                            }}
                            onSuccess={(value) => {
                                if (value) {
                                    this.functions.getAdgroupsData(this.state.edit);
                                    this.promisedSetState({ edit: false });
                                }
                            }}
                        />
                    }

                    {/* SNAPCHAT */}
                    {
                        this.state.edit &&
                        this.state.edit.level === "campaign" &&
                        this.state.edit.channel === "snapchat" &&
                        <EditLiveSnapchatCampaign
                            ref="EditLiveSnapchatCampaign"
                            wizard={false}
                            campaign={JSON.parse(JSON.stringify(this.state.edit))}
                            client={this.state.client}
                            onPauseCampaign={(value) => {
                                if (value) this.functions.pauseCampaign(this.state.edit);
                            }}
                            onActivateCampaign={(value) => {
                                if (value) this.functions.activateCampaign(this.state.edit);
                            }}
                            onLoader={(loading) => {
                                this.promisedSetState({ edit_loader: loading });
                            }}
                            onSuccess={(value) => {
                                if (value) {
                                    this.functions.getCampaignsData(this.state.edit);
                                    this.promisedSetState({ edit: false });
                                }
                            }}
                        />
                    }
                    {
                        this.state.edit &&
                        this.state.edit.level === "adgroup" &&
                        this.state.edit.channel === "snapchat" &&
                        <EditLiveSnapchatAdgroup
                            ref="EditLiveSnapchatAdgroup"
                            wizard={false}
                            adgroup={JSON.parse(JSON.stringify(this.state.edit))}
                            campaigns={JSON.parse(JSON.stringify(this.state.campaigns.filter((item) => { return item.id == this.state.edit.campaign_id })))}
                            client={this.state.client}
                            onPauseAdgroup={(value) => {
                                if (value) this.functions.pauseAdgroup(this.state.edit);
                            }}
                            onActivateAdgroup={(value) => {
                                if (value) this.functions.activateAdgroup(this.state.edit);
                            }}
                            onLoader={(loading) => {
                                this.promisedSetState({ edit_loader: loading });
                            }}
                            onSuccess={(value) => {
                                if (value) {
                                    this.functions.getAdgroupsData(this.state.edit);
                                    this.promisedSetState({ edit: false });
                                }
                            }}
                        />
                    }


                    {/* GOOGLE */}
                    {
                        this.state.edit &&
                        this.state.edit.level === "campaign" &&
                        this.state.edit.channel === "google" &&
                        <EditLiveGoogleCampaign
                            ref="EditLiveGoogleCampaign"
                            wizard={false}
                            campaign={JSON.parse(JSON.stringify(this.state.edit))}
                            client={this.state.client}
                            onPauseCampaign={(value) => {
                                if (value) this.functions.pauseCampaign(this.state.edit);
                            }}
                            onActivateCampaign={(value) => {
                                if (value) this.functions.activateCampaign(this.state.edit);
                            }}
                            onLoader={(loading) => {
                                this.promisedSetState({ edit_loader: loading });
                            }}
                            onSuccess={(value) => {
                                if (value) {
                                    this.functions.getCampaignsData(this.state.edit);
                                    this.promisedSetState({ edit: false });
                                }
                            }}
                        />
                    }
                    {
                        this.state.edit &&
                        this.state.edit.level === "asset" &&
                        this.state.edit.channel === "google" &&
                        <EditLiveGoogleAssets
                            ref="EditLiveGoogleAssets"
                            asset={JSON.parse(JSON.stringify(this.state.edit))}
                            client={this.state.client}
                            onLoader={(loading) => {
                                this.promisedSetState({ edit_loader: loading });
                            }}
                            onSuccess={(value) => {
                                if (value) {
                                    this.functions.getAssets();
                                    this.promisedSetState({ edit: false });
                                }
                            }}
                            onDisable={async (value) => {
                                await this.promisedSetState({
                                    disabled: value
                                });
                            }}
                        />
                    }
                    {
                        this.state.edit &&
                        this.state.edit.level === "adgroup" &&
                        this.state.edit.channel === "google" &&
                        <EditLiveGoogleAdgroup
                            ref="EditLiveGoogleAdgroup"
                            wizard={false}
                            adgroup={JSON.parse(JSON.stringify(this.state.edit))}
                            client={this.state.client}
                            onPauseAdgroup={(value) => {
                                if (value) this.functions.pauseAdgroup(this.state.edit);
                            }}
                            onActivateAdgroup={(value) => {
                                if (value) this.functions.activateAdgroup(this.state.edit);
                            }}
                            onLoader={(loading) => {
                                this.promisedSetState({ edit_loader: loading });
                            }}
                            onSuccess={(value) => {
                                if (value) {
                                    this.functions.getAdgroupsData(this.state.edit);
                                    this.promisedSetState({ edit: false });
                                }
                            }}
                        />
                    }
                    {
                        this.state.edit &&
                        this.state.edit.level === "ad" &&
                        this.state.edit.channel === "google" &&
                        <EditLiveGoogleSearchAd
                            ref="EditLiveGoogleSearchAd"
                            wizard={false}
                            ad={JSON.parse(JSON.stringify(this.state.edit))}
                            client={this.state.client}
                            updatePreview={async (value) => {
                                await this.promisedSetState({ group: value });
                            }}
                            /*onPauseAdgroup={(value) => {
                                if (value) this.functions.pauseAdgroup(this.state.edit);
                            }}
                            onActivateAdgroup={(value) => {
                                if (value) this.functions.activateAdgroup(this.state.edit);
                            }}*/
                            onLoader={(loading) => {
                                this.promisedSetState({ edit_loader: loading });
                            }}
                            onSuccess={(value) => {
                                if (value) {
                                    this.functions.getAdsData(this.state.edit);
                                    this.promisedSetState({ edit: false });
                                }
                            }}
                            onDisable={async (value) => {
                                await this.promisedSetState({
                                    disabled: value
                                });
                            }}
                        />
                    }


                    {/* LINKEDIN */}
                    {
                        this.state.edit &&
                        this.state.edit.level === "campaign" &&
                        this.state.edit.channel === "linkedin" &&
                        <EditLiveLinkedinCampaign
                            ref="EditLiveLinkedinCampaign"
                            wizard={false}
                            campaign={JSON.parse(JSON.stringify(this.state.edit))}
                            client={this.state.client}
                            onLoader={(loading) => {
                                this.promisedSetState({ edit_loader: loading });
                            }}
                            onPauseCampaign={(value) => {
                                if (value) this.functions.pauseCampaign(this.state.edit);
                            }}
                            onActivateCampaign={(value) => {
                                if (value) this.functions.activateCampaign(this.state.edit);
                            }}
                            onSuccess={(value) => {
                                if (value) {
                                    this.functions.getCampaignsData(this.state.edit);
                                    this.promisedSetState({ edit: false });
                                }
                            }}
                        />
                    }
                    {
                        this.state.edit &&
                        this.state.edit.level === "adgroup" &&
                        this.state.edit.channel === "linkedin" &&
                        <EditLiveLinkedinAdgroup
                            ref="EditLiveLinkedinAdgroup"
                            wizard={false}
                            adgroup={JSON.parse(JSON.stringify(this.state.edit))}
                            client={this.state.client}
                            onLoader={(loading) => {
                                this.promisedSetState({ edit_loader: loading });
                            }}
                            onPauseAdgroup={(value) => {
                                if (value) this.functions.pauseAdgroup(this.state.edit);
                            }}
                            onActivateAdgroup={(value) => {
                                if (value) this.functions.activateAdgroup(this.state.edit);
                            }}
                            onSuccess={(value) => {
                                if (value) {
                                    this.functions.getAdgroupsData(this.state.edit);
                                    this.promisedSetState({ edit: false });
                                }
                            }}
                        />
                    }

                </SlideoutTailwind>
                {/* SYNC TO BING */}
                <SlideoutTailwind
                    title={"Sync campaign with Bing"}
                    submitButtonText={"Create"}
                    small={true}
                    secondaryButton={false}
                    open={this.state.syncToBing ? true : false}
                    onCancel={() => {
                        this.promisedSetState({ update: {}, edit: false, syncToBing: false });
                    }}
                    onSubmit={() => {
                        this.refs.SyncWithBing.functions.syncWitnBing()
                    }}
                    loader={this.state.edit_loader}
                >
                    {
                        this.state.syncToBing &&
                        <SyncWithBing
                            ref="SyncWithBing"
                            campaign={JSON.parse(JSON.stringify(this.state.campaign))}
                            client={this.state.client}
                            onLoader={(loading) => {
                                this.promisedSetState({ edit_loader: loading });
                            }}
                            onSuccess={(value) => {
                                const index = this.state.campaigns.findIndex(item => item.importId === value.importedCampaign.importId);
                                index >= 0 ? (this.state.campaigns[index] = value.importedCampaign) : this.state.campaigns.push(value.importedCampaign);
                                this.promisedSetState({ syncToBing: false, importAlertShow: true, update: {}, edit: false, campaigns: this.state.campaigns });
                            }}
                            onError={(value) => {
                                this.promisedSetState({ edit_loader: false });
                            }}
                        />
                    }
                </SlideoutTailwind>

                {/*DELETE MODAL*/}
                <WarningModalTailwind
                    open={this.state.remove ? true : false}
                    title={"Delete"}
                    description={'Are you sure you want to DELETE ? It will delete it from its external platform'}
                    cancelButtonText={"cancel"}
                    submitButtonText={"delete"}
                    disableSubmitButton={!this.state.remove || (this.state.remove_name != this.state.remove.name)}
                    showInput={true}
                    inputTitle={"Type name to DELETE"}
                    copyInput={this.state.remove ? this.state.remove.name : ""}
                    inputError={(!this.state.remove || (this.state.remove_name != this.state.remove.name)) ? "Name is not matching" : false}
                    onClose={async (value) => {
                        await this.promisedSetState({ remove: null });
                        await this.promisedSetState({ remove_name: "" });
                    }}
                    onSubmit={(value) => {
                        if (this.state.remove && this.state.remove_name == this.state.remove.name) {
                            if (this.state.tab.value === "campaigns") {
                                this.functions.removeCampaign();
                            } else if (this.state.tab.value === "keywords" || this.state.tab.value === "negative_keywords") {
                                this.google.removeKeyword();
                            } else if (this.state.tab.value === "adgroups") {
                                this.functions.removeAdgroup();
                            } else if (this.state.tab.value === "ads") {
                                this.functions.removeAd();
                            }
                        }
                    }}
                    onInput={(value) => {
                        this.setState({
                            remove_name: value
                        })
                    }}
                />

                {/*DELETE NEGATIVE KEYWORDS*/}
                <WarningModalTailwind
                    open={this.state.delete_keyword || this.state.delete_asset ? true : false}
                    title={"Delete"}
                    description={'Are you sure you want to DELETE ? It will delete it from its external platform'}
                    cancelButtonText={"cancel"}
                    submitButtonText={"delete"}
                    showInput={false}
                    onClose={async (value) => {
                        await this.promisedSetState({ delete_keyword: false, keyword_multi_text: false, delete_asset: false });
                    }}
                    onSubmit={(value) => {
                        if (this.state.tab.value === "keywords" || this.state.tab.value === "negative_keywords") {
                            this.google.removeKeyword(true);
                        } else if (this.state.tab.value === "assets") {
                            this.google.removeAsset();
                        }
                    }}
                />

                {/*PAUSE SCHEDULE WARNING*/}
                <WarningModalTailwind
                    open={this.state.schedule_warning ? true : false}
                    title={"Pause"}
                    description={'Are you sure ?'}
                    cancelButtonText={"cancel"}
                    submitButtonText={"pause"}
                    showInput={false}
                    dropDown={true}
                    dropDownOptions={[
                        { id: 1, name: "Remove schedule settings", value: "remove_schedule" },
                        { id: 2, name: "Keep schedule settings", value: "keep_schedule" }
                    ]}
                    dropDownSelectedRender={this.state.pause_campaign_schedule}
                    onDropDownSelect={(item) => {
                        this.setState({
                            pause_campaign_schedule: item
                        });
                    }}
                    onClose={async (value) => {
                        await this.promisedSetState({
                            schedule_warning: false,
                            pause_item: false,
                            pause_campaign_schedule: { id: 1, name: "Remove schedule settings", value: "remove_schedule" }
                        });
                    }}
                    onSubmit={(value) => {
                        if (this.state.tab.value === "adgroups") {
                            this.functions.pauseAdgroup(this.state.pause_item);
                        } else if (this.state.tab.value === "campaigns") {
                            this.functions.pauseCampaign(this.state.pause_item);
                        }
                    }}
                />

                {/* SUCCESS MESSAGE */}
                <SuccessModal
                    open={this.state.success ? true : false}
                    title={"Created"}
                    text={"Click OK to continue"}
                    button={"Ok"}
                    showInput={false}
                    onClose={(value) => {
                        this.setState({
                            success: null
                        })
                    }}
                />

                {/*REMOVE REFERENCE MODAL*/}
                <WarningModalTailwind
                    open={this.state.remove_ref ? true : false}
                    title={"Remove reference"}
                    description={'Are you sure you want to remove campaign reference ?'}
                    cancelButtonText={"Cancel"}
                    submitButtonText={"Remove"}
                    disableSubmitButton={!this.state.remove_ref || (this.state.remove_name != this.state.remove_ref.name)}
                    showInput={true}
                    inputTitle={"Type Name to remove"}
                    copyInput={this.state.remove_ref ? this.state.remove_ref.name : ""}
                    inputError={(!this.state.remove_ref || (this.state.remove_name != this.state.remove_ref.name)) ? "Name is not matching" : false}
                    onClose={async (value) => {
                        await this.promisedSetState({ remove_ref: null });
                        await this.promisedSetState({ remove_name: "" });
                    }}
                    onSubmit={(value) => {
                        if (this.state.remove_ref && this.state.remove_name == this.state.remove_ref.name) {
                            this.functions.removeCampaignReference();
                        }
                    }}
                    onInput={(value) => {
                        this.setState({
                            remove_name: value
                        })
                    }}
                />




                {/*NEW DRAFT MODAL*/}
                {/*
                <WizardModal
                    title={"New campaign (draft)"}
                    text={"Set Name below"}
                    button={"Create"}
                    open={this.state.create_wizard}
                    input={this.state.wizard_name}
                    onLoading={this.state.loading_wizard}
                    onInput={(value) => {
                        this.setState({
                            wizard_name: value
                        })
                    }}
                    onCreate={() => {
                        this.functions.createWizard();
                    }}
                    onClose={() => {
                        this.setState({
                            create_wizard: false
                        })
                    }}
                />
                */}

                {/*ADD NEW KEYWORD*/}
                <KeywordModal
                    title={"Negative keyword"}
                    text={"Set settings below"}
                    button={"Create"}
                    open={this.state.create_keyword}
                    exact={this.state.keyword_exact}
                    onExact={(value) => {
                        this.setState({
                            keyword_exact: value
                        })
                    }}
                    broad={this.state.keyword_broad}
                    onBroad={(value) => {
                        this.setState({
                            keyword_broad: value
                        })
                    }}
                    phrase={this.state.keyword_phrase}
                    onPhrase={(value) => {
                        this.setState({
                            keyword_phrase: value
                        })
                    }}
                    input={this.state.keyword_text}
                    onInput={(value) => {
                        this.setState({
                            keyword_text: value
                        })
                    }}
                    searchTerm={true}
                    multi_input={this.state.keyword_multi_text}
                    onMultiInput={(value) => {
                        this.setState({
                            keyword_multi_text: value
                        })
                    }}
                    add_negative_level={this.state.add_negative_level}
                    onSelectLevel={(value) => {
                        this.setState({
                            add_negative_level: value
                        })
                    }}
                    onCreate={async () => {
                        await this.google.addNegativeKeywordAdgroup();
                    }}
                    onClose={() => {
                        this.setState({
                            create_keyword: false,
                            keyword_multi_text: false,
                        })
                    }}
                />

                {/*Edit Negative KEYWORD*/}
                <KeywordModal
                    title={"Edit keywords"}
                    text={"Set settings below"}
                    button={"Update"}
                    open={this.state.edit_keyword}
                    exact={this.state.keyword_exact}
                    onExact={(value) => {
                        this.setState({
                            keyword_exact: value
                        })
                    }}
                    broad={this.state.keyword_broad}
                    onBroad={(value) => {
                        this.setState({
                            keyword_broad: value
                        })
                    }}
                    phrase={this.state.keyword_phrase}
                    onPhrase={(value) => {
                        this.setState({
                            keyword_phrase: value
                        })
                    }}
                    input={this.state.keyword_text}
                    onInput={(value) => {
                        this.setState({
                            keyword_text: value
                        })
                    }}
                    multi_input={this.state.keyword_multi_text}
                    onMultiInput={(value) => {
                        this.setState({
                            keyword_multi_text: value
                        })
                    }}
                    onCreate={() => {
                        if (this.state.tab.value === "keywords") {
                            this.google.editPositiveKeywordAdgroup();
                        } else if (this.state.tab.value === "negative_keywords") {
                            if (this.state.negative_filter && this.state.negative_filter.value === "campaign") {
                                this.google.editNegativeKeywordCampaign();
                            } else {
                                this.google.editNegativeKeywordAdgroup();
                            }
                        }
                    }}
                    onClose={() => {
                        this.setState({
                            edit_keyword: false,
                            keyword_multi_text: false,
                        })
                    }}
                />

                {/*ADD POSITIVE KEYWORD*/}
                <KeywordModal
                    title={this.state.tab.value === "keywords" ? "Add positive keywords" : "Add negative keywords"}
                    text={"Set settings below"}
                    button={"Add2"}
                    open={this.state.add_keyword}
                    exact={this.state.keyword_exact}
                    onExact={(value) => {
                        this.setState({
                            keyword_exact: value,
                            customMatch: false
                        })
                    }}
                    broad={this.state.keyword_broad}
                    onBroad={(value) => {
                        this.setState({
                            keyword_broad: value,
                            customMatch: false
                        })
                    }}
                    phrase={this.state.keyword_phrase}
                    onPhrase={(value) => {
                        this.setState({
                            keyword_phrase: value,
                            customMatch: false
                        })
                    }}
                    customMatch={this.state.customMatch}
                    onCustomMatch={(value) => {
                        this.setState({
                            customMatch: value
                        })
                        if (value) {
                            this.setState({
                                keyword_exact: false,
                                keyword_broad: false,
                                keyword_phrase: false
                            })
                        }
                    }}
                    locked={this.state.locked_adgroup}
                    campaign={this.state.selected_campaign}
                    campaigns={JSON.parse(JSON.stringify(this.state.selected_campaigns))}
                    campaignFilter={this.state.tab.value === "negative_keywords" && this.state.negative_filter && this.state.negative_filter.value === "campaign" ? true : false}
                    onCampaign={(value) => {
                        this.setState({
                            selected_campaign: value
                        })
                    }}
                    adgroups={JSON.parse(JSON.stringify(this.state.adgroups))}
                    adgroup={this.state.selected_adgroup}
                    error={this.state.selected_adgroup_error}
                    onAdgroup={(value) => {
                        this.setState({
                            selected_adgroup: value
                        })
                    }}
                    new_multi_input={this.state.new_multi_input}
                    onNewMultiInput={(value) => {
                        this.setState({
                            new_multi_input: value
                        })
                    }}
                    multi_input={this.state.keyword_multi_text}
                    onMultiInput={(value) => {
                        this.setState({
                            keyword_multi_text: value
                        })
                    }}
                    onCreate={async () => {
                        if (this.state.tab.value === "keywords") {
                            await this.google.addPositiveKeywordAdgroup();
                        } else if (this.state.tab.value === "negative_keywords") {
                            await this.google.addNegativeKeywordAdgroup();
                        }
                    }}
                    onClose={() => {
                        this.setState({
                            customMatch: false,
                            new_multi_input: false,
                            add_keyword: false,
                            keyword_multi_text: false,
                        })
                    }}
                />

                {/*LOADING VIEW*/}
                {
                    this.state.loading &&
                    <div className="min-h-screen flex justify-center items-center flex-col flex-1 w-full">
                        <div style={{ borderTopColor: "transparent" }} class="w-10 h-10 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                        <div className="font-semibold mt-3">Loading ...</div>
                    </div>
                }

                {
                    !this.state.loading &&
                    <div className="p-4 flex-1 flex flex-col max-h-screen">

                        {/* TABS */}
                        <div className="flex flex-row">
                            {
                                this.renders.getSelectedTabs(this.state.selected_channels).map((tab, index) => {
                                    return (
                                        <Fragment key={"zart" + index}>
                                            {
                                                index > 0 &&
                                                <div className="w-2"></div>
                                            }
                                            <div
                                                onClick={async () => {
                                                    if (tab.value === 'campaigns' || (['adgroups', 'ads', 'keywords', 'negative_keywords', 'search_terms', 'assets'].includes(tab.value) && this.state.selected_campaigns.length)) {

                                                        await this.promisedSetState({ reload_table: true })
                                                        await this.promisedSetState({
                                                            tab: tab,
                                                            sortBy: tab.value === 'search_terms' ? "spend" : "name",
                                                            orderBy: "DESC"
                                                        });
                                                        if (tab.value === 'campaigns' && !this.state.campaigns.length) {
                                                            this.functions.getCampaigns();
                                                        }
                                                        if (tab.value === 'adgroups' && !this.state.adgroups.length) {
                                                            this.functions.getAdgroups();
                                                        }
                                                        if (tab.value === 'ads' && !this.state.ads.length) {
                                                            if (!this.state.adgroups.length) {
                                                                await this.functions.getAdgroups();
                                                            }
                                                            await this.functions.getAds();
                                                        }
                                                        if (tab.value === 'assets' && !this.state.assets.length) {
                                                            await this.functions.getAssets();
                                                        }
                                                        if (tab.value === 'keywords' && this.state.keywords.length < 1) {
                                                            if (!this.state.adgroups.length) {
                                                                this.functions.getAdgroups();
                                                            }
                                                            [...new Set(this.state.selected_channels)].forEach(item => {
                                                                if (this[item].getKeywords) {
                                                                    this[item].getKeywords(true)
                                                                }
                                                            })
                                                        }
                                                        if (tab.value === 'negative_keywords' && this.state.negative_keywords.length < 1 && (!this.state.negative_filter || this.state.negative_filter.value === "adgroup")) {
                                                            if (!this.state.adgroups.length) {
                                                                this.functions.getAdgroups();
                                                            }
                                                            [...new Set(this.state.selected_channels)].forEach(item => {
                                                                if (this[item].getNegativeKeywords) {
                                                                    this[item].getNegativeKeywords(true)
                                                                }
                                                            })
                                                        }

                                                        if (tab.value === 'negative_keywords' && this.state.negative_keywords_campaign.length < 1 && this.state.negative_filter && this.state.negative_filter.value === "campaign") {

                                                            [...new Set(this.state.selected_channels)].forEach(item => {
                                                                if (this[item].getCampaignNegativeKeywords) {
                                                                    this[item].getCampaignNegativeKeywords(true)
                                                                }

                                                            })
                                                        }



                                                        if (tab.value === 'search_terms' && this.state.search_terms.length < 1) {
                                                            if (!this.state.adgroups.length) {
                                                                this.functions.getAdgroups();
                                                            }
                                                            [...new Set(this.state.selected_channels)].forEach(item => {
                                                                if (this[item].getSearchterms) {
                                                                    this[item].getSearchterms(true)
                                                                }
                                                            })
                                                        }
                                                        await this.promisedSetState({ reload_table: false })
                                                    }
                                                }}
                                                style={tab.id === this.state.tab.id ? { borderBottomColor: "rgb(249, 250, 251)" } : {}}
                                                className={(tab.id === this.state.tab.id ? "bg-white" : "text-gray-300 hover:text-gray-700 bg-gray-50") + " shadow py-3 flex flex-1 items-center flex-row truncate px-5 text-sm font-medium rounded-t-lg cursor-pointer"}>
                                                {
                                                    tab.value === "campaigns" &&
                                                    <FolderIcon className={(tab.id === this.state.tab.id ? "text-purple-500" : "text-gray-300") + " h-6 w-6 -ml-1 mr-2 "} />
                                                }
                                                {
                                                    tab.value === "adgroups" &&
                                                    <ViewGridIcon className={(tab.id === this.state.tab.id ? "text-purple-500" : "text-gray-300") + " h-6 w-6 -ml-1 mr-2 "} />
                                                }
                                                {
                                                    tab.value === "ads" &&
                                                    <NewspaperIcon className={(tab.id === this.state.tab.id ? "text-purple-500" : "text-gray-300") + " h-6 w-6 -ml-1 mr-2 "} />
                                                }
                                                {
                                                    tab.value === "keywords" &&
                                                    <SearchIcon className={(tab.id === this.state.tab.id ? "text-purple-500" : "text-gray-300") + " h-6 w-6 -ml-1 mr-2 "} />
                                                }
                                                {
                                                    tab.value === "negative_keywords" &&
                                                    <SearchIcon className={(tab.id === this.state.tab.id ? "text-purple-500" : "text-gray-300") + " h-6 w-6 -ml-1 mr-2 "} />
                                                }
                                                {
                                                    tab.value === "search_terms" &&
                                                    <LightBulbIcon className={(tab.id === this.state.tab.id ? "text-purple-500" : "text-gray-300") + " h-6 w-6 -ml-1 mr-2 "} />
                                                }
                                                <div className="flex flex-1 truncate">
                                                    <div style={{ paddingTop: "1px" }} className="truncate mr-3">
                                                        {tab.name}
                                                    </div>
                                                </div>
                                                {
                                                    tab.value === "adgroups" && this.state.adgroup && this.state.adgroup.id &&
                                                    <div className="px-2 py-1 bg-purple-500 text-white rounded-full flex justify-center items-center text-xxs">1</div>
                                                }
                                            </div>
                                        </Fragment>
                                    )
                                })
                            }
                        </div>

                        {/*SUBTABS*/}
                        <div style={{ top: "65px" }} className={"bg-white flex shadow flex-col" + (this.state.tab.value === 'search_terms' ? " sticky z-30" : "")}>
                            <div className="pt-6 pb-2 w-full">
                                <div className="flex items-center w-full px-6">
                                    {
                                        this.state.tab.value === 'campaigns' &&
                                        <>
                                            <nav className="flex flex-1 space-x-4" aria-label="Tabs">
                                                {this.state.channels.map((channel) => (
                                                    <div
                                                        onClick={async () => {
                                                            this.promisedSetState({
                                                                channel: channel,
                                                                campaign: { channel: 'all' },
                                                                adgroup: {},
                                                                ad: {},
                                                                adgroups: [],
                                                                ads: []
                                                            })
                                                            await this.refs.TableHeadTailwind.functions.filterByChannel(channel);
                                                        }}
                                                        key={channel.name}
                                                        style={{ whiteSpace: "nowrap" }}
                                                        className={cn(
                                                            channel.id == this.state.channel.id ? 'bg-purple-100 text-purple-500' : 'text-gray-500 hover:text-purple-500',
                                                            'px-3 py-2 font-medium text-sm rounded-md whitespace-nowrap cursor-pointer capitalize'
                                                        )}
                                                        aria-current={channel.id == this.state.channel.id ? 'page' : undefined}
                                                    >
                                                        {channel.name}
                                                    </div>
                                                ))}
                                            </nav>
                                            {
                                                <div className="items-center text-sm bg-purple-500 hover:bg-purple-600 mr-2 cursor-pointer focus:ring-purple-500 text-white inline-flex shadow relative justify-center rounded-md py-2 px-3 font-medium  focus:outline-none focus:ring-2 focus:ring-offset-2"
                                                    onClick={async () => {
                                                        this.promisedSetState({
                                                            charts_open: !this.state.charts_open
                                                        })
                                                    }}
                                                >
                                                    <PresentationChartBarIcon class="w-5 h-5 mr-2" />
                                                    <span>Chart</span>
                                                </div>
                                            }
                                            {
                                                this.state.selected_campaigns.length > 0 &&
                                                this.state.user && this.state.user.userRole !== "sales" &&
                                                <div className={cn(
                                                    this.state.selected_campaigns.filter((item) => { return item.channel === "twitter" || item.channel === "bing" || (item.channel === "google" && item.advertising_type && (!item.advertising_type.value || item.advertising_type.value && item.advertising_type.value !== "SEARCH")) }).length < 1 ? 'bg-green-600 hover:bg-green-700 cursor-pointer text-white' : 'bg-gray-100 text-black',
                                                    'items-center text-sm mr-2  focus:ring-purple-500  inline-flex shadow relative justify-center rounded-md py-2 px-2 font-medium  focus:outline-none focus:ring-2 focus:ring-offset-2'
                                                )}
                                                    onClick={async () => {
                                                        if (this.state.selected_campaigns.filter((item) => { return item.channel === "twitter" || item.channel === "bing" || (item.channel === "google" && item.advertising_type && (!item.advertising_type.value || item.advertising_type.value && item.advertising_type.value !== "SEARCH")) }).length < 1) {
                                                            await this.functions.createPartialWizard_V2();
                                                        }
                                                    }}
                                                >
                                                    <PlusCircleIcon class="w-4 h-4 mr-2" />

                                                    <span>Add new ad</span>

                                                </div>
                                            }

                                            {
                                                this.state.user && this.state.user.userRole !== "sales" &&
                                                <div className="items-center text-sm bg-purple-500 hover:bg-purple-600 max-w-48 cursor-pointer focus:ring-purple-500 text-white inline-flex shadow relative justify-center rounded-md py-2 px-3 font-medium  focus:outline-none focus:ring-2 focus:ring-offset-2"
                                                    onClick={async () => {
                                                        this.promisedSetState({
                                                            cutomize_open: true
                                                        })
                                                    }}
                                                >
                                                    <AdjustmentsIcon class="w-4 h-4 mr-2" />
                                                    <span>Custom metrics</span>
                                                </div>
                                            }

                                            <div className='flex justify-end'>
                                                <div className={(Array.isArray(this.state[this.state.tab.value]) && this.state[this.state.tab.value].length > 0 ? "bg-purple-500 hover:bg-purple-600 " : "bg-gray-500 cursor-not-allowed ") + "items-center text-sm  cursor-pointer focus:ring-purple-500 text-white inline-flex shadow relative justify-center rounded-md py-2 px-3 font-medium  focus:outline-none focus:ring-2 focus:ring-offset-2 ml-2"}
                                                    onClick={() => {
                                                        if (Array.isArray(this.state[this.state.tab.value]) && this.state[this.state.tab.value].length > 0) {
                                                            this.refs.TableHeadTailwind.functions.openSlideOut()
                                                        }
                                                    }}
                                                >
                                                    <CogIcon class="w-4 h-4 mr-2" />
                                                    <span>Table</span>
                                                </div>
                                            </div>
                                        </>
                                    }
                                    {
                                        this.state.tab.value === 'adgroups' &&
                                        <>
                                            <nav className="flex space-x-4 flex-1" aria-label="Tabs">
                                                <div
                                                    className={cn(
                                                        this.state.subtab === this.state.tab.name ? 'bg-purple-100 text-purple-500' : 'text-gray-500 hover:text-purple-500',
                                                        'px-3 py-2 font-medium text-sm rounded-md whitespace-nowrap cursor-pointer capitalize'
                                                    )}
                                                    onClick={() => this.state.subtab = this.state.tab.name}
                                                >
                                                    {this.state.tab.name}
                                                </div>
                                                {
                                                    <div id="asset_seach" className='w-24 flex transition-all duration-700 rounded-md mr-2 relative'>
                                                        <input
                                                            onBlur={() => {
                                                                document.getElementById('asset_seach').classList.remove('w-120');
                                                                document.getElementById('asset_seach').classList.add('w-24');
                                                            }}
                                                            onFocus={() => {
                                                                document.getElementById('asset_seach').classList.remove('w-24');
                                                                document.getElementById('asset_seach').classList.add('w-120');
                                                            }}
                                                            autocomplete="off"
                                                            className={" block w-full bg-custom-input rounded-md border-1.5 px-2 pr-8 py-2 focus:outline-none sm:text-sm"}
                                                            placeholder={"Search"}
                                                            value={this.state.tab_search.adgroups}
                                                            onChange={async (event) => {
                                                                this.state.tab_search.adgroups = event.target.value;
                                                                await this.promisedSetState({
                                                                    tab_search: this.state.tab_search
                                                                })

                                                            }}
                                                        />
                                                        <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                                            <SearchIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                                                        </div>
                                                    </div>
                                                }
                                            </nav>
                                            {
                                                <div className="items-center text-sm bg-purple-500 hover:bg-purple-600 mr-2 cursor-pointer focus:ring-purple-500 text-white inline-flex shadow relative justify-center rounded-md py-2 px-3 font-medium  focus:outline-none focus:ring-2 focus:ring-offset-2"
                                                    onClick={async () => {
                                                        this.promisedSetState({
                                                            charts_open: !this.state.charts_open
                                                        })
                                                    }}
                                                >
                                                    <PresentationChartBarIcon class="w-5 h-5 mr-2" />
                                                    <span>Chart</span>
                                                </div>
                                            }
                                            {
                                                this.state.selected_campaigns.length > 0 && this.state.selected_campaigns.filter((item) => { return item.channel === "twitter" || item.channel === "bing" || (item.channel === "google" && item.advertising_type && (!item.advertising_type.value || item.advertising_type.value && item.advertising_type.value !== "SEARCH")) }).length < 1 &&
                                                this.state.user && this.state.user.userRole !== "sales" &&
                                                <div className={'bg-green-600 hover:bg-green-700 cursor-pointer text-white items-center text-sm mr-2  focus:ring-purple-500  inline-flex shadow relative justify-center rounded-md py-2 px-2 font-medium  focus:outline-none focus:ring-2 focus:ring-offset-2'}
                                                    onClick={async () => {
                                                        if (this.state.selected_campaigns.filter((item) => { return item.channel === "twitter" || item.channel === "bing" || (item.channel === "google" && item.advertising_type && (!item.advertising_type.value || item.advertising_type.value && item.advertising_type.value !== "SEARCH")) }).length < 1) {
                                                            await this.functions.createPartialWizard_V2();
                                                        }
                                                    }}
                                                >
                                                    <PlusCircleIcon class="w-4 h-4 mr-2" />
                                                    <span>Add new ad</span>
                                                </div>
                                            }
                                            {
                                                this.state.user && this.state.user.userRole !== "sales" &&
                                                <div className="items-center text-sm bg-purple-500 hover:bg-purple-600 max-w-48 cursor-pointer focus:ring-purple-500 text-white inline-flex shadow relative justify-center rounded-md py-2 px-3 font-medium  focus:outline-none focus:ring-2 focus:ring-offset-2"
                                                    onClick={async () => {
                                                        this.promisedSetState({
                                                            cutomize_open: true
                                                        })
                                                    }}
                                                >
                                                    <AdjustmentsIcon class="w-4 h-4 mr-2" />
                                                    <span>Custom metrics</span>
                                                </div>
                                            }
                                            <div className='flex justify-end'>
                                                <div className={(Array.isArray(this.state[this.state.tab.value]) && this.state[this.state.tab.value].length > 0 ? "bg-purple-500 hover:bg-purple-600 " : "bg-gray-500 cursor-not-allowed ") + "items-center text-sm  cursor-pointer focus:ring-purple-500 text-white inline-flex shadow relative justify-center rounded-md py-2 px-3 font-medium  focus:outline-none focus:ring-2 focus:ring-offset-2 ml-2"}
                                                    onClick={() => {
                                                        if (Array.isArray(this.state[this.state.tab.value]) && this.state[this.state.tab.value].length > 0) {
                                                            this.refs.TableHeadTailwind.functions.openSlideOut()
                                                        }
                                                    }}
                                                >
                                                    <CogIcon class="w-4 h-4 mr-2" />
                                                    <span>Table</span>
                                                </div>
                                            </div>
                                        </>
                                    }
                                    {
                                        this.state.tab.value === 'ads' &&
                                        <>
                                            <nav className="flex space-x-4 flex-1" aria-label="Tabs">
                                                <div
                                                    className={cn(
                                                        this.state.subtab === this.state.tab.name ? 'bg-purple-100 text-purple-500' : 'text-gray-500 hover:text-purple-500',
                                                        'px-3 py-2 font-medium text-sm rounded-md whitespace-nowrap cursor-pointer capitalize'
                                                    )}
                                                    onClick={() => this.state.subtab = this.state.tab.name}
                                                >
                                                    {this.state.tab.name}
                                                </div>
                                                {
                                                    <div id="asset_seach" className='w-24 flex transition-all duration-700 rounded-md mr-2 relative'>
                                                        <input
                                                            onBlur={() => {
                                                                document.getElementById('asset_seach').classList.remove('w-120');
                                                                document.getElementById('asset_seach').classList.add('w-24');
                                                            }}
                                                            onFocus={() => {
                                                                document.getElementById('asset_seach').classList.remove('w-24');
                                                                document.getElementById('asset_seach').classList.add('w-120');
                                                            }}
                                                            autocomplete="off"
                                                            className={" block w-full bg-custom-input rounded-md border-1.5 px-2 pr-8 py-2 focus:outline-none sm:text-sm"}
                                                            placeholder={"Search"}
                                                            value={this.state.tab_search.ads}
                                                            onChange={async (event) => {
                                                                this.state.tab_search.ads = event.target.value;
                                                                await this.promisedSetState({
                                                                    tab_search: this.state.tab_search
                                                                })

                                                            }}
                                                        />
                                                        <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                                            <SearchIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                                                        </div>
                                                    </div>
                                                }
                                            </nav>
                                            {
                                                <div className="items-center text-sm bg-purple-500 hover:bg-purple-600 mr-2 cursor-pointer focus:ring-purple-500 text-white inline-flex shadow relative justify-center rounded-md py-2 px-3 font-medium  focus:outline-none focus:ring-2 focus:ring-offset-2"
                                                    onClick={async () => {
                                                        this.promisedSetState({
                                                            charts_open: !this.state.charts_open
                                                        })
                                                    }}
                                                >
                                                    <PresentationChartBarIcon class="w-5 h-5 mr-2" />
                                                    <span>Chart</span>
                                                </div>
                                            }
                                            {
                                                this.state.user && this.state.user.userRole !== "sales" &&
                                                <div className="items-center text-sm bg-purple-500 hover:bg-purple-600 max-w-48 cursor-pointer focus:ring-purple-500 text-white inline-flex shadow relative justify-center rounded-md py-2 px-3 font-medium  focus:outline-none focus:ring-2 focus:ring-offset-2"
                                                    onClick={async () => {
                                                        this.promisedSetState({
                                                            cutomize_open: true
                                                        })
                                                    }}
                                                >
                                                    <AdjustmentsIcon class="w-4 h-4 mr-2" />
                                                    <span>Custom metrics</span>
                                                </div>
                                            }
                                            <div className='flex justify-end'>
                                                <div className={(Array.isArray(this.state[this.state.tab.value]) && this.state[this.state.tab.value].length > 0 ? "bg-purple-500 hover:bg-purple-600 " : "bg-gray-500 cursor-not-allowed ") + "items-center text-sm  cursor-pointer focus:ring-purple-500 text-white inline-flex shadow relative justify-center rounded-md py-2 px-3 font-medium  focus:outline-none focus:ring-2 focus:ring-offset-2 ml-2"}
                                                    onClick={() => {
                                                        if (Array.isArray(this.state[this.state.tab.value]) && this.state[this.state.tab.value].length > 0) {
                                                            this.refs.TableHeadTailwind.functions.openSlideOut()
                                                        }
                                                    }}
                                                >
                                                    <CogIcon class="w-4 h-4 mr-2" />
                                                    <span>Table</span>
                                                </div>
                                            </div>
                                        </>
                                    }
                                    {
                                        (this.state.tab.value === 'keywords') &&
                                        <nav className="flex flex-1 space-x-4" aria-label="Tabs">
                                            {
                                                this.state.user && this.state.user.userRole !== "sales" &&
                                                <>
                                                    <div
                                                        onClick={() => {
                                                            this.functions.menu("Add positive", { channel: "google" });
                                                        }}
                                                        key={"Add positive keywords"}
                                                        style={{ whiteSpace: "nowrap" }}
                                                        className={'cursor-pointer bg-purple-500 hover:bg-purple-600 text-white inline-flex shadow-sm justify-center items-center rounded-md border border-transparent px-4 py-2  text-base font-medium  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm'}
                                                        aria-current={"page"}
                                                    >
                                                        <div className="flex flex-1 mr-2">Add keywords</div>
                                                        <PlusCircleIcon class="w-4 h-4" />
                                                    </div>
                                                    <div
                                                        onClick={async () => {
                                                            if (this.state.keywords.some(item => item.selected)) {
                                                                let items = []
                                                                items.push(...this.state.keywords.filter(item => item.selected))
                                                                await this.promisedSetState({ temp_multi_edit: items })
                                                                this.functions.menu("Edit positive", { channel: "google", multi: true });
                                                            }

                                                        }}
                                                        key={"Edit multiple positive keywords"}
                                                        style={{ whiteSpace: "nowrap" }}
                                                        className={cn(
                                                            this.state.keywords.some(item => item.selected) ? 'cursor-pointer bg-purple-500 hover:bg-purple-600 text-white' : 'text-gray-500',
                                                            'inline-flex shadow-sm justify-center items-center rounded-md border border-transparent px-4 py-2  text-base font-medium  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm'
                                                        )}
                                                        aria-current={"page"}
                                                    >
                                                        <div className="flex flex-1 mr-2">Edit keywords</div>
                                                        <PencilAltIcon class="w-4 h-4" />
                                                    </div>
                                                    <div
                                                        onClick={() => {
                                                            if (this.state.keywords.some(item => item.selected)) {
                                                                this.functions.menu("Delete positive", { channel: "google", multi: true });
                                                            }
                                                        }}
                                                        key={"delete keywords"}
                                                        style={{ whiteSpace: "nowrap" }}
                                                        className={cn(
                                                            this.state.keywords.some(item => item.selected) ? 'bg-red-500 text-white hover:bg-red-600 cursor-pointer' : 'text-gray-500',
                                                            'inline-flex shadow-sm justify-center items-center flex-row rounded-md border border-transparent px-4 py-2  text-base font-medium  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm'
                                                        )}
                                                        aria-current={"page"}
                                                    >
                                                        <div className="flex flex-1 mr-2">Delete</div>
                                                        <TrashIcon class="w-4 h-4" />
                                                    </div>
                                                </>
                                            }
                                            <div className='flex flex-1 justify-end'>
                                                <div className={(Array.isArray(this.state[this.state.tab.value]) && this.state[this.state.tab.value].length > 0 ? "bg-purple-500 hover:bg-purple-600 " : "bg-gray-500 cursor-not-allowed ") + "items-center text-sm  cursor-pointer focus:ring-purple-500 text-white inline-flex shadow relative justify-center rounded-md py-2 px-3 font-medium  focus:outline-none focus:ring-2 focus:ring-offset-2 ml-2"}
                                                    onClick={() => {
                                                        if (Array.isArray(this.state[this.state.tab.value]) && this.state[this.state.tab.value].length > 0) {
                                                            this.refs.TableHeadTailwind.functions.openSlideOut()
                                                        }
                                                    }}
                                                >
                                                    <CogIcon class="w-4 h-4 mr-2" />
                                                    <span>Table</span>
                                                </div>
                                            </div>

                                        </nav>
                                    }
                                    {
                                        (this.state.tab.value === 'negative_keywords') &&
                                        <nav className="flex flex-1 space-x-4" aria-label="Tabs">
                                            {
                                                this.state.user && this.state.user.userRole !== "sales" &&
                                                <>
                                                    <div>
                                                        <DropdownTailwind
                                                            small={true}
                                                            selected={this.state.negative_filter ? this.state.negative_filter : { id: 1, name: "Adgroup", value: "adgroup" }}
                                                            options={[{ id: 1, name: "Adgroup", value: "adgroup" }, { id: 2, name: "Campaign", value: "campaign" }]}
                                                            onChange={async (value) => {
                                                                if (value.id === 2 && (!this.state.negative_filter || this.state.negative_filter.value === "adgroup")) {
                                                                    this.state.negative_keywords = this.state.negative_keywords.map((item) => {
                                                                        item.selected = false;
                                                                        return item;
                                                                    })
                                                                } else if (value.id === 1 && this.state.negative_filter && this.state.negative_filter.value === "campaign") {
                                                                    this.state.negative_keywords_campaign = this.state.negative_keywords_campaign.map((item) => {
                                                                        item.selected = false;
                                                                        return item;
                                                                    })
                                                                }
                                                                await this.promisedSetState({
                                                                    negative_keywords: this.state.negative_keywords,
                                                                    negative_keywords_campaign: this.state.negative_keywords_campaign
                                                                });


                                                                if (value.id === 2) {
                                                                    if (this.state.negative_keywords_campaign.length < 1) {
                                                                        this.google.getCampaignNegativeKeywords(true);
                                                                    }
                                                                } else {
                                                                    if (this.state.negative_keywords.length < 1) {
                                                                        [...new Set(this.state.selected_channels)].forEach(item => {
                                                                            if (this[item].getNegativeKeywords) {
                                                                                this[item].getNegativeKeywords(true)
                                                                            }
                                                                        })
                                                                    }
                                                                }

                                                                this.state.negative_filter = value;
                                                                await this.promisedSetState({
                                                                    negative_filter: this.state.negative_filter
                                                                });
                                                            }
                                                            }
                                                        />
                                                    </div>
                                                    <div
                                                        onClick={() => {
                                                            this.functions.menu("Add negative", { channel: "google" });
                                                        }}
                                                        key={"Add negative keywords"}
                                                        style={{ whiteSpace: "nowrap" }}
                                                        className={'cursor-pointer bg-purple-500 hover:bg-purple-600 text-white inline-flex shadow-sm justify-center items-center rounded-md border border-transparent px-4 py-2  text-base font-medium  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm'}
                                                        aria-current={"page"}
                                                    >
                                                        <div className="flex flex-1 mr-2">Add negative keywords</div>
                                                        <PlusCircleIcon class="w-4 h-4" />
                                                    </div>
                                                    <div
                                                        onClick={async () => {
                                                            if (!this.state.negative_filter || this.state.negative_filter.value === "adgroup") {
                                                                if (this.state.negative_keywords.some(item => item.selected)) {
                                                                    let items = []
                                                                    items.push(...this.state.negative_keywords.filter(item => item.selected))
                                                                    await this.promisedSetState({ temp_multi_edit: items })
                                                                    this.functions.menu("Edit negative", { channel: "google", multi: true });
                                                                }
                                                            } else if (this.state.negative_filter && this.state.negative_filter.value === "campaign") {
                                                                if (this.state.negative_keywords_campaign.some(item => item.selected)) {
                                                                    let items = []
                                                                    items.push(...this.state.negative_keywords_campaign.filter(item => item.selected))
                                                                    await this.promisedSetState({ temp_multi_edit: items })
                                                                    this.functions.menu("Edit negative", { channel: "google", multi: true });
                                                                }
                                                            }
                                                        }}
                                                        key={"Edit multiple negative keywords"}
                                                        style={{ whiteSpace: "nowrap" }}
                                                        className={cn(
                                                            this.state.negative_keywords.some(item => item.selected) || this.state.negative_keywords_campaign.some(item => item.selected) ? 'cursor-pointer bg-purple-500 hover:bg-purple-600 text-white' : 'text-gray-500',
                                                            'inline-flex shadow-sm justify-center items-center rounded-md border border-transparent px-4 py-2  text-base font-medium  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm'
                                                        )}
                                                        aria-current={"page"}
                                                    >
                                                        <div className="flex flex-1 mr-2">Edit keywords</div>
                                                        <PencilAltIcon class="w-4 h-4" />
                                                    </div>
                                                    <div
                                                        onClick={() => {
                                                            if (this.state.negative_keywords.some(item => item.selected) || this.state.negative_keywords_campaign.some(item => item.selected)) {
                                                                this.functions.menu("Delete negative", { channel: "google", multi: true });
                                                            }
                                                        }}
                                                        key={"delete keywords"}
                                                        style={{ whiteSpace: "nowrap" }}
                                                        className={cn(
                                                            this.state.negative_keywords.some(item => item.selected) || this.state.negative_keywords_campaign.some(item => item.selected) ? 'bg-red-500 text-white hover:bg-red-600 cursor-pointer' : 'text-gray-500',
                                                            'inline-flex shadow-sm justify-center items-center flex-row rounded-md border border-transparent px-4 py-2  text-base font-medium  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm'
                                                        )}
                                                        aria-current={"page"}
                                                    >
                                                        <div className="flex flex-1 mr-2">Delete</div>
                                                        <TrashIcon class="w-4 h-4" />
                                                    </div>
                                                </>
                                            }
                                            <div className='flex flex-1 justify-end'>
                                                <div className={(Array.isArray(this.state[this.state.tab.value]) && this.state[this.state.tab.value].length > 0 ? "bg-purple-500 hover:bg-purple-600 " : "bg-gray-500 cursor-not-allowed ") + "items-center text-sm  cursor-pointer focus:ring-purple-500 text-white inline-flex shadow relative justify-center rounded-md py-2 px-3 font-medium  focus:outline-none focus:ring-2 focus:ring-offset-2 ml-2"}
                                                    onClick={() => {
                                                        if (Array.isArray(this.state[this.state.tab.value]) && this.state[this.state.tab.value].length > 0) {
                                                            this.refs.TableHeadTailwind.functions.openSlideOut()
                                                        }
                                                    }}
                                                >
                                                    <CogIcon class="w-4 h-4 mr-2" />
                                                    <span>Table</span>
                                                </div>
                                            </div>

                                        </nav>
                                    }
                                    {
                                        (this.state.tab.value === 'assets') &&
                                        <nav className="flex flex-1 space-x-4" aria-label="Tabs">
                                            {
                                                this.state.user && this.state.user.userRole !== "sales" &&
                                                <>
                                                    <div
                                                        onClick={() => {
                                                            if (this.state.assets.some(item => item.selected)) {
                                                                this.functions.menu("Delete assets", { channel: "google", multi: true });
                                                            }
                                                        }}
                                                        key={"delete assets"}
                                                        style={{ whiteSpace: "nowrap" }}
                                                        className={cn(
                                                            this.state.assets.some(item => item.selected) ? 'bg-red-500 text-white hover:bg-red-600 cursor-pointer' : 'text-gray-500',
                                                            'inline-flex shadow-sm justify-center items-center flex-row rounded-md border border-transparent px-4 py-2  text-base font-medium  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm'
                                                        )}
                                                        aria-current={"page"}
                                                    >
                                                        <div className="flex flex-1 mr-2">Delete</div>
                                                        <TrashIcon class="w-4 h-4" />
                                                    </div>
                                                </>
                                            }
                                            <div className='flex flex-1 justify-end'>
                                                <div className={(Array.isArray(this.state[this.state.tab.value]) && this.state[this.state.tab.value].length > 0 ? "bg-purple-500 hover:bg-purple-600 " : "bg-gray-500 cursor-not-allowed ") + "items-center text-sm  cursor-pointer focus:ring-purple-500 text-white inline-flex shadow relative justify-center rounded-md py-2 px-3 font-medium  focus:outline-none focus:ring-2 focus:ring-offset-2 ml-2"}
                                                    onClick={() => {
                                                        if (Array.isArray(this.state[this.state.tab.value]) && this.state[this.state.tab.value].length > 0) {
                                                            this.refs.TableHeadTailwind.functions.openSlideOut()
                                                        }
                                                    }}
                                                >
                                                    <CogIcon class="w-4 h-4 mr-2" />
                                                    <span>Table</span>
                                                </div>
                                            </div>
                                        </nav>
                                    }
                                    {
                                        (this.state.tab.value === 'search_terms') &&
                                        <nav className="flex flex-1 space-x-4" aria-label="Tabs">

                                            {
                                                this.state.user && this.state.user.userRole !== "sales" &&
                                                <>
                                                    <div
                                                        onClick={() => {
                                                            if (this.state.search_terms.some(item => item.selected)) {
                                                                this.functions.menu("Add as negative to Adgroup", { channel: "google", multi: true });
                                                            }
                                                        }}
                                                        key={"Add multiple as negative"}
                                                        style={{ whiteSpace: "nowrap" }}
                                                        className={cn(
                                                            this.state.search_terms.some(item => item.selected) ? 'bg-red-500 text-white hover:bg-red-600' : 'text-gray-500',
                                                            'inline-flex shadow-sm justify-center rounded-md border border-transparent px-4 py-2  text-base font-medium  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm'
                                                        )}
                                                        aria-current={"page"}
                                                    >
                                                        Add multiple as negative
                                                    </div>
                                                </>
                                            }

                                        </nav>
                                    }

                                    {
                                        this.state.tab.value === 'search_terms' &&
                                        <>

                                            <div className="flex-1 flex justify-end">
                                                <div>
                                                    <div onClick={() => { this.functions.exportCSVFile() }} className="px-3 py-2 font-medium text-sm text-purple-500 inline-flex flex-row rounded-md whitespace-nowrap cursor-pointer capitalize">
                                                        <DownloadIcon className="h-5 w-5" />
                                                        <div className="ml-2">Export to CSV</div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='flex flex-1 justify-end'>
                                                <div className={(Array.isArray(this.state[this.state.tab.value]) && this.state[this.state.tab.value].length > 0 ? "bg-purple-500 hover:bg-purple-600 " : "bg-gray-500 cursor-not-allowed ") + "items-center text-sm  cursor-pointer focus:ring-purple-500 text-white inline-flex shadow relative justify-center rounded-md py-2 px-3 font-medium  focus:outline-none focus:ring-2 focus:ring-offset-2 ml-2"}
                                                    onClick={() => {
                                                        if (Array.isArray(this.state[this.state.tab.value]) && this.state[this.state.tab.value].length > 0) {
                                                            this.refs.TableHeadTailwind.functions.openSlideOut()
                                                        }
                                                    }}
                                                >
                                                    <CogIcon class="w-4 h-4 mr-2" />
                                                    <span>Table</span>
                                                </div>
                                            </div>
                                        </>

                                    }

                                </div>
                            </div>
                        </div>

                        {/* Camapign charts */}
                        <OrderCharts
                            open={this.state.charts_open}
                            reload={this.state.reload_charts}
                            selected={(this.state.tab.value === 'campaigns' && this.state.selected_campaigns.length) || (this.state.tab.value === 'adgroups' && this.state.selected_adgroups.length)}
                            channel={this.state.channel.value}
                            campaigns={this.state.selected_campaigns.length ? this.state.selected_campaigns : this.state.campaigns}
                            adgroups={this.state.selected_adgroups.length ? this.state.selected_adgroups : this.state.adgroups}
                            ads={this.state.ads}
                            tab={this.state.tab.value}
                            client={this.state.client}
                            customDates={this.state.custom_dates}
                            dateSpan={this.state.date_span}
                            metrics={this.state.columns_new ? this.state.columns_new.filter(item => item.metric) : this.state.metrics}
                            orderId={this.state.order.id}
                        />

                        {/*TABLE*/}
                        {
                            <div className="relative overflow-x-auto bg-white shadow rounded-b-lg h-full table-overflow">

                                {/*LOADER PARTIAL*/}
                                {
                                    this.state.loading_partial &&
                                    <div className="text-center absolute top-0 bottom-0 z-50 bg-white bg-opacity-25 right-0 left-0 flex justify-center align-middle items-center">
                                        <div style={{ borderTopColor: "transparent" }} class="w-12 h-12 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                                    </div>
                                }

                                {!this.state.reload_table &&
                                    Array.isArray(this.state[this.state.tab.value]) &&
                                    this.state[(this.state.negative_filter && this.state.negative_filter.value === "campaign" && this.state.tab.value === "negative_keywords" ? "negative_keywords_campaign" : this.state.tab.value)].length > 0 &&
                                    <table className="min-w-full divide-y divide-gray-300 border-gray-300">

                                        <TableHeadTailwind
                                            ref={"TableHeadTailwind"}
                                            empty_space={false}
                                            sticky={true}
                                            columns={this.state.columns.filter((item) => {
                                                return !item.scheduler || (item.scheduler && this.state.tab.value === 'campaigns')
                                            }).filter((item) => {
                                                if (item.channel) {
                                                    return !!(item.tab === this.state.tab.value && this.state.selected_campaigns.some(s_channel => item.channel === item.channel))
                                                } else if (this.state.tab.value === "search_terms") {
                                                    return !!(item.value !== "start" && item.value !== "end" && item.value !== "status")
                                                } else if (this.state.tab.value === "keywords" || this.state.tab.value === "negative_keywords") {
                                                    return !!(item.value !== "start" && item.value !== "end")
                                                } else if (this.state.tab.value === "assets") {
                                                    return !!(item.value !== "start" && item.value !== "end")
                                                }
                                                return true
                                            }).concat(JSON.parse(JSON.stringify((["search_terms", "keywords", "negative_keywords"].includes(this.state.tab.value) ? this.state.default_metrics : this.state.metrics))))}
                                            sort={this.state.sortBy}
                                            direction={this.state.orderBy && this.state.orderBy === "ASC" ? "ascending" : "descending"}
                                            view_name={this.state.tab.value}
                                            channel={this.state.channel}
                                            sticky_col={true}
                                            onColumnSort={async (value) => {
                                                console.log(value, "===SORT===")
                                                await this.promisedSetState(({
                                                    page: value.page,
                                                    sortBy: value.sort,
                                                    orderBy: value.direction === "ascending" ? "ASC" : "DESC",
                                                    loading: false
                                                }))
                                                if (this.state.tab.value === 'keywords') {
                                                    [...new Set(this.state.selected_channels)].forEach(item => {
                                                        if (this[item].getKeywords) {
                                                            this[item].getKeywords(true)
                                                        }
                                                    })
                                                } else if (this.state.tab.value === 'negative_keywords') {
                                                    [...new Set(this.state.selected_channels)].forEach(item => {
                                                        if (this[item].getKeywords) {
                                                            this[item].getNegativeKeywords(true)
                                                        }
                                                    })
                                                }
                                                if (this.state.tab.value === 'search_terms') {
                                                    [...new Set(this.state.selected_channels)].forEach(item => {
                                                        if (this[item].getSearchterms) {
                                                            this[item].getSearchterms(true)
                                                        }
                                                    })
                                                }
                                            }}
                                            onColumnSave={async (value) => {
                                                this.setState({ columns_new: value })
                                            }}
                                            onChannelSelect={async (value) => {
                                                await this.promisedSetState({
                                                    channel: value
                                                })
                                            }}

                                        />

                                        <tbody className="bg-white divide-gray-300">
                                            {
                                                Array.isArray(this.state[this.state.tab.value]) &&
                                                this.renders.sort(
                                                    (
                                                        this.state.tab.value === "negative_keywords" && this.state.negative_filter && this.state.negative_filter.value === "campaign" ?
                                                            this.state.negative_keywords_campaign.filter(item => [item.channel, "all"].includes(this.state.channel.value))
                                                            : this.state[this.state.tab.value].filter(item => [item.channel, "all"].includes(this.state.channel.value))
                                                    )
                                                )
                                                    .filter((item) => {
                                                        if (this.state.tab.value === "campaigns") {
                                                            if (this.state.advancedFilters[0].selected.length < 1) {
                                                                return true
                                                            } else {
                                                                return this.state.advancedFilters[0].selected.map((status) => { return status.value }).includes(item.status);
                                                            }
                                                        } else if (["keywords", "search_terms"].includes(this.state.tab.value)) {
                                                            if (Array.isArray(this.state.selected_adgroups) && this.state.selected_adgroups.length && !item.list) {
                                                                return this.state.selected_adgroups.some(inner_item => item.adgroup_id === inner_item.id)
                                                            } else {
                                                                return true
                                                            }
                                                        } else if (["negative_keywords"].includes(this.state.tab.value)) {
                                                            if (!this.state.negative_filter || this.state.negative_filter.value === "adgroup") {
                                                                if (Array.isArray(this.state.selected_adgroups) && this.state.selected_adgroups.length && !item.list) {
                                                                    return this.state.selected_adgroups.some(inner_item => item.adgroup_id === inner_item.id)
                                                                } else {
                                                                    return true
                                                                }
                                                            } else {
                                                                return true
                                                            }
                                                        } else {
                                                            return true
                                                        }
                                                    })
                                                    .filter((item) => {
                                                        if ((this.state.tab.value === "adgroups" && this.state.tab_search.adgroups !== "") || (this.state.tab.value === "ads" && this.state.tab_search.ads !== "")) {
                                                            return item.name.toLowerCase().includes(this.state.tab.value === "adgroups" ? this.state.tab_search.adgroups.toLowerCase() : this.state.tab_search.ads.toLowerCase())
                                                        } else return true
                                                    }).map((item, index) => {
                                                        let total_length = this.state[this.state.tab.value].filter((item) => {
                                                            return ((item.channel === this.state.channel.value) || this.state.channel.value == "all")
                                                        }).length
                                                        let init = true;
                                                        if ((this.state.tab.value === "keywords" || this.state.tab.value === "negative_keywords") && this.state.adgroup.id) {
                                                            let id = item.resourceName.substring(0, item.resourceName.indexOf("~")).substring(item.resourceName.lastIndexOf("/") + 1);
                                                            if (id !== this.state.adgroup.id) {
                                                                init = false;
                                                            }
                                                        }
                                                        if (init) {
                                                            return (
                                                                <Fragment>
                                                                    <tr key={item.id} className="border-b relative">
                                                                        {
                                                                            item.status === 'syncing' &&
                                                                            <div style={{ 'zIndex': 100 }} className="absolute bg-white bg-opacity-50 left-0 right-0 top-0 bottom-0"></div>
                                                                        }
                                                                        <td id={index} style={{ whiteSpace: "nowrap" }} className={(this.state.sortBy === 'name' ? "bg-gray-50" : " bg-white") + " px-4 sm:px-6 py-4 whitespace-no-wrap font-semibold items-center border-gray-300 sticky z-30 left-0 flex overflow-visible"}>
                                                                            {
                                                                                !["keywords", "negative_keywords", "search_terms", "ads", "assets"].includes(this.state.tab.value) &&
                                                                                this.state[this.state.tab.selected] &&
                                                                                <div onClick={() => {
                                                                                    if (this.state.tab.value === 'campaigns') {
                                                                                        if (this.state.selected_campaigns.some(inner_item => item.id === inner_item.id)) { //remove
                                                                                            this.state.selected_campaigns = this.state.selected_campaigns.filter(inner_item => item.id !== inner_item.id);
                                                                                        } else { //add
                                                                                            this.state.selected_campaigns.push(item);
                                                                                        }

                                                                                        this.state.adgroup = {};
                                                                                        this.state.ad = {};
                                                                                        this.state.adgroups = [];
                                                                                        this.state.selected_adgroups = [];
                                                                                        this.state.ads = [];
                                                                                        this.state.keywords = [];
                                                                                        this.state.negative_keywords = [];
                                                                                        this.state.negative_keywords_campaign = [];
                                                                                        this.state.search_terms = [];
                                                                                        this.state.assets = [];
                                                                                        this.state.selected_channels = this.state.selected_campaigns.length ? this.state.selected_campaigns.map(i => i.channel) : ["all"];
                                                                                    }

                                                                                    if (this.state.tab.value === 'adgroups') {
                                                                                        if (this.state.selected_adgroups.some(inner_item => item.id === inner_item.id)) { //remove
                                                                                            this.state.selected_adgroups = this.state.selected_adgroups.filter(inner_item => item.id !== inner_item.id);
                                                                                        } else { //add
                                                                                            this.state.selected_adgroups.push(item);
                                                                                        }
                                                                                        this.state.ad = {};
                                                                                        this.state.ads = [];
                                                                                        this.state.keywords = [];
                                                                                        this.state.negative_keywords = [];
                                                                                        this.state.search_terms = [];
                                                                                        this.state.assets = [];
                                                                                    }
                                                                                    this.setState(this.state);
                                                                                }} className={(
                                                                                    (this.state[this.state.tab.selected] && item.id === this.state[this.state.tab.selected].id) ||
                                                                                        (["campaigns", "adgroups"].includes(this.state.tab.value) && this.state['selected_' + this.state.tab.value].some(inner_item => item.id === inner_item.id)) ? "border-purple-500 bg-purple-500 text-white" : "border-gray-300 bg-white hover:bg-gray-50 text-gray-700 ") + (item.status === 'syncing' ? " opacity-0 " : "") + " cursor-pointer mr-2 flex relative w-10 h-10 justify-center items-center rounded-md border text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-purple-500"}>

                                                                                    {
                                                                                        (["campaigns", "adgroups"].includes(this.state.tab.value) && this.state['selected_' + this.state.tab.value].some(inner_item => item.id === inner_item.id) ||
                                                                                            (this.state[this.state.tab.selected] && item.id === this.state[this.state.tab.selected].id)) &&
                                                                                        <CheckIcon className="h-5 w-5" />
                                                                                    }

                                                                                </div>
                                                                            }
                                                                            {
                                                                                this.state.tab.value === 'search_terms' &&
                                                                                <div className="flex flex-row">
                                                                                    <div onClick={() => {
                                                                                        item.selected = !item.selected;
                                                                                        this.setState({
                                                                                            search_terms: this.state.search_terms
                                                                                        });
                                                                                    }} className={(item.selected ? "border-purple-500 bg-purple-500 text-white" : "border-gray-300 bg-white hover:bg-gray-50 text-gray-700 ") + " cursor-pointer mr-2 flex relative w-10 h-10 justify-center items-center rounded-md border text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-purple-500"}>
                                                                                        {
                                                                                            item.selected &&
                                                                                            <CheckIcon className="h-5 w-5" />
                                                                                        }
                                                                                    </div>
                                                                                    <div
                                                                                        onClick={async () => {
                                                                                            if (item.searchTermView && item.searchTermView.status === "NONE") {
                                                                                                item.selected = !item.selected;
                                                                                                await this.promisedSetState({
                                                                                                    search_terms: this.state.search_terms
                                                                                                });
                                                                                                this.functions.menu("Add as negative to Adgroup", { channel: "google", multi: true });
                                                                                                //this.functions.menu("Add as negative to Adgroup", item);
                                                                                            }

                                                                                        }}
                                                                                        className={(item.searchTermView && item.searchTermView.status === "NONE" ? "cursor-pointer " : "cursor-not-allowed ") + "flex relative w-10 h-10 justify-center items-center rounded-md border text-red-600 border-gray-300 bg-white text-sm font-medium hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"}
                                                                                    >
                                                                                        {
                                                                                            item.searchTermView.status === "EXCLUDED" &&
                                                                                            <BanIcon className="h-5 w-5" aria-hidden="true" />
                                                                                        }
                                                                                        {
                                                                                            item.searchTermView.status === "NONE" &&
                                                                                            <ThumbDownIcon className="h-5 w-5" aria-hidden="true" />
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            }
                                                                            {
                                                                                this.state.tab.value === "keywords" &&
                                                                                <div className="flex flex-row">
                                                                                    <div onClick={() => {
                                                                                        item.selected = !item.selected;
                                                                                        this.setState({
                                                                                            keywords: this.state.keywords
                                                                                        });

                                                                                    }} className={(item.selected ? "border-purple-500 bg-purple-500 text-white" : "border-gray-300 bg-white hover:bg-gray-50 text-gray-700 ") + " cursor-pointer mr-2 flex relative w-10 h-10 justify-center items-center rounded-md border text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-purple-500"}>
                                                                                        {
                                                                                            item.selected &&
                                                                                            <CheckIcon className="h-5 w-5" />
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            }
                                                                            {
                                                                                this.state.tab.value === "negative_keywords" &&
                                                                                <div className="flex flex-row">
                                                                                    <div onClick={async () => {
                                                                                        item.selected = !item.selected;
                                                                                        if (this.state.negative_filter && this.state.negative_filter.value === "campaign") {
                                                                                            await this.promisedSetState({
                                                                                                negative_keywords_campaign: this.state.negative_keywords_campaign
                                                                                            });
                                                                                        } else {
                                                                                            await this.promisedSetState({
                                                                                                negative_keywords: this.state.negative_keywords
                                                                                            });
                                                                                        }
                                                                                    }} className={(item.selected ? "border-purple-500 bg-purple-500 text-white" : "border-gray-300 bg-white hover:bg-gray-50 text-gray-700 ") + " cursor-pointer mr-2 flex relative w-10 h-10 justify-center items-center rounded-md border text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-purple-500"}>
                                                                                        {
                                                                                            item.selected &&
                                                                                            <CheckIcon className="h-5 w-5" />
                                                                                        }
                                                                                    </div>

                                                                                </div>
                                                                            }
                                                                            {
                                                                                this.state.tab.value === "assets" &&
                                                                                <div className="flex flex-row">
                                                                                    <div onClick={async () => {
                                                                                        item.selected = !item.selected;

                                                                                        await this.promisedSetState({
                                                                                            assets: this.state.assets
                                                                                        });

                                                                                    }} className={(item.selected ? "border-purple-500 bg-purple-500 text-white" : "border-gray-300 bg-white hover:bg-gray-50 text-gray-700 ") + " cursor-pointer mr-2 flex relative w-10 h-10 justify-center items-center rounded-md border text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-purple-500"}>
                                                                                        {
                                                                                            item.selected &&
                                                                                            <CheckIcon className="h-5 w-5" />
                                                                                        }
                                                                                    </div>

                                                                                </div>
                                                                            }
                                                                            {
                                                                                (this.state.tab.value !== 'search_terms') &&

                                                                                <Menu as="div" className={(item.status === 'syncing' ? "opacity-0" : "") + " relative inline-block text-left"}>
                                                                                    <div>
                                                                                        <Menu.Button disabled={item.status === 'syncing'} className={"cursor-pointer flex relative w-10 h-10 justify-center items-center rounded-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"}
                                                                                        >
                                                                                            <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                                                                                            {
                                                                                                item.loading &&
                                                                                                <div className="w-full h-full absolute bg-white top-0 bottom-0 rounded-md left-0 right-0 flex justify-center items-center">
                                                                                                    <div style={{ borderTopColor: "transparent" }}
                                                                                                        class="w-4 h-4 border-2 border-purple-500 absolute border-solid rounded-full animate-spin"></div>
                                                                                                </div>

                                                                                            }
                                                                                        </Menu.Button>
                                                                                    </div>
                                                                                    <Transition
                                                                                        beforeEnter={() => {
                                                                                            document.getElementById(index).classList.add("z-50")
                                                                                        }}
                                                                                        afterLeave={() => {
                                                                                            document.getElementById(index).classList.remove("z-50")
                                                                                        }}
                                                                                        as={Fragment}
                                                                                        enter="transition ease-out duration-100"
                                                                                        enterFrom="transform opacity-0 scale-95"
                                                                                        enterTo="transform opacity-100 scale-100"
                                                                                        leave="transition ease-in duration-75"
                                                                                        leaveFrom="transform opacity-100 scale-100"
                                                                                        leaveTo="transform opacity-0 scale-95"
                                                                                    >
                                                                                        <Menu.Items className={`${total_length - (index) < 4 && total_length > 4 ? "bottom-100 left-100" : ""} border absolute z-50 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none`}>

                                                                                            <div className="py-1 font-normal">
                                                                                                {
                                                                                                    Array.isArray(this.renders.menuOptions(item)) &&
                                                                                                    this.renders.menuOptions(item).map((option) => {
                                                                                                        return (
                                                                                                            <Menu.Item>
                                                                                                                {({ active }) => (
                                                                                                                    <div
                                                                                                                        onClick={() => {
                                                                                                                            this.functions.menu(option, item);
                                                                                                                        }}
                                                                                                                        className={cn(
                                                                                                                            active ? (option !== "Delete" ? 'bg-gray-50 text-gray-900' : '') : (option !== "Delete" ? 'text-gray-700' : ''),
                                                                                                                            option === "Remove reference" ? 'text-red-700' : '',
                                                                                                                            option === "Delete" ? 'bg-red-700 text-white' : '',
                                                                                                                            'px-4 py-2 text-sm relative items-center flex flex-row cursor-pointer z-20'
                                                                                                                        )}
                                                                                                                    >
                                                                                                                        <div className="flex flex-1">{option}</div>
                                                                                                                        {
                                                                                                                            option !== "Schedule" && option !== "Negative to Campaign" && option !== "Positive to Campaign" && option !== "Add as negative to Adgroup" && option !== "Positive to Adgroup" && option !== "Delete" && option !== "Remove reference" && option !== "Pause" && option !== "Activate" && option !== "Edit" && option !== "Add new Ad" &&
                                                                                                                            <ExternalLinkIcon class="w-4 h-4" />
                                                                                                                        }
                                                                                                                        {
                                                                                                                            option === "Remove reference" &&
                                                                                                                            <TrashIcon class="w-4 h-4" />
                                                                                                                        }
                                                                                                                        {
                                                                                                                            option === "Delete" &&
                                                                                                                            <TrashIcon class="w-4 h-4" />
                                                                                                                        }
                                                                                                                        {
                                                                                                                            option === "Schedule" &&
                                                                                                                            <CalendarIcon class="w-4 h-4" />
                                                                                                                        }
                                                                                                                    </div>
                                                                                                                )}
                                                                                                            </Menu.Item>
                                                                                                        )
                                                                                                    })
                                                                                                }
                                                                                            </div>
                                                                                        </Menu.Items>
                                                                                    </Transition>
                                                                                </Menu>
                                                                            }
                                                                            <div onClick={async () => {
                                                                                await this.promisedSetState({ temp_selected_item: item })
                                                                                if (!["adgroups", "campaigns"].includes(this.state.tab.value)) {
                                                                                    this.state[this.state.tab.selected] = item
                                                                                }
                                                                                if (this.state.tab.value === 'campaigns') {
                                                                                    this.state.selected_campaigns = [];
                                                                                    this.state.selected_campaigns.push(item);
                                                                                    this.state.selected_channels = this.state.selected_campaigns.map(i => i.channel);
                                                                                    await this.promisedSetState({
                                                                                        selected_campaigns: this.state.selected_campaigns,
                                                                                        selected_channels: this.state.selected_channels,
                                                                                        adgroupAdsFetchedKeys: null
                                                                                    });
                                                                                }
                                                                                await this.promisedSetState(this.state);
                                                                                let tab = null;
                                                                                if (this.state.tab.value === 'campaigns') {
                                                                                    tab = { id: 2, name: "Adgroups", value: "adgroups", selected: 'adgroup', previous: 'campaign' };
                                                                                    this.state.adgroups = [];
                                                                                    this.state.ads = [];
                                                                                    this.state.keywords = [];
                                                                                    this.state.negative_keywords = [];
                                                                                    this.state.search_terms = [];
                                                                                }
                                                                                if (this.state.tab.value === 'adgroups') {
                                                                                    tab = { id: 3, name: "Ads", value: "ads", previous: 'adgroup' };
                                                                                    this.state.selected_adgroups = [item];
                                                                                }
                                                                                if (tab) {
                                                                                    await this.promisedSetState({
                                                                                        adgroups: this.state.adgroups,
                                                                                        selected_adgroups: this.state.selected_adgroups,
                                                                                        ads: this.state.ads,
                                                                                        tab: tab
                                                                                    });
                                                                                    if (this.state.tab.value === 'adgroups' && this.state.adgroups.length < 1) {
                                                                                        this.functions.getAdgroups();
                                                                                    }
                                                                                    if (this.state.tab.value === 'ads') {
                                                                                        this.functions.getAds();
                                                                                    }
                                                                                }
                                                                            }} className=" z-0 ml-2 cursor-pointer hover:text-purple-500 flex text-sm hover:underline items-center relative">
                                                                                {
                                                                                    this.state.loading_basic &&
                                                                                    <div className=' absolute bg-white bg-opacity-50 left-0 right-0 top-0 bottom-0'>
                                                                                    </div>
                                                                                }
                                                                                {
                                                                                    (item.channel === 'google' && this.state.tab.value === 'ads') &&
                                                                                    google_ad(item)
                                                                                }
                                                                                {
                                                                                    (item.channel !== 'google' && this.state.tab.value === 'ads') &&
                                                                                    social_ad(item)
                                                                                }
                                                                                {
                                                                                    ((item.channel !== 'google' && this.state.tab.value === 'ads') || this.state.tab.value !== 'ads') &&
                                                                                    <div>
                                                                                        {/* HERE */}
                                                                                        {item.name ? item.name : "-"}

                                                                                        {
                                                                                            this.state.tab.value === 'campaigns' &&
                                                                                            Array.isArray(item.tags) && item.tags.length > 0 &&
                                                                                            <div className="text-xxs text-gray-600 font-medium">{item.tags.map((item) => { return "#" + item.name }).join(" ")}</div>
                                                                                        }
                                                                                    </div>
                                                                                }
                                                                            </div>
                                                                        </td>

                                                                        {
                                                                            this.state.columns_new &&
                                                                            this.state.columns_new.filter(column => !column.disabled).filter((inner_item) => { return inner_item.value !== "name" }).map(col => {
                                                                                if (col.value === "scheduleActivated") {
                                                                                    if (this.state.tab.value === 'campaigns') {
                                                                                        return (
                                                                                            <td className={(this.state.sortBy === 'scheduleActivated' ? "bg-gray-50" : "") + " px-4 sm:px-6 py-4 items-center text-sm  border-gray-300"}>
                                                                                                <span
                                                                                                    className={cn("px-4 py-1 inline-flex text-xs font-bold rounded-full uppercase ",
                                                                                                        {
                                                                                                            ["bg-purple-100 text-purple-500"]: item.scheduleActivated,
                                                                                                            ["bg-gray-100 text-gray-600"]: !item.scheduleActivated,
                                                                                                        })
                                                                                                    }>
                                                                                                    {!item.scheduleActivated ? "Disabled" : "Enabled"}
                                                                                                </span>
                                                                                            </td>
                                                                                        )
                                                                                    }
                                                                                }

                                                                                if (Object.keys(item).includes(col.value) || (item.metrics && Object.keys(item.metrics).includes(col.value))) {
                                                                                    if (Object.keys(item).includes(col.value)) {

                                                                                        if (col.value === "start") {
                                                                                            return (
                                                                                                <td className={(this.state.sortBy === 'startDate' || this.state.sortBy === 'start' ? "bg-gray-50" : "") + " px-4 font-medium sm:px-6 py-4 items-center text-sm border-gray-300"}>
                                                                                                    {item.start && item.start !== '-' ? item.start : '-'}
                                                                                                </td>
                                                                                            )
                                                                                        } else if (col.value === "end") {
                                                                                            return (
                                                                                                <td className={(this.state.sortBy === 'endDate' || this.state.sortBy === 'end' ? "bg-gray-50" : "") + " px-4 font-medium sm:px-6 py-4 items-center text-sm border-gray-300"}>
                                                                                                    {item.end && item.end !== '-' ? item.end : '-'}
                                                                                                </td>
                                                                                            )
                                                                                        } else if (col.value === "status") {
                                                                                            return (
                                                                                                <td className={(this.state.sortBy === 'status' ? "bg-gray-50" : "") + " px-4 sm:px-6 py-4 items-center text-sm  border-gray-300 relative"}>
                                                                                                    {
                                                                                                        this.state.loading_basic &&
                                                                                                        <div className=' absolute flex items-center justify-center bg-white bg-opacity-50 left-0 right-0 top-0 bottom-0'>
                                                                                                            <div style={{ borderTopColor: "transparent" }}
                                                                                                                class="w-3 h-3 border-2 border-purple-500 absolute border-solid rounded-full animate-spin"></div>
                                                                                                        </div>
                                                                                                    }
                                                                                                    <span
                                                                                                        className={cn("px-4 py-1 inline-flex text-xs font-bold rounded-full uppercase ",
                                                                                                            {
                                                                                                                ["bg-green-100 text-green-500"]: item.status === "active" || item.status === "enable" || item.status === "enabled" || item.status === "scheduled",
                                                                                                                ["bg-purple-100 text-purple-500"]: item.status === "upcoming" || item.status === "syncing",
                                                                                                                ["bg-gray-100 text-gray-500"]: (item.status === "archived" || item.status === "ended" || item.status === "done" || item.status === "balance_exceed"),
                                                                                                                [" bg-gray-200 text-gray-500"]: (item.status === "expired" || item.status === "completed" || item.status === "time_done"),
                                                                                                                ["bg-orange-100 text-orange-500 bg-opacity-50"]: item.status === "paused" || item.status === "disable" || item.status === 'campaign_disable' || item.status === 'adgroup_disable',
                                                                                                                ["bg-gray-100 text-gray-500"]: item.status === "draft" || item.status === "pending",
                                                                                                                ["bg-red-100 text-red-600"]: item.status === "error" || item.status === "removed" || item.status === "rejected"
                                                                                                            })
                                                                                                        }>
                                                                                                        {item.status ? item.status : "-"}
                                                                                                        {
                                                                                                            item.channel === 'linkedin' &&
                                                                                                            item.rejected_reason && item.status === "rejected" &&
                                                                                                            <div
                                                                                                                onMouseEnter={() => { this.state.show[item.id] = true; this.setState({ show: this.state.show }) }}
                                                                                                                onMouseLeave={() => { this.state.show[item.id] = false; this.setState({ show: this.state.show }) }}
                                                                                                            >
                                                                                                                <ExclamationIcon className="h-4 w-4 text-red-600 ml-2" />
                                                                                                                <ToolTip
                                                                                                                    show={this.state.show[item.id]}
                                                                                                                    start={true}
                                                                                                                >
                                                                                                                    {
                                                                                                                        <div>
                                                                                                                            {item.rejected_reason.replace(/\_/g, " ").toLowerCase()}
                                                                                                                        </div>

                                                                                                                    }
                                                                                                                </ToolTip>
                                                                                                            </div>
                                                                                                        }
                                                                                                        {
                                                                                                            item.channel === 'google' &&
                                                                                                            item.policy_summary && item.policy_summary.approvalStatus === "DISAPPROVED" &&
                                                                                                            <div
                                                                                                                onMouseEnter={() => { this.state.show[item.id] = true; this.setState({ show: this.state.show }) }}
                                                                                                                onMouseLeave={() => { this.state.show[item.id] = false; this.setState({ show: this.state.show }) }}
                                                                                                            >
                                                                                                                <ExclamationIcon className="h-5 w-5 text-red-600 ml-2" />
                                                                                                                <ToolTip
                                                                                                                    show={this.state.show[item.id]}
                                                                                                                    start={true}
                                                                                                                >
                                                                                                                    {
                                                                                                                        Array.isArray(item.policy_summary.policyTopicEntries) &&
                                                                                                                        <div>
                                                                                                                            {item.policy_summary.policyTopicEntries.map((inner_item) => {
                                                                                                                                return inner_item.topic.replace(/\_/g, " ").toLowerCase()
                                                                                                                            }).toString()}
                                                                                                                        </div>

                                                                                                                    }
                                                                                                                </ToolTip>
                                                                                                            </div>
                                                                                                        }
                                                                                                    </span>
                                                                                                </td>
                                                                                            )
                                                                                        } else if (col.value === "channel") {
                                                                                            return (
                                                                                                <td className={(this.state.sortBy === 'channel' ? "bg-gray-50" : "") + " px-4 sm:px-6 capitalize py-4 items-center text-sm border-gray-300"}>
                                                                                                    <div className="flex flex-row items-center">
                                                                                                        {((item.status !== "draft" && item.channel === "facebook") || (item.status === "draft" && item.channels && item.channels["facebook"])) &&
                                                                                                            <div
                                                                                                                className="w-8 h-8 bg-facebook-500 rounded-full p-2 border-2 border-white">
                                                                                                                <div
                                                                                                                    className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                                                    style={{ backgroundImage: "url(" + require('../assets/images/facebook_icon.svg') + ")" }}></div>
                                                                                                            </div>
                                                                                                        }
                                                                                                        {((item.status !== "draft" && item.channel === "linkedin") || (item.status == "draft" && item.channels && item.channels["linkedin"])) &&
                                                                                                            <div
                                                                                                                className={'w-8 h-8 bg-linkedin-500 rounded-full p-2 border-2 border-white'}>
                                                                                                                <div
                                                                                                                    className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                                                    style={{ backgroundImage: "url(" + require('../assets/images/linkedin_icon.svg') + ")" }}></div>
                                                                                                            </div>
                                                                                                        }
                                                                                                        {((item.status !== "draft" && item.channel === "google") || (item.status == "draft" && item.channels && item.channels["google"])) &&
                                                                                                            <div
                                                                                                                className={'w-8 h-8 bg-google-500 rounded-full p-2 border-2 border-white'}>
                                                                                                                <div
                                                                                                                    className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                                                    style={{ backgroundImage: "url(" + require('../assets/images/google_icon.svg') + ")" }}></div>
                                                                                                            </div>
                                                                                                        }
                                                                                                        {((item.status !== "draft" && item.channel === "tiktok") || (item.status == "draft" && item.channels && item.channels["tiktok"])) &&
                                                                                                            <div
                                                                                                                className={'w-8 h-8 bg-black rounded-full p-2 border-2 border-white'}>
                                                                                                                <div
                                                                                                                    className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                                                    style={{ backgroundImage: "url(" + require('../assets/images/tiktok_icon.png') + ")" }}></div>
                                                                                                            </div>
                                                                                                        }
                                                                                                        {((item.status !== "draft" && item.channel === "twitter") || (item.status == "draft" && item.channels && item.channels["twitter"])) &&
                                                                                                            <div
                                                                                                                className={'w-8 h-8 bg-twitter-500 rounded-full p-2 border-2 border-white'}>
                                                                                                                <div
                                                                                                                    className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                                                    style={{ backgroundImage: "url(" + require('../assets/images/twitter_icon.svg') + ")" }}></div>
                                                                                                            </div>
                                                                                                        }
                                                                                                        {((item.status !== "draft" && item.channel === "snapchat") || (item.status == "draft" && item.channels && item.channels["snapchat"])) &&
                                                                                                            <div
                                                                                                                className={'w-8 h-8 bg-snapchat-500 rounded-full p-2 border-2 border-white'}>
                                                                                                                <div
                                                                                                                    className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                                                    style={{ backgroundImage: "url(" + require('../assets/images/snapchat.svg') + ")" }}></div>
                                                                                                            </div>
                                                                                                        }
                                                                                                        {((item.status !== "draft" && item.channel === "bing") || (item.status == "draft" && item.channels && item.channels["bing"])) &&
                                                                                                            <div
                                                                                                                className={'w-8 h-8 bg-bing-500 rounded-full p-2 border-2 border-white'}>
                                                                                                                <div
                                                                                                                    className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                                                    style={{ backgroundImage: "url(" + require('../assets/images/bing-logo.png') + ")" }}></div>
                                                                                                            </div>
                                                                                                        }
                                                                                                        {
                                                                                                            item.status !== "draft" &&
                                                                                                            <div className="ml-3 456 font-medium">
                                                                                                                {item.channel}
                                                                                                            </div>
                                                                                                        }
                                                                                                    </div>
                                                                                                </td>
                                                                                            )
                                                                                        } if (col.value === "approval_status") {
                                                                                            return (
                                                                                                <td className={(this.state.sortBy === 'approval_status' ? "bg-gray-50" : "") + " px-4 font-medium sm:px-6 py-4 items-center text-sm border-gray-300"}>
                                                                                                    <span
                                                                                                        className={cn("px-4 py-1 inline-flex text-xs font-bold rounded-full uppercase ",
                                                                                                            {
                                                                                                                ["bg-green-100 text-green-500"]: item.approval_status === "active" || item.approval_status === "enable" || item.approval_status === "enabled" || item.approval_status === "approved",
                                                                                                                ["bg-purple-100 text-purple-500"]: item.approval_status === "upcoming",
                                                                                                                ["bg-gray-100 text-gray-500"]: (item.approval_status === "archived" || item.approval_status === "ended" || item.approval_status === "done" || item.approval_status === "balance_exceed"),
                                                                                                                [" bg-gray-200 text-gray-500"]: (item.approval_status === "expired" || item.approval_status === "completed" || item.approval_status === "time_done"),
                                                                                                                ["bg-orange-100 text-orange-500 bg-opacity-50"]: item.approval_status === "paused" || item.approval_status === "disable",
                                                                                                                ["bg-gray-100 text-gray-500"]: item.approval_status === "draft" || item.approval_status === "pending",
                                                                                                                ["bg-red-100 text-red-600"]: item.approval_status === "error"
                                                                                                            })
                                                                                                        }>
                                                                                                        {item.approval_status ? item.approval_status : "-"}
                                                                                                    </span>
                                                                                                </td>
                                                                                            )
                                                                                        }
                                                                                        else {
                                                                                            return (
                                                                                                <td className={(this.state.sortBy === col.value ? "bg-gray-50" : "") + " px-4 font-medium sm:px-6 py-4 items-center text-sm border-gray-300"}>
                                                                                                    {item[col.value] ? item[col.value].toString() : "-"}
                                                                                                </td>
                                                                                            )
                                                                                        }

                                                                                    } else if (item.metrics && Object.keys(item.metrics).includes(col.value)) {
                                                                                        return (
                                                                                            <td className={(this.state.sortBy === col.value ? "bg-gray-50" : "") + " px-4 font-medium sm:px-6 py-4 items-center text-sm border-gray-300"}>
                                                                                                <span className="flex items-center">
                                                                                                    {item.metrics ? item.metrics[col.value] : "-"}
                                                                                                    {
                                                                                                        item.metrics && this.state.loading_metrics &&
                                                                                                        <span className="ml-2 flex items-center">
                                                                                                            <div style={{ borderTopColor: "transparent" }}
                                                                                                                class="w-3 h-3 border-2 border-purple-500 absolute border-solid rounded-full animate-spin"></div>
                                                                                                        </span>
                                                                                                    }
                                                                                                </span>
                                                                                            </td>
                                                                                        )
                                                                                    }
                                                                                } else {
                                                                                    return (
                                                                                        <td className={(this.state.sortBy === col.value ? "bg-gray-50" : "") + " px-4 font-medium sm:px-6 py-4 items-center text-sm border-gray-300"}>
                                                                                            -
                                                                                        </td>
                                                                                    )
                                                                                }
                                                                            })
                                                                        }
                                                                    </tr>
                                                                </Fragment >
                                                            )
                                                        }
                                                    })
                                            }
                                        </tbody >
                                    </table >
                                }

                                {
                                    ((this.state.tab.value === 'keywords' && this.state.keywords.length > 0 && this.state.pagination.positive_pagination) || (this.state.tab.value === 'search_terms' && this.state.search_terms.length > 0 && this.state.pagination.search_term) || (this.state.tab.value === "negative_keywords" && ((this.state.negative_filter && this.state.negative_filter.value === "campaign" && this.state.negative_keywords_campaign.length > 0 && this.state.pagination.campaign_negative_pagination) || ((!this.state.negative_filter || this.state.negative_filter.value === "adgroup") && this.state.negative_keywords.length > 0 && this.state.pagination.negative_pagination)))) &&
                                    <div className="w-full px-4 py-6 flex justify-center items-center">
                                        <button
                                            onClick={() => {
                                                if (this.state.tab.value === 'keywords') {
                                                    [...new Set(this.state.selected_channels)].forEach(item => {
                                                        if (this[item].getKeywords) {
                                                            this[item].getKeywords()
                                                        }
                                                    })
                                                } else if (this.state.tab.value === "negative_keywords") {
                                                    [...new Set(this.state.selected_channels)].forEach(item => {

                                                        if (!this.state.negative_filter || this.state.negative_filter.value === "adgroup") {
                                                            if (this[item].getNegativeKeywords) {
                                                                this[item].getNegativeKeywords()
                                                            }
                                                        } else if (this.state.negative_filter && this.state.negative_filter.value === "campaign") {
                                                            if (this[item].getCampaignNegativeKeywords) {
                                                                this[item].getCampaignNegativeKeywords()
                                                            }
                                                        }
                                                    })
                                                }
                                                if (this.state.tab.value === 'search_terms') {
                                                    [...new Set(this.state.selected_channels)].forEach(item => {
                                                        if (this[item].getSearchterms) {
                                                            this[item].getSearchterms()
                                                        }
                                                    })
                                                }
                                            }}
                                            className={"ml-4 inline-flex shadow relative justify-center rounded-md border border-transparent bg-purple-500 py-2 px-12 text-sm font-medium text-white hover:bg-purple-600 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"}
                                        >
                                            Load more
                                            {
                                                this.state.loading_partial &&
                                                <div className="w-full h-full absolute bg-purple-500 top-0 bottom-0 rounded-md border left-0 right-0 flex justify-center items-center">
                                                    <div style={{ borderTopColor: "transparent" }}
                                                        class="w-4 h-4 border-2 border-white absolute border-solid rounded-full animate-spin"></div>
                                                </div>
                                            }
                                        </button>
                                    </div>
                                }
                            </div >
                        }
                    </div >
                }
            </div >
        )
    }

}

export default OrderOptimizeNew;