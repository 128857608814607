import React, { Component, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react'
import { BeakerIcon, CheckIcon, ExclamationIcon, InformationCircleIcon, MinusIcon, PlusIcon, ReplyIcon } from '@heroicons/react/outline';
import cn from "classnames";
import InputTailwind from './inputTailwind';
import { tokenRegister } from '../services/tokenRegister';
import { apiRegister } from '../services/apiRegister';
import DropdownTailwind from './dropdownTailwind';
import TextAreaTailwind from './textAreaTailwind';
import InputDatepickerTailwind from './inputDatepickerTailwind';
import InputTimepickerTailwind from './inputTimepickerTailwind';

import tiktokLanguages from '../assets/json/tiktok_languages.json';
import moment from 'moment';

import { adgroupTikTok } from '../validators/adgroupTiktok';
import SwitchTailwind from './switchTailwind';

class EditLiveTikTokAdgroup extends Component {

    constructor(props) {
        super(props);
        this.state = {
            error: false,
            wizard: false,
            open: false,
            adgroup: {},
            updated: {},
            client: {},
            loader: false,
            audience_type: { id: 0, name: "Select ..." },
            billing_event_options: [
                { id: 1, name: "CPC", value: "CPC" },
                { id: 2, name: "CPM", value: "CPM" },
                { id: 3, name: "CPV", value: "CPV" },
                { id: 4, name: "OCPM", value: "OCPM" },
            ],
            goals: [
                { id: 1, name: 'Show', value: "SHOW" },
                { id: 2, name: 'In App Event', value: "IN_APP_EVENT" },
                { id: 4, name: 'Product click in live', value: "PRODUCT_CLICK_IN_LIVE" },
                { id: 5, name: 'Conversion', value: "CONVERT" },
                { id: 7, name: 'Install', value: "INSTALL" },
                { id: 8, name: 'Click', value: "CLICK" },
                //{ id: 9, name: 'Leads', value: "LEAD_GENERATION" },
                { id: 10, name: 'Reach', value: "REACH" },
                { id: 11, name: 'MT Live room', value: "MT_LIVE_ROOM" },
                { id: 12, name: 'Followers', value: "FOLLOWERS" },
                { id: 13, name: '6-Second video views', value: "VIDEO_VIEW" },//?
                { id: 14, name: 'Profile views', value: "PROFILE_VIEWS" },
                { id: 15, name: 'Value', value: "VALUE" },
            ],
            tab: { id: 1, name: "Settings" },
            tabs: [
                { id: 1, name: "Settings", value: "basic" },
                { id: 2, name: "Promotion type" },
                { id: 3, name: "Placements" },
                { id: 4, name: "Creative Type" },
                { id: 5, name: "Targeting" },
                { id: 6, name: "Budget & Schedule" },
                { id: 7, name: "Bidding & Optimization" },
                //{ id: 8, name: "App" },
            ],
            status_values: [
                { id: 1, name: 'Enabled', value: "ENABLE" },
                { id: 2, name: 'Disabled', value: "DISABLE" },
                { id: 3, name: 'Deleted', value: "DELETE" }
            ],
            promotion_types: [
                { id: 1, name: "Website", value: "WEBSITE" },
                { id: 2, name: "App (Android)", value: "APP_ANDROID" },
                { id: 3, name: "App (iOS)", value: "APP_IOS" }
            ],
            creator_interactions: [
                { id: 1, name: "Followed", value: "FOLLOWING" },
                { id: 2, name: "Viewed profile", value: "VIEW_HOMEPAGE" }
            ],
            video_interactions: [
                { id: 1, name: "Watched till the end", value: "WATCHED_TO_END" },
                { id: 2, name: "Liked", value: "LIKED" },
                { id: 3, name: "Commented", value: "COMMENTED" },
                { id: 4, name: "Shared", value: "SHARED" }
            ],
            ages: [
                { id: 1, name: "All ages", value: "ALL", selected: true },
                { id: 2, name: "13-17", value: "AGE_13_17", selected: false },
                { id: 3, name: "18-24", value: "AGE_18_24", selected: false },
                { id: 4, name: "25-34", value: "AGE_25_34", selected: false },
                { id: 5, name: "35-44", value: "AGE_35_44", selected: false },
                { id: 6, name: "45-54", value: "AGE_45_54", selected: false },
                { id: 7, name: "50+", value: "AGE_55_100", selected: false }
            ],
            genders: [
                { id: 1, name: "All genders", value: "GENDER_UNLIMITED", selected: true },
                { id: 2, name: "Men only", value: "GENDER_MALE", selected: false },
                { id: 3, name: "Female only", value: "GENDER_FEMALE", selected: false }
            ],
            budget_modes: [
                { id: 1, name: "Daily budget", value: "BUDGET_MODE_DAY" },
                { id: 2, name: "Lifetime budget", value: "BUDGET_MODE_TOTAL" }
            ],
            days: [
                { id: 1, name: "Monday", time: { id: 2, name: "Start - End" }, start: { id: 1, name: "00:00" }, end: { id: 49, name: "24:00" } },
                { id: 2, name: "Tuesday", time: { id: 2, name: "Start - End" }, start: { id: 1, name: "00:00" }, end: { id: 49, name: "24:00" } },
                { id: 3, name: "Wednesday", time: { id: 2, name: "Start - End" }, start: { id: 1, name: "00:00" }, end: { id: 49, name: "24:00" } },
                { id: 4, name: "Thursday", time: { id: 2, name: "Start - End" }, start: { id: 1, name: "00:00" }, end: { id: 49, name: "24:00" } },
                { id: 5, name: "Friday", time: { id: 2, name: "Start - End" }, start: { id: 1, name: "00:00" }, end: { id: 49, name: "24:00" } },
                { id: 6, name: "Saturday", time: { id: 2, name: "Start - End" }, start: { id: 1, name: "00:00" }, end: { id: 49, name: "24:00" } },
                { id: 7, name: "Sunday", time: { id: 2, name: "Start - End" }, start: { id: 1, name: "00:00" }, end: { id: 49, name: "24:00" } }
            ],
            hours_options: [
                { id: 1, name: "00:00" }, { id: 2, name: "00:30" }, { id: 3, name: "01:00" }, { id: 4, name: "01:30" }, { id: 5, name: "02:00" }, { id: 6, name: "02:30" },
                { id: 7, name: "03:00" }, { id: 8, name: "03:30" }, { id: 9, name: "04:00" }, { id: 10, name: "04:30" }, { id: 11, name: "05:00" }, { id: 12, name: "05:30" },
                { id: 13, name: "06:00" }, { id: 14, name: "06:30" }, { id: 15, name: "07:00" }, { id: 16, name: "07:30" }, { id: 17, name: "08:00" }, { id: 18, name: "08:30" },
                { id: 19, name: "09:00" }, { id: 20, name: "09:30" }, { id: 21, name: "10:00" }, { id: 22, name: "10:30" }, { id: 23, name: "11:00" }, { id: 24, name: "11:30" },
                { id: 25, name: "12:00" }, { id: 26, name: "12:30" }, { id: 27, name: "13:00" }, { id: 28, name: "13:30" }, { id: 29, name: "14:00" }, { id: 30, name: "14:30" },
                { id: 31, name: "15:00" }, { id: 32, name: "15:30" }, { id: 33, name: "16:00" }, { id: 34, name: "16:30" }, { id: 35, name: "17:00" }, { id: 36, name: "17:30" },
                { id: 37, name: "18:00" }, { id: 38, name: "18:30" }, { id: 39, name: "19:00" }, { id: 40, name: "19:30" }, { id: 41, name: "20:00" }, { id: 42, name: "20:30" },
                { id: 43, name: "21:00" }, { id: 44, name: "21:30" }, { id: 45, name: "22:00" }, { id: 46, name: "22:30" }, { id: 47, name: "23:00" }, { id: 48, name: "23:30" },
                { id: 49, name: "24:00" }
            ],
            targeting: {
                behaviors_creator: {
                    search: "",
                    loading: false,
                    options: [],
                    type: { id: 1, name: "Included", value: true }
                },
                behaviors_video: {
                    search: "",
                    loading: false,
                    options: [],
                    type: { id: 1, name: "Included", value: true }
                },
                categories: {
                    search: "",
                    loading: false,
                    options: [],
                    type: { id: 1, name: "Included", value: true }
                },
                locations: {
                    search: "",
                    loading: false,
                    options: [],
                    type: { id: 1, name: "Included", value: true }
                },
                pixels: {
                    search: "",
                    loading: false,
                    options: [],
                    type: { id: 1, name: "Included", value: true }
                },
                languages: {
                    search: "",
                    loading: false,
                    options: tiktokLanguages.map((item) => { return { name: item.name, id: item.code } }),
                    type: { id: 1, name: "Included", value: true }
                },
                interests: {
                    search: "",
                    loading: false,
                    options: [],
                    type: { id: 1, name: "Included", value: true }
                },
                hashtags: {
                    search: "",
                    loading: false,
                    options: [],
                    type: { id: 1, name: "Included", value: true }
                },
                apps: {
                    search: "",
                    loading: false,
                    options: [],
                    type: { id: 1, name: "Included", value: true }
                }
            },
            locked: false

        }
    };

    async componentDidMount() {
        await this.promisedSetState({
            adgroup: this.props.adgroup,
            client: this.props.client,
            wizard: this.props.wizard,
            locked: this.props.locked ? this.props.locked : false
        });
        this.functions.listLocations();
        this.functions.listBehaviors();
        this.functions.listCategories();
        this.functions.gender();
        this.functions.ages();
        console.log(this.state.adgroup, "adgroup");
    }

    componentWillReceiveProps(nextProps) {

    }

    functions = {
        ages: async () => {
            try {
                if (this.state.adgroup.ages && this.state.adgroup.ages.length > 0) {
                    this.state.ages = this.state.ages.map((item) => {
                        if (this.state.adgroup.ages.filter((age) => { return age.id === item.id }).length > 0) {
                            item.selected = true;
                        } else {
                            item.selected = false;
                        }
                        return item;
                    });
                    await this.promisedSetState({ ages: this.state.ages });
                }
            } catch (error) {

            }
        },
        gender: async () => {
            try {
                if (this.state.adgroup.gender) {
                    this.state.genders = this.state.genders.map((item) => {
                        if (item.value !== this.state.adgroup.gender.value) {
                            item.selected = false;
                        } else {
                            item.selected = true;
                        }
                        return item;
                    });
                    await this.promisedSetState({ genders: this.state.genders });
                }
            } catch (error) {

            }
        },
        searchInterests: async (search) => {
            try {
                this.state.targeting["interests"].loading = true;
                await this.promisedSetState({ targeting: this.state.targeting });
                let response = await this.calls.interests(search);
                this.state.targeting["interests"].options = response.data;
                this.state.targeting["interests"].loading = false;
                await this.promisedSetState({ targeting: this.state.targeting });
            } catch (error) {
                this.state.targeting["interests"].options = [];
                this.state.targeting["interests"].loading = false;
                await this.promisedSetState({ targeting: this.state.targeting });
            }
        },
        searchHashtags: async (search) => {
            try {
                this.state.targeting["hashtags"].loading = true;
                await this.promisedSetState({ targeting: this.state.targeting });
                let response = await this.calls.hashtags(search);
                this.state.targeting["hashtags"].options = response.data;
                this.state.targeting["hashtags"].loading = false;
                await this.promisedSetState({ targeting: this.state.targeting });
            } catch (error) {
                this.state.targeting["hashtags"].options = [];
                this.state.targeting["hashtags"].loading = false;
                await this.promisedSetState({ targeting: this.state.targeting });
            }
        },
        listBehaviors: async () => {
            try {
                this.state.targeting["behaviors_creator"].loading = true;
                this.state.targeting["behaviors_video"].loading = true;
                await this.promisedSetState({ targeting: this.state.targeting });
                let response = await this.calls.behaviors();
                this.state.targeting["behaviors_creator"].options = response.data.filter((item) => { return item.type === "CREATOR_RELATED" });
                this.state.targeting["behaviors_video"].options = response.data.filter((item) => { return item.type === "VIDEO_RELATED" });
                this.state.targeting["behaviors_creator"].loading = false;
                this.state.targeting["behaviors_video"].loading = false;
                await this.promisedSetState({ targeting: this.state.targeting });
            } catch (error) {
                this.state.targeting["behaviors_creator"].options = [];
                this.state.targeting["behaviors_video"].options = [];
                this.state.targeting["behaviors_creator"].loading = false;
                this.state.targeting["behaviors_video"].loading = false;
                await this.promisedSetState({ targeting: this.state.targeting });
            }
        },
        listLocations: async () => {
            try {
                this.state.targeting["locations"].loading = true;
                await this.promisedSetState({ targeting: this.state.targeting });
                let response = await this.calls.locations();
                this.state.targeting["locations"].options = response.data;
                this.state.targeting["locations"].loading = false;
                await this.promisedSetState({ targeting: this.state.targeting });
            } catch (error) {
                this.state.targeting["locations"].options = [];
                this.state.targeting["locations"].loading = false;
                await this.promisedSetState({ targeting: this.state.targeting });
            }
        },
        listCategories: async () => {
            try {

                this.state.targeting["categories"].loading = true;
                await this.promisedSetState({ targeting: this.state.targeting });
                let response = await this.calls.categories();
                this.state.targeting["categories"].options = response.data;
                this.state.targeting["categories"].loading = false;
                await this.promisedSetState({ targeting: this.state.targeting });
            } catch (error) {
                this.state.targeting["categories"].options = [];
                this.state.targeting["categories"].loading = false;
                await this.promisedSetState({ targeting: this.state.targeting });
            }
        },
        update: async (data) => {
            if (!this.state.wizard) {
                this.props.onSuccess(false);
                this.props.onLoader(true);
                this.promisedSetState({
                    error: false
                });
                if (!data) {
                    if (Object.keys(this.state.updated).length > 0) {
                        let body = {
                            ...(this.state.updated.name) && { adgroup_name: this.state.updated.name },
                            ...(this.state.updated.budget) && { budget: this.state.updated.budget },
                            ...(this.state.updated.start_date) && { schedule_start_time: this.state.updated.start_date },
                            ...(this.state.updated.end_date) && { schedule_end_time: this.state.updated.end_date, schedule_type: "SCHEDULE_START_END" },
                            ...(this.state.updated.end_date === null) && { schedule_type: "SCHEDULE_FROM_NOW" },
                            ...(this.state.updated.comments) && { comment_disabled: !this.state.updated.comments.value },
                            ...(this.state.updated.locations) && { location_ids: this.state.updated.locations.map((item) => { return item.id }) },
                            ...(this.state.updated.languages) && { languages: this.state.updated.languages.map((item) => { return item.id }) },
                            ...(this.state.updated.gender) && { gender: this.state.updated.gender.value },
                            ...(this.state.updated.ages) && { age_groups: this.state.updated.ages.filter((item) => { return item.id === 1 }).length > 0 ? [] : this.state.updated.ages.filter((item) => { return item.selected }).map((item) => { return item.value }) },
                            ...(this.state.updated.interests) && { interest_keyword_ids: this.state.updated.interests.map((item) => { return item.id.toString() }) },
                            ...(this.state.updated.categories) && { interest_category_ids: this.state.updated.categories.map((item) => { return item.id }) },
                        };
                        if (this.state.updated.hashtags || this.state.updated.behaviors_creator || this.state.updated.behaviors_video) {
                            let actions = this.state.adgroup.actions.length > 0 ? this.state.adgroup.actions : [];
                            if (this.state.updated.hashtags) {
                                if (actions.filter((item) => { return item.action_scene === "HASHTAG_RELATED" }).length > 0) {
                                    actions = actions.filter((item) => { return item.action_scene !== "HASHTAG_RELATED" });
                                }
                                if (this.state.updated.hashtags.length > 0) {
                                    actions.push({
                                        action_category_ids: this.state.updated.hashtags.map((item) => { return item.id.toString() }),
                                        action_scene: "HASHTAG_RELATED",
                                        action_period: 0,
                                        video_user_actions: ["VIEW_HASHTAG"]
                                    })
                                }
                            }
                            if (this.state.updated.behaviors_creator) {
                                if (actions.filter((item) => { return item.action_scene === "CREATOR_RELATED" }).length > 0) {
                                    actions = actions.filter((item) => { return item.action_scene !== "CREATOR_RELATED" });
                                }
                                if (this.state.updated.behaviors_creator.length > 0) {
                                    actions.push({
                                        action_category_ids: this.state.updated.behaviors_creator.map((item) => { return item.id.toString() }),
                                        action_scene: "CREATOR_RELATED",
                                        action_period: 0,
                                        video_user_actions: ["VIEW_HOMEPAGE", "FOLLOWING"]
                                    })
                                }
                            }
                            if (this.state.updated.behaviors_video) {
                                if (actions.filter((item) => { return item.action_scene === "VIDEO_RELATED" }).length > 0) {
                                    actions = actions.filter((item) => { return item.action_scene !== "VIDEO_RELATED" });
                                }
                                if (this.state.updated.behaviors_video.length > 0) {
                                    actions.push({
                                        action_category_ids: this.state.updated.behaviors_video.map((item) => { return item.id.toString() }),
                                        action_scene: "VIDEO_RELATED",
                                        action_period: 15,
                                        video_user_actions: ["WATCHED_TO_END", "LIKED", "COMMENTED", "SHARED"]
                                    })
                                }
                            }
                            body.actions = actions;
                        }
                        if (this.state.updated.targeting_mode) {
                            if (this.state.updated.targeting_mode.value === "auto") {
                                body.auto_targeting_enabled = true;
                            } else {
                                body.auto_targeting_enabled = false;
                            }
                        }
                        try {
                            let response = await this.calls.updateLiveAdgroup(body);
                            if (response.data.error) {
                                this.promisedSetState({
                                    error: response.data.error
                                });
                            }
                        } catch (error) {
                            //this.props.onError(error.error ? error.error : "Something went wrong");
                        }
                    }
                } else {
                    this.promisedSetState({
                        loading_status: true
                    });
                    try {
                        if (data.value === "DISABLE") {
                            this.props.onPauseAdgroup(true);
                        } else {
                            this.props.onActivateAdgroup(true);
                        }
                        this.state.updated.status = data;
                        this.promisedSetState({
                            loading_status: false,
                            updated: this.state.updated
                        });
                    } catch (error) {
                        //this.props.onError(error.error ? error.error : "Something went wrong");
                    }
                }
                if (!this.state.error) {
                    this.props.onSuccess(true);
                }
                this.props.onLoader(false);
            } else {
                this.props.onUpdate(this.state.updated);
            }
        }
    };

    calls = {
        interests: (search) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/tiktok/searchKeywords?client=" + this.state.client.id + "&search=" + search;
            return apiRegister.call(options, url);
        },
        hashtags: (search) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/tiktok/searchHashtags?client=" + this.state.client.id + "&search=" + search;
            return apiRegister.call(options, url);
        },
        categories: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/tiktok/searchCategories?client=" + this.state.client.id;
            return apiRegister.call(options, url);
        },
        behaviors: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/tiktok/searchBehaviors?client=" + this.state.client.id;
            return apiRegister.call(options, url);
        },
        locations: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/tiktok/listLocations?client=" + this.state.client.id;
            return apiRegister.call(options, url);
        },
        updateLiveAdgroup: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/adcredo/updateLiveAdgroup?client=" + this.state.client.id + "&adgroup=" + this.state.adgroup.id + "&channel=tiktok";
            return apiRegister.call(options, url);
        },
    };

    renders = {
        audience_options: (type) => {
            try {
                return this.state.targeting[type].options
            } catch (error) {
                console.log("error HANDLE", error);
                return [];
            }
        },
        audience_type: (type) => {
            try {
                if (type in this.state.updated) {
                    return this.state.updated[type];
                } else if (this.state.adgroup[type]) {
                    return this.state.adgroup[type];
                } else {
                    return [];
                }
            } catch (error) {
                return [];
            }
        },
        name: () => {
            try {
                if ('name' in this.state.updated) {
                    return this.state.updated.name;
                } else if (this.state.adgroup.name) {
                    return this.state.adgroup.name;
                } else {
                    return "";
                }
            } catch (error) {
                return "";
            }
        },
        locations: () => {
            try {
                if ('locations' in this.state.updated) {
                    return this.state.updated.locations;
                } else if (this.state.adgroup.locations) {
                    return this.state.adgroup.locations;
                } else {
                    return [];
                }
            } catch (error) {
                return [];
            }
        },
        behaviors_video: () => {
            try {
                if ('behaviors_video' in this.state.updated) {
                    return this.state.updated.behaviors_video;
                } else if (this.state.adgroup.behaviors_video) {
                    return this.state.adgroup.behaviors_video;
                } else {
                    return [];
                }
            } catch (error) {
                return [];
            }
        },
        behaviors_creator: () => {
            try {
                if ('behaviors_creator' in this.state.updated) {
                    return this.state.updated.behaviors_creator;
                } else if (this.state.adgroup.behaviors_creator) {
                    return this.state.adgroup.behaviors_creator;
                } else {
                    return [];
                }
            } catch (error) {
                return [];
            }
        },
        hashtags: () => {
            try {
                if ('hashtags' in this.state.updated) {
                    return this.state.updated.hashtags;
                } else if (this.state.adgroup.hashtags) {
                    return this.state.adgroup.hashtags;
                } else {
                    return [];
                }
            } catch (error) {
                return [];
            }
        },
        categories: () => {
            try {
                if ('categories' in this.state.updated) {
                    return this.state.updated.categories;
                } else if (this.state.adgroup.categories) {
                    return this.state.adgroup.categories;
                } else {
                    return [];
                }
            } catch (error) {
                return [];
            }
        },
        interests: () => {
            try {
                if ('interests' in this.state.updated) {
                    return this.state.updated.interests;
                } else if (this.state.adgroup.interests) {
                    return this.state.adgroup.interests;
                } else {
                    return [];
                }
            } catch (error) {
                return [];
            }
        },
        ages: () => {
            try {
                if ('ages' in this.state.updated) {
                    return this.state.updated.ages;
                } else if (this.state.adgroup.ages) {
                    return this.state.adgroup.ages;
                } else {
                    return [];
                }
            } catch (error) {
                return [];
            }
        },
        languages: () => {
            try {
                if ('languages' in this.state.updated) {
                    return this.state.updated.languages;
                } else if (this.state.adgroup.languages) {
                    return this.state.adgroup.languages;
                } else {
                    return [];
                }
            } catch (error) {
                return [];
            }
        },
        promotionType: () => {
            try {
                if ((this.state.promotion_types.filter((item) => { return item.value === this.state.adgroup.promotion_type }).length > 0)) {
                    return this.state.promotion_types.filter((item) => { return item.value === this.state.adgroup.promotion_type })[0];
                } else {
                    return { id: 0, name: this.state.adgroup.promotion_type, value: "" };
                }
            } catch (error) {
                return { id: 0, name: "...", value: "" };
            }
        },
        videoDownload: () => {
            try {
                if (this.state.adgroup.video_download_disabled === false) {
                    return { id: 1, name: "Yes", value: true };
                } else {
                    return { id: 2, name: "No", value: false };
                }
            } catch (error) {
                return { id: 2, name: "No", value: false };
            }
        },
        userComment: () => {
            try {
                if ('comments' in this.state.updated) {
                    return this.state.updated.comments;
                } else if (!this.state.adgroup.comment_disabled) {
                    return { id: 1, name: "Yes", value: true };
                } else {
                    return { id: 2, name: "No", value: false };
                }
            } catch (error) {
                return { id: 2, name: "No", value: false };
            }
        },
        autoPlacements: () => {
            try {
                if (this.state.adgroup.placement_type === "PLACEMENT_TYPE_AUTOMATIC") {
                    return { id: 1, name: "Yes", value: true };
                } else {
                    return { id: 2, name: "No", value: false };
                }
            } catch (error) {
                return { id: 1, name: "Yes", value: true };
            }
        },

        automatedCreative: () => {
            try {
                if (this.state.adgroup.creative_material_mode === "CUSTOM") {
                    return { id: 2, name: "No", value: false };
                } else {
                    return { id: 1, name: "Yes", value: true };
                }
            } catch (error) {
                return { id: 2, name: "No", value: false };
            }
        },
        usePixel: () => {
            try {
                if (this.state.adgroup.pixel_id !== null) {
                    return { id: 1, name: "Yes", value: true };
                } else {
                    return { id: 2, name: "No", value: false };
                }
            } catch (error) {
                return { id: 2, name: "No", value: false };
            }
        },
        Pixel: () => {
            try {
                if (this.state.adgroup.pixel) {
                    return this.state.adgroup.pixel;
                } return false;
            } catch (error) {
                return false;
            }
        },
        optimization_event: () => {
            try {
                if (typeof this.state.adgroup.optimization_event  === "object") {
                    return this.state.adgroup.optimization_event;
                } return false;
            } catch (error) {
                return false;
            }
        },
        pacing: () => {
            try {
                if (typeof this.state.adgroup.pacing  === "object") {
                    return this.state.adgroup.pacing;
                } else {
                    return false;
                }
            } catch (error) {
                return false;
            }
        },
        
        targetingMode: () => {
            try {
                if ('targeting_mode' in this.state.updated) {
                    return this.state.updated.targeting_mode;
                } else if (this.state.adgroup.targeting_mode) {
                    return this.state.adgroup.targeting_mode;
                }
            } catch (error) {
                return { id: 2, name: "Custom targeting", value: "custom" };
            }
        },
        videoDays: () => {
            try {
                if ("video_action_day" in this.state.updated) {
                    return this.state.updated.video_action_day;
                } else if ("video_action_day" in this.state.adgroup) {
                    return this.state.adgroup.video_action_day;
                } else {
                    return { id: 1, name: "7 Days", value: 7 };
                }
            } catch (error) {
                return { id: 1, name: "7 Days", value: 7 };
            }
        },
        budgetMode: () => {
            try {
                if (this.state.adgroup.budget_mode) {
                    return this.state.adgroup.budget_mode
                }
            } catch (error) {
                return { id: 1, name: "Daily budget", value: "BUDGET_MODE_DAY" };
            }
        },
        cammpaignOptimization: () => {
            try {
                if (this.state.adgroup.campaign_budget_optimization) {
                    return true
                } else return false
            } catch (error) {
                return false
            }
        },
        budget: () => {
            try {
                if ('budget' in this.state.updated) {
                    return this.state.updated.budget;
                } else if (this.state.adgroup.budget) {
                    return this.state.adgroup.budget;
                } else {
                    return "";
                }
            } catch (error) {
                return "";
            }
        },
        startHour: (index) => {
            try {
                if ('items' in this.state.adgroup) {
                    return this.state.adgroup.items[index].start;
                } else {
                    return this.state.days[index].start;
                }
            } catch (error) {
                return 0;
            }
        },
        endHour: (index) => {
            try {
                if ('items' in this.state.adgroup) {
                    return this.state.adgroup.items[index].end;
                } else {
                    return this.state.days[index].end;
                }
            } catch (error) {
                return 0;
            }
        },
        startDate: () => {
            try {
                if ('start_date' in this.state.updated) {
                    return this.state.updated.start_date;
                } else {
                    return moment(this.state.adgroup.start).format("YYYY-MM-DD HH:mm");
                }
            } catch (error) {
                return 0;
            }
        },
        endDate: () => {
            try {
                if ('end_date' in this.state.updated) {
                    return this.state.updated.end_date;
                } else if (this.state.adgroup.end === null) {
                    return null;
                } else {
                    return moment(this.state.adgroup.end).format("YYYY-MM-DD HH:mm");
                }
            } catch (error) {
                return 0;
            }
        },

        gender: () => {
            try {
                if ('gender' in this.state.updated) {
                    return this.state.updated.gender;
                } else if (this.state.adgroup.gender) {
                    return this.state.adgroup.gender;
                } else {
                    return { id: 1, name: "All ages", value: "GENDER_UNLIMITED" };
                }
            } catch (error) {
                return "";
            }
        },

        goal: () => {
            try {
                if ((this.state.goals.filter((item) => { return item.value === this.state.adgroup.optimization_goal }).length > 0)) {
                    return this.state.goals.filter((item) => { return item.value === this.state.adgroup.optimization_goal })[0];
                } else {
                    return { id: 0, name: this.state.adgroup.optimization_goal, value: "" };
                }
            } catch (error) {
                return { id: 0, name: "...", value: "" };
            }
        },
        billing_event: () => {
            try {
                if ((this.state.billing_event_options.filter((item) => { return item.value === this.state.adgroup.billing_event }).length > 0)) {
                    return this.state.billing_event_options.filter((item) => { return item.value === this.state.adgroup.billing_event })[0];
                } else {
                    return { id: 0, name: this.state.adgroup.billing_event, value: "" };
                }
            } catch (error) {
                return { id: 0, name: "...", value: "" };
            }
        },

        bidStrategy: () => {
            try {
                if (this.state.adgroup.bid_type === "BID_TYPE_CUSTOM") {
                    return { id: 2, name: "Bid cap", value: "BID_TYPE_CUSTOM" };
                } else {
                    return { id: 1, name: "Lowest cost", value: "BID_TYPE_NO_BID" };
                }
            } catch (error) {
                return { id: 1, name: "Lowest cost", value: "BID_TYPE_NO_BID" };
            }
        },
        bidCap: () => {
            try {
                if('bid_cap' in this.state.updated){
                    return this.state.updated.bid_cap;
                } else if (this.state.adgroup.bid_cap) {
                    return this.state.adgroup.bid_cap;
                } else {
                    return "";
                }
            } catch (error) {
                return "";
            }
        },
        status: () => {
            try {
                if ('status' in this.state.updated) {
                    return this.state.updated.status;
                } else if (this.state.status_values.filter((item) => { return item.value.toLowerCase() === this.state.adgroup.status.toLowerCase() }).length > 0) {
                    return this.state.status_values.filter((item) => { return item.value.toLowerCase() === this.state.adgroup.status.toLowerCase() })[0];
                } else {
                    return { id: 0, name: this.state.adgroup.status, value: "PAUSED" }
                }
            } catch (error) {
                return { id: 0, name: 'Click here ...', value: "PAUSED" }
            }
        }
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {
        let selected_targeting = (type, add = false) => {
            return (
                this.renders[type]().sort(function (x, y) {
                    return (x.included === y.included) ? 0 : x.included ? -1 : 1;
                }).map((item, index) => {
                    return (
                        <Fragment>
                            <div className={(item.included ? " bg-indigo-100 text-indigo-700" : " bg-red-100 text-red-700") + " flex m-1 rounded-full items-center py-1.5 px-3 text-sm font-medium "}>
                                <div className="">{item.name}</div>
                                <button
                                    onClick={() => {
                                        this.state.updated[type] = JSON.parse(JSON.stringify(this.renders[type]())).filter((location) => {
                                            return location.id !== item.id;
                                        })
                                        this.setState({
                                            updated: this.state.updated
                                        })
                                    }}
                                    type="button"
                                    className={(item.included ? "focus:bg-indigo-500 text-indigo-400 hover:bg-indigo-200 hover:text-indigo-500" : "focus:bg-red-500 text-red-400 hover:bg-red-200 hover:text-red-500") + "flex-shrink-0 ml-1 h-4 w-4 rounded-full inline-flex items-center justify-center focus:outline-none focus:text-white"}
                                >
                                    <span className="sr-only">Remove</span>
                                    <svg className="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
                                        <path strokeLinecap="round" strokeWidth="1.5" d="M1 1l6 6m0-6L1 7" />
                                    </svg>
                                </button>
                            </div>
                        </Fragment>
                    )
                })

            )
        }

        return (
            <>
                <div className="grid grid-cols-6 gap-4">

                    <div className="col-span-6">
                        <div className="block overflow-scroll w-ful">
                            <nav className="flex space-x-4" aria-label="Tabs">
                                {this.state.tabs.map((tab) => (
                                    <div
                                        onClick={() => (
                                            this.setState({
                                                tab: tab
                                            })
                                        )}
                                        key={tab.name}
                                        style={{ whiteSpace: "nowrap" }}
                                        className={cn(
                                            tab.id == this.state.tab.id ? 'bg-purple-100 text-purple-500' : 'text-gray-500 hover:text-purple-500',
                                            'px-3 py-2 font-medium text-sm rounded-md whitespace-nowrap cursor-pointer flex'
                                        )}
                                        aria-current={tab.id == this.state.tab.id ? 'page' : undefined}
                                    >
                                        <div>
                                            {
                                                (
                                                    (tab.id === 6 && (parseInt(this.renders.budget()) < 1 || isNaN(parseInt(this.renders.budget()))) && !this.renders.cammpaignOptimization()) ||
                                                    (tab.id === 6 && adgroupTikTok.validate({ start_date: this.renders.startDate(), ...(moment().unix() > moment(this.state.adgroup.start).unix() && { status: this.renders.status() }) }).start_date)

                                                ) ? <ExclamationIcon className="h-5 w-5 text-red-600 mr-2" /> : ""
                                            }
                                        </div>
                                        {tab.name}
                                    </div>
                                ))}
                            </nav>
                        </div>
                    </div>

                    {/*Basic*/}
                    {
                        this.state.tab.id === 1 &&
                        <div className="col-span-6">
                            <div className="col-span-6">
                                <InputTailwind
                                    label={"Adgroup name"}
                                    value={this.renders.name()}
                                    onChange={(value) => {
                                        this.state.updated.name = value;
                                        this.setState({
                                            updated: this.state.updated
                                        })
                                    }}
                                />
                            </div>

                            <div className="col-span-2 mt-2">
                                <div className='-mb-4 text-xs font-medium text-gray-700 flex flex-1'>Status</div>
                                <div className='flex items-center justify-center my-5 border-gray-300 w-full bg-custom-input border-1.5 rounded-md' style={{ paddingTop: "0.5rem", paddingBottom: "0.6rem" }}>
                                    <div className="flex flex-1 ml-5">
                                        {this.renders.status().name}
                                    </div>
                                    <div className="relative overflow-hidden mr-5">
                                        <SwitchTailwind
                                            value={this.renders.status().value === "ENABLE" ? true : false}
                                            disabled={this.state.locked}
                                            onSwitch={async () => {
                                                if (!this.state.locked) {
                                                    if (this.renders.status().value === "ENABLE") {
                                                        this.functions.update({ id: 2, name: 'Disabled', value: "DISABLE" });
                                                    } else if (this.renders.status().value === "DISABLE") {
                                                        this.functions.update({ id: 1, name: 'Active', value: "ENABLE" });
                                                    }
                                                }

                                            }}
                                        />
                                        {
                                            this.state.loading_status &&
                                            <div className="w-full h-full bg-opacity-50 absolute bg-white top-0 bottom-0 rounded-md left-0 right-0 flex justify-center items-center">
                                                <div style={{ borderTopColor: "transparent" }}
                                                    class="w-4 h-4 border-2 border-purple-500 absolute border-solid rounded-full animate-spin"></div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                    {/*Promotion type*/}
                    {
                        this.state.tab.id == 2 &&
                        <>
                            <div className="col-span-6">
                                <DropdownTailwind
                                    label={"Type"}
                                    selected={this.renders.promotionType()}
                                    options={[{ id: 1, name: "Website", value: "WEBSITE" }, { id: 2, name: "App (Android)", value: "APP_ANDROID" }, { id: 2, name: "App (iOS)", value: "APP_IOS" }]}
                                    locked={true}
                                />
                            </div>
                            {
                                this.renders.promotionType().id == 1 && false &&
                                <div className="col-span-6">
                                    <DropdownTailwind
                                        label={"Use TikTok Pixel for track your website"}
                                        selected={this.renders.usePixel()}
                                        options={[{ id: 1, name: "No", value: false }, { id: 2, name: "Yes", value: true }]}
                                        locked={true}
                                    />
                                </div>
                            }
                            {
                                this.renders.Pixel() &&
                                <div className="col-span-6">
                                    <DropdownTailwind
                                        label={"Pixel"}
                                        selected={this.renders.Pixel()}
                                        options={[]}
                                        locked={true}
                                    />
                                </div>
                            }
                            {
                                this.renders.optimization_event() &&
                                <div className="col-span-6">
                                    <DropdownTailwind
                                        label={"Optimization event"}
                                        selected={this.renders.optimization_event()}
                                        options={[]}
                                        locked={true}
                                    />
                                </div>
                            }
                            
                            {/*
                                this.renders.usePixel().id == 2 &&
                                <div className="col-span-3 grid grid-cols-3 gap-4">
                                    <div className="col-span-3">
                                        <DropdownTailwind
                                            label={"Select pixel"}
                                            searchInput={true}
                                            selected={this.renders.pixel()}
                                            options={this.state.targeting.pixels.options}
                                            loadingSearch={this.state.targeting.pixels.loading}
                                            onChange={async (value) => {
                                                this.state.updated.pixel = value;
                                                await this.promisedSetState({
                                                    updated: this.state.updated
                                                });
                                            }}
                                        />
                                    </div>
                                    <div className="col-span-3">
                                        <DropdownTailwind
                                            label={"Select optimization event"}
                                            searchInput={true}
                                            selected={this.renders.optimization_event()}
                                            options={this.state.optimization_event}
                                            onChange={async (value) => {
                                                this.state.updated.optimization_event = value;
                                                await this.promisedSetState({
                                                    updated: this.state.updated
                                                });
                                            }}
                                        />
                                    </div>
                                </div>
                                        */}
                        </>
                    }

                    {/*Placements*/}
                    {
                        this.state.tab.id == 3 &&
                        <>
                            <div className="col-span-6">
                                <DropdownTailwind
                                    label={"Automatic placements"}
                                    selected={this.renders.autoPlacements()}
                                    options={[{ id: 1, name: "Yes", value: true }, { id: 2, name: "No", value: false }]}
                                    locked={true}
                                />
                            </div>
                            {
                                /*
                                this.renders.autoPlacements().id === 2 &&
                                <div className="col-span-3">
                                    <div className="text-xs font-medium text-gray-700 mb-1">
                                        Placements
                                    </div>
                                    <div className="">
                                        {
                                            [
                                                { name: "TikTok", value: "tiktok", icon: FilmIcon },
                                                { name: "Pangle", value: "pangle", icon: DeviceMobileIcon }
                                            ].map((item) => {
                                                return (
                                                    <div onClick={async () => {
                                                        if (!this.state.updated.placements) {
                                                            this.state.updated.placements = {};
                                                        } else {
                                                            this.state.updated.placements[item.value] = !this.state.updated.placements[item.value];
                                                        }
                                                        await this.promisedSetState({
                                                            updated: this.state.updated
                                                        });
                                                    }} className={((this.state.updated.placements && this.state.updated.placements[item.value]) ? "bg-indigo-100 text-indigo-700" : "bg-gray-100 text-gray-500 hover:text-indigo-500") + " cursor-pointer flex flex-row rounded-full mb-2 items-center py-1.5 px-3 text-sm font-medium"}>
                                                        <item.icon className={"h-5 w-5 mr-2"} aria-hidden="true" />
                                                        <div className="flex flex-1">{item.name}</div>
                                                        <div className="ml-3">
                                                            <div className={((this.state.updated.placements && this.state.updated.placements[item.value]) ? "border-indigo-500 bg-indigo-500" : "border-gray-500 ") + " h-5 w-5 flex justify-center items-center rounded-full border-2"}>
                                                                <CheckIcon className={((this.state.updated.placements && this.state.updated.placements[item.value]) ? "" : "opacity-0") + " h-5 w-5 text-white"} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                                    */}
                            <div className="col-span-6">
                                <DropdownTailwind
                                    label={"User comment"}
                                    selected={this.renders.userComment()}
                                    options={[{ id: 1, name: "Yes", value: true }, { id: 2, name: "No", value: false }]}
                                    onChange={(value) => {
                                        this.state.updated.comments = value;
                                        this.setState({
                                            updated: this.state.updated
                                        })
                                    }}
                                />
                            </div>
                            <div className="col-span-6">
                                <DropdownTailwind
                                    label={"Video download"}
                                    selected={this.renders.videoDownload()}
                                    options={[{ id: 1, name: "Yes", value: true }, { id: 2, name: "No", value: false }]}
                                    locked={true}
                                />
                            </div>
                        </>
                    }

                    {/*Creative type*/}
                    {
                        this.state.tab.id === 4 &&
                        <>
                            <div className="col-span-6">
                                <DropdownTailwind
                                    label={"Automated creative optimization"}
                                    selected={this.renders.automatedCreative()}
                                    options={[{ id: 1, name: "Yes", value: true }, { id: 2, name: "No", value: false }]}
                                    locked={true}
                                />
                            </div>
                        </>
                    }

                    {/*Budget & Schedule*/}
                    {
                        this.state.tab.id == 6 &&
                        <>
                            {
                                !this.renders.cammpaignOptimization() &&
                                <div className="col-span-6 grid grid-cols-6 col-gap-2">
                                    <div className="col-span-2">
                                        <DropdownTailwind
                                            label={"Mode"}
                                            selected={this.renders.budgetMode()}
                                            options={[
                                                { id: 1, name: "Daily budget", value: "BUDGET_MODE_DAY" },
                                                { id: 2, name: "Lifetime budget", value: "BUDGET_MODE_TOTAL" }
                                            ]}
                                            locked={true}
                                        />
                                    </div>
                                    <div className="col-span-4">
                                        <InputTailwind
                                            label={"Budget"}
                                            error={(parseInt(this.renders.budget()) < 1 || isNaN(parseInt(this.renders.budget())))}
                                            leftSection={(this.state.client.currency && this.state.client.currency.tiktok ? true : false)}
                                            leftSectionLabel={(this.state.client.currency && this.state.client.currency.tiktok ? this.state.client.currency.tiktok : "")}
                                            value={this.renders.budget()}
                                            onChange={(value) => {
                                                this.state.updated.budget = value;
                                                this.setState({
                                                    updated: this.state.updated
                                                })
                                            }}
                                        />
                                    </div>
                                </div>
                            }
                            {
                                this.renders.cammpaignOptimization() &&
                                <div className="col-span-6">
                                    <div className="bg-blue-100 rounded-md inline-flex w-full p-4 text-sm font-medium text-blue-500">
                                        <InformationCircleIcon className="h-5 w-5 text-blue-500 mr-2" />

                                        {"Uses campaign budget " + (this.state.adgroup.campaign_budget && this.state.adgroup.campaign_budget.mode && this.state.adgroup.campaign_budget.mode === "BUDGET_MODE_DAY" ? " Daily: " : "Lifetime: ")} {this.state.adgroup.campaign_budget && this.state.adgroup.campaign_budget.value ? this.state.adgroup.campaign_budget.value : "1--"} {(this.state.client.currency && this.state.client.currency.tiktok ? this.state.client.currency.tiktok : "")}
                                    </div>
                                </div>
                            }

                            <div className="col-span-6">
                                <div className="grid grid-cols-6 gap-4">
                                    <div className="col-span-3 grid grid-cols-6 gap-4">
                                        <div className="col-span-3">
                                            <InputDatepickerTailwind
                                                error={adgroupTikTok.validate({ start_date: this.renders.startDate(), ...(moment().unix() > moment(this.state.adgroup.start).unix() && { status: this.renders.status() }) }).start_date}
                                                disabled={(moment().unix() > moment(this.state.adgroup.start).unix() ? true : false)}
                                                locked={(moment().unix() > moment(this.state.adgroup.start).unix() ? true : false)}
                                                placeholder={(moment().unix() > moment(this.renders.startDate()).unix() ? moment(this.renders.startDate()).format("MM/DD/YYYY") : "")}
                                                label={"Start date"}
                                                value={moment(this.renders.startDate()).format("YYYY-MM-DD")}
                                                onChange={(value) => {
                                                    let time = moment(this.renders.startDate()).format("HH:mm");
                                                    let new_date = value + " " + time;
                                                    this.state.updated.start_date = new_date;
                                                    this.setState({
                                                        updated: this.state.updated
                                                    })
                                                }}
                                            />
                                        </div>
                                        <div className="col-span-3">
                                            <InputTimepickerTailwind
                                                error={adgroupTikTok.validate({ start_date: this.renders.startDate(), ...(moment().unix() > moment(this.state.adgroup.start).unix() && { status: this.renders.status() }) }).start_date}
                                                disabled={(moment().unix() > moment(this.state.adgroup.start).unix() ? true : false)}
                                                locked={(moment().unix() > moment(this.state.adgroup.start).unix() ? true : false)}
                                                placeholder={(moment().unix() > moment(this.renders.startDate()).unix() ? moment(this.renders.startDate()).format("HH:mm") : "")}
                                                label={"Start time"}
                                                value={moment(this.renders.startDate()).format("HH:mm")}
                                                onChange={(value) => {
                                                    let date = moment(this.renders.startDate()).format("YYYY-MM-DD");
                                                    let new_date = date + " " + value;
                                                    this.state.updated.start_date = new_date;
                                                    this.setState({
                                                        updated: this.state.updated
                                                    })
                                                }}
                                            />
                                        </div>
                                    </div>

                                    <div className={"col-span-3 grid grid-cols-6 col-gap-4"}>
                                        <div className={(this.renders.endDate() ? "col-span-3" : "col-span-6")}>
                                            <InputDatepickerTailwind
                                                label={"End date"}
                                                disabled={!this.renders.endDate()}
                                                //labelRight={false}
                                                labelRight={(!this.renders.cammpaignOptimization() && this.renders.budgetMode().id === 1) ? (this.renders.endDate() ? "Disable" : "Enable") : false}
                                                placeholder={!this.renders.endDate() ? "No end date" : ""}
                                                value={moment(this.renders.endDate()).format("YYYY-MM-DD")}
                                                onChange={async (value) => {
                                                    let time = moment(this.renders.endDate()).format("HH:mm");
                                                    let new_date = value + " " + time;
                                                    this.state.updated.end_date = new_date;
                                                    this.setState({
                                                        updated: this.state.updated
                                                    })
                                                }}
                                                onLabelRightClick={async (value) => {
                                                    if (this.renders.endDate()) {
                                                        this.state.updated.end_date = null;
                                                    } else {
                                                        let new_date = moment(this.renders.startDate()).add(1, 'days').format("YYYY-MM-DD HH:mm");
                                                        this.state.updated.end_date = new_date;
                                                    }
                                                    this.setState({
                                                        updated: this.state.updated
                                                    })
                                                }}
                                            />
                                        </div>
                                        {
                                            this.renders.endDate() &&
                                            <div className="col-span-3">
                                                <InputTimepickerTailwind
                                                    label={"End time"}
                                                    placeholder={!this.renders.endDate() ? "No end time" : ""}
                                                    disabled={!this.renders.endDate()}
                                                    value={moment(this.renders.endDate()).format("HH:mm")}
                                                    onChange={async (value) => {
                                                        let date = moment(this.renders.endDate()).format("YYYY-MM-DD");
                                                        let new_date = date + " " + value;
                                                        this.state.updated.end_date = new_date;
                                                        this.setState({
                                                            updated: this.state.updated
                                                        })
                                                    }}
                                                />
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>

                            {
                                this.state.adgroup.items &&
                                this.state.adgroup.items.map((item, index) => {
                                    return (
                                        <div className="col-span-6 mt-2 grid grid-cols-12 gap-4">
                                            <div className="col-span-4">
                                                <DropdownTailwind
                                                    label={"Day"}
                                                    selected={item}
                                                    locked={true}
                                                    options={[]}
                                                    onChange={(value) => {

                                                    }}

                                                />
                                            </div>
                                            <div className="col-span-4">
                                                <DropdownTailwind
                                                    label={"Start time"}
                                                    locked={true}
                                                    selected={this.renders.startHour(index)}
                                                    options={[]}
                                                    onChange={(value) => {

                                                    }}
                                                />
                                            </div>
                                            <div className="col-span-4">
                                                <DropdownTailwind
                                                    label={"End time"}
                                                    locked={true}
                                                    selected={this.renders.endHour(index)}
                                                    options={[]}
                                                    onChange={(value) => {

                                                    }}
                                                />
                                            </div>
                                        </div>
                                    )
                                })
                            }

                        </>
                    }



                    {/*Bidding & Optimization*/}
                    {
                        this.state.tab.id == 7 &&
                        <>
                            <div className="col-span-6">
                                <DropdownTailwind
                                    label={"Optimization goal"}
                                    selected={this.renders.goal()}
                                    options={this.state.goals}
                                    locked={true}
                                />
                            </div>
                            {/*
                            (this.state.updated.goal && this.state.updated.goal.id && (this.state.updated.goal.id === 7 || this.state.updated.goal.id === 15)) &&
                            <div className="col-span-3">
                                <DropdownTailwind
                                    label={"Secondary optimization goal"}
                                    selected={this.renders.goal_secondary()}
                                    options={this.state.optimization_event_secondary}
                                    locked={false}
                                    onChange={(value) => {
                                        this.state.updated.goal_secondary = value;
                                        this.setState({
                                            updated: this.state.updated
                                        })
                                    }}
                                />
                            </div>
                                */}
                            <div className="col-span-6">
                                <DropdownTailwind
                                    label={"Billing event"}
                                    selected={this.renders.billing_event()}
                                    options={this.state.billing_event_options}
                                    locked={true}
                                />
                            </div>
                            <div className="col-span-6">
                                <DropdownTailwind
                                    label={"Bid strategy"}
                                    selected={this.renders.bidStrategy()}
                                    options={[
                                        { id: 1, name: "Lowest cost", value: "BID_TYPE_NO_BID" },
                                        { id: 2, name: "Bid cap", value: "BID_TYPE_CUSTOM" }
                                    ]}
                                    locked={true}
                                />
                            </div>
                            {
                                this.renders.bidStrategy().id === 2 &&
                                <div className="col-span-3">
                                    <InputTailwind
                                        label={"Bid cap"}
                                        locked={true}
                                        value={this.renders.bidCap()}
                                        type={'number'}
                                        onChange={async (value) => {
                                            /*
                                            if (value < 0) value = 0;
                                            this.state.updated.bid_cap = value;
                                            this.setState({
                                                updated: this.state.updated
                                            })
                                                */
                                        }}
                                    />
                                </div>
                            }
                            <div className="col-span-6">
                                <DropdownTailwind
                                    label={"Delivery type"}
                                    selected={this.renders.pacing()}
                                    options={[]}
                                    locked={true}
                                />
                            </div>
                            {/*
                                this.renders.bidStrategy().id === 2 &&
                                <div className="col-span-3">
                                    <InputTailwind
                                        label={"Bid cap"}
                                        leftSection={false}
                                        value={this.renders.bidCap()}
                                        onChange={(value) => {
                                            this.state.updated.bid_cap = value;
                                            this.setState({
                                                updated: this.state.updated
                                            })
                                        }}
                                    />
                                </div>
                                    */}
                            {/*
                                this.renders.goal().id === 10 &&
                                <div className="col-span-3">
                                    <div className="col-span-3">
                                        <DropdownTailwind
                                            label={"Content exclusions"}
                                            selected={this.renders.content_execution()}
                                            options={[{ id: 1, name: "Full", value: "FULL" }, { id: 2, name: "Limited", value: "LIMITED" }]}
                                            onChange={async (value) => {
                                                this.state.updated.content_execution = value;
                                                await this.promisedSetState({
                                                    updated: this.state.updated
                                                });
                                            }}
                                        />
                                    </div>
                                    <div className="grid grid-cols-6 gap-4">
                                        <div className="col-span-3">
                                            <InputTailwind
                                                label={"Frequency (impressions)"}
                                                leftSection={false}
                                                value={this.renders.frequency()}
                                                onChange={(value) => {
                                                    this.state.updated.frequency = value;
                                                    this.setState({
                                                        updated: this.state.updated
                                                    })
                                                }}
                                            />
                                        </div>
                                        <div className="col-span-3">
                                            <InputTailwind
                                                label={"Frequency schedule (days)"}
                                                leftSection={false}
                                                value={this.renders.frequency_schedule()}
                                                onChange={(value) => {
                                                    this.state.updated.frequency_schedule = value;
                                                    this.setState({
                                                        updated: this.state.updated
                                                    })
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                            */}
                        </>
                    }





                    {/*Targeting*/}
                    {
                        this.state.tab.id === 5 &&
                        <>
                            <div className="col-span-6">
                                <DropdownTailwind
                                    ref="searchDropdown"
                                    label={"Locations"}
                                    searchInput={true}
                                    selected={{ name: "Search ..." }}
                                    options={(Array.isArray(this.state.targeting.locations.options) && this.state.targeting.locations.search !== "") ? this.state.targeting.locations.options.filter((item) => {
                                        return this.renders.locations().filter((inner_item) => { return item.id === inner_item.id }).length < 1
                                    }) : []}
                                    loadingSearch={this.state.targeting.locations.loading}
                                    onChange={async (location) => {
                                        location.included = true;
                                        if (!Array.isArray(this.state.updated.locations)) {
                                            this.state.updated.locations = JSON.parse(JSON.stringify(this.renders.locations()));
                                        }
                                        this.state.updated.locations.unshift(location);
                                        await this.promisedSetState({
                                            updated: this.state.updated
                                        })
                                    }}
                                    onSearch={async (value) => {
                                        this.state.targeting.locations.search = value;
                                        await this.promisedSetState({
                                            targeting: this.state.targeting
                                        });
                                    }}
                                />
                            </div>
                            {
                                Array.isArray(this.renders.locations()) && this.renders.locations().length > 0 &&
                                <div className="col-span-6 flex flex-row flex-wrap">
                                    {selected_targeting("locations")}
                                </div>
                            }
                            <div className="col-span-6">
                                <DropdownTailwind
                                    ref="searchDropdown"
                                    label={"Languages"}
                                    searchInput={true}
                                    selected={{ name: "Search ..." }}
                                    options={Array.isArray(this.state.targeting.languages.options) ? this.state.targeting.languages.options.filter((item) => {
                                        return this.renders.languages().filter((inner_item) => { return item.id === inner_item.id }).length < 1
                                    }) : []}
                                    loadingSearch={this.state.targeting.languages.loading}
                                    onChange={async (language) => {
                                        language.included = true;
                                        if (!Array.isArray(this.state.updated.languages)) {
                                            this.state.updated.languages = JSON.parse(JSON.stringify(this.renders.languages()));
                                        }
                                        this.state.updated.languages.unshift(language);
                                        await this.promisedSetState({
                                            updated: this.state.updated
                                        })
                                        //this.functions.audienceSize();
                                    }}
                                    onSearch={async (value) => {
                                        this.state.targeting.languages.search = value;
                                        await this.promisedSetState({
                                            targeting: this.state.targeting
                                        });
                                    }}
                                />
                            </div>
                            {
                                Array.isArray(this.renders.languages()) && this.renders.languages().length > 0 &&
                                <div className="col-span-6 flex flex-row flex-wrap">
                                    {selected_targeting("languages")}
                                </div>
                            }
                            <div className="col-span-6">
                                <DropdownTailwind
                                    label={"Select targeting mode"}
                                    selected={this.renders.targetingMode()}
                                    options={[{ id: 1, name: "Automatic targeting", value: "auto" }, { id: 2, name: "Custom targeting", value: "custom" }]}
                                    onChange={async (value) => {
                                        this.state.updated.targeting_mode = value;
                                        await this.promisedSetState({
                                            updated: this.state.updated
                                        });
                                    }}
                                />
                            </div>
                            {
                                this.renders.targetingMode().id === 2 &&
                                <div className="col-span-6 text-lg font-semibold">
                                    Demographic
                                </div>
                            }
                            {
                                this.renders.targetingMode().id === 2 &&
                                <div className="col-span-6">
                                    <div className="text-xs font-medium text-gray-700 mb-1">
                                        Gender
                                    </div>
                                    <div className="flex flex-row flex-wrap">
                                        {
                                            this.state.genders.map((item) => {
                                                return (
                                                    <div onClick={() => {
                                                        item.selected = true;
                                                        this.state.updated.gender = item;
                                                        this.state.genders = this.state.genders.map((inner_item) => {
                                                            if (inner_item.id !== item.id) {
                                                                inner_item.selected = false;
                                                            } else {
                                                                inner_item.selected = true;
                                                            }
                                                            return inner_item;
                                                        })
                                                        this.setState({
                                                            updated: this.state.updated,
                                                            genders: this.state.genders
                                                        })

                                                    }} className={(item.selected ? "bg-indigo-100 text-indigo-700" : "bg-gray-100 text-gray-500 hover:text-indigo-500") + " cursor-pointer inline-flex flex-row rounded-full mb-2 mr-2 items-center py-1.5 px-3 text-sm font-medium"}>
                                                        <div className="">{item.name}</div>
                                                        <div className="ml-3">
                                                            <div className={(item.selected ? "border-indigo-500 bg-indigo-500" : "border-gray-500 ") + " h-5 w-5 flex justify-center items-center rounded-full border-2"}>
                                                                <CheckIcon className={(item.selected ? "" : "opacity-0") + " h-5 w-5 text-white"} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            }
                            {
                                this.renders.targetingMode().id === 2 &&
                                <div className="col-span-6">
                                    <div className="text-xs font-medium text-gray-700 mb-1">
                                        Ages
                                    </div>
                                    <div className="flex flex-row flex-wrap">
                                        {
                                            this.state.ages.map((item) => {
                                                return (
                                                    <div onClick={() => {
                                                        let buff = !item.selected;
                                                        if (item.id === 1) {
                                                            this.state.updated.ages = [item];
                                                            this.state.ages = this.state.ages.map((inner_item) => {
                                                                if (inner_item.id !== item.id) {
                                                                    inner_item.selected = false;
                                                                } else {
                                                                    inner_item.selected = true;
                                                                }
                                                                return inner_item;
                                                            })
                                                        } else {
                                                            if (!Array.isArray(this.state.updated.ages)) {
                                                                this.state.updated.ages = JSON.parse(JSON.stringify(this.renders.ages()));
                                                            }

                                                            this.state.ages = this.state.ages.map((inner_item) => {
                                                                if (inner_item.id === 1) {
                                                                    inner_item.selected = false;
                                                                } else if (inner_item.id === item.id) {
                                                                    if (this.state.ages.filter((inner_item) => { return inner_item.selected; }).length === 1 && !buff) {
                                                                    } else {
                                                                        inner_item.selected = buff;
                                                                    }
                                                                    inner_item.selected = item.selected;
                                                                }
                                                                return inner_item;
                                                            })
                                                            this.state.updated.ages = this.state.ages.filter((inner_item) => {
                                                                return inner_item.id !== 1;
                                                            })
                                                            //this.state.updated.ages.push(item);
                                                        }
                                                        this.setState({
                                                            updated: this.state.updated,
                                                            ages: this.state.ages
                                                        })

                                                    }} className={(item.selected ? "bg-indigo-100 text-indigo-700" : "bg-gray-100 text-gray-500 hover:text-indigo-500") + " cursor-pointer inline-flex flex-row rounded-full mb-2 mr-2 items-center py-1.5 px-3 text-sm font-medium"}>
                                                        <div className="">{item.name}</div>
                                                        <div className="ml-3">
                                                            <div className={(item.selected ? "border-indigo-500 bg-indigo-500" : "border-gray-500 ") + " h-5 w-5 flex justify-center items-center rounded-full border-2"}>
                                                                <CheckIcon className={(item.selected ? "" : "opacity-0") + " h-5 w-5 text-white"} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            }

                            <div className="col-span-6 text-lg font-semibold">
                                Interests & Behaviors
                            </div>
                            <div className="col-span-6">
                                <DropdownTailwind
                                    label={"Type"}
                                    selected={this.state.audience_type}
                                    options={[{ id: 1, name: "Interests", value: "categories" }, { id: 2, name: "Additional interests", value: "interests" }, { id: 3, name: "Video interactions", value: "behaviors_video" }, { id: 4, name: "Creator interactions", value: "behaviors_creator" }, { id: 5, name: "Hashtags", value: "hashtags" }]}
                                    onChange={async (value) => {
                                        await this.promisedSetState({
                                            audience_type: value
                                        })
                                    }}
                                />
                            </div>
                            {
                                this.state.audience_type.id !== 0 &&
                                <div className="col-span-6">
                                    <div className='flex'>
                                        <div className='mx-4 self-center'>
                                            <ReplyIcon className='h-6 w-6' style={{ transform: 'rotate(180deg)' }} />
                                        </div>
                                        <div className='flex-col flex-1'>
                                            <DropdownTailwind
                                                ref="searchDropdown"
                                                label={"Search: " + this.state.audience_type.name}
                                                searchInput={true}
                                                loader={this.state.loading_targeting}
                                                selected={{ name: "Search ..." }}
                                                options={this.renders.audience_options(this.state.audience_type.value)}
                                                loadingSearch={this.state.loading_targeting}
                                                onChange={(value) => {
                                                    let buffer = JSON.parse(JSON.stringify(value));
                                                    buffer.included = true;
                                                    buffer.name = buffer.name + " [" + this.state.audience_type.name + "] ";
                                                    if (!Array.isArray(this.state.updated[this.state.audience_type.value])) {
                                                        this.state.updated[this.state.audience_type.value] = JSON.parse(JSON.stringify(this.renders.audience_type(this.state.audience_type.value)));
                                                    }
                                                    this.state.updated[this.state.audience_type.value].push(buffer);
                                                    this.setState({
                                                        updated: this.state.updated
                                                    })
                                                }}
                                                onSearch={async (value) => {
                                                    if (this.state.audience_type.id === 2) {
                                                        this.functions.searchInterests(value);
                                                    } else if (this.state.audience_type.id === 5) {
                                                        this.functions.searchHashtags(value);
                                                    }
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            }

                            {

                                <div className="col-span-6 flex flex-row flex-wrap">
                                    {
                                        ["categories", "interests", "behaviors_video", "behaviors_creator", "hashtags"].map((item) => {
                                            if (this.renders.targetingMode().id === 2 && Array.isArray(this.renders[item]()) && this.renders[item]().length > 0) {
                                                return selected_targeting(item)
                                            }
                                        })
                                    }
                                </div>


                            }

                            {/*
                                this.renders.targetingMode().id === 2 && this.renders.categories().length > 0 &&
                                <div className="col-span-6 text-sm font-semibold">
                                    Interests
                                </div>
                            }


                            {
                                this.renders.targetingMode().id === 2 && this.renders.interests().length > 0 &&
                                <div className="col-span-6 text-sm font-semibold">
                                    Additional interests
                                </div>
                            }

                            {
                                this.renders.targetingMode().id === 2 &&
                                Array.isArray(this.renders.interests()) && this.renders.interests().length > 0 &&
                                selected_targeting("interests")
                            }
                            {
                                this.renders.targetingMode().id === 2 && this.renders.behaviors_video().length > 0 &&
                                <div className="col-span-6 text-sm font-semibold">
                                    Video behavior
                                </div>
                            }

                            {
                                this.renders.targetingMode().id === 2 &&
                                Array.isArray(this.renders.behaviors_video()) && this.renders.behaviors_video().length > 0 &&
                                selected_targeting("behaviors_video", "what kind of videos they've interacted with")
                            }

                            {
                                this.renders.targetingMode().id === 2 && this.renders.behaviors_creator().length > 0 &&
                                <div className="col-span-6 text-sm font-semibold">
                                    Creator behavior
                                </div>
                            }

                            {
                                this.renders.targetingMode().id === 2 &&
                                Array.isArray(this.renders.behaviors_creator()) && this.renders.behaviors_creator().length > 0 &&
                                selected_targeting("behaviors_creator", "what kind of creators they've interacted with")
                            }

                            {
                                this.renders.targetingMode().id === 2 && this.renders.hashtags().length > 0 &&
                                <div className="col-span-3 text-sm font-semibold">
                                    Hashtag
                                </div>
                            }

                            {
                                this.renders.targetingMode().id === 2 &&
                                Array.isArray(this.renders.hashtags()) && this.renders.hashtags().length > 0 &&
                                selected_targeting("hashtags")
                            */}
                        </>
                    }



                    {
                        this.state.error &&
                        <div className='text-red-500 text-sm p-4 col-span-6 bg-red-100 rounded-md'>{this.state.error}</div>
                    }

                </div>
            </>
        )
    }
}

export default EditLiveTikTokAdgroup;
