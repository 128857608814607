class campaignFacebook {
    static validate(campaign) {
        let error = false;
        if (campaign.budget_optimization && campaign.budget_optimization.value) {
            if (campaign.budget_optimization_type.value === "total" && (campaign.lifetime_budget === "" || parseInt(campaign.lifetime_budget) === 0 || isNaN(parseInt(campaign.lifetime_budget)))) {
                error = true;
            } else if (campaign.budget_optimization_type.value === "daily" && (campaign.daily_budget === "" || parseInt(campaign.daily_budget) === 0 || isNaN(parseInt(campaign.daily_budget)))) {
                error = true;
            }
        }
        if (campaign.ios && campaign.ios.value) {
            if (campaign.application && campaign.application.id !== 0) {
            } else {
                error = true;
            }
        }
        return error;
    }
}

export { campaignFacebook }