import moment from 'moment';
import React, { Component, Fragment } from 'react';
import { UserIcon, LockClosedIcon, CheckIcon, ArchiveIcon, ClipboardListIcon, ColorSwatchIcon, DocumentSearchIcon, BadgeCheckIcon, GlobeAltIcon, ChevronDownIcon, ArrowNarrowRightIcon, SaveAsIcon, ChevronUpIcon, IdentificationIcon, CurrencyDollarIcon, InformationCircleIcon, CubeIcon, UsersIcon } from '@heroicons/react/outline'
import { apiRegister } from '../services/apiRegister';
import { tokenRegister } from '../services/tokenRegister';
import DropdownTailwind from './dropdownTailwind';
import InputDatepickerTailwind from './inputDatepickerTailwind';
import InputTailwind from './inputTailwind';
import WarningModalTailwind from './warningModalTailwind';
import SuccessModal from './successModal';
import TextAreaTailwind from './textAreaTailwind';
import SlideDown from 'react-slidedown';
import { isNumber, round } from '@turf/turf';
import InputTimepickerTailwind from './inputTimepickerTailwind';
import SwitchTailwind from './switchTailwind';
import { userRegister } from '../services/userRegister';
import { ChevronDoubleDownIcon, ChevronDoubleUpIcon } from '@heroicons/react/solid';
import { text } from 'body-parser';
import TagManager from './tagManager';

class CreateOrder extends Component {

    constructor(props) {
        super(props);
        this.state = {
            name: "",
            note: "",
            clients: [],
            client: { id: 0, name: "Click to search ..." },
            loading_clients: false,
            startDate: moment(new Date()).format("YYYY-MM-DD"),
            endDate: null,
            client_search: '',
            client_limit: 10,
            client_page: 1,
            clients_total: 0,
            selected_client: {},
            throttling: {},
            agents: [],
            agent_options: [],
            loading_agents_search: "",
            loading_agents_pagination: 0,
            loading_agents: false,
            open: {
                clients: true
            },
            total_budget: 0,
            google_budget: 0,
            bing_budget: 0,
            crm_option: false,
            channel_budget: {
                facebook: false,
                google: false,
                bing: false,
                linkedin: false,
                snapchat: false,
                tiktok: false,
                twitter: false
            },
            automated_budget: false,
            tags: [],
            order: {},
        };
    }

    componentDidMount() {
        this.functions.getUser();
        this.functions.getClients(true)
    }

    functions = {
        getUser: async () => {
            await this.promisedSetState({
                user: userRegister.get() ? userRegister.get() : {}
            });
            this.state.agents.push({
                email: this.state.user.email,
                id: this.state.user.id
            });
        },
        getClients: async (init, paginaton, search, search_value) => {
            await this.promisedSetState({
                loading_clients_pagination: paginaton,
                loading_clients: init
            });
            try {
                let response = await this.calls.getClients();
                if (!search_value || (search_value && search_value == this.state.client_search)) {
                    if (!paginaton) {
                        await this.promisedSetState({
                            clients: response.data.map((item) => { item.image = item.logo; return item }),
                            clients_total: response.meta.total
                        });
                    } else {
                        this.state.clients = this.state.clients.concat(response.data.map((item) => { item.image = item.logo; return item }));
                        await this.promisedSetState({
                            clients: this.state.clients,
                            clients_total: response.meta.total
                        });
                    }
                }
            } catch (error) {
                console.log(error)
            }
            await this.promisedSetState({
                loading_clients_search: false,
                loading_clients_pagination: false,
                loading_clients: false
            });
        },
        createOrder: async () => {
            this.setState({
                open: {}
            })
            let order = {
                name: this.state.name,
                crmId: this.state.crmId,
                crmRef: this.state.crmRef,
                maxBudget: this.state.total_budget,
                ...(this.state.selected_client.id && { client: this.state.selected_client.id }),
                ...(this.state.startDate && { startDate: this.state.startDate }),
                endDate: this.state.endDate,
                documents: this.state.documents,
                agents: this.state.agents,
                budgetChannels: this.state.channel_budget,
                ...(this.state.automated_budget && { maxBudgetEnabled: true }),
                ...(this.state.tags.length > 0 && { tags: this.state.tags }),
                //public: this.state.public,
                //client_status: { id: 3, name: "Pending", value: "pending", step: 1 },
                //...(this.state.budget_split && { budget_split: { value: this.state.budget_split, budget: this.state.total_budget, bing_budget: this.state.bing_budget, google_budget: this.state.google_budget } }),
            };

            if (Object.keys(this.state.order).length > 0) {
                Object.keys(this.state.order).map((item) => {
                    order[item] = this.state.order[item];
                })
            }
            try {
                let response = await this.calls.createOrder(order);
                this.props.onCreated(response);
            } catch (error) {
                this.props.onError();
                await this.promisedSetState({ error: error.body ? error.body.message : "Something went wrong" });
            }
        },
        onSelectFile: async (e) => {
            let files = e.target.files;
            if (files) {
                for (let i in files) {
                    if (files[i] && files[i].name && files[i].size) {
                        this.functions.uploadDocument(files[i]);
                    }
                }
            }
            const randomString = Math.random().toString(36);
            this.promisedSetState({
                theInputKeyOne: randomString + 1
            });
        },
        uploadDocument: async (file) => {
            let files_object = {
                id: Math.floor((Math.random() * 9999999999) + 1),
                loading: true,
                name: file.name
            };
            this.state.documents.push(files_object);
            await this.promisedSetState({ documents: this.state.documents });
            try {
                const formData = new FormData();
                formData.append('file', file);
                let response = await this.calls.uploadDocument(formData);
                this.state.documents = this.state.documents.map((item) => {
                    if (item.id === files_object.id) {
                        item.loading = false;
                        item = { ...item, ...response.data };
                    }
                    return item;
                });
                await this.promisedSetState({ documents: this.state.documents });
            } catch (error) {
                this.state.documents = this.state.documents.map((item) => {
                    if (item.id === files_object.id) {
                        item.loading = false;
                    }
                    return item;
                });
                await this.promisedSetState({ documents: this.state.documents });
            }
        },
        getAgents: async (init, paginaton, search) => {
            await this.promisedSetState({
                loading_agents_search: search,
                loading_agents_pagination: paginaton,
                loading_agents: init
            });
            try {
                let response = await this.calls.getAgents();
                if (!paginaton) {
                    await this.promisedSetState({
                        agent_options: response.data.map((item) => { item.name = item.email; return item }),
                        total: response.meta.total
                    });
                } else {
                    this.state.agent_options = this.state.agent_options.concat(response.data.map((item) => { item.name = item.email; return item }));
                    await this.promisedSetState({
                        agent_options: this.state.agent_options,
                        total: response.meta.total
                    });
                }
            } catch (error) { }
            await this.promisedSetState({
                loading_agents_search: false,
                loading_agents_pagination: false,
                loading_agents: false
            });
        },

    };

    showVersionButton = () => {
        let show = false;
        try {
            if (window.location.search.indexOf("show_planer") !== -1) {
                show = true;
            } else if (this.state.user && (this.state.user.agency == "6231a88af13cda6ff4cf0153" || this.state.user.agency == "662115a52584db000756d7e0")) {
                show = true;
            }
        } catch (error) { }
        return show;
    };

    renders = {
        budgetLeft: () => {
            if (this.state.total_budget > 0 && Object.keys(this.state.channel_budget).filter((item) => { return this.state.channel_budget[item] }).length > 0) {
                let left = this.state.total_budget;
                Object.keys(this.state.channel_budget).filter((item) => { return this.state.channel_budget[item] }).map((item) => {
                    if (this.state.channel_budget[item].value) {
                        left -= +this.state.channel_budget[item].value;
                    }
                });
                if (left === 0) {
                    return {
                        text: "Left ",
                        value: 0,
                        percentage: false
                    };
                } else {
                    return {
                        text: left > 0 ? "Budget left: " : "Over budget: ",
                        value: Math.abs(left),
                        percentage: round((Math.abs(left) / this.state.total_budget) * 100, 2)
                    }
                    //return (left > 0 ? "Budget left: " : "Over budget: ") + Math.abs(left) + " (" + round((Math.abs(left) / this.state.total_budget) * 100, 2) + "%)";
                }

                //return left > 0 ? "(Left: " + left + ", " + round((left / this.state.total_budget) * 100, 2) + "%)" : "Over budget";
            } else {
                return {
                    text: "",
                    value: 0,
                    percentage: false
                };
            }
        },
    };
    calls = {
        getClients: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/listClients?limit=" + this.state.client_limit + "&page=" + this.state.client_page + "&sortBy=" + "name" + "&orderBy=" + "ascending" + (this.state.client_search !== "" ? ("&search=" + this.state.client_search) : "");
            return apiRegister.call(options, url);
        },
        uploadDocument: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data, true);
            let url = apiRegister.url.api + "/v3/adcredo/uploadFile?client=" + this.state.client.id;
            return apiRegister.call(options, url);
        },
        createOrder: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data, false);
            let url = apiRegister.url.api + "/v3/adcredo/createOrder";
            return apiRegister.call(options, url);
        },
        getAgents: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/listAgencyAgents?limit=" + this.state.limit + "&page=" + this.state.page + "&sortBy=" + this.state.sort + "&orderBy=" + this.state.direction + (this.state.search !== "" ? ("&search=" + this.state.search) : "");
            return apiRegister.call(options, url);
        }
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {
        return (
            <>
                <div className="flex flex-col">


                    {/* SELECT CLIENT */}
                    {
                        <>
                            <div className={"flex justify-between"}>
                                <div className='flex'>
                                    <div className="h-10 w-10 text-purple-500 border-purple-100 bg-purple-100 border-1.5 flex justify-center items-center rounded-md">
                                        <IdentificationIcon className="w-5 h-5" />
                                    </div>
                                    <div className="flex flex-col ml-2">
                                        <div className="font-semibold text-sm">Select client</div>
                                        {
                                            this.state.selected_client && this.state.selected_client.id &&
                                            <div className="font-medium text-xs text-gray-500 flex flex-row">You have selected <div className="text-purple-500 ml-1">{this.state.selected_client.name}</div></div>
                                        }
                                        {
                                            !(this.state.selected_client && this.state.selected_client.id) &&
                                            <div className="font-medium text-xs text-gray-500">No client selected</div>
                                        }
                                    </div>
                                </div>
                                <div className='flex'>
                                    {
                                        this.state.selected_client && this.state.selected_client.id &&
                                        <div className="h-10 w-10 text-green-500 mr-2 border-green-500 bg-green-100 border-1.5 flex justify-center items-center rounded-md">
                                            <CheckIcon className="w-5 h-5 " />
                                        </div>
                                    }
                                    <div className={"bg-gray-100 cursor-pointer h-10 w-10 border-1.5 flex justify-center items-center rounded-md"}
                                        onClick={() => {
                                            //if (!this.props.report) {
                                            this.state.open.clients = !this.state.open.clients;
                                            this.setState({ open: this.state.open })
                                            //}
                                        }}
                                    >
                                        {
                                            //!this.props.report &&
                                            !this.state.open.clients &&
                                            <ChevronDownIcon className="w-5 h-5 " />
                                        }
                                        {
                                            //!this.props.report &&
                                            this.state.open.clients &&
                                            <ChevronUpIcon className="w-5 h-5 " />
                                        }
                                        {
                                            false &&
                                            this.props.report &&
                                            <LockClosedIcon className="w-5 h-5 text-gray-500" />
                                        }
                                    </div>
                                </div>
                            </div>
                            <SlideDown
                                closed={!this.state.open.clients}
                            >
                                <div className='mb-4'>
                                    <div className='mt-4'>
                                        <DropdownTailwind
                                            small={false}
                                            skipInternalSearch={true}
                                            loader={this.state.loading_clients}
                                            loadingPagination={this.state.loading_clients_pagination}
                                            loadingSearch={this.state.loading_clients_search}
                                            total={this.state.clients_total}
                                            searchInput={true}
                                            placeholder={"Search ..."}
                                            pagination={this.state.clients_total > (this.state.client_page * this.state.client_limit)}
                                            selected={this.state.selected_client.id !== 1 ? this.state.selected_client : { id: 1, name: "Select client" }}
                                            options={this.state.clients.length > 0 ? this.state.clients : []}
                                            onChange={async (value) => {
                                                this.state.open.clients = false;
                                                this.state.open.information = true;
                                                await this.promisedSetState({
                                                    selected_client: value,
                                                    open: this.state.open
                                                });
                                            }}
                                            onPagination={async () => {
                                                if (!this.state.loading_clients_pagination) {
                                                    await this.promisedSetState({
                                                        client_page: this.state.client_page + 1
                                                    });
                                                    this.functions.getClients(false, true, false);
                                                }
                                            }}
                                            onSearch={async (value) => {
                                                await this.promisedSetState({
                                                    loading_clients_search: true,
                                                    client_search: value
                                                });
                                                setTimeout(async () => {
                                                    if (value === this.state.client_search) {
                                                        await this.promisedSetState({
                                                            client_page: 1
                                                        });
                                                        this.functions.getClients(false, false, true, value);
                                                    }
                                                }, 400);
                                            }}
                                        />
                                    </div>
                                </div>

                                {/* NEXT */}
                                {
                                    <div className='flex justify-center'>
                                        <div
                                            onClick={() => {
                                                if (this.state.selected_client &&
                                                    this.state.selected_client.id &&
                                                    this.state.selected_client.id !== 1 &&
                                                    this.state.selected_client.id !== 0) {
                                                    this.state.open.clients = false;
                                                    this.state.open.information = true;
                                                    this.setState({ open: this.state.open })
                                                }
                                            }}
                                            className={`flex w-32 h-10 overflow-hidden rounded-md justify-center text-sm font-medium py-2 border-1.5 ${this.state.selected_client.id ? "bg-purple-500 text-white cursor-pointer" : "bg-gray-100 text-gray-300 cursor-not-allowed"}`}>
                                            Next step
                                        </div>
                                    </div>
                                }

                            </SlideDown>
                        </>
                    }

                    {/* FULL BORDER */}
                    {
                        <div className="border-b w-full mt-4 mb-4"></div>
                    }

                    {/* SET INFROMATION */}
                    {
                        <>
                            <div className={"flex justify-between"}>
                                <div className='flex'>
                                    <div className="h-10 w-10 text-purple-500 border-purple-100 bg-purple-100 border-1.5 flex justify-center items-center rounded-md">
                                        <CubeIcon className="w-5 h-5" />
                                    </div>
                                    <div className="flex flex-col ml-2">
                                        <div className="font-semibold text-sm">Order informaton</div>
                                        <div className="font-medium text-xs text-gray-500 flex flex-row">Fill in data below</div>
                                    </div>
                                </div>
                                <div className='flex'>
                                    {
                                        this.state.name !== "" &&
                                        <div className="h-10 w-10 text-green-500 mr-2 border-green-500 bg-green-100 border-1.5 flex justify-center items-center rounded-md">
                                            <CheckIcon className="w-5 h-5 " />
                                        </div>
                                    }
                                    {
                                        this.state.selected_client &&
                                        this.state.selected_client.id &&
                                        this.state.selected_client.id !== 1 &&
                                        this.state.selected_client.id !== 0 &&
                                        <div className={"bg-gray-100 cursor-pointer h-10 w-10 border-1.5 flex justify-center items-center rounded-md"}
                                            onClick={() => {
                                                this.state.open.information = !this.state.open.information;
                                                this.setState({ open: this.state.open })
                                            }}
                                        >
                                            {
                                                !this.state.open.information &&
                                                <ChevronDownIcon className="w-5 h-5 " />
                                            }
                                            {
                                                this.state.open.information &&
                                                <ChevronUpIcon className="w-5 h-5 " />
                                            }
                                        </div>
                                    }
                                </div>
                            </div>
                            <SlideDown
                                closed={!this.state.open.information}
                            >
                                <div className='mb-4'>
                                    <div className='mt-4'>
                                        <div className="grid-cols-12 gap-4 grid">
                                            <div className={"col-span-12"}>
                                                <InputTailwind
                                                    label={"Name"}
                                                    value={this.state.name}
                                                    onChange={(value) => {
                                                        this.promisedSetState({
                                                            name: value
                                                        })
                                                    }}
                                                />
                                            </div>
                                            <div className={"col-span-12 md:col-span-6 grid grid-cols-6 col-gap-2"}>
                                                <div className="col-span-3">
                                                    <InputDatepickerTailwind
                                                        label={"Start date"}
                                                        minDate={moment(new Date()).format("YYYY-MM-DD")}
                                                        value={moment(this.state.startDate).format("YYYY-MM-DD")}
                                                        onChange={async (value) => {
                                                            let time = moment(this.state.startDate).format("HH:mm");
                                                            let new_date = value + " " + time;
                                                            await this.promisedSetState({
                                                                startDate: moment(new_date)
                                                            })
                                                            if (this.state.endDate && new Date(this.state.endDate).getTime() < new Date(new_date).getTime()) {
                                                                await this.promisedSetState({
                                                                    endDate: moment(new_date).add(1, 'days')
                                                                })
                                                            }
                                                        }}
                                                    />
                                                </div>
                                                <div className="col-span-3">
                                                    <InputTimepickerTailwind
                                                        label={"Start time"}
                                                        value={moment(this.state.startDate).format("HH:mm")}
                                                        onChange={async (value) => {
                                                            let date = moment(this.state.startDate).format("YYYY-MM-DD");
                                                            let new_date = date + " " + value;
                                                            await this.promisedSetState({
                                                                startDate: moment(new_date)
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className={"col-span-12 md:col-span-6 grid grid-cols-6 col-gap-2"}>
                                                <div className={(this.state.endDate ? "col-span-3" : "col-span-6")}>
                                                    <InputDatepickerTailwind
                                                        label={"End date"}
                                                        disabled={!this.state.endDate}
                                                        minDate={this.state.startDate ? moment(this.state.startDate).format("YYYY-MM-DD") : ""}
                                                        labelRight={this.state.endDate ? "Disable" : "Enable"}
                                                        placeholder={!this.state.endDate ? "No end date" : ""}
                                                        value={moment(this.state.endDate).format("YYYY-MM-DD")}
                                                        onChange={async (value) => {
                                                            let time = moment(this.state.endDate).format("HH:mm");
                                                            let new_date = value + " " + time;
                                                            await this.promisedSetState({
                                                                endDate: moment(new_date)
                                                            })
                                                        }}
                                                        onLabelRightClick={async (value) => {
                                                            if (this.state.endDate) {
                                                                this.state.endDate = null;
                                                            } else {
                                                                let new_date = moment(this.state.startDate).add(1, 'days');
                                                                this.state.endDate = new_date;
                                                            }
                                                            await this.promisedSetState({
                                                                endDate: this.state.endDate
                                                            });
                                                        }}
                                                    />
                                                </div>
                                                {
                                                    this.state.endDate &&
                                                    <div className="col-span-3">
                                                        <InputTimepickerTailwind
                                                            label={"End time"}
                                                            placeholder={!this.state.endDate ? "No end time" : ""}
                                                            disabled={!this.state.endDate}
                                                            value={moment(this.state.endDate).format("HH:mm")}
                                                            onChange={async (value) => {
                                                                let date = moment(this.state.endDate).format("YYYY-MM-DD");
                                                                let new_date = date + " " + value;
                                                                await this.promisedSetState({
                                                                    endDate: moment(new_date)
                                                                })
                                                            }}
                                                        />
                                                    </div>
                                                }
                                            </div>
                                            <div className={"col-span-12"}>
                                                <TagManager
                                                    report={{}}
                                                    label={"Add tags"}
                                                    tags={this.state.tags ? this.state.tags : []}
                                                    tagType={"component"}
                                                    onUpdateTags={(tags) => {
                                                        this.setState({
                                                            tags: tags
                                                        });
                                                    }}
                                                />
                                            </div>
                                            <div className={"col-span-12"}>
                                                <div className="mb-1 text-xs font-medium text-gray-700">
                                                    Upload documents
                                                </div>
                                                <div key={this.state.theInputKeyOne || ''} className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md relative bg-custom-input">
                                                    {
                                                        !this.state.success &&
                                                        <input
                                                            onChange={(e) => this.functions.onSelectFile(e)}
                                                            type="file"
                                                            multiple
                                                            className="absolute top-0 left-0 w-full h-full cursor-pointer opacity-0 z-50"
                                                        />
                                                    }
                                                    <div className="space-y-1 text-center">
                                                        <svg
                                                            className="mx-auto h-12 w-12 text-gray-400"
                                                            stroke="currentColor"
                                                            fill="none"
                                                            viewBox="0 0 48 48"
                                                            aria-hidden="true"
                                                        >
                                                            <path
                                                                d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                                                strokeWidth={2}
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                            />
                                                        </svg>
                                                        <div className="flex text-sm text-gray-600">
                                                            <span className="relative cursor-pointer whitespace-no-wrap bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">Upload a document</span>
                                                            <p className="pl-1">or drag and drop</p>
                                                        </div>
                                                        <p className="text-xs text-gray-500">PDF, TXT, ODT, DOC, PPT up to 100MB</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={"col-span-6"}>
                                                <InputTailwind
                                                    label={"Crm ID"}
                                                    value={this.state.crmId}
                                                    onChange={(value) => {
                                                        this.promisedSetState({
                                                            crmId: value
                                                        })
                                                    }}
                                                />
                                            </div>
                                            <div className={"col-span-6"}>
                                                <InputTailwind
                                                    label={"Crm Ref"}
                                                    value={this.state.crmRef}
                                                    onChange={(value) => {
                                                        this.promisedSetState({
                                                            crmRef: value
                                                        })
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* NEXT */}
                                {
                                    <div className='flex justify-center'>
                                        <div
                                            onClick={() => {
                                                if (this.state.name !== "") {
                                                    this.state.open.information = false;
                                                    this.state.open.budget = true;
                                                    this.setState({ open: this.state.open })
                                                }
                                            }}
                                            className={`flex w-32 h-10 overflow-hidden rounded-md justify-center text-sm font-medium py-2 border-1.5 ${this.state.name !== "" ? "bg-purple-500 text-white cursor-pointer" : "bg-gray-100 text-gray-300 cursor-not-allowed"}`}>
                                            Next step
                                        </div>
                                    </div>
                                }

                            </SlideDown>
                        </>
                    }

                    {
                        <div className="border-b w-full mt-4 mb-4"></div>
                    }

                    {/* BUDGET */}
                    {
                        <>
                            <div className={"flex justify-between"}>
                                <div className='flex'>
                                    <div className="h-10 w-10 text-purple-500 border-purple-100 bg-purple-100 border-1.5 flex justify-center items-center rounded-md">
                                        <CurrencyDollarIcon className="w-5 h-5" />
                                    </div>
                                    <div className="flex flex-col ml-2">
                                        <div className="font-semibold text-sm">Budget management (optional)</div>
                                        <div className="font-medium text-xs text-gray-500 flex flex-row">Fill in data below</div>
                                    </div>
                                </div>
                                <div className='flex'>
                                    {
                                        this.state.name !== "" &&
                                        <div className="h-10 w-10 text-green-500 mr-2 border-green-500 bg-green-100 border-1.5 flex justify-center items-center rounded-md">
                                            <CheckIcon className="w-5 h-5 " />
                                        </div>
                                    }
                                    {
                                        this.state.name !== "" &&
                                        <div className={"bg-gray-100 cursor-pointer h-10 w-10 border-1.5 flex justify-center items-center rounded-md"}
                                            onClick={() => {
                                                this.state.open.budget = !this.state.open.budget;
                                                this.setState({ open: this.state.open })
                                            }}
                                        >
                                            {
                                                !this.state.open.budget &&
                                                <ChevronDownIcon className="w-5 h-5 " />
                                            }
                                            {
                                                this.state.open.budget &&
                                                <ChevronUpIcon className="w-5 h-5 " />
                                            }
                                        </div>
                                    }
                                </div>
                            </div>
                            <SlideDown
                                closed={!this.state.open.budget}
                            >

                                <div className="grid grid-cols-12 gap-4 mt-4">
                                    <div className={this.showVersionButton() ? "col-span-8" : "col-span-12"}>
                                        <div className="grid gap-4 grid-cols-12">
                                            <div className="col-span-6">
                                                <InputTailwind
                                                    label={"Budget target"}
                                                    type={"number"}
                                                    value={this.state.order.maxBudget}
                                                    onChange={async (value) => {
                                                        this.state.order.maxBudget = value;
                                                        await this.promisedSetState({
                                                            order: this.state.order
                                                        });
                                                    }}
                                                />
                                            </div>
                                            <div className='col-span-6 relative'>
                                                <div className='-mb-4 text-xs font-medium text-gray-700 flex flex-1'>Automated budget controll</div>
                                                <div className=' flex flex-1 transform transition-all duration-500 ease-in-out  items-center justify-center mt-5 border-gray-300 w-full bg-custom-input border-1.5 rounded-md' style={{ paddingTop: "0.5rem", paddingBottom: "0.6rem" }}>
                                                    <div className="flex flex-1 ml-5 text-sm font-medium">
                                                        {this.state.order.maxBudgetEnabled ? "Active" : "Disabled"}
                                                    </div>
                                                    <div className="relative overflow-hidden mr-5">
                                                        <SwitchTailwind
                                                            value={this.state.order.maxBudgetEnabled}
                                                            onSwitch={async () => {
                                                                this.state.order.maxBudgetEnabled = !this.state.order.maxBudgetEnabled;
                                                                await this.promisedSetState({
                                                                    order: this.state.order
                                                                })
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={"col-span-6 relative"}>
                                                <DropdownTailwind
                                                    label={"Cycle type"}
                                                    locked={!this.showVersionButton()}
                                                    selected={this.state.order.orderCycleType ? this.state.order.orderCycleType : { id: 1, name: "Monthly (default)", value: "monthly" }}
                                                    options={[
                                                        { id: 1, name: "Monthly (default)", value: "monthly" },
                                                        { id: 2, name: "Custom", value: "custom" }
                                                    ]}
                                                    onChange={async (option) => {
                                                        if (this.showVersionButton()) {
                                                            this.state.order.orderCycleType = option;
                                                            this.setState({
                                                                order: this.state.order
                                                            });
                                                        }
                                                    }}
                                                />
                                            </div>
                                            <div className={"col-span-6 relative"}>
                                                <DropdownTailwind
                                                    label={"Cycle option"}
                                                    locked={!(this.state.order.orderCycleType && this.state.order.orderCycleType.value == "custom")}
                                                    selected={{
                                                        id: (this.state.order.orderCycleEndDate && this.state.order.orderCycleEndDate !== "undefined" ? 2 : 1),
                                                        name: (this.state.order.orderCycleEndDate && this.state.order.orderCycleEndDate !== "undefined" ? "End date" : "Repeat cycle"),
                                                        value: (this.state.order.orderCycleEndDate && this.state.order.orderCycleEndDate !== "undefined" ? "enddate" : "repeat"),
                                                    }}
                                                    options={[
                                                        { id: 1, name: "Repeat cycle", value: "repeat" },
                                                        { id: 2, name: "End date", value: "enddate" }
                                                    ]}
                                                    onChange={async (option) => {
                                                        if (option.value == "repeat") {
                                                            this.state.order.orderCycleEndDate = "undefined";
                                                        } else {
                                                            let end_date = moment(this.state.order.startDate).add(1, 'days').format("YYYY-MM-DD");
                                                            if (this.state.order.orderCycleStartDate) {
                                                                end_date = moment(this.state.order.orderCycleStartDate).add(1, 'days').format("YYYY-MM-DD");
                                                            }
                                                            this.state.order.orderCycleEndDate = end_date;
                                                        }
                                                        this.setState({
                                                            order: this.state.order
                                                        });
                                                    }}
                                                />
                                            </div>
                                            {
                                                this.showVersionButton() &&
                                                this.state.order.orderCycleType && this.state.order.orderCycleType.value == "custom" &&
                                                (this.state.order.orderCycleEndDate && this.state.order.orderCycleEndDate !== "undefined") &&
                                                <div className={"col-span-6 relative"}>
                                                    <InputDatepickerTailwind
                                                        label={"End date"}
                                                        value={this.state.order.orderCycleEndDate}
                                                        onChange={async (value) => {
                                                            this.state.order.orderCycleEndDate = value;
                                                            this.setState({
                                                                order: this.state.order
                                                            });
                                                        }}
                                                    />
                                                </div>
                                            }
                                            {
                                                this.showVersionButton() &&
                                                this.state.order.orderCycleType && this.state.order.orderCycleType.value == "custom" &&
                                                !(this.state.order.orderCycleEndDate && this.state.order.orderCycleEndDate !== "undefined") &&
                                                <div className={"col-span-6 relative"}>
                                                    <DropdownTailwind
                                                        searchInput={true}
                                                        label={"Days in cycle"}
                                                        selected={this.state.order.orderCycleRange ? { id: this.state.order.orderCycleRange, titl: this.state.order.orderCycleRange, name: this.state.order.orderCycleRange + " days", value: this.state.order.orderCycleRange } : { id: 0, name: "Select days", value: "null" }}
                                                        options={Array.from({ length: 364 }, (_, i) => i + 2).map((item) => { return { id: item, title: item, name: item + " days", value: item } })}
                                                        onChange={async (option) => {
                                                            this.state.order.orderCycleRange = option.value;
                                                            this.setState({
                                                                order: this.state.order
                                                            });
                                                        }}
                                                    />
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    {
                                        this.showVersionButton() &&
                                        <div className="col-span-4">
                                            <div className="grid-cols-12 gap-4 grid">
                                                <div className="bg-blue-100 border-blue-500 border-1.5 mt-5.5 col-span-12 grid grid-cols-12 rounded-md">
                                                    <div className={"col-span-12 p-4 relative"}>
                                                        <div className="text-sm font-medium">
                                                            <span className="font-semibold">Rollover</span> will take unused budget from previous cycle and add it to the next.
                                                        </div>
                                                        <div className="w-full mt-4 flex items-center">
                                                            <SwitchTailwind
                                                                value={this.state.order.rollOverEnabled ? true : false}
                                                                onSwitch={async () => {
                                                                    this.state.order.rollOverEnabled = !this.state.order.rollOverEnabled;
                                                                    await this.promisedSetState({
                                                                        order: this.state.order
                                                                    });
                                                                }}
                                                            />
                                                            <div className="font-medium text-sm ml-4">{this.state.order.rollOverEnabled ? "Enabled" : "Disabled"}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>

                                {
                                    false &&
                                    <div className='mb-4'>
                                        <div className='mt-4'>
                                            <div className="grid-cols-12 gap-2 grid">
                                                <div className='col-span-12 text-xs font-semibold'>
                                                    <p>Order budget settings</p>
                                                </div>

                                                {
                                                    this.showVersionButton() &&
                                                    <div className="col-span-12 grid-cols-12 gap-4 grid">
                                                        {
                                                            this.showVersionButton() &&
                                                            <div className={"col-span-12 relative"}>
                                                                <div className="bg-blue-100 relative border-1.5 border-blue-500 rounded-md p-4">
                                                                    {
                                                                        this.state.loading_cycle &&
                                                                        <div className="w-full h-full absolute inset-0 bg-white bg-opacity-50 flex justify-center items-center z-20 rounded-lg">
                                                                            <div style={{ borderTopColor: "transparent" }} className="w-5 h-5 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                                                                        </div>
                                                                    }
                                                                    <div className="text-sm font-medium">
                                                                        <span className="font-semibold">Rollover</span> will take unused budget from previous cycle and add it to the next.
                                                                    </div>
                                                                    <div className="w-full mt-4 flex items-center">
                                                                        <SwitchTailwind
                                                                            value={this.state.order.rollOverEnabled ? true : false}
                                                                            onSwitch={async () => {
                                                                                this.state.order.rollOverEnabled = !this.state.order.rollOverEnabled;
                                                                                await this.promisedSetState({
                                                                                    order: this.state.order
                                                                                });
                                                                                //this.functions.updateOrderCycle(true);
                                                                            }}
                                                                        />
                                                                        <div className="font-medium text-sm ml-4">{this.state.order.rollOverEnabled ? "Rollover Enabled" : "Rollover Disabled"}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                        <div className={"col-span-12 relative"}>
                                                            <DropdownTailwind
                                                                label={"Cycle type"}
                                                                //locked={moment(this.state.order.startDate).isBefore(moment()) || !this.showVersionButton()}
                                                                selected={this.state.order.orderCycleType ? this.state.order.orderCycleType : { id: 1, name: "Monthly (default)", value: "monthly" }}
                                                                options={[
                                                                    { id: 1, name: "Monthly (default)", value: "monthly" },
                                                                    { id: 2, name: "Custom", value: "custom" }
                                                                ]}
                                                                onChange={async (option) => {
                                                                    this.state.order.orderCycleType = option;
                                                                    this.setState({
                                                                        order: this.state.order
                                                                    });
                                                                }}
                                                            />
                                                        </div>
                                                        {
                                                            this.state.order.orderCycleType && this.state.order.orderCycleType.value == "custom" &&
                                                            <div className={"col-span-12 relative"}>
                                                                <InputDatepickerTailwind
                                                                    label={"Start date (order start)"}
                                                                    locked={true}
                                                                    disabled={true}
                                                                    placeholder={moment(this.state.startDate).format("YYYY-MM-DD")}
                                                                    value={moment(this.state.startDate).format("YYYY-MM-DD")}
                                                                    onChange={async (value) => {

                                                                    }}
                                                                />
                                                            </div>
                                                        }
                                                        <div className={"col-span-12 relative"}>
                                                            <DropdownTailwind
                                                                label={"Cycle option"}
                                                                locked={!(this.state.order.orderCycleType && this.state.order.orderCycleType.value == "custom")}
                                                                selected={{
                                                                    id: (this.state.order.orderCycleEndDate && this.state.order.orderCycleEndDate !== "undefined" ? 2 : 1),
                                                                    name: (this.state.order.orderCycleEndDate && this.state.order.orderCycleEndDate !== "undefined" ? "End date" : "Repeat cycle"),
                                                                    value: (this.state.order.orderCycleEndDate && this.state.order.orderCycleEndDate !== "undefined" ? "enddate" : "repeat"),
                                                                }}
                                                                options={[
                                                                    { id: 1, name: "Repeat cycle", value: "repeat" },
                                                                    { id: 2, name: "End date", value: "enddate" }
                                                                ]}
                                                                onChange={async (option) => {
                                                                    if (option.value == "repeat") {
                                                                        this.state.order.orderCycleEndDate = "undefined";
                                                                    } else {
                                                                        let end_date = moment(this.state.startDate).add(1, 'days').format("YYYY-MM-DD");
                                                                        if (this.state.order.orderCycleStartDate) {
                                                                            end_date = moment(this.state.order.orderCycleStartDate).add(1, 'days').format("YYYY-MM-DD");
                                                                        }
                                                                        this.state.order.orderCycleEndDate = end_date;
                                                                    }
                                                                    this.setState({
                                                                        order: this.state.order
                                                                    });
                                                                }}
                                                            />
                                                        </div>
                                                        {
                                                            this.state.order.orderCycleType && this.state.order.orderCycleType.value == "custom" &&
                                                            (this.state.order.orderCycleEndDate && this.state.order.orderCycleEndDate !== "undefined") &&
                                                            <div className={"col-span-12 relative"}>
                                                                <InputDatepickerTailwind
                                                                    label={"End date"}
                                                                    //locked={moment(this.state.startDate).isBefore(moment()) || !this.showVersionButton()}
                                                                    minDate={moment(this.state.startDate).format("YYYY-MM-DD")}
                                                                    value={this.state.order.orderCycleEndDate}
                                                                    onChange={async (value) => {
                                                                        this.state.order.orderCycleEndDate = value;
                                                                        this.setState({
                                                                            order: this.state.order
                                                                        });
                                                                    }}
                                                                />
                                                            </div>
                                                        }
                                                        {
                                                            this.state.order.orderCycleType && this.state.order.orderCycleType.value == "custom" &&
                                                            !(this.state.order.orderCycleEndDate && this.state.order.orderCycleEndDate !== "undefined") &&
                                                            <div className={"col-span-12 relative"}>
                                                                <DropdownTailwind
                                                                    searchInput={true}
                                                                    //locked={moment(this.state.order.startDate).isBefore(moment()) || !this.showVersionButton()}
                                                                    label={"Days in cycle"}
                                                                    selected={this.state.order.orderCycleRange ? { id: this.state.order.orderCycleRange, titl: this.state.order.orderCycleRange, name: this.state.order.orderCycleRange + " days", value: this.state.order.orderCycleRange } : { id: 0, name: "Select days", value: "null" }}
                                                                    options={Array.from({ length: 364 }, (_, i) => i + 2).map((item) => { return { id: item, title: item, name: item + " days", value: item } })}
                                                                    onChange={async (option) => {
                                                                        this.state.order.orderCycleRange = option.value;
                                                                        this.setState({
                                                                            order: this.state.order
                                                                        });
                                                                    }}
                                                                />
                                                            </div>
                                                        }
                                                    </div>
                                                }


                                                <div className={"col-span-7"}>
                                                    <InputTailwind
                                                        label={"Monthly budget"}
                                                        value={this.state.total_budget}
                                                        rightSectionClass={this.renders.budgetLeft().text.includes("Over") ? "text-red-500" : (this.renders.budgetLeft().text.includes("Budget left") ? "text-green-600" : "")}
                                                        rightSection={Object.keys(this.state.channel_budget).filter((item) => { return this.state.channel_budget[item] }).length > 0 ? true : false}
                                                        rightSectionLabel={this.renders.budgetLeft().text + this.renders.budgetLeft().value + (this.renders.budgetLeft().percentage ? " (" + this.renders.budgetLeft().percentage + "%)" : "")}
                                                        type={'number'}
                                                        onChange={(value) => {
                                                            this.promisedSetState({
                                                                total_budget: value
                                                            })
                                                            if (Object.keys(this.state.channel_budget).filter((item) => { return this.state.channel_budget[item] }).length > 0) {
                                                                Object.keys(this.state.channel_budget).map((item) => {
                                                                    if (this.state.channel_budget[item] && this.state.channel_budget[item].value && +this.state.channel_budget[item].value > 0) {
                                                                        this.state.channel_budget[item].off_budget = round((this.state.channel_budget[item].value / value) * 100, 2);
                                                                    }
                                                                });
                                                            }
                                                        }}
                                                    />
                                                </div>
                                                <div className={"col-span-5"}>
                                                    <div className='flex relative items-center justify-center mt-5.5 h-12 border-gray-300 w-full bg-custom-input border-1.5 rounded-md' style={{ paddingTop: "0.3rem", paddingBottom: "0.4rem" }}>
                                                        <div className="flex ml-5 text-xms font-semibold items-center">
                                                            Automated budget control
                                                        </div>
                                                        <div className={"relative overflow-hidden mr-5 ml-3"}>
                                                            <SwitchTailwind
                                                                value={this.state.automated_budget}
                                                                onSwitch={async () => {
                                                                    await this.promisedSetState({
                                                                        automated_budget: !this.state.automated_budget
                                                                    });
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={"col-span-12"}>
                                                    <div onClick={() => {
                                                        this.setState({
                                                            crm_option: !this.state.crm_option
                                                        });
                                                    }} className="col-span-12 flex flex-row items-center font-medium text-xs cursor-pointer text-purple-500 hover:text-purple-700">
                                                        Crm settings
                                                        {
                                                            this.state.crm_option &&
                                                            <ChevronDoubleUpIcon className="w-3 h-3 ml-2" />
                                                        }
                                                        {
                                                            !this.state.crm_option &&
                                                            <ChevronDoubleDownIcon className="w-3 h-3 ml-2" />
                                                        }
                                                    </div>
                                                </div>
                                                {
                                                    this.state.crm_option &&
                                                    <>
                                                        <div className={"col-span-7"}>
                                                            <InputTailwind
                                                                label={"Crm ID"}
                                                                value={this.state.crmId}
                                                                onChange={(value) => {
                                                                    this.promisedSetState({
                                                                        crmId: value
                                                                    })
                                                                }}
                                                            />
                                                        </div>
                                                        <div className={"col-span-5"}>
                                                            <InputTailwind
                                                                label={"Crm Ref"}
                                                                value={this.state.crmRef}
                                                                onChange={(value) => {
                                                                    this.promisedSetState({
                                                                        crmRef: value
                                                                    })
                                                                }}
                                                            />
                                                        </div>
                                                    </>
                                                }
                                                <div className='col-span-12 text-xs font-semibold'>
                                                    <p>Channel budget settings</p>
                                                </div>
                                                {
                                                    this.state.selected_client && this.state.selected_client.id &&
                                                    ["facebook", "google", "bing", "linkedin", "snapchat", "tiktok", "twitter"].filter((item) => { return this.state.selected_client.channels.filter((inner_item) => { return inner_item.value === item }).length > 0 }).map((channel, index) => {
                                                        return (
                                                            <Fragment>
                                                                <div className={"col-span-12 grid grid-cols-12 gap-2" + (this.state.total_budget !== "" && this.state.total_budget > 0 ? "" : " opacity-75")}>
                                                                    <div className={"col-span-1"}>
                                                                        <div className={"bg-" + (channel) + "-500" + " h-12 w-12 rounded-md flex justify-center items-center mr-3 mt-5.5"}>
                                                                            {
                                                                                channel === 'google' &&
                                                                                <img className="w-5"
                                                                                    alt=""
                                                                                    src={require('../assets/images/google_icon.png')} />
                                                                            }
                                                                            {
                                                                                channel === 'facebook' &&
                                                                                <img className="w-3"
                                                                                    alt=""
                                                                                    src={require('../assets/images/facebook_icon.svg')} />
                                                                            }
                                                                            {
                                                                                channel === 'linkedin' &&
                                                                                <img className="w-5"
                                                                                    alt=""
                                                                                    src={require('../assets/images/linkedin_icon.svg')} />
                                                                            }
                                                                            {
                                                                                channel === 'twitter' &&
                                                                                <img className="w-5"
                                                                                    alt=""
                                                                                    src={require('../assets/images/twitter_icon.svg')} />
                                                                            }
                                                                            {
                                                                                channel === 'tiktok' &&
                                                                                <img className="w-5"
                                                                                    alt=""
                                                                                    src={require('../assets/images/tiktok_icon.png')} />
                                                                            }
                                                                            {
                                                                                channel === 'snapchat' &&
                                                                                <img className="w-5"
                                                                                    alt=""
                                                                                    src={require('../assets/images/snapchat.svg')} />
                                                                            }
                                                                            {
                                                                                channel === 'bing' &&
                                                                                <img className="w-5"
                                                                                    alt=""
                                                                                    src={require('../assets/images/bing-logo.png')} />
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <div className={"col-span-6"}>
                                                                        <InputTailwind
                                                                            label={"Channel budget"}
                                                                            disabled={this.state.total_budget !== "" && this.state.total_budget > 0 ? false : true}
                                                                            locked={this.state.total_budget !== "" && this.state.total_budget > 0 ? false : true}
                                                                            type={'number'}
                                                                            value={this.state.channel_budget[channel] ? this.state.channel_budget[channel].value : " "}
                                                                            onChange={async (value) => {
                                                                                if (!this.state.channel_budget[channel]) {
                                                                                    this.state.channel_budget[channel] = {
                                                                                        value: "",
                                                                                        off_budget: ""
                                                                                    };
                                                                                }
                                                                                let buffer = JSON.parse(JSON.stringify(this.state.channel_budget[channel]));
                                                                                this.state.channel_budget[channel].value = value;
                                                                                this.state.channel_budget[channel].off_budget = round((value / this.state.total_budget) * 100, 2);

                                                                                await this.promisedSetState({
                                                                                    channel_budget: this.state.channel_budget
                                                                                })
                                                                                if (this.renders.budgetLeft().text.includes("Over")) {
                                                                                    this.state.channel_budget[channel] = buffer;
                                                                                    await this.promisedSetState({
                                                                                        channel_budget: this.state.channel_budget
                                                                                    })
                                                                                }
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    <div className={"col-span-5"}>
                                                                        <InputTailwind
                                                                            label={"% of Order Budget"}
                                                                            disabled={this.state.total_budget !== "" && this.state.total_budget > 0 ? false : true}
                                                                            rightSection={true}
                                                                            rightSectionLabel={"%"}
                                                                            type={'number'}
                                                                            value={this.state.channel_budget[channel] ? this.state.channel_budget[channel].off_budget : " "}
                                                                            onChange={async (value) => {
                                                                                if (!this.state.channel_budget[channel]) {
                                                                                    this.state.channel_budget[channel] = {
                                                                                        value: "",
                                                                                        off_budget: ""
                                                                                    };
                                                                                }

                                                                                let buffer = JSON.parse(JSON.stringify(this.state.channel_budget[channel]));

                                                                                this.state.channel_budget[channel].off_budget = value;
                                                                                this.state.channel_budget[channel].value = round((value / 100) * this.state.total_budget, 2);

                                                                                await this.promisedSetState({
                                                                                    channel_budget: this.state.channel_budget
                                                                                })
                                                                                if (this.renders.budgetLeft().text.includes("Over")) {
                                                                                    this.state.channel_budget[channel] = buffer;
                                                                                    await this.promisedSetState({
                                                                                        channel_budget: this.state.channel_budget
                                                                                    })
                                                                                }
                                                                            }}
                                                                        />
                                                                    </div>

                                                                </div>
                                                            </Fragment>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                }

                                {/* NEXT */}
                                {
                                    false &&
                                    <div className='flex justify-center'>
                                        <div
                                            onClick={() => {
                                                this.state.open.budget = false;
                                                this.state.open.agents = true;
                                                this.setState({ open: this.state.open })
                                            }}
                                            className={`flex w-32 h-10 overflow-hidden rounded-md justify-center text-sm font-medium py-2 border-1.5 ${this.state.name !== "" ? "bg-purple-500 text-white cursor-pointer" : "bg-gray-100 text-gray-300 cursor-not-allowed"}`}>
                                            Next step
                                        </div>
                                    </div>
                                }

                            </SlideDown>
                        </>
                    }

                    {
                        false &&
                        <div className="border-b w-full mt-4 mb-4"></div>
                    }

                    {/* SELECT AGENTS */}
                    {
                        false &&
                        <>
                            <div className={"flex justify-between"}>
                                <div className='flex'>
                                    <div className="h-10 w-10 text-purple-500 border-purple-100 bg-purple-100 border-1.5 flex justify-center items-center rounded-md">
                                        <UsersIcon className="w-5 h-5" />
                                    </div>
                                    <div className="flex flex-col ml-2">
                                        <div className="font-semibold text-sm">Select agents</div>
                                        <div className="font-medium text-xs text-gray-500 flex flex-row">Select agents</div>
                                    </div>
                                </div>
                                <div className='flex'>
                                    {
                                        this.state.agents.length > 0 &&
                                        <div className="h-10 w-10 text-green-500 mr-2 border-green-500 bg-green-100 border-1.5 flex justify-center items-center rounded-md">
                                            <CheckIcon className="w-5 h-5 " />
                                        </div>
                                    }
                                    <div className={"bg-gray-100 cursor-pointer h-10 w-10 border-1.5 flex justify-center items-center rounded-md"}
                                        onClick={() => {
                                            this.state.open.agents = !this.state.open.agents;
                                            this.setState({ open: this.state.open })
                                        }}
                                    >
                                        {
                                            !this.state.open.agents &&
                                            <ChevronDownIcon className="w-5 h-5 " />
                                        }
                                        {
                                            this.state.open.agents &&
                                            <ChevronUpIcon className="w-5 h-5 " />
                                        }
                                    </div>
                                </div>
                            </div>
                            <SlideDown
                                closed={!this.state.open.agents}
                            >
                                <div className='mb-4'>
                                    <div className='mt-4'>
                                        <div className="grid-cols-12 gap-4 grid">
                                            <div className="col-span-12">
                                                <DropdownTailwind
                                                    label={"Agents"}
                                                    small={false}
                                                    skipInternalSearch={true}
                                                    loader={this.state.loading_agents}
                                                    loadingPagination={this.state.loading_agents_pagination}
                                                    loadingSearch={this.state.loading_agents_search}
                                                    total={this.state.total}
                                                    searchInput={true}
                                                    placeholder={"..."}
                                                    pagination={this.state.total > (this.state.page * this.state.limit)}
                                                    selected={{ id: 0, name: "Select agent" }}
                                                    options={this.state.agent_options}
                                                    onChange={async (value) => {
                                                        this.state.agents.unshift({
                                                            email: value.name,
                                                            id: value.id
                                                        });
                                                        await this.promisedSetState({
                                                            client: this.state.client
                                                        });
                                                    }}
                                                    onPagination={async () => {
                                                        if (!this.state.loading_agents_pagination) {
                                                            await this.promisedSetState({
                                                                page: this.state.page + 1
                                                            });
                                                            this.functions.getAgents(false, true, false);
                                                        }
                                                    }}
                                                    onSearch={async (value) => {
                                                        if (value && value === "") {
                                                            await this.promisedSetState({
                                                                search: "",
                                                                page: 1
                                                            });
                                                            this.functions.getAgents(false, false, true);
                                                        } else {
                                                            if (this.state.throttling.current) {
                                                                //SKIP
                                                            } else {
                                                                if (value && value === "") {
                                                                    await this.promisedSetState({
                                                                        search: value,
                                                                        page: 1
                                                                    });
                                                                    this.functions.getAgents(false, false, true);
                                                                } else {
                                                                    this.state.throttling.current = true;
                                                                    await this.promisedSetState({
                                                                        search: value,
                                                                        throttling: this.state.throttling
                                                                    });
                                                                    setTimeout(async () => {
                                                                        this.state.throttling.current = false;
                                                                        await this.promisedSetState({
                                                                            throttling: this.state.throttling
                                                                        });
                                                                        await this.promisedSetState({
                                                                            page: 1
                                                                        });
                                                                        this.functions.getAgents(false, false, true);
                                                                    }, 300);
                                                                }
                                                            }
                                                        }
                                                    }}
                                                />
                                            </div>
                                            {
                                                Array.isArray(this.state.agents) && this.state.agents.length > 0 &&
                                                <div className="col-span-12 -mt-2">
                                                    {
                                                        this.state.agents.map((item, index) => {
                                                            return (
                                                                <Fragment>
                                                                    <div className={"bg-indigo-100 text-indigo-700 mt-2 flex flex-1 rounded-md items-center p-2 text-sm font-medium "}>
                                                                        <div className="flex flex-1 ml-2">{item.email}</div>
                                                                        <button
                                                                            onClick={() => {
                                                                                this.state.agents = this.state.agents.filter((agent) => {
                                                                                    return agent.id !== item.id;
                                                                                });
                                                                                this.setState({
                                                                                    client: this.state.client
                                                                                });
                                                                            }}
                                                                            type="button"
                                                                            className={"focus:bg-indigo-500  bg-indigo-200 text-indigo-500 flex-shrink-0 ml-1 h-8 w-8 rounded-md inline-flex items-center justify-center focus:outline-none focus:text-white"}
                                                                        >
                                                                            <span className="sr-only">Remove agent</span>
                                                                            <svg className="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
                                                                                <path strokeLinecap="round" strokeWidth="1.5" d="M1 1l6 6m0-6L1 7" />
                                                                            </svg>
                                                                        </button>
                                                                    </div>
                                                                </Fragment>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>

                                {/* NEXT */}
                                {
                                    false &&
                                    <div className='flex justify-center mb-4'>
                                        <div
                                            onClick={() => {
                                                this.state.open.agents = false;
                                                this.state.open.agents = true;
                                                this.setState({ open: this.state.open })
                                            }}
                                            className={`flex w-32 h-10 overflow-hidden rounded-md justify-center text-sm font-medium py-2 border-1.5 ${this.state.name !== "" ? "bg-purple-500 text-white cursor-pointer" : "bg-gray-100 text-gray-300 cursor-not-allowed"}`}>
                                            Next step
                                        </div>
                                    </div>
                                }

                            </SlideDown>
                        </>
                    }

                    {
                        this.state.error &&
                        <div className={"w-full mt-4"}>
                            <div className="bg-red-100 p-4 rounded-md">
                                <div className="text-sm text-red-500">
                                    {this.state.error}
                                </div>
                            </div>
                        </div>
                    }

                </div>
            </>
        )
    }
}

export default CreateOrder;