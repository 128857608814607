import React, { Component } from 'react';
import { apiRegister } from "../services/apiRegister";
import { tokenRegister } from '../services/tokenRegister';
import { userRegister } from '../services/userRegister';
import InputTailwind from '../moduleFiles/inputTailwind';
import { clientRegister } from '../services/clientRegister';

class Login extends Component {

    constructor(props) {
        super(props);
        this.state = {
            email: "",
            password: "",
            loading: false,

        }
    };

    functions = {
        login: () => {
            if (!this.state.loading) {
                this.setState({
                    loading: true,
                    error: false,
                    message: null
                }, () => {
                    this.calls.login({ email: this.state.email, password: this.state.password }).then((response) => {
                        tokenRegister.set(response.headers['x-auth-token'], response.headers['x-auth-refresh-token'], response.data.type === 'admin');
                        userRegister.set(response.data, response.data.type === 'admin');
                        clientRegister.remove();
                        if (response.data.type === 'admin') {
                            this.props.history.push('/verification');
                        } else {
                            if (!response.data.auth_verification) {
                                let url = localStorage.getItem("redirectUrl")
                                if (url) {
                                    localStorage.removeItem("redirectUrl");
                                    this.props.history.push(url);
                                } else {
                                    this.props.history.push('/v2/dashboard');
                                }
                            } else {
                                this.props.history.push('/verification');
                            }
                        }
                    }, (error) => {
                        this.setState({
                            message: error && error.body && error.body.message ? error.body.message : "Something went wrong",
                            loading: false,
                            error: true
                        });
                    });
                })
            }
        }
    };

    calls = {
        login: (data) => {
            let options = apiRegister.options(null, 'POST', data);
            let url = apiRegister.url.api + "/v3/adcredo/loginUser";
            return apiRegister.call(options, url);
        }
    };

    render() {
        return (
            <div className="min-h-full flex">
                <div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:w-1/3 lg:flex-none lg:px-20 xl:px-24">
                    <div className="mx-auto w-full max-w-sm lg:w-96">
                        <div>
                            <img
                                className="h-12 w-auto"
                                src={require("../assets/images/adcredo_icon_purple.png")}
                                alt="Workflow"
                            />
                            <h2 className="mt-6 text-3xl font-extrabold text-gray-900">Welcome back</h2>
                            <p className="mt-1 text-sm text-gray-600">
                                Please login to your account.
                            </p>
                        </div>
                        <div className="mt-8">
                            <div className="mt-6">
                                <div className="space-y-6">
                                    <InputTailwind
                                        error={this.state.error}
                                        label={"Email address"}
                                        value={this.state.email}
                                        onChange={(value) => {
                                            this.setState({
                                                email: value
                                            })
                                        }}
                                    />
                                    <div className="space-y-1">
                                        <InputTailwind
                                            error={this.state.error}
                                            errorMessage={this.state.message}
                                            label={"Password"}
                                            type={"password"}
                                            value={this.state.password}
                                            onChange={(value) => {
                                                this.setState({
                                                    password: value
                                                })
                                            }}
                                        />
                                    </div>
                                    <div className={"pt-6"}>
                                        <button
                                            onClick={() => {
                                                this.functions.login();
                                            }}
                                            className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                        >
                                            {
                                                this.state.loading &&
                                                <div>
                                                    <div style={{ borderTopColor: "transparent" }} class="w-4 h-4 border-2 border-white border-solid rounded-full animate-spin"></div>
                                                </div>
                                            }
                                            {
                                                !this.state.loading &&
                                                <div>Sign in</div>
                                            }
                                        </button>
                                    </div>
                                    <div className="flex items-center justify-between">
                                        <div className="text-sm">
                                            <a href="/forgot-password" className="font-medium text-indigo-600 hover:text-indigo-500">
                                                Forgot your password?
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-row absolute bottom-0 items-center pb-6">
                            <a className="text-xs text-gray-600" href={"https://www.adcredo.com/privacy-policy"} target={"_blank"}>Privacy Policy</a>
                            <div className="w-1 h-1 ml-3 mr-3 rounded-full bg-gray-600"></div>
                            <a className="text-xs text-gray-600" href={"https://www.adcredo.com/terms-of-service"} target={"_blank"}>Terms of Service</a>
                        </div>
                    </div>
                </div>
                <div className="hidden lg:block relative w-0 flex-1 overlay overlay--gradient bg-cover bg-center" style={{ backgroundImage: 'url(https://images.unsplash.com/photo-1558591710-4b4a1ae0f04d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=668&q=80)' }}>

                </div>
            </div>
        )
    }
}

export default Login;
