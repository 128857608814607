import React, { Component, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react'
import { BeakerIcon, CheckIcon, ExclamationIcon, MinusIcon, PlusIcon } from '@heroicons/react/outline';
import cn from "classnames";
import InputTailwind from './inputTailwind';
import { tokenRegister } from '../services/tokenRegister';
import { apiRegister } from '../services/apiRegister';
import DropdownTailwind from './dropdownTailwind';
import TextAreaTailwind from './textAreaTailwind';
import InputDatepickerTailwind from './inputDatepickerTailwind';
import moment from 'moment';

import { campaignSnapchat } from '../validators/campaignSnapchat';
import InputTimepickerTailwind from './inputTimepickerTailwind';
import SwitchTailwind from './switchTailwind';

class EditLiveSnapchatCampaign extends Component {

    constructor(props) {
        super(props);
        this.state = {
            wizard: false,
            open: false,
            currency: "",
            campaign: {},
            adgroups: [],
            updated: {},
            client: {},
            loader: false,
            // DEFAULT BRAND_AWARENESS
            objectives: [
                // CAN HAVE MULTIPLE, SELECTED FROM DROPWDOWN - IMPRESSIONS, SWIPES, STORY_OPENS, ?? SHARE ??
                // { id: 0, name: 'Brand Awareness', value: "BRAND_AWARENESS", goal: { id: 0, name: 'Brand awareness', value: "BRAND_AWARENESS" } },
                { id: 1, name: 'Awareness', value: "BRAND_AWARENESS", goal: { id: 1, name: 'awareness', value: "VARIABLE" } },
                { id: 2, name: 'Promote Places', value: "PROMOTE_PLACES", goal: { id: 2, name: 'swipes', value: "SWIPES" } },
                // CAN HAVE MULTIPLE, SELECTED FROM DROPWDOWN - APP_INSTALLS, IMPRESSIONS, SWIPES, APP_PURCHASE, APP_SIGNUP, APP_ADD_TO_CART
                { id: 3, name: 'App Installs', value: "APP_INSTALLS", goal: { id: 3, name: 'VARIABLE', value: "VARIABLE" } },
                { id: 4, name: 'Drive Traffic To Website', value: "WEB_VIEW", goal: { id: 2, name: 'swipes', value: "SWIPES" } },
                { id: 5, name: 'Drive Traffic To App', value: "TRAFFIC_APP", goal: { id: 2, name: 'swipes', value: "SWIPES" } },
                // CAN HAVE MULTIPLE, SELECTED FROM DROPWDOWN - SWIPES, STORY OPENS, ?? SHARE ?? MAYBE USES ??
                { id: 6, name: 'Engagement', value: "ENGAGEMENT", goal: { id: 6, name: 'variable', value: "VARIABLE" } },
                // CAN HAVE MULTIPLE, SELECTED FROM DROPWDOWN - IMPRESSIONS, VIDEO_VIEWS, VIDEO_VIEWS_15_SEC
                { id: 7, name: 'Video Views', value: "VIDEO_VIEWS", goal: { id: 7, name: 'variable', value: "VARIABLE" } },
                // CAN HAVE MULTIPLE, SELECTED FROM DROPWDOWN - STORY_OPENS, SWIPES, LEAD_FORM_SUBMISSIONS
                { id: 8, name: 'Lead Generation', value: "LEAD_GENERATION", goal: { id: 8, name: 'variable', value: "VARIABLE" } },
                // CAN HAVE MULTIPLE, SELECTED FROM DROPWDOWN - SWIPES, APP_INSTALLS, APP_PURCHASES, APP_SIGNUPS, APP_ADD_TO_CART
                { id: 9, name: 'App Conversions', value: "APP_CONVERSIONS", goal: { id: 9, name: 'variable', value: "VARIABLE" } },
                // !!! REQUIRES PIXEL ON WEBSITE !!! CAN HAVE MULTIPLE, SELECTED FROM DROPWDOWN - SWIPES, APP_PURCHASES, APP_SIGNUPS, APP_ADD_TO_CART, APP_INSTALLS
                // OR PIXEL_PURCHASE, PIXEL_SIGNUP, PIXEL_PAGE_VIEW, PIXEL_ADD_TO_CART ?? 
                { id: 10, name: 'Website Conversions', value: "WEBSITE_CONVERSIONS", goal: { id: 10, name: 'variable', value: "VARIABLE" } },
                // !!! REQUIRES CATALOG !!! CAN HAVE MULTIPLE, SELECTED FROM DROPWDOWN - SWIPES, APP_PURCHASES, APP_SIGNUPS, APP_ADD_TO_CART, APP_INSTALLS
                { id: 11, name: 'Catalog Sales', value: "CATALOG_SALES", goal: { id: 11, name: 'variable', value: "VARIABLE" } },
            ],
            tab: { id: 1, name: "Basic", value: "basic" },
            tabs: [
                { id: 1, name: "Basic", value: "basic" }
            ],
            status_values: [{ id: 1, name: 'Active', value: "ACTIVE" }, { id: 2, name: 'Paused', value: "PAUSED" }],
            daily_spend_cap: [
                { id: 1, name: "Custom", value: "CUSTOM" },
                { id: 2, name: "No cap", value: "NO_CAP" },
            ],
            lifetime_spend_cap: [
                { id: 1, name: "Custom", value: "CUSTOM" },
                { id: 2, name: "No cap", value: "NO_CAP" },
            ],
            locked: false,
        }
    }

    async componentDidMount() {
        await this.promisedSetState({
            campaign: this.props.campaign,
            client: this.props.client,
            wizard: this.props.wizard,
            locked: this.props.locked ? this.props.locked : false,
            // adgroups: this.props.adgroups ? this.props.adgroups : []
        })
        console.log("campaign from editLive", this.state)
        if (this.state.client.currency && Object.keys(this.state.client.currency).includes("snapchat") && this.state.client.currency.snapchat !== "") {
            await this.promisedSetState({
                currency: this.state.client.currency.snapchat
            })
        }
        //console.log("campaign from editLive", this.state.campaign)
        // if (this.state.client.channels.filter((item) => { return item.value === "snapchat" }).length > 0) {
        //     await this.promisedSetState({
        //         currency: this.state.client.channels.filter((item) => { return item.value === "snapchat" })[0].currency
        //     })
        // }
        // if (this.props.updated) {
        //     this.setState({
        //         updated: this.props.updated
        //     });
        // }
    }

    componentWillReceiveProps(nextProps) {
        /*this.setState({
            campaign: nextProps.campaign,
            client: nextProps.client,
            wizard: nextProps.wizard
        })
        if (nextProps.updated) {
            this.setState({
                updated: nextProps.updated
            });
        }*/
    }

    functions = {
        update: async (data) => {
            if (!this.state.wizard) {
                this.props.onSuccess(false);
                this.props.onLoader(true);
                this.promisedSetState({
                    error: false
                });
                if (!data) {
                    if (Object.keys(this.state.updated).length > 0) {
                        let body = {
                            id: this.state.campaign.id,
                            name: this.state.updated.name ? this.state.updated.name : this.state.campaign.name,
                            start_time: this.renders.startDate(),
                            status: this.renders.status().value,
                            ...(this.state.campaign.buy_model) && { buy_model: this.state.campaign.buy_model },
                            ...(this.renders.dailyBudget() && this.renders.dailyBudget() !== "") && { daily_budget_micro: this.renders.dailyBudget() * 1000000 },
                            ...(this.renders.lifetimeBudget() && this.renders.lifetimeBudget() !== "") && { lifetime_spend_cap_micro: this.renders.lifetimeBudget() * 1000000 },
                            ...(this.renders.endDate()) && { end_time: this.renders.endDate() },
                        };
                        try {
                            let response = await this.calls.updateLiveCampaign(body);
                            if (response.data.error) {
                                this.promisedSetState({
                                    error: response.data.error
                                });
                            }
                        } catch (error) {
                            //this.props.onError(error.error ? error.error : "Something went wrong");
                        }
                    }
                } else {
                    this.promisedSetState({
                        loading_status: true
                    });
                    try {
                        if (data.value === "PAUSED") {
                            this.props.onPauseCampaign(true);
                        } else {
                            this.props.onActivateCampaign(true);
                        }

                        this.state.updated.status = data;
                        this.promisedSetState({
                            loading_status: false,
                            updated: this.state.updated
                        });

                    } catch (error) {
                        //this.props.onError(error.error ? error.error : "Something went wrong");
                    }
                }
                if (!this.state.error) {
                    if (!data) this.props.onSuccess(true);
                }
                this.props.onLoader(false);
            } else {
                this.props.onUpdate(this.state.updated);
            }
        }
    };

    calls = {
        updateLiveCampaign: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/adcredo/updateLiveCampaign?client=" + this.state.client.id + "&campaign=" + this.state.campaign.id + "&channel=snapchat";
            return apiRegister.call(options, url);
        },
    };

    renders = {
        name: () => {
            try {
                if ('name' in this.state.updated) {
                    return this.state.updated.name;
                } else if (this.state.campaign.name) {
                    return this.state.campaign.name
                } else {
                    return "";
                }
            } catch (error) {
                return "";
            }
        },
        objective: () => {
            try {
                if (this.state.objectives.filter((objective) => { return objective.value === this.state.campaign.objective }).length > 0) {
                    return this.state.objectives.filter((objective) => { return objective.value === this.state.campaign.objective })[0].name;
                } else {
                    return "";
                }
            } catch (error) {
                return "";
            }
        },
        status: () => {
            try {
                if ('status' in this.state.updated) {
                    return this.state.updated.status;
                } else if (this.state.status_values.filter((item) => { return item.value.toLowerCase() === this.state.campaign.status.toLowerCase() }).length > 0) {
                    return this.state.status_values.filter((item) => { return item.value.toLowerCase() === this.state.campaign.status.toLowerCase() })[0];
                } else {
                    return { id: 0, name: 'Click here ...', value: "PAUSED" }
                }
            } catch (error) {
                return { id: 0, name: 'Click here ...', value: "PAUSED" }
            }
        },
        startDate: () => {
            try {
                if ('start_date' in this.state.updated) {
                    return this.state.updated.start_date;
                } else if (this.state.campaign.start) {
                    return moment(this.state.campaign.start)
                } else {
                    return "";
                }
            } catch (error) {
                return "";
            }
        },
        endDate: () => {
            try {
                if ('end_date' in this.state.updated) {
                    return this.state.updated.end_date;
                } else if (this.state.campaign.end) {
                    return moment(this.state.campaign.end)
                } else {
                    return false;
                }
            } catch (error) {
                return false;
            }
        },
        dailyBudget: () => {
            try {
                if ('daily_spend_cap' in this.state.updated) {
                    return this.state.updated.daily_spend_cap / 1000000;
                } else if (this.state.campaign.daily_budget_micro) {
                    return this.state.campaign.daily_budget_micro / 1000000
                } else {
                    return false;
                }
            } catch (error) {
                return false;
            }
        },
        lifetimeBudget: () => {
            try {
                if ('lifetime_spend_cap' in this.state.updated) {
                    return this.state.updated.lifetime_spend_cap / 1000000;
                } else if (this.state.campaign.lifetime_spend_cap_micro) {
                    return this.state.campaign.lifetime_spend_cap_micro / 1000000
                } else {
                    return false;
                }
            } catch (error) {
                return false;
            }
        },
        hasDailyCap: () => {
            try {
                if ('daily_budget' in this.state.updated) {
                    return this.state.updated.daily_budget;
                } else if (this.renders.dailyBudget()) {
                    return { id: 1, name: "Custom", value: true }
                } else {
                    return { id: 2, name: "No cap", value: false }
                }
            } catch (error) {
                return { id: 2, name: "No cap", value: false };
            }
        },
        hasLifeTimeCap: () => {
            try {
                if ('lifetime_budget' in this.state.updated) {
                    return this.state.updated.lifetime_budget;
                } else if (this.renders.lifetimeBudget()) {
                    return { id: 1, name: "Custom", value: true }
                } else {
                    return { id: 2, name: "No cap", value: false }
                }
            } catch (error) {
                return { id: 2, name: "No cap", value: false };
            }
        },
    }


    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {

        return (
            <>
                <div className="grid grid-cols-6 gap-4">

                    {/*Menu*/}
                    <div className="col-span-3">
                        <div className="block overflow-scroll w-full">
                            <nav className="flex space-x-4" aria-label="Tabs">
                                {this.state.tabs.map((tab) => (
                                    <div
                                        onClick={() => (
                                            this.setState({
                                                tab: tab
                                            })
                                        )}
                                        key={tab.name}
                                        style={{ whiteSpace: "nowrap" }}
                                        className={cn(
                                            tab.value == this.state.tab.value ? 'bg-purple-100 text-purple-500' : 'text-gray-500 hover:text-purple-500',
                                            'px-3 py-2 font-medium text-sm rounded-md whitespace-nowrap cursor-pointer flex'
                                        )}
                                        aria-current={tab.value == this.state.tab.value ? 'page' : undefined}
                                    >
                                        <div>
                                            {
                                                (tab.id === 1 && (campaignSnapchat.validate(this.state.updated).lifetime_amount || campaignSnapchat.validate(this.state.updated).daily_amount || campaignSnapchat.validate(this.state.updated).start_date)) ? <ExclamationIcon className="h-5 w-5 text-red-600 mr-2" /> : ""
                                            }
                                        </div>
                                        {tab.name}
                                    </div>
                                ))}
                            </nav>
                        </div>
                    </div>

                    {/* NAME */}
                    <div className="col-span-6">
                        <InputTailwind
                            label={"Campaign name"}
                            value={this.renders.name()}
                            onChange={(value) => {
                                this.state.updated.name = value;
                                this.setState({
                                    updated: this.state.updated
                                })
                            }}
                        />
                    </div>

                    {/*OBJECTIVE*/}
                    <div className="col-span-3">
                        <InputTailwind
                            label={"Campaign objective"}
                            value={this.renders.objective()}
                            disabled={true}
                            locked={true}
                        />
                    </div>

                    {/* STATUS */}
                    <div className="col-span-3">
                        <div className='-mb-4 text-xs font-medium text-gray-700 flex flex-1'>Status</div>
                        <div className='flex items-center justify-center mt-5 border-gray-300 w-full bg-custom-input border-1.5 rounded-md' style={{ paddingTop: "0.5rem", paddingBottom: "0.6rem" }}>
                            <div className="flex flex-1 ml-5">
                                {this.renders.status().name}
                            </div>
                            <div className="relative overflow-hidden mr-5">
                                <SwitchTailwind
                                    value={this.renders.status().value === "ACTIVE" ? true : false}
                                    disabled={this.state.locked}
                                    onSwitch={async () => {
                                        if (!this.state.locked) {
                                            if (this.renders.status().value === "ACTIVE") {
                                                this.functions.update({ id: 2, name: 'Paused', value: "PAUSED" });
                                            } else if (this.renders.status().value === "PAUSED") {
                                                this.functions.update({ id: 1, name: 'Active', value: "ACTIVE" });
                                            }
                                        }

                                    }}
                                />
                                {
                                    this.state.loading_status &&
                                    <div className="w-full h-full bg-opacity-50 absolute bg-white top-0 bottom-0 rounded-md left-0 right-0 flex justify-center items-center">
                                        <div style={{ borderTopColor: "transparent" }}
                                            class="w-4 h-4 border-2 border-purple-500 absolute border-solid rounded-full animate-spin"></div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>

                    {/* DAILY SPEND CAP */}
                    <div className="col-span-6 grid grid-cols-6 gap-4">
                        <div className="col-span-3">
                            <div className='-mb-4 text-xs font-medium text-gray-700 flex flex-1'>Daily spend cap</div>
                            <div className='flex items-center justify-center mt-5 border-gray-300 w-full bg-custom-input border-1.5 rounded-md' style={{ paddingTop: "0.5rem", paddingBottom: "0.6rem" }}>
                                <div className="flex flex-1 ml-5">
                                    {this.renders.hasDailyCap().name}
                                </div>
                                <div className="relative overflow-hidden mr-5">
                                    <SwitchTailwind
                                        value={this.renders.hasDailyCap().value}
                                        onSwitch={async () => {
                                            if (this.renders.hasDailyCap().value) {
                                                this.state.updated.daily_budget = { id: 2, name: "No cap", value: false };
                                                this.state.updated.daily_spend_cap = "";
                                            } else if (!this.renders.hasDailyCap().value) {
                                                this.state.updated.daily_budget = { id: 1, name: "Custom", value: true };
                                            }
                                            await this.promisedSetState({
                                                updated: this.state.updated
                                            })
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-span-3">
                            <InputTailwind
                                //error={campaignSnapchat.validate(this.state.updated).lifetime_spend_cap}
                                label={"Daily amount"}
                                leftSection={(this.state.currency !== "" ? true : false)}
                                leftSectionLabel={(this.state.currency)}
                                type={'number'}
                                value={this.renders.dailyBudget() ? this.renders.dailyBudget() : ""}
                                onChange={(value) => {
                                    this.state.updated.daily_spend_cap = value * 1000000;
                                    this.setState({
                                        updated: this.state.updated
                                    })
                                }}
                                disabled={!this.renders.hasDailyCap().value}
                                locked={!this.renders.hasDailyCap().value}
                            />
                        </div>

                    </div>
                    {/* LIFETIME SPEND CAP */}
                    <div className="col-span-6 grid grid-cols-6 gap-4">
                        <div className="col-span-3">
                            <div className='-mb-4 text-xs font-medium text-gray-700 flex flex-1'>Lifetime spend cap</div>
                            <div className='flex items-center justify-center mt-5 border-gray-300 w-full bg-custom-input border-1.5 rounded-md' style={{ paddingTop: "0.5rem", paddingBottom: "0.6rem" }}>
                                <div className="flex flex-1 ml-5">
                                    {this.renders.hasLifeTimeCap().name}
                                </div>
                                <div className="relative overflow-hidden mr-5">
                                    <SwitchTailwind
                                        value={this.renders.hasLifeTimeCap().value}
                                        onSwitch={async () => {
                                            if (this.renders.hasLifeTimeCap().value) {
                                                this.state.updated.lifetime_budget = { id: 2, name: "No cap", value: false };
                                                this.state.updated.lifetime_spend_cap = "";
                                            } else if (!this.renders.hasLifeTimeCap().value) {
                                                this.state.updated.lifetime_budget = { id: 1, name: "Custom", value: true };
                                            }
                                            await this.promisedSetState({
                                                updated: this.state.updated
                                            })
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-span-3">
                            <InputTailwind
                                //error={campaignSnapchat.validate(this.state.updated).lifetime_spend_cap}
                                label={"Daily amount"}
                                leftSection={(this.state.currency !== "" ? true : false)}
                                leftSectionLabel={(this.state.currency)}
                                type={'number'}
                                value={this.renders.lifetimeBudget() ? this.renders.lifetimeBudget() : ""}
                                onChange={(value) => {
                                    this.state.updated.lifetime_spend_cap = value * 1000000;
                                    this.setState({
                                        updated: this.state.updated
                                    })
                                }}
                                disabled={!this.renders.hasLifeTimeCap().value}
                                locked={!this.renders.hasLifeTimeCap().value}
                            />
                        </div>
                    </div>
                    {/* DATES + DATE PICKERS */}

                    <div className="col-span-6">
                        <div className="grid grid-cols-6 gap-4">
                            <div className="col-span-3 grid grid-cols-6 gap-4">
                                <div className="col-span-3">
                                    <InputDatepickerTailwind
                                        //error={adgroupFacebook.validate({ start_date: this.renders.startDate(), ...(moment().unix() > moment(this.state.adgroup.start_date).unix() && { status: this.renders.status() }) }).start_date}
                                        disabled={(moment().unix() > moment(this.renders.startDate()).unix() ? true : false)}
                                        locked={(moment().unix() > moment(this.renders.startDate()).unix() ? true : false)}
                                        placeholder={moment(this.renders.startDate()).format("MM/DD/YYYY")}
                                        label={"Start date"}
                                        value={moment(this.renders.startDate()).format("YYYY-MM-DD")}
                                        onChange={(value) => {
                                            let time = moment(this.renders.startDate()).format("HH:mm");
                                            let new_date = value + " " + time;
                                            this.state.updated.start_date = moment(new_date);
                                            this.setState({
                                                updated: this.state.updated
                                            })
                                        }}
                                    />
                                </div>
                                <div className="col-span-3">
                                    <InputTimepickerTailwind
                                        //error={adgroupFacebook.validate({ start_date: this.renders.startDate(), ...(moment().unix() > moment(this.state.adgroup.start_date).unix() && { status: this.renders.status() }) }).start_date}
                                        disabled={(moment().unix() > moment(this.renders.startDate()).unix() ? true : false)}
                                        locked={(moment().unix() > moment(this.renders.startDate()).unix() ? true : false)}
                                        placeholder={moment(this.renders.startDate()).format("HH:mm")}
                                        label={"Start time"}
                                        value={moment(this.renders.startDate()).format("HH:mm")}
                                        onChange={(value) => {
                                            let date = moment(this.renders.startDate()).format("YYYY-MM-DD");
                                            let new_date = date + " " + value;
                                            this.state.updated.start_date = moment(new_date);
                                            this.setState({
                                                updated: this.state.updated
                                            })
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="col-span-3 grid grid-cols-6 col-gap-4">
                                <div className={(this.renders.endDate() ? "col-span-3" : "col-span-6")}>
                                    <InputDatepickerTailwind
                                        label={"End date"}
                                        disabled={!this.renders.endDate()}
                                        placeholder={!this.renders.endDate() ? "No end date" : ""}
                                        value={moment(this.renders.endDate()).format("YYYY-MM-DD")}
                                        onChange={async (value) => {
                                            let time = moment(this.renders.endDate()).format("HH:mm");
                                            let new_date = value + " " + time;
                                            this.state.updated.end_date = moment(new_date);
                                            this.setState({
                                                updated: this.state.updated
                                            })
                                        }}
                                        labelRight={(this.renders.endDate() ? "Disable" : "Enable")}
                                        onLabelRightClick={() => {
                                            if (this.renders.endDate()) {
                                                this.state.updated.end_date = null;
                                            } else {
                                                let new_date = moment(this.renders.startDate()).add(1, 'days');
                                                this.state.updated.end_date = moment(new_date);
                                            }
                                            this.setState({
                                                updated: this.state.updated
                                            })
                                        }}
                                    />
                                </div>
                                {
                                    this.renders.endDate() &&
                                    <div className="col-span-3">
                                        <InputTimepickerTailwind
                                            label={"End time"}
                                            placeholder={!this.renders.endDate() ? "No end time" : ""}
                                            disabled={!this.renders.endDate()}
                                            value={moment(this.renders.endDate()).format("HH:mm")}
                                            onChange={async (value) => {
                                                let date = moment(this.renders.endDate()).format("YYYY-MM-DD");
                                                let new_date = date + " " + value;
                                                this.state.updated.end_date = moment(new_date);
                                                this.setState({
                                                    updated: this.state.updated
                                                })
                                            }}
                                        />
                                    </div>
                                }
                            </div>



                        </div>
                    </div>
                </div>
            </>
        )
    }
}


export default EditLiveSnapchatCampaign;
